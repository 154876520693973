import { RawTranslations } from 'src/i18n/translations/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'Stornierungsbedingungen anzeigen',
    hide_cancellation_policy: 'Stornierungsbedingungen ausblenden',
    departure: 'Abfahrt',
    price: 'Preis',
    difficulty: 'Schwierigkeit',
    duration: 'Dauer',
    starting_time: 'Startzeit',
    about: 'Über',
    book_now: 'Jetzt buchen',
    book: 'Buchen',
    login: 'Einloggen',
    buy_as_gift: 'Als Geschenk kaufen',
    from: 'Von',
    your_booking_reference_is: 'Ihre Buchungsreferenz lautet',
    booking_details_in_email: 'Wir haben Ihnen eine E-Mail mit weiteren Informationen gesendet',
    see_more_activities: 'Mehr Aktivitäten anzeigen',
    your_cart: 'Ihr Warenkorb',
    when_are_you_going: 'Wählen Sie ein Datum und eine Uhrzeit',
    how_many_are_going: 'Wie viele sind dabei?',
    contact_details: 'Kontaktinformationen',
    traveler: 'Reisender',
    extras: 'Extras',
    please_select: 'Bitte auswählen',
    no_items_in_cart: 'Ihr Warenkorb ist leer',
    payment: 'Bezahlung',
    receipt: 'Quittung',
    do_you_have_any_comments_or_special_needs:
        'Haben Sie irgendwelche Kommentare oder besondere Anforderungen?',
    i_have_read_and_accepted_the: 'Ich habe gelesen und akzeptiert',
    privacy_policy: 'Datenschutzrichtlinien',
    terms_and_conditions: 'Geschäftsbedingungen',
    first_name: 'Vorname',
    last_name: 'Nachname',
    phone_number: 'Telefonnummer',
    dial_code: 'Dial code',
    email: 'E-Mail',
    please_register: 'Bitte registrieren Sie sich',
    main_contact: 'Hauptkontakt',
    additional_information: 'Zusatzinformationen',
    remove_cart_item: 'Artikel aus dem Warenkorb entfernen',
    back_to_webshop: 'Zurück zum Online-Shop',
    edit_activity: 'Aktivität bearbeiten',
    excl: 'excl.',
    vat: 'MwSt.',
    total: 'Gesamt',
    next: 'Weiter',
    go_and_pay: 'Bezahlen',
    print: 'Drucken',
    close: 'Schließen',
    checkout: 'Zur Kasse gehen',
    summary: 'Zusammenfassung',
    cancel: 'Abbrechen',
    save: 'Speichern',
    select_time: 'Zeit auswählen',
    selected_time: 'Ausgewählte Zeit',
    time: 'Zeit',
    go_back: 'geh zurück',
    thank_you_for_booking_with_us: 'Vielen Dank für Ihre Buchung bei uns!',
    please_fill_in_all_required_information:
        'Bitte füllen Sie alle erforderlichen Informationen aus',
    error_occurred_when_creating_reservation:
        'Bei der Übermittlung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
    error_occurred_when_creating_giftcard:
        'Beim Erstellen der Geschenkkarte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
    couldnt_apply_promocode_is_the_code_correct:
        'Der Aktionscode konnte nicht angewendet werden. Ist der Code korrekt?',
    choose_a_country: 'Wählen Sie ein Land',
    select_date: 'Datum auswählen',
    select_travelers: 'Reisende auswählen',
    hourShort: 'Std.',
    dayShort: 'Tag',
    minuteShort: 'Min.',
    years: 'Jahre',
    age: 'Alter',
    more_information: 'Mehr Informationen',
    same_as_main_contact: 'Gleich wie Hauptkontakt',
    easy: 'Einfach',
    medium: 'Mittel',
    hard: 'Schwer',
    please_select_date: 'Bitte Datum auswählen',
    please_select_date_and_time: 'Bitte wählen Sie Datum und Uhrzeit',
    name: 'Name',
    comment: 'Kommentar',
    submit_request: 'Anfrage absenden',
    any_special_requests_etc: 'Besondere Anfragen, Lebensmittelallergien...?',
    request_tour_comment: 'Beschreiben Sie Ihre Anfrage',
    thank_you_for_your_request:
        'Vielen Dank für Ihre Anfrage, wir werden uns schnell bei Ihnen melden.',
    got_it: 'Verstanden!',
    continue_shopping: 'Weiter einkaufen',
    continue_travelers: 'Weiter Reisende',
    please_provide_your_name: 'Bitte geben Sie Ihren Namen an',
    please_provide_your_email: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    please_provide_a_description: 'Bitte geben Sie eine Beschreibung an',
    an_error_occurred_when_submitting_your_request:
        'Bei der Übermittlung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
    private_tour: 'Private Tour',
    read_more_and_book: 'Mehr lesen und buchen',
    read_more: 'Mehr lesen',
    prices_from: 'Preise ab',
    about_the_trip: 'Über die Reise',
    prices: 'Preise',
    whats_included: 'Enthaltene Leistungen',
    any: 'Beliebig',
    duration_up_to_1_hour: 'Bis zu 1 Stunde',
    duration_1_2_hours: '1 - 2 Stunden',
    duration_2_6_hours: '2 - 6 Stunden',
    duration_6_12_hours: '6 - 12 Stunden',
    duration_12_24_hours: '12 - 24 Stunden',
    duration_1_2_days: '1 - 2 Tage',
    duration_more_than_2_days: 'Mehr als 2 Tage',
    find_activities: 'Aktivitäten finden',
    ok: 'OK',
    clear: 'Löschen',
    filterActivities: 'Aktivitäten filtern',
    hideFilters: 'Filter ausblenden',
    shopMore: 'Mehr einkaufen',
    noSearchResults:
        'Es tut uns leid, wir konnten keine verfügbaren Aktivitäten finden, die zu Ihrer Suche passen',
    update: 'Aktualisieren',
    select_date_to_view_timeslots: 'Wählen Sie ein Datum, um verfügbare Zeitfenster anzuzeigen',
    your_reservation_timed_out: 'Ihre Reservierung ist abgelaufen. Bitte versuchen Sie es erneut.',
    aria_calendar_input_field_label: 'Kalenderfeld. Drücken Sie Enter, um es zu öffnen.',
    aria_book_now_button: 'Ein Fenster für die Buchung dieser Aktivität öffnen',
    aria_next_image: 'Nächstes Bild',
    aria_previous_image: 'Vorheriges Bild',
    aria_open_booking_widget: 'Buchungsfenster öffnen',
    aria_close_booking_widget: 'Buchungsfenster schließen',
    aria_open_request_tour_widget: 'Fenster für Touranfrage öffnen',
    aria_close_request_tour_widget: 'Fenster für Touranfrage schließen',
    aria_open_find_activity: 'Öffnen Sie die Suchaktivität',
    aria_close_find_activity: 'Aktivität schließen',
    request_tour: 'Tour anfragen',
    giftcard_message: 'Nachricht',
    giftcard_message_label: 'Persönliche Nachricht an den Empfänger (optional)',
    recipient_details: 'Empfängerinformationen',
    gift_card: 'Geschenkkarte',
    value: 'Wert',
    create: 'Erstellen',
    create_your_gift: 'Erstellen Sie Ihre Geschenkkarte',
    your_giftcard_reference_is: 'Ihre Referenz lautet',
    giftcard_voucher_and_receipt:
        'Wir haben Ihnen einen Geschenkgutschein zum Ausdrucken sowie eine Quittung gesendet an:',
    giftcard_voucher: 'Wir haben den Geschenkgutschein an:',
    giftcard_and_receipt: 'und eine Quittung an Sie geschickt an:',
    thank_you_for_your_purchase: 'Vielen Dank für Ihren Kauf!',
    valid_until: 'Gültig bis',
    print_receipt: 'Quittung drucken',
    select_gift_type: 'Geschenkkartentyp auswählen',
    please_select_gift_type: 'Bitte wählen Sie den Geschenkkartentyp aus',
    select_gift_print_description:
        'Erhalten Sie eine druckbare PDF-Geschenkkarte in Ihrem Posteingang',
    select_gift_email_description:
        'Senden Sie ein Geschenkkarte per E-Mail direkt an den Empfänger',
    enter_giftcard_code: 'Geben Sie einen Geschenkgutscheincode ein',
    enter_promo_code: 'Geben Sie einen Promo-Code ein',
    apply: 'Anwenden',
    giftcard_code_error: 'Der Geschenkgutscheincode ist ungültig',
    remaining_value: 'Verbleibender Wert',
    giftcard_code_not_found: 'Der Geschenkgutschein mit dem Code konnte nicht gefunden werden:',
    purchased_giftcard: {
        self: 'druckbare Geschenkkarte, die an Sie gesendet wurde',
        recipient: 'druckbare Geschenkkarte, die an den Empfänger gesendet wurde',
    },
    contact_information: {
        short: 'Kontaktinformation',
        long: 'Kontaktinformationen',
    },
    minutes: {
        singular: 'Min',
        plural: 'Min',
        long: 'Minute',
    },
    hours: {
        singular: 'Stunde',
        plural: 'Stunden',
    },
    days: {
        singular: 'Tag',
        plural: 'Tage',
    },
    children: {
        singular: 'Kind',
        plural: 'Kinder',
    },
    adult: {
        singular: 'Erwachsener',
        plural: 'Erwachsene',
    },
    card: {
        singular: 'Karte   ',
        plural: 'Karten',
    },
    number_of_travelers_exceeded: {
        template: 'Die maximale Gruppengröße an diesem Tag beträgt <COUNT:person:people> Personen',
    } as any,
    booking_is_closed: {
        template: 'Die Buchung für  <NAME> ist jetzt geschlossen',
    } as any,
    rooms: {
        singular: 'Zimmer',
        plural: 'Räume',
    },
    person: {
        singular: 'Person',
        plural: 'Personen',
    },
    select_room: 'Wählen Sie einen Raum aus',
    change_room: 'Umkleideraum',
    less_than: 'weniger als',
    year: {
        singular: 'Jahr',
        plural: 'Jahre',
    },
    select_age: 'Alter auswählen',
    done: 'Erledigt',
    number_of_rooms: 'Anzahl der Räume',
    select_bed: 'Bett auswählen',
    room_info: 'Zimmerinfo',
    price_one_night_one_guest: {
        template: 'Preis für <COUNT:night:nights>, <COUNT:guest:guest>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:night:nights>, <COUNT:guest:guests>',
    } as any,
    price_nights: {
        template: 'Preis für <COUNT:night:nights>',
    } as any,
    night: {
        singular: 'Nacht',
        plural: 'Nächte',
    },
    select_room_for_total_price: 'Wählen Sie die Zimmer aus, um den Gesamtpreis zu erhalten',
    find_rooms: 'Zimmer finden',
    check_in_check_out: 'Check-in und Check-out',
    guest_and_rooms: 'Gäste und Zimmer',
    no_accommodations_found:
        'Es tut uns leid, aber wir konnten keine Zimmer finden, die Ihren Suchkriterien entsprechen. Haben Sie darüber nachgedacht, Ihre Suchparameter anzupassen, um verfügbare Zimmer zu finden?',
    facilities_included: 'Ausstattung inklusive',
    price_per_night: 'Preis pro Nacht',
    season: 'Jahreszeit',
    has_airconditioning: 'Klimaanlage',
    has_balcony: 'Balkon',
    has_bicycles: 'Fahrräder',
    has_boat: 'Boot',
    has_coffee_maker: 'Kaffeemaschine',
    has_dishwasher: 'Geschirrspüler',
    has_dryer: 'Trockner',
    has_fireplace: 'Kamin',
    has_garage: 'Garage',
    has_garden_furniture: 'Gartenmöbel',
    has_grill: 'Grill',
    has_kitchen: 'Küche',
    has_minibar: 'Minibar',
    has_mooring_space: 'Liegeplatz',
    has_pool: 'Schwimmbad',
    has_roomservice: 'Zimmerservice',
    has_sauna: 'Sauna',
    has_tv: 'Fernseher',
    has_washing_machine: 'Waschmaschine',
    has_wifi: 'W-lan',
    is_breakfast_included: 'Frühstück',
    is_cancellation_included: 'Möglichkeit zur Stornierung',
    is_pets_allowed: 'Haustiere erlaubt',
    is_smoking_allowed: 'Rauchen erlaubt',
    is_towels_and_bedlines_included: 'Handtücher und Bettwäsche',
    is_wheelchair_accessible: 'Für Rollstuhlfahrer zugänglich',
    wifi: 'W-lan',
    breakfast: 'Frühstück',
    accessible: 'Zugänglich',
    pets: 'Haustiere',
    kitchen: 'Küche',
    smoking: 'Rauchen',
    unknown: 'Unbekannt',
    accommodation_cancellation_policy: 'Stornierungsbedingungen für Unterkünfte',
    accommodations: 'Unterkünfte',
    activities: 'Aktivitäten',
    address: 'Adresse',
    city: 'Stadt',
    post_code: 'Postleitzahl',
    to: 'Zu',
    date_range_start: 'Start',
    date_range_end: 'Ende',
    flag_of: 'Flagge von',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    today: 'Heute',
    tomorrow: 'morgen',
    january: 'Januar',
    february: 'Februar',
    march: 'Marsch',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    few_left: 'wenige übrig',
    available: 'verfügbar',
    sold_out: 'ausverkauft',
    upcoming_tours_title: 'Kommende Touren',
    unavailable: 'Unavailable',
    notes_regarding_your_stay: 'Hinweise zu Ihrem Aufenthalt',
    notes_for_accommodation_name_with_guests: {
        template: 'Hinweise für <NAME> mit <COUNT:guest:guests>',
    } as any,
    select_price_type: 'Preistyp auswählen',
    finding_available_rooms: 'Verfügbare Zimmer finden',
    total_price: 'Gesamtpreis',
    of: 'von',
    select_dates: 'Wählen Sie Daten auss',
    please_select_price_type: 'Bitte wählen Sie den Preistyp aus',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: `Mindestens <COUNT> Teilnehmer sind erforderlich, um dieses Produkt zu buchen`,
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Eine Buchungsbestätigung wird an Ihre E-Mail-Adresse gesendet, sobald wir Ihre Bestellung bearbeitet haben.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Eine Bestätigung wird an Ihre E-Mail-Adresse gesendet, sobald wir Ihre Bestellung bearbeitet haben.',
    add: 'Hinzufügen',
    amount: 'Menge',
    areYouAMemberOrHaveValueCard: 'Sind Sie Mitglied oder besitzen eine Wertkarte?',
    areYouSureYouWantToCancelYourBooking:
        'Sind Sie sicher, dass Sie diese Buchung stornieren möchten?',
    areYouSureYouWantToCancelYourMembership:
        'Sind Sie sicher, dass Sie Ihre Mitgliedschaft kündigen möchten?',
    automaticallyRenewed: 'Automatisch erneuert',
    at: 'Bei',
    backToWebsite: 'Zurück zur Website',
    belayCertification: 'Sicherungszertifizierung',
    belayCertificationConsent: 'Ich habe eine Sicherungszertifizierung',
    buyMembership: 'Mitgliedschaft kaufen',
    buyNow: 'Kaufe jetzt',
    cancelled: 'Abgesagt',
    canBeCancelledFrom: 'Kann ab storniert werden',
    choose_payment_method: 'Zahlungsart auswählen',
    bookNow: 'Kaufe jetzt',
    bookTimeslot: 'Tickets kaufen',
    cancellation: 'Stornierung',
    choose: 'Wählen',
    chooseACountry: 'Wähle ein Land',
    chooseCreditsType: 'Wählen Sie die Art der Credits',
    chooseDateAndTime: 'Wählen Sie Datum und Uhrzeit',
    chooseNumberOfCredits: 'Wählen Sie die Anzahl der Credits',
    chooseStartDate: 'Wählen Sie das Startdatum',
    chooseType: 'Wählen Sie Typ',
    ticketCode: 'Bestellnummer',
    completed: 'Vollendet',
    consents: 'Einwilligungen',
    contact: 'Kontakt',
    contactByEmail: 'Kontakt per E-Mail',
    contactBySms: 'Kontakt per SMS',
    couldntApplyGiftcardIsTheCodeCorrect:
        'Die Geschenkkarte konnte nicht angewendet werden. Ist der Code korrekt?',
    couldntApplyPromocodeIsTheCodeCorrect:
        'Der Aktionscode konnte nicht angewendet werden. Ist der Code korrekt?',
    couldntGetCompanyInformation: 'Es konnten keine Unternehmensinformationen abgerufen werden',
    couldntGetPaymentPlan:
        'Der Zahlungsplan konnte nicht abgerufen werden. Bitte versuchen Sie, die Webseite zu aktualisieren.',
    couldntGetPaymentPlanUsingPreviousPlan:
        'Der Zahlungsplan konnte mit dem vorherigen Plan nicht abgerufen werden',
    couldntGetValueCards: 'Es konnten keine Wertkarten abgerufen werden',
    couldntGetProductPleaseTryAgain:
        'Das Produkt konnte nicht abgerufen werden. Bitte versuchen Sie es erneut',
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        'Der Checkout für die Buchung konnte nicht geöffnet werden, da nicht alle erforderlichen Buchungsinformationen angegeben wurden.',
    couldntOpenProfilePleaseSignInFirst:
        'Das Profil konnte nicht geöffnet werden. Bitte melden Sie sich zuerst an.',
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        'Die Wertkartenkasse konnte nicht geöffnet werden, da nicht alle erforderlichen Wertkarteninformationen angegeben wurden.',
    country: 'Land',
    createAccount: 'Benutzerkonto erstellen',
    credit: {
        singular: 'Kredit',
        plural: 'Credits',
    },
    credits: 'Credits',
    creditsLeft: 'Credits übrig',
    creditDiscount: 'Kreditrabatt',
    date: 'Datum',
    delete: 'Löschen',
    doYouHaveAnAccount: 'Hast du einen Account?',
    doYouHaveValueCards: 'Haben Sie ein Konto oder Wertkarten?',
    doYouHaveBrattkort: 'Verfügen Sie über eine Brattkort- oder Sicherungszertifizierung?',
    doYouHaveClimbingCertification: 'Haben Sie eine Kletterzertifizierung?',
    edit: 'Bearbeiten',
    editTimeslot: 'Zeitfenster bearbeiten',
    failed: 'Fehlgeschlagen',
    firstName: 'Vorname',
    flagOf: 'Flagge von',
    giftcard: 'Geschenkkarte',
    goToMyPage: 'Gehen Sie auf meine Seite',
    ifYouWantToDeleteYourProfile: 'Wenn Sie Ihr Profil löschen möchten',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'Tickets können Sie im Shop auf dieser Website kaufen.',
    yourOrderReferenceIs: 'Ihre Bestellnummer lautet',
    iHaveReadAndAccepted: 'Ich habe die gelesen und akzeptiert',
    lastName: 'Familienname, Nachname',
    location: 'Standort',
    loggedInAs: 'Angemeldet als',
    logout: 'Ausloggen',
    membershipCard: 'Mitgliedschaft',
    membershipNumber: 'Mitgliedsnummer',
    membershipSlashCredits: 'Mitgliedschaft / Credits',
    monthlyCharge: 'Monatliche Gebühr',
    myAddress: 'Meine Adresse',
    myPage: 'Meine Seite',
    myProfile: 'Mein Profil',
    nextCreateAccount: 'Als nächstes: Konto erstellen',
    no: 'Nein',
    noneSelected: 'Nichts ausgewählt',
    noSelectedDate: 'Kein Datum ausgewählt',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'Es wurden nicht alle Felder ausgefüllt. Bitte füllen Sie alle Felder aus.',
    noUserInfoFound: 'Keine Benutzerinformationen gefunden.',
    numberOfPersons: {
        singular: 'Person',
        plural: 'Personen',
        template: '<COUNT:person:persons>',
    } as any,
    pay: 'Zahlen',
    payWithCreditCard: 'Mit Kreditkarte bezahlen',
    phoneNumber: 'Telefonnummer',
    pleaseChoose: 'Bitte auswählen',
    pleaseContact: 'Kontaktieren Sie bitte',
    pleaseFillOutUserInfoInProfile:
        'Bitte füllen Sie die Benutzerinformationen in Ihrem Profil aus.',
    pleaseLogInToBook: 'Bitte melden Sie sich an, um damit einzukaufen',
    pleaseLogInToPurchase:
        'Bitte melden Sie sich an und wir füllen Ihre Kontaktinformationen für Sie aus',
    pleaseProvideAllValueCardInformation: 'Bitte geben Sie alle Informationen zur Wertkarte an',
    pleaseProvideProperBookingInformation: 'Bitte geben Sie gültige Informationen an',
    pleaseRegister: 'Bitte registrieren',
    postalCodeAndLocation: 'Postleitzahl / Ort',
    prefix: 'Präfix',
    privacyPolicy: 'Datenschutzrichtlinie',
    problemLoadingData: 'Fehler beim Laden der Daten',
    processing: 'wird bearbeitet',
    purchaseValueCard: 'Einkaufswertkarte',
    readMoreAbout: 'Lesen Sie mehr über unsere',
    readMoreAndBook: 'Lesen Sie mehr und kaufen Sie',
    readMoreAndBuy: 'Lesen Sie mehr und kaufen Sie',
    refillCredits: 'Guthaben aufladen',
    returnToWebsite: 'Zurück zur Website',
    scanTicket: 'Ticket scannen',
    signUp: 'Melden Sie sich an',
    subtract: 'Subtrahieren',
    shoesAndHarnessAlwaysIncluded: 'Schuhe und Geschirr immer inklusive.',
    shoesAndHarnessNotIncluded: 'Schuhe und Geschirr nicht im Lieferumfang enthalten.',
    somethingWentWrongUpdatingUserInfo:
        'Beim Aktualisieren der Benutzerinformationen ist ein Fehler aufgetreten.',
    termsOfUse: {
        template: 'Nutzungsbedingungen für <NAME>',
    } as any,
    upcomingBookings: 'Kommende Buchungen',
    updateAddress: 'Adresse aktualisieren',
    terminate: 'Beenden',
    thankYouForBookingWithUs: 'Vielen Dank, dass Sie bei uns gebucht haben.',
    thePrivacyPolicy: 'Datenschutzrichtlinie',
    thankYouForShoppingWithUs: 'Danke für ihren Einkauf.',
    thisGiftcardIsEmpty: 'Diese Geschenkkarte ist leer.',
    tHours: 'Std',
    totalCredits: 'Gesamt Credits',
    totalNOK: 'Insgesamt NOK',
    updateBooking: 'Buchung aktualisieren',
    updateProfile: 'Profil aktualisieren',
    useGiftcard: 'Geschenkkarte verwenden',
    useGiftcardOrPromocode: 'Geschenkkarte / Promocode verwenden',
    validationNotANumber: 'Muss eine Nummer sein',
    validationMaxNumber: {
        template: 'Keine Zahlen über <NUM>',
    } as any,
    validationMinNumber: {
        template: 'Keine Zahlen unter <NUM>',
    } as any,
    validForThePeriod: 'Gültig für den Zeitraum',
    validUntil: 'Gültig bis',
    valueCard: 'Wertkarte',
    visitors: 'Besucher',
    withoutVat: 'Ohne VAT',
    yes: 'Ja',
    youDontHaveAnyUpcomingBookings: 'Sie haben keine bevorstehenden Buchungen.',
    youDontHaveAnyValueCards: 'Sie haben keine Wertkarten',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        'Sie haben keine gültige Mitgliedschaft, möchten Sie eine erwerben?',
    youMustSpecifyAValueCard: 'Sie müssen eine gültige Wertkarte angeben.',
    yourReservationTimedOut: 'Bei Ihrer Reservierung ist eine Zeitüberschreitung aufgetreten.',
    zipcode: 'Postleitzahl',
    payByMembershipCard: 'Bezahlen Sie mit der Mitgliedskarte',
    payWith: 'Bezahlen mit',
    deleteMyProfile: 'Mein Profil löschen',
    areYouSureYouWantToDeleteYourProfile: 'Sind Sie sicher, dass Sie Ihr Profil löschen möchten?',
    choosePaymentOtherMethods: 'Wählen Sie andere Zahlungsmethoden',
    dontUseMembershipCardForThisBooking: 'Verwenden Sie für diese Buchung keine Mitgliedskarte',
    nameNotProvided: 'Name wird nicht angegeben',
    phoneNumberNotProvided: 'Telefonnummer wird nicht angegeben',
    emailNotProvided: 'E-Mail wird nicht bereitgestellt',
    addressNotProvided: 'Adresse wird nicht angegeben',
    promocode: 'Aktionscode',
    show_usage_terms: 'Nutzungsbedingungen anzeigen',
    hide_usage_terms: 'Nutzungsbedingungen ausblenden',
    about_the_product: 'über das Produkt',
    successful: 'Erfolgreich',
    selectProduct: 'Ausgewähltes Produkt',
    withCreditCard: 'Mit Kreditkarte',
    paymentMethod: 'Bezahlverfahren',
    noAvailableTimeslots: 'Keine verfügbaren Zeitfenster',
    orderReference: 'Bestellnummer',
    iWishToRecieveNewsletters: 'Ich möchte Newsletter erhalten',
    mySite: 'Meine Seite',
    pleaseConfirm: {
        template: 'Bitte bestätigen Sie "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Bitte geben Sie die Anzahl der Teilnehmer an',
    pleaseSelectTheDateAndTimeOfYourBooking:
        'Bitte wählen Sie das Datum und die Uhrzeit Ihrer Buchung aus',
    pleaseSelectNumberOfCredits: 'Bitte wählen Sie die Anzahl der Credits aus',
    pleaseSelectTypeOfCreditcard: 'Bitte wählen Sie die Art der Kreditkarte aus',
    pleaseSelectAStartDate: 'Bitte wählen Sie ein Startdatum aus',
    pleaseSelectAMembershipType: 'Bitte wählen Sie eine Mitgliedschaftsart aus',
    contactCustomerServiceToDeleteYourProfile:
        'Wenden Sie sich an den Kundendienst, um Ihr Profil zu löschen',
    inclInMembership: 'inkl. bei der Mitgliedschaft',
    maxAmount: 'Maximaler Betrag',
    checkIn: 'Einchecken',
    checkOut: 'Kasse',

    anErrorOccurredDuringPayment: 'Bei der Zahlung ist ein Fehler aufgetreten',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut, indem Sie die Seite neu laden. Fehlermeldung',
    share: 'Aktie',
    copyLink: 'Link kopieren',
    howDoYouWantToShare: 'Wie möchten Sie teilen?',

    shoppingCart: 'Einkaufswagen',
    heresMyShoppingCart: 'Hier ist mein Warenkorb',
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        'Die Kapazität für die Artikel in diesem Warenkorb reicht nicht aus',

    full: 'Voll',
    no_available_capacity_for_this_tour: 'Für diese Tour sind keine Kapazitäten verfügbar',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        'Eine oder mehrere Aktivitäten verfügen nicht über genügend Kapazität',

    pick_a_date: 'Suche dir einen Termin aus',
    package: 'Paket',
    packages: 'Pakete',
    customize_activities: 'Passen Sie Aktivitäten an',
    check_in_at: 'Checken Sie ein um',
    activity_starts_at: 'Die Aktivität beginnt um',
    other_times_are_available: 'Andere Zeiten sind verfügbar',
    press_to_select_other_options: 'Drücken Sie , um andere Optionen auszuwählen',
    unavailableForSelectedTime: 'Zum ausgewählten Zeitpunkt nicht verfügbar',
    order_status_awaiting_payment: 'Zahlung verarbeiten',
    order_status_payment_failed: 'Bezahlung fehlgeschlagen',
    awaiting_payment_info:
        'Die Zahlungsabwicklung dauert in der Regel nicht länger als ein paar Minuten.',
    cancel_membership: 'Mitgliedschaft kündigen',
    update_subscription_payment_details: 'Zahlungsdetails aktualisieren',
    payment_failed_info:
        'Es scheint ein Problem mit Ihrer Zahlung zu geben. Sie könnten versuchen, die Zahlungsdetails zu aktualisieren.',
    retry_subscription_payment: 'Versuchen Sie es jetzt noch einmal mit der Zahlung',
};

export default translations;
