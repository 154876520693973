import { darken } from '@mui/material/styles';
import { ThemeType } from 'src/utils/common/theme/ThemeType';

export const positionInputLabelAboveField: Record<string, string> = {
    position: 'relative',
    transform: ` translate(0px, 4px) scale(0.85) !important`,
    paddingBottom: '1px',
};

export const positionInputLabelBelowField: Record<string, string> = {
    position: 'relative',
    transform: ` scale(0.85) !important`,
    paddingBottom: '1px',
    textAlign: 'center',
    transformOrigin: 'center center',
    maxWidth: '100%',
};

export function bookingWidgetInputStyle(
    customizations: ThemeType,
    backgroundColor?: string,
    color?: string,
): Record<string, any> {
    return {
        backgroundColor: `${backgroundColor ?? customizations.bookingWidgetInputColor} !important`,
        color: `${color ?? customizations.bookingWidgetInputTextColor} !important`,
        border: 'none',
        transition: '0.1s ease-in-out all',
        '&:hover,&:focus,&:active': {
            backgroundColor: `${darken(
                backgroundColor ?? customizations.bookingWidgetInputColor,
                0.1,
            )} !important`,
            color: `${color ?? customizations.bookingWidgetInputTextColor} !important`,
        },
    };
}

export function bookingSearchFormInputStyle(customizations: ThemeType): Record<string, any> {
    return {
        backgroundColor: `${customizations.bookingSearchFormInputColor} !important`,
        color: `${customizations.bookingSearchFormInputTextColor} !important`,
        border: 'none',
        transition: '0.1s ease-in-out all',
        '&:hover,&:focus,&:active': {
            backgroundColor: `${darken(
                customizations.bookingSearchFormInputColor,
                0.1,
            )} !important`,
            color: `${customizations.bookingSearchFormInputTextColor} !important`,
        },
    };
}

export default {
    positionInputLabelAboveField,
    positionInputLabelBelowField,
    bookingWidgetInputStyle,
    bookingSearchFormInputStyle,
};
