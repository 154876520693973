import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, h } from 'preact';
import { useEffect } from 'preact/hooks';
import Extras from 'src/components/domain/extras/Extras';
import Summary from 'src/components/domain/summary/Summary';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useLocale } from 'src/i18n/locale';
import { createAddExtraToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import {
    ICartItem,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useExtrasByIds } from 'src/utils/domain/api/bilberry-api-client';
import {
    getProductIdsFromCartItems,
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
} from 'src/utils/domain/cart/cartUtils';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { ButtonDisplay } from './ButtonDisplay';
import { getTabIndex } from './getTabIndex';

type ExtrasViewProps = {
    cartItems: { [key: number]: ICartItem };
    largeScreen: boolean;
    activeTab: Parameters<typeof getTabIndex>[0];
    onNextClicked: () => void;
    isMakingReservation: boolean;
};

export function ExtrasView({
    cartItems,
    largeScreen,
    activeTab,
    onNextClicked,
    isMakingReservation,
}: ExtrasViewProps) {
    const config = useConfigurations();
    const { t, locale } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Extras',
        cartItems: Object.values(cartItems),
    }));

    const {
        extras,
        isLoading: isExtrasLoading,
        isError: isExtrasError,
    } = useExtrasByIds(getProductIdsFromCartItems(cartItems), locale, config);

    const hasExtras = isExtrasLoading || (extras && extras.length > 0);
    const cartItemsLength = Object.values(cartItems).length ?? 0;
    const cartProductItemsLength =
        Object.values(cartItems).filter((item) => isICartProductItemType(item.item)).length ?? 0;
    const cartPackageItemsLength =
        Object.values(cartItems).filter((item) => isICartPackageItemType(item.item)).length ?? 0;
    const cartMultiDayProductItemsLength =
        Object.values(cartItems).filter((item) => isICartMultiDayProductItemType(item.item))
            .length ?? 0;

    useEffect(() => {
        // Whenever extras changes check if we got empty, and if so move on
        if (!config.enableExtras || !hasExtras || isExtrasError) {
            // If we have already passed the first tab, there is no
            // need to navigate to tab 1 again. Often if swr revalidated
            // on this page it returned back to tab 1 as this use effect ran.
            if (activeTab === 'extras') {
                onNextClicked();
            }
        }

        if (extras) {
            Object.values(cartItems)
                .map((item) => item.item)
                .filter(
                    (
                        item,
                    ): item is ICartMultiDayProductItem | ICartProductItem | ICartPackageItem =>
                        isICartProductItemType(item) ||
                        isICartMultiDayProductItemType(item) ||
                        isICartPackageItemType(item),
                )
                .map((cartItem) => createAddExtraToCartEvent(cartItem, extras))
                .forEach((e) => {
                    cartAtom.update(e);
                });
        }
        // Cannot use cartItems or onNextClicked as dependencies as they are changing based on this useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasExtras, extras, isExtrasError, activeTab]);

    return (
        <Fragment>
            {isExtrasLoading &&
                (cartPackageItemsLength > 0 ||
                    cartProductItemsLength > 0 ||
                    cartMultiDayProductItemsLength > 0) && <CircularProgress />}
            {cartItemsLength === 0 && (
                <Typography color="textSecondary" variant="h4">
                    {t.no_items_in_cart}
                </Typography>
            )}
            {!isExtrasLoading && hasExtras && (
                <Grid
                    container
                    justifyContent={largeScreen ? 'space-between' : 'center'}
                    alignItems={largeScreen ? 'flex-start' : 'center'}
                    direction={largeScreen ? 'row' : 'column-reverse'}
                >
                    {!largeScreen && (
                        <Box
                            maxWidth="440px"
                            width="100%"
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    marginBottom: theme.spacing(3),
                                },
                            }}
                        >
                            <Button
                                sx={{ float: 'right' }}
                                color="primary"
                                variant={customizations.primaryButtonStyle}
                                disabled={isMakingReservation}
                                onClick={onNextClicked}
                            >
                                <ButtonDisplay
                                    isMakingReservation={isMakingReservation}
                                    buttonText={t.continue_travelers}
                                />
                            </Button>
                        </Box>
                    )}
                    <Extras />
                    <Summary
                        showShare
                        hideGoPay={!largeScreen}
                        isCheckoutSummary={true}
                        isMakingReservation={isMakingReservation}
                        goPayClicked={onNextClicked}
                        nextButtonText={t.continue_travelers}
                    />
                </Grid>
            )}
        </Fragment>
    );
}
