import { Fragment, h } from 'preact';
import UpcomingTour from 'src/components/domain/upcoming-tours/Tour/UpcomingTour';
import { BilberryProduct } from 'src/types/bilberry-api-types';

export default function UpcomingToursList(props: {
    upcomingTours: BilberryProduct[];
    fewLeft: number;
}) {
    return (
        <Fragment>
            {props.upcomingTours.map((tour) => (
                <UpcomingTour key={tour.id} tour={tour} fewLeft={props.fewLeft}></UpcomingTour>
            ))}
        </Fragment>
    );
}
