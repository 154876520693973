import '../common/setup-dayjs';
import { LicenseInfo } from '@mui/x-license-pro';

import { assertVersionAndTTLValid } from 'src/state/localstorage';
assertVersionAndTTLValid();

import { createHashHistory } from 'history';

export const history = createHashHistory();

// Add license key for Mui X Pro components.
// (it's supposed to be public)
// https://mui.com/x/advanced-components/#security
LicenseInfo.setLicenseKey(
    'ad7cdc4b474f4b5d1e38853e14664680T1JERVI6NDMwMDEsRVhQSVJZPTE2ODMyMDUwMjYwMDAsS0VZVkVSU0lPTj0x',
);
