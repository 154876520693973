import { alpha, Box, Button, darken, Tab, Tabs, useTheme } from '@mui/material';
import { capitalize, groupBy } from 'lodash-es';
import { useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAtom } from 'ximple';
import ItineraryDay from './ItineraryDay';

type ProductType = 'hotel' | 'activity' | 'transfer';

type ItineraryProps = {
    items: {
        id: string;
        index: number;
        dayIndex: number;
        title: string;
        productType: ProductType;
        subtitle: string;
        category: string;
    }[];
};

export default function Itinerary(props: ItineraryProps) {
    const theme = useTheme();
    const { t } = useLocale();
    const customizations = useCustomizations();

    const { items } = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const handleChange = (_e: MouseEvent, i: number) => setCurrentIndex(i);

    const itemsByDay = groupBy(items, (item) => item.dayIndex);

    const [toggleBookingState] = useAtom(toggleBookingAtom);
    const handleBookNowClicked = () =>
        toggleBookingAtom.update({ visible: !toggleBookingState.visible, interaction: 'mouse' });
    const handleBookNowEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            toggleBookingAtom.update({
                visible: !toggleBookingState.visible,
                interaction: 'keyboard',
            });
        }
    };
    // TODO: Update color customizations for tabs so they use colors we want to be using
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Tabs
                value={currentIndex}
                onChange={handleChange}
                sx={{
                    '* > .MuiTabs-indicator': {
                        backgroundColor: customizations.checkoutHeaderPrimaryColor,
                    },
                }}
            >
                {Object.values(itemsByDay).map((_p, i) => (
                    <Tab
                        label={`${capitalize(t.days.singular)} ${i + 1}`}
                        sx={{
                            '&.Mui-selected': {
                                color: customizations.checkoutHeaderPrimaryColor,
                            },
                            '&.Mui-disabled': {
                                color: customizations.checkoutHeaderColorContrast,
                                opacity: 0.5,
                            },
                            '&.MuiTabs-indicator': {
                                backgroundColor: customizations.checkoutHeaderPrimaryColor,
                            },
                        }}
                    />
                ))}
            </Tabs>
            {Object.values(itemsByDay).map((items, i) => (
                <Box hidden={i !== currentIndex} sx={{ width: '100%', maxWidth: '360px' }}>
                    <ItineraryDay items={items} />
                </Box>
            ))}
            <Button
                variant="contained"
                disableElevation
                sx={{
                    mt: theme.spacing(3),
                    backgroundColor:
                        customizations.primaryButtonStyle === 'contained'
                            ? customizations.bookingWidgetPrimaryColor
                            : 'transparent',
                    color:
                        customizations.primaryButtonStyle === 'contained'
                            ? customizations.bookingWidgetPrimaryColorContrast
                            : customizations.bookingWidgetPrimaryColor,
                    borderColor:
                        customizations.primaryButtonStyle === 'contained'
                            ? 'transparent'
                            : customizations.bookingWidgetPrimaryColor,
                    '&:hover': {
                        backgroundColor:
                            customizations.primaryButtonStyle === 'contained'
                                ? darken(customizations.bookingWidgetPrimaryColor, 0.2)
                                : alpha(customizations.bookingWidgetPrimaryColor, 0.2),
                        color:
                            customizations.primaryButtonStyle === 'contained'
                                ? darken(customizations.bookingWidgetPrimaryColorContrast, 0.2)
                                : undefined,
                    },
                }}
                onClick={handleBookNowClicked}
                onKeyDown={handleBookNowEnter}
            >
                {t.bookNow}
            </Button>
        </Box>
    );
}
