import { h, render } from 'preact';
import { getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import AccommodationSearch from './accommodation-search-form/AccommodationSearch';

export function AccommodationSearchWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    url: string;
    showChildrenData: boolean;
}): JSX.Element {
    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <AccommodationSearch showChildrenData={props.showChildrenData} />
        </WidgetWrapper>
    );
}

class BilberryAccommodationSearch extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const resultUrl = this.getAttribute('url') ?? '#';
        const showChildrenData = getYesAttribute(this, 'showChildrenData');
        overrideLocaleFromAttribute(this);

        render(
            <AccommodationSearchWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                url={resultUrl}
                showChildrenData={showChildrenData}
            />,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-accommodation-search', BilberryAccommodationSearch);
}
