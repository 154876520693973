import { h, render } from 'preact';
import { getMediaQuerySizesFromAttributes } from 'src/utils/common/mediaQueryAttributeInputHelper';
import {
    getCsvAttribute,
    getStringAttribute,
    getYesAttribute,
} from 'src/utils/domain/attributes/attribute-helper';
import { getCsvColorsAttributes } from 'src/utils/domain/attributes/color-attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { ProductListBookable } from './ProductListBookable';

class BilberryProductListBookable extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCollectionIdAttr = getStringAttribute(this, 'product-collection-id');
        const productCollectionId = productCollectionIdAttr
            ? parseInt(productCollectionIdAttr)
            : undefined;
        const productCatalogIdsAttr =
            productCollectionId === undefined
                ? getCsvAttribute(this, 'product-catalog-ids')
                : undefined;
        const productCatalogUrls = getCsvAttribute(this, 'product-catalog-urls');
        const productCatalogIds =
            productCatalogIdsAttr && productCatalogIdsAttr?.length > 0
                ? productCatalogIdsAttr.map((x) => parseInt(x))
                : undefined;

        if (productCollectionId === undefined && productCatalogIds === undefined) {
            throw new Error(
                'You must specify either a product-collection-id or product-catalog-ids attribute',
            );
        }

        const hideReadMore = getYesAttribute(this, 'hide-read-more');
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);

        overrideLocaleFromAttribute(this);

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductListBookable
                    scroll={scroll}
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                    productCollectionId={productCollectionId}
                    productCatalogIds={productCatalogIds}
                    productCatalogUrls={productCatalogUrls}
                    hideReadMore={hideReadMore}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-product-list-bookable', BilberryProductListBookable);
}
