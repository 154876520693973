import dayjs from 'dayjs';
import first from 'lodash-es/first';
import groupBy from 'lodash-es/groupBy';
import {
    ICartAccommodationItem,
    ICartItem,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import {
    isICartAccommodationItemType,
    isICartMultiDayProductItemType,
    isICartPackageItemType,
} from '../cart/cartUtils';

export function serializeCartToQueryParams(cart: { [key: number]: ICartItem }) {
    const cartItems = Object.values(cart);
    const cartItemParams = cartItems.map(asQueryParam).join('&');
    return cartItemParams;
}

function asQueryParam(cartItem: ICartItem) {
    const item = cartItem.item;

    if (isICartAccommodationItemType(item)) return asAccommodationQueryParam(item);
    if (isICartMultiDayProductItemType(item)) return asMultiDayQueryParam(item);
    if (isICartPackageItemType(item)) return asPackageQueryParam(item);
    else return asActivityQueryParam(item);
}

function asActivityQueryParam(item: ICartProductItem) {
    const productId = `${item.product.product_catalog_id}:${item.product.id}`;
    const quantities = getQuantitiesQueryParam(item);
    const extras = getExtrasQueryParam(item);

    const date = encodeURIComponent(dayjs(item.product.start).format());
    return `activity=${[productId, quantities, extras, date].join(';')}`;
}

function asPackageQueryParam(item: ICartPackageItem) {
    const packageId = `${item.pkg.id}:${item.selectedProducts
        .map(({ product }) => product.id)
        .join(',')}`;
    const quantities = getQuantitiesQueryParam(item);
    const extras = getExtrasQueryParam(item);

    const start = encodeURIComponent(dayjs(item.start).format());
    const end = encodeURIComponent(dayjs(item.end).format());
    return `package=${[packageId, quantities, extras, start, end].join(';')}`;
}

function asAccommodationQueryParam(item: ICartAccommodationItem) {
    return `accommodation=${[
        item.accommodation.id,
        item.numberOfGuests,
        item.price.id,
        encodeURIComponent(dayjs(item.start).format()),
        encodeURIComponent(dayjs(item.end).format()),
    ].join(',')}`;
}

function asMultiDayQueryParam(item: ICartMultiDayProductItem) {
    const productIds = item.products
        .map((product) => `${product.product_catalog_id}:${product.id}`)
        .join(',');
    const quantities = getQuantitiesQueryParam(item);
    const extras = getExtrasQueryParam(item);
    return `multiActivity=${[productIds, quantities, extras, item.dateRangeVariant].join(';')}`;
}

function getQuantitiesQueryParam(
    item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem,
) {
    const _quantities = item.quantities;
    const quantitiesById = groupBy(_quantities, (quantity) => quantity.id);

    const quantities = Object.entries(quantitiesById)
        .filter(([_id, value]) => (first(value)?.quantity ?? 0) > 0)
        .map(([id, value]) => `${id}:${first(value)?.quantity}`)
        .join(',');

    return quantities;
}

function getExtrasQueryParam(item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem) {
    const _extras = item.extras;
    const extras = _extras
        .map(({ extra, quantities }) => {
            const quantitiesString = quantities
                .filter(({ quantity }) => quantity > 0)
                .map(({ id, quantity }) => `${id}:${quantity}`);
            return quantitiesString.length > 0 ? `${extra.id}:${quantitiesString.join('_')}` : '';
        })
        .filter((extra) => extra)
        .join(',');
    return extras;
}
