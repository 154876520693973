import { Box, Button, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import { ComponentChildren, Fragment } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { useLocale } from 'src/i18n/locale';
import {
    formatCurrencyNoDecimalsIfPossible,
    getLocaleNumberFormatNoDecimals,
} from 'src/i18n/Localization';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { largeButton } from 'src/utils/common/jss/ButtonStyleUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getPrimaryColors } from 'src/utils/common/theme/getPrimaryColors';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import { ProductCardReadMoreDialog } from './ProductCardReadMoreDialog';

export function ProductCardInfoContainer(props: { children: ComponentChildren }) {
    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <Grid
            color={customizations.productCardTextColor}
            position="relative"
            textAlign="center"
            padding={theme.spacing(4, 2)}
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100%"
            sx={{ backgroundColor: customizations.productCardColor }}
        >
            {props.children}
        </Grid>
    );
}

type ProductCardInfoContentProps = {
    title?: string | null;
    description?: string | null;
    fromPrice?: number | null;
    originalPrice?: number | null;
    url: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    customPriceLabel?: string | JSX.Element;
    hidePriceIcon?: boolean;
    readMoreProductId?: number;
    readMoreLinkVariant?: 'href' | 'popup' | 'none';
    icons?: ComponentChildren;
    onClick?: () => void;
    showFrom?: boolean;
};
export function ProductCardInfoContent(props: ProductCardInfoContentProps) {
    const { showFrom = true } = props;
    const { t, locale } = useLocale();

    const customizations = useCustomizations();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    const [isDisplayingMoreInfo, setIsDisplayingMoreInfo] = useState(false);

    const focusRef = useRef<HTMLAnchorElement>(null);

    const priceIsDiscounted =
        props.originalPrice && props.fromPrice && props.originalPrice > props.fromPrice;

    return (
        <Fragment>
            <Grid
                position="absolute"
                top={priceIsDiscounted ? '-2.25rem' : '-1.25rem'}
                display="flex"
                justifyContent="center"
                width="100%"
                sx={{
                    '& > div': {
                        padding: theme.spacing(1.25, 2.5),
                        backgroundColor: customizations.productCardAccentColor,
                        color: customizations.productCardAccentColorContrast,
                        borderRadius: customizations.borderRadius
                            ? `${customizations.borderRadius / 2}px`
                            : undefined,
                        '& > .MuiTypography-root': {
                            color: customizations.productCardAccentColorContrast,
                            fontSize: '0.75rem',
                        },
                    },
                }}
            >
                <Stack gap={0.5}>
                    <Typography lineHeight={1}>
                        {props.customPriceLabel ? (
                            props.customPriceLabel
                        ) : (
                            <Fragment>
                                {showFrom && t.from}{' '}
                                <Grid
                                    component="span"
                                    fontWeight={customizations.boldFontWeight}
                                    fontSize="1rem"
                                >
                                    {props.fromPrice
                                        ? formatCurrencyNoDecimalsIfPossible(
                                              locale,
                                              currency,
                                              props.fromPrice,
                                          )
                                        : '-'}
                                </Grid>
                            </Fragment>
                        )}
                    </Typography>
                    {priceIsDiscounted && (
                        <Typography fontSize={theme.typography.fontSize * 0.75} lineHeight={1}>
                            <s>{getLocaleNumberFormatNoDecimals(locale, props.originalPrice!)}</s>
                        </Typography>
                    )}
                </Stack>
            </Grid>
            <Typography variant="h2" color={customizations.productCardTextColor}>
                {props.title ?? ''}
            </Typography>
            <Box
                padding={theme.spacing(2, 0)}
                height="100%"
                sx={{
                    '& .MuiTypography-root': {
                        color: customizations.productCardTextColor,
                    },
                }}
            >
                {parseHtmlToMui(props.description ?? '')}
                {props.readMoreLinkVariant === 'popup' && (
                    <Link
                        onClick={() => {
                            setIsDisplayingMoreInfo(true);
                        }}
                        sx={{
                            display: 'block',
                            mt: 1,
                            color: `${customizations.linkColor} !important`,
                            fontFamily: theme.typography.body1.fontFamily ?? '',
                        }}
                    >
                        {t.read_more}
                    </Link>
                )}
                {props.readMoreLinkVariant === 'href' && (
                    <Link
                        href={props.url}
                        sx={{
                            display: 'block',
                            mt: 1,
                            color: `${customizations.linkColor} !important`,
                            fontFamily: theme.typography.body1.fontFamily ?? '',
                        }}
                    >
                        {t.read_more}
                    </Link>
                )}
            </Box>
            {props.icons && (
                <Grid
                    container
                    direction="row"
                    pb={theme.spacing(4)}
                    display="grid"
                    gridTemplateColumns="repeat(auto-fit, minmax(50px, 1fr))"
                    justifyContent="center"
                    maxWidth="400px"
                >
                    {props.icons}
                </Grid>
            )}
            {props.readMoreLinkVariant === 'popup' && props.readMoreProductId && (
                <ProductCardReadMoreDialog
                    focusRef={focusRef}
                    display={isDisplayingMoreInfo}
                    setDisplay={setIsDisplayingMoreInfo}
                    productId={props.readMoreProductId}
                />
            )}
        </Fragment>
    );
}

export function ProductCardButton(props: {
    onClick?: () => void;
    url?: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
}) {
    const { t, locale } = useLocale();

    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const theme = useTheme();
    return (
        <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <Button
                variant="outlined"
                color="primary"
                sx={{
                    ...largeButton(theme),
                    ...getPrimaryColors(
                        customizations.productCardAccentColor,
                        customizations.productCardAccentColorContrast,
                        customizations.productCardColor,
                        true,
                        false,
                    ),
                }}
                onClick={props.onClick}
                href={props.url}
            >
                {getCustomOrDefaultText(
                    configurations.textCustomizations,
                    props.buttonCustomizationKey,
                    locale,
                    t.read_more_and_book,
                )}
            </Button>
        </Grid>
    );
}

export function ProductCardInfo(props: ProductCardInfoContentProps): JSX.Element {
    const { url } = props;

    return (
        <ProductCardInfoContainer>
            <ProductCardInfoContent {...props} />
            <ProductCardButton
                onClick={props.onClick}
                url={url}
                buttonCustomizationKey={props.buttonCustomizationKey}
            />
        </ProductCardInfoContainer>
    );
}
