import { Breakpoint } from '@mui/material/styles';
import { CustomBreakpointValues, ValueOrCustomBreakpointValues } from './theme/ThemeType';

export function ensurePixelValue(value: string | number | undefined) {
    if (typeof value === 'number') return value + 'px';

    return value;
}

export function findValueOrCustomBreakpointValue(
    value: ValueOrCustomBreakpointValues | undefined,
    defaultValue: string | number | undefined,
    breakpoint?: Breakpoint,
) {
    if (value === undefined) return defaultValue;

    const isCustomBreakpointValues = typeof value === 'object';

    if (breakpoint) {
        if (!isCustomBreakpointValues) return defaultValue;
        return (value as CustomBreakpointValues<string | number>)[breakpoint] ?? defaultValue;
    }

    return !isCustomBreakpointValues ? (value as string | number) : defaultValue;
}
