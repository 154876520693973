import groupBy from 'lodash-es/groupBy';
import isObject from 'lodash-es/isObject';
import mapValues from 'lodash-es/mapValues';
import sumBy from 'lodash-es/sumBy';
import { localeAtom } from 'src/i18n/locale';
import {
    BilberryPriceQuantity,
    ICartExtra,
    ICartItem,
    ICartMultiDayProductItem,
} from 'src/state/cart/ICart';
import { BilberryExtra, BilberryProduct } from 'src/types/bilberry-api-types';
import { showError } from 'src/utils/common/error-handling';
import { getBilberryProductsByIds, getExtrasByIds } from '../../api/bilberry-api-client';
import { isCartItemGuard } from '../../cart/cartUtils';
import { configurationAtom } from '../../widgetsConfiguration';
import {
    asPriceQuantities,
    assignQuantityToExtraProduct,
    MinimalMultiDayProductCartItem,
    parseExtraParams,
} from './common';

const { t } = localeAtom.subject.value;

export async function deserializeMultiActivities(params: string[]) {
    if (params.length === 0) return;
    const minimalCartItems = params.map(asMinimalMultiDayProductCartItem);

    const minimalProducts = minimalCartItems.flatMap((item) => item.products);
    const productIds = minimalProducts.map(({ productId }) => productId);

    const [bilberryProducts, extraProducts] = await Promise.all([
        getBilberryProductsByIds(
            productIds,
            localeAtom.subject.value.locale,
            configurationAtom.subject.value,
        ),
        getExtrasByIds(
            productIds,
            localeAtom.subject.value.locale,
            configurationAtom.subject.value,
        ),
    ]);

    if (!hasCapacity(bilberryProducts, minimalCartItems)) {
        showError(t.thereIsNotEnoughCapacityForTheItemsInThisCart);
        return;
    }

    const products = groupBy(bilberryProducts, 'product_catalog_id');
    const priceQuantities = mapValues<
        { [x: number]: BilberryProduct[] },
        { id: number; quantities: BilberryPriceQuantity[] }[]
    >(products, (productGroup: BilberryProduct[]) =>
        productGroup.map((product) =>
            asPriceQuantities(
                product,
                getQuantityFromMinimalMultiDayProductCartItems(
                    minimalCartItems,
                    String(product.id),
                ),
            ),
        ),
    );

    const extrasGroups = groupBy(extraProducts, 'tours');

    const extras: Record<string, ICartExtra[]> = mapValues(extrasGroups, (group: BilberryExtra[]) =>
        group
            .flatMap((extra) => assignQuantityToExtraProduct(minimalCartItems, extra))
            .filter(isObject),
    ) as unknown as Record<string, ICartExtra[]>;

    const cartItemList = minimalCartItems.map((item) => {
        const currentProducts = item.products
            .map((product) =>
                products[product.productCatalogId].find(
                    ({ id }) => String(id) === product.productId,
                ),
            )
            .filter(isObject) as BilberryProduct[];

        const quantities = priceQuantities[Number(item.products[0].productCatalogId)]
            .filter(({ id }) => item.products.find(({ productId }) => productId === String(id)))
            .flatMap((filtered) => filtered?.quantities);

        const currentExtras = item.products
            .flatMap((product) => extras[product.productId])
            .filter(isObject);

        if (!products || !quantities) return null;

        const dateRangeVariant = (
            ['days', 'nights'].includes(item.dateRangeVariant) ? item.dateRangeVariant : 'nights'
        ) as ICartMultiDayProductItem['dateRangeVariant'];

        const productItem: ICartMultiDayProductItem = {
            products: currentProducts,
            quantities,
            extras: currentExtras,
            dateRangeVariant,
        };

        return {
            item: productItem,
        };
    });

    const cartItems = cartItemList.reduce((acc, cartItem) => {
        const key = cartItem?.item.products.map(({ id }) => id).join('-');
        return { ...acc, [`multi-day-product-${key}`]: cartItem! };
    }, {} as Record<string, ICartItem>);

    if (isCartItemGuard(cartItems)) {
        return cartItems;
    }
}

function getQuantityFromMinimalMultiDayProductCartItems(
    items: MinimalMultiDayProductCartItem[],
    productId: string,
) {
    return (
        items.find((item) => item.products.find((x) => x.productId === productId))?.quantities ?? []
    );
}

function asMinimalMultiDayProductCartItem(cartItemString: string) {
    const [productParams, quantitiesParam, extrasParam, dateRangeVariant] =
        cartItemString.split(';');
    const products = productParams.split(',').map((x) => x.split(':'));
    const quantities = quantitiesParam.split(',').map((quantity) => quantity.split(':'));
    const extras = parseExtraParams(extrasParam);

    return {
        products: products.map(([productCatalogId, productId]) => ({
            productCatalogId,
            productId,
        })),
        quantities: quantities.map(([id, quantity]) => ({
            id,
            quantity,
        })),
        extras,
        dateRangeVariant,
    };
}

function hasCapacity(products: BilberryProduct[], items: MinimalMultiDayProductCartItem[]) {
    return products.every((product) => {
        const currentItem = items.find((item) =>
            item.products.find((x) => x.productId === String(product.id)),
        );
        const totalQuantities = sumBy(currentItem?.quantities ?? [], ({ quantity }) =>
            Number(quantity),
        );

        return product.capacity >= totalQuantities;
    });
}
