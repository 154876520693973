import { darken, getContrastRatio } from '@mui/material/styles';
import { alpha } from '@mui/material';

export function getPrimaryColors(
    primary: string,
    contrast: string,
    bg: string,
    focusable = false,
    background = true,
) {
    const ratioPrimary = getContrastRatio(primary, bg);
    const ratioPrimaryContrast = getContrastRatio(contrast, bg);

    const backgroundColor =
        ratioPrimary > ratioPrimaryContrast || ratioPrimary >= 4.5 ? primary : contrast;
    let color = ratioPrimary > ratioPrimaryContrast || ratioPrimary >= 4.5 ? contrast : primary;

    if (!background) {
        color = backgroundColor;
    }

    return {
        color,
        ...(background ? { backgroundColor } : {}),
        ...(focusable
            ? {
                  borderColor:
                      getContrastRatio(color, bg) >= 4.5 || !background ? color : 'transparent',
                  '&:hover': {
                      '@media (hover: hover)': {
                          backgroundColor: background
                              ? darken(backgroundColor, 0.2)
                              : alpha(backgroundColor, 0.2),
                          color,
                          borderColor:
                              getContrastRatio(color, bg) >= 4.5 || !background
                                  ? color
                                  : 'transparent',
                      },
                  },
                  '&:focus,&:active': {
                      backgroundColor: background
                          ? darken(backgroundColor, 0.2)
                          : alpha(backgroundColor, 0.2),
                      color,
                      borderColor:
                          getContrastRatio(color, bg) >= 4.5 || !background ? color : 'transparent',
                  },
              }
            : {}),
    };
}
