import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useLocale } from 'src/i18n/locale';
import {
    AccommodationCheckoutInfo,
    ActivityCheckoutInfo,
    CheckoutInfoData,
    ContactPerson,
    MultiDayCheckoutInfo,
    PackageCheckoutInfo,
} from 'src/types/checkout-info';
import { capitalize } from 'src/utils/common/TextUtils';
import {
    getCartMultiDayProductTypeAndId,
    getCartPackageTypeAndId,
    getCartProductTypeAndId,
} from 'src/utils/domain/cart/cartUtils';
import { getUpdatedCheckoutInfoByActivity } from 'src/utils/domain/checkout-info-helper';
import { CheckoutInfoForAccommodations } from './CheckoutInfoForAccommodations';
import CheckoutInfoQuestionares from './CheckoutInfoForActivity';
import ContactPersonForm from './ContactPersonForm';

type Props = {
    showErrors: boolean;
    checkoutInfoData: CheckoutInfoData;
    onChange(checkoutInfoData: CheckoutInfoData): void;
    showAddressFields: boolean;
};

export default function CheckoutInfo(props: Props): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();

    return (
        <Stack
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            pt={0}
            minWidth="300px"
            width="100%"
            maxWidth="440px"
            gap={1}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& > *': {
                        marginTop: theme.spacing(1),
                    },
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: theme.spacing(2),
                }}
                variant="outlined"
            >
                <Typography color="secondary" variant="h6" mt={0}>
                    {capitalize(t.main_contact)}
                </Typography>

                <ContactPersonForm
                    contactPerson={props.checkoutInfoData.contactPerson}
                    showErrors={props.showErrors}
                    onChange={(newContactPerson) =>
                        updateContactPerson(
                            newContactPerson,
                            props.checkoutInfoData,
                            props.onChange,
                        )
                    }
                    showAddressFields={props.showAddressFields}
                />
            </Paper>

            {Object.entries(props.checkoutInfoData.checkoutInfoByActivity).map(
                ([key, activityCheckoutInfo]) => (
                    <CheckoutInfoQuestionares
                        key={key}
                        checkoutInfo={activityCheckoutInfo}
                        showErrors={props.showErrors}
                        onChange={(checkoutInfo: ActivityCheckoutInfo) =>
                            checkoutInfoForActivityOnChange(
                                checkoutInfo,
                                props.checkoutInfoData,
                                props.onChange,
                            )
                        }
                        contactPerson={props.checkoutInfoData.contactPerson}
                    />
                ),
            )}
            {Object.entries(props.checkoutInfoData.checkoutInfoByPackage).map(
                ([key, packageCheckoutInfo]) => (
                    <CheckoutInfoQuestionares
                        key={key}
                        checkoutInfo={packageCheckoutInfo}
                        showErrors={props.showErrors}
                        onChange={(checkoutInfo: PackageCheckoutInfo) =>
                            checkoutInfoForPackageOnChange(
                                checkoutInfo,
                                props.checkoutInfoData,
                                props.onChange,
                            )
                        }
                        contactPerson={props.checkoutInfoData.contactPerson}
                    />
                ),
            )}
            {Object.entries(props.checkoutInfoData.checkoutInfoByMultiDayActivity).map(
                ([key, multiDayCheckoutInfo]) => (
                    <CheckoutInfoQuestionares
                        key={key}
                        checkoutInfo={multiDayCheckoutInfo}
                        showErrors={props.showErrors}
                        onChange={(checkoutInfo: MultiDayCheckoutInfo) =>
                            checkoutInfoForMultiDayOnChange(
                                checkoutInfo,
                                props.checkoutInfoData,
                                props.onChange,
                            )
                        }
                        contactPerson={props.checkoutInfoData.contactPerson}
                    />
                ),
            )}
            {props.checkoutInfoData.accommodationCheckoutInfo?.length > 0 && (
                <CheckoutInfoForAccommodations
                    accommodationsCheckoutInfo={props.checkoutInfoData.accommodationCheckoutInfo}
                    onChange={(activityCheckoutInfo) =>
                        checkoutInfoForAccommodationsOnChange(
                            activityCheckoutInfo,
                            props.checkoutInfoData,
                            props.onChange,
                        )
                    }
                    showErrors={props.showErrors}
                />
            )}
        </Stack>
    );
}

function checkoutInfoForAccommodationsOnChange(
    accommodationCheckoutInfo: AccommodationCheckoutInfo[],
    checkoutInfoData: CheckoutInfoData,
    onChange: (checkoutInfoData: CheckoutInfoData) => void,
) {
    const updated: CheckoutInfoData = {
        ...checkoutInfoData,
        accommodationCheckoutInfo,
    };

    onChange(updated);
}

function checkoutInfoForActivityOnChange(
    activityCheckoutInfo: ActivityCheckoutInfo,
    checkoutInfoData: CheckoutInfoData,
    onChange: (checkoutInfoData: CheckoutInfoData) => void,
) {
    const updated: CheckoutInfoData = {
        ...checkoutInfoData,
        checkoutInfoByActivity: {
            ...checkoutInfoData.checkoutInfoByActivity,
            [getCartProductTypeAndId(activityCheckoutInfo.cartItem)]: activityCheckoutInfo,
        },
    };

    onChange(updated);
}

function updateContactPerson(
    updatedContactPerson: ContactPerson,
    checkoutInfoData: CheckoutInfoData,
    onChange: (checkoutInfoData: CheckoutInfoData) => void,
) {
    const updatedCheckoutInfoByActivity = getUpdatedCheckoutInfoByActivity(
        checkoutInfoData.checkoutInfoByActivity,
        updatedContactPerson,
    );

    const updated: CheckoutInfoData = {
        ...checkoutInfoData,
        contactPerson: updatedContactPerson,
        checkoutInfoByActivity: updatedCheckoutInfoByActivity,
    };

    onChange(updated);
}

function checkoutInfoForMultiDayOnChange(
    multiDayCheckoutInfo: MultiDayCheckoutInfo,
    checkoutInfoData: CheckoutInfoData,
    onChange: (checkoutInfoData: CheckoutInfoData) => void,
) {
    const updated: CheckoutInfoData = {
        ...checkoutInfoData,
        checkoutInfoByMultiDayActivity: {
            ...checkoutInfoData.checkoutInfoByMultiDayActivity,
            [getCartMultiDayProductTypeAndId(multiDayCheckoutInfo.cartItem)]: multiDayCheckoutInfo,
        },
    };

    onChange(updated);
}

function checkoutInfoForPackageOnChange(
    packageCheckoutInfo: PackageCheckoutInfo,
    checkoutInfoData: CheckoutInfoData,
    onChange: (checkoutInfoData: CheckoutInfoData) => void,
) {
    const updated: CheckoutInfoData = {
        ...checkoutInfoData,
        checkoutInfoByPackage: {
            ...checkoutInfoData.checkoutInfoByPackage,
            [getCartPackageTypeAndId(packageCheckoutInfo.cartItem)]: packageCheckoutInfo,
        },
    };

    onChange(updated);
}
