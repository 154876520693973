import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { createRef, h, RefObject } from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import AccommodationImage from 'src/components/domain/accommodation-image/AccommodationImage';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { localeAtom, useLocale } from 'src/i18n/locale';
import {
    formatCurrencyNoDecimalsIfPossible,
    getLocaleNumberFormatNoDecimals,
} from 'src/i18n/Localization';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { createAddAccommodationToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import {
    AccommodationPrice,
    BilberryAccommodation,
    PricedAccommodation,
} from 'src/types/bilberry-hotels-api-types';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { findImageAttribute } from 'src/utils/domain/accommodations/accommodationUtils';
import { configurationAtom, useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import AccommodationOverviewFacilityIcons from '../accommodation-overview/subcomponents/AccommodationOverviewFacilityIcons';
import AccommodationOverviewModal from './accommodation-overview-modal/AccommodationOverviewModal';

interface IProps {
    accommodation: BilberryAccommodation;
    numberPersons: number;
    nights: number;
    url?: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    bookDirectly: boolean;
    numberOfCards: number;
    onSelectRoom: (selectedAccommodation: PricedAccommodation) => void;
    dateRange: DateRange<Dayjs>;
    isUnavailable: boolean;
    selected?: boolean;
    selectedPrice: AccommodationPrice;
    setSelectedPrice?: (price: AccommodationPrice) => void;
}

export default function AccommodationCard(props: IProps): JSX.Element {
    const { t } = useLocale();
    const configurations = useConfigurations();
    const customizations = useCustomizations();
    const {
        accommodation,
        bookDirectly,
        onSelectRoom,
        numberPersons,
        selected = false,
        isUnavailable,
        setSelectedPrice,
        dateRange,
        selectedPrice,
        nights,
    } = props;
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const theme = useTheme();
    const [visible, setVisible] = useState(false);
    const popoverRef = createRef<HTMLDivElement>();
    const refocusElement = createRef<HTMLInputElement>();
    const trap = useFocusTrap(popoverRef, visible, refocusElement);

    const change = (price: AccommodationPrice) => {
        if (trap) trap.deactivate();
        setVisible(false);
        setSelectedPrice?.(price);
    };

    const boxRef = createRef<HTMLDivElement>();
    const shouldShowBasketOnBook = !configurations.skipBasketOnBook;

    const accommodationImage = findImageAttribute(accommodation);

    function handleOpenInfoOverlay() {
        setIsOverlayOpen(true);
    }

    function handleCloseInfoOverlay() {
        setIsOverlayOpen(false);
    }

    return (
        <Grid
            container
            direction="column"
            borderRadius={`${customizations.borderRadius}px`}
            sx={[
                {
                    boxSizing: 'border-box',
                    position: 'relative',
                    height: '100%',
                    width: 'auto',
                    border: `1px solid`,
                    borderColor: theme.palette.grey[400],
                    color: theme.palette.common.black,
                    maxWidth: 420,
                },
                selected && {
                    borderColor: customizations.primaryColor,
                    borderWidth: '2px',
                    border: 'solid',
                },
            ]}
            wrap="nowrap"
        >
            <Box sx={{ position: 'relative', height: '230px', margin: theme.spacing(0) }}>
                <AccommodationImage url={accommodationImage} faded={isUnavailable} />
            </Box>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                padding={theme.spacing(2.5)}
                bgcolor={theme.palette.grey[50]}
                flexGrow={1}
                borderRadius={`${customizations.borderRadius}px`}
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
            >
                <Typography align="center" variant="h4" mb={theme.spacing(1)}>
                    <Box fontWeight="bold">{accommodation.name}</Box>
                </Typography>
                <Typography
                    align="center"
                    sx={{
                        paddingBottom: theme.spacing(2.5),
                        flexGrow: 1,
                        overflow: 'hidden',
                        // get ellipsis when text extends 3 lines
                        display: '-webkit-box !important',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    <Box>
                        {parseHtmlToMui(
                            accommodation.attributes?.descriptions.shortDescriptionHtml?.value ??
                                accommodation.attributes?.descriptions.shortDescription ??
                                '',
                        )}
                    </Box>
                </Typography>
                <AccommodationOverviewFacilityIcons
                    accommodation={accommodation}
                    numberOfIcons={3}
                />
                {!isUnavailable || selected ? (
                    accommodation.prices.length > 1 ? (
                        <PriceSelection
                            accommodation={accommodation}
                            change={change}
                            selectedPrice={selectedPrice}
                            numberPersons={numberPersons}
                            nights={nights}
                        />
                    ) : (
                        <PriceInfo
                            selectedPrice={selectedPrice}
                            numberPersons={numberPersons}
                            nights={nights}
                        />
                    )
                ) : (
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        position="relative"
                        mt={theme.spacing(2)}
                    >
                        <Typography variant="h4" color="error">
                            {t.unavailable}
                        </Typography>
                        <Typography color="error">This room is sold out!</Typography>
                    </Grid>
                )}

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="stretch"
                    wrap="wrap"
                    position="relative"
                    mt={theme.spacing(2)}
                    sx={{ gap: 1 }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            paddingLeft: 0,
                            paddingRight: 0,
                            color: theme.palette.getContrastText('#FFF'),
                            textDecoration: 'underline',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            width: 'auto',
                            flexGrow: '1',
                        }}
                        onClick={handleOpenInfoOverlay}
                    >
                        {t.room_info.toUpperCase()}
                    </Button>
                    {(!isUnavailable || selected) && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                paddingTop: theme.spacing(1),
                                paddingBottom: theme.spacing(1),
                                paddingLeft: theme.spacing(5),
                                paddingRight: theme.spacing(5),
                                width: 'auto',
                                flexGrow: '1',
                            }}
                            onClick={() =>
                                handleCardSelected(
                                    bookDirectly,
                                    accommodation,
                                    props.selectedPrice,
                                    numberPersons,
                                    props.dateRange,
                                    shouldShowBasketOnBook,
                                    boxRef,
                                    onSelectRoom,
                                )
                            }
                        >
                            {getButtonText(bookDirectly, props.buttonCustomizationKey)}
                        </Button>
                    )}
                </Grid>
            </Grid>
            {isOverlayOpen && (
                <AccommodationOverviewModal
                    accommodation={accommodation}
                    onCloseRoomInfoOverlay={handleCloseInfoOverlay}
                    dateRange={dateRange}
                    guests={numberPersons}
                />
            )}
        </Grid>
    );
}

function getButtonText(
    bookDirectly: boolean,
    buttonCustomizationKey?: keyof UserTextCustomizations,
) {
    const { t, locale } = localeAtom.subject.value;
    const configurations = configurationAtom.subject.value;

    if (bookDirectly) {
        return getCustomOrDefaultText(
            configurations.textCustomizations,
            buttonCustomizationKey,
            locale,
            t.book_now,
        ).toUpperCase();
    } else {
        return getCustomOrDefaultText(
            configurations.textCustomizations,
            buttonCustomizationKey,
            locale,
            t.select_room,
        ).toUpperCase();
    }
}

function handleCardSelected(
    bookDirectly: boolean,
    accommodation: BilberryAccommodation,
    selectedPrice: AccommodationPrice,
    numberPersons: number,
    dateRange: DateRange<Dayjs>,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onSelectRoom: (selectedAccommodation: PricedAccommodation) => void,
) {
    const configurations = configurationAtom.subject.value;
    if (bookDirectly) {
        cartAtom.update(
            createAddAccommodationToCartEvent(
                accommodation,
                selectedPrice,
                numberPersons,
                dateRange[0]!.toDate(),
                dateRange[1]!.toDate(),
            ),
        );

        if (shouldShowBasketOnBook)
            showBasketAtom.update({
                visible: true,
                refocusElementOnClose: boxRef,
            });
        else {
            route('/checkout');
        }
    } else {
        const selectedAccommodation = {
            accommodation,
            price: selectedPrice,
        } as PricedAccommodation;
        onSelectRoom(selectedAccommodation);
    }
}

type IPriceSelectionProp = {
    accommodation: BilberryAccommodation;
    change: (price: AccommodationPrice) => void;
    selectedPrice: AccommodationPrice;
    numberPersons: number;
    nights: number;
};

function PriceSelection(props: IPriceSelectionProp): JSX.Element {
    const { accommodation, change } = props;
    const customizations = useCustomizations();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    return (
        <Box position="relative" mt={theme.spacing(2)}>
            <Grid container item justifyContent="space-between" wrap="nowrap" sx={{ gap: 1 }}>
                <Dropdown
                    sx={{ flexGrow: '1' }}
                    options={accommodation.prices ?? []}
                    getOptionLabel={(p) => [
                        <span>{p.name}</span>,
                        <Box component="span" pl={2} sx={{ whiteSpace: 'nowrap' }}>
                            {formatCurrencyNoDecimalsIfPossible(locale, currency, p.value)}
                        </Box>,
                    ]}
                    getTextFieldLabel={(p) => `${p.name} `}
                    onChange={change}
                    value={props.selectedPrice ?? null}
                    labelColor={customizations.productCardTextColor}
                    label={t.please_select}
                    adornmentDown={<ArrowDropDown />}
                    adornmentUp={<ArrowDropUp />}
                />
                <Grid
                    container
                    display="inline-flex"
                    wrap="nowrap"
                    direction="column"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    sx={{ width: 'auto' }}
                >
                    <Typography
                        color={customizations.productCardTextColor}
                        pt={0.5}
                        noWrap
                        sx={{ fontSize: '13px' }}
                    >
                        {capitalize(
                            t.one_night_one_guest.parsed(props.nights, props.numberPersons),
                        )}
                    </Typography>
                    <Grid
                        container
                        display="inline-flex"
                        wrap="nowrap"
                        direction="row"
                        alignItems="flex-end"
                        sx={{ width: 'auto' }}
                    >
                        <Typography
                            variant="h4"
                            color="textSecondary"
                            fontSize={28}
                            fontWeight={700}
                            mb={-1} // alignment fix because of different sizes. Might break with different fonts, but seems ok on open sans and helvetica
                        >
                            {getLocaleNumberFormatNoDecimals(
                                locale,
                                props.selectedPrice?.value ?? 0,
                            )}
                        </Typography>
                        <Typography variant="h6">&nbsp;{currency.currency}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

type IPriceInfoProp = {
    selectedPrice: AccommodationPrice;
    numberPersons: number;
    nights: number;
};

function PriceInfo(props: IPriceInfoProp): JSX.Element {
    const customizations = useCustomizations();
    const { t, locale } = useLocale();
    const theme = useTheme();
    const [currency] = useAtom(currencyAtom);

    return (
        <Grid
            container
            item
            justifyContent="space-between"
            wrap="nowrap"
            position="relative"
            mt={theme.spacing(2)}
            sx={{ gap: 1, height: '69px' }}
        >
            <Grid
                container
                display="inline-flex"
                wrap="nowrap"
                direction="row"
                alignItems="flex-end"
                sx={{ width: 'auto' }}
                pb={1}
            >
                <Typography color={customizations.productCardTextColor}>
                    {capitalize(
                        t.price_one_night_one_guest.parsed(props.nights, props.numberPersons),
                    )}
                </Typography>
            </Grid>

            <Grid
                container
                display="inline-flex"
                wrap="nowrap"
                direction="row"
                alignItems="flex-end"
                sx={{ width: 'auto' }}
                pb={1}
            >
                <Typography
                    variant="h4"
                    color="textSecondary"
                    fontSize={28}
                    fontWeight={700}
                    mb={-1} // alignment fix because of different sizes. Might break with different fonts, but seems ok on open sans and helvetica
                >
                    {getLocaleNumberFormatNoDecimals(locale, props.selectedPrice?.value ?? 0)}
                </Typography>
                <Typography variant="h6">&nbsp;{currency.currency}</Typography>
            </Grid>
        </Grid>
    );
}
