import PrintRounded from '@mui/icons-material/PrintRounded';
import SendRounded from '@mui/icons-material/SendRounded';
import { Box, capitalize, FormLabel, RadioGroup, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import GiftTypeSelectionBody from './gift-type-selection-body/GiftTypeSelectionBody';
import GiftTypeSelectionEntry from './GiftTypeSelectionEntry';

interface Props {
    selectedGiftType: GiftTypeEnum | null;
    setSelectedGiftType: (arg: GiftTypeEnum | null) => void;
}

export enum GiftTypeEnum {
    Print = 'Print',
    Email = 'Email',
}

export default function GiftTypeSelections(props: Props): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();
    const printIconStyles =
        props.selectedGiftType === GiftTypeEnum.Print
            ? { color: customizations.secondaryColorContrast }
            : {};
    const emailIconStyles =
        props.selectedGiftType === GiftTypeEnum.Email
            ? { color: customizations.secondaryColorContrast }
            : {};

    function setSelected(type: GiftTypeEnum) {
        props.selectedGiftType === type
            ? props.setSelectedGiftType(null)
            : props.setSelectedGiftType(type);
    }

    return (
        <Box mt={theme.spacing(4)}>
            <FormLabel>
                <Typography color="textSecondary" variant="h6">
                    <Box fontWeight="bold">{t.select_gift_type.toUpperCase()}</Box>
                </Typography>
            </FormLabel>
            <RadioGroup sx={{ marginTop: theme.spacing(2) }}>
                <GiftTypeSelectionEntry
                    icon={<PrintRounded fontSize="large" sx={printIconStyles} />}
                    iconText={t.print}
                    description={capitalize(t.select_gift_print_description)}
                    expandedContent={<GiftTypeSelectionBody />}
                    isSelected={props.selectedGiftType === GiftTypeEnum.Print}
                    onSelect={() => setSelected(GiftTypeEnum.Print)}
                />
                <GiftTypeSelectionEntry
                    icon={<SendRounded fontSize="large" sx={emailIconStyles} />}
                    iconText={t.email}
                    description={capitalize(t.select_gift_email_description)}
                    expandedContent={<GiftTypeSelectionBody />}
                    isSelected={props.selectedGiftType === GiftTypeEnum.Email}
                    onSelect={() => setSelected(GiftTypeEnum.Email)}
                />
            </RadioGroup>
        </Box>
    );
}
