import { GuestInfo } from 'src/components/domain/MultipleRoomSearch/guestInfo';
import useSWR from 'swr';
import { getUrlWithParams, fetcher, post } from './api-client-common';

import {
    BilberryAccommodation,
    BilberryAccommodationReservationRequest,
    BilberryPaginationAccommodation,
} from 'src/types/bilberry-hotels-api-types';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useMemo } from 'preact/hooks';
import { BASE_SERVICE_URL, USE_SERVICE_PROXY } from 'src/__autogen/env';
import { BilberryWidgetsGlobalType } from '../widgetsConfiguration';

const accommodationsProxyPath = '/api/v2/accommodations/proxy';

function getApiBaseUrl(config: BilberryWidgetsGlobalType) {
    const baseUrlFromGlobal = config.bilberryBaseApiUrl;
    if (baseUrlFromGlobal) return baseUrlFromGlobal + accommodationsProxyPath;

    // Needed for local development through node-proxy
    return USE_SERVICE_PROXY ? accommodationsProxyPath : BASE_SERVICE_URL + accommodationsProxyPath;
}

function getDefaultHotelHeaders(locale: string, config: BilberryWidgetsGlobalType) {
    return new Headers({
        'Content-Type': 'text/json',
        'Accept-Language': locale ?? 'en',
        Authorization: `Bearer ${config.bilberryAccessToken}`,
    });
}

function getDefaultHotelRequest(
    subdirectory: string,
    locale: string,
    config: BilberryWidgetsGlobalType,
    queryParams: Record<string, any> = {},
) {
    const endpoint = `${getApiBaseUrl(config)}${subdirectory}`;
    return {
        url: getUrlWithParams(endpoint, locale, queryParams),
        headers: getDefaultHotelHeaders(locale, config),
    };
}

export function useAccommodation(
    id: string,
    dateRange: DateRange<Dayjs>,
    guests: number,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const queryParams = {
        ...dateParams(dateRange),
        guests,
        units: 1,
    };

    const { url, headers } = getDefaultHotelRequest(
        `/accommodations/${id}`,
        locale,
        config,
        queryParams,
    );

    const { data, error, isLoading } = useSWR(url, (url) => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        keepPreviousData: true,
    });

    return {
        accommodation: data as BilberryAccommodation,
        isError: !!error,
        isLoading: isLoading,
    };
}

const multiFetcher = async (locale: string, config: BilberryWidgetsGlobalType, urls: string[]) => {
    const fetchers = urls.map((url) => fetcher(url, getDefaultHotelHeaders(locale, config)));
    const res = await Promise.all(fetchers);
    return res;
};

export function useAccommodations(
    dateRange: DateRange<Dayjs>,
    accommodationsInfo: GuestInfo[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const urls = useMemo(
        () =>
            accommodationsInfo.map((accommodation: GuestInfo) => {
                const guestCount = accommodation.adults + accommodation.children.length;

                const queryParams = {
                    ...dateParams(dateRange),
                    guests: guestCount,
                    units: 1,
                };

                const { url } = getDefaultHotelRequest(
                    `/accommodations`,
                    locale,
                    config,
                    queryParams,
                );
                return url;
            }),
        [dateRange, accommodationsInfo, locale],
    );

    const { data, error } = useSWR(urls, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        fetcher: async () => await multiFetcher(locale, config, urls),
    });

    return {
        pages: data as BilberryPaginationAccommodation[],
        isError: !!error,
        isLoading: !error && !data,
    };
}

export function useAccommodationAvailabilities(
    id: string,
    dateRange: DateRange<Dayjs>,
    guests: number,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const queryParams = {
        ...dateParams(dateRange),
        guests,
        units: 1,
    };

    const { url, headers } = getDefaultHotelRequest(
        `/accommodations/${id}/availabilities`,
        locale,
        config,
        queryParams,
    );

    const { data, error } = useSWR(url, (url) => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    return {
        availabilities: data as Record<string, BilberryAccommodation>,
        isError: !!error,
        isLoading: !error && !data,
    };
}

function dateParams(dateRange: DateRange<Dayjs>) {
    const startDate = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : '';
    const endDate = () => {
        if (dateRange[0] && !dateRange[1]) {
            return dateRange[0].add(1, 'days').format('YYYY-MM-DD');
        } else if (dateRange[1]) {
            return dateRange[1].format('YYYY-MM-DD');
        } else {
            return '';
        }
    };

    return {
        from: startDate,
        to: endDate(),
    };
}

export async function createAccommodationReservation(
    reservation: BilberryAccommodationReservationRequest,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getDefaultHotelRequest('/reservations', locale, config);

    const response = await post(url, headers, reservation);
    return response as {
        id: string;
        accommodations: { accommodationId: string; guests: number; notes: string[] }[];
        from: string;
        to: string;
    };
}

export async function getAccommodationById(
    id: string,
    dateRange: DateRange<Dayjs>,
    guests: number,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const queryParams = {
        ...dateParams(dateRange),
        guests,
        units: 1,
    };

    const { url, headers } = getDefaultHotelRequest(
        `/accommodations/${id}`,
        locale,
        config,
        queryParams,
    );

    const response = await fetch(url, { headers });
    const data = await response.json();
    return data as BilberryAccommodation;
}
