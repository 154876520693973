import { ICartItem } from 'src/state/cart/ICart';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { isICartProductItemType } from 'src/utils/domain/cart/cartUtils';
import { getPriceSummaryFromPriceQuantities } from 'src/utils/domain/price-helper';

export function calculateActivityPromoCodePriceReduction(
    cartItems: ICartItem[],
    promoCodeStatus?: BilberryPromoCodeStatus,
) {
    const productCartItems = cartItems.map((x) => x.item).filter(isICartProductItemType);

    const promoCodeValue = promoCodeStatus?.coupon_value ?? 0;
    const applicableProductIds = promoCodeStatus?.products ?? [];
    const applicableCartItems =
        applicableProductIds.length === 0
            ? productCartItems
            : productCartItems.filter((c) =>
                  applicableProductIds.find((id) => c.product.product_catalog_id === id),
              );
    const isApplicable = applicableCartItems.length > 0;

    let priceReduction = isApplicable ? promoCodeValue : 0;
    if (promoCodeStatus?.coupon_type === 'percent') {
        const priceQuantities = [
            applicableCartItems.map((item) => item.quantities),
            applicableCartItems.map((item) => item.extras.map((extra) => extra.quantities)),
        ].flat(3);

        const priceSummary = getPriceSummaryFromPriceQuantities(priceQuantities);
        priceReduction = (promoCodeStatus.coupon_value / 100) * priceSummary.totalPrice;
    }

    return priceReduction;
}
