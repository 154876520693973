import Person from '@mui/icons-material/Person';
import {
    Box,
    Button,
    darken,
    debounce,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Paper,
    Popover,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { createRef, Fragment, Ref, RefObject } from 'preact';
import { StateUpdater, useCallback, useContext, useEffect, useMemo, useRef } from 'preact/hooks';
import QuantityPicker from 'src/components/common/quantity-picker/QuantityPicker';
import {
    useControlledVisibilityState,
    VisibilityControllable,
} from 'src/hooks/common/ui/visibility-control';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from 'src/i18n/locale';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { MountPointContext } from 'src/utils/common/mountPoint';
import { capitalize, deselectTextInput } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getPrimaryColors } from 'src/utils/common/theme/getPrimaryColors';

export type MultipleNumberInputValueType = {
    name: string | ((num: number) => string);
    value: number;
    id: number;
    subText?: string;
    disabled?: boolean;
    disabledText?: string;
};

type IProps = VisibilityControllable & {
    values: MultipleNumberInputValueType[];
    defaultValues: MultipleNumberInputValueType[];
    onChange(id: number, value: number): void;
    message?: string;
    enablePopoverBorder?: boolean;
    labelColor?: string;
    backgroundColor?: string;
    color?: string;
    variant?: 'outlined' | 'filled';
    minEntrants?: number;
    maxEntrants?: number;
    labelText?: string;
    onConfirm?: () => void;
};

export default function MultipleNumberInput(props: IProps): JSX.Element {
    const {
        values,
        defaultValues,
        onChange,
        message,
        enablePopoverBorder = false,
        labelColor,
        minEntrants = 1,
        maxEntrants,
        labelText = undefined,
        backgroundColor,
        color,
        variant,
        onConfirm,
    } = props;

    const [visible, setVisible] = useControlledVisibilityState(props);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const popoverRef = createRef<HTMLDivElement>();
    const refocusElement = createRef<HTMLInputElement>();
    const anchorRef = createRef<HTMLDivElement>();
    const trap = useFocusTrap(popoverRef, visible, refocusElement);

    const numberOfParticipants = values.reduce((sum, val) => sum + val.value, 0);

    useEffect(() => {
        if (visible && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [visible]);

    return (
        <Box position="relative">
            <MultipleNumberInputField
                labelColor={labelColor}
                labelText={labelText}
                refocusElement={refocusElement}
                visible={visible}
                setVisible={setVisible}
                values={values}
                anchorRef={anchorRef}
                backgroundColor={backgroundColor}
                color={color}
                variant={variant}
            ></MultipleNumberInputField>
            <MultipleNumberInputCollapseContent
                visible={visible}
                setVisible={setVisible}
                values={values}
                enablePopoverBorder={enablePopoverBorder}
                message={message}
                onChange={onChange}
                buttonRef={buttonRef}
                trap={trap}
                popoverRef={popoverRef}
                onConfirm={onConfirm}
                numberOfParticipants={numberOfParticipants}
                defaultValues={defaultValues}
                minEntrants={minEntrants}
                maxEntrants={maxEntrants}
                anchorRef={anchorRef}
                onClose={() => {
                    onConfirm && onConfirm();
                    close(
                        values,
                        numberOfParticipants,
                        minEntrants,
                        onChange,
                        trap,
                        setVisible,
                        defaultValues,
                    );
                }}
            ></MultipleNumberInputCollapseContent>
        </Box>
    );
}

function MultipleNumberInputField(props: {
    labelColor: string | undefined;
    backgroundColor?: string;
    color?: string;
    variant?: 'outlined' | 'filled';
    labelText: string | undefined;
    refocusElement: RefObject<HTMLInputElement>;
    anchorRef: RefObject<HTMLDivElement>;
    visible: boolean;
    setVisible: StateUpdater<boolean>;
    values: MultipleNumberInputValueType[];
}) {
    const {
        labelColor,
        labelText,
        refocusElement,
        visible,
        setVisible,
        values,
        anchorRef,
        backgroundColor,
        color,
        variant = 'filled',
    } = props;
    const { t } = useLocale();
    const theme = useTheme();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeVisible = useCallback(
        debounce((visible: boolean) => setVisible(visible), 300),
        [],
    );

    const displayString = useMemo(
        () =>
            inputString(values).length > 0 ? inputString(values) : capitalize(t.select_travelers),
        [values, t],
    );

    return (
        <FormControl fullWidth>
            <InputLabel
                id="how-many-are-going-label"
                sx={{
                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                    color: labelColor,
                    whiteSpace: 'nowrap',
                }}
            >
                {labelText ?? capitalize(t.how_many_are_going)}
            </InputLabel>
            <TextField
                ref={anchorRef}
                inputRef={refocusElement}
                sx={{
                    width: '100%',
                    '& .MuiFilledInput-input': {
                        padding: theme.spacing(1.5),
                        cursor: 'pointer',
                    },
                    '& .MuiInputBase-root': {
                        cursor: 'pointer',
                        backgroundColor: backgroundColor,
                        color: color,
                    },
                }}
                onClick={() => onChangeVisible(!visible)}
                onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && onChangeVisible(!visible)}
                onFocus={deselectTextInput}
                variant={variant}
                margin="dense"
                size="small"
                value={displayString}
                InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">{<Person />}</InputAdornment>,
                }}
                inputProps={{
                    'aria-labelledby': 'how-many-are-going-label',
                }}
            />
        </FormControl>
    );
}

function MultipleNumberInputCollapseContent(props: {
    visible: boolean;
    setVisible: StateUpdater<boolean>;
    values: MultipleNumberInputValueType[];
    enablePopoverBorder: boolean;
    message: string | undefined;
    onChange: (id: number, value: number) => void;
    buttonRef: Ref<HTMLButtonElement | null>;
    trap: any;
    popoverRef: RefObject<HTMLDivElement>;
    onConfirm: (() => void) | undefined;
    numberOfParticipants: number;
    defaultValues: MultipleNumberInputValueType[];
    minEntrants: number;
    maxEntrants?: number;
    anchorRef: RefObject<HTMLDivElement>;
    onClose: () => void;
}) {
    const {
        visible,
        setVisible,
        values,
        enablePopoverBorder,
        message,
        onChange,
        buttonRef,
        trap,
        popoverRef,
        onConfirm,
        numberOfParticipants,
        defaultValues,
        minEntrants,
        maxEntrants,
        anchorRef,
        onClose,
    } = props;
    const { t } = useLocale();
    const theme = useTheme();

    const customizations = useCustomizations();
    const { popover } = useContext(MountPointContext);

    const maxReached = maxEntrants === values.reduce((acc, cur) => acc + cur.value, 0) ?? !!message;

    return (
        <Popover open={visible} onClose={onClose} container={popover} anchorEl={anchorRef.current}>
            <Paper
                elevation={enablePopoverBorder ? 0 : 2}
                sx={{
                    width: visible ? anchorRef.current?.getBoundingClientRect().width : 0,
                    padding: theme.spacing(2, 0.5),
                    border: enablePopoverBorder
                        ? `1px solid ${
                              getPrimaryColors(
                                  theme.palette.primary.main,
                                  theme.palette.primary.contrastText,
                                  '#FFFFFF',
                                  false,
                                  false,
                              ).color
                          }`
                        : undefined,
                    '& > *': {
                        maxWidth: 250,
                        width: '100%',
                        margin: '0 auto',
                    },
                    transition: 'all 0.5s ease-in-out',
                }}
                ref={popoverRef}
            >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    {message && (
                        <Typography color="error" align="center">
                            {message}
                        </Typography>
                    )}
                    <Inputs
                        values={values}
                        defaultValues={defaultValues}
                        onChange={onChange}
                        maxReached={maxReached}
                        maxEntrants={maxEntrants}
                    />
                    <Button
                        ref={buttonRef}
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        sx={{
                            minWidth: 100,
                            marginTop: theme.spacing(1),
                            '&:hover': {
                                backgroundColor: (_props) =>
                                    customizations.primaryButtonStyle === 'contained'
                                        ? darken(theme.palette.primary.main, 0.2)
                                        : 'rgba(0, 0, 0, 30%)',
                            },
                        }}
                        onClick={() => {
                            onConfirm && onConfirm();
                            close(
                                values,
                                numberOfParticipants,
                                minEntrants,
                                onChange,
                                trap,
                                setVisible,
                                defaultValues,
                            );
                        }}
                        disabled={numberOfParticipants < minEntrants}
                    >
                        {t.ok}
                    </Button>
                    <MinEntrantsMessage
                        numberOfParticipants={numberOfParticipants}
                        minEntrants={minEntrants}
                    ></MinEntrantsMessage>
                </Grid>
            </Paper>
        </Popover>
    );
}

function MinEntrantsMessage(props: { numberOfParticipants: number; minEntrants: number }) {
    const { numberOfParticipants, minEntrants } = props;
    const { t } = useLocale();
    const theme = useTheme();
    return numberOfParticipants < minEntrants && numberOfParticipants !== 0 ? (
        <Typography textAlign="center" fontSize="small" mt={theme.spacing(1)}>
            {t.a_minimum_of_x_participants_is_required_to_book_this_product.parsed(
                minEntrants.toString(),
            )}
        </Typography>
    ) : (
        <Fragment />
    );
}

function checkForValues(values: MultipleNumberInputValueType[]): boolean {
    return values.some((x) => x.value > 0);
}

function inputString(values: MultipleNumberInputValueType[]) {
    return values
        .filter((x) => x.value > 0)
        .map((val) => {
            return `${val.value} ${
                typeof val.name === 'function'
                    ? capitalize(val.name(val.value))
                    : capitalize(val.name)
            }`;
        })
        .join(', ');
}

function Inputs({
    values,
    defaultValues,
    onChange,
    maxReached = false,
    maxEntrants,
}: {
    values: MultipleNumberInputValueType[];
    defaultValues: MultipleNumberInputValueType[];
    onChange: (id: number, value: number) => void;
    maxReached: boolean;
    maxEntrants?: number;
}) {
    return useMemo(
        () => (
            <>
                {defaultValues.map((defaultVal) => {
                    const actualValue = values.find((x) => x.id === defaultVal.id)
                    const val = actualValue ?? defaultVal;
                    const disabled = !actualValue || actualValue.disabled;
                    return (
                        <QuantityPicker
                            key={`quantity-picker-${
                                typeof val.name === 'function'
                                    ? capitalize(val.name(defaultVal.value))
                                    : capitalize(val.name)
                            }`}
                            value={actualValue?.value ?? val.value}
                            name={
                                typeof val.name === 'function'
                                    ? capitalize(val.name(val.value))
                                    : capitalize(val.name)
                            }
                            subText={disabled ? val.disabledText : val.subText}
                            onChange={(num) => {
                                onChange(val.id, num);
                            }}
                            maxReached={maxReached}
                            maxValue={maxEntrants ?? Infinity}
                            minValue={0}
                            disabled={disabled}
                        />
                    );
                })}
            </>
        ),
        [defaultValues, values, maxReached, onChange, maxEntrants],
    );
}

function close(
    values: MultipleNumberInputValueType[],
    numberOfParticipants: number,
    minEntrants: number,
    onChange: (id: number, value: number) => void,
    trap: any,
    setVisible: StateUpdater<boolean>,
    defaultValues: MultipleNumberInputValueType[],
) {
    const hasAnyQuantities = checkForValues(values);

    // If no travelers or less than the minimum valid number of travelers are selected, we should initialize to default state again
    if (!hasAnyQuantities || numberOfParticipants < minEntrants) {
        defaultValues.forEach((val) => {
            onChange(val.id, val.value);
        });
    }

    if (trap) trap.deactivate();
    setVisible(false);
}
