import { Dayjs } from 'dayjs';
import { BilberryPriceQuantity } from 'src/state/cart/ICart';
import { BilberryExtra, BilberryProduct } from 'src/types/bilberry-api-types';

export type MinimalPackageCartItem = {
    pkg: string;
    start: string;
    end: string;
    selectedProducts: string[];
    quantities: {
        id: string;
        quantity: string;
    }[];
    extras: {
        id: string;
        quantities: { priceId: string; quantity: string }[];
    }[];
};

export type MinimalProductCartItem = {
    product: {
        productCatalogId: string;
        productId: string;
        startDate: Dayjs;
    };
    quantities: {
        id: string;
        quantity: string;
    }[];
    extras: {
        id: string;
        quantities: { priceId: string; quantity: string }[];
    }[];
};

export type MinimalMultiDayProductCartItem = {
    products: {
        productCatalogId: string;
        productId: string;
    }[];
    quantities: {
        id: string;
        quantity: string;
    }[];
    extras: {
        id: string;
        quantities: { priceId: string; quantity: string }[];
    }[];
};

export function asPriceQuantities(
    product: BilberryProduct,
    minimalQuantities: { id: string; quantity: string }[],
) {
    const quantities = product.prices.map((price) => {
        const quantityString = minimalQuantities.find(
            ({ id }) => Number(id) === price.id,
        )?.quantity;
        const quantity = quantityString ? Number(quantityString) : 0;
        return {
            ...price,
            quantity,
        } as BilberryPriceQuantity;
    });

    return {
        id: product.id,
        quantities,
    };
}

export function assignQuantityToExtraProduct(
    minimalCartItems:
        | (MinimalProductCartItem | MinimalMultiDayProductCartItem)[]
        | MinimalPackageCartItem,
    extra: BilberryExtra,
) {
    let currentMinimalCartItem;
    if (isMinimalPackageCartItem(minimalCartItems)) currentMinimalCartItem = minimalCartItems;
    else {
        currentMinimalCartItem = minimalCartItems.find(({ extras }) =>
            extras.find((minimalExtra) => String(extra.id) === minimalExtra.id),
        );
    }
    const quantities = currentMinimalCartItem?.extras.flatMap(({ quantities }) => quantities) ?? [];

    return {
        extra,
        quantities: extra.prices.map((price) => {
            const quantity = quantities.find(
                ({ priceId }) => priceId === String(price.id),
            )?.quantity;

            return {
                ...price,
                quantity: quantity ? Number(quantity) : 0,
            };
        }),
    };
}

export function parseExtraParams(params: string) {
    return params.split(',').map((extra) => {
        const [id, ...quantitiesParams] = extra.split(':');
        const quantitiesData = quantitiesParams.join(':').split('_');
        const quantities = quantitiesData.map((quantitiesParam) => quantitiesParam.split(':'));
        return {
            id,
            quantities: quantities.map(([priceId, quantity]) => ({ priceId, quantity })),
        };
    });
}

function isMinimalPackageCartItem(obj: any): obj is MinimalPackageCartItem {
    return !!obj && 'pkg' in obj;
}
