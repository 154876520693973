import { h, render } from 'preact';
import { getIdAttribute, getStringAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ProductImageWidgetRoot from './ProductImageWidgetRoot';

class BilberryProductImage extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        const format = (getStringAttribute(this, 'aspect-ratio') ?? '16/9').replace('/', '_');
        overrideLocaleFromAttribute(this);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductImageWidgetRoot productCatalogId={productCatalogId} format={format} />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-product-image', BilberryProductImage);
}
