import { Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { capitalize } from 'src/utils/common/TextUtils';
import { PriceSummary } from 'src/utils/domain/price-helper';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';

export default function SummaryVat(props: {
    priceSummary: PriceSummary;
    invertedColorsClass?: Object;
    hasPackage?: boolean;
}): JSX.Element | null {
    const { t, locale } = useLocale();
    const [configuration] = useAtom(configurationAtom);
    const theme = useTheme();

    const vatAmountInfoKeysSorted = Object.keys(props.priceSummary.vatAmountInfo).sort(
        (keyA, keyB) => {
            return -(parseFloat(keyA) - parseFloat(keyB));
        },
    );

    if (configuration.hideVat) return null;

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            borderTop={`1px solid ${theme.palette.grey[400]}`}
            borderBottom={`1px solid ${theme.palette.grey[400]}`}
            py={theme.spacing(1)}
        >
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="3"
                flexShrink="1"
            >
                <Typography align="right" color="textSecondary" sx={props.invertedColorsClass}>
                    {capitalize(t.excl)}&nbsp;{t.vat}
                </Typography>

                {props.hasPackage ? (
                    <Typography
                        key={'price-summary-vat-amount-info-total-description'}
                        align="right"
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {t.vat}
                    </Typography>
                ) : (
                    vatAmountInfoKeysSorted.map((key) => {
                        return props.priceSummary.vatAmountInfo[key] > 0 ? (
                            <Typography
                                key={key}
                                align="right"
                                color="textSecondary"
                                sx={props.invertedColorsClass}
                            >
                                {t.vat}&nbsp;{`${key}%`}
                            </Typography>
                        ) : null;
                    })
                )}
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="1"
                flexShrink="1"
            >
                <Typography align="right" color="textSecondary" sx={props.invertedColorsClass}>
                    {getLocaleNumberFormatTwoDecimals(locale, props.priceSummary.totalPriceExVat)}
                </Typography>

                {props.hasPackage ? (
                    <Typography
                        key="price-summary-vat-amount-info-total"
                        align="right"
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {getLocaleNumberFormatTwoDecimals(locale, props.priceSummary.totalVat)}
                    </Typography>
                ) : (
                    vatAmountInfoKeysSorted.map((key) => {
                        return props.priceSummary.vatAmountInfo[key] > 0 ? (
                            <Typography
                                key={key}
                                align="right"
                                color="textSecondary"
                                sx={props.invertedColorsClass}
                            >
                                {getLocaleNumberFormatTwoDecimals(
                                    locale,
                                    props.priceSummary.vatAmountInfo[key],
                                )}
                            </Typography>
                        ) : null;
                    })
                )}
            </Grid>
        </Grid>
    );
}
