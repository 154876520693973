import { BilberryProductCatalog, BilberryProductCollection } from 'src/types/bilberry-api-types';

/**
 * This transform is necessary because the widgets assume the "products" of the product collection
 * Are the same type as what is returned from the product-catalog endpoint. This is not the case.
 * Because of performance reasons, the format is somewhat different.
 *
 * This transform goes from the new product-collection format to the old product-catalog format
 * in order to ensure compatability in the widgets.
 */
export function transformProductCollectionToProductCatalogMediaFormat(
    productCollection: BilberryProductCollection,
): BilberryProductCollection {
    return {
        ...productCollection,
        products: productCollection.products.map(
            (product) =>
                ({
                    ...product,
                    media: {
                        image: {
                            url: (product as any).media[0]?.url ?? undefined,
                        },
                        gallery: (product as any).media.slice(1),
                    },
                } as BilberryProductCatalog),
        ),
    };
}
