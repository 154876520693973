import { Paper, Button, Grid, darken, capitalize, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { h, RefObject, VNode } from 'preact';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import Calendar from 'src/components/common/calendar/Calendar';
import PackageCalendar from 'src/components/common/calendar/PackageCalendar';
import MultipleNumberInput from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import { useLocale } from 'src/i18n/locale';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import {
    getNumberOfTravelers,
    mapPriceQuantitiesToMultipleNumberInputValueType,
    onChangeQuantity,
} from 'src/utils/domain/booking/bookingHelpers';
import { AvailabilityProductType, TimeSlotType } from 'src/utils/domain/TimeSlotType';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ControlledInputVisibilitySection from '../../common/inputs/ControlledInputVisibilitySection';
import BookingWidgetCard from '../booking-widget-card/BookingWidgetCard';
import { CostSummary } from './cost-summary/CostSummary';
import { ensurePixelValue } from 'src/utils/common/styleUtils';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { WarningMessage } from './BookingCardWarningMessage';

const DEFAULT_NUM_PRICES = 3;

interface IProps {
    title: string;
    visible: boolean;
    onToggleVisible: (visible: boolean, interaction: 'keyboard' | 'mouse') => void;
    toggleButton?: JSX.Element;
    productTitle: string;
    travelerQuantities: Array<BookingPriceAndQuantity>;
    priceQuantities: Array<BookingPriceAndQuantity>;
    defaultQuantities: Array<BookingPriceAndQuantity>;
    setQuantities: StateUpdater<BookingPriceAndQuantity[]>;
    selectedTimeSlot?: TimeSlotType<any>;
    onSelectTimeSlot?: (timeslot: TimeSlotType<any> | undefined) => void;
    selectedDateRange?: DateRange<Dayjs>;
    onSelectDateRange?: (newRange: DateRange<Dayjs>) => void;
    availabilityData: {
        [id: string]:
            | AvailabilityProductType<any>[]
            | AvailabilityProductType<any>
            | { available: boolean };
    };
    numPrices?: number;
    setSelectedProducts?(products: AvailabilityProductType<any>[] | undefined): void;
    leftButtonLabel?: string;
    rightButtonLabel: string;
    onClickLeftButton?(): void;
    onClickRightButton(): void;
    availabilitySearchPeriod: { startDay: Dayjs | null; endDay: Dayjs | null };
    setAvailabilitySearchPeriod(availabilitySearchPeriod: {
        startDay: Dayjs | null;
        endDay: Dayjs | null;
    }): void;
    hasChosenDate: boolean;
    setHasChosenDate(hasChosen: boolean): void;
    attemptedBooking: boolean;
    fromPrice?: number | null;
    hideLeftButton?: boolean;
    productCapacity?: number;
    disableFixedPosition?: boolean;
    addMarginRightToHeader?: boolean;
    bookingCardRef?: RefObject<HTMLDivElement>;
    expandArrowInside: boolean;
    dateRangeVariant?: 'days' | 'nights';
    minDate?: Dayjs;
    jsxSectionEnd?: VNode;
    minEntrants?: number;
    isPackageCalendar?: boolean;
    setSelectedDate?: (value: Dayjs | null) => void;
    selectedDate?: Dayjs | null;
    warningLabel?: string;
    positionOffscreen: boolean;
}

export default function BookingBase(props: IProps): JSX.Element {
    const {
        productTitle,
        priceQuantities,
        leftButtonLabel,
        onClickLeftButton,
        onClickRightButton,
        rightButtonLabel,
        hasChosenDate,
        productCapacity,
        onToggleVisible,
        visible,
        title,
        toggleButton,
        hideLeftButton,
        disableFixedPosition,
        fromPrice,
        addMarginRightToHeader,
        bookingCardRef,
        expandArrowInside,
        numPrices = DEFAULT_NUM_PRICES,
        minEntrants,
        warningLabel,
        positionOffscreen,
    } = props;

    const { t } = useLocale();
    const [productCapacityReached, setProductCapacityReached] = useState<boolean>(false);
    const [hasChangedQuantities, setHasChangedQuantities] = useState(false);

    useEffect(() => {
        setProductCapacityReached(false);
    }, [productCapacity]);

    const showFromPrice = fromPrice && !visible && !hasChangedQuantities;

    function handleRightClick() {
        setProductCapacityReached(false);
        onClickRightButton();
    }

    let disableBookButton = false;
    let disabledReason = null as null | 'minEntrants' | 'capacity';
    const numberOfTravelers = getNumberOfTravelers(priceQuantities);
    if (minEntrants && numberOfTravelers < minEntrants) {
        disableBookButton = true;
        disabledReason = 'minEntrants';
    } else {
        const product = props.selectedTimeSlot?.product as BilberryProduct | undefined;
        if (
            product &&
            numberOfTravelers > Math.min(product.capacity, product.max_entrants ?? Number.MAX_VALUE)
        ) {
            disabledReason = 'capacity';
            disableBookButton = true;
        }
    }

    function getWarningLabel() {
        if (warningLabel) return warningLabel;
        else if (disabledReason === 'minEntrants') {
            return t.a_minimum_of_x_participants_is_required_to_book_this_product.parsed(
                minEntrants?.toString() ?? '',
            );
        } else if (disabledReason === 'capacity') {
            return t.no_available_capacity_for_this_tour;
        }
    }

    return (
        <BookingWidgetCard
            bookingCardRef={bookingCardRef}
            visible={visible}
            onToggleVisible={onToggleVisible}
            title={title}
            toggleButton={toggleButton}
            rightButtonLabel={rightButtonLabel}
            onClickRightButton={handleRightClick}
            leftButtonLabel={leftButtonLabel}
            onClickLeftButton={onClickLeftButton}
            hideLeftButton={hideLeftButton}
            quantities={priceQuantities}
            fromPrice={showFromPrice ? fromPrice : null}
            disableFixedPosition={disableFixedPosition}
            addMarginRightToHeader={addMarginRightToHeader}
            expandArrowInside={expandArrowInside}
            disableBookButton={disableBookButton}
            positionOffscreen={positionOffscreen}
            getWarningMessage={getWarningLabel}
        >
            <BookingBaseForm
                {...props}
                setHasChangedQuantities={setHasChangedQuantities}
                setProductCapacityReached={setProductCapacityReached}
                productCapacityReached={productCapacityReached}
            />
            <CostSummary
                header={productTitle}
                quantities={hasChosenDate ? priceQuantities : []}
                numPrices={numPrices}
            />
        </BookingWidgetCard>
    );
}

export function BookingBaseInline(
    props: Pick<
        IProps,
        | 'title'
        | 'productTitle'
        | 'travelerQuantities'
        | 'priceQuantities'
        | 'defaultQuantities'
        | 'setQuantities'
        | 'setSelectedProducts'
        | 'selectedTimeSlot'
        | 'onSelectTimeSlot'
        | 'availabilityData'
        | 'numPrices'
        | 'rightButtonLabel'
        | 'onClickRightButton'
        | 'availabilitySearchPeriod'
        | 'setAvailabilitySearchPeriod'
        | 'hasChosenDate'
        | 'setHasChosenDate'
        | 'attemptedBooking'
        | 'productCapacity'
        | 'onSelectDateRange'
        | 'selectedDateRange'
        | 'dateRangeVariant'
        | 'minDate'
        | 'minEntrants'
        | 'warningLabel'
        | 'bookingCardRef'
    >,
): JSX.Element {
    const {
        productTitle,
        priceQuantities,
        onClickRightButton,
        rightButtonLabel,
        hasChosenDate,
        productCapacity,
        numPrices = DEFAULT_NUM_PRICES,
        minEntrants,
        warningLabel,
    } = props;

    const { t } = useLocale();
    const [productCapacityReached, setProductCapacityReached] = useState<boolean>(false);

    useEffect(() => {
        setProductCapacityReached(false);
    }, [productCapacity]);

    let disableBookButton = false;
    let disabledReason = null as null | 'minEntrants' | 'capacity';
    const numberOfTravelers = getNumberOfTravelers(priceQuantities);
    if (minEntrants && numberOfTravelers < minEntrants) {
        disableBookButton = true;
        disabledReason = 'minEntrants';
    } else {
        const product = props.selectedTimeSlot?.product as BilberryProduct | undefined;
        if (
            product &&
            numberOfTravelers > Math.min(product.capacity, product.max_entrants ?? Number.MAX_VALUE)
        ) {
            disabledReason = 'capacity';
            disableBookButton = true;
        }
    }

    function getWarningLabel() {
        if (warningLabel) return warningLabel;
        else if (disabledReason === 'minEntrants') {
            return t.a_minimum_of_x_participants_is_required_to_book_this_product.parsed(
                minEntrants?.toString() ?? '',
            );
        } else if (disabledReason === 'capacity') {
            return t.no_available_capacity_for_this_tour;
        }
    }

    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <Grid component={'div'}>
            <Paper
                elevation={5}
                sx={{
                    backgroundColor: customizations.bookingWidgetColor,
                    color: customizations.bookingWidgetColorContrast,
                    borderRadius: `calc(${ensurePixelValue(theme.shape.borderRadius)} * 2)`,
                    padding: 5,
                    maxWidth: '640px',
                    margin: '0 auto',
                }}
            >
                <BookingBaseForm
                    {...props}
                    setProductCapacityReached={setProductCapacityReached}
                    productCapacityReached={productCapacityReached}
                />
                <CostSummary
                    header={productTitle}
                    quantities={hasChosenDate ? priceQuantities : []}
                    numPrices={numPrices}
                />
                <WarningMessage label={getWarningLabel()} />
                <Button
                    sx={{
                        flex: '0 0 auto',
                        margin: '0 auto',
                        display: 'block',
                        minWidth: '10rem',
                        backgroundColor:
                            customizations.primaryButtonStyle === 'contained'
                                ? customizations.bookingWidgetPrimaryColor
                                : 'transparent',
                        color:
                            customizations.primaryButtonStyle === 'contained'
                                ? customizations.bookingWidgetPrimaryColorContrast
                                : customizations.bookingWidgetPrimaryColor,
                        borderColor:
                            customizations.primaryButtonStyle === 'contained'
                                ? 'transparent'
                                : customizations.bookingWidgetPrimaryColor,
                        '&:hover': {
                            backgroundColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? darken(customizations.bookingWidgetPrimaryColor, 0.2)
                                    : alpha(customizations.bookingWidgetPrimaryColor, 0.2),
                            color:
                                customizations.primaryButtonStyle === 'contained'
                                    ? darken(customizations.bookingWidgetPrimaryColorContrast, 0.2)
                                    : undefined,
                        },
                        '&:disabled': {
                            backgroundColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? darken(customizations.bookingWidgetPrimaryColor, 0.5)
                                    : 'transparent',
                            color:
                                customizations.primaryButtonStyle === 'contained'
                                    ? customizations.bookingWidgetColor
                                    : customizations.bookingWidgetPrimaryColor,
                            borderColor:
                                customizations.primaryButtonStyle === 'contained'
                                    ? 'transparent'
                                    : darken(customizations.bookingWidgetPrimaryColor, 0.5),
                        },
                    }}
                    onClick={onClickRightButton}
                    aria-label={rightButtonLabel}
                    variant={customizations.primaryButtonStyle}
                    color="primary"
                    disabled={disableBookButton}
                >
                    {capitalize(rightButtonLabel)}
                </Button>
            </Paper>
        </Grid>
    );
}

export function BookingBaseForm(
    props: Pick<
        IProps,
        | 'defaultQuantities'
        | 'travelerQuantities'
        | 'selectedTimeSlot'
        | 'setQuantities'
        | 'onSelectTimeSlot'
        | 'availabilityData'
        | 'setSelectedProducts'
        | 'availabilitySearchPeriod'
        | 'setAvailabilitySearchPeriod'
        | 'hasChosenDate'
        | 'setHasChosenDate'
        | 'attemptedBooking'
        | 'productCapacity'
        | 'selectedDateRange'
        | 'onSelectDateRange'
        | 'dateRangeVariant'
        | 'minDate'
        | 'jsxSectionEnd'
        | 'minEntrants'
        | 'isPackageCalendar'
        | 'selectedDate'
        | 'setSelectedDate'
    > & {
        setHasChangedQuantities?: StateUpdater<boolean>;
        setProductCapacityReached: StateUpdater<boolean>;
        productCapacityReached: boolean;
        backgroundColor?: string;
        color?: string;
        labelColor?: string;
        variant?: 'filled' | 'outlined';
        id?: string;
    },
) {
    const {
        defaultQuantities,
        travelerQuantities,
        selectedTimeSlot,
        setQuantities,
        onSelectTimeSlot,
        availabilityData,
        setSelectedProducts,
        availabilitySearchPeriod,
        setAvailabilitySearchPeriod,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        productCapacity,
        selectedDateRange,
        onSelectDateRange,
        dateRangeVariant,
        minDate,
        jsxSectionEnd,
        minEntrants,
        setHasChangedQuantities,
        setProductCapacityReached,
        productCapacityReached,
        isPackageCalendar,
        selectedDate,
        setSelectedDate,
        id,
        backgroundColor,
        color,
        labelColor,
        variant,
    } = props;

    const { t } = useLocale();
    const customizations = useCustomizations();
    const config = useConfigurations();

    const { multipleNumberInputValues, defaultMultipleNumberInputValues } =
        mapPriceQuantitiesToMultipleNumberInputValueType(t, travelerQuantities, defaultQuantities);

    return (
        <ControlledInputVisibilitySection>
            <MultipleNumberInput
                values={multipleNumberInputValues}
                defaultValues={defaultMultipleNumberInputValues}
                onChange={(id, q) =>
                    onChangeQuantity(
                        id,
                        q,
                        setHasChangedQuantities,
                        setQuantities,
                        productCapacity,
                        setProductCapacityReached,
                    )
                }
                message={
                    productCapacity && productCapacityReached
                        ? t.number_of_travelers_exceeded.parsed(productCapacity)
                        : ''
                }
                labelColor={customizations.bookingWidgetColorContrast}
                backgroundColor={backgroundColor}
                color={color}
                variant={variant}
                minEntrants={minEntrants ? minEntrants : 1}
                maxEntrants={config.personsMax}
            />
            {isPackageCalendar && setSelectedDate ? (
                <PackageCalendar
                    hasChosenDate={hasChosenDate}
                    setHasChosenDate={setHasChosenDate}
                    selectedDate={selectedDate ?? null}
                    setSelectedDate={setSelectedDate}
                    availabilityData={availabilityData}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    minDate={minDate}
                    attemptedBooking={attemptedBooking}
                    backgroundColor={backgroundColor}
                    color={color}
                    labelColor={labelColor}
                    variant={variant}
                    id={id}
                />
            ) : (
                <Calendar
                    dateRangeVariant={dateRangeVariant}
                    onSelectDateRange={onSelectDateRange}
                    selectedDateRange={selectedDateRange}
                    selectedTimeSlot={selectedTimeSlot}
                    onSelectTimeSlot={onSelectTimeSlot}
                    setSelectedProducts={setSelectedProducts}
                    availabilityData={availabilityData}
                    availabilitySearchPeriod={availabilitySearchPeriod}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    hasChosenDate={hasChosenDate}
                    setHasChosenDate={setHasChosenDate}
                    attemptedBooking={attemptedBooking}
                    minDate={minDate}
                    id={id}
                    backgroundColor={backgroundColor}
                    color={color}
                    labelColor={labelColor}
                    variant={variant}
                    quantities={travelerQuantities}
                />
            )}
            {jsxSectionEnd}
        </ControlledInputVisibilitySection>
    );
}
