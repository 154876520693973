import { alpha, Box, Button, darken, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getAvailableTimeSlots } from 'src/utils/domain/DateHelpers';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';

interface IProps {
    availabilityData: BilberryProduct[] | undefined;
    selectedTimeSlot: TimeSlotType<BilberryProduct> | undefined;
    onSelectTimeSlot(timeSlot: TimeSlotType<any>): void;
    isTimeSlotsEqual?: (timeSlotA: TimeSlotType<any>, timeSlotB: TimeSlotType<any>) => boolean;
    hideHeader?: boolean;
    fullWidth?: boolean;
}

export default function TimeSlots(props: IProps): JSX.Element {
    const {
        isTimeSlotsEqual = (a: TimeSlotType<BilberryProduct>, b: TimeSlotType<BilberryProduct>) =>
            a.product.id === b.product.id,
    } = props;
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const { selectedTimeSlot, onSelectTimeSlot, availabilityData } = props;

    const availableTimeSlots = getAvailableTimeSlots(availabilityData, locale);

    const timeSlots = availableTimeSlots.map((timeSlot) => {
        const isSelected = selectedTimeSlot && isTimeSlotsEqual(selectedTimeSlot, timeSlot);
        const isAtCapacity = timeSlot.product.capacity <= 0;

        return (
            <Grid
                key={`time-slot-${timeSlot.product.id}`}
                component={Button}
                height="fit-content"
                border={`1px solid ${
                    isAtCapacity
                        ? alpha(customizations.bookingWidgetColor, 0.5)
                        : customizations.bookingWidgetColor
                }`}
                borderRadius={`${theme.shape.borderRadius}px`}
                disabled={isAtCapacity}
                sx={{
                    px: theme.spacing(1.5),
                    py: timeSlot.sublabel ? theme.spacing(0.5) : theme.spacing(1.5),
                    ...(isSelected && {
                        backgroundColor: customizations.bookingWidgetColor,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: (props) =>
                                customizations.primaryButtonStyle === 'contained'
                                    ? darken(theme.palette.primary.main, 0.2)
                                    : 'rgba(0, 0, 0, 30%)',
                        },
                    }),
                }}
                onClick={() => !isAtCapacity && onSelectTimeSlot(timeSlot)}
            >
                <Box>
                    <Typography
                        sx={{
                            color: isSelected
                                ? customizations.bookingWidgetPrimaryColor
                                : customizations.bookingWidgetColor,
                            ...(isAtCapacity && {
                                textDecoration: 'line-through',
                            }),
                        }}
                    >
                        {timeSlot.label}
                    </Typography>
                    {timeSlot.sublabel && (
                        <Typography
                            sx={{
                                mt: -0.5,
                                fontSize: '0.75rem',
                                color: isSelected
                                    ? customizations.bookingWidgetPrimaryColor
                                    : customizations.bookingWidgetColor,
                                ...(isAtCapacity && {
                                    textDecoration: isAtCapacity ? 'none' : 'line-through',
                                }),
                            }}
                        >
                            {isAtCapacity ? t.full : timeSlot.sublabel}
                        </Typography>
                    )}
                </Box>
            </Grid>
        );
    });

    return (
        <Box
            sx={{
                padding: props.fullWidth ? undefined : theme.spacing(0, 1.5),
                mb: theme.spacing(1),
            }}
        >
            {!props.hideHeader && (
                <Grid container direction="row" alignItems="center">
                    <Typography color={{ color: customizations.bookingWidgetColor }}>
                        {capitalize(t.select_time)}
                    </Typography>
                    <Box
                        flex="1"
                        borderTop={`1px solid ${customizations.bookingWidgetColor}`}
                        ml={theme.spacing(1)}
                        sx={{
                            opacity: 0.2,
                        }}
                    />
                </Grid>
            )}
            <Stack
                direction="row"
                padding={theme.spacing(1, 0)}
                flexWrap="wrap"
                gap={theme.spacing(1)}
            >
                {availabilityData && timeSlots}
                {!availabilityData && (
                    <Fragment>
                        <Grid
                            item
                            xs={4}
                            sm={3}
                            container
                            justifyContent="center"
                            visibility="hidden"
                        >
                            <Grid
                                component={Button}
                                display="flex"
                                justifyContent="center"
                                alignContent="center"
                                height="fit-content"
                                border={`1px solid ${customizations.bookingWidgetColor}`}
                                borderRadius={`${theme.shape.borderRadius}px`}
                                padding={theme.spacing(1.5)}
                            >
                                <Typography>placeholder</Typography>
                            </Grid>
                        </Grid>
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            width="100%"
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography textAlign="center" fontSize="0.9rem">
                                {t.select_date_to_view_timeslots}
                            </Typography>
                        </Box>
                    </Fragment>
                )}
            </Stack>
        </Box>
    );
}
