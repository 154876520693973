export default function createWebComponentTagName(
    baseName: string,
    componentIsVersion: 'v1' | 'v2',
) {
    if (BilberryWidgetsGlobal.defaultVersion === 'v1') {
        if (componentIsVersion === 'v1') return baseName;
        else return `${baseName}-2`;
    }

    if (componentIsVersion === 'v1') return `${baseName}-1`;
    else return baseName;
}
