import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    Paper,
    TextField,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Fragment } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { useLocale } from 'src/i18n/locale';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize } from 'src/utils/common/TextUtils';
import { validateEmail, validateTextField } from 'src/utils/common/validation-helper';

export type RecipientDetails = {
    firstName: string;
    lastName: string;
    email: string;
};

type Props = {
    showErrors: boolean;
    recipient: RecipientDetails;
    onRecipientChange: (recipient: RecipientDetails) => void;
    validate: (isValid: boolean) => void;
};

function validateRecipient(recipient: RecipientDetails) {
    const isFirstNameValid = validateTextField(recipient.firstName);
    const isLastNameValid = validateTextField(recipient.lastName);
    const isEmailValid = validateEmail(recipient.email);
    const isValid = isFirstNameValid && isLastNameValid && isEmailValid;
    return {
        isFirstNameValid,
        isLastNameValid,
        isEmailValid,
        isValid,
    };
}
export default function GiftcardRecipientDetails(props: Props): JSX.Element {
    const { t } = useLocale();
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const { isFirstNameValid, isLastNameValid, isEmailValid, isValid } = validateRecipient(
        props.recipient,
    );
    const theme = useTheme();
    const showFirstNameValidationError = !isFirstNameValid && props.showErrors;
    const showLastNameValidationError = !isLastNameValid && props.showErrors;
    const showEmailValidationError = !isEmailValid && props.showErrors;
    props.validate(isValid);

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                marginBottom: theme.spacing(2),
                backgroundColor: 'white',
                width: '100%',
                marginTop: theme.spacing(2),
                border: `1px solid ${theme.palette.grey[300]}`,
            }}
            variant="outlined"
        >
            <Typography color="secondary" variant="h6" mt={0}>
                {capitalize(t.recipient_details)}
            </Typography>

            <Fragment>
                <FormControl
                    fullWidth
                    sx={{
                        '& > *': {
                            marginTop: theme.spacing(1),
                        },
                        backgroundColor: 'white',
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap={largeScreen ? 'nowrap' : 'wrap'}
                    >
                        <Box
                            flexBasis={largeScreen ? '50%' : '100%'}
                            width="100%"
                            mr={largeScreen ? theme.spacing(1) : 0}
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    minWidth: '300px',
                                },
                            }}
                        >
                            <InputLabel
                                sx={{
                                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                                    color: theme.palette.secondary.main,
                                }}
                                required
                                error={showFirstNameValidationError}
                            >
                                {capitalize(t.first_name)}
                            </InputLabel>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: largeScreen ? theme.spacing(1) : 0,
                                    [theme.breakpoints.down('md')]: {
                                        minWidth: '300px',
                                    },
                                }}
                                error={showFirstNameValidationError}
                                variant="outlined"
                                type="text"
                                color="secondary"
                                margin="dense"
                                size="small"
                                onChange={(
                                    e: TargetedEvent<HTMLTextAreaElement | HTMLInputElement, Event>,
                                ) =>
                                    props.onRecipientChange({
                                        ...props.recipient,
                                        firstName: e.currentTarget.value,
                                    })
                                }
                                value={props.recipient.firstName}
                            />
                        </Box>
                        <Box
                            flexBasis={largeScreen ? '50%' : '100%'}
                            width="100%"
                            ml={largeScreen ? theme.spacing(1) : 0}
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    minWidth: '300px',
                                },
                            }}
                        >
                            <InputLabel
                                sx={{
                                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                                    color: theme.palette.secondary.main,
                                }}
                                required
                                error={showLastNameValidationError}
                            >
                                {capitalize(t.last_name)}
                            </InputLabel>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: largeScreen ? theme.spacing(1) : 0,
                                    [theme.breakpoints.down('md')]: {
                                        minWidth: '300px',
                                    },
                                }}
                                error={showLastNameValidationError}
                                variant="outlined"
                                type="text"
                                color="secondary"
                                margin="dense"
                                size="small"
                                onChange={(
                                    e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                                ) =>
                                    props.onRecipientChange({
                                        ...props.recipient,
                                        lastName: e.currentTarget.value,
                                    })
                                }
                                value={props.recipient.lastName}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap={largeScreen ? 'nowrap' : 'wrap'}
                    >
                        <Box
                            mr={largeScreen ? theme.spacing(1) : 0}
                            width={largeScreen ? '50%' : '100%'}
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    minWidth: '300px',
                                },
                            }}
                        >
                            <InputLabel
                                sx={{
                                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                                    color: theme.palette.secondary.main,
                                }}
                                required
                                error={showEmailValidationError}
                            >
                                {capitalize(t.email)}
                            </InputLabel>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: largeScreen ? theme.spacing(1) : 0,
                                    [theme.breakpoints.down('md')]: {
                                        minWidth: '300px',
                                    },
                                }}
                                error={showEmailValidationError}
                                variant="outlined"
                                type="text"
                                color="secondary"
                                margin="dense"
                                size="small"
                                onChange={(
                                    e: TargetedEvent<HTMLTextAreaElement | HTMLInputElement, Event>,
                                ) =>
                                    props.onRecipientChange({
                                        ...props.recipient,
                                        email: e.currentTarget.value,
                                    })
                                }
                                value={props.recipient.email}
                            />
                        </Box>
                        <Box
                            ml={largeScreen ? theme.spacing(1) : 0}
                            flexBasis={largeScreen ? '50%' : '100%'}
                            width="100%"
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    minWidth: '300px',
                                },
                            }}
                        ></Box>
                    </Grid>
                </FormControl>
            </Fragment>
        </Paper>
    );
}
