import { Translations, UserTextCustomizations } from './translations/types';

declare const window: any;

// A bit complicated reducer below.
//
// custom translations/texts are input from user as e.g.
//
// window.BilberryWidgetsGlobal.textCustomizations = {
//     nok: {
//         'nb-NO': 'kr',
//     },
// };
//
// We need to kind of invert that to fit the internal translations structure:
// {
//     'nb-NO': {
//         nok: 'kr'
//     }
// }

type TextCustomizations = {
    [locale: string]:
        | {
              [key in keyof UserTextCustomizations]: Translations[keyof Translations];
          }
        | { [key: string]: string };
};

const userTextCustomizations: UserTextCustomizations =
    window.BilberryWidgetsGlobal.textCustomizations ?? {};

export const customTranslations = Object.entries(userTextCustomizations).reduce(
    (finalTranslations, textCustomization) => {
        const [textCustomizationKey, textTranslations] = textCustomization;
        if (textTranslations === undefined) return finalTranslations;

        const localeEntries = Object.entries(textTranslations);

        return localeEntries.reduce((accumulatedTranslations, localeEntry) => {
            const [locale, text] = localeEntry;

            return {
                ...accumulatedTranslations,
                [locale]: {
                    ...accumulatedTranslations[locale],
                    [textCustomizationKey]: text,
                },
            };
        }, finalTranslations as TextCustomizations);
    },
    {} as TextCustomizations,
);
