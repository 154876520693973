const TagOpen = '<';
const TagClose = '>';

export type Attribute = {
    name: string;
    value: string;
};

export type Tag = {
    name: string;
    children: Array<Tag | string>;
    parent: Tag | null;
    attributes: Attribute[];
};

type NextTagInfo = {
    openIndex: number;
    closeIndex: number;
    continueAtIndex: number;
    isSelfClosing: boolean;
    isClosingPreviousTag: boolean;
    noTagsInContent: boolean;
    tagName: string;
};

export function buildHtmlAst(html: string) {
    // simple preprocessing to ensure parsable html
    let preprocessed = html;
    preprocessed = preprocessed.split(/\<br\s*\>/).join('<br />');
    preprocessed = preprocessed.split(/\<hr\s*\>/).join('<hr />');
    preprocessed = preprocessed.split('\n').join('');
    preprocessed = `<root>${preprocessed}</root>`;

    const rootTag: Tag = {
        name: 'root',
        parent: null,
        attributes: [],
        children: [],
    };

    return buildTree(preprocessed, 0, rootTag);
}

function buildTree(
    html: string,
    index: number,
    parent: Tag,
): { tag: Tag; continue: number; closing: boolean } {
    const nextTagInfo = getNextTagInfo(html, index);

    // Handle base cases
    if (nextTagInfo.isSelfClosing) {
        return returnSelfClosingTag(html, index, parent, nextTagInfo);
    } else if (nextTagInfo.isClosingPreviousTag) {
        return returnClosingPreviousTag(html, index, parent, nextTagInfo);
    } else if (nextTagInfo.openIndex > index) {
        return returnTextContentAtStart(html, index, parent, nextTagInfo);
    }

    const tag: Tag = {
        name: nextTagInfo.tagName,
        parent,
        children: [],
        attributes: [],
    };

    let continueIndex = nextTagInfo.continueAtIndex;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const childTag = buildTree(html, continueIndex, tag);
        continueIndex = childTag.continue;

        if (childTag.closing) {
            // Add text content right before the end of the tag, if there.
            if (childTag.tag.children.length > 0) {
                tag.children.push(childTag.tag);
            }
            break;
        } else {
            tag.children.push(childTag.tag);
        }
    }

    const [name, attributes] = getElementAttributes(tag.name);
    tag.name = name;
    tag.attributes = attributes;

    return {
        continue: continueIndex,
        tag,
        closing: false,
    };
}

function getElementAttributes(name: string): [string, Attribute[]] {
    const items = name.split(/\s(?=[a-zA-Z\-]+\=)/);
    if (items.length > 1) {
        return [
            items[0].trim(),
            items.slice(1).map((item) => {
                const [name, value] = item.split('=');
                let unwrapped = value.trim();
                if (
                    unwrapped.length > 0 &&
                    ['"', "'"].includes(unwrapped[0]) &&
                    ['"', "'"].includes(unwrapped[unwrapped.length - 1])
                ) {
                    unwrapped = unwrapped.slice(1, unwrapped.length - 1);
                }
                return { name, value: unwrapped };
            }),
        ];
    }
    return [name.trim(), []];
}

/**
 * Add selfclosing tag to parent and return
 */
function returnSelfClosingTag(html: string, index: number, parent: Tag, nextTagInfo: NextTagInfo) {
    const textBeforeSelfClosingTag = html.slice(index, nextTagInfo.openIndex);
    if (textBeforeSelfClosingTag.length > 0) {
        parent.children.push({
            name: 'textContent',
            parent,
            children: [textBeforeSelfClosingTag],
            attributes: [],
        });
    }

    const [name, attributes] = getElementAttributes(nextTagInfo.tagName);

    return {
        tag: {
            name: name,
            parent,
            children: [],
            attributes: attributes,
        },
        continue: nextTagInfo.continueAtIndex,
        closing: false,
    };
}

function returnClosingPreviousTag(
    html: string,
    index: number,
    parent: Tag,
    nextTagInfo: NextTagInfo,
) {
    const textContentAtEnd = html.slice(index, nextTagInfo.openIndex);
    return {
        continue: nextTagInfo.continueAtIndex,
        closing: true,
        tag: {
            name: 'textContent',
            parent,
            children: [...(textContentAtEnd.length > 0 ? [textContentAtEnd] : [])],
            attributes: [],
        },
    };
}

function returnTextContentAtStart(
    html: string,
    index: number,
    parent: Tag,
    nextTagInfo: NextTagInfo,
) {
    return {
        continue: nextTagInfo.openIndex,
        closing: false,
        tag: {
            name: 'textContent',
            parent,
            children: [html.slice(index, nextTagInfo.openIndex)],
            attributes: [],
        },
    };
}

function getNextTagInfo(html: string, start: number): NextTagInfo {
    const slice = html.slice(start);
    const openIndex = start + slice.indexOf(TagOpen);
    const closeIndex = start + slice.indexOf(TagClose);
    const isSelfClosing = html.charAt(closeIndex - 1) === '/';

    const isClosingPreviousTag =
        html.charAt(openIndex + 1) === '/' || closeIndex + 1 >= html.length;

    const tagName = html.slice(
        openIndex + (isClosingPreviousTag ? 2 : 1),
        isSelfClosing ? closeIndex - 1 : closeIndex,
    );

    const noTagsInContent = openIndex === closeIndex && openIndex === -1;

    return {
        openIndex,
        closeIndex: isSelfClosing ? closeIndex - 1 : closeIndex,
        continueAtIndex: closeIndex + 1,
        isSelfClosing,
        isClosingPreviousTag,
        noTagsInContent,
        tagName,
    };
}
