import Person from '@mui/icons-material/Person';
import { Box, Grid, List, Paper, Stack, SxProps, Typography, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { groupBy, mapValues, sortBy, uniq } from 'lodash-es';
import { Fragment } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency } from 'src/i18n/Localization';
import { cartAtom } from 'src/state/cart/cartAtom';
import {
    ICartExtra,
    ICartItem,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartPackageItemProduct,
    ICartProductItem,
} from 'src/state/cart/ICart';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { formatDate } from 'src/utils/common/DateHelpers';
import {
    getICartMultiDayProductItems,
    getICartPackageItems,
    getICartProductItems,
    isICartPackageItemType,
    isICartProductItemType,
} from 'src/utils/domain/cart/cartUtils';
import {
    getExtraDisplayTitle,
    getPackageDisplayTitle,
    getProductDisplayTitle,
} from 'src/utils/domain/display-helper';
import { findLowestPriceAmongExtras, hasMultiplePrices } from 'src/utils/domain/price-helper';
import { findNumberOfTravelersInCartProductItem } from 'src/utils/domain/travelers-helper';
import { useAtom } from 'ximple';
import ExtraItemRow from './ExtraItemRow';

export default function Extras(): JSX.Element {
    const theme = useTheme();
    const [cart] = useAtom(cartAtom);
    const cartProductItems = getICartProductItems(Object.values(cart));
    const cartMultiDayProductItems = getICartMultiDayProductItems(Object.values(cart));
    const cartPackageItems = getICartPackageItems(Object.values(cart));

    return (
        <List
            sx={
                {
                    '& > *': {
                        marginTop: theme.spacing(3),
                        marginBottom: theme.spacing(3),
                    },
                    color: theme.palette.text.primary,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,

                    paddingTop: 0,
                    minWidth: '300px',
                    width: '100%',
                    maxWidth: '440px',
                } as SxProps
            }
        >
            {cartProductItems.map((item) => (
                <Extra item={item} cart={cart} key={`extra-panel-product-${item.product.id}`} />
            ))}
            {cartPackageItems.map((item) => (
                <Extra item={item} cart={cart} key={`extra-panel-package-${item.pkg.id}`} />
            ))}
            {cartMultiDayProductItems.map((item) => (
                <Extra
                    item={item}
                    cart={cart}
                    key={`extra-panel-accommodation-${item.products[0].id}`}
                />
            ))}
        </List>
    );
}

type ExtraProps = {
    item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem;
    cart: { [x: string]: ICartItem };
};

function Extra({ item, cart }: ExtraProps) {
    const { locale } = useLocale();
    const theme = useTheme();

    const title = isICartProductItemType(item)
        ? getProductDisplayTitle(item.product)
        : isICartPackageItemType(item)
        ? getPackageDisplayTitle(item.pkg)
        : getProductDisplayTitle(item.products[0]);

    const id = isICartProductItemType(item)
        ? item.product.id
        : isICartPackageItemType(item)
        ? item.selectedProducts.map(({ product }) => product.id).join('-')
        : item.products.map(({ id }) => id).join('-');

    const numberOfTravelers = findNumberOfTravelersInCartProductItem(item);
    const startTime = isICartProductItemType(item)
        ? dayjs(item.product.start)
        : isICartPackageItemType(item)
        ? dayjs(item.start)
        : dayjs(item.products[0].start);
    const endTime = isICartProductItemType(item)
        ? undefined
        : isICartPackageItemType(item)
        ? dayjs(item.end)
        : dayjs(item.products[item.products.length - 1].end);

    const extras = sortBy(
        Object.entries(groupBy(item.extras, (i) => i.extra.category.id.toString())),
        ([, values]) =>
            values[0].extra.start ? new Date(values[0].extra.start).getTime() : Number.MAX_VALUE,
    );

    let content: JSX.Element | JSX.Element[] | null = null;

    if (isICartPackageItemType(item)) {
        const ticketOptionIds = uniq(item.selectedProducts.map((x) => x.ticketOptionId));
        const groupedByCategoryAndTour = extras.map(([, groupedExtras]) =>
            groupedExtras.reduce((acc, extra) => {
                ticketOptionIds.forEach((ticketOptionId) => {
                    if (extra.extra.ticketOptionId !== ticketOptionId) return;
                    extra.extra.tours.forEach((tour) => {
                        const product = item.selectedProducts
                            .filter((x) => x.ticketOptionId === ticketOptionId)
                            .find((x) => x.product.id === tour);
                        const key = `${tour}-${ticketOptionId}`;
                        if (
                            acc[key] &&
                            !acc[key].extras.find((x) => x.extra.id === extra.extra.id)
                        ) {
                            acc[key].extras.push(extra);
                        } else if (product) {
                            acc[key] = {
                                product,
                                extras: [extra],
                            };
                        }
                    });
                });
                return acc;
            }, {} as Record<string, { product: ICartPackageItemProduct; extras: ICartExtra[] }>),
        );
        content = Object.entries(groupedByCategoryAndTour).flatMap(([categoryId, group]) =>
            Object.values(group).map(({ product, extras }, i, arr) => {
                const ticketOptionName = item.pkg.ticket_options.find(
                    (x) => x.ticket_option_id === product.ticketOptionId,
                )?.name;

                return (
                    <Paper
                        key={`${categoryId}-${product.ticketOptionId}-${product.product.id}-${i}`}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            '& > *': {
                                marginTop: theme.spacing(1),
                            },
                            backgroundColor: 'white',
                            width: '100%',
                        }}
                        variant="outlined"
                    >
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                            {product.product.web_title} - {ticketOptionName}
                        </Typography>
                        <ExtraGroupHeader extrasGroup={extras} />
                        {extras.map((extra) => (
                            <ExtraItemRow
                                key={extra.extra.id}
                                extra={extra}
                                extrasGroup={extras}
                                item={item}
                            />
                        ))}
                        {i < arr.length - 2 && <Box sx={{ mb: theme.spacing(3) }} />}
                    </Paper>
                );
            }),
        );
    } else {
        content = extras.map(([groupId, extrasGroup]) => {
            return (
                <Paper
                    key={groupId}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        '& > *': {
                            marginTop: theme.spacing(1),
                        },
                        backgroundColor: 'white',
                        width: '100%',
                    }}
                    variant="outlined"
                >
                    <ExtraGroupHeader extrasGroup={extrasGroup} />
                    {extrasGroup.map((extra) => (
                        <ExtraItemRow
                            key={extra.extra.id}
                            extra={extra}
                            extrasGroup={extrasGroup}
                            item={item}
                        />
                    ))}
                </Paper>
            );
        });
    }

    return (
        <Fragment key={id}>
            {Object.values(cart).length > 1 && (
                <Header
                    title={title}
                    locale={locale}
                    numberOfTravelers={numberOfTravelers}
                    startTime={startTime}
                    endTime={endTime}
                />
            )}
            {content}
        </Fragment>
    );
}

function ExtraGroupHeader({ extrasGroup }: { extrasGroup: ICartExtra[] }) {
    const [currency] = useAtom(currencyAtom);
    const { t, locale } = useLocale();

    return (
        <ExtrasGroupContainer
            title={extrasGroup[0].extra.category.web_name}
            subtitle={`${hasMultiplePrices(extrasGroup) ? t.from : ' '} ${formatCurrency(
                locale,
                currency,
                findLowestPriceAmongExtras(extrasGroup),
            )}`}
            description={extrasGroup[0].extra.category.web_short_description}
            imageSrc={extrasGroup[0].extra.category.media.image.url}
            imageAlt={`${getExtraDisplayTitle(extrasGroup[0].extra)}`}
        />
    );
}

function ExtrasGroupContainer({
    title,
    subtitle,
    description,
    imageSrc,
    imageAlt,
}: {
    title: string;
    subtitle: string;
    description: string;
    imageSrc: string;
    imageAlt: string;
}) {
    return (
        <Stack justifyContent="flex-end" direction="row-reverse" gap={2}>
            <Stack direction="column" width="100%">
                <Typography color="secondary" variant="h5">
                    {title}
                </Typography>
                <Typography color="secondary" variant="h6">
                    {subtitle}
                </Typography>
                <Typography color="secondary" paragraph mb={0}>
                    {description}
                </Typography>
            </Stack>
            {imageSrc && (
                <Stack flex="0 0 20%" alignItems="center" justifyContent="center">
                    <Box
                        component="img"
                        maxWidth="100%"
                        maxHeight="100%"
                        src={imageSrc}
                        alt={`${imageAlt}`}
                    />
                </Stack>
            )}
        </Stack>
    );
}

type HeaderProps = {
    title: string;
    numberOfTravelers: number;
    locale: string;
    startTime: Dayjs;
    endTime?: Dayjs;
};

function Header({
    title,
    locale,
    numberOfTravelers,
    startTime,
    endTime,
}: HeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                backgroundColor: 'white',
                width: '100%',
            }}
            variant="outlined"
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={9}>
                    <Typography color="textSecondary" variant="h6">
                        <span>{title}</span>
                        <Box component="span" display="block" sx={{ fontSize: '.9rem' }}>
                            {`${formatDate(startTime, locale, 'lll')}${
                                endTime ? ' - ' + formatDate(endTime, locale, 'lll') : ''
                            }`}
                        </Box>
                    </Typography>
                </Grid>
                <Grid container xs={3} justifyContent="flex-end" alignItems="center">
                    <Typography color="textSecondary" variant="h6">
                        {numberOfTravelers}x{' '}
                    </Typography>
                    <Person sx={{ fontSize: '1.6rem' }} color="secondary" />
                </Grid>
            </Grid>
        </Paper>
    );
}
