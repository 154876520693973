import dayjs from 'dayjs';
import produce from 'immer';
import { postValueCardReservation } from 'src/utils/domain/api/membership-api';
import { MembershipReserveResponse, MembershipUser } from 'src/types/membership-api-types';
import { atom, Subject } from 'ximple';
import { Company } from './company';
import { Action } from './reducers';
import { AppliedPromoCode } from 'src/types/promoCode';
import { AppliedGiftCard } from 'src/types/giftcards';
import { formatDate } from 'src/utils/common/DateHelpers';
import { localeAtom } from 'src/i18n/locale';

export type ValueCardCategory = 'membership' | 'credits' | '';
export type ValueCardPurchase = {
    typeId: number;
    category: ValueCardCategory;
    appliedPromoCode: AppliedPromoCode | null;
    appliedGiftcard: AppliedGiftCard | null;
    giftcardReference?: string;
    promoCodeReference?: string;
    membership: {
        id: number;
        dateTime: {
            date: string;
            time: string;
        };
    };
    credits: {
        id: number;
        name: string;
    };
    reservation?: MembershipReserveResponse;
};

export type ValueCardPurchaseAction =
    | 'RESET'
    | 'INITIALIZE'
    | 'UPDATE_DATE'
    | 'UPDATE_TIME'
    | 'UPDATE_APPLIED_GIFTCARD'
    | 'UPDATE_APPLIED_PROMOCODE'
    | 'UPDATE_GIFTCARD_REFERENCE'
    | 'UPDATE_PROMOCODE_REFERENCE'
    | 'UPDATE_MEMBERSHIP_TYPE'
    | 'UPDATE_CREDITS_TYPE'
    | 'UPDATE_CREDITS_NAME'
    | 'SET_MEMBERSHIP_CATEGORY'
    | 'SET_CREDITS_CATEGORY'
    | 'UPDATE_CREDITS_CREDITS'
    | 'SET_RESERVATION'
    | 'CLEAR_RESERVATION'
    | 'CANCEL';

export const valueCardReducer = produce(
    (draft: ValueCardPurchase, action: Action<ValueCardPurchaseAction>) => {
        switch (action.type) {
            case 'RESET':
            case 'CANCEL':
                return {
                    ...initialValueCardAtom,
                };
            case 'INITIALIZE':
                return action.value as ValueCardPurchase;
            case 'UPDATE_DATE':
                draft.membership.dateTime.date = (action.value as Date | null)?.toISOString() ?? '';
                break;
            case 'UPDATE_TIME':
                draft.membership.dateTime.time = action.value;
                break;
            case 'SET_MEMBERSHIP_CATEGORY':
                draft.category = 'membership';
                break;
            case 'SET_CREDITS_CATEGORY':
                draft.category = 'credits';
                break;
            case 'UPDATE_MEMBERSHIP_TYPE':
                draft.membership.id = action.value;
                break;
            case 'UPDATE_CREDITS_TYPE':
                draft.credits.id = action.value;
                break;
            case 'UPDATE_CREDITS_NAME':
                draft.credits.name = action.value;
                break;
            case 'SET_RESERVATION':
                draft.reservation = action.value;
                break;
            case 'CLEAR_RESERVATION':
                draft.reservation = undefined;
                break;
            case 'UPDATE_GIFTCARD_REFERENCE':
                draft.giftcardReference = action.value !== '' ? action.value : undefined;
                break;
            case 'UPDATE_PROMOCODE_REFERENCE':
                draft.promoCodeReference = action.value !== '' ? action.value : undefined;
                break;
            case 'UPDATE_APPLIED_GIFTCARD':
                draft.appliedGiftcard = action.value;
                break;
            case 'UPDATE_APPLIED_PROMOCODE':
                draft.appliedPromoCode = action.value;
                break;
            default:
                break;
        }
    },
);

export const initialValueCardAtom: ValueCardPurchase = {
    typeId: -1,
    category: '',
    membership: {
        id: -1,
        dateTime: {
            date: '',
            time: '',
        },
    },
    giftcardReference: undefined,
    promoCodeReference: undefined,
    appliedGiftcard: null,
    appliedPromoCode: null,
    credits: {
        id: -1,
        name: '',
    },
    reservation: undefined,
};

function updateValueCard(state: ValueCardPurchase, action: Action<ValueCardPurchaseAction>) {
    const newState = valueCardReducer(state, action);
    valueCardActionLog$.next({ state, newState, action });
    return newState;
}

export const valueCardActionLog$ = new Subject<{
    state: ValueCardPurchase;
    newState: ValueCardPurchase;
    action: Action<ValueCardPurchaseAction>;
}>();

export const valueCardAtom = atom<ValueCardPurchase, Action<ValueCardPurchaseAction>>({
    initialValue: initialValueCardAtom,
    persistKey: 'no.bilberry-timeslots.value-card-checkout',
    update: updateValueCard,
});

export function getSelectedDateText(dateTime: { date: string; time: string }) {
    const timeString = dateTime.time ? ', ' + dateTime.time : '';
    const displayDate =
        dateTime.date !== ''
            ? formatDate(dayjs(dateTime.date), localeAtom.subject.value.locale, 'll')
            : '';
    return displayDate + ' ' + timeString;
}

export async function createValueCardReservation(
    valueCard: ValueCardPurchase,
    consumer: MembershipUser,
    company: Company,
) {
    const cardValueTypeId =
        valueCard.membership.id === -1 ? valueCard.credits.id : valueCard.membership.id;

    if (cardValueTypeId === -1) return;

    var campaignId = company.company.valueCardProducts.find((x) => x.id == cardValueTypeId)
        ?.campaign?.id;

    return postValueCardReservation(
        consumer,
        cardValueTypeId,
        company.currentSite.key,
        window.location.href,
        valueCard.membership.dateTime.date,
        campaignId,
        valueCard.giftcardReference,
        valueCard.promoCodeReference,
    );
}

export function isValueCardPurchaseValid() {
    const { category, membership, credits } = valueCardAtom.subject.value;

    if (category === 'membership') {
        if (typeof membership.id == 'string') {
            return membership.id != '' && membership.dateTime.date;
        }
        return membership.id >= 0 && membership.dateTime.date;
    } else return credits.id >= 0;
}
