import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { Translations } from 'src/i18n/translations/types';
import { DifficultyEnum } from 'src/types/parameter-types';
import { capitalize } from 'src/utils/common/TextUtils';
import { Dropdown } from '../dropdown/Dropdown';

const difficultyOptions = [
    DifficultyEnum.Any,
    DifficultyEnum.Easy,
    DifficultyEnum.Medium,
    DifficultyEnum.Hard,
];

export function DifficultyPicker(props: {
    value: DifficultyEnum;
    onChange: (value: DifficultyEnum) => void;
    labelColor?: string;
}) {
    const { t } = useLocale();
    return (
        <Dropdown
            options={difficultyOptions}
            getOptionLabel={(x) => getDifficultyText(x, t)}
            label={capitalize(t.difficulty)}
            {...props}
        />
    );
}

function getDifficultyText(difficulty: DifficultyEnum, t: Translations) {
    switch (difficulty) {
        case DifficultyEnum.Any:
            return t.any;
        case DifficultyEnum.Easy:
            return t.easy;
        case DifficultyEnum.Medium:
            return t.medium;
        case DifficultyEnum.Hard:
            return t.hard;
        default:
            return '';
    }
}
