import { TextFieldProps, Theme } from '@mui/material';
import { Translations } from 'src/i18n/translations/types';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { ThemeType } from 'src/utils/common/theme/ThemeType';

export function getInputLayoutProps(
    t: Translations,
    theme: Theme,
    customizations: ThemeType,
    showErrorDate: boolean,
    endIcon: JSX.Element,
    backgroundColor?: string,
    color?: string,
    labelColor?: string,
    variant?: 'filled' | 'outlined',
): Partial<TextFieldProps> {
    return {
        required: true,
        sx: {
            '& .MuiFilledInput-input': {
                padding: theme.spacing(1.5),
                cursor: 'pointer',
            },
            '& .MuiFilledInput-root': TextFieldStyleUtils.bookingWidgetInputStyle(
                customizations,
                backgroundColor,
                color,
            ),
            '& .MuiInputBase-root': {
                cursor: 'pointer',
            },
        },
        ariaLabel: t.aria_calendar_input_field_label,
        variant: variant ?? 'filled',
        margin: 'dense',
        size: 'small',
        InputProps: {
            readOnly: true,
            sx: showErrorDate
                ? {
                      color: theme.palette.error.main,
                      '& > *': {
                          color: theme.palette.error.main,
                      },
                  }
                : undefined,
            endAdornment: endIcon,
        },
        inputProps: {
            'aria-labelledby': 'calendar-label',
        },
        FormHelperTextProps: {
            color: `${labelColor ?? customizations.bookingWidgetColorContrast} !important`,
        },
    };
}

export function getInputCallbackProps(
    hasChosenDate: boolean,
    setHasChosenDate: (value: boolean) => void,
    deselectTextInput: (e: FocusEvent) => void,
    setAnchorEl: (value: HTMLInputElement | HTMLDivElement | null) => void,
) {
    return {
        onClick: (e: MouseEvent) => {
            if (
                e.currentTarget instanceof HTMLInputElement ||
                e.currentTarget instanceof HTMLDivElement
            ) {
                setAnchorEl(e.currentTarget);
                if (!hasChosenDate) setHasChosenDate(true);
            }
        },
        onKeyDown: (e: KeyboardEvent) => {
            if (
                (e.currentTarget instanceof HTMLInputElement ||
                    e.currentTarget instanceof HTMLDivElement) &&
                e.key === 'Enter'
            ) {
                setAnchorEl(e.currentTarget);
                if (!hasChosenDate) setHasChosenDate(true);
            }
        },
        onFocus: deselectTextInput,
    };
}
