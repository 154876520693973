import { Box, Link, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { ProductCardReadMoreDialog } from '../../product-card-2/ProductCardReadMoreDialog';
import ActivityIcon from './ItineraryActivityIcon';
import HotelIcon from './ItineraryHotelIcon';
import TransferIcon from './ItineraryTransferIcon';

type IconRowProps = {
    title: string;
    id: string;
    subtitle: string;
    productType: 'hotel' | 'activity' | 'transfer';
    shouldDisplayLine: boolean;
    index: number;
    category: string;
};

export default function IconRow(props: IconRowProps) {
    const { id, title, category, subtitle, productType, shouldDisplayLine, index } = props;
    const [isDisplayingMoreInfo, setIsDisplayingMoreInfo] = useState(false);
    const { t } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();

    const focusRef = useRef<HTMLAnchorElement>(null);

    return (
        <Box
            sx={{
                py: 2,
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '80px 1fr',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    height: '100%',
                    gridColumn: 1,
                    justifySelf: 'center',
                }}
            >
                {shouldDisplayLine && <BackgroundLine color="#000000" />}
                <Box sx={{ width: '64px', height: '64px', ml: '5px' }}>
                    <Icon productType={productType} id={index.toString()} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    ml: 2,
                    gridColumn: 2,
                    justifySelf: 'flex-start',
                }}
            >
                <Typography variant="h6" sx={{ lineHeight: '1.0' }}>
                    {title}
                </Typography>
                <Typography variant="body1">{category}</Typography>
                <Typography variant="body1">{subtitle}</Typography>
                <Link
                    onClick={() => {
                        setIsDisplayingMoreInfo(true);
                    }}
                    sx={{
                        display: 'block',
                        color: `${customizations.linkColor} !important`,
                        fontFamily: theme.typography.body1.fontFamily ?? '',
                    }}
                >
                    {t.read_more}
                </Link>
            </Box>
            {id && (
                <ProductCardReadMoreDialog
                    focusRef={focusRef}
                    display={isDisplayingMoreInfo}
                    setDisplay={setIsDisplayingMoreInfo}
                    productId={Number(id)}
                />
            )}
        </Box>
    );
}

function Icon(props: { id: string; productType: 'hotel' | 'activity' | 'transfer' }) {
    switch (props.productType) {
        case 'hotel':
            return <HotelIcon id={props.id} />;
        case 'activity':
            return <ActivityIcon id={props.id} />;
        case 'transfer':
            return <TransferIcon id={props.id} />;
    }
}

function BackgroundLine(props: { color: string }) {
    return (
        <div
            style={{
                position: 'absolute',
                paddingBottom: 32,
                top: 4,
                left: '50%',
                zIndex: -1,
                backgroundColor: props.color,
                height: '100%',
                width: '3px',
            }}
        />
    );
}
