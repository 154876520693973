import produce from 'immer';
import { Iso2Code, norway } from 'src/components/common/country-select/countries';

import { atom } from 'ximple';
import { Action } from './reducers';

export type Contact = {
    content: {
        firstName: string;
        surname: string;
        address: string;
        zipcode: string;
        country: Iso2Code | undefined;
        location: string;
        phone: string;
        phonePrefix: string;
        email: string;
        privacyPolicy: boolean;
        terms: boolean;
        receiveNewsletter: boolean;
    };
    validation: {
        firstName: boolean;
        surname: boolean;
        address: boolean;
        zipcode: boolean;
        country: boolean;
        location: boolean;
        phone: boolean;
        phonePrefix: boolean;
        email: boolean;
        privacyPolicy: boolean;
        terms: boolean;
    };
};

export type ContactAction =
    | 'INITIALIZE'
    | 'RESET'
    | 'UPDATE_FIRST_NAME'
    | 'UPDATE_SURNAME'
    | 'UPDATE_ADDRESS'
    | 'UPDATE_LOCATION'
    | 'UPDATE_COUNTRY'
    | 'UPDATE_ZIPCODE'
    | 'UPDATE_PHONE'
    | 'UPDATE_PHONE_PREFIX'
    | 'UPDATE_EMAIL'
    | 'READ_PRIVACY_POLICY'
    | 'UPDATE_VALIDATION'
    | 'UPDATE_RECEIVE_NEWSLETTER'
    | 'READ_TERMS';

export const contactReducer = produce((draft: Contact, action: Action<ContactAction>) => {
    switch (action.type) {
        case 'UPDATE_FIRST_NAME':
            draft.content.firstName = action.value;
            break;
        case 'UPDATE_SURNAME':
            draft.content.surname = action.value;
            break;
        case 'UPDATE_ADDRESS':
            draft.content.address = action.value;
            break;
        case 'UPDATE_LOCATION':
            draft.content.location = action.value;
            break;
        case 'UPDATE_PHONE':
            draft.content.phone = action.value;
            break;
        case 'UPDATE_PHONE_PREFIX':
            draft.content.phonePrefix = action.value;
            break;
        case 'UPDATE_EMAIL':
            draft.content.email = action.value;
            break;
        case 'UPDATE_COUNTRY':
            draft.content.country = action.value;
            break;
        case 'UPDATE_ZIPCODE':
            draft.content.zipcode = action.value;
            break;
        case 'READ_PRIVACY_POLICY':
            draft.content.privacyPolicy = action.value;
            break;
        case 'READ_TERMS':
            draft.content.terms = action.value;
            break;
        case 'UPDATE_VALIDATION':
            draft.validation = action.value;
            break;
        case 'UPDATE_RECEIVE_NEWSLETTER':
            draft.content.receiveNewsletter = action.value;
            break;
        case 'INITIALIZE':
            draft.content = action.value;
            draft.validation = {
                firstName: false,
                surname: false,
                address: false,
                location: false,
                zipcode: false,
                country: false,
                phone: false,
                phonePrefix: false,
                email: false,
                privacyPolicy: false,
                terms: false,
            };
            break;
        case 'RESET':
            return {
                content: {
                    firstName: '',
                    surname: '',
                    address: '',
                    location: '',
                    zipcode: '',
                    country: norway,
                    phone: '',
                    phonePrefix: '47',
                    email: '',
                    privacyPolicy: false,
                    terms: false,
                    receiveNewsletter: false,
                },
                validation: {
                    firstName: false,
                    surname: false,
                    address: false,
                    location: false,
                    zipcode: false,
                    country: false,
                    phone: false,
                    phonePrefix: false,
                    email: false,
                    privacyPolicy: false,
                    terms: false,
                },
            } as Contact;
        default:
            break;
    }
});

export const contactAtom = atom<Contact, Action<ContactAction>>({
    initialValue: {
        content: {
            firstName: '',
            surname: '',
            address: '',
            location: '',
            zipcode: '',
            country: norway,
            phone: '',
            phonePrefix: '47',
            email: '',
            privacyPolicy: false,
            terms: false,
            receiveNewsletter: false,
        },
        validation: {
            firstName: false,
            surname: false,
            address: false,
            location: false,
            zipcode: false,
            country: false,
            phone: false,
            phonePrefix: false,
            email: false,
            privacyPolicy: false,
            terms: false,
        },
    },
    persistKey: 'no.bilberry-timeslots.contact-info',
    update: contactReducer,
});
