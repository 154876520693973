import { Box } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductOverview from './overview/ProductOverview';

export default function ProductOverviewWidgetRoot(props: { productCatalogId: number }) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(props.productCatalogId, locale, config);

    return (
        <Box>
            <ProductOverview product={productCatalog} />
        </Box>
    );
}
