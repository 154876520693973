import {
    Accessible,
    Help,
    Kitchen,
    LocalDining,
    PersonOutlined,
    Pets,
    SmokingRooms,
    SvgIconComponent,
    Wifi,
} from '@mui/icons-material';
import { Translations } from 'src/i18n/translations/types';
import { BilberryAccommodation, FacilitiesAttributes } from 'src/types/bilberry-hotels-api-types';

export function getIconSet(
    accommodation: BilberryAccommodation,
    numberOfIcons: number,
    t: Translations,
    iconDecorator: (icon: SvgIconComponent, label: string) => JSX.Element,
) {
    const guestIcons = getGuestIcons(t, accommodation.capacity) ?? [];
    const importantFacilities = getImportantFacilities(accommodation.attributes?.facilities);
    const facilityIcons = importantFacilities.map((facility) => toIconData(facility, t));

    const icons = [...guestIcons, ...facilityIcons]
        .slice(0, numberOfIcons)
        .map(({ icon, label }) => iconDecorator(icon, label));

    return icons;
}

function getImportantFacilities(facilities?: FacilitiesAttributes) {
    if (!facilities) return [];

    const importantKeys = [
        'isBreakfastIncluded',
        'hasWifi',
        'isWheelchairAccessible',
        'hasKitchen',
        'isPetsAllowed',
        'isSmokingAllowed',
    ];

    return importantKeys.filter((facility) =>
        Object.entries(facilities).some(([key, value]) => key === facility && !!value),
    ) as (keyof FacilitiesAttributes)[];
}

function toIconData(facility: keyof FacilitiesAttributes, t: Translations) {
    switch (facility) {
        case 'hasWifi':
            return { icon: Wifi, label: t.has_wifi };
        case 'isBreakfastIncluded':
            return { icon: LocalDining, label: t.breakfast };
        case 'isWheelchairAccessible':
            return { icon: Accessible, label: t.accessible };
        case 'isPetsAllowed':
            return { icon: Pets, label: t.pets };
        case 'hasKitchen':
            return { icon: Kitchen, label: t.kitchen };
        case 'isSmokingAllowed':
            return { icon: SmokingRooms, label: t.smoking };
        default:
            return { icon: Help, label: t.unknown };
    }
}

function getGuestIcons(t: Translations, count?: number) {
    if (!count) return [];
    return [
        {
            icon: PersonOutlined,
            label: `${count} ${t.person.num(count)}`,
        },
    ];
}
