import { useLocale } from 'src/i18n/locale';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { CustomizationsContext, useCustomizations } from 'src/utils/common/theme/customizations';
import { ThemeType } from 'src/utils/common/theme/ThemeType';
import ProductCardListContainer from '../product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from '../product/product-list-2/product-card-list-2/ProductCardListItem';
import PackageCardInfo from 'src/components/domain/package/package-list/PackageCard';
import { usePackages } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type IProps = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
};

export default function PackageList(props: IProps): JSX.Element {
    const {
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
    } = props;
    const { locale } = useLocale();
    const config = useConfigurations();
    const customizations = useCustomizations();
    const { pkgs } = usePackages(locale, config);

    const packageList = pkgs?.map((pkg, i) => {
        const url = pkg.web_url;
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
        };

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={pkgs.length}
                >
                    <PackageCardInfo pkg={pkg} url={url} />
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {packageList.length > 0 && packageList}
        </ProductCardListContainer>
    );
}
