import { h } from 'preact';
import { route } from 'preact-router';
import Summary from 'src/components/domain/summary/Summary';
import { useLocale } from 'src/i18n/locale';

export default function BasketPopUp(props: { onCloseModal: () => void }): JSX.Element {
    const { t } = useLocale();

    const handleGoToCheckoutClicked = () => {
        props.onCloseModal();
        route('/checkout');
    };

    return (
        <Summary
            heading={t.your_cart}
            invertedColors={true}
            isCheckoutSummary={true}
            goToCheckout={handleGoToCheckoutClicked}
            onCloseModal={props.onCloseModal}
            hideGoPay={true}
            showShare
        />
    );
}
