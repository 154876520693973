import { omit } from 'lodash-es';
import { Fragment } from 'preact';
import Itinerary from 'src/components/domain/package/itinerary/Itinerary';
import { useLocale } from 'src/i18n/locale';
import { BilberryPackage } from 'src/types/bilberry-api-types';
import { usePackage } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type ProductType = 'hotel' | 'activity' | 'transfer';

type ItineraryItem = {
    id: string;
    index: number;
    dayIndex: number;
    title: string;
    productType: ProductType;
    subtitle: string;
    category: string;
};

export default function PackageContainer({ id }: { id: number }) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { pkg: tourPackage } = usePackage(id, locale, config);

    if (!tourPackage) {
        return <Fragment></Fragment>;
    }

    const items = asItinerary(tourPackage);

    return <Itinerary items={items} />;
}

function asItinerary(tourPackage: BilberryPackage): ItineraryItem[] {
    const packageProducts = tourPackage.ticket_options.flatMap((option) =>
        option.products.map((product) => ({ ...omit(option, 'products'), ...product })),
    );

    const itineraryProducts = packageProducts.map((p, i) => ({
        id: p.product_id.toString(),
        index: i,
        dayIndex: Math.floor(p.minutes_relative_start / 1440),
        title: p.product_name,
        productType: (p.is_accommodation ? 'hotel' : 'activity') as ProductType,
        category: '',
        subtitle: p.name,
    }));

    return itineraryProducts;
}
