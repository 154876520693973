import { BilberryPackage, BilberryProduct, BilberryProductCatalog } from '../bilberry-api-types';

export function isBilberryProductCatalog(obj: any): obj is BilberryProductCatalog {
    return !!obj && 'id' in obj && 'name' in obj && 'duration' in obj && 'default_prices' in obj;
}

export function isBilberryPackage(obj: any): obj is BilberryPackage {
    return (
        !!obj &&
        'id' in obj &&
        'title' in obj &&
        'short_description' in obj &&
        'full_description' in obj &&
        'media' in obj
    );
}

export function isBilberryProduct(obj: any): obj is BilberryProduct {
    return (
        !!obj &&
        'id' in obj &&
        'title' in obj &&
        'start' in obj &&
        'end' in obj &&
        'prices' in obj &&
        'guest_list_dependant' in obj &&
        'order_entity_dependant' in obj
    );
}
