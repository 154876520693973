import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import {
    cancelReservation,
    getAvailableValueCardProductsForUser,
    getUser,
    getValueCards,
} from 'src/utils/domain/api/membership-api';
import {
    MembershipBooking,
    MembershipCreditBalanceResponse,
    MembershipUserValueCard,
    MembershipValueCardProduct,
} from 'src/types/membership-api-types';
import { atom } from 'ximple';
import { Action } from './reducers';
import { Iso2Code } from 'src/components/common/country-select/countries';
import { formatDate } from 'src/utils/common/DateHelpers';
import { localeAtom } from 'src/i18n/locale';
import { isAuthenticatedAtom } from './authentication';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';

export type User = {
    info: {
        firstName: string;
        surname: string;
        phone: string;
        phonePrefix: string;
        email: string;
    };
    address: {
        address: string;
        zipcode: string;
        location: string;
        country: Iso2Code;
    };
    membership?: {
        startDate: string;
        endDate: string;
    };
    credits: {
        amount: number;
    };
    bookings: MembershipBooking[];
    valueCardProducts?: MembershipValueCardProduct[];
    valueCards: (MembershipUserValueCard & {
        creditsLeft: number;
        cancellation?: {
            fromDate: Dayjs;
            nextChargeAt: Dayjs;
        };
    })[];
};

export type UserAction =
    | 'INITIALIZE'
    | 'UPDATE_INFO'
    | 'UPDATE_FIRST_NAME'
    | 'UPDATE_SURNAME'
    | 'UPDATE_EMAIL'
    | 'UPDATE_PHONE'
    | 'UPDATE_PHONE_PREFIX'
    | 'UPDATE_FULL_ADDRESS'
    | 'UPDATE_ADDRESS'
    | 'UPDATE_ZIPCODE'
    | 'UPDATE_LOCATION'
    | 'UPDATE_COUNTRY'
    | 'CANCEL_MEMBERSHIP'
    | 'PURCHASE_MEMBERSHIP'
    | 'REFILL_CREDITS'
    | 'UPDATE_BOOKINGS'
    | 'EDIT_BOOKING'
    | 'DELETE_BOOKING'
    | 'CANCEL_BOOKING'
    | 'SAVE'
    | 'UPDATE_MEMBERSHIP'
    | 'UPDATE_VALUECARDS'
    | 'UPDATE_VALUECARD_PRODUCTS'
    | 'UPDATE_CREDITS'
    | 'DELETE_PROFILE';

export const userReducer = produce((draft: User, action: Action<UserAction>) => {
    switch (action.type) {
        case 'INITIALIZE':
            return action.value as User;
        case 'UPDATE_INFO':
            draft.info = action.value;
            break;
        case 'UPDATE_FULL_ADDRESS':
            draft.address = action.value;
            break;
        case 'UPDATE_FIRST_NAME':
            draft.info.firstName = action.value;
            break;
        case 'UPDATE_SURNAME':
            draft.info.surname = action.value;
            break;
        case 'UPDATE_PHONE':
            draft.info.phone = action.value;
            break;
        case 'UPDATE_PHONE_PREFIX':
            draft.info.phonePrefix = action.value;
            break;
        case 'UPDATE_EMAIL':
            draft.info.email = action.value;
            break;
        case 'UPDATE_ADDRESS':
            draft.address.address = action.value;
            break;
        case 'UPDATE_ZIPCODE':
            draft.address.zipcode = action.value;
            break;
        case 'UPDATE_LOCATION':
            draft.address.location = action.value;
            break;
        case 'UPDATE_COUNTRY':
            draft.address.country = action.value;
            break;
        case 'SAVE':
            // TODO: Call api and save updated info
            break;
        case 'UPDATE_BOOKINGS':
            draft.bookings = action.value;
            break;
        case 'EDIT_BOOKING':
            // TODO: Open popover booking widget
            break;
        case 'DELETE_BOOKING':
            break;
        case 'CANCEL_BOOKING':
            if (action.value.orderReference) {
                const cancel = cancelReservation(action.value.orderReference);
                draft.bookings = draft.bookings.filter((x) => x.orderId !== action.value.orderId);
            }
            break;
        case 'UPDATE_MEMBERSHIP':
            draft.membership = action.value;
            break;
        case 'UPDATE_VALUECARDS':
            draft.valueCards = action.value;
            break;
        case 'UPDATE_VALUECARD_PRODUCTS':
            draft.valueCardProducts = action.value;
            break;
        case 'UPDATE_CREDITS':
            draft.valueCards = draft.valueCards.map((valueCard) => {
                const foundValueCard = action.value.find(
                    (x: MembershipCreditBalanceResponse) => x.valueCardId === valueCard.id,
                );
                if (!foundValueCard) return valueCard;

                valueCard = { ...valueCard, creditsLeft: foundValueCard.creditsAvailable };
                return valueCard;
            });
            break;
        case 'DELETE_PROFILE':
        //TODO: delete profile
        default:
            break;
    }
});

export const userAtom = atom<User, Action<UserAction>>({
    initialValue: {
        info: {
            firstName: '',
            surname: '',
            phone: '',
            phonePrefix: '47',
            email: '',
        },
        address: {
            address: '',
            zipcode: '',
            location: '',
            country: 'no',
        },
        membership: {
            startDate: 'string',
            endDate: 'string',
        },
        credits: {
            amount: 0,
        },
        bookings: [],
        valueCards: [],
    },
    update: userReducer,
});

export function getValueCardDateRange(validFrom: string, validTo: string) {
    return {
        startDate: formatDate(dayjs(validFrom), localeAtom.subject.value.locale, 'll'),
        endDate: formatDate(dayjs(validTo), localeAtom.subject.value.locale, 'll'),
    };
}

export function configureUser() {
    isAuthenticatedAtom.subject.subscribe(async (authentication) => {
        if (!authentication.user) return;

        await getUser();
        await updateValueCardProductsForUser();
        await getValueCards(configurationAtom.subject.value.companyKey ?? '');
    });
}

export async function updateValueCardProductsForUser() {
    await getAvailableValueCardProductsForUser(configurationAtom.subject.value.siteKey ?? '');
}
