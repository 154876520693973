import { atom, useAtom } from 'ximple';
import { allTranslations, defaultTranslation } from './i18n';
import { Translations } from './translations/types';
import enGB from 'dayjs/locale/en-gb';
import nb from 'dayjs/locale/nb';
import de from 'dayjs/locale/de';
import nl from 'dayjs/locale/nl';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import enCA from 'dayjs/locale/en-ca';

export type LocaleContextData = {
    readonly locale: string;
    t: Translations;
    readonly lastOverriddenWithType: LocaleOverrideType;
    readonly dayjsLocale: any;
};

export enum LocaleOverrideType {
    JS_OVERRIDE = 3,
    ATTRIBUTE_OVERRIDE = 2,
    DEFAULT = 1,
}

declare const window: any;

export const SUPPORTED_LANGUAGES: { [key: string]: string } = {
    en: 'en',
    nb: 'nb-NO',
    no: 'nb-NO',
    'nb-NO': 'nb-NO',
    nn: 'nb-NO',
    'nn-NO': 'nb-NO',
    nl: 'nl',
    de: 'de',
    fr: 'fr',
    it: 'it',
    es: 'es',
};

function getSupportedLanguage(lang: string): string {
    const supportedLang = SUPPORTED_LANGUAGES[lang] ?? SUPPORTED_LANGUAGES[lang.split('-')[0]];
    if (!supportedLang) {
        return SUPPORTED_LANGUAGES['en'];
    }
    return supportedLang;
}

const browserLocale = getSupportedLanguage(
    window.BilberryWidgetsGlobal?.language ?? navigator.language,
);

const defaultValue: LocaleContextData = {
    locale: browserLocale,
    t: getTranslation(browserLocale),
    lastOverriddenWithType: LocaleOverrideType.DEFAULT,
    dayjsLocale: getDayjsLocale(browserLocale),
};

function localeAtomReducer(
    state: LocaleContextData,
    action: [string, LocaleOverrideType],
): LocaleContextData {
    const [locale, overrideType] = action;

    if (allowOverride(state.lastOverriddenWithType, overrideType)) {
        return {
            locale,
            t: getTranslation(locale),
            lastOverriddenWithType: overrideType,
            dayjsLocale: getDayjsLocale(locale),
        };
    }

    return state;
}

export const localeAtom = atom<LocaleContextData, [string, LocaleOverrideType]>({
    initialValue: defaultValue,
    update: localeAtomReducer,
});

export function overrideLocale(locale: string, overrideType: LocaleOverrideType) {
    localeAtom.update([locale, overrideType]);
}

window.BilberryOverrideLanguage = function (locale: string) {
    overrideLocale(locale, LocaleOverrideType.JS_OVERRIDE);
};

function allowOverride(
    lastOverriddenWithType: LocaleOverrideType,
    newOverrideType: LocaleOverrideType,
) {
    return newOverrideType >= lastOverriddenWithType;
}

function getTranslation(locale: string) {
    let translation = allTranslations[locale];
    if (!translation) {
        const [first, second] = locale.toLowerCase().split('-');
        const firstLang = allTranslations[first];
        const secondLang = allTranslations[second];
        translation = firstLang ?? secondLang ?? defaultTranslation;
    }
    return translation;
}

export function getDayjsLocale(locale: string): any {
    const norwegianLocales = ['nb-NO', 'nn-NO', 'no-NO', 'no', 'nb', 'nn'];

    if (locale === 'en-US') {
        return 'en';
    } else if (locale === 'en-CA') {
        return enCA;
    } else if (norwegianLocales.includes(locale)) {
        return nb;
    } else if (locale === 'de') {
        return de;
    } else if (locale === 'nl') {
        return nl;
    } else if (locale === 'es') {
        return es;
    } else if (locale === 'fr') {
        return fr;
    } else if (locale === 'it') {
        return it;
    }

    return enGB;
}

/**
 * Wait for BilberryWidgetsGlobal.language to be added to the page (it might be added after the main script tags).
 * Also wait in case the language is set twice (once generally in head, and once in the body)
 * Do not wait for more than 5 seconds.
 **/
function waitForLang(timeWaiting: number) {
    const data = localeAtom.subject.value;
    const { lastOverriddenWithType } = data;

    if (
        !window.BilberryWidgetsGlobal.language ||
        lastOverriddenWithType === LocaleOverrideType.DEFAULT
    ) {
        setTimeout(() => {
            if (window.BilberryWidgetsGlobal?.language) {
                overrideLocale(window.BilberryWidgetsGlobal.language, LocaleOverrideType.DEFAULT);
                return;
            } else if (timeWaiting > 5000) {
                return;
            } else {
                waitForLang(timeWaiting + 100);
            }
        }, 100);
    }
}
waitForLang(100);

export function useLocale() {
    return useAtom(localeAtom)[0];
}
