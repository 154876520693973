import { useMemo } from 'preact/hooks';
import TourProductCardList from 'src/components/domain/tour-product-card/TourProductCardList';
import { useLocale } from 'src/i18n/locale';
import { BilberryProduct, BilberrySmartEventPlan } from 'src/types/bilberry-api-types';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { groupToursByProduct } from 'src/utils/domain/activities/tours';
import { useSmartEvent } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    id: number;
    hideReadMore?: boolean;
};

export default function SmartEventList(props: Props) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { event } = useSmartEvent(props.id, locale, config);

    const tours = useMemo(() => {
        const toursWithId = event?.tours.map(asBilberryProduct) ?? [];
        return groupToursByProduct(toursWithId, 'product_catalog_id');
    }, [event?.tours]);

    return <TourProductCardList tours={tours} {...props} />;
}

function asBilberryProduct(plan: BilberrySmartEventPlan): BilberryProduct {
    return {
        id: plan.tour_id,
        product_catalog_id: plan.product_id,
        group_size: -1,
        web_title: plan.title,
        cancellation_time: -1,
        cutoff_time: -1,
        cut_off_date: null,
        associated_products: [],
        location: null,
        web_additional_info: '',
        web_disclaimer: '',
        web_full_description: '',
        web_short_description: plan.description,
        web_category: '',
        web_times: '',
        ...plan,
    };
}
