import { Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { h } from 'preact';
import { GiftTypeEnum } from 'src/components/domain/gift-type-selection/GiftTypeSelections';
import { useLocale } from 'src/i18n/locale';
import SummaryGiftcardBooking from '../SummaryGiftcardBooking/SummaryGiftcardbooking';
import SummaryGiftcardPayment from '../SummaryGiftcardPayment/SummaryGiftcardPayment';
import SummaryGiftcardVat from '../SummaryGiftcardVat/SummaryGiftcardVat';

interface IProps {
    giftcardTotal: number;
    selectedGiftType: GiftTypeEnum | null;
    showPrintButton?: boolean;
    showNextButton?: boolean;
    goPayClicked?: () => void;
    isCreatingGiftcard?: boolean;
    expiryDate?: string;
}

export default function SummaryGiftcardReceipt(props: IProps): JSX.Element {
    const {
        giftcardTotal,
        showPrintButton = false,
        showNextButton = false,
        goPayClicked,
        isCreatingGiftcard = false,
    } = props;
    const { t } = useLocale();
    const theme = useTheme();
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Paper
            sx={{
                marginTop: theme.spacing(2),
                padding: theme.spacing(3, 3, 2, 3),
                [theme.breakpoints.down('md')]: {
                    maxWidth: '440px',
                },
                width: '100%',
                backgroundColor: theme.palette.grey[50],
                color: theme.palette.primary.main,
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
            variant="outlined"
        >
            <Typography
                color="textSecondary"
                variant="h4"
                pb={theme.spacing(2)}
                fontWeight={500}
                fontSize={28}
            >
                {t.summary}
            </Typography>
            <SummaryGiftcardBooking
                giftcardTotal={giftcardTotal}
                selectedGiftType={props.selectedGiftType}
                largeScreen={largeScreen}
                expiryDate={props.expiryDate}
            />
            <SummaryGiftcardVat giftcardPrice={giftcardTotal} />
            <SummaryGiftcardPayment
                showPrintButton={showPrintButton}
                showNextButton={showNextButton}
                totalPrice={giftcardTotal}
                goPayClicked={goPayClicked}
                isCreatingGiftcard={isCreatingGiftcard}
            />
        </Paper>
    );
}
