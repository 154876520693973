import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import invert from 'lodash-es/invert';
import { getDialcodes } from 'src/components/common/country-select/countries';
import { PhoneNumber } from 'src/types/checkout-info';

export function validateTextField(value: string) {
    return value.length !== 0;
}
export function validateEmail(email: string) {
    return /^.+@.+\..+$/.test(email);
}

export function validatePhone(phone: PhoneNumber) {
    const countriesByDialCode = invert(getDialcodes());
    const isValidDialCode = countriesByDialCode[phone.dialCode];
    const isValidNumber = /^[0-9]{4,}$/.test(phone.number); //At least 4 digits
    return isValidDialCode && isValidNumber;
}

export function validateDateRange(value: DateRange<Dayjs>) {
    return value.some((date) => date);
}
