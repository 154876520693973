/**
 * Replace last part of image url (after id) with "/conversions/{name}-{conversion}.jpg
 *
 * Currently only uses the 4-3 format in sizes S, M and L
 *
 * @param {string | null} url
 * @param {"s" | "m" | "l"} size
 * @returns {string | null}
 */
export function getConvertedImageUrl(
    url: string | null | undefined,
    size: 's' | 'm' | 'l',
    format: '4_3' | '16_9',
): string | null {
    if (!url) return null;
    if (url && !/https:\/\/tripkit.+Bilberries/.test(url)) return null;

    const sConversion = `-${format}-s`;
    const mConversion = `-${format}-m`;
    const lConversion = `-${format}-l`;

    const parts = url.split('/');
    const conversionUrl = [
        ...parts.slice(0, parts.length - 1),
        'conversions',
        parts[parts.length - 1],
    ]
        .join('/')
        .replace('.jpeg', '.jpg'); // only .jpg is supported by the conversion jobs in Bilberry

    const [, filename] = conversionUrl.split('.').reverse();
    switch (size) {
        case 's':
            return conversionUrl.replace(filename, filename + sConversion);
        case 'm':
            return conversionUrl.replace(filename, filename + mConversion);
        case 'l':
            return conversionUrl.replace(filename, filename + lConversion);
        default:
            return url;
    }
}
