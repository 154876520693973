declare const window: any;

import { DIBS_CHECKOUT_SRC } from 'src/__autogen/env';
import { initStripePayment } from 'src/widgets/stripe/initStripeWidget';

function initDibs() {
    // This try / catch is needed because the loading of resources is more volatile when
    // deployed in production than when developing, and because the DOMContentLoaded event
    // appears to trigger before this code is executed, hence the code inside is not run when in dev.
    try {
        AddDibsScript();
    } catch (_e) {
        window.addEventListener('DOMContentLoaded', () => {
            AddDibsScript();
        });
    }

    function AddDibsScript() {
        const dibsScript = document.createElement('script');
        dibsScript.type = 'text/javascript';
        dibsScript.src = DIBS_CHECKOUT_SRC;
        document.body.appendChild(dibsScript);
    }
}

export function setupPaymentProvider(checkoutElement?: HTMLElement) {
    if (!window.BilberryWidgetsGlobal.paymentProvider || !checkoutElement) initDibs();
    switch (window.BilberryWidgetsGlobal.paymentProvider) {
        case 'stripe': {
            initStripePayment(checkoutElement!);
            break;
        }
        case 'nets': {
            initDibs();
            break;
        }
    }
}
