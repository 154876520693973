import { getCsvAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { errorLog } from 'src/utils/common/Logger';

function isValidColor(color: string) {
    if (!CSS) return true;
    return CSS.supports(`color: ${color}`);
}

function logInvalidColor(color: string, attribute: string, elementName: string) {
    if (CSS && !CSS.supports(`color: ${color}`))
        // We would like to throw an error here, however customers may already have invalid colors
        // in production. If so, throwing an error will break existing solutions.
        errorLog(
            `Element <${elementName}>'s attribute "${attribute}" contains invalid color "${color}"`,
        );
}

function logInvalidLength(colors: string[][], elementName: string) {
    const lengths = colors.map((x) => x.length).filter((x) => x !== 0);
    if (!lengths) return;
    const [head, ...tail] = lengths;
    if (!tail.every((x) => x === head))
        errorLog(`Element <${elementName}>'s color attributes have inconsistent lengths`);
}

export function getColorAttributes(elem: Element) {
    const backgroundColor = elem.getAttribute('background-color');
    const textColor = elem.getAttribute('text-color');
    const primaryColor = elem.getAttribute('primary-color');
    const primaryTextColor = elem.getAttribute('primary-text-color');
    const accentColor = elem.getAttribute('accent-color');
    const accentTextColor = elem.getAttribute('accent-text-color');

    backgroundColor && logInvalidColor(backgroundColor, 'background-color', elem.localName);
    textColor && logInvalidColor(textColor, 'text-color', elem.localName);
    primaryColor && logInvalidColor(primaryColor, 'primary-color', elem.localName);
    primaryTextColor && logInvalidColor(primaryTextColor, 'primary-text-color', elem.localName);
    accentColor && logInvalidColor(accentColor, 'accent-color', elem.localName);
    accentTextColor && logInvalidColor(accentTextColor, 'accent-text-color', elem.localName);

    return {
        backgroundColor,
        textColor,
        primaryColor,
        primaryTextColor,
        accentColor,
        accentTextColor,
    };
}

export function getCsvColorsAttributes(elem: Element) {
    const backgroundColors = getCsvAttribute(
        elem,
        'background-colors',
        true,
        isValidColor,
        'background-color',
    );
    const textColors = getCsvAttribute(elem, 'text-colors', true, isValidColor, 'text-color');
    const primaryColors = getCsvAttribute(
        elem,
        'primary-colors',
        true,
        isValidColor,
        'primary-color',
    );
    const primaryTextColors = getCsvAttribute(
        elem,
        'primary-text-colors',
        true,
        isValidColor,
        'primary-text-color',
    );
    const accentColors = getCsvAttribute(elem, 'accent-colors', true, isValidColor, 'accent-color');
    const accentTextColors = getCsvAttribute(
        elem,
        'accent-text-colors',
        true,
        isValidColor,
        'accent-text-color',
    );

    logInvalidLength(
        [
            backgroundColors,
            textColors,
            primaryColors,
            primaryTextColors,
            accentColors,
            accentTextColors,
        ],
        elem.localName,
    );

    return {
        backgroundColors,
        textColors,
        primaryColors,
        primaryTextColors,
        accentColors,
        accentTextColors,
    };
}
