import {
    ICartExtra,
    BilberryPriceQuantity,
    BookingPriceAndQuantity,
    ICartItem,
} from 'src/state/cart/ICart';
import {
    BilberryGiftcardStatus,
    BilberryProductPrice,
    PackagePrice,
} from 'src/types/bilberry-api-types';
import { AccommodationPrice } from 'src/types/bilberry-hotels-api-types';
import {
    isAccommodationPriceType,
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
    isPriceQuantityType,
} from './cart/cartUtils';
import { calculateMultiDayPriceQuantities } from './booking/multiDayPriceHelper';
import { getTotalPackagePrice, getTotalPackageVatAmount } from './booking/packagePriceHelper';

export type PriceSummary = {
    totalPrice: number;
    totalPriceExVat: number;
    totalVat: number;
    vatAmountInfo: { [vat: string]: number };
};

export function getPriceSummaryFromCartItems(cartItems: ICartItem[]): PriceSummary {
    const priceQuantities = [
        ...cartItems.flatMap(
            (cartItem): (BilberryPriceQuantity | AccommodationPrice | PackagePrice)[] => {
                const item = cartItem.item;
                if (isICartProductItemType(item)) {
                    return item.quantities;
                } else if (isICartMultiDayProductItemType(item)) {
                    return calculateMultiDayPriceQuantities(item.quantities, item.products);
                } else if (isICartPackageItemType(item)) {
                    return [
                        {
                            id: item.pkg.id,
                            price: getTotalPackagePrice(item.quantities, item.vats),
                            vat_amount: getTotalPackageVatAmount(item.quantities, item.vats),
                        },
                    ];
                }
                return [item.price];
            },
        ),
        ...cartItems.flatMap((cartItem) =>
            isICartProductItemType(cartItem.item) ||
            isICartMultiDayProductItemType(cartItem.item) ||
            isICartPackageItemType(cartItem.item)
                ? cartItem.item.extras.flatMap((extra) => extra.quantities)
                : [],
        ),
    ];

    return getPriceSummaryFromPriceQuantities(priceQuantities);
}

export function getGiftcardSpent(giftcard: BilberryGiftcardStatus, totalPrice: number): number {
    let priceReduction;
    if (totalPrice < giftcard.balance) {
        priceReduction = totalPrice;
    } else {
        priceReduction = giftcard.balance;
    }

    return priceReduction;
}

export function getInitialQuantityData<T>(
    prices: T[],
    minEntrants: number | undefined | null,
): Array<T & { quantity: number }> {
    if (prices.length === 1) {
        return prices.map((price) => ({
            ...price,
            quantity: minEntrants ?? 1,
        }));
    }

    const initialAdults = minEntrants ? Math.ceil(minEntrants / 2) : 1;
    const initialChildren = minEntrants ? Math.floor(minEntrants / 2) : 0;

    return prices.map((price, i) => ({
        ...price,
        quantity: i === 0 ? initialAdults : i === 1 ? initialChildren : 0,
    }));
}

export function updateQuantityData<T extends Omit<BookingPriceAndQuantity, 'quantity'>>(
    selectedProductPrices: T[] | undefined,
    defaultPrices: T[],
    quantities: BookingPriceAndQuantity[],
): (T & { quantity: number })[] {
    const allPricesPreferSelected = defaultPrices.map(
        (price) =>
            selectedProductPrices?.find(
                (selectedPrice) => selectedPrice.price_category_id === price.price_category_id,
            ) ?? {...price, should_disable_price: !!selectedProductPrices },
    );
    return allPricesPreferSelected.map((price) => {
        const existingQuantity = quantities.find(
            (quantity) => quantity.price_category_id === price.price_category_id,
        );
        return {
            ...price,
            quantity: existingQuantity ? existingQuantity.quantity : 0,
        };
    });
}

export function getPriceSummaryFromPriceQuantities(
    priceQuantities: (BilberryPriceQuantity | AccommodationPrice | PackagePrice)[],
) {
    const priceSummaries = priceQuantities.map((price) => {
        if (isPriceQuantityType(price)) {
            return getPriceSummaryFromPriceQuantity(price);
        } else if (isAccommodationPriceType(price)) {
            return getPriceSummaryFromAccommodationPrice(price);
        } else {
            return getPriceSummaryFromPackagePrice(price);
        }
    });
    return reducePriceSummaries(priceSummaries);
}

function reducePriceSummaries(priceSummaries: PriceSummary[]) {
    const initialValue: PriceSummary = {
        totalPrice: 0,
        totalPriceExVat: 0,
        totalVat: 0,
        vatAmountInfo: {},
    };

    return priceSummaries.reduce((acc, current) => {
        acc.totalPrice += current.totalPrice;
        acc.totalPriceExVat += current.totalPriceExVat;
        acc.totalVat += current.totalVat;

        acc.vatAmountInfo = mergeVatAmountInfos(acc.vatAmountInfo, current.vatAmountInfo);
        return acc;
    }, initialValue);
}

function getPriceSummaryFromPackagePrice(packagePrice: PackagePrice) {
    return {
        totalPrice: packagePrice.price,
        totalPriceExVat: packagePrice.price - packagePrice.vat_amount,
        totalVat: packagePrice.vat_amount,
        vatAmountInfo: {},
    };
}

function getPriceSummaryFromAccommodationPrice(
    accommodationPrice: AccommodationPrice,
): PriceSummary {
    const totalPrice = accommodationPrice.value;
    return {
        totalPrice,
        totalVat: totalPrice,
        totalPriceExVat: totalPrice,
        vatAmountInfo: {},
    };
}

function getPriceSummaryFromPriceQuantity(priceQuantity: BilberryPriceQuantity): PriceSummary {
    const { quantity } = priceQuantity;
    const totalPrice = priceQuantity.price * quantity;
    const totalPriceExVat = (priceQuantity.price - priceQuantity.vat_amount) * quantity;
    const totalVat = priceQuantity.vat_amount * quantity;

    const initialValue: { [vat: string]: number } = {};

    const vatAmountInfo = priceQuantity.rates.reduce((acc, currentRate) => {
        const vatKey = currentRate.vat.toString();
        if (acc[vatKey] === undefined) {
            acc[vatKey] = 0;
        }

        acc[vatKey] += currentRate.vat_amount * quantity;

        return acc;
    }, initialValue);

    return {
        totalPrice,
        totalVat,
        totalPriceExVat,
        vatAmountInfo,
    };
}

function mergeVatAmountInfos(left: { [vat: string]: number }, right: { [vat: string]: number }) {
    const rightEntries = Object.entries(right);

    return rightEntries.reduce((acc, currentEntry) => {
        const [key, value] = currentEntry;

        if (acc[key] === undefined) {
            acc[key] = 0;
        }

        acc[key] += value;

        return acc;
    }, left);
}

function findAllPricesForExtras(extras: ICartExtra[]) {
    return extras.reduce((prev, current) => {
        return [...prev, ...current.extra.prices];
    }, [] as BilberryProductPrice[]);
}

function findLowestPrice(prices: BilberryProductPrice[]) {
    const minPrice = prices.reduce((prevPrice, currentPrice) => {
        return prevPrice.price < currentPrice.price ? prevPrice : currentPrice;
    });

    return minPrice.price;
}

export function findLowestPriceAmongExtras(extras: ICartExtra[]) {
    const allPrices = findAllPricesForExtras(extras);
    return findLowestPrice(allPrices);
}

export function hasMultiplePrices(extras: ICartExtra[]) {
    const allPrices = findAllPricesForExtras(extras);
    return allPrices.length > 1;
}
