import isEmpty from 'lodash-es/isEmpty';
import { cartAtom } from 'src/state/cart/cartAtom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { deserializeAccommodations } from './deserialization/accommodations';
import { deserializeActivities } from './deserialization/activities';
import { deserializeMultiActivities } from './deserialization/multiDayActivities';
import { deserializePackages } from './deserialization/packages';

export async function loadCartFromQueryParameters() {
    const cartItems = await deserializeCartFromUrl(new URL(window.location.href));

    if (!isEmpty(cartItems)) {
        cartAtom.update({ type: 'INITIALIZE', data: cartItems });
        showBasketAtom.update({ visible: true });
    }
}

async function deserializeCartFromUrl(url: URL) {
    const activityParams = url.searchParams.getAll('activity');
    const accommodationParams = url.searchParams.getAll('accommodation');
    const multiActivityParams = url.searchParams.getAll('multiActivity');
    const packageParams = url.searchParams.getAll('package');
    const [productCartItems, accommdationCartItems, multiActivityCartItems, packageCartItems] =
        await Promise.all([
            deserializeActivities(activityParams),
            deserializeAccommodations(accommodationParams),
            deserializeMultiActivities(multiActivityParams),
            deserializePackages(packageParams),
        ]);

    return {
        ...productCartItems,
        ...accommdationCartItems,
        ...multiActivityCartItems,
        ...packageCartItems,
    };
}
