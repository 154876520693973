export function capitalize(word: string | undefined | null): string {
    return !word ? '' : word.slice(0, 1).toUpperCase() + word.slice(1);
}

export function selectAllInTextInput(e: FocusEvent) {
    if (e.target instanceof HTMLInputElement) {
        if (e.target?.select !== undefined) {
            e.target.select();
        } else {
            e.target?.setSelectionRange(0, e.target.value.length);
        }
    }
}

export function deselectTextInput(e: FocusEvent) {
    if (e.target instanceof HTMLInputElement) e.target?.setSelectionRange(0, 0);
}

export function thousandSeperateNumber(p: number): string {
    return p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function thousandSeperateNumberString(p: string): string {
    return p.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
