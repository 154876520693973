import { alpha } from '@mui/material/styles';
import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { ThemeType } from 'src/utils/common/theme/ThemeType';
import { atom, useAtom } from 'ximple';

declare const window: any;

const _defaultTheme: ThemeType = {
    bookingWidgetColor: '#6a43f9',
    bookingWidgetColorContrast: '#FFFFFF',
    bookingWidgetPrimaryColor: '#FFFFFF',
    bookingWidgetPrimaryColorContrast: window.BilberryCustomTheme?.bookingWidgetColor ?? '#6a43f9',
    bookingWidgetHeaderColor: window.BilberryCustomTheme?.bookingWidgetPrimaryColor ?? '#FFFFFF',
    bookingWidgetInputColor: '#FFFFFF',
    bookingWidgetInputTextColor: '#000000',
    bookingSearchFormColor: '#6a43f9',
    bookingSearchFormColorContrast: '#FFFFFF',
    bookingSearchFormInputColor: '#FFFFFF',
    bookingSearchFormInputTextColor: '#000000',
    accommodationSearchFormColor: '#6a43f9',
    accommodationSearchFormColorContrast: '#FFFFFF',
    accommodationSearchFormInputColor: '#FFFFFF',
    accommodationSearchFormInputTextColor: '#000000',
    accommodationSearchFormBookNowButtonColor: '#6a43f9',
    accommodationSearchFormBookNowButtonTextColor: '#FFFFFF',
    bookingButtonColor: window.BilberryCustomTheme?.primaryColor ?? '#6a43f9',
    bookingButtonTextColor: window.BilberryCustomTheme?.primaryColorContrast ?? '#FFFFFF',
    bookingButtonStyle: 'contained',
    basketIconColor: window.BilberryCustomTheme?.bookingWidgetColor ?? '#6a43f9',
    basketIconTextColor: window.BilberryCustomTheme?.bookingWidgetColorContrast ?? '#FFFFFF',
    basketIconBorderColor: window.BilberryCustomTheme?.bookingWidgetColorContrast ?? '#FFFFFF',
    basketIconCountColor: window.BilberryCustomTheme?.bookingWidgetColor ?? '#6a43f9',
    checkoutHeaderColor: '#f3f3f3',
    checkoutHeaderColorContrast: '#000',
    checkoutHeaderPrimaryColor: '#6a43f9',
    productCardColor: '#F3F3F3',
    productCardTextColor: '#000000',
    productCardPrimaryColor: '#16017f',
    productCardPrimaryColorContrast: '#FFFFFF',
    productCardAccentColor: '#6a43f9',
    productCardAccentColorContrast: '#FFFFFF',
    productCardIconColor: '#16017f',
    productCardBorderColor: '#1617f',
    productListPadding: 0,
    productOverviewPadding: 0,
    inputFieldTextColor: '#000',
    inputFieldLabelColor: '#000',
    inputFieldBorderColor: alpha('#000', 0.2),
    baseUnit: 8,
    primaryColor: '#6a43f9',
    primaryColorContrast: '#FFFFFF',
    primaryButtonStyle: 'contained',
    secondaryColor: '#16017f',
    secondaryColorContrast: '#FFF',
    secondaryButtonStyle: 'contained',
    lightestGrey: '#F3F3F3',
    fontFamily: '"Arial", sans-serif',
    fontSize: 14,
    borderRadius: 4,
    imageShadow: false,
    h1Color: '#000',
    h1Size: '48px',
    h1Font: '"Arial", sans-serif',
    h1FontWeight: 700,
    h2Color: '#000',
    h2Size: '38px',
    h2Font: '"Arial", sans-serif',
    h2FontWeight: 700,
    h3Color: '#000',
    h3Size: '24px',
    h3Font: '"Arial", sans-serif',
    h3FontWeight: 500,
    h4Color: '#000',
    h4Size: '22px',
    h4Font: '"Arial", sans-serif',
    h4FontWeight: 400,
    h5Color: '#000',
    h5Size: '1.25rem',
    h5Font: '"Arial", sans-serif',
    h5FontWeight: 700,
    h6Color: '#000',
    h6Size: '1rem',
    h6Font: '"Arial", sans-serif',
    h6FontWeight: 700,
    bodyColor: '#000',
    bodySize: '1rem',
    bodyFont: '"Arial", sans-serif',
    bodyFontWeight: 400,
    linkColor: '#6a43f9',
    iconUrl: '',
    informationColor: '#dad2f7',
    warningColor: '#f7e2d2',
    errorColor: '#f44336',
    disableCopyFromContactPerson: false,
    netsPaymentTheme: {
        textColor: '',
        primaryColor: '',
        buttonRadius: '',
        buttonTextColor: '',
        linkColor: '',
        footerBackgroundColor: '',
        footerOutlineColor: '',
        footerTextColor: '',
        backgroundColor: '',
        panelColor: '',
        outlineColor: '',
        primaryOutlineColor: '',
        panelTextColor: '',
        panelLinkColor: '',
        fontFamily: '',
        buttonFontWeight: '',
        buttonFontStyle: '',
        buttonTextTransform: '',
        placeholderColor: '',
        useLightIcons: false,
        useLightFooterIcons: false,
    },
    linkStyle: 'normal',
    navigationTabFontSize: undefined,
    navigationTabBorderBottom: undefined,
    buttonFontSize: undefined,
    buttonFontWeight: undefined,
    buttonFont: undefined,
    outlinedButtonBorderWidth: undefined,
    outlinedButtonBorderColor: undefined,
    containedButtonLeftRightPadding: undefined,
    outlinedButtonLeftRightPadding: undefined,
    bookingWidgetSecondaryButtonColor: undefined,
    boldFontWeight: 700,
    breakpoints: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
};

// TODO: REMOVE THIS IN v4
if (window.location.origin.includes('hoytundertaket.no')) {
    _defaultTheme.productListPadding = 4;
}

const _customizations = {
    ..._defaultTheme,
    basketIconCountColor:
        window.BilberryCustomTheme?.basketIconColor ?? _defaultTheme.basketIconColor,
    ...window.BilberryCustomTheme,
} as ThemeType;

export const customizationAtom = atom({ initialValue: _customizations });

export const CustomizationsContext = createContext<ThemeType>(customizationAtom.subject.value);

export function useCustomizations() {
    return useContext(CustomizationsContext);
}

window.BilberryOverrideTheme = function (customizations: Partial<ThemeType>) {
    customizationAtom.update({ ...customizationAtom.subject.value, ...customizations });
};

/**
 * Wait for BilberryCustomTheme to be added to the page (it might be added after the main script tags).
 * Do not wait for more than 5 seconds.
 **/
function waitForTheme(timeWaiting: number) {
    if (!window.BilberryCustomTheme) {
        setTimeout(() => {
            if (window.BilberryCustomTheme) {
                window.BilberryOverrideTheme(window.BilberryCustomTheme);
                return;
            } else if (timeWaiting > 5000) {
                return;
            } else {
                waitForTheme(timeWaiting + 100);
            }
        }, 100);
    }
}
waitForTheme(100);
