import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/';
import { CSSProperties, forwardRef } from 'preact/compat';
import { useLocale } from 'src/i18n/locale';
import { useCustomizations } from 'src/utils/common/theme/customizations';

type Props = {
    direction: 'right' | 'left';
    onClick?: (e: MouseEvent) => void;
    positionAbsolutely?: boolean;
    style?: CSSProperties;
};

export const ArrowButton = forwardRef((props: Props, ref: any) => {
    const { onClick, direction, positionAbsolutely = true, style } = props;
    const { t } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();

    const arrow = direction === 'right' ? <ArrowForwardRounded /> : <ArrowBackRounded />;

    return (
        <IconButton
            ref={ref}
            onClick={onClick}
            sx={[
                {
                    backgroundColor: 'rgba(255,255,255,0.70)',
                    color: '#000',
                    touchAction: 'manipulation',
                    borderRadius: `${customizations.borderRadius}px`,
                    '& .MuiSvgIcon-root': {
                        color: '#000',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.95)',
                    },
                },
                positionAbsolutely && {
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                },
                direction === 'left' && { left: theme.spacing(2) },
                direction === 'right' && { right: theme.spacing(2) },
            ]}
            style={style}
            aria-label={t.aria_next_image}
        >
            {arrow}
        </IconButton>
    );
});
