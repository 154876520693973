import { Box, Button, Collapse, Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useState } from 'preact/hooks';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { useLocale } from 'src/i18n/locale';
import { formatCurrencyNoDecimalsIfPossible } from 'src/i18n/Localization';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getAgeString } from 'src/utils/domain/mapToTranslations';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import OverviewIconRow from 'src/widgets/activities/product/product-overview-2/overview/OverviewIconRow';
import { useAtom } from 'ximple/atoms';

export type ProductOverview2Props = {
    product: Pick<
        BilberryProductCatalog,
        | 'id'
        | 'web_full_description'
        | 'web_disclaimer'
        | 'web_additional_info'
        | 'web_times'
        | 'default_prices'
        | 'location'
        | 'duration'
        | 'difficulty'
        | 'from_price'
        | 'from_age'
    > | null;
    aboutTheTripTextCustomizationKey?: keyof UserTextCustomizations;
    additionalInfoTextCustomizationKey?: keyof UserTextCustomizations;
    pricesTextCustomizationKey?: keyof UserTextCustomizations;
    startingTimeTextCustomizationKey?: keyof UserTextCustomizations;
    departureTextCustomizationKey?: keyof UserTextCustomizations;
    durationTextCustomizationKey?: keyof UserTextCustomizations;
    difficultyTextCustomizationKey?: keyof UserTextCustomizations;
    ageTextCustomizationKey?: keyof UserTextCustomizations;
    pricesFromTextCustomizationKey?: keyof UserTextCustomizations;
};

export default function ProductOverview2(props: ProductOverview2Props): JSX.Element {
    const { t, locale } = useLocale();
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const {
        product,
        aboutTheTripTextCustomizationKey,
        additionalInfoTextCustomizationKey,
        pricesTextCustomizationKey,
        startingTimeTextCustomizationKey,
        departureTextCustomizationKey,
        durationTextCustomizationKey,
        difficultyTextCustomizationKey,
        ageTextCustomizationKey,
        pricesFromTextCustomizationKey,
    } = props;
    const customizations = useCustomizations();
    const configurations = useConfigurations();
    const theme = useTheme();
    const [currency] = useAtom(currencyAtom);

    const webDisclaimer = product?.web_disclaimer;

    return (
        <Grid container p={`${customizations.productOverviewPadding}px`}>
            <Grid
                item
                xs={12}
                container
                mb={theme.spacing(8)}
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        marginBottom: theme.spacing(4),
                    },
                }}
            >
                <Grid item xs={12} sm={10} md={8}>
                    {product && (
                        <OverviewIconRow
                            productCatalog={product}
                            durationTextCustomizationKey={durationTextCustomizationKey}
                            difficultyTextCustomizationKey={difficultyTextCustomizationKey}
                            ageTextCustomizationKey={ageTextCustomizationKey}
                            pricesFromTextCustomizationKey={pricesFromTextCustomizationKey}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                {product && (
                    <Typography variant="h3">
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            aboutTheTripTextCustomizationKey,
                            locale,
                            t.about_the_trip,
                        )}
                    </Typography>
                )}
            </Grid>
            <Grid item container xs={12}>
                <Grid item container xs={12} sm={8} alignContent="flex-start" mt={theme.spacing(3)}>
                    <Grid item xs={12}>
                        {product?.web_full_description &&
                            parseHtmlToMui(product.web_full_description)}
                    </Grid>
                    {product?.web_additional_info && (
                        <Grid item xs={12}>
                            <Typography variant="h3" m={theme.spacing(3, 0)}>
                                {getCustomOrDefaultText(
                                    configurations.textCustomizations,
                                    additionalInfoTextCustomizationKey,
                                    locale,
                                    t.additional_information,
                                )}
                            </Typography>
                            {parseHtmlToMui(product.web_additional_info)}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    container
                    direction="column"
                    pl={theme.spacing(12)}
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            paddingLeft: theme.spacing(4),
                        },
                        [theme.breakpoints.down('sm')]: {
                            paddingLeft: 0,
                            paddingTop: theme.spacing(4),
                        },
                    }}
                >
                    <Typography variant="h4">
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            pricesTextCustomizationKey,
                            locale,
                            t.prices,
                        )}
                    </Typography>
                    {product?.default_prices &&
                        product.default_prices.map((price) => (
                            <Typography key={price.id} fontSize="0.9rem">
                                {capitalize(price.name)}
                                {getAgeString(t, price)}:{' '}
                                <Grid
                                    component="span"
                                    fontSize="1rem"
                                    fontWeight={customizations.boldFontWeight}
                                >
                                    {formatCurrencyNoDecimalsIfPossible(
                                        locale,
                                        currency,
                                        price.price,
                                    )}
                                </Grid>
                            </Typography>
                        ))}
                    <Typography variant="h4" mt={theme.spacing(4)}>
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            startingTimeTextCustomizationKey,
                            locale,
                            t.starting_time,
                        )}
                    </Typography>
                    {product?.web_times &&
                        product.web_times
                            .split('\n')
                            .map((time, i) => (
                                <Typography key={`${product.id}-${time}-${i}`}>{time}</Typography>
                            ))}
                    <Typography variant="h4" mt={theme.spacing(4)}>
                        {getCustomOrDefaultText(
                            configurations.textCustomizations,
                            departureTextCustomizationKey,
                            locale,
                            t.departure,
                        )}
                    </Typography>
                    {product?.location?.city && <Typography>{product.location.city}</Typography>}
                    {webDisclaimer && (
                        <Box mt={theme.spacing(4)}>
                            <Button
                                sx={{
                                    marginLeft: theme.spacing(-1),
                                    marginTop: theme.spacing(-1),
                                    color: customizations.linkColor,
                                }}
                                variant="text"
                                onClick={() => setShowCancellationPolicy(!showCancellationPolicy)}
                            >
                                {capitalize(
                                    showCancellationPolicy
                                        ? t.hide_cancellation_policy
                                        : t.show_cancellation_policy,
                                )}
                            </Button>
                            <Collapse in={showCancellationPolicy}>
                                <Typography>{parseHtmlToMui(webDisclaimer)}</Typography>
                            </Collapse>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
