import { Grid, useTheme } from '@mui/material';
import { h } from 'preact';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';

export default function ProductGallery(props: {
    product: BilberryProductCatalog | null;
}): JSX.Element {
    const { product } = props;
    const theme = useTheme();
    const numberOfImages = product?.media.gallery.length ?? 0;

    return (
        <Grid item xs={12} direction="column">
            {numberOfImages >= 1 && (
                <ProductImage
                    url={product?.media.gallery[0]?.url ?? null}
                    size="s"
                    marginBottom={1}
                />
            )}
            {(numberOfImages === 2 && (
                <Grid container direction="row" md={12} wrap="wrap" justifyContent="space-between">
                    <Grid item xs={12} md={12}>
                        <ProductImage
                            url={product?.media.gallery[1]?.url ?? null}
                            size="s"
                            marginBottom={1}
                        />
                    </Grid>
                </Grid>
            )) ||
                (numberOfImages > 2 && (
                    <Grid
                        container
                        direction="row"
                        md={12}
                        wrap="wrap"
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} md={6} pr={theme.spacing(0.5)}>
                            <ProductImage url={product?.media.gallery[1]?.url ?? null} size="s" />
                        </Grid>
                        <Grid item xs={12} md={6} pl={theme.spacing(0.5)}>
                            <ProductImage url={product?.media.gallery[2]?.url ?? null} size="s" />
                        </Grid>
                    </Grid>
                ))}
        </Grid>
    );
}
