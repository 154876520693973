import { StateUpdater, useState } from 'preact/hooks';

/**
 * Type that can be added to a component's prop type to ensure the component's visibility state
 * can be controlled by a parent component.
 */
export type VisibilityControllable = { visible?: boolean; setVisible?: StateUpdater<boolean> };

/**
 * Hook that handles whether the visibility of a component should be controlled or uncontrolled,
 * based on whether the visible and setVisible props exist. These should be passed in directly from
 * the props of the component calling the hook. To make sure the calling component has these props
 * with the correct types, the component should implement the VisibiltyControllable type also
 * in this file.
 *
 * A common use case for such potentially controlled visibility is in components that wrap inputs,
 * since inputs can be both controlled and uncontrolled by default.
 *
 * @param props An object containing the props of the component that calls the hook. The props need
 * to implement the VisibilityControllable type, meaning that they need optional fields for `visible`
 * and `setVisible`. If one of the fields are undefined, the component is uncontrolled, otherwise it is
 * controlled by the parent,
 */
export const useControlledVisibilityState = <T = {}>(
    props: VisibilityControllable & T,
): [boolean, StateUpdater<boolean>] => {
    const { visible, setVisible } = props;
    const uncontrolledState = useState(false);

    if (visible === undefined || setVisible === undefined) return uncontrolledState;
    return [visible, setVisible];
};
