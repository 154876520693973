import { Box, Button, Grid, useTheme } from '@mui/material';
import { h } from 'preact';
import CheckoutInfo from 'src/components/domain/checkout-info/CheckoutInfo';
import Summary from 'src/components/domain/summary/Summary';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useLocale } from 'src/i18n/locale';
import { cartAtom } from 'src/state/cart/cartAtom';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { CheckoutInfoData } from 'src/types/checkout-info';
import { AppliedGiftCard } from 'src/types/giftcards';
import { showError } from 'src/utils/common/error-handling';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAtom } from 'ximple';
import { ButtonDisplay } from './ButtonDisplay';

type ContactViewProps = {
    largeScreen: boolean;
    isMakingReservation: boolean;
    handleGoPayClicked: () => void;
    checkoutInfoData: CheckoutInfoData | null;
    setCheckoutInfoData: (val: CheckoutInfoData | null) => void;
    showAddressFields: boolean;
    clickedNext: boolean;
    appliedGiftcard: AppliedGiftCard | null;
    setAppliedGiftcard: (val: AppliedGiftCard | null) => void;
    appliedPromoCode: BilberryPromoCodeStatus | null;
    setAppliedPromoCode: (val: BilberryPromoCodeStatus | null) => void;
};

export function ContactView({
    largeScreen,
    isMakingReservation,
    handleGoPayClicked,
    checkoutInfoData,
    showAddressFields,
    setCheckoutInfoData,
    clickedNext,
    appliedGiftcard,
    setAppliedGiftcard,
    appliedPromoCode,
    setAppliedPromoCode,
}: ContactViewProps) {
    const { t } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();

    const [cartItems] = useAtom(cartAtom);

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Contact Info',
        cartItems: Object.values(cartItems),
        giftCard: appliedGiftcard
            ? {
                  giftcardReference: appliedGiftcard.giftcardStatus.id,
                  priceReduction: appliedGiftcard.priceReduction,
              }
            : null,
        promoCode: appliedPromoCode,
    }));

    return (
        <Grid
            container
            justifyContent={largeScreen ? 'space-between' : 'center'}
            alignItems={largeScreen ? 'flex-start' : 'center'}
            direction={largeScreen ? 'row' : 'column-reverse'}
        >
            {!largeScreen && (
                <Box
                    maxWidth="440px"
                    width="100%"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            marginBottom: theme.spacing(3),
                        },
                    }}
                >
                    <Button
                        sx={{ float: 'right', marginTop: theme.spacing(2) }}
                        color="primary"
                        variant={customizations.primaryButtonStyle}
                        disabled={isMakingReservation}
                        onClick={handleGoPayClicked}
                    >
                        <ButtonDisplay isMakingReservation={isMakingReservation} />
                    </Button>
                </Box>
            )}

            {checkoutInfoData && (
                <CheckoutInfo
                    checkoutInfoData={checkoutInfoData}
                    onChange={setCheckoutInfoData}
                    showErrors={clickedNext}
                    showAddressFields={showAddressFields}
                />
            )}

            <Summary
                showShare
                hideGoPay={!largeScreen}
                isCheckoutSummary={true}
                isMakingReservation={isMakingReservation}
                goPayClicked={handleGoPayClicked}
                onGiftcardApplied={setAppliedGiftcard}
                appliedGiftcard={appliedGiftcard}
                appliedPromoCode={appliedPromoCode}
                onPromoCodeApplied={setAppliedPromoCode}
                onError={(message: string) => showError(message)}
            />
        </Grid>
    );
}
