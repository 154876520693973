import { capitalize, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import produce from 'immer';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { useLocale } from 'src/i18n/locale';
import { BilberryProduct, PackageProduct } from 'src/types/bilberry-api-types';
import TourSelectionPanel from './TourSelectionPanel';

type TourSelectionContainerProps = {
    packageProducts: PackageProduct[];
    selectedAvailabilities: {
        [ticketOptionId: string]: Record<
            string,
            BilberryProduct & {
                ticketOptionId: number;
                priceCategoryId: number;
                packageProductId: number;
            }
        >;
    };
    setSelectedAvailabilities: (val: {
        [ticketOptionId: string]: Record<
            string,
            BilberryProduct & {
                ticketOptionId: number;
                priceCategoryId: number;
                packageProductId: number;
            }
        >;
    }) => void;
};

export default function TourSelectionContainer({
    packageProducts,
    selectedAvailabilities,
    setSelectedAvailabilities,
}: TourSelectionContainerProps) {
    const theme = useTheme();
    const { t } = useLocale();

    const accommodations = groupBy(
        packageProducts.filter((p) => p.isAccommodation),
        (p) => p.start.format('YYYY-MM-DD'),
    );

    const activities = mapValues(
        groupBy(
            packageProducts.filter((p) => !p.isAccommodation),
            (p) => p.start.format('YYYY-MM-DD'),
        ),
        (products) =>
            products.map((p) => {
                p.availabilities = p.availabilities.filter((a) =>
                    dayjs(a.start).isSame(p.start, 'day'),
                );
                return p;
            }),
    );

    return (
        <Stack
            spacing={4}
            sx={{
                minWidth: '300px',
                width: '100%',
                maxWidth: '440px',
                mt: theme.spacing(2),
            }}
        >
            <Stack spacing={2}>
                <Typography variant="h5" component="h2">
                    {t.accommodations}
                </Typography>
                <TourSelectionPanelList
                    productsByDay={accommodations}
                    {...{ selectedAvailabilities, setSelectedAvailabilities }}
                />
            </Stack>
            <Stack spacing={2}>
                <Typography variant="h5" component="h2">
                    {t.activities}
                </Typography>
                <TourSelectionPanelList
                    productsByDay={activities}
                    {...{ selectedAvailabilities, setSelectedAvailabilities }}
                />
            </Stack>
        </Stack>
    );
}

function TourSelectionPanelList(
    props: { productsByDay: Record<string, PackageProduct[]> } & Omit<
        TourSelectionContainerProps,
        'packageProducts'
    >,
) {
    const { t } = useLocale();

    const selectedAvailabilities = Object.values(props.selectedAvailabilities).flatMap((val) =>
        Object.values(val).map((x) => ({ ...x, ticketOptionId: x.ticketOptionId })),
    );
    const findProduct = (product: PackageProduct) =>
        selectedAvailabilities.find((sa) =>
            product.availabilities.find(
                (a) => a.ticketOptionId === sa.ticketOptionId && a.id === sa.id,
            ),
        );

    return (
        <>
            {Object.entries(props.productsByDay).map(([dayKey, day], i) => (
                <Stack spacing={1} key={`tour-selection-panel-day-${dayKey}`}>
                    <Typography variant="h6" component="h3">
                        {capitalize(t.days.singular)} {i + 1}
                    </Typography>
                    {day.map((p) => {
                        const product = findProduct(p);
                        if (!product) return null;
                        return (
                            <TourSelectionPanel
                                {...p}
                                key={`tour-selection-panel-${p.id}-${p.ticketOptionId}`}
                                id={`tour-selection-panel-${p.id}-${p.ticketOptionId}`}
                                value={product}
                                setValue={(val) => {
                                    const newSelectedAvailabilities = produce(
                                        props.selectedAvailabilities,
                                        (draft) => {
                                            delete draft[product.ticketOptionId][product.id];
                                            draft[product.ticketOptionId][val.id] = val as any;

                                            return draft;
                                        },
                                    );
                                    props.setSelectedAvailabilities(newSelectedAvailabilities);
                                }}
                            />
                        );
                    })}
                </Stack>
            ))}
        </>
    );
}
