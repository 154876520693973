import unionBy from 'lodash-es/unionBy';
import take from 'lodash-es/take';

export type GuestInfo = {
    id: number;
    adults: number;
    children: ChildInfo[];
};

export type ChildInfo = {
    childId: number;
    age: string | null;
    bed: string | null;
};

export function updateRoomCount(existingRooms: GuestInfo[], count: number) {
    const emptyRooms = [...Array(count)].map((_, idx) => ({
        id: idx,
        adults: 1,
        children: [],
    }));

    const newRooms = take(unionBy(existingRooms, emptyRooms, 'id'), count) as GuestInfo[];
    return newRooms;
}

function updateRoom(
    rooms: GuestInfo[],
    roomId: number,
    guests: { adults?: number; children?: ChildInfo[] },
) {
    const newRoom = {
        ...rooms[roomId],
        ...(guests.adults && { adults: guests.adults }),
        ...(guests.children && { children: guests.children }),
    };

    return rooms.map((room) => (room.id === roomId ? newRoom : room));
}

export function updateAdultCountInRoom(existingRooms: GuestInfo[], roomId: number, count: number) {
    return updateRoom(existingRooms, roomId, { adults: count });
}

export function updateRoomChildCount(existingRooms: GuestInfo[], roomId: number, count: number) {
    const emptyChildren = [...Array(count)].map((_, idx) => ({
        childId: idx,
        age: null,
        bed: null,
    }));

    const existingChildren = existingRooms[roomId].children;

    const newChildren = take(
        unionBy(existingChildren, emptyChildren, 'childId'),
        count,
    ) as ChildInfo[];

    return updateRoom(existingRooms, roomId, { children: newChildren });
}

export function updateChildrenInRoom(
    existingRooms: GuestInfo[],
    roomId: number,
    updatedChildren: ChildInfo[],
) {
    const existingChildren = existingRooms[roomId].children;
    const newChildren = unionBy(updatedChildren, existingChildren, 'childId');

    return updateRoom(existingRooms, roomId, { children: newChildren });
}

export function updateChild(
    existingChildren: ChildInfo[],
    childId: number,
    child: { age?: string; bed?: string },
) {
    const existingChild = existingChildren.find((child) => child.childId === childId);

    const updatedChild = {
        ...existingChild,
        ...(child.age && { age: child.age }),
        ...(child.bed && { bed: child.bed }),
    } as ChildInfo;

    const newChildren = existingChildren.map((child) =>
        child.childId === childId ? updatedChild : child,
    );

    return newChildren;
}
