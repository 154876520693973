import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductGallery2, {
    ProductGalleryAlign,
} from 'src/widgets/activities/product/product-gallery-2/gallery/ProductGallery2';

export default function ProductGallery2WidgetRoot(props: {
    productCatalogId: number;
    numVisibleImages: MediaQueryAttributeInput;
    align: ProductGalleryAlign;
}) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(props.productCatalogId, locale, config);

    return (
        <ProductGallery2
            imageList={productCatalog?.media?.gallery ?? null}
            numVisibleImages={props.numVisibleImages}
            align={props.align}
        />
    );
}
