import { Box, Button, darken, Grid, Popover, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { createRef, Fragment, h } from 'preact';
import { StateUpdater, useContext } from 'preact/hooks';
import TimeSlots from 'src/components/domain/time-slot/TimeSlots';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from 'src/i18n/locale';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { MountPointContext } from 'src/utils/common/mountPoint';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { zIndex } from 'src/utils/common/theme/Theme';
import { findAvailabilityData, getAllDatesInDateRange } from 'src/utils/domain/DateHelpers';
import { AvailabilityProductType, TimeSlotType } from 'src/utils/domain/TimeSlotType';
import StaticDateRangePicker from '../../date-range-picker/StaticDateRangePicker';
import BilberryStaticCalendar from '../BilberryStaticCalendar';

interface IProps {
    dateRangeVariant?: 'days' | 'nights';
    selectedDateRange: DateRange<Dayjs> | undefined;
    onSelectDateRange: ((dateRange: DateRange<any>) => void) | undefined;
    minDate: dayjs.Dayjs;
    displayDate: dayjs.Dayjs | null;
    displayTimeslot: TimeSlotType<any> | undefined;
    setDisplayTimeslot: StateUpdater<TimeSlotType<any> | undefined>;
    anchorEl: HTMLDivElement | HTMLInputElement | null;
    availabilityData: {
        [id: string]:
            | AvailabilityProductType<BilberryProduct>[]
            | AvailabilityProductType<BilberryProduct>;
    };
    updateDisplayDate: (date: Dayjs | null) => void;
    setAnchorEl: StateUpdater<HTMLDivElement | HTMLInputElement | null>;
    onSelectTimeSlot?: (timeslot: TimeSlotType<any> | undefined) => void;
    setSelectedProducts?: (product: AvailabilityProductType<any>[] | undefined) => void;
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: dayjs.Dayjs | null;
        endDay: dayjs.Dayjs | null;
    }) => void;
    id?: string;
    quantities: BookingPriceAndQuantity[];
    setHasChosenDate: (hasChosenDate: boolean) => void;
}

export default function CalendarPopover(props: IProps) {
    const {
        dateRangeVariant,
        selectedDateRange,
        onSelectDateRange,
        minDate,
        displayDate,
        displayTimeslot,
        setDisplayTimeslot,
        anchorEl,
        availabilityData,
        updateDisplayDate,
        setAnchorEl,
        onSelectTimeSlot,
        setSelectedProducts,
        setAvailabilitySearchPeriod,
        id = 'bilberry-calendar-input',
        quantities,
        setHasChosenDate,
    } = props;
    const customizations = useCustomizations();
    const mountPoint = useContext(MountPointContext);
    const popoverRef = createRef<HTMLDivElement>();
    const theme = useTheme();
    const { t, locale } = useLocale();
    const threeYearsAhead = minDate.add(5, 'year');
    const trap = useFocusTrap(popoverRef, !!anchorEl, `#${id}`, mountPoint.shadowRoot);

    const updateAvailabilitySearchPeriod = (date: Dayjs | null) => {
        if (!date) return;

        setAvailabilitySearchPeriod({
            startDay: date.subtract(1, 'month').startOf('month'),
            endDay: date.add(1, 'month').startOf('month').add(1, 'day'),
        });
    };

    const onSelectDay = (date: Dayjs | null) => {
        updateDisplayDate(date);
    };

    const onClose = () => {
        if (trap) trap.deactivate(200);
        setAnchorEl(null);
    };

    const availabilityDataForDisplayDate = findAvailabilityData(
        displayDate,
        availabilityData,
        locale,
        quantities,
    );

    const disableOkButton =
        (!displayTimeslot && !dateRangeVariant) ||
        (dateRangeVariant === 'nights' && selectedDateRange?.includes(null));

    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            sx={{
                zIndex: `${zIndex.alwaysVisible} !important` as any,

                '& > .MuiPaper-root': {
                    maxWidth: 325,
                },
            }}
            container={mountPoint.popover}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onBackdropClick={onClose}
            onClose={onClose}
            aria-modal={true}
            disableEnforceFocus={true}
            ref={popoverRef}
        >
            {dateRangePickerOrCalendar(
                dateRangeVariant,
                selectedDateRange,
                onSelectDateRange,
                updateAvailabilitySearchPeriod,
                minDate,
                threeYearsAhead,
                displayDate,
                onSelectDay,
                availabilityDataForDisplayDate,
                displayTimeslot,
                setDisplayTimeslot,
                availabilityData,
            )}

            <Box pt={1} pb={2}>
                <Grid container justifyContent="right" pl={2} pr={2.5} gap={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            onSelectTimeSlot?.(undefined);
                            setSelectedProducts?.(undefined);
                            setHasChosenDate(false);
                            onClose();
                        }}
                        role={capitalize(t.select_date)}
                    >
                        {t.clear}
                    </Button>
                    <Button
                        sx={{
                            '&:hover': {
                                backgroundColor: (props) =>
                                    customizations.primaryButtonStyle === 'contained'
                                        ? darken(theme.palette.primary.main, 0.2)
                                        : 'rgba(0, 0, 0, 30%)',
                            },
                        }}
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        disabled={disableOkButton}
                        onClick={() =>
                            onOkClicked(
                                dateRangeVariant,
                                selectedDateRange,
                                displayTimeslot,
                                onSelectTimeSlot,
                                setSelectedProducts,
                                getAllDatesInDateRange,
                                availabilityData,
                                locale,
                                findAvailabilityData,
                                onClose,
                                quantities,
                            )
                        }
                        role={capitalize(t.select_date)}
                    >
                        {t.ok.toUpperCase()}
                    </Button>
                </Grid>
            </Box>
        </Popover>
    );
}

function dateRangePickerOrCalendar(
    dateRangeVariant: 'days' | 'nights' | undefined,
    selectedDateRange: DateRange<dayjs.Dayjs> | undefined,
    onSelectDateRange:
        | ((date: DateRange<unknown>, keyboardInputValue?: string | undefined) => void)
        | undefined,
    updateAvailabilitySearchPeriod: any,
    minDate: dayjs.Dayjs | undefined,
    threeYearsAhead: dayjs.Dayjs,
    displayDate: dayjs.Dayjs | null,
    onSelectDay: (date: Dayjs | null) => void,
    availabilityDataForDisplayDate: any[] | undefined,
    displayTimeslot: TimeSlotType<any> | undefined,
    setDisplayTimeslot: StateUpdater<TimeSlotType<any> | undefined>,
    availabilityData: {
        [id: string]: any;
    },
) {
    if (dateRangeVariant) {
        if (selectedDateRange && onSelectDateRange)
            return (
                <StaticDateRangePicker
                    dateRangeVariant={dateRangeVariant}
                    dateRange={selectedDateRange}
                    onChange={onSelectDateRange}
                    onMonthChange={updateAvailabilitySearchPeriod}
                    minDate={minDate}
                    maxDate={threeYearsAhead}
                    isDateUnavailable={(date) => {
                        return isDateUnavailable(date, availabilityData);
                    }}
                    loading={false}
                />
            );
        else return null;
    }

    return (
        <Fragment>
            <BilberryStaticCalendar
                date={displayDate}
                onChange={onSelectDay}
                onMonthChange={updateAvailabilitySearchPeriod}
                onYearChange={updateAvailabilitySearchPeriod}
                minDate={minDate}
                maxDate={threeYearsAhead}
                shouldDisableDate={(date) => isDateUnavailable(date, availabilityData)}
                loading={false}
            />
            <TimeSlots
                availabilityData={availabilityDataForDisplayDate}
                selectedTimeSlot={displayTimeslot ?? undefined}
                onSelectTimeSlot={setDisplayTimeslot}
            />
        </Fragment>
    );
}

function onOkClicked(
    dateRangeVariant: 'days' | 'nights' | undefined,
    selectedDateRange: DateRange<Dayjs> | undefined,
    displayTimeslot: TimeSlotType<any> | undefined,
    onSelectTimeSlot: ((timeslot: TimeSlotType<any>) => void) | undefined,
    setSelectedProducts: ((product: any[] | undefined) => void) | undefined,
    getAllDatesInDateRange: (arg0: DateRange<dayjs.Dayjs> | undefined) => any[],
    availabilityData: any,
    locale: any,
    findAvailabilityData: (arg0: any, arg1: any, arg2: any, arg3: any) => any[],
    onClose: () => void,
    quantities: BookingPriceAndQuantity[],
) {
    if (!dateRangeVariant) {
        setSelectedProducts?.([displayTimeslot!.product]);
        if (onSelectTimeSlot) {
            onSelectTimeSlot(displayTimeslot!);
        }
    } else {
        // Allow selecting a single day in the date range picker. Represent this by copying the start date into the end date.
        const dateRange =
            dateRangeVariant === 'days' && !!selectedDateRange?.[0] && !selectedDateRange?.[1]
                ? ([selectedDateRange[0], selectedDateRange[0]] as DateRange<Dayjs>)
                : selectedDateRange;
        setSelectedProducts?.(
            getAllDatesInDateRange(dateRange).map(
                (date: any) => findAvailabilityData(date, availabilityData, locale, quantities)[0],
            ),
        );
    }
    onClose();
}

function isDateUnavailable(date: Dayjs | null, availabilityData: { [x: string]: any }): boolean {
    if (!date) return false;
    const data = availabilityData[date.format('YYYY-MM-DD')];
    if (!data) return true;
    else if ('availableUnits' in data) {
        return data.availableUnits < 1;
    }
    return false;
}
