import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, Button, Collapse, SvgIcon, SxProps, Typography, useTheme, Zoom } from '@mui/material';
import { useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';

type SharePanelProps = {
    url: string;
    visible: boolean;
    sx?: SxProps<any>;
    invertedColorsClass?: Object;
};

export function SharePanel({ url, visible, sx, invertedColorsClass }: SharePanelProps) {
    const { t } = useLocale();
    const theme = useTheme();

    const handleCopyLink = async (_e?: MouseEvent) => {
        await navigator.clipboard.writeText(url);
    };

    const handleSendEmail = (_e?: MouseEvent) => {
        const subject = t.shoppingCart;
        const body = `${t.heresMyShoppingCart}: ${url}`;
        const href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            body,
        )}`;
        window.open(href, '_self');
    };

    return (
        <Collapse in={visible} sx={sx}>
            <Box sx={invertedColorsClass}>
                <Box
                    sx={{
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    <Typography variant="h6" color="inherit" sx={{ mb: theme.spacing(0.5) }}>
                        {t.howDoYouWantToShare}
                    </Typography>

                    <Box sx={{ my: theme.spacing(2), display: 'flex', justifyContent: 'center' }}>
                        <ShareButton
                            text={t.copyLink}
                            handler={handleCopyLink}
                            Icon={ContentCopyOutlinedIcon}
                            invertedColorsClass={invertedColorsClass}
                        />
                        <ShareButton
                            text={t.email}
                            handler={handleSendEmail}
                            Icon={EmailOutlinedIcon}
                            invertedColorsClass={invertedColorsClass}
                        />
                    </Box>
                </Box>
            </Box>
        </Collapse>
    );
}

type ShareButtonProps = {
    handler: (e: any) => void;
    text: string;
    Icon: typeof SvgIcon;
    invertedColorsClass?: Object;
};

function ShareButton({ handler, text, Icon, invertedColorsClass }: ShareButtonProps) {
    const theme = useTheme();
    const [hasShared, setHasShared] = useState(false);

    const onClick = (e: MouseEvent) => {
        setHasShared(true);
        handler(e);
    };

    return (
        <Button onClick={onClick} sx={{ mx: theme.spacing(1) }} disableRipple>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {hasShared ? (
                    <Zoom in={hasShared}>
                        <CheckCircleOutlineOutlinedIcon />
                    </Zoom>
                ) : (
                    <Icon />
                )}
                <Typography
                    variant="body2"
                    sx={{
                        mt: theme.spacing(0.5),
                        ...invertedColorsClass,
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </Button>
    );
}
