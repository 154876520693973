import { Box } from '@mui/material';
import { h } from 'preact';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import ProductDetailsEntry from 'src/widgets/activities/product/product-details/details/ProductDetailsEntry';

export default function ProductDetails(props: {
    product: BilberryProductCatalog | null;
}): JSX.Element {
    const { product } = props;

    return (
        <Box>
            <ProductDetailsEntry text={product?.web_full_description ?? ''} />
            <ProductDetailsEntry text={product?.web_additional_info ?? ''} />
        </Box>
    );
}
