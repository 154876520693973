import { useState, useCallback, useEffect, useRef } from 'preact/hooks';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';
import { useFocusTrap } from '../common/useFocusTrap';

export default function useBookingState() {
    const [{ visible, interaction }] = useAtom(toggleBookingAtom);
    const [attemptedBooking, setAttemptedBooking] = useState<boolean>(false);

    const [hasChosenDate, setHasChosenDate] = useState<boolean>(false);
    const config = useConfigurations();
    const shouldShowBasketOnBook = !config.skipBasketOnBook;
    // Trap to booking when

    const boxRef = useRef<HTMLDivElement | null>(null);
    const refocusElement = document
        .querySelector('bilberry-booking-button')
        ?.shadowRoot?.querySelector('button') as HTMLElement | null;
    const trap = useFocusTrap(boxRef, visible && interaction === 'keyboard', {
        current: refocusElement,
    });
    useEffect(() => {
        if (trap && !visible) trap.deactivate();
    }, [trap, visible]);

    const onToggleVisible = useCallback((visible: boolean, interaction: 'keyboard' | 'mouse') => {
        toggleBookingAtom.update({ visible, interaction });
    }, []);

    return {
        onToggleVisible,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    };
}
