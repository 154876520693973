import { instantiateShadowRoot } from 'src/utils/domain/shadowRootHelper';

export default class BilberryCustomElement extends HTMLElement {
    protected _shadowRoot: ShadowRoot;
    protected _mountPoint: HTMLDivElement;
    protected _popoverMountPoint: HTMLDivElement;

    constructor() {
        super();
        const { mountPoint, shadowRoot, popoverMountPoint } = instantiateShadowRoot(this);
        this._shadowRoot = shadowRoot;
        this._mountPoint = mountPoint;
        this._popoverMountPoint = popoverMountPoint;
    }
}
