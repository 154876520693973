import { Box } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductOverview2 from 'src/widgets/activities/product/product-overview-2/overview/ProductOverview2';

export default function ProductOverview2WidgetRoot(props: {
    productCatalogId: number;
    aboutTheTripTextCustomizationKey?: keyof UserTextCustomizations;
    additionalInfoTextCustomizationKey?: keyof UserTextCustomizations;
    pricesTextCustomizationKey?: keyof UserTextCustomizations;
    startingTimeTextCustomizationKey?: keyof UserTextCustomizations;
    departureTextCustomizationKey?: keyof UserTextCustomizations;
    durationTextCustomizationKey?: keyof UserTextCustomizations;
    difficultyTextCustomizationKey?: keyof UserTextCustomizations;
    ageTextCustomizationKey?: keyof UserTextCustomizations;
    pricesFromTextCustomizationKey?: keyof UserTextCustomizations;
}) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(props.productCatalogId, locale, config);

    return (
        <Box>
            <ProductOverview2
                product={productCatalog}
                aboutTheTripTextCustomizationKey={props.aboutTheTripTextCustomizationKey}
                additionalInfoTextCustomizationKey={props.additionalInfoTextCustomizationKey}
                pricesTextCustomizationKey={props.pricesTextCustomizationKey}
                startingTimeTextCustomizationKey={props.startingTimeTextCustomizationKey}
                departureTextCustomizationKey={props.departureTextCustomizationKey}
                durationTextCustomizationKey={props.durationTextCustomizationKey}
                difficultyTextCustomizationKey={props.difficultyTextCustomizationKey}
                ageTextCustomizationKey={props.ageTextCustomizationKey}
                pricesFromTextCustomizationKey={props.pricesFromTextCustomizationKey}
            />
        </Box>
    );
}
