import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import merge from 'lodash-es/merge';
import { h } from 'preact';
import { MountPointContext } from 'src/utils/common/mountPoint';
import { customizationAtom, CustomizationsContext } from 'src/utils/common/theme/customizations';
import { MUITheme } from 'src/utils/common/theme/Theme';
import { ThemeType } from 'src/utils/common/theme/ThemeType';
import { useAtom } from 'ximple/atoms';

export function WidgetWrapper(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    customizationOverrides?: Partial<{ [key in keyof ThemeType]: ThemeType[keyof ThemeType] }>;
    children: any;
}): JSX.Element {
    const [customizations] = useAtom(customizationAtom);
    const customizationsWithOverrides = merge(customizations, props.customizationOverrides);

    const cache = createCache({
        key: 'css',
        prepend: true,
        container: props.mountPoint,
    });

    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={MUITheme(customizationsWithOverrides)}>
                <CustomizationsContext.Provider value={customizationsWithOverrides}>
                    <MountPointContext.Provider
                        value={{
                            popover: props.popoverMountPoint,
                            shadowRoot: props.shadowRoot,
                        }}
                    >
                        {props.children}
                    </MountPointContext.Provider>
                </CustomizationsContext.Provider>
            </ThemeProvider>
        </CacheProvider>
    );
}
