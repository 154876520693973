import { Box, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';

type IProps = {
    text: string;
    header?: string;
};

export default function ProductDetailsEntry(props: IProps): JSX.Element {
    const { header, text } = props;
    const theme = useTheme();

    return (
        <Box
            p={theme.spacing(0, 2, 4, 0)}
            sx={{
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(0, 0, 4, 0),
                },
            }}
        >
            {header && <Typography variant="h6">{header}</Typography>}
            {parseHtmlToMui(text)}
        </Box>
    );
}
