import { h, render } from 'preact';
import { getIdAttribute, getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import PackageBooking from './PackageBooking';

class PackageBookingWidget extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        const packageId = getIdAttribute(this, false, 'package-id');
        const expandArrowInside = getYesAttribute(this, 'expand-arrow-inside');
        const positionOffscreen = getYesAttribute(this, 'position-offscreen');

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <PackageBooking
                    packageId={packageId}
                    expandArrowInside={expandArrowInside}
                    positionOffscreen={positionOffscreen}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-package-booking', PackageBookingWidget);
}
