import { Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';

interface IProps {
    capacity: number;
    fewLeft: number;
}

export default function TourAvailability({ capacity, fewLeft }: IProps) {
    const theme = useTheme();
    const { t } = useLocale();
    const getCss = () => {
        if (capacity < 1) {
            return { backgroundColor: '#C4C0C0' };
        } else if (capacity <= fewLeft) {
            return { backgroundColor: '#94ECFC' };
        } else {
            return { backgroundColor: '#7BE880' };
        }
    };

    const getText = () => {
        if (capacity < 1) {
            return t.sold_out.toUpperCase();
        } else if (capacity <= fewLeft) {
            return t.few_left.toUpperCase();
        } else {
            return t.available.toUpperCase();
        }
    };

    return (
        <Typography
            alignSelf="flex-end"
            ml="auto"
            px={theme.spacing(0.5)}
            py={0.2}
            borderRadius="5px"
            width="6rem"
            display="flex"
            justifyContent="center"
            fontSize="0.8rem"
            fontWeight="bold"
            sx={{
                [theme.breakpoints.down('sm')]: {
                    paddingRight: theme.spacing(0),
                    paddingLeft: theme.spacing(0),
                    width: '5rem',
                    fontSize: '0.6rem',
                },
                ...getCss(),
            }}
        >
            {getText()}
        </Typography>
    );
}
