import { render } from 'preact';
import { getMediaQuerySizesFromAttributes } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { getCsvColorsAttributes } from 'src/utils/domain/attributes/color-attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import { getCsvProductCatalogAttributes } from 'src/utils/domain/attributes/product-catalog-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import PackageList from './PackageList';

class BilberryPackage extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);

        overrideLocaleFromAttribute(this);

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <PackageList
                    scroll={scroll}
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-package-list', BilberryPackage);
}
