import { errorLog } from 'src/utils/common/Logger';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { getCsvAttribute } from 'src/utils/domain/attributes/attribute-helper';

export type ProductCatalogAttributes = {
    id: number;
    url: string | undefined;
    textCustomizationKey: keyof UserTextCustomizations | undefined;
};

export function getCsvProductCatalogAttributes(elem: Element) {
    const ids = getCsvAttribute(elem, 'product-catalog-ids', true).map(Number);
    const urls = getCsvAttribute(elem, 'product-catalog-urls', true);
    const textCustomizationKeys = getCsvAttribute(elem, 'button-text-customization-keys', true).map(
        (x) => x as keyof UserTextCustomizations,
    );
    const zippedProductCatalogs = zipProductCatalogAttributesTypes(
        elem,
        ids,
        urls,
        textCustomizationKeys,
    );
    return zippedProductCatalogs.filter((x) => invalidProductCatalogAttributes(elem, x));
}

function zipProductCatalogAttributesTypes(
    elem: Element,
    ids: number[],
    urls: string[],
    textCustomizationKeys: (keyof UserTextCustomizations)[],
): ProductCatalogAttributes[] {
    if (urls.length > 0 && ids.length !== urls.length) {
        errorLog(
            `<${elem.localName}> has inconsisitent csv attribute length, "product-catalog-ids" (${ids.length}) vs "product-catalog-urls" (${urls.length})`,
        );
        // When lengths are inconsistent we cannot guaranty that we are zipping together the correct
        // items. Therefor we would like to clear the list, however there may exist incorrect config
        // in production sites, so we cannot do this.
    }
    if (textCustomizationKeys.length > 0 && ids.length !== textCustomizationKeys.length) {
        errorLog(
            `<${elem.localName}> has inconsisitent csv attribute length, "product-catalog-ids" (${ids.length}) vs "button-text-customization-keys" (${textCustomizationKeys.length})`,
        );
        // When lengths are inconsistent we cannot guaranty that we are zipping together the correct
        // items. Therefor we would like to clear the list, however there may exist incorrect config
        // in production sites, so we cannot do this.
    }

    function getUrl(index: number): string | undefined {
        if (urls.length <= index) return undefined;
        if (urls[index].length === 0) return undefined;
        return urls[index];
    }

    function getTextCustomization(index: number): keyof UserTextCustomizations | undefined {
        if (textCustomizationKeys.length <= index) return undefined;
        if (textCustomizationKeys[index].length === 0) return undefined;
        return textCustomizationKeys[index];
    }

    return ids.map((x, i) => ({
        id: x,
        url: getUrl(i),
        textCustomizationKey: getTextCustomization(i),
    }));
}

function invalidProductCatalogAttributes(
    element: Element,
    productCatalogAttributes: ProductCatalogAttributes,
) {
    if (isNaN(productCatalogAttributes.id) || productCatalogAttributes.id <= 0) {
        errorLog(
            `Invalid product catalog id in <${element.localName}>, removing product catalog:`,
            productCatalogAttributes,
        );
        return false;
    }
    return true;
}
