import { Dayjs } from 'dayjs';
import { mapValues } from 'lodash-es';
import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';
import { useMemo } from 'react';
import { currencyAtom, updateAtomFromCurrencyCodeEffect } from 'src/state/currency/currency.atom';
import {
    BilberryBooking,
    BilberryCreateGiftcardRequest,
    BilberryExtra,
    BilberryGiftcard,
    BilberryGiftcardStatus,
    BilberryLeadFormData,
    BilberryPackage,
    BilberryPackageAvailability,
    BilberryPackageMetadata,
    BilberryProduct,
    BilberryProductCatalog,
    BilberryProductCollection,
    BilberryPromoCodeStatus,
    BilberryReservation,
    BilberrySettings,
    BilberrySmartEvent,
    PackageProductMetadata,
} from 'src/types/bilberry-api-types';
import { ProductSearch } from 'src/types/parameter-types';
import { BASE_SERVICE_URL, USE_SERVICE_PROXY } from 'src/__autogen/env';
import useSWR from 'swr';
import { compose } from '../../common/fp/compose';
import { debugLog } from '../../common/Logger';
import { transformProductCollectionToProductCatalogMediaFormat } from '../product-collection/transforms';
import { BilberryWidgetsGlobalType } from '../widgetsConfiguration';
import {
    fetcher,
    getBilberryLanguageFromLocale,
    getUrlWithParams,
    post,
    wait,
} from './api-client-common';

function getApiBaseUrl(config: BilberryWidgetsGlobalType) {
    const baseUrlFromGlobal = config.bilberryBaseApiUrl;
    if (baseUrlFromGlobal) return baseUrlFromGlobal;

    // Needed for local development through proxy
    return USE_SERVICE_PROXY ? '' : BASE_SERVICE_URL;
}

function getApiAccessToken(config: BilberryWidgetsGlobalType) {
    return config.bilberryAccessToken;
}

function getApiHeaders(
    config: BilberryWidgetsGlobalType,
    additionalHeaders: Record<string, any> = {},
) {
    const accessToken = getApiAccessToken(config);

    const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    });

    Object.entries(additionalHeaders).forEach(([key, val]) => {
        if (val !== undefined) {
            headers.set(key, val);
        }
    });

    return headers;
}

function getRequest(
    subdirectory: string,
    locale: string,
    config: BilberryWidgetsGlobalType,
    queryParams: Record<string, any> = {},
    additionalHeaders?: Record<string, any>,
) {
    const endpoint = `${getApiBaseUrl(config)}${subdirectory}`;
    const fullUrl = getUrlWithParams(endpoint, locale, queryParams, config);
    return {
        url: fullUrl,
        headers: getApiHeaders(config, additionalHeaders),
    };
}

function isAllProductCatalogIdsInCache(
    productCatalogIds: number[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const localeBilberryLanguage = getBilberryLanguageFromLocale(locale);
    const isLocaleLanguageSameAsPreloadLanguage = localeBilberryLanguage === config.preloadLanguage;

    return (
        isLocaleLanguageSameAsPreloadLanguage &&
        productCatalogIds.every((id) => config.cache.productCatalogs[id])
    );
}

function isAllProductCatalogIdsConfiguredForPreload(
    productCatalogIds: number[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const localeBilberryLanguage = getBilberryLanguageFromLocale(locale);
    const isLocaleLanguageSameAsPreloadLanguage = localeBilberryLanguage === config.preloadLanguage;
    const preloadProductCatalogIds = config.preloadMapping[window.location.pathname];

    return (
        isLocaleLanguageSameAsPreloadLanguage &&
        preloadProductCatalogIds &&
        productCatalogIds.every((id) => preloadProductCatalogIds.includes(id))
    );
}

export function useExtrasByIds(ids: number[], locale: string, config: BilberryWidgetsGlobalType) {
    const { url, headers } = getRequest('/api/extras', locale, config);

    const swrCacheKey = config.enableExtras && ids.length > 0 ? [url, ...ids] : null;

    const { data, error, isLoading } = useSWR<{ data: BilberryExtra[] }>(
        swrCacheKey,
        ([fetchUrl, ...tourIds]) => post(fetchUrl.toString(), headers, { tours: tourIds }),
        { loadingTimeout: 3000, shouldRetryOnError: false }, // Added timeout just in case the API is not supporting extras ("new" feature).
    );
    return {
        extras: data?.data,
        isError: error,
        isLoading,
    };
}

export async function getAvailableProductsFromMultipleProductCatalogs(
    ids: number[],
    start: Dayjs | null,
    end: Dayjs | null,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const reqs = ids.map((id) => {
        const { url, headers } = getAvailableProductsRequest(id, start, end, locale, config);
        return fetcher(url, headers);
    });
    return await Promise.all(reqs);
}

export function useAvailableProductsFromMultipleProductCatalogs(
    ids: number[],
    start: Dayjs | null,
    end: Dayjs | null,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const fetcherFn = async (ids: number[]) => {
        const reqs = ids.map((id) => {
            const { url, headers } = getAvailableProductsRequest(id, start, end, locale, config);
            return fetcher(url, headers);
        });
        return await Promise.all(reqs);
    };

    const { data, error } = useSWR<{ [id: string]: BilberryProduct[] }[]>(ids, fetcherFn, {
        shouldRetryOnError: false,
    });

    return {
        availabilities: data,
        error,
    };
}

export function useAvailableProducts(
    productCatalogId: number,
    start: Dayjs | null,
    end: Dayjs | null,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getAvailableProductsRequest(
        productCatalogId,
        start,
        end,
        locale,
        config,
    );

    const { data, error } = useSWR<{ [id: string]: BilberryProduct[] }>(
        url,
        () => fetcher(url, headers),
        {
            shouldRetryOnError: false,
        },
    );

    return {
        availableProducts: data,
        isError: error,
        isLoading: !error && !data,
    };
}

function getAvailableProductsRequest(
    productCatalogId: number,
    start: Dayjs | null,
    end: Dayjs | null,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const subdirectory = `/api/product-catalogs/${productCatalogId}/availability`;
    const dateFormat = 'YYYY-MM-DD';
    const queryParams: Record<string, any> = {};
    if (start && end) {
        queryParams.start = start.format(dateFormat);
        queryParams.end = end.format(dateFormat);
    }

    return getRequest(subdirectory, locale, config, queryParams);
}

export function useUpcomingTours(
    productCatalogIds: number[],
    startDate: Dayjs,
    endDate: Dayjs,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const dateFormat = 'YYYY-MM-DD';
    const queryParams =
        startDate !== null && endDate !== null
            ? {
                  start: startDate.format(dateFormat),
                  end: endDate.format(dateFormat),
                  ids: productCatalogIds,
              }
            : null;
    const { url, headers } = getRequest(`/api/upcoming-tours/`, locale, config, queryParams ?? {});

    const { data, error } = useSWR<{ data: BilberryProduct[] }>(url, () => fetcher(url, headers), {
        shouldRetryOnError: false,
    });

    return {
        tours: data?.data ?? [],
        isError: error,
        isLoading: !error && !data,
    };
}

export const useProductCatalogsFiltered = compose(
    useProductCatalogsFilteredDefault,
    updateAtomFromCurrencyCodeEffect((x) => get(x, 'productCatalogsFiltered[0].currency', null)),
);

function useProductCatalogsFilteredDefault(props: {
    filters: ProductSearch | null;
    locale: string;
    config: BilberryWidgetsGlobalType;
}) {
    const { filters, locale, config } = props;
    const dateFormat = 'YYYY-MM-DD';
    const subdirectory = `/api/product-catalogs`;

    const queryParamsUnfiltered = {
        start: filters?.start?.format(dateFormat),
        end: filters?.end?.format(dateFormat),
        difficulty: filters?.difficulty,
        capacity:
            filters?.adults || filters?.children
                ? (filters?.adults ?? 0) + (filters?.children ?? 0)
                : undefined,
        minDuration: filters?.minDuration ?? undefined,
        maxDuration: filters?.maxDuration ?? undefined,
        page: '1',
        all: '1',
    };

    const removeNonExistentKeys = ([_, v]: [string, any]) => v;
    const queryParams = Object.entries(queryParamsUnfiltered).filter(removeNonExistentKeys);

    const { url, headers } = getRequest(subdirectory, locale, config, queryParams);

    const { data, error } = useSWR<{ data: BilberryProductCatalog[] }>(
        url,
        () => fetcher(url, headers),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        productCatalogsFiltered: data?.data ?? [],
        isError: error,
        isLoading: !error && !data,
    };
}

export function useProductCollectionById(
    productCollectionId: number | null,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const subdirectory = `/api/external/product-collections/${productCollectionId}`;
    const queryParams = { id: productCollectionId };
    const { url, headers } = getRequest(subdirectory, locale, config, queryParams);

    const { data, error } = useSWR(
        productCollectionId ? url : null,
        async () => {
            const res: { data: BilberryProductCollection } = await fetcher(url, headers);
            return transformProductCollectionToProductCatalogMediaFormat(res.data);
        },
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        productCollection: data ?? null,
        isError: error,
        isLoading: !error && !data,
    };
}

export async function createReservation(
    reservation: BilberryReservation,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const queryParams = {
        quick_checkout: config.quickCheckout,
    };

    const { url, headers } = getRequest('/api/reserve', locale, config, queryParams, {
        'X-Bilberry-Backoffice-Authorization': config.backoffice_authorize,
    });

    const response = await post(url, headers, reservation);
    return response.data as BilberryBooking;
}

export const useProductCatalogsByIds = compose(
    useProductCatalogsByIdsDefault,
    updateAtomFromCurrencyCodeEffect((x) => get(x, 'productCatalogs[0].currency', null)),
);

function useProductCatalogsByIdsDefault(
    productCatalogIds: number[] | null,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const ids = productCatalogIds ?? [];
    const subdirectory = '/api/product-catalogs';
    const queryParams = { ids: ids.join(',') };
    const { url, headers } = getRequest(subdirectory, locale, config, queryParams);

    const { data, error } = useSWR<{ data: BilberryProductCatalog[] }>(
        ids.length > 0 ? url : null,
        async () => {
            debugLog('useProductCatalogs, ', ids);
            const isCacheHit = isAllProductCatalogIdsInCache(ids, locale, config);
            const configuredForPreload = isAllProductCatalogIdsConfiguredForPreload(
                ids,
                locale,
                config,
            );

            if (isCacheHit) {
                const data = ids.map((id) => config.cache.productCatalogs[id]);
                debugLog('Returning productCatalogIds from cache', data);
                return { data };
            }

            if (configuredForPreload) {
                debugLog('Waiting for preload');

                const waitStart = new Date().valueOf();
                let currentTime = waitStart;
                while (config.preloadInProgress.productCatalogs && currentTime - waitStart < 5000) {
                    await wait(50);
                    currentTime = new Date().valueOf();
                }

                const data = Object.values(config.cache.productCatalogs).filter((productCatalog) =>
                    ids.includes(productCatalog.id),
                );
                debugLog(
                    `Waited for ${new Date().valueOf() - waitStart} ms. Returning from preload`,
                    data,
                );
                return { data };
            }

            debugLog('Skipping preload: ', ids);
            return fetcher(url, headers);
        },
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        productCatalogs: data?.data ?? [],
        isError: error,
        isLoading: !error && !data,
    };
}

export const useProductCatalog = compose(
    useProductCatalogDefault,
    updateAtomFromCurrencyCodeEffect((x) => get(x, 'productCatalog.currency', null)),
);

function useProductCatalogDefault(
    productCatalogId: number,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const subdirectory = `/api/product-catalogs/${productCatalogId}`;
    const { url, headers } = getRequest(subdirectory, locale, config);

    const { data, error } = useSWR<{ data: BilberryProductCatalog }>(
        url,
        async () => {
            debugLog('useProductCatalog', productCatalogId);
            const isCacheHit = isAllProductCatalogIdsInCache([productCatalogId], locale, config);
            const configuredForPreload = isAllProductCatalogIdsConfiguredForPreload(
                [productCatalogId],
                locale,
                config,
            );

            if (isCacheHit) {
                debugLog('Returning from cache: ', productCatalogId);
                return { data: config.cache.productCatalogs[productCatalogId] };
            }

            if (configuredForPreload) {
                debugLog('Waiting for preload');

                const waitStart = new Date().valueOf();
                let currentTime = waitStart;
                while (config.preloadInProgress.productCatalogs && currentTime - waitStart < 5000) {
                    await wait(50);
                    currentTime = new Date().valueOf();
                }

                const data = config.cache.productCatalogs[productCatalogId];
                if (data) {
                    debugLog(
                        `Waited for ${new Date().valueOf() - waitStart} ms. Returning from promise`,
                        data,
                    );
                    return { data };
                }
            }

            debugLog('Skipping preload: ', productCatalogId);
            return fetcher(url, headers);
        },
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        productCatalog: data?.data ?? null,
        isError: error,
        isLoading: !error && !data,
    };
}

export async function postLeadForm(
    lead: BilberryLeadFormData,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getRequest('/api/leads', locale, config);

    const response = await post(url, headers, lead);
    return response.data as any;
}

export async function createGiftcard(
    giftcard: BilberryCreateGiftcardRequest,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getRequest('/api/giftcards', locale, config);

    const response = await post(url, headers, giftcard);
    return response.data as BilberryGiftcard;
}

export async function getGiftcardStatus(
    giftcardId: string,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const subdirectory = `/api/giftcards/${giftcardId}`;
    const { url, headers } = getRequest(subdirectory, locale, config);

    const response = await fetcher(url, headers);
    return response.data as BilberryGiftcardStatus;
}

export function useBilberrySettings(locale: string, config: BilberryWidgetsGlobalType) {
    const { url, headers } = getRequest('/api/settings', locale, config);

    const { data, error } = useSWR(url, () => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    return {
        bilberrySettings: data?.data as BilberrySettings,
        isError: error,
        isLoading: !error && !data,
    };
}

export async function getBilberrySettings(locale: string, config: BilberryWidgetsGlobalType) {
    const { url, headers } = getRequest('/api/settings', locale, config);

    const body = await fetcher(url, headers);
    return body?.data as BilberrySettings;
}

export function usePromocodeStatus(
    promoCode: string | undefined,
    locale: string,
    config: BilberryWidgetsGlobalType,
    onError?: (error: { message: string }) => void,
    onSuccess?: (data: BilberryPromoCodeStatus) => void,
) {
    const { url, headers } = getRequest(`/api/v2/promo-code/${promoCode}`, locale, config);

    const { data, error, mutate } = useSWR(promoCode ? url : null, () => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        onError,
        onSuccess: (data) => onSuccess?.call(null, data.data as BilberryPromoCodeStatus),
    });

    return {
        data: data ? (data.data as BilberryPromoCodeStatus) : undefined,
        mutate,
        error,
    };
}

export function useSmartEvent(eventId: number, locale: string, config: BilberryWidgetsGlobalType) {
    const { url, headers } = getRequest(`/api/webevents/${eventId}`, locale, config);

    const { data, error, isLoading } = useSWR<{ data: BilberrySmartEvent }>(
        eventId ? url : null,
        () => fetcher(url, headers),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        event: data?.data,
        isLoading,
        error,
    };
}

export function useBilberryProductsByIds(
    ids: string[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const fetcher = (ids: string[]) => getBilberryProductsByIds(ids, locale, config);
    const { data, error, isLoading } = useSWR<BilberryProduct[]>(
        ids.length > 0 ? ids : null,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    return {
        products: data,
        isLoading,
        error,
    };
}

export async function getBilberryProductsByIds(
    ids: string[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getRequest(`/api/products?ids=${ids.join(',')}`, locale, config);
    const res = await fetch(url, { headers });
    const data = await res.json();

    return data?.data as BilberryProduct[];
}

export async function getExtrasByIds(
    ids: string[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getRequest('/api/extras', locale, config);
    const body = {
        tours: ids,
    };
    const data = await post(url, headers, body);

    return data?.data;
}

let usePackageCallerIndex = 0;
export function usePackage(
    id: number | undefined,
    locale: string,
    config: BilberryWidgetsGlobalType,
    opts?: {
        onError?: (error: { message: string }) => void;
        onSuccess?: (data: any) => void;
    },
) {
    const idx = useMemo(() => usePackageCallerIndex++, []);
    const { url, headers } = getRequest(`/api/packages/${id}`, locale, config);

    const { data, error } = useSWR<{ data: BilberryPackage }>(
        id !== undefined ? `${url}-${idx}` : null,
        () => fetcher(url, headers),
        {
            ...opts,
            onSuccess: (data) => opts?.onSuccess?.(data.data),
        },
    );

    return {
        pkg: data?.data,
        error,
    };
}

export function useAvailableProductsFromPackageProductMetadata(
    productMetadata: PackageProductMetadata[],
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const pcIds = getProductCatalogIds(productMetadata);
    const [startDate, endDate] = getTimeRange(productMetadata);
    const fetcherFn = async (ids: number[]) => {
        const reqs = ids.map((id) => {
            const { url, headers } = getAvailableProductsRequest(
                id,
                startDate,
                endDate,
                locale,
                config,
            );
            return fetcher(url, headers);
        });
        return await Promise.all(reqs);
    };

    const { data, error } = useSWR<{ [id: string]: BilberryProduct[] }[]>(pcIds, fetcherFn, {
        shouldRetryOnError: false,
    });

    return {
        availabilities: data,
        error,
    };
}

export function usePackageAvailability(
    id: number | undefined,
    locale: string,
    config: BilberryWidgetsGlobalType,
) {
    const { url, headers } = getRequest(`/api/packages/${id}/availability`, locale, config);

    const { data, error } = useSWR<{ data: BilberryPackageAvailability }>(id ? url : null, () =>
        fetcher(url, headers),
    );

    return {
        pkgAvailability: data?.data ?? [],
        error,
    };
}

export function usePackages(locale: string, config: BilberryWidgetsGlobalType) {
    const { url, headers } = getRequest(`/api/packages`, locale, config);

    const { data, error } = useSWR<{ data: BilberryPackageMetadata[] }>(url, () =>
        fetcher(url, headers),
    );

    return {
        pkgs: data?.data ?? [],
        error,
    };
}

export async function getPackage(
    id: number | undefined,
    locale: string,
    config: BilberryWidgetsGlobalType,
): Promise<BilberryPackage> {
    const { url, headers } = getRequest(`/api/packages/${id}`, locale, config);
    const res = await fetch(url, { headers });
    const data = await res.json();
    return data?.data;
}

export async function getPackageAvailability(
    id: number | undefined,
    locale: string,
    config: BilberryWidgetsGlobalType,
): Promise<BilberryPackageAvailability> {
    const { url, headers } = getRequest(`/api/packages/${id}/availability`, locale, config);
    const res = await fetch(url, { headers });
    const data = await res.json();
    return data?.data;
}

export function usePackageAvailabilityMulti(
    id: number | undefined,
    locale: string,
    config: BilberryWidgetsGlobalType,
    start: Dayjs,
    end: Dayjs,
) {
    const queryParams = {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
    };
    const { url, headers } = getRequest(
        `/api/packages/${id}/availability-multi`,
        locale,
        config,
        queryParams,
    );

    const { data, error } = useSWR<{ data: BilberryPackageAvailability }>(id ? url : null, () =>
        fetcher(url, headers),
    );

    return {
        pkgAvailability: data?.data ?? [],
        error,
    };
}

function getTimeRange(packageProduct: PackageProductMetadata[]) {
    if (packageProduct.length === 0) return [];
    const times = packageProduct.flatMap((p) => [p.start, p.end]);
    const minTime = times.reduce((min, t) => (t.isBefore(min) ? t : min));
    const maxTime = times.reduce((max, t) => (t.isAfter(max) ? t : max));

    return [minTime, maxTime];
}

function getProductCatalogIds(products: { id: string }[]) {
    return uniq(products.map(({ id }) => Number(id)));
}
