import ClearIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useLocale } from 'src/i18n/locale';
import { removeFromCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { ICartItem, ICartPackageItem, ICartUiItem } from 'src/state/cart/ICart';
import { capitalize } from 'src/utils/common/TextUtils';
import {
    convertCartDataToUiFriendlyCartType,
    getCartItemSummaryDateInformation,
    getCartItemTypeAndId,
    isICartAccommodationItemType,
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
} from 'src/utils/domain/cart/cartUtils';
import SummaryAccommodationInformation from './SummaryInformation/SummaryAccommodationInformation';
import SummaryActivityInformation from './SummaryInformation/SummaryActivityInformation';
import SummaryMultiDayProductInformation from './SummaryInformation/SummaryMultiDayProductInformation';
import SummaryPackageInformation from './SummaryInformation/SummaryPackageInformation';

interface IProps {
    isCheckoutSummary: boolean;
    cartItems: ICartItem[];
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
}

export default function SummaryBooking(props: IProps): JSX.Element {
    const { cartItems, isCheckoutSummary, invertedColorsClass, isConfirmationPage } = props;
    const { t } = useLocale();
    const theme = useTheme();

    const handleClearActivity = (item: ICartUiItem) => {
        if (props.cartItems.length > 1) {
            cartAtom.update(removeFromCartEvent(item.id));
            return;
        }

        cartAtom.update(removeFromCartEvent(item.id));
    };

    const accommodations = cartItems.filter(({ item }) => isICartAccommodationItemType(item));
    const multiDayProducts = cartItems.filter(({ item }) => isICartMultiDayProductItemType(item));
    const activities = cartItems.filter(({ item }) => isICartProductItemType(item));
    const packages = cartItems.filter(({ item }) => isICartPackageItemType(item));

    return (
        <Box>
            {packages.length > 0 &&
                packages.map((p) => (
                    <Grid
                        mb={theme.spacing(4)}
                        key={`summary-booking-package-${(p.item as ICartPackageItem).pkg.id}`}
                    >
                        <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                            {t.packages}
                        </Typography>
                        <SummaryBookingItem
                            key={getCartItemTypeAndId(p)}
                            item={p}
                            isCheckoutSummary={isCheckoutSummary}
                            invertedColorsClass={invertedColorsClass}
                            isConfirmationPage={isConfirmationPage}
                            onClearActivity={handleClearActivity}
                        />
                    </Grid>
                ))}
            {accommodations.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.accommodations}
                    </Typography>
                    {accommodations.map((item, i) => {
                        return (
                            <SummaryBookingItem
                                key={getCartItemTypeAndId(item) + i}
                                item={item}
                                isCheckoutSummary={isCheckoutSummary}
                                invertedColorsClass={invertedColorsClass}
                                isConfirmationPage={isConfirmationPage}
                                onClearActivity={handleClearActivity}
                            />
                        );
                    })}
                </Grid>
            )}
            {multiDayProducts.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.accommodations}
                    </Typography>
                    {multiDayProducts.map((item, i) => {
                        return (
                            <SummaryBookingItem
                                key={getCartItemTypeAndId(item) + i}
                                item={item}
                                isCheckoutSummary={isCheckoutSummary}
                                invertedColorsClass={invertedColorsClass}
                                isConfirmationPage={isConfirmationPage}
                                onClearActivity={handleClearActivity}
                            />
                        );
                    })}
                </Grid>
            )}
            {activities.length > 0 && (
                <Grid mb={theme.spacing(4)}>
                    <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                        {t.activities}
                    </Typography>
                    {activities.map((item, i) => {
                        return (
                            <SummaryBookingItem
                                key={getCartItemTypeAndId(item) + i}
                                item={item}
                                isCheckoutSummary={isCheckoutSummary}
                                invertedColorsClass={invertedColorsClass}
                                isConfirmationPage={isConfirmationPage}
                                onClearActivity={handleClearActivity}
                            />
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
}

interface SummaryBookingItemProps {
    item: ICartItem;
    onClearActivity?: (param: ICartUiItem) => void;
    isCheckoutSummary: boolean;
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
}

function SummaryBookingItem(props: SummaryBookingItemProps): JSX.Element {
    const { locale, t } = useLocale();
    const theme = useTheme();
    const cartUiItem = convertCartDataToUiFriendlyCartType(props.item);

    return (
        <Box key={'summary-item-' + cartUiItem.id}>
            <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                borderBottom={`1px solid ${theme.palette.grey[400]}`}
                margin={theme.spacing(1, 0, 1.5, 0)}
            >
                <Box>
                    <Typography
                        variant="h6"
                        align="left"
                        color="textSecondary"
                        sx={props.invertedColorsClass}
                    >
                        {cartUiItem.title}
                    </Typography>
                    <Typography align="left" color="textSecondary" sx={props.invertedColorsClass}>
                        {getCartItemSummaryDateInformation(props.item, locale)}
                    </Typography>
                </Box>
                {props.isCheckoutSummary && props.onClearActivity && (
                    <IconButton
                        aria-label={`
                            ${capitalize(t.remove_cart_item)}${' '}
                            ${cartUiItem.title}
                        `}
                        color="secondary"
                        sx={{
                            height: 'fit-content',
                            marginRight: '-0.5em',
                            ...props.invertedColorsClass,
                        }}
                        onClick={() => props.onClearActivity?.(cartUiItem)}
                    >
                        <ClearIcon />
                    </IconButton>
                )}
            </Grid>
            {isICartProductItemType(props.item.item) ? (
                <SummaryActivityInformation
                    invertedColorsClass={props.invertedColorsClass}
                    isCheckoutSummary={props.isCheckoutSummary}
                    cartProductItem={props.item.item}
                    isConfirmationPage={props.isConfirmationPage}
                />
            ) : isICartAccommodationItemType(props.item.item) ? (
                <SummaryAccommodationInformation
                    cartAccommodationItem={props.item.item}
                    invertedColorsClass={props.invertedColorsClass}
                />
            ) : isICartPackageItemType(props.item.item) ? (
                <SummaryPackageInformation
                    cartPackageItem={props.item.item}
                    invertedColorsClass={props.invertedColorsClass}
                />
            ) : (
                <SummaryMultiDayProductInformation
                    cartMultiDayProductItem={props.item.item}
                    invertedColorsClass={props.invertedColorsClass}
                />
            )}
        </Box>
    );
}
