import { BilberryWidgetsGlobalType } from '../widgetsConfiguration';

async function executeRequest(url: string, options: RequestInit) {
    const response = await fetch(url, options);

    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }

    return await response.json();
}

export async function fetcher(fullUrl: string, headers: any) {
    const options: RequestInit = {
        method: 'GET',
        headers,
    };

    return await executeRequest(fullUrl, options);
}

export async function post(url: string, headers: Headers, data: any) {
    const options: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    };

    return await executeRequest(url, options);
}

export function getUrlWithParams(
    url: string,
    locale: string,
    queryParams: Record<string, any> = {},
    config?: BilberryWidgetsGlobalType,
) {
    const langQueryParam = `lang=${getBilberryLanguageFromLocale(locale)}`.toLowerCase();

    if (config && config.referralCode) {
        queryParams.bref = config.referralCode;
    }

    let fullUrl = `${url}?${langQueryParam}`;

    const queryString = new URLSearchParams(queryParams).toString();
    if (queryString.length > 0) fullUrl += `&${queryString}`;
    return fullUrl;
}

export function getBilberryLanguageFromLocale(locale: string) {
    return locale.split('-')[0];
}

export async function wait(ms: number) {
    return await new Promise((res) => {
        setTimeout(() => res(true), ms);
    });
}
