import { ICartMultiDayProductItem, ICartPackageItem, ICartProductItem } from 'src/state/cart/ICart';
import { isICartPackageItemType } from './cart/cartUtils';

export function findNumberOfTravelersInCartProductItem(
    cartItem: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem,
) {
    if (isICartPackageItemType(cartItem)) {
        return cartItem.quantities.reduce((acc, { quantity }) => acc + quantity, 0);
    }
    return cartItem.quantities.reduce((sum, priceQuantity) => {
        const increment = priceQuantity.quantity * priceQuantity.occupancy;
        return sum + increment;
    }, 0);
}
