import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useSmartEvent } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductDetailsEntry from 'src/widgets/activities/product/product-details/details/ProductDetailsEntry';

export default function SmartEventDetails(props: { id: number }): JSX.Element {
    const config = useConfigurations();
    const theme = useTheme();
    const { locale } = useLocale();
    const { event, isLoading } = useSmartEvent(props.id, locale, config);

    return (
        <Box>
            <Box sx={{ height: '55vh', width: '100%' }}>
                {isLoading ? (
                    <Skeleton variant="rectangular" />
                ) : (
                    <img src={event?.media.image.url} />
                )}
            </Box>
            <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
                <Typography variant="h2" component="h1" sx={{ mb: theme.spacing(1) }}>
                    {event?.title ?? ''}
                </Typography>
                <Typography variant="h4" component="h2" sx={{ mb: theme.spacing(5) }}>
                    {event?.subline ?? ''}
                </Typography>
                <ProductDetailsEntry text={event?.body ?? ''} />
            </Box>
        </Box>
    );
}
