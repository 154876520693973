import { BilberryPriceQuantity } from 'src/state/cart/ICart';
import { BilberryProduct, BilberryProductPriceRate } from 'src/types/bilberry-api-types';

export function calculateMultiDayPriceQuantities(
    quantities: BilberryPriceQuantity[],
    products: BilberryProduct[],
): BilberryPriceQuantity[] {
    return quantities.map((cur) => ({
        ...cur,
        price: products.reduce(
            (acc: number, p) =>
                acc + (p.prices.find((price) => price.price_type === cur.price_type)?.price ?? 0),
            0,
        ),
        retail_price: products.reduce(
            (acc: number, p) =>
                acc +
                (p.prices.find((price) => price.price_type === cur.price_type)?.retail_price ?? 0),
            0,
        ),
        vat_amount: products.reduce(
            (acc: number, p) =>
                acc +
                (p.prices.find((price) => price.price_type === cur.price_type)?.vat_amount ?? 0),
            0,
        ),
        rates: products
            .flatMap(
                (x) => x.prices.find((price) => price.price_type === cur.price_type)?.rates ?? [],
            )
            .reduce((acc: BilberryProductPriceRate[], rate) => {
                const existingRate = acc.find((r) => r.name === rate.name);
                if (!existingRate) acc.push({ ...rate });
                else {
                    existingRate.vat_amount += rate.vat_amount;
                    existingRate.retail_vat_amount += rate.retail_vat_amount;
                    existingRate.price += rate.price;
                    existingRate.retail_price += rate.retail_price;
                }
                return acc;
            }, []),
    }));
}
