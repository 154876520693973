import { createRef, render } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { getIdAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import Booking from 'src/widgets/activities/activity-booking/booking/Booking';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

export function ActivityBookingInline(props: {
    productCatalogId: number;
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
}): JSX.Element {
    const ref = createRef<HTMLDivElement>();
    const [popoverMountPoint, setPopoverMountPoint] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
        if (ref.current) setPopoverMountPoint(ref.current);
    }, []);

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <Booking productCatalogId={props.productCatalogId} isInlineBooking={true} />
        </WidgetWrapper>
    );
}
class BilberryBookingInline extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        overrideLocaleFromAttribute(this);

        render(
            <ActivityBookingInline
                productCatalogId={productCatalogId}
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            />,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking-inline', BilberryBookingInline);
}
