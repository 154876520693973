import dayjs from 'dayjs';
import isObject from 'lodash-es/isObject';
import { localeAtom } from 'src/i18n/locale';
import { ICartAccommodationItem, ICartItem } from 'src/state/cart/ICart';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { showError } from 'src/utils/common/error-handling';
import { getAccommodationById } from '../../api/bilberry-hotels-api-client';
import { configurationAtom } from '../../widgetsConfiguration';

const { t } = localeAtom.subject.value;

export async function deserializeAccommodations(params: string[]) {
    if (params.length === 0) return;

    const accommodationItemList = await Promise.all(params.map(asCartAccommodationItem));

    return accommodationItemList
        .filter((x): x is ICartAccommodationItem => isObject(x))
        .reduce(
            (acc, accommodationItem) => ({
                ...acc,
                [`accommodation-${accommodationItem.accommodation.id}`]: {
                    item: accommodationItem,
                },
            }),
            {} as Record<string, ICartItem>,
        );
}

async function asCartAccommodationItem(item: string) {
    const [id, guestCountString, priceId, startDateString, endDateString] = item.split(',');

    const startDate = dayjs(decodeURIComponent(startDateString));
    const endDate = dayjs(decodeURIComponent(endDateString));
    const guestCount = Number(guestCountString);
    const accommodation = await getAccommodationById(
        id,
        [startDate, endDate],
        guestCount,
        localeAtom.subject.value.locale,
        configurationAtom.subject.value,
    );

    if (!hasCapacity(accommodation, guestCount)) {
        showError(t.thereIsNotEnoughCapacityForTheItemsInThisCart); // TODO: Display error using global error handler
        return;
    }

    const price = accommodation?.prices.find((price) => String(price.id) === priceId);

    const accommodationItem: ICartAccommodationItem = {
        accommodation,
        id,
        start: startDate.toDate(),
        end: endDate.toDate(),
        numberOfGuests: guestCount,
        price: price!,
    };

    return accommodationItem;
}

function hasCapacity(accommodation: BilberryAccommodation, guestCount: number) {
    return accommodation.capacity >= guestCount;
}
