import { Grid, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { createRef, h } from 'preact';
import { StateUpdater, useState } from 'preact/hooks';
import AccommodationCard from 'src/components/domain/accommodation-card/AccommodationCard';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import {
    AccommodationPrice,
    BilberryAccommodation,
    PricedAccommodation,
} from 'src/types/bilberry-hotels-api-types';
import { getLowestAccommodationPrice } from 'src/utils/domain/accommodations/accommodationUtils';
import { GuestInfo } from '../MultipleRoomSearch/guestInfo';

interface IProps {
    accommodationIds: number[];
    accommodationUrls?: string[];
    accommodations?: BilberryAccommodation[];
    guestInfo?: GuestInfo;
    accommodationCardButtonCustomizationKeys?: (keyof UserTextCustomizations)[];
    bookDirectly: boolean;
    onSelectRoom: (selectedAccommodation: PricedAccommodation) => void;
    dateRange: DateRange<Dayjs>;
    nights: number;
    availability: Map<number, number>;
    selectedRoomId?: number;
}

export default function AccommodationCardList(props: IProps): JSX.Element {
    const {
        accommodationIds,
        accommodationUrls,
        accommodationCardButtonCustomizationKeys,
        bookDirectly,
        onSelectRoom,
        guestInfo,
        dateRange,
        availability,
        accommodations = [],
        nights,
        selectedRoomId,
    } = props;

    const [pricedAccommodations, setPricedAccommodations] = useState<PricedAccommodation[]>(
        accommodations.map((accommodation) => {
            return {
                accommodation,
                price: getLowestAccommodationPrice(accommodation.prices),
            };
        }),
    );

    const theme = useTheme();
    const container = createRef<HTMLDivElement>();

    const accommodationCardList = pricedAccommodations?.map((pricedAccommodation) => {
        const unavailable = (availability.get(pricedAccommodation.accommodation.id) ?? 0) <= 0;

        return (
            <AccommodationCard
                key={pricedAccommodation.accommodation.id}
                isUnavailable={unavailable}
                accommodation={pricedAccommodation.accommodation}
                numberPersons={guestInfo!.adults + guestInfo!.children.length}
                url={
                    accommodationUrls?.[
                        accommodationIds.indexOf(pricedAccommodation.accommodation.id ?? 0)
                    ]
                }
                buttonCustomizationKey={
                    accommodationCardButtonCustomizationKeys
                        ? accommodationCardButtonCustomizationKeys[
                              accommodationIds.indexOf(pricedAccommodation.accommodation.id ?? 0)
                          ]
                        : undefined
                }
                bookDirectly={bookDirectly}
                numberOfCards={pricedAccommodations.length}
                onSelectRoom={onSelectRoom}
                dateRange={dateRange}
                nights={nights}
                selected={selectedRoomId === pricedAccommodation.accommodation.id}
                selectedPrice={pricedAccommodation.price}
                setSelectedPrice={(selectedPrice) =>
                    onSelectPrice(selectedPrice, setPricedAccommodations)
                }
            />
        );
    });

    return (
        <Grid container justifyContent="center">
            <Grid
                item
                sx={{
                    padding: theme.spacing(2, 0),
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    [`@media screen and (min-width: ${800}px)`]: {
                        gridTemplateColumns: '1fr 1fr',
                    },
                    [`@media screen and (min-width: ${1250}px)`]: {
                        gridTemplateColumns: '1fr 1fr 1fr',
                    },
                    [`@media screen and (min-width: ${1680}px)`]: {
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    },
                }}
                ref={container}
                gap={2}
            >
                {pricedAccommodations?.length !== 0 && accommodationCardList}
            </Grid>
        </Grid>
    );
}

function onSelectPrice(
    selectedPrice: AccommodationPrice,
    setPricedAccommodations: StateUpdater<PricedAccommodation[]>,
) {
    setPricedAccommodations((current) => {
        if (current) {
            return current.map((x) => {
                if (x.accommodation.prices.find((price) => price.id === selectedPrice.id)) {
                    return { ...x, price: selectedPrice };
                }
                return x;
            });
        } else {
            return current;
        }
    });
}
