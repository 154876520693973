import { h, render } from 'preact';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import LeadsForm from 'src/widgets/activities/leads-form/LeadsForm';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
class BilberryLeadsForm extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        const commentPlaceholderTextCustomizationKey = this.getAttribute(
            'comment-placeholder-text-customization-key',
        ) as keyof UserTextCustomizations;
        const hideRequestDate = getYesAttribute(this, 'hide-request-date');

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <LeadsForm
                    commentPlaceholderTextCustomizationKey={
                        commentPlaceholderTextCustomizationKey ?? undefined
                    }
                    hideRequestDate={hideRequestDate}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-leads-form', BilberryLeadsForm);
}
