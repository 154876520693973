import { FormControl, InputLabel, TextField, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import isNil from 'lodash-es/isNil';
import { createRef, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { Translations } from 'src/i18n/translations/types';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { formatDate, formatTime } from 'src/utils/common/DateHelpers';
import { showError } from 'src/utils/common/error-handling';
import { positionInputLabelAboveField } from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize, deselectTextInput } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { findFirstAvailableProduct, tryFindFirstTimeslot } from 'src/utils/domain/DateHelpers';
import { AvailabilityProductType, TimeSlotType } from 'src/utils/domain/TimeSlotType';
import { getInputCallbackProps, getInputLayoutProps } from './common/calendar-props';
import CalendarPopover from './subcomponents/CalendarPopover';
import { EndIcon } from './subcomponents/EndIcon';

interface IProps {
    availabilityData: {
        [id: string]: AvailabilityProductType<BilberryProduct>[] | AvailabilityProductType<BilberryProduct>;
    };
    selectedTimeSlot?: TimeSlotType<any>;
    onSelectTimeSlot?: (timeslot: TimeSlotType<any> | undefined) => void;
    setSelectedProducts?: (product: AvailabilityProductType<BilberryProduct>[] | undefined) => void;
    availabilitySearchPeriod: { startDay: Dayjs | null; endDay: Dayjs | null };
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: Dayjs | null;
        endDay: Dayjs | null;
    }) => void;
    hasChosenDate: boolean;
    setHasChosenDate(hasChosen: boolean): void;
    attemptedBooking: boolean;
    onSelectDateRange?: (dateRange: DateRange<Dayjs>) => void;
    selectedDateRange?: DateRange<Dayjs>;
    dateRangeVariant?: 'days' | 'nights';
    minDate?: Dayjs;
    id?: string;
    labelColor?: string;
    backgroundColor?: string;
    color?: string;
    variant?: 'filled' | 'outlined';
    quantities: BookingPriceAndQuantity[];
}

export default function Calendar(props: IProps): JSX.Element {
    const {
        selectedTimeSlot,
        onSelectTimeSlot,
        setSelectedProducts,
        selectedDateRange,
        onSelectDateRange,
        availabilityData,
        setAvailabilitySearchPeriod,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        dateRangeVariant,
        minDate = dayjs(),
        id = '',
        labelColor,
        backgroundColor,
        color,
        variant,
        quantities,
    } = props;

    const { t, locale } = useLocale();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    const [displayTimeslot, setDisplayTimeslot] = useState(selectedTimeSlot);
    const [displayDate, setDisplayDate] = useState<Dayjs | null>(
        selectedTimeSlot ? dayjs(selectedTimeSlot.product.start) : null,
    );

    const customizations = useCustomizations();
    const theme = useTheme();
    const refocusElement = createRef<HTMLInputElement>();

    const showErrorDate = attemptedBooking && !hasChosenDate;

    const updateDisplayDate = (date: Dayjs | null) => {
        setDisplayDate(date);
        setDisplayTimeslot(tryFindFirstTimeslot(date, availabilityData, locale, quantities));
    };

    useEffect(() => {
        if (!isNil(displayTimeslot)) return;
        const firstAvailableProduct = findFirstAvailableProduct(availabilityData);

        if (firstAvailableProduct) {
            updateDisplayDate(dayjs(firstAvailableProduct.start));
        }
    }, [availabilityData]);

    useEffect(() => {
        if (selectedTimeSlot) {
            setDisplayTimeslot(selectedTimeSlot);
            setDisplayDate(dayjs(selectedTimeSlot.product.start));
        } else {
            setDisplayTimeslot(undefined);
            setDisplayDate(null);
        }
    }, [selectedTimeSlot]);

    const selectedDateTimeText =
        dateRangeVariant && selectedDateRange
            ? getSelectedRangeText(selectedDateRange, locale, t, dateRangeVariant)
            : getSelectedDateTimeText(selectedTimeSlot, locale);

    const inputId = `bilberry-calendar-input-${id}`;

    const endIcon = (
        <EndIcon
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            showErrorDate={showErrorDate}
        />
    );

    return (
        <Fragment>
            <FormControl fullWidth>
                <InputLabel
                    id={`calendar-label-${id}`}
                    htmlFor={inputId}
                    sx={{
                        color: labelColor ?? customizations.bookingWidgetColorContrast,
                        ...positionInputLabelAboveField,
                    }}
                >
                    {capitalize(t.when_are_you_going)}
                </InputLabel>
                <TextField
                    id={inputId}
                    inputRef={refocusElement}
                    error={showErrorDate}
                    helperText={showErrorDate ? capitalize(t.please_select_date_and_time) : ''}
                    value={
                        hasChosenDate
                            ? selectedDateTimeText
                            : dateRangeVariant
                            ? t.select_dates
                            : t.select_date
                    }
                    {...getInputLayoutProps(
                        t,
                        theme,
                        customizations,
                        showErrorDate,
                        endIcon,
                        backgroundColor,
                        color,
                        labelColor,
                        variant,
                    )}
                    {...getInputCallbackProps(
                        hasChosenDate,
                        setHasChosenDate,
                        deselectTextInput,
                        setAnchorEl,
                    )}
                />
            </FormControl>
            <CalendarPopover
                dateRangeVariant={dateRangeVariant}
                selectedDateRange={selectedDateRange}
                onSelectDateRange={onSelectDateRange}
                minDate={minDate}
                displayDate={displayDate}
                displayTimeslot={displayTimeslot}
                setDisplayTimeslot={setDisplayTimeslot}
                anchorEl={anchorEl}
                availabilityData={availabilityData}
                updateDisplayDate={updateDisplayDate}
                setAnchorEl={setAnchorEl}
                onSelectTimeSlot={onSelectTimeSlot}
                setSelectedProducts={setSelectedProducts}
                setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                id={inputId}
                quantities={quantities}
                setHasChosenDate={setHasChosenDate}
            />
        </Fragment>
    );
}

function getSelectedDateTimeText(selectedTimeSlot: TimeSlotType<any> | undefined, locale: string) {
    if (selectedTimeSlot) {
        const date = dayjs(selectedTimeSlot.product.start);
        const dayString = formatDate(date, locale, 'L');
        return `${dayString} - ${formatTime(date)}`;
    }

    return '';
}

function getSelectedRangeText(
    selectedDateRange: DateRange<Dayjs>,
    locale: string,
    t: Translations,
    dateRangeVariant?: 'days' | 'nights',
) {
    if (selectedDateRange[0] && selectedDateRange[1]) {
        return `${formatDate(selectedDateRange[0], locale, 'L')} - ${formatDate(
            selectedDateRange[1],
            locale,
            'L',
        )}`;
    } else if (dateRangeVariant === 'days' && selectedDateRange[0]) {
        return formatDate(selectedDateRange[0], locale, 'L');
    } else return t.select_dates;
}
