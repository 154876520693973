import { Grid, Typography } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { getAccommodationFullDescription } from 'src/utils/domain/accommodations/accommodationUtils';

type AccommodationOverviewDescriptionProps = {
    accommodation: BilberryAccommodation;
};

export default function AccommodationOverviewDescription({
    accommodation,
}: AccommodationOverviewDescriptionProps) {
    const { t } = useLocale();
    const fullAccommodationDescription = getAccommodationFullDescription(accommodation);

    return (
        <Grid item container>
            <Typography variant="h2" fontSize={22} fontWeight={400}>
                {t.about}
            </Typography>
            <Grid item container alignContent="flex-start">
                <Grid item mb={3}>
                    {fullAccommodationDescription && parseHtmlToMui(fullAccommodationDescription)}
                </Grid>
            </Grid>
        </Grid>
    );
}
