import { Box, Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import EditActivity from 'src/components/domain/summary/edit-activity/EditActivity';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency, getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { ICartProductItem } from 'src/state/cart/ICart';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { useAtom } from 'ximple/atoms';
import SummaryExtras from './SummaryExtras';

interface IProps {
    cartProductItem: ICartProductItem;
    isCheckoutSummary: boolean;
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
}

export default function SummaryActivityInformation(props: IProps): JSX.Element {
    const [currency] = useAtom(currencyAtom);
    const { locale } = useLocale();
    const theme = useTheme();

    return (
        <Box m={theme.spacing(0.5, 0, 2, 0)}>
            {props.cartProductItem.quantities.map((quantity) => {
                return (
                    quantity.quantity > 0 && (
                        <Grid
                            key={`summary-activity-info-${quantity.id}`}
                            container
                            direction="row"
                            justifyContent="space-between"
                            wrap="nowrap"
                        >
                            <Typography
                                align="left"
                                color="textSecondary"
                                sx={props.invertedColorsClass}
                            >
                                {quantity.quantity}x {quantity.name} (
                                {formatCurrency(locale, currency, quantity.price)})
                            </Typography>
                            <Typography
                                align="right"
                                color="textSecondary"
                                sx={props.invertedColorsClass}
                            >
                                {getLocaleNumberFormatTwoDecimals(
                                    locale,
                                    quantity.quantity * quantity.price,
                                )}
                            </Typography>
                        </Grid>
                    )
                );
            })}
            <SummaryExtras cartItem={props.cartProductItem} {...props} />
            {props.isCheckoutSummary && !props.isConfirmationPage && (
                <EditActivity
                    cartItem={props.cartProductItem}
                    invertedColorsClass={props.invertedColorsClass}
                />
            )}
        </Box>
    );
}
