import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { h } from 'preact';
import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import BookingBase, { BookingBaseInline } from 'src/components/domain/booking-base/BookingBase';
import BookingToggleOpenButton from 'src/components/domain/booking-widget-card/BookingToggleOpenButton';
import { useOnClickBook } from 'src/hooks/domain/activities/useOnClickBook';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from 'src/i18n/locale';
import { BilberryPriceQuantity, BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { upcomingSelectedTourAtom } from 'src/state/upcomingSelectedTour.atom';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { infoLog } from 'src/utils/common/Logger';
import { capitalize } from 'src/utils/common/TextUtils';
import {
    getBilberryProductMaxEntrants,
    getBilberryProductMinEntrants,
} from 'src/utils/domain/activities/bilberryProductEntrants';
import { useAvailabilityData } from 'src/utils/domain/api/availabilityDataHook';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { calculateMultiDayPriceQuantities } from 'src/utils/domain/booking/multiDayPriceHelper';
import { getProductCatalogDisplayTitle } from 'src/utils/domain/display-helper';
import { getInitialQuantityData, updateQuantityData } from 'src/utils/domain/price-helper';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';

interface IProps {
    productCatalogId: number;
    expandArrowInside?: boolean;
    isInlineBooking?: boolean;
    positionOffscreen?: boolean;
}
export default function Booking(props: IProps): JSX.Element {
    const {
        productCatalogId,
        expandArrowInside = false,
        positionOffscreen = false,
        isInlineBooking = false,
    } = props;
    const {
        onToggleVisible,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    } = useBookingState();
    const { t, locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(productCatalogId, locale, config);
    const [selectedTimeSlot, setSelectedTimeslot] = useState<
        TimeSlotType<BilberryProduct> | undefined
    >(undefined);
    const [quantities, setQuantities] = useState<BilberryPriceQuantity[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<BilberryProduct[] | undefined>(
        undefined,
    );
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const { availabilitySearchPeriod, availabilityData, setAvailabilitySearchPeriod } =
        useAvailabilityData(productCatalogId, quantities);

    usePreselectedTour(
        setSelectedTimeslot,
        setSelectedProducts,
        setHasChosenDate,
        onToggleVisible,
        productCatalogId,
    );

    useWidgetEventEffect(
        (productCatalog) => ({
            eventType: 'viewItem',
            product: productCatalog,
            productType: 'Activity',
        }),
        productCatalog,
        visible,
    );

    const productCatalogPrices = useMemo(
        () => (productCatalog ? productCatalog.default_prices : []),
        [productCatalog],
    );

    const pricesFromSelectedProduct = useMemo(
        () =>
            selectedProducts && selectedProducts.length > 0
                ? selectedProducts[0].prices
                : undefined,
        [selectedProducts],
    );
    const numPrices = Object.values(availabilityData).flat()[0]?.prices.length;

    useEffect(() => {
        setQuantities((quantities) =>
            updateQuantityData(pricesFromSelectedProduct, productCatalogPrices, quantities),
        );
    }, [pricesFromSelectedProduct, productCatalogPrices]);

    function noExtendSetDateRange(newRange: DateRange<Dayjs>) {
        //Removes the ability to extend range in both directions in mui calendar
        if (!dateRange[0]?.isSame(newRange[0], 'day')) {
            newRange[1] = null;
        }
        setDateRange(newRange);
    }

    const priceQuantities = useMemo(() => {
        if (selectedProducts && selectedProducts.length > 1 && quantities) {
            return calculateMultiDayPriceQuantities(
                quantities,
                selectedProducts.slice(0, selectedProducts.length - 1),
            );
        }

        return quantities;
    }, [quantities, selectedProducts]);

    const onClickBook = useOnClickBook(
        quantities,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        config,
        selectedProducts,
        setSelectedProducts,
        setSelectedTimeslot,
        productCatalogPrices,
        pricesFromSelectedProduct,
        setDateRange,
        productCatalog?.is_accommodation
            ? 'nights'
            : productCatalog?.is_rental
                ? 'days'
                : undefined,
    );

    if (isInlineBooking) {
        return (
            <BookingBaseInline
                productTitle={getProductCatalogDisplayTitle(productCatalog)}
                setSelectedProducts={setSelectedProducts}
                travelerQuantities={priceQuantities}
                priceQuantities={priceQuantities}
                numPrices={numPrices}
                defaultQuantities={getInitialQuantityData(
                    productCatalogPrices,
                    productCatalog?.min_entrants,
                )}
                setQuantities={setQuantities as unknown as StateUpdater<BookingPriceAndQuantity[]>}
                selectedTimeSlot={selectedTimeSlot}
                onSelectTimeSlot={setSelectedTimeslot}
                availabilityData={availabilityData}
                rightButtonLabel={capitalize(t.book_now)}
                onClickRightButton={onClickBook}
                availabilitySearchPeriod={availabilitySearchPeriod}
                setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                hasChosenDate={hasChosenDate}
                setHasChosenDate={setHasChosenDate}
                attemptedBooking={attemptedBooking}
                productCapacity={getBilberryProductMaxEntrants(selectedProducts)}
                title={capitalize(t.book_now)}
                minEntrants={getBilberryProductMinEntrants(selectedProducts, productCatalog)}
                onSelectDateRange={noExtendSetDateRange}
                dateRangeVariant={
                    productCatalog?.is_accommodation
                        ? 'nights'
                        : productCatalog?.is_rental
                            ? 'days'
                            : undefined
                }
                selectedDateRange={dateRange}
                bookingCardRef={boxRef}
            />
        );
    }

    return (
        <BookingBase
            bookingCardRef={boxRef}
            hideLeftButton={true}
            productTitle={getProductCatalogDisplayTitle(productCatalog)}
            setSelectedProducts={setSelectedProducts}
            travelerQuantities={priceQuantities}
            priceQuantities={priceQuantities}
            numPrices={numPrices}
            defaultQuantities={getInitialQuantityData(
                productCatalogPrices,
                productCatalog?.min_entrants,
            )}
            setQuantities={setQuantities as unknown as StateUpdater<BookingPriceAndQuantity[]>}
            selectedTimeSlot={selectedTimeSlot}
            onSelectTimeSlot={setSelectedTimeslot}
            availabilityData={availabilityData}
            rightButtonLabel={capitalize(t.book_now)}
            onClickRightButton={onClickBook}
            availabilitySearchPeriod={availabilitySearchPeriod}
            setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            attemptedBooking={attemptedBooking}
            productCapacity={getBilberryProductMaxEntrants(selectedProducts)}
            title={capitalize(t.book_now)}
            fromPrice={productCatalog?.from_price}
            expandArrowInside={expandArrowInside}
            visible={visible}
            onToggleVisible={onToggleVisible}
            minEntrants={getBilberryProductMinEntrants(selectedProducts, productCatalog)}
            onSelectDateRange={noExtendSetDateRange}
            dateRangeVariant={
                productCatalog?.is_accommodation
                    ? 'nights'
                    : productCatalog?.is_rental
                        ? 'days'
                        : undefined
            }
            selectedDateRange={dateRange}
            toggleButton={
                <BookingToggleOpenButton expandArrowInside={expandArrowInside} visible={visible} />
            }
            positionOffscreen={positionOffscreen}
        />
    );
}

function usePreselectedTour(
    setSelectedTimeslot: (timeslot: TimeSlotType<BilberryProduct> | undefined) => void,
    setSelectedProducts: (products: BilberryProduct[] | undefined) => void,
    setHasChosenDate: (hasChosenDate: boolean) => void,
    onToggleVisible: (visible: boolean, interaction: 'keyboard' | 'mouse') => void,
    productCatalogId: number,
) {
    const [selectedTour, setSelectedTour] = useAtom(upcomingSelectedTourAtom);
    useEffect(() => {
        if (selectedTour && selectedTour.product_catalog_id === productCatalogId) {
            infoLog('preselected tour from upcoming tours: ', selectedTour);
            setSelectedTimeslot({
                product: selectedTour,
                label: selectedTour.web_title,
            });
            setSelectedProducts([selectedTour]);
            setHasChosenDate(true);
            onToggleVisible(true, 'keyboard');
            setSelectedTour(null);
        }
    }, [
        selectedTour,
        setSelectedTimeslot,
        setHasChosenDate,
        setSelectedProducts,
        onToggleVisible,
        setSelectedTour,
        productCatalogId,
    ]);
}
