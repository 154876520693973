import { Fragment } from 'preact';
import { Box, Grid } from '@mui/material';
import { Theme, useMediaQuery } from '@mui/material';
import Overlay from 'src/components/common/overlay/Overlay';
import Tabs, { Tab as TabType, TabSet, TabsProps } from 'src/components/common/tabs/Tabs';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Tab from './Tab'; 
import { useLocale } from 'src/i18n/locale';

export type CheckoutTab = TabType & {
    title: string,
    description?: string,
    backButtonOnClick?: () => void,
    backButtonText?: string,
    hideTitle?: boolean,
    hideBackButton?: boolean
};

type CheckoutTabSet = TabSet & { tabs: CheckoutTab[] };

type CheckoutTabsProps = TabsProps & { tabset: CheckoutTabSet };

function ArrangeTabsContent(tabset: CheckoutTabSet): CheckoutTabSet {
    const arrangedTabset = { ...tabset };

    arrangedTabset.tabs = tabset.tabs.map((checkoutTab: CheckoutTab) => { 
        const tab = { ...checkoutTab };
        tab.content = <Tab tab={{ ...checkoutTab }} />;

        return tab;
    });

    return arrangedTabset;
}

export default function CheckoutTabs({ tabset, currentTab, onChange }: CheckoutTabsProps) {
    const arrangedTabset = ArrangeTabsContent(tabset); 

    return (
        <Overlay>
            <Tabs
                tabset={arrangedTabset}
                currentTab={currentTab}
                onChange={onChange}
            />
        </Overlay>
    );
}
