import ActivateBookingWidget from 'src/widgets/activities/activate-booking-widget/ActivateBookingWidget';
import ActivityBooking from 'src/widgets/activities/activity-booking/ActivityBooking';
import ActivityBookingInline from 'src/widgets/activities/booking-inline/BookingInline';
import BasketIcon from 'src/widgets/activities/basket-icon/BasketIcon';
import Basket from 'src/widgets/activities/basket/Basket';
import BookingSearchFormWidget from 'src/widgets/activities/booking-search/BookingSearchFormWidget';
import BookingSearchWidget from 'src/widgets/activities/booking-search/BookingSearchWidget';
import BookingToggler from 'src/widgets/activities/booking-toggler/BookingToggler';
import Checkout from 'src/widgets/activities/checkout/Checkout';
import LeadsFormWidget from 'src/widgets/activities/leads-form/LeadsFormWidget';
import ProductFeatured from 'src/widgets/activities/product/product-featured/ProductFeatured';
import ProductGallery2Widget from 'src/widgets/activities/product/product-gallery-2/ProductGallery2Widget';
import ProductGalleryWidget from 'src/widgets/activities/product/product-gallery/ProductGalleryWidget';
import ProductImageWidget from 'src/widgets/activities/product/product-image/ProductImageWidget';
import ProductList2 from 'src/widgets/activities/product/product-list-2/ProductList2';
import ProductList from 'src/widgets/activities/product/product-list/ProductList';
import ProductOverview2Widget from 'src/widgets/activities/product/product-overview-2/ProductOverview2Widget';
import ProductOverviewWidget from 'src/widgets/activities/product/product-overview/ProductOverviewWidget';
import ProductPage2 from 'src/widgets/activities/product/product-page-2/ProductPage2Widget';
import ProductPage from 'src/widgets/activities/product/product-page/ProductPageWidget';
import RequestTourWidget from 'src/widgets/activities/request-tour/RequestTourWidget';
import { loadCartFromQueryParameters } from './utils/domain/sharing/deserializeCart';
import AccommodationBooking from './widgets/activities/accommodation-booking/AccommodationBooking';
import AccommodationGalleryWidget from './widgets/activities/accommodation-gallery/AccommodationGalleryWidget';
import AccommodationProductOverviewWidget from './widgets/activities/accommodation-product/AccommodationProductOverviewWidget';
import AccommodationSearchWidget from './widgets/activities/accommodation-search/AccommodationSearchWidget';
import CreateGiftcardButton from './widgets/activities/create-gift-card-button/CreateGiftcardButton';
import ProductListBookableWidget from './widgets/activities/product/product-list-bookable/ProductListBookableWidget';
import SmartEventDetailsWidget from './widgets/activities/smart-events/SmartEventDetailsWidget';
import SmartEventListWidget from './widgets/activities/smart-events/SmartEventListWidget';
import TourListBookableWidget from './widgets/activities/tour/TourListBookableWidget';
import UpcomingToursWidget from './widgets/activities/upcoming-tours/UpcomingToursWidget';
import PackageItinerary from './widgets/activities/packages/PackageItineraryWidget';
import PackageBooking from './widgets/activities/packages/PackageBookingWidget';
import PackageList from './widgets/activities/packages/PackageListWidget';

import { initSentry } from 'src/utils/domain/sentryConfigurations';
import { setupPaymentProvider } from 'src/utils/domain/initPayments';
import ModalWidget from 'src/widgets/activities/modal-widget/ModalWidget';
import { initFromSettings } from './utils/domain/settings/initFromSettings';
import ProductDetailsWidget from 'src/widgets/activities/product/product-details/ProductDetailsWidget';
import GlobalSnackbar from 'src/widgets/common/GlobalSnackbar';
import 'src/utils/common/error-handling';
import 'src/utils/domain/setup';
import 'src/events/connect-analytics';

ProductDetailsWidget();
ModalWidget();

initSentry();
initFromSettings();

const checkout = document.createElement('bilberry-checkout');
const modal = document.createElement('bilberry-modal');

setupPaymentProvider(checkout);

ProductList();
ProductPage();
BookingToggler();
BasketIcon();
Basket();
ActivityBooking();
ActivityBookingInline();
Checkout();
ActivateBookingWidget();
ProductOverviewWidget();
ProductGalleryWidget();
ProductImageWidget();
LeadsFormWidget();
ProductList2();
BookingSearchWidget();
BookingSearchFormWidget();
ProductOverview2Widget();
ProductGallery2Widget();
ProductPage2();
RequestTourWidget();
ProductFeatured();
CreateGiftcardButton();
AccommodationSearchWidget();
AccommodationProductOverviewWidget();
AccommodationBooking();
AccommodationGalleryWidget();
UpcomingToursWidget();
ProductListBookableWidget();
SmartEventListWidget();
SmartEventDetailsWidget();
TourListBookableWidget();
GlobalSnackbar();
PackageItinerary();
PackageBooking();
PackageList();

const basket = document.createElement('bilberry-basket');
const snackbar = document.createElement('bilberry-snackbar');

window.onload = async () => {
    await loadCartFromQueryParameters();
};

// This try / catch is needed because the loading of resources is more volatile when
// deployed in production than when developing, and because the DOMContentLoaded event
// appears to trigger before this code is executed, hence the code inside is not run when in dev.
try {
    document.body.appendChild(checkout);
    document.body.appendChild(modal);
    document.body.appendChild(basket);
    document.body.appendChild(snackbar);
} catch (_e) {
    window.addEventListener('DOMContentLoaded', () => {
        document.body.appendChild(basket);
        document.body.appendChild(checkout);
        document.body.appendChild(modal);
        document.body.appendChild(snackbar);
    });
}
