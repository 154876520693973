import { Dayjs } from 'dayjs';
import { RefObject } from 'preact';
import { route } from 'preact-router';
import { StateUpdater, useCallback } from 'preact/hooks';
import { createAddPackageToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import {
    BilberryPriceQuantity,
    BookingPriceAndQuantity,
    PackagePriceQuantity,
} from 'src/state/cart/ICart';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import {
    BilberryPackage,
    BilberryPackageAvailability,
    BilberryProductPrice,
} from 'src/types/bilberry-api-types';
import { getPackageDuration } from 'src/utils/domain/packages/duration';
import { updateQuantityData } from 'src/utils/domain/price-helper';

export function useOnClickBookPackage(
    pkg: BilberryPackage | undefined,
    availability: BilberryPackageAvailability,
    quantities: BilberryPriceQuantity[],
    packagePrices: (BookingPriceAndQuantity & BilberryProductPrice)[],
    attemptedBooking: boolean,
    onBookingAttempt: StateUpdater<boolean>,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onQuantitiesChange: StateUpdater<BilberryPriceQuantity[]>,
    hasChosenDate: boolean,
    selectedDate: Dayjs | null,
    setSelectedDate: (value: Dayjs | null) => void,
) {
    return useCallback(() => {
        if (!attemptedBooking) onBookingAttempt(true);
        if (hasChosenDate && pkg && selectedDate) {
            if (shouldShowBasketOnBook)
                showBasketAtom.update({
                    visible: true,
                    refocusElementOnClose: boxRef,
                });

            const [startTime, endTime] = getPackageDuration(selectedDate, pkg);

            const quantityCounts: PackagePriceQuantity[] =
                quantities?.map((q) => ({ id: q.id, quantity: q.quantity })) ?? [];

            const vats = availability.reduce(
                (acc, { ticket_option_id, vats }) => ({
                    ...acc,
                    [ticket_option_id]: vats,
                }),
                {},
            );

            cartAtom.update(
                createAddPackageToCartEvent(
                    pkg,
                    vats,
                    quantityCounts,
                    [],
                    startTime.format(),
                    endTime.format(),
                ),
            );
            toggleBookingAtom.update({ visible: false });
            setSelectedDate(null);
            onQuantitiesChange(updateQuantityData(undefined, packagePrices, quantities));

            if (!shouldShowBasketOnBook) route('/checkout');
        }
    }, [
        pkg,
        availability,
        attemptedBooking,
        onBookingAttempt,
        hasChosenDate,
        quantities,
        boxRef,
        packagePrices,
        shouldShowBasketOnBook,
        onQuantitiesChange,
        selectedDate,
        setSelectedDate,
    ]);
}
