import { BilberryWidgetEvent } from './events.types';
import * as GA from './google.analytics';
import * as MP from './metaPixel.analytics';

if (BilberryWidgetsGlobal.useGtag === true) {
    (window as any).addEventListener(
        'bilberrywidgetevent',
        (e: CustomEvent<BilberryWidgetEvent>) => {
            GA.subscriberGtag(e.detail);
        },
    );
} else if (!BilberryWidgetsGlobal.useGtag) {
    (window as any).addEventListener(
        'bilberrywidgetevent',
        (e: CustomEvent<BilberryWidgetEvent>) => {
            GA.subscriberGTM(e.detail);
        },
    );
}

if (BilberryWidgetsGlobal.useMetaPixel === true) {
    (window as any).addEventListener(
        'bilberrywidgetevent',
        (e: CustomEvent<BilberryWidgetEvent>) => {
            MP.subscriber(e.detail);
        },
    );
}
