import { Checkbox, useTheme } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { lighten } from '@mui/material/styles';
import { TargetedEvent } from 'preact/compat';
import { useState } from 'preact/hooks';

interface PreactCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
    onChange: (checked: boolean) => void;
}

/**
 * Focus on checkboxes are not set correctly when using Preact.
 * https://github.com/mui/material-ui/issues/27144
 *
 * PreactCheckbox avoids this issue by _manually_ handling the focus state.
 *
 * NOTE: `onChange` accepts a `boolean` instead of the standard `HTMLInputElement`
 */
export function PreactCheckbox(props: PreactCheckboxProps) {
    const [checked, setChecked] = useState(props.checked);
    const [focused, setFocused] = useState(false);
    const theme = useTheme();

    const handleChange = (isChecked: boolean) => {
        setChecked(isChecked);
        props.onChange(isChecked);
    };

    return (
        <Checkbox
            {...props}
            checked={checked}
            sx={[
                focused && {
                    background: `radial-gradient(circle, ${lighten(
                        theme.palette.primary.main,
                        0.8,
                    )} 50%, rgba(255,255,255,0) 60%)`,
                    outline: 'none',
                },
            ]}
            onClick={() => {
                setFocused(false);
                handleChange(!checked);
            }}
            onKeyDown={(e: KeyboardEvent) => {
                if (e.code === 'Enter' || e.code === 'Space') {
                    e.preventDefault();
                    handleChange(!checked);
                }
            }}
            onChange={(e: TargetedEvent<HTMLInputElement, Event>) =>
                handleChange(e.currentTarget.checked)
            }
            inputProps={{
                ...(props.inputProps ?? {}),
                onFocus: (event: FocusEvent) => {
                    setFocused(true);
                    props.inputProps?.onFocus && props.inputProps.onFocus(event);
                },
                onBlur: (event: FocusEvent) => {
                    setFocused(false);
                    props.inputProps?.onBlur && props.inputProps.onBlur(event);
                },
            }}
        />
    );
}
