import { Box, Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { GiftTypeEnum } from 'src/components/domain/gift-type-selection/GiftTypeSelections';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { getSimpleDateString } from 'src/utils/common/DateHelpers';
import { capitalize } from 'src/utils/common/TextUtils';

interface IProps {
    largeScreen: boolean;
    giftcardTotal: number;
    selectedGiftType: GiftTypeEnum | null;
    expiryDate?: string;
}

export default function SummaryGiftcardBooking(props: IProps): JSX.Element {
    const { locale, t } = useLocale();
    const theme = useTheme();

    return (
        <Box>
            <Box>
                <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    borderBottom={`1px solid ${theme.palette.grey[400]}`}
                    m={theme.spacing(2, 0, 0.5, 0)}
                >
                    <Box>
                        <Typography variant="h6" align="left" color="textSecondary">
                            {capitalize(t.gift_card)}
                        </Typography>
                    </Box>
                </Grid>

                <Box sx={{ margin: theme.spacing(0.5, 0, 2, 0) }}>
                    <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                        <Grid container item direction="column">
                            <Typography align="left" color="textSecondary">
                                {props.selectedGiftType === GiftTypeEnum.Print
                                    ? capitalize(t.purchased_giftcard.self)
                                    : capitalize(t.purchased_giftcard.recipient)}
                            </Typography>
                            {props.expiryDate && (
                                <Typography align="left" color="textPrimary">
                                    <Box fontWeight="light" fontSize="12px">
                                        {capitalize(t.valid_until)}:{' '}
                                        {getSimpleDateString(new Date(props.expiryDate), locale)}
                                    </Box>
                                </Typography>
                            )}
                        </Grid>
                        <Typography align="right" color="textSecondary">
                            {getLocaleNumberFormatTwoDecimals(locale, props.giftcardTotal)}
                        </Typography>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
