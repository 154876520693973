import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import TourProductCardList from 'src/components/domain/tour-product-card/TourProductCardList';
import { useLocale } from 'src/i18n/locale';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { groupToursByProduct } from 'src/utils/domain/activities/tours';
import { useBilberryProductsByIds } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    ids: string[];
    hideReadMore?: boolean;
};

export default function TourListBookable(props: Props) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { products } = useBilberryProductsByIds(props.ids, locale, config);

    const tours = useMemo(() => {
        const toursWithId = products ?? [];
        return groupToursByProduct(toursWithId, 'product_catalog_id');
    }, [products]);

    return <TourProductCardList tours={tours} {...props} />;
}
