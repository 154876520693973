import { Box, Typography } from '@mui/material';
import { h } from 'preact';

type AccommodationProductDetailsProps = {
    header: string;
    description: string | string[];
    sx: any;
};

export default function AccommodationProductDetails({
    header,
    description,
    sx,
}: AccommodationProductDetailsProps) {
    return (
        <Box className={sx}>
            <Typography variant="h2" fontSize={22} fontWeight={400}>
                {header}
            </Typography>
            {Array.isArray(description) ? (
                description.map((d) => <Typography fontSize={16}>{d}</Typography>)
            ) : (
                <Typography fontSize={16}>{description}</Typography>
            )}
        </Box>
    );
}
