import { currencyAtom } from 'src/state/currency/currency.atom';
import { BilberryWidgetEventsContentful, BilberryWidgetEventSimple, Product } from './events.types';
import {
    mapAccommodationToWidgetEvent,
    mapBilberryGiftcardToWidgetEvent,
    mapBilberryGiftcardUsageToWidgetEvent,
    mapBilberryPackageToWidgetEvent,
    mapBilberryProductToWidgetEvent,
    mapBilberryTimeslotToWidgetEvent,
    mapCartItemToWidgetEvent,
    mapMembershipBookingToWidgetEvent,
    mapMembershipValueCardProductToWidgetEvent,
    mapTimeslotBookingToWidgetEvent,
} from './mapper';
import { cartAtom } from 'src/state/cart/cartAtom';
import { history } from 'src/utils/domain/setup';
import {
    isBilberryPackage,
    isBilberryProduct,
    isBilberryProductCatalog,
} from 'src/types/guards/bilberry-api-type-guards';
import {
    DispatchAuthenticationEventProps,
    DispatchCartEventProps,
    DispatchGiftcardEventProps,
    DispatchInteractionEventProps,
    DispatchMembershipEventProps,
} from './eventDispatcher.types';

function dispatchCartEvent({
    eventType,
    cartItems,
    reference,
    checkoutStep,
    promoCode,
    giftCard,
}: DispatchCartEventProps) {
    const widgetEvents = cartItems.map((item) => mapCartItemToWidgetEvent(item, promoCode));

    if (giftCard) {
        widgetEvents.push(mapBilberryGiftcardUsageToWidgetEvent(giftCard));
    }

    const eventBody: Partial<BilberryWidgetEventsContentful> = {
        type: eventType,
        products: widgetEvents.flatMap((x) => x.products),
        currency: currencyAtom.subject.value.currency,
        priceIncVat: widgetEvents.reduce((acc, cur) => acc + cur.priceIncVat, 0),
        reference,
        checkoutStep,
        promoCode: widgetEvents[0]?.promoCode,
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

function dispatchInteractionEvent({
    eventType,
    product,
    productType,
}: DispatchInteractionEventProps) {
    let products: Product[];

    if (isBilberryProductCatalog(product) || isBilberryProduct(product)) {
        products = mapBilberryProductToWidgetEvent(product, productType).products;
    } else if (isBilberryPackage(product)) {
        products = mapBilberryPackageToWidgetEvent(product).products;
    } else if (product && 'productTypeId' in product) {
        products = mapMembershipValueCardProductToWidgetEvent(product).products;
    } else if (product && 'from_price' in product) {
        products = mapBilberryTimeslotToWidgetEvent(product).products;
    } else if (product && 'recipient_first_name' in product) {
        products = mapBilberryGiftcardToWidgetEvent(product).products;
    } else {
        products = mapAccommodationToWidgetEvent(product).products;
    }

    const eventBody: BilberryWidgetEventsContentful = {
        type: eventType,
        products,
        currency: currencyAtom.subject.value.currency,
        priceIncVat: products.reduce((acc, cur) => acc + cur.priceIncVat, 0),
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

export function dispatchMembershipEvent({
    purchaseProduct,
    checkoutStep,
    eventType,
    giftCard,
    promoCode,
}: DispatchMembershipEventProps) {
    let widgetEvent: Omit<BilberryWidgetEventsContentful, 'type'> | null = null;

    if (purchaseProduct && 'timeslots' in purchaseProduct) {
        widgetEvent = mapTimeslotBookingToWidgetEvent(purchaseProduct, promoCode);
    } else if (purchaseProduct && 'membership' in purchaseProduct) {
        widgetEvent = mapMembershipBookingToWidgetEvent(purchaseProduct, promoCode);
    }

    if (giftCard) {
        widgetEvent?.products.push(...mapBilberryGiftcardUsageToWidgetEvent(giftCard).products);
    }

    const eventBody: Partial<BilberryWidgetEventsContentful> = {
        type: eventType,
        products: widgetEvent?.products ?? [],
        currency: currencyAtom.subject.value.currency,
        priceIncVat: (widgetEvent?.products ?? []).reduce((acc, cur) => acc + cur.priceIncVat, 0),
        reference: purchaseProduct?.reservation?.orderReference,
        checkoutStep,
        promoCode: widgetEvent?.promoCode,
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

export function dispatchGiftCardEvent({
    giftCard,
    checkoutStep,
    eventType,
}: DispatchGiftcardEventProps) {
    let products: Product[] = [];

    if (giftCard) {
        products = mapBilberryGiftcardToWidgetEvent(giftCard).products;
    }

    const eventBody: Partial<BilberryWidgetEventsContentful> = {
        type: eventType,
        products,
        currency: currencyAtom.subject.value.currency,
        priceIncVat: products.reduce((acc, cur) => acc + cur.priceIncVat, 0),
        checkoutStep,
    };

    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });

    window.dispatchEvent(event);
}

export function dispatchAuthenticationEvent({ eventType }: DispatchAuthenticationEventProps) {
    const eventBody: BilberryWidgetEventSimple = {
        type: eventType,
    };
    const event = new CustomEvent('bilberrywidgetevent', {
        detail: eventBody,
    });
    window.dispatchEvent(event);
}

export function dispatchWidgetEvent(
    props:
        | DispatchInteractionEventProps
        | DispatchCartEventProps
        | DispatchMembershipEventProps
        | DispatchGiftcardEventProps
        | DispatchAuthenticationEventProps,
) {
    if ('cartItems' in props) {
        dispatchCartEvent(props);
    } else if ('purchaseProduct' in props) {
        dispatchMembershipEvent(props);
    } else if ('giftCard' in props) {
        dispatchGiftCardEvent(props);
    } else if (props.eventType === 'viewItem') {
        dispatchInteractionEvent(props);
    } else {
        dispatchAuthenticationEvent(props);
    }
}

// Listen for "start checkout event" for activities.
// This is the only checkout that uses navigates using /checkout route
history.listen((e) => {
    if (e.location.pathname === '/checkout') {
        dispatchWidgetEvent({
            eventType: 'startCheckout',
            cartItems: Object.values(cartAtom.subject.value),
        });
    }
});

import './membership-event-stream.ts';
