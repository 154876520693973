import { Collapse, Grid, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { Fragment, h } from 'preact';
import { memo } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import AccommodationCardList from 'src/components/domain/accommodation-card-list/AccommodationCardList';
import HotelLoadingOverlay from 'src/components/domain/hotel-loading-overlay/HotelLoadingOverlay';
import RoomsSelection from 'src/components/domain/hotel-rooms-selection/RoomsSelection';
import HotelTotalPrice from 'src/components/domain/hotel-total-price/HotelTotalPrice';
import { GuestInfo } from 'src/components/domain/MultipleRoomSearch/guestInfo';
import { useLocale } from 'src/i18n/locale';
import { BilberryAccommodation, PricedAccommodation } from 'src/types/bilberry-hotels-api-types';
import { getAvailabilityMap } from 'src/utils/domain/accommodations/accommodationUtils';
import { useAccommodations } from 'src/utils/domain/api/bilberry-hotels-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import NoAccommodationFoundOverlay from './NoAccommodationFoundOverlay';

type AccommodationListProps = {
    guestInfoSearchParameters: GuestInfo[];
    dateRange: DateRange<Dayjs>;
    selectedAccommodations: PricedAccommodation[];
    setSelectedAccommodations: (selectedAccommodations: PricedAccommodation[]) => void;
    nights: number;
    clearRoomSelection: () => void;
    newSearch: boolean;
    setNewSearch: (b: boolean) => void;
};

function AccommodationListBase({
    dateRange,
    guestInfoSearchParameters,
    selectedAccommodations,
    setSelectedAccommodations,
    nights,
    clearRoomSelection,
    newSearch,
    setNewSearch,
}: AccommodationListProps) {
    const theme = useTheme();
    const config = useConfigurations();

    const { locale } = useLocale();

    const [minDelayPassed, setMinDelayPassed] = useState(false);

    useEffect(() => {
        if (newSearch) {
            setMinDelayPassed(false);
            setTimeout(function () {
                setMinDelayPassed(true);
            }, 300);
        }
        setNewSearch(false);
    }, [newSearch]);

    const { pages, isLoading } = useAccommodations(
        dateRange,
        guestInfoSearchParameters,
        locale,
        config,
    );

    const accommodations = pages
        ? pages.map((page) =>
              page._embedded.accommodations.sort(compareAccommodationsByAvailabilityDesc),
          )
        : [];

    const availability = getAvailabilityMap(accommodations[0] ?? [], selectedAccommodations);

    const onSelectSingleAccommodation = (selectedAccommodation: PricedAccommodation) => {
        const newSelectedAccommodations = selectedAccommodations.slice(0);
        newSelectedAccommodations[0] = selectedAccommodation;
        setSelectedAccommodations(newSelectedAccommodations);
    };

    const onSelectMultipleAccommodation = (
        selectedAccommodation: PricedAccommodation,
        idx: number,
    ) => {
        const newSelectedAccommodations = selectedAccommodations.slice(0);
        newSelectedAccommodations[idx] = selectedAccommodation;
        setSelectedAccommodations(newSelectedAccommodations);
    };

    const isSomeAvailableAccommodations = accommodations?.some(
        (x) => x.length > 0 && x.some((y) => y.availableUnits > 0),
    );

    return (
        <Fragment>
            {isLoading || !minDelayPassed ? (
                <Grid container justifyContent="center">
                    <Grid item width="50%" margin={theme.spacing(4)}>
                        <HotelLoadingOverlay />
                    </Grid>
                </Grid>
            ) : (
                <Collapse in={isSomeAvailableAccommodations}>
                    {isSomeAvailableAccommodations && (
                        <Fragment>
                            {guestInfoSearchParameters.length <= 1 && (
                                <Grid container justifyContent="center">
                                    <Grid
                                        container
                                        item
                                        justifyContent="center"
                                        mb={theme.spacing(16)}
                                        width="95%"
                                    >
                                        <Grid item width="100%">
                                            {isSomeAvailableAccommodations && (
                                                <AccommodationCardList
                                                    availability={availability}
                                                    accommodationIds={accommodations[0].map(
                                                        (room) => room.id,
                                                    )}
                                                    accommodations={accommodations[0]}
                                                    guestInfo={guestInfoSearchParameters[0]}
                                                    nights={nights}
                                                    bookDirectly={true}
                                                    onSelectRoom={onSelectSingleAccommodation}
                                                    dateRange={dateRange}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Fragment>
                                {isSomeAvailableAccommodations &&
                                    guestInfoSearchParameters.length > 1 &&
                                    accommodations.length === guestInfoSearchParameters.length &&
                                    zip(guestInfoSearchParameters, accommodations).map(
                                        (room, idx) => {
                                            return (
                                                <RoomsSelection
                                                    key={idx}
                                                    adults={room.chosenRoomInfo.adults}
                                                    numberOfChildren={
                                                        room.chosenRoomInfo.children.length
                                                    }
                                                    roomNumber={idx + 1}
                                                    guestInfo={guestInfoSearchParameters[idx]}
                                                    hotelRooms={room.foundRoomsInfo}
                                                    selectedRoom={selectedAccommodations[idx]}
                                                    onSelectRoom={(selectedAccommodation) =>
                                                        onSelectMultipleAccommodation(
                                                            selectedAccommodation,
                                                            idx,
                                                        )
                                                    }
                                                    dateRange={dateRange}
                                                    nights={nights}
                                                    availability={availability}
                                                ></RoomsSelection>
                                            );
                                        },
                                    )}
                                {guestInfoSearchParameters.length > 1 &&
                                    accommodations.length > 0 && (
                                        <Grid container direction="row-reverse">
                                            <HotelTotalPrice
                                                selectedAccommodations={selectedAccommodations}
                                                guestInfoList={guestInfoSearchParameters}
                                                dateRange={dateRange}
                                                clearRoomSelections={clearRoomSelection}
                                            ></HotelTotalPrice>
                                        </Grid>
                                    )}
                            </Fragment>
                        </Fragment>
                    )}
                </Collapse>
            )}
            {!isLoading && !isSomeAvailableAccommodations && <NoAccommodationFoundOverlay />}
        </Fragment>
    );
}

export const AccommodationList = memo(AccommodationListBase);

function zip(accommodationsInfo: GuestInfo[], accommodations: BilberryAccommodation[][]) {
    return accommodationsInfo.map((accommodationInfo, idx) => {
        return {
            chosenRoomInfo: accommodationInfo,
            foundRoomsInfo: accommodations[idx],
        };
    });
}

function compareAccommodationsByAvailabilityDesc(
    a: BilberryAccommodation,
    b: BilberryAccommodation,
) {
    return a.availableUnits > b.availableUnits ? -1 : a.availableUnits === b.availableUnits ? 0 : 1;
}
