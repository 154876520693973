import dayjs from 'dayjs';
import { ProductSearch } from 'src/types/parameter-types';
import { VERSION } from 'src/__autogen/env';
import { atom } from 'ximple/atoms';
import { LocalStorageKey } from '../localstorage';

export const searchFormDataAtom = atom<ProductSearch | null>({
    persistKey: LocalStorageKey.BILBERRY_SEARCH_FORM_DATA,
    appVersion: VERSION,
    initialValue: null,
    transformOnDeserialize: (data) => {
        if (data && data.start) data.start = dayjs(data.start);
        if (data && data.end) data.end = dayjs(data.end);

        return data;
    },
});
