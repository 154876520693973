import { Box, TextField, TextFieldProps, useTheme } from '@mui/material';
import {
    DateRange,
    DateRangePickerDay,
    DateRangePickerDayProps,
    LocalizationProvider,
    StaticDateRangePicker as DateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment } from 'preact';
import { useCallback } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { Translations } from 'src/i18n/translations/types';
import { capitalize } from 'src/utils/common/TextUtils';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';

interface IProps {
    dateRange: DateRange<Dayjs>;
    onChange: (newDateRange: DateRange<Dayjs>) => void;
    date?: string;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    isDateUnavailable?: (date: Dayjs) => boolean;
    onMonthChange?: (date: Dayjs | null) => void;
    loading?: boolean;
    dateRangeVariant?: 'days' | 'nights';
}

function allDatesBetweenAreAvailable(
    day1: Dayjs,
    day2: Dayjs,
    isDateUnavailable?: (date: Dayjs) => boolean,
) {
    let day = day1.clone();
    if (!isDateUnavailable) return true;

    while (day.isBefore(day2)) {
        if (isDateUnavailable(day)) return false;
        day = day.add(1, 'days');
    }

    return true;
}

export default function StaticDateRangePicker(props: IProps): JSX.Element {
    const {
        date,
        dateRange,
        onChange,
        minDate,
        maxDate,
        isDateUnavailable,
        onMonthChange,
        loading,
        dateRangeVariant = 'days',
    } = props;

    const { t, dayjsLocale } = useLocale();
    const [{ timezone }] = useAtom(configurationAtom);

    const shouldDisableDate = useCallback(
        (date: Dayjs) => {
            if (date?.isSame(dateRange[0], 'day')) {
                return true;
            } else if (isDateUnavailable) {
                if (isDateUnavailable && (dateRange[1] !== null || dateRange[0] === null)) {
                    return isDateUnavailable(date);
                } else {
                    if (date?.isBefore(dateRange[0])) {
                        return isDateUnavailable(date);
                    } else if (dateRange[0] && date) {
                        return !allDatesBetweenAreAvailable(dateRange[0], date, isDateUnavailable);
                    }
                }
            }
            return false;
        },
        [dateRange, isDateUnavailable],
    );

    const defaultMonth = minDate && minDate.isAfter(dayjs(date)) ? minDate : dayjs(date);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={dayjsLocale}>
            <DateRangePicker
                value={dateRange}
                disablePast={true}
                autoFocus={true}
                onChange={(range: [Dayjs?, Dayjs?]) =>
                    onChange([
                        range[0]
                            ? dayjs()
                                  .tz(timezone)
                                  .set('year', range[0].year())
                                  .set('month', range[0].month())
                                  .set('date', range[0].date())
                            : null,
                        range[1]
                            ? dayjs()
                                  .tz(timezone)
                                  .set('year', range[1].year())
                                  .set('month', range[1].month())
                                  .set('date', range[1].date())
                            : null,
                    ])
                }
                minDate={minDate}
                maxDate={maxDate}
                defaultCalendarMonth={defaultMonth}
                renderDay={(date: Dayjs, dateRangePickerDayProps: DateRangePickerDayProps<Dayjs>) =>
                    getRenderDay(dateRangePickerDayProps, isDateUnavailable, date, dateRangeVariant)
                }
                renderInput={(startProps: TextFieldProps, endProps: TextFieldProps): JSX.Element =>
                    getRenderInput(startProps, endProps, t)
                }
                toolbarTitle=""
                disableHighlightToday={true}
                startText={capitalize(t.date_range_start)}
                endText={capitalize(t.date_range_end)}
                inputFormat="DD.MM.YYYY"
                mask="__.__.____"
                shouldDisableDate={shouldDisableDate}
                onMonthChange={onMonthChange}
                loading={loading}
                components={{
                    ActionBar: () => null,
                }}
            />
        </LocalizationProvider>
    );
}

function getRenderInput(startProps: TextFieldProps, endProps: TextFieldProps, t: Translations) {
    return (
        <Fragment>
            <TextField
                {...startProps}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Box sx={{ mx: 2 }}> {t.to} </Box>
            <TextField
                {...endProps}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Fragment>
    );
}

function getRenderDay(
    dateRangePickerDayProps: DateRangePickerDayProps<dayjs.Dayjs>,
    isDateUnavailable: ((date: dayjs.Dayjs) => boolean) | undefined,
    date: dayjs.Dayjs,
    dateRangeVariant: 'days' | 'nights',
) {
    if (
        dateRangePickerDayProps.selected ||
        dateRangePickerDayProps.isPreviewing ||
        dateRangePickerDayProps.outsideCurrentMonth ||
        dateRangePickerDayProps.isHighlighting || //highligted
        !isDateUnavailable || // no availabilityFunction
        (!isDateUnavailable(date) && !isDateUnavailable(date.subtract(1, 'day'))) ||
        dateRangeVariant === 'days'
    ) {
        return <DateRangePickerDay tabIndex={0} {...dateRangePickerDayProps} />;
    } else {
        return (
            <DateRangePickerDay
                tabIndex={0}
                {...dateRangePickerDayProps}
                sx={{
                    position: 'relative',
                    '&:hover, &:focus, &:active': {
                        '& .bilberry-period-unavailable-edge': {
                            background: 'unset',
                        },
                    },
                }}
            >
                {!isDateUnavailable(date.subtract(1, 'day')) ? (
                    <StartOfUnavailablePeriod />
                ) : !isDateUnavailable(date) ? (
                    <EndOfUnavailablePeriod />
                ) : (
                    <UnavailablePeriod />
                )}
                {dateRangePickerDayProps.day.date().toString()}
            </DateRangePickerDay>
        );
    }
}

function StartOfUnavailablePeriod() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                zIndex: -1,
                position: 'absolute',
                width: '107%',
                height: `calc(${theme.typography.body1.fontSize} + 6px)`,
                top: '50%',
                transform: 'translateY(-50%)',
            }}
        >
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    ml: '25%',
                    width: '50%',
                    height: '100%',
                    background: `linear-gradient(to bottom right, transparent 50%,${theme.palette.grey[100]} 50%);`,
                    '&:hover,&focus,&active': {
                        background: 'unset',
                    },
                }}
            />
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    width: '25%',
                    height: '100%',
                    background: theme.palette.grey[100],
                    '&:hover,&focus,&active': {
                        background: 'unset',
                    },
                }}
            />
        </Box>
    );
}

function EndOfUnavailablePeriod() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                zIndex: -1,
                position: 'absolute',
                width: '107%',
                height: `calc(${theme.typography.body1.fontSize} + 6px)`,
                top: '50%',
                transform: 'translateY(-50%)',
            }}
        >
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    width: '25%',
                    height: '100%',
                    background: theme.palette.grey[100],
                }}
            />
            <Box
                className="bilberry-period-unavailable-edge"
                sx={{
                    display: 'inline-block',
                    mr: '25%',
                    width: '50%',
                    height: '100%',
                    background: `linear-gradient(to top left, transparent 50%,${theme.palette.grey[100]} 50%);`,
                }}
            />
        </Box>
    );
}

function UnavailablePeriod() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                zIndex: -1,
                position: 'absolute',
                background: theme.palette.grey[100],
                width: '107%',
                height: `calc(${theme.typography.body1.fontSize} + 6px)`,
                top: '50%',
                transform: 'translateY(-50%)',
            }}
        ></Box>
    );
}
