import { h, render } from 'preact';
import { getIdAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import createWebComponentTagName from 'src/utils/domain/createWebComponentTagName';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ProductOverviewWidgetRoot from './ProductOverviewWidgetRoot';

class BilberryProductOverview extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        overrideLocaleFromAttribute(this);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductOverviewWidgetRoot productCatalogId={productCatalogId} />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        createWebComponentTagName('bilberry-product-overview', 'v1'),
        BilberryProductOverview,
    );
}
