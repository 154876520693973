import dayjs from 'dayjs';
import groupBy from 'lodash-es/groupBy';
import isObject from 'lodash-es/isObject';
import mapValues from 'lodash-es/mapValues';
import sumBy from 'lodash-es/sumBy';
import { localeAtom } from 'src/i18n/locale';
import {
    BilberryPriceQuantity,
    ICartExtra,
    ICartItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import { BilberryExtra, BilberryProduct } from 'src/types/bilberry-api-types';
import { showError } from 'src/utils/common/error-handling';
import { getBilberryProductsByIds, getExtrasByIds } from '../../api/bilberry-api-client';
import { isCartItemGuard } from '../../cart/cartUtils';
import { configurationAtom } from '../../widgetsConfiguration';
import {
    asPriceQuantities,
    assignQuantityToExtraProduct,
    MinimalProductCartItem,
    parseExtraParams,
} from './common';

const { t } = localeAtom.subject.value;

export async function deserializeActivities(params: string[]) {
    if (params.length === 0) return;

    const minimalCartItems = params.map(asMinimalProductCartItem);

    const minimalProducts = minimalCartItems.map((item) => item.product);
    const productIds = minimalProducts.map(({ productId }) => productId);

    const [productArray, extraProducts] = await Promise.all([
        getBilberryProductsByIds(
            productIds,
            localeAtom.subject.value.locale,
            configurationAtom.subject.value,
        ),
        getExtrasByIds(
            productIds,
            localeAtom.subject.value.locale,
            configurationAtom.subject.value,
        ),
    ]);

    if (!hasCapacity(productArray, minimalCartItems)) {
        showError(t.thereIsNotEnoughCapacityForTheItemsInThisCart);
        return;
    }

    const products = groupBy(productArray, 'product_catalog_id');

    const priceQuantities = mapValues<
        { [x: number]: BilberryProduct[] },
        { id: number; quantities: BilberryPriceQuantity[] }[]
    >(products, (productGroup: BilberryProduct[]) =>
        productGroup.map((product) =>
            asPriceQuantities(
                product,
                getQuantityFromMinimalProductCartItems(minimalCartItems, String(product.id)),
            ),
        ),
    );

    const extrasGroups = groupBy(extraProducts, 'tours');

    const extras: Record<string, ICartExtra[]> = mapValues(extrasGroups, (group: BilberryExtra[]) =>
        group
            .flatMap((extra) => assignQuantityToExtraProduct(minimalCartItems, extra))
            .filter(isObject),
    ) as unknown as Record<string, ICartExtra[]>;

    const cartItemList = minimalCartItems.map((item) => {
        const product = products[item.product.productCatalogId].find(
            ({ id }) => String(id) === item.product.productId,
        );

        const quantities = priceQuantities[Number(item.product.productCatalogId)].find(
            ({ id }) => String(id) === item.product.productId,
        )?.quantities;

        const currentExtras = extras[item.product.productId] ?? [];

        if (!product || !quantities) return;

        const productItem: ICartProductItem = {
            product,
            quantities,
            extras: currentExtras,
        };

        return {
            item: productItem,
        };
    });

    const cartItems = cartItemList.reduce(
        (acc, cartItem) => ({
            ...acc,
            [`product-${cartItem?.item.product.id}`]: cartItem as ICartItem,
        }),
        {} as Record<string, ICartItem>,
    );

    if (isCartItemGuard(cartItems)) {
        return cartItems;
    }
}

function getQuantityFromMinimalProductCartItems(
    items: MinimalProductCartItem[],
    productId: string,
) {
    return items.find((item) => item.product.productId === productId)?.quantities ?? [];
}

function asMinimalProductCartItem(cartItemString: string) {
    const [product, quantitiesParam, extrasParam, startDate] = cartItemString.split(';');
    const [productCatalogId, productId] = product.split(':');
    const quantities = quantitiesParam.split(',').map((quantity) => quantity.split(':'));
    const extras = parseExtraParams(extrasParam);

    return {
        product: {
            productCatalogId,
            productId,
            startDate: dayjs(decodeURIComponent(startDate)),
        },
        quantities: quantities.map(([id, quantity]) => ({
            id,
            quantity,
        })),
        extras,
    };
}

function hasCapacity(products: BilberryProduct[], items: MinimalProductCartItem[]) {
    return products.every((product) => {
        const currentItem = items.find((item) => item.product.productId === String(product.id));
        const totalQuantities = sumBy(currentItem?.quantities ?? [], ({ quantity }) =>
            Number(quantity),
        );
        return product.capacity >= totalQuantities;
    });
}
