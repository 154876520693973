import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Collapse, darken, Stack, Typography, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment, h } from 'preact';
import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import MultipleNumberInput from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import TimeSlots from 'src/components/domain/time-slot/TimeSlots';
import { useOnClickBook } from 'src/hooks/domain/activities/useOnClickBook';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from 'src/i18n/locale';
import { formatCurrencyNoDecimalsIfPossible } from 'src/i18n/Localization';
import { BilberryPriceQuantity, BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { formatDate } from 'src/utils/common/DateHelpers';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import {
    getBilberryProductMaxEntrants,
    getBilberryProductMinEntrants,
} from 'src/utils/domain/activities/bilberryProductEntrants';
import {
    getNumberOfTravelers,
    mapPriceQuantitiesToMultipleNumberInputValueType,
    onChangeQuantity,
} from 'src/utils/domain/booking/bookingHelpers';
import { calculateMultiDayPriceQuantities } from 'src/utils/domain/booking/multiDayPriceHelper';
import { getInitialQuantityData, updateQuantityData } from 'src/utils/domain/price-helper';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';
import { WarningMessage } from '../booking-base/BookingCardWarningMessage';

export function ProductCardBookingForm(props: { products: BilberryProduct[] }) {
    const { products } = props;
    const hasMultipleProducts = props.products.length > 1;
    const hasCapacity = products.some((product) => product.capacity > 0);

    const { hasChosenDate, setHasChosenDate, attemptedBooking, setAttemptedBooking, boxRef } =
        useBookingState();

    const { t, locale } = useLocale();
    const config = useConfigurations();
    const customizations = useCustomizations();
    const theme = useTheme();

    const [selectedTimeSlot, setSelectedTimeslot] = useState<
        TimeSlotType<BilberryProduct> | undefined
    >(undefined);
    const [quantities, setQuantities] = useState<BilberryPriceQuantity[]>([]);

    const [_dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const [selectedProducts, setSelectedProducts] = useState<BilberryProduct[] | undefined>(
        !hasMultipleProducts ? products : undefined,
    );
    const [productCapacityReached, setProductCapacityReached] = useState<boolean>(false);
    const [_hasChangedQuantities, setHasChangedQuantities] = useState(false);

    const productPrices = useMemo(
        () => (products.length > 0 ? products[0].prices : []),
        [products],
    );

    const [currentInteraction, setCurrentInteraction] = useState<
        'SELECT_PARTICIPANTS' | 'SELECT_TIME' | undefined
    >('SELECT_TIME');

    useEffect(() => {
        if (!hasMultipleProducts) {
            setHasChosenDate(true);
            setCurrentInteraction('SELECT_PARTICIPANTS');
            setSelectedTimeslot({
                label: formatDate(dayjs(new Date(products[0]['start'])), locale, 'HH:mm'),
                product: products[0],
            });
        }
    }, [setHasChosenDate, setCurrentInteraction, setSelectedTimeslot]);

    const pricesFromSelectedProduct = useMemo(
        () =>
            selectedProducts && selectedProducts.length > 0
                ? selectedProducts[0].prices
                : undefined,
        [selectedProducts],
    );

    useEffect(() => {
        setQuantities(updateQuantityData(pricesFromSelectedProduct, productPrices, quantities));
    }, [pricesFromSelectedProduct, productPrices]);

    const priceQuantities = useMemo(() => {
        if (selectedProducts && selectedProducts.length > 1 && quantities) {
            return calculateMultiDayPriceQuantities(
                quantities,
                selectedProducts.slice(0, products.length - 1),
            );
        }

        return quantities;
    }, [quantities, products]);

    const onClickBook = useOnClickBook(
        quantities,
        attemptedBooking,
        setAttemptedBooking,
        true,
        boxRef,
        setQuantities,
        hasChosenDate,
        config,
        selectedProducts,
        hasMultipleProducts ? setSelectedProducts : () => true,
        hasMultipleProducts ? setSelectedTimeslot : () => {},
        productPrices,
        pricesFromSelectedProduct,
        setDateRange,
    );

    const onBook = () => {
        setCurrentInteraction(hasMultipleProducts ? 'SELECT_TIME' : 'SELECT_PARTICIPANTS');
        onClickBook();
    };

    const defaultQuantities = getInitialQuantityData(productPrices, products[0]?.min_entrants);
    const { multipleNumberInputValues, defaultMultipleNumberInputValues } =
        mapPriceQuantitiesToMultipleNumberInputValueType(t, priceQuantities, defaultQuantities);

    const productCapacity = getBilberryProductMaxEntrants(selectedProducts);
    const minEntrants = getBilberryProductMinEntrants(selectedProducts, null);
    const warningLabel =
        !minEntrants || getNumberOfTravelers(priceQuantities) < minEntrants
            ? t.a_minimum_of_x_participants_is_required_to_book_this_product.parsed(
                  minEntrants?.toString() ?? '',
              )
            : undefined;

    return (
        <Stack alignItems="center" gap={2} textAlign="left" sx={{ width: '100%' }}>
            {hasCapacity ? (
                <Fragment>
                    <Box
                        sx={{
                            width: '100%',
                            '&& > *': { mx: 1, color: customizations.productCardTextColor },
                        }}
                    >
                        <InlineTimeslotTitle
                            selectedTimeSlot={selectedTimeSlot}
                            hasMultipleProducts={hasMultipleProducts}
                            onClick={() => {
                                if (hasMultipleProducts) {
                                    setCurrentInteraction('SELECT_TIME');
                                    setSelectedTimeslot(undefined);
                                    setHasChosenDate(false);
                                }
                            }}
                        />
                        <Collapse
                            easing="linear(0,1)"
                            in={currentInteraction === 'SELECT_TIME'}
                            exit={currentInteraction !== 'SELECT_TIME'}
                        >
                            <TimeSlots
                                selectedTimeSlot={selectedTimeSlot}
                                onSelectTimeSlot={(ts) => {
                                    setSelectedTimeslot(ts);
                                    setSelectedProducts([ts.product]);
                                    setHasChosenDate(true);
                                    setCurrentInteraction('SELECT_PARTICIPANTS');
                                }}
                                availabilityData={products}
                                hideHeader
                                fullWidth
                            />
                        </Collapse>
                        <Collapse
                            easing="linear(0,1)"
                            in={currentInteraction === 'SELECT_PARTICIPANTS'}
                            exit={currentInteraction !== 'SELECT_PARTICIPANTS'}
                        >
                            <MultipleNumberInput
                                values={multipleNumberInputValues}
                                defaultValues={defaultMultipleNumberInputValues}
                                onChange={(type, q) =>
                                    onChangeQuantity(
                                        type,
                                        q,
                                        setHasChangedQuantities,
                                        setQuantities as unknown as StateUpdater<
                                            BookingPriceAndQuantity[]
                                        >,
                                        productCapacity,
                                        setProductCapacityReached,
                                    )
                                }
                                message={
                                    productCapacity && productCapacityReached
                                        ? t.number_of_travelers_exceeded.parsed(productCapacity)
                                        : ''
                                }
                                labelColor={customizations.productCardTextColor}
                                backgroundColor={theme.palette.common.white}
                                color={customizations.inputFieldTextColor}
                                variant="outlined"
                                minEntrants={minEntrants ? minEntrants : 1}
                            />
                            <Box sx={{ mb: 0.575 }}></Box>
                        </Collapse>
                    </Box>
                    <WarningMessage label={warningLabel} />
                </Fragment>
            ) : (
                <Box sx={{ py: 6.8 }}></Box>
            )}
            <InlineSummary
                onBook={onBook}
                quantities={quantities}
                fromPrice={products[0].from_price}
                hasCapacity={hasCapacity}
                bookingDisabled={
                    !hasChosenDate ||
                    !selectedProducts ||
                    quantities.every(({ quantity }) => quantity === 0)
                }
            />
        </Stack>
    );
}

type InlineSummaryProps = {
    onBook: () => void;
    quantities: BilberryPriceQuantity[];
    fromPrice: number | null;
    bookingDisabled?: boolean;
    hasCapacity?: boolean;
};
function InlineSummary({
    onBook,
    quantities,
    fromPrice,
    bookingDisabled,
    hasCapacity,
}: InlineSummaryProps) {
    const customizations = useCustomizations();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        mt: 1,
                        px: 1,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {hasCapacity ? (
                        <Fragment>
                            <Typography
                                sx={{
                                    flexGrow: 0,
                                    flexShrink: 1,
                                    minWidth: 'fit-content',
                                    color: customizations.productCardTextColor,
                                    fontSize: '0.9rem',
                                }}
                            >
                                {quantities.some(({ quantity }) => quantity > 0)
                                    ? t.total
                                    : fromPrice !== null
                                    ? t.from
                                    : ''}
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    borderBottom: '1px solid #00000044',
                                    flexGrow: 1,
                                    mx: 1,
                                    flexShrink: 1,
                                }}
                            ></Box>
                            <Typography
                                sx={{
                                    flexGrow: 0,
                                    flexShrink: 1,
                                    minWidth: 'fit-content',
                                    color: customizations.productCardTextColor,
                                    fontWeight: 'bold',
                                }}
                            >
                                {quantities.some(({ quantity }) => quantity > 0)
                                    ? formatCurrencyNoDecimalsIfPossible(
                                          locale,
                                          currency,
                                          quantities.reduce(
                                              (acc, { price, quantity }) => acc + price * quantity,
                                              0,
                                          ),
                                      )
                                    : fromPrice
                                    ? formatCurrencyNoDecimalsIfPossible(
                                          locale,
                                          currency,
                                          fromPrice,
                                      )
                                    : ''}
                            </Typography>
                        </Fragment>
                    ) : (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: '0.9rem' }}>
                                {t.no_available_capacity_for_this_tour}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Button
                disabled={bookingDisabled}
                onClick={onBook}
                sx={(theme) => ({
                    px: 3,
                    mt: 2,
                    flexShrink: 0,
                    borderRadius: theme.shape,
                    color: customizations.productCardPrimaryColorContrast,
                    backgroundColor: customizations.productCardPrimaryColor,
                    borderColor: customizations.productCardPrimaryColor,
                    '&:disabled': {
                        color: customizations.productCardPrimaryColorContrast,
                        backgroundColor: customizations.productCardPrimaryColor,
                        borderColor: customizations.productCardPrimaryColor,
                        filter: 'grayscale(0.9)',
                        opacity: 0.75,
                    },
                    '&:hover': {
                        color: darken(customizations.productCardPrimaryColorContrast, 0.1),
                        backgroundColor: darken(customizations.productCardPrimaryColor, 0.1),
                        borderColor: darken(customizations.productCardPrimaryColor, 0.1),
                    },
                })}
            >
                {t.book_now}
            </Button>
        </Box>
    );
}

type InlineTimeslotTitleProps = {
    selectedTimeSlot: TimeSlotType<BilberryProduct> | undefined;
    hasMultipleProducts: boolean;
    onClick: () => void;
};

function InlineTimeslotTitle({
    selectedTimeSlot,
    hasMultipleProducts,
    onClick,
}: InlineTimeslotTitleProps) {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    return (
        <Typography
            sx={{
                cursor: 'pointer',
                fontSize: '0.9rem',
                mb: 1.5,
                borderBottom: '1px solid #00000044',
            }}
            onClick={onClick}
        >
            {selectedTimeSlot ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '4px' }}>
                        {hasMultipleProducts ? t.selected_time : t.time}:
                    </span>
                    <span>{` ${formatDate(
                        dayjs(selectedTimeSlot?.product.start),
                        locale,
                        'HH:mm',
                    )} - ${formatDate(
                        dayjs(selectedTimeSlot?.product.end),
                        locale,
                        'HH:mm',
                    )}`}</span>
                    {hasMultipleProducts && (
                        <EditIcon
                            sx={{
                                ml: 'auto',
                                fontSize: '1rem',
                                color: customizations.productCardTextColor,
                            }}
                        />
                    )}
                </Box>
            ) : (
                <span style={{ fontWeight: 'bold' }}>{t.select_time}</span>
            )}
        </Typography>
    );
}
