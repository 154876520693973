export default function ItineraryHotelIcon(props: { id: string }) {
    return (
        <svg
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <circle cx="52" cy="52" r="50" stroke="black" strokeWidth="3" fill="white" />
            <rect x="14" y="26" width="75" height="46" fill={`url(#hotelpattern${props.id})`} />
            <defs>
                <pattern
                    id={`hotelpattern${props.id}`}
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use
                        xlinkHref="#image0_134_4686"
                        transform="matrix(0.01 0 0 0.016129 0 -0.612903)"
                    />
                </pattern>
                <image
                    id="image0_134_4686"
                    width="100"
                    height="100"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFT0lEQVR4nO2cTW8VVRjH/2ngsrCYYBTxFdxINYortWAMlETdgUrDJ1CioQs/QQMsdKmVj0C7AqMLXWsrQRZYtBolXVB1qY0vVEM1acc84blxeDwz95yZM3Pn3vn/kpPc3M4zMz2/Oe9nLjB4PALgDQBzABYBrAL4R9Oqfjenx+zp980OKyMAjgP4HMAmgMQzybELACb1HCQCEwC+DZCQlb4BcJBGirMNwNnAEuFTYmYAdCgmjLsAXMrJ2C8BTAM4BGAMwB2axrRETesxWfEXAeygFH8ZSxlP93kAewMyUgRdyChlS3ot0qOauuTIvOsA9pfIuQMAVjJKCquvHM46Mm0ewN0RHuN7tMdlzy9tCnEw4aha5iM/wR2HFLkme1+GEUfX9rpnydgC4EVN8tmnpKw42hOOU1Icdzy1+z1lzKfiPvOU8pyj6jrmEdcabDVy3jPuJUfGSknx4QMTJzKJzk2l247NgK5tGSGPOUrlbhq5NQloB32+bNEnuxv7qWeV1eWqufaJgNihZc5kynRgfLdRfyFQhnDKXPtcYPxQsmgy5VCN1z5srn2lxms3llWTKY8Gxp8wKYS95to/B8YPJX+bTBkNjLeNegijJnY9ML4VQrbXKOROColfZSUlhLDK8mjUJ2oUwka9gm5vUkIIu70eA8PFGoV8bWJfC4wfSvY4pk7GahDyuInbAPBwgftvxeTihRqEfGTiZNqFKJOOib4DFQp53hH3Km38x4jum0pSaUUXk2KzE8AP5lpfcYHq/xx0LOEuRF7C3aa7H21plBJDHMw4qpKFSCVlp0OGpHdpIv8JvujItBVddi2KlIAfM2RzG1DBjXKJLrvKSl9I1/bDjHPJGIS7Fz3ZkVFSuumqjrIP65hlVNOYfnfKsRpoSwZlBNLRNiX2ZmtpM1hNlex9LUWQIV1b9qYijlOO6YaGkBKzoTEy6ONGuIrYrUu153QNfFUXuST9ot/J317n3BQhhBBCCCGEhCPrDmd058efmuTz6UgvXpIAXgHwR840xO8AXg45ISknY8NzbohSaqim8kqGTb+x+qqWMybD/wIwBeBeTVP6XfoYWQAiFWFX1kSAZarkdk8SwJrJbCkVrh0b6WNuhFyAhGHbiLLHkZJQSMOgkIZBIQ2DQlogZKu+YjAL4HudE1vTz7P6NznGh452uy/reZKGJ7nHLwCcLLr/K7aQowCWPW58WY/N437HS6GDlOR3W+7rlxDZ9/RO4A3LHqu3M/ZMdQZcRjddCagNogoJlZFOIqXX7MAgpzdRs5Cjjh2FN3Uf7TOp39WVz+/p39LHSuwRc87L5piPATyA5vMggE/MvcsvrdYmZKujzZB3MZ7IOdeTAH4yMcumaNspHflHB4WHykw1lRViX9i82UNGWsq6iZ0scF9Nxd7/W1rqf+21EltWyGyJV8Rmcn5ALBkyId4rsWWFXDPfPx1w08+a2GsF7quphDT6t63ElhVyo8TvXW3PqWuTFgm5bSWWQqohKboSG7vKkq6tL+MtqrKmfFdiYzfqMs7w5f0WNeq7HMfsclXZVXR7pUvbi6da1u31Pq6qgWGelH0eA8OEQooJyZo6WddxxnjqPfJxrabWPaZOEgopLqSKycWEQsoJGdGM3Yw0/Z5QSDkhXY4ELFDZaioNhUQS4lrCXdP0nXZtfZZwE5aQeEJikFAIhQz0OIQJQXlAIWjWQ0Mh6L8ECkGLhJBqoJCGQSENg0IaBoU0DAoZFiH97jYmA54oBP2XQCHof8ZTCPqf2Y0QQvKhkIZBIQ2DQoZVSNlGityicL5SSDVQSMOgEDSLQkL+BWN84ctnYbQpAAAAAElFTkSuQmCC"
                />
            </defs>
        </svg>
    );
}
