import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductGallery from './gallery/ProductGallery';

export default function ProductGalleryWidgetRoot(props: { productCatalogId: number }) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(props.productCatalogId, locale, config);

    return <ProductGallery product={productCatalog} />;
}
