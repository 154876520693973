import { Grid, useTheme } from '@mui/material';
import { h } from 'preact';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { ProductGalleryAlign } from 'src/widgets/activities/product/product-gallery-2/gallery/ProductGallery2';
import ProductGallery2WidgetRoot from 'src/widgets/activities/product/product-gallery-2/ProductGallery2WidgetRoot';
import ProductOverview2WidgetRoot from 'src/widgets/activities/product/product-overview-2/ProductOverview2WidgetRoot';

export default function ProductPage2(props: { productCatalogId: number }) {
    const theme = useTheme();
    const numVisibleImages: MediaQueryAttributeInput = {
        numXs: 1,
        numSm: 2,
        numMd: 3,
        numLg: 3,
    };

    const overviewPadding = {
        paddingBottom: theme.spacing(12),
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(6),
        },
    };
    return (
        <Grid container>
            <Grid item sm={12} sx={overviewPadding}>
                <ProductOverview2WidgetRoot productCatalogId={props.productCatalogId} />
            </Grid>
            <Grid item sm={12} sx={overviewPadding}>
                <ProductGallery2WidgetRoot
                    productCatalogId={props.productCatalogId}
                    numVisibleImages={numVisibleImages}
                    align={ProductGalleryAlign.SCALE}
                />
            </Grid>
        </Grid>
    );
}
