import { Box, Button, Grid, Link, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { capitalize } from 'src/utils/common/TextUtils';

export default function GiftcardReceipt(props: {
    bookingRef?: string;
    shouldSendToRecipient: boolean;
    buyerEmail?: string;
    receiverEmail?: string;
}): JSX.Element {
    const { t } = useLocale();
    const linkUrl = '/';
    const theme = useTheme();

    return (
        <Stack
            mt={theme.spacing(2)}
            pt={0}
            minWidth="300px"
            width="100%"
            sx={{
                color: theme.palette.secondary.main,
                [theme.breakpoints.down('md')]: {
                    maxWidth: '440px',
                },
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
        >
            <Paper
                sx={{
                    fontWeight: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '100%',
                    marginBottom: theme.spacing(2),
                    '& > *': {
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        fontWeight: 300,
                    },
                }}
                variant="outlined"
            >
                <Grid container alignItems="center">
                    {props.bookingRef && (
                        <Fragment>
                            <Typography>
                                {capitalize(t.your_giftcard_reference_is)}&nbsp;
                            </Typography>
                            <Typography variant="h5">{props.bookingRef}</Typography>
                        </Fragment>
                    )}
                    <Box mt={theme.spacing(2)}>
                        <Typography>
                            {props.shouldSendToRecipient
                                ? capitalize(t.giftcard_voucher)
                                : capitalize(t.giftcard_voucher_and_receipt)}
                        </Typography>
                        <Typography color="textSecondary">
                            <Box fontWeight="bold">{props.receiverEmail}</Box>
                        </Typography>
                    </Box>
                    {props.shouldSendToRecipient && (
                        <Box mt={theme.spacing(2)}>
                            {' '}
                            <Typography>{capitalize(t.giftcard_and_receipt)}</Typography>
                            <Typography color="textSecondary">
                                <Box fontWeight="bold">{props.buyerEmail}</Box>
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Paper>
            <Link href={linkUrl} className="no-print">
                <Button variant="outlined" color="primary">
                    {t.see_more_activities}
                </Button>
            </Link>
        </Stack>
    );
}
