import { atom } from 'ximple/atoms';
import { reduceCartState } from 'src/state/cart/cart.reducer';
import { CartEvents, ICartItem } from './ICart';
import { LocalStorageKey } from '../localstorage';
import { VERSION } from 'src/__autogen/env';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';

export const cartAtom = atom<{ [key: string]: ICartItem }, CartEvents>({
    initialValue: {},
    persistKey: LocalStorageKey.BILBERRY_CART,
    appVersion: VERSION,
    update: updateCartAtom,
});

function updateCartAtom(state: { [key: number]: ICartItem }, action: CartEvents) {
    const result = reduceCartState(state, action);

    switch (action.type) {
        case 'ADD':
            dispatchWidgetEvent({ eventType: 'addToCart', cartItems: [action.data] });
            break;
        case 'REMOVE':
            dispatchWidgetEvent({
                eventType: 'removeFromCart',
                cartItems: [state[action.data as unknown as number]],
            });
            break;
    }

    return result;
}
