import { Box, Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { ICartAccommodationItem } from 'src/state/cart/ICart';

interface IProps {
    cartAccommodationItem: ICartAccommodationItem;
    invertedColorsClass?: Object;
}

export default function SummaryAccommodationInformation(props: IProps): JSX.Element {
    const { locale, t } = useLocale();
    const theme = useTheme();
    const { price } = props.cartAccommodationItem;

    return (
        <Box margin={theme.spacing(0.5, 0, 2, 0)}>
            <Grid
                key={`summary-activity-info-${price.id}`}
                container
                direction="row"
                justifyContent="space-between"
                wrap="nowrap"
            >
                <Typography align="left" color="textSecondary" sx={props.invertedColorsClass}>
                    {/* TODO: Add information about number of guests (adults/children) for each accommodation in the summary (andre) */}
                    {price.name}
                </Typography>
                <Typography align="right" color="textSecondary" sx={props.invertedColorsClass}>
                    {getLocaleNumberFormatTwoDecimals(locale, price.value)}
                </Typography>
            </Grid>
        </Box>
    );
}
