import { BilberryProduct, BilberryProductCatalog } from 'src/types/bilberry-api-types';

export function getBilberryProductMaxEntrants(selectedProducts: BilberryProduct[] | undefined) {
    return selectedProducts
        ? selectedProducts.length === 1
            ? selectedProducts[0].capacity
            : Math.min(
                  ...selectedProducts.slice(0, selectedProducts.length - 1).map((p) => p.capacity),
              )
        : undefined;
}

export function getBilberryProductMinEntrants(
    selectedProducts: BilberryProduct[] | undefined,
    productCatalog: BilberryProductCatalog | null,
) {
    const defaultValue = productCatalog?.min_entrants ?? 1;
    if (!selectedProducts) return defaultValue;
    else if (selectedProducts.length === 1) return selectedProducts[0].min_entrants ?? defaultValue;
    else {
        const multipleDayEntrants = Math.max(
            ...selectedProducts
                .slice(0, selectedProducts.length - 1)
                .map((p) => p.min_entrants ?? 0),
        );
        if (multipleDayEntrants != 0) return multipleDayEntrants;
        else return defaultValue;
    }
}
