import {
    CalendarPicker,
    CalendarPickerSkeleton,
    LocalizationProvider,
    PickersDay,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useLocale } from 'src/i18n/locale';

interface IProps {
    date: Dayjs | null;
    onChange: (newDate: Dayjs | null) => void;
    onMonthChange?: (date: Dayjs | null) => void;
    onYearChange?: (date: Dayjs | null) => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    shouldDisableDate?: (date: Dayjs | null) => boolean;
    loading?: boolean;
}

export default function BilberryStaticCalendar(props: IProps): JSX.Element {
    const {
        date,
        onChange,
        onMonthChange,
        onYearChange,
        minDate,
        maxDate,
        shouldDisableDate,
        loading,
    } = props;

    const { dayjsLocale } = useLocale();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={dayjsLocale}>
            <CalendarPicker
                autoFocus={true}
                date={date}
                views={['day']}
                onChange={onChange}
                onMonthChange={onMonthChange}
                onYearChange={onYearChange}
                minDate={minDate}
                maxDate={maxDate}
                renderDay={(_day: Date, _selectedDates: Date[], pickersDayProps: any) => {
                    return <PickersDay tabIndex={0} {...pickersDayProps} />;
                }}
                allowSameDateSelection={true}
                disableHighlightToday={true}
                shouldDisableDate={shouldDisableDate}
                loading={loading}
                renderLoading={() => <CalendarPickerSkeleton />}
            />
        </LocalizationProvider>
    );
}
