import { Grid, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency } from 'src/i18n/Localization';
import { Translations } from 'src/i18n/translations/types';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { formatMinutesToDaysHoursMinutes } from 'src/utils/common/DateHelpers';
import { capitalize } from 'src/utils/common/TextUtils';
import { getAgeString, getDifficultyText } from 'src/utils/domain/mapToTranslations';
import { useAtom } from 'ximple';

interface IProps {
    product: BilberryProductCatalog | null;
}

export default function KeyInformationGrid(props: IProps): JSX.Element {
    const { locale, t } = useLocale();
    const { product } = props;
    const theme = useTheme();

    const keyInformationList = getKeyInformationList(
        product,
        {
            marginBottom: theme.spacing(0.5),
            '&:last-child': {
                marginBottom: 0,
            },
        },
        t,
        locale,
    );

    if (keyInformationList.length === 0) {
        return <Grid />;
    }

    const leftSideInfo = keyInformationList.filter((_item, i) => i % 2 === 0);
    const rightSideInfo = keyInformationList.filter((_item, i) => i % 2 !== 0);
    const rowIndexes = Array(Math.ceil(keyInformationList.length / 2))
        .fill(0)
        .map((_value, index) => index);

    return (
        <Grid container>
            {rowIndexes.map((key, index) => (
                <Grid
                    container
                    direction="row"
                    key={key}
                    mb={theme.spacing(2)}
                    sx={{
                        '&:last-child': {
                            marginBottom: 0,
                        },
                    }}
                >
                    <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        pr={theme.spacing(2)}
                        sx={{
                            '&:last-child': {
                                paddingRight: 0,
                            },
                        }}
                    >
                        {leftSideInfo[index]}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        pr={theme.spacing(2)}
                        sx={{
                            '&:last-child': {
                                paddingRight: 0,
                            },
                        }}
                    >
                        {index <= rightSideInfo.length && rightSideInfo[index]}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

function getKeyInformationList(
    product: BilberryProductCatalog | null,
    sx: any,
    t: Translations,
    locale: string,
) {
    const [currency] = useAtom(currencyAtom);
    const keyInformationList = [];

    if (product?.default_prices)
        keyInformationList.push(
            <KeyInformationComponent
                sx={sx}
                heading={capitalize(t.price)}
                body={product.default_prices.map((price) => (
                    <Typography key={price.id} sx={sx}>
                        {capitalize(price.name)}
                        {getAgeString(t, price)}: {formatCurrency(locale, currency, price.price)}
                    </Typography>
                ))}
            />,
        );
    if (product?.duration)
        keyInformationList.push(
            <KeyInformationComponent
                sx={sx}
                heading={capitalize(t.duration)}
                body={<Typography>{getFormattedDuration(t, product.duration)}</Typography>}
            />,
        );
    if (product?.web_times)
        keyInformationList.push(
            <KeyInformationComponent
                sx={sx}
                heading={capitalize(t.starting_time)}
                body={product.web_times.split('\n').map((time) => (
                    <Typography sx={sx} key={`${product.id}-${time}`}>
                        {time}
                    </Typography>
                ))}
            />,
        );
    if (product?.difficulty)
        keyInformationList.push(
            <KeyInformationComponent
                sx={sx}
                heading={capitalize(t.difficulty)}
                body={<Typography>{getDifficultyText(t, product)}</Typography>}
            />,
        );
    if (product?.location?.city)
        keyInformationList.push(
            <KeyInformationComponent
                sx={sx}
                heading={capitalize(t.departure)}
                body={<Typography>{product?.location.city}</Typography>}
            />,
        );

    return keyInformationList;
}

function getFormattedDuration(t: Translations, minutes: number | null) {
    if (!minutes) return '-';

    return formatMinutesToDaysHoursMinutes(t, minutes);
}

function KeyInformationComponent(props: {
    sx: any;
    heading: string;
    body: JSX.Element | JSX.Element[];
}) {
    const { sx, heading, body } = props;

    return (
        <Fragment>
            <Typography sx={sx} variant="h6">
                {heading}
            </Typography>
            {body}
        </Fragment>
    );
}
