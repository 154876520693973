import { BilberryPriceQuantity } from 'src/state/cart/ICart';
import {
    BilberryPackage,
    BilberryPackageProduct,
    BilberryProduct,
} from 'src/types/bilberry-api-types';

type PackageProductTicketOptionMap = {
    ticket_option_id: number;
    products: BilberryPackageProduct[];
};

export function packageProductsFromTicketOptions(pkg: BilberryPackage) {
    return pkg.ticket_options.map(({ ticket_option_id, products }) => ({
        ticket_option_id,
        products,
    }));
}

export function getPackageProductIds(packageProducts: PackageProductTicketOptionMap[]) {
    return packageProducts.flatMap(({ products }) => products.map(({ product_id }) => product_id));
}

export function hasPackageProductsCapacity(
    packageProducts: PackageProductTicketOptionMap[],
    bilberryProducts: BilberryProduct[],
    quantities: BilberryPriceQuantity[],
) {
    const bilberryProductArray = bilberryProducts.flatMap((bp) => Object.values(bp)).flat();

    return packageProducts.every(({ ticket_option_id, products }) => {
        const validForTicketOption = bilberryProductArray.filter((p) => {
            return products.some(({ product_id }) => product_id === p.product_catalog_id);
        });

        return validForTicketOption.every(
            (p) => p.capacity > (quantities.find((q) => q.id === ticket_option_id)?.quantity ?? 0),
        );
    });
}
