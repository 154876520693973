function createUrlObject(url: string) {
    try {
        return new URL(url);
    } catch (error) {
        return new URL(url, window.location.href); //Try handling the url as a relative path
    }
}

export function findUrlQueryParam(name: string): string | null {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(name);
}

export function appendQueryParam(url: string, name: string, value?: string): string {
    if (!value) return url;

    const urlObject = createUrlObject(url);
    urlObject.searchParams.set(name, value);
    return urlObject.href;
}
