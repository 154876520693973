import { Box } from '@mui/material';
import IconRow from './IconRow';

type ProductType = 'hotel' | 'activity' | 'transfer';

type ItineraryDayProps = {
    items: {
        id: string;
        index: number;
        title: string;
        productType: ProductType;
        subtitle: string;
        category: string;
    }[];
};

export default function ItineraryDay(props: ItineraryDayProps) {
    const { items } = props;

    return (
        <Box>
            {items.map((item, i) => (
                <IconRow shouldDisplayLine={i !== items.length - 1} {...item} />
            ))}
        </Box>
    );
}
