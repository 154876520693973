import { Checkbox, FormControlLabel, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useLocale } from 'src/i18n/locale';
import {
    ActivityCheckoutInfo,
    ContactPerson,
    MultiDayCheckoutInfo,
    PackageCheckoutInfo,
    ProductQuestion,
} from 'src/types/checkout-info';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import {
    findContactPersonValue,
    getUpdatedTravelerQuestions,
} from 'src/utils/domain/checkout-info-helper';
import BilberryQuestionInput from './BilberryQuestionInput';

interface IProps {
    onChange(checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo): void;
    travelerKey: string;
    showErrors: boolean;
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo;
    contactPerson: ContactPerson | null;
}

export default function TravelerQuestionnaire(props: IProps): JSX.Element {
    const { t } = useLocale();
    const { onChange, travelerKey, checkoutInfo, contactPerson } = props;
    const customizations = useCustomizations();
    const theme = useTheme();
    const travelerIndex = parseInt(travelerKey);
    const travelerQuestions = checkoutInfo.travelersQuestions[travelerKey];
    const isFirstTraveler = travelerIndex === 0;

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                backgroundColor: 'white',
                width: '100%',
                marginTop: theme.spacing(1),
            }}
            variant="outlined"
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography color="textSecondary" variant="h6" mt={0}>
                    {capitalize(t.traveler)} {travelerIndex + 1}
                </Typography>
                {isFirstTraveler && !customizations.disableCopyFromContactPerson && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkoutInfo.copyFromContactPerson}
                                onChange={(_event, copyFromContactPerson) =>
                                    copyFromContactPersonOnChange(
                                        copyFromContactPerson,
                                        isFirstTraveler,
                                        checkoutInfo,
                                        travelerKey,
                                        contactPerson,
                                        onChange,
                                    )
                                }
                            />
                        }
                        labelPlacement="start"
                        label={
                            (
                                <Typography color="textSecondary" variant="body1">
                                    {capitalize(t.same_as_main_contact)}
                                </Typography>
                            ) as JSX.Element
                        }
                        color="secondary"
                    />
                )}
            </Grid>

            {Object.entries(travelerQuestions).map(([key, question]) => {
                const contactPersonValue = findContactPersonValue(contactPerson, question.key);
                const disabled =
                    isFirstTraveler &&
                    checkoutInfo.copyFromContactPerson &&
                    contactPersonValue !== null;
                return (
                    <BilberryQuestionInput
                        key={key}
                        onChange={(question) =>
                            travelerQuestionOnChange(
                                question,
                                travelerQuestions,
                                checkoutInfo,
                                travelerKey,
                                onChange,
                            )
                        }
                        showErrors={props.showErrors}
                        question={question}
                        disabled={disabled}
                    />
                );
            })}
        </Paper>
    );
}

function copyFromContactPersonOnChange(
    copyFromContactPerson: boolean,
    isFirstTraveler: boolean,
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    travelerKey: string,
    contactPerson: ContactPerson | null,
    onChange: (
        activityCheckoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    ) => void,
) {
    const shouldCopyFromContactPerson = isFirstTraveler && copyFromContactPerson;
    const updatedTravelerQuestions = getUpdatedTravelerQuestions(
        checkoutInfo.travelersQuestions[travelerKey],
        contactPerson,
        shouldCopyFromContactPerson,
    );
    const updated: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo = {
        ...checkoutInfo,
        copyFromContactPerson,
        travelersQuestions: {
            ...checkoutInfo.travelersQuestions,
            [travelerKey]: updatedTravelerQuestions,
        },
    };

    onChange(updated);
}

function travelerQuestionOnChange(
    question: ProductQuestion,
    travelerQuestions: {
        [key: string]: ProductQuestion;
    },
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    travelerKey: string,
    onChange: (
        activityCheckoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    ) => void,
) {
    const updatedTravelerQuestions = {
        ...travelerQuestions,
        [question.id]: question,
    };

    const updated: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo = {
        ...checkoutInfo,
        travelersQuestions: {
            ...checkoutInfo.travelersQuestions,
            [travelerKey]: updatedTravelerQuestions,
        },
    };

    onChange(updated);
}
