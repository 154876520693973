import { Translations } from 'src/i18n/translations/types';
import { BilberryProductCatalog, BilberryProductPrice } from 'src/types/bilberry-api-types';
import { capitalize } from 'src/utils/common/TextUtils';

const difficulty = {
    EASY: 'easy',
    MEDIUM: 'medium',
    HARD: 'hard',
};

export function getDifficultyText(
    t: Translations,
    productCatalog?: Pick<BilberryProductCatalog, 'difficulty'>,
) {
    return capitalize(
        productCatalog?.difficulty === difficulty.EASY
            ? t.easy
            : productCatalog?.difficulty === difficulty.MEDIUM
            ? t.medium
            : productCatalog?.difficulty === difficulty.HARD
            ? t.hard
            : '',
    );
}

export function getAgeString(t: Translations, price: BilberryProductPrice) {
    if (price.age_from === null && price.age_to === null) {
        return '';
    } else if (price.age_to === null) {
        return ` (${price.age_from}+ ${t.years})`;
    } else if (price.age_from === null) {
        return ` (0-${price.age_to} ${t.years})`;
    } else {
        return ` (${price.age_from}-${price.age_to} ${t.years})`;
    }
}
