import { CheckoutInfoData } from 'src/types/checkout-info';
import { loadDataFromLocalStorage, LocalStorageKey, updateWidgetsMeta } from '../localstorage';

export function storeCheckoutInfoState(checkoutInfo: CheckoutInfoData | null) {
    localStorage.setItem(LocalStorageKey.BILBERRY_CHECKOUT_INFO, JSON.stringify(checkoutInfo));
    updateWidgetsMeta();
}

export function loadCheckoutInfoState() {
    return loadDataFromLocalStorage(
        LocalStorageKey.BILBERRY_CHECKOUT_INFO,
    ) as CheckoutInfoData | null;
}
