import { bookingActionLog$, bookingAtom } from 'src/state/booking';
import { bookingCheckoutTabStateActionLog$ } from 'src/state/bookingCheckoutTab';
import { uiStateActionLog$ } from 'src/state/ui-timeslots';
import { valueCardActionLog$, valueCardAtom } from 'src/state/valueCard';
import { dispatchWidgetEvent } from './eventDispatcher';

bookingActionLog$.subscribe(({ state, newState, action }) => {
    switch (action.type) {
        case 'INITIALIZE':
            dispatchWidgetEvent({
                eventType: 'startCheckout',
                purchaseProduct: newState,
            });
            break;
        case 'CANCEL':
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Cancel',
                purchaseProduct: state,
            });
    }
});

valueCardActionLog$.subscribe(({ state, newState, action }) => {
    switch (action.type) {
        case 'INITIALIZE':
            dispatchWidgetEvent({
                eventType: 'startCheckout',
                purchaseProduct: newState,
            });
            break;
        case 'CANCEL':
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Cancel',
                purchaseProduct: state,
            });
    }
});

uiStateActionLog$.subscribe(({ state, action }) => {
    switch (action.type) {
        case 'SET_PAYMENT_IS_SUCCSESSFUL':
            if (state.payment.isSuccessful) break;

            const targetAtom = !!valueCardAtom?.subject.value.reservation
                ? valueCardAtom
                : bookingAtom;

            const appliedGiftCard = bookingAtom.subject.value.appliedGiftcard;
            dispatchWidgetEvent({
                eventType: 'book',
                purchaseProduct: targetAtom.subject.value,
                promoCode: bookingAtom.subject.value.appliedPromoCode?.promoCode,
                giftCard:
                    appliedGiftCard && (appliedGiftCard.priceReduction ?? 0) > 0
                        ? {
                              giftcardReference: appliedGiftCard.giftcardStatus.id,
                              priceReduction: appliedGiftCard.priceReduction,
                          }
                        : null,
                reference: targetAtom.subject.value.reservation?.orderReference,
            });
            break;
    }
});

bookingCheckoutTabStateActionLog$.subscribe(({ action }) => {
    switch (action.type) {
        case 'INITIALIZE':
            dispatchWidgetEvent({
                eventType: 'startCheckout',
                purchaseProduct: bookingAtom.subject.value,
            });
            break;

        case 'GOTO_BOOKING_INTENT':
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Intent View',
                purchaseProduct: bookingAtom.subject.value,
                promoCode: bookingAtom.subject.value.appliedPromoCode?.promoCode,
                giftCard: bookingAtom.subject.value.appliedGiftcard
                    ? {
                          giftcardReference:
                              bookingAtom.subject.value.appliedGiftcard?.giftcardStatus.id,
                          priceReduction: bookingAtom.subject.value.appliedGiftcard?.priceReduction,
                      }
                    : undefined,
            });
            break;
        case 'GOTO_BOOKING_CONTACT_INFO':
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Contact Info',
                purchaseProduct: bookingAtom.subject.value,
                promoCode: bookingAtom.subject.value.appliedPromoCode?.promoCode,
                giftCard: bookingAtom.subject.value.appliedGiftcard
                    ? {
                          giftcardReference:
                              bookingAtom.subject.value.appliedGiftcard?.giftcardStatus.id,
                          priceReduction: bookingAtom.subject.value.appliedGiftcard?.priceReduction,
                      }
                    : undefined,
            });
            break;
        case 'GOTO_BOOKING_PAYMENT':
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Payment',
                purchaseProduct: bookingAtom.subject.value,
                promoCode: bookingAtom.subject.value.appliedPromoCode?.promoCode,
                giftCard: bookingAtom.subject.value.appliedGiftcard
                    ? {
                          giftcardReference:
                              bookingAtom.subject.value.appliedGiftcard?.giftcardStatus.id,
                          priceReduction: bookingAtom.subject.value.appliedGiftcard?.priceReduction,
                      }
                    : undefined,
            });
            break;
    }
});
