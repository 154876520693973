import dayjs, { Dayjs } from 'dayjs';
import { groupBy, mapValues, omit } from 'lodash-es';
import { useLocale } from 'src/i18n/locale';
import {
    BilberryPackage,
    BilberryProduct,
    PackageProduct,
    PackageProductMetadata,
} from 'src/types/bilberry-api-types';
import {
    useAvailableProductsFromPackageProductMetadata,
    usePackage,
} from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import TourSelectionContainer from './subcomponents/TourSelectionContainer';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { ICartPackageItem } from 'src/state/cart/ICart';
import { cartAtom } from 'src/state/cart/cartAtom';
import { createAddPackageToCartEvent } from 'src/state/cart/cart.reducer';
import { useCallback } from 'react';

export default function TourSelection({ item }: { item: ICartPackageItem }) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { pkg } = usePackage(item.pkg.id, locale, config);

    const productMetadata = useMemo(
        () => asProductMetadata(pkg, dayjs(item.start)),
        [pkg, item.start],
    );

    const { availabilities } = useAvailableProductsFromPackageProductMetadata(
        productMetadata,
        locale,
        config,
    );

    const packageProductsWithAvailability = useMemo(
        () => assignProductData(productMetadata, availabilities ?? []),
        [productMetadata, availabilities],
    );

    const [selectedAvailabilities, setSelectedAvailabilities] = useState(
        item.selectedProducts.length > 0
            ? item.selectedProducts.reduce(
                  (acc, cur) => {
                      if (!acc[cur.ticketOptionId]) acc[cur.ticketOptionId] = {};
                      acc[cur.ticketOptionId][cur.product.id] = {
                          ...cur.product,
                          ticketOptionId: cur.ticketOptionId,
                          priceCategoryId: cur.priceCategoryId,
                          packageProductId: cur.packageProductId,
                      };
                      return acc;
                  },
                  {} as Record<
                      string,
                      Record<
                          string,
                          BilberryProduct & {
                              ticketOptionId: number;
                              priceCategoryId: number;
                              packageProductId: number;
                          }
                      >
                  >,
              )
            : {},
    );

    const onSelectAvailabilities = useCallback(
        (
            selected: Record<
                string,
                Record<
                    string,
                    BilberryProduct & {
                        ticketOptionId: number;
                        priceCategoryId: number;
                        packageProductId: number;
                    }
                >
            >,
        ) => {
            setSelectedAvailabilities(selected);
            const data = Object.values(Object.values(selected)).flatMap((products) =>
                Object.values(products).map((p) => ({
                    product: p,
                    ticketOptionId: p.ticketOptionId,
                    priceCategoryId: p.priceCategoryId,
                    packageProductId: Number(p.packageProductId),
                    extras: [],
                })),
            );
            cartAtom.update(
                createAddPackageToCartEvent(
                    item.pkg,
                    item.vats,
                    item.quantities,
                    data,
                    item.start,
                    item.end,
                ),
            );
        },
        [item],
    );

    useEffect(() => {
        if (Object.values(selectedAvailabilities).some((x) => Object.keys(x).length > 0)) return;
        const products = getFirstAvailableProducts(packageProductsWithAvailability);

        const mappedValues = mapValues(products, (x) =>
            x.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur.id]: cur,
                }),
                {} as Record<
                    string,
                    BilberryProduct & {
                        ticketOptionId: number;
                        priceCategoryId: number;
                        packageProductId: number;
                    }
                >,
            ),
        );
        onSelectAvailabilities(mappedValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packageProductsWithAvailability]);

    return (
        <TourSelectionContainer
            packageProducts={packageProductsWithAvailability}
            selectedAvailabilities={selectedAvailabilities}
            setSelectedAvailabilities={onSelectAvailabilities}
        />
    );
}

function assignProductData(
    packageProducts: PackageProductMetadata[],
    availabilities: { [date: string]: BilberryProduct[] }[],
): PackageProduct[] {
    const flattenedAvailabilities = availabilities.flatMap((product) =>
        Object.values(product).flat(),
    );

    return packageProducts.map((item) => {
        const itemAvailabilities = flattenedAvailabilities
            .filter(
                (product) =>
                    product.product_catalog_id.toString() === item.id &&
                    dayjs(product.start).isAfter(item.start) &&
                    dayjs(product.start).isBefore(item.end),
            )
            .map((x) => ({
                ...x,
                ticketOptionId: item.ticketOptionId,
                priceCategoryId: item.priceCategoryId,
                packageProductId: item.id,
            }));

        return {
            ...item,
            imageSrc: itemAvailabilities.length > 0 ? itemAvailabilities[0].media.image.url : '',
            description: itemAvailabilities.length > 0 ? item.subtitle : '',
            availabilities: itemAvailabilities,
            isAccommodation: item.isAccommodation,
        };
    });
}

function getFirstAvailableProducts(products: PackageProduct[]): Record<
    string,
    (BilberryProduct & {
        ticketOptionId: number;
        priceCategoryId: number;
        packageProductId: number;
    })[]
> {
    const packageProductsByTicketOptionId = groupBy(products, (p) => p.ticketOptionId);
    const sorted = mapValues(packageProductsByTicketOptionId, (p) =>
        p
            .flatMap(({ availabilities, ticketOptionId, priceCategoryId, id }) =>
                availabilities.map((a) => ({
                    ...a,
                    ticketOptionId,
                    priceCategoryId,
                    packageProductId: Number(id),
                })),
            )
            .sort((a, b) =>
                dayjs(a.start).isSame(dayjs(b.start))
                    ? 0
                    : dayjs(a.start).isBefore(dayjs(b.start))
                    ? -1
                    : 1,
            ),
    );

    const prods = mapValues(packageProductsByTicketOptionId, (p, key) => {
        const usedProducts: number[] = [];
        if (Object.values(sorted).some((x) => x.length === 0)) return [];
        return p.map((x) => {
            const first = sorted[key].findIndex(
                (y) =>
                    String(y.product_catalog_id) === String(x.id) && !usedProducts.includes(y.id),
            );
            usedProducts.push(sorted[key][first]!.id);
            return sorted[key][first]!;
        });
    });

    return prods;
}

function asProductMetadata(pkg: BilberryPackage | undefined, date: Dayjs) {
    if (!pkg) return [];

    const packageProducts = pkg.ticket_options.flatMap((option) =>
        option.products.map((p) => ({ ...omit(option, 'products'), ...p })),
    );

    return packageProducts.map((p, i) => ({
        id: p.product_id.toString(),
        ticketOptionId: p.ticket_option_id,
        priceCategoryId: p.price_category_id,
        index: i,
        start: date.add(p.minutes_relative_start, 'minutes'),
        end: date.add(p.minutes_relative_end, 'minutes'),
        title: p.product_name,
        subtitle: p.name,
        isAccommodation: p.is_accommodation === 1,
    }));
}
