import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { h } from 'preact';
import ProductCard2, {
    ActivityProductCardInfo,
} from 'src/components/domain/product-card-2/ActivityProductCard';
import { useLocale } from 'src/i18n/locale';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';

interface IProps {
    productCatalog: BilberryProductCatalog;
    productCatalogUrl?: string;
}

export default function ProductCardWide(props: IProps): JSX.Element {
    const { t } = useLocale();
    const { productCatalog, productCatalogUrl } = props;
    const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const isPrivateTour = false; // TODO: not yet implemented in Bilberry
    const customizations = useCustomizations();
    const theme = useTheme();

    return isSmScreen ? (
        <ProductCard2
            productCatalog={productCatalog}
            url={productCatalogUrl ?? productCatalog?.web_url}
            disableMaxWidth={true}
        />
    ) : (
        <Box
            overflow="hidden"
            position="relative"
            boxShadow={customizations.imageShadow ? theme.shadows[3] : undefined}
            width="100%"
            height="auto"
        >
            <Box display="block" position="relative">
                <ProductImage
                    url={productCatalog?.media?.image?.url}
                    size="l"
                    disableShadows={true}
                    format="16_9"
                />
                {isPrivateTour && (
                    <Grid
                        padding="0 64px"
                        justifyContent="center"
                        position="absolute"
                        top={0}
                        right={0}
                        sx={{
                            transform: 'rotateZ(45deg) translateX(64px)',
                            background: theme.palette.grey[800],
                            '& > .MuiTypography-root': {
                                color: theme.palette.common.white,
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <Typography>{t.private_tour}</Typography>
                    </Grid>
                )}
            </Box>
            {productCatalog?.web_category && (
                <Box
                    position="absolute"
                    top={theme.spacing(2)}
                    left={0}
                    padding={theme.spacing(0.5, 2, 0.5, 2.5)}
                    sx={{
                        backgroundColor: customizations.productCardPrimaryColor,
                        '& > .MuiTypography-root': {
                            color: customizations.productCardPrimaryColorContrast,
                            fontWeight: customizations.boldFontWeight,
                        },
                    }}
                >
                    <Typography>{productCatalog?.web_category}</Typography>
                </Box>
            )}
            <Box
                position="absolute"
                bottom={0}
                left={theme.spacing(16)}
                maxWidth="436px"
                sx={{
                    '& > div': {
                        borderTopLeftRadius: customizations.borderRadius,
                        borderTopRightRadius: customizations.borderRadius,
                    },
                }}
            >
                <ActivityProductCardInfo
                    product={productCatalog}
                    url={productCatalogUrl ?? productCatalog?.web_url}
                />
            </Box>
        </Box>
    );
}
