import { Box, capitalize, Grid, Paper, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { cartAtom } from 'src/state/cart/cartAtom';
import { ICartItem } from 'src/state/cart/ICart';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { AppliedGiftCard } from 'src/types/giftcards';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import {
    isICartAccommodationItemType,
    isICartPackageItemType,
} from 'src/utils/domain/cart/cartUtils';
import { calculateActivityPromoCodePriceReduction } from 'src/utils/domain/cart/priceReductions';
import { getPriceSummaryFromCartItems } from 'src/utils/domain/price-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { ShareCartButton } from 'src/widgets/activities/share-cart-button/ShareCartButton';
import { SharePanel } from 'src/widgets/activities/share-cart-button/SharePanel';
import { useAtom } from 'ximple/atoms';
import SummaryBooking from './SummaryBooking';
import SummaryGiftCardPayment from './SummaryGiftCardPayment';
import SummaryPayment from './SummaryPayment';
import SummaryPromoCode from './SummaryPromoCode';
import SummaryVat from './SummaryVat';

interface IProps {
    goPayClicked?: () => void;
    isMakingReservation?: boolean;
    goToCheckout?: () => void;
    isCheckoutSummary: boolean;
    invertedColors?: boolean;
    heading?: string;
    nextButtonText?: string;
    onCloseModal?: () => void;
    isConfirmationPage?: boolean;
    cartItems?: ICartItem[];
    hideGoPay: boolean;
    appliedGiftcard?: AppliedGiftCard | null;
    onGiftcardApplied?: (appliedGiftcard: AppliedGiftCard | null) => void;
    appliedPromoCode?: BilberryPromoCodeStatus | null;
    onPromoCodeApplied?: (appliedPromoCode: BilberryPromoCodeStatus | null) => void;
    onError?: (message: string) => void;
    showShare?: boolean;
}

export default function Summary(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const configurations = useConfigurations();
    const [cartItemsFromStore] = useAtom(cartAtom);

    const cartItems = props.cartItems ?? Object.values(cartItemsFromStore);

    const priceSummary = getPriceSummaryFromCartItems(cartItems);
    const invertedColorsClass = props.invertedColors
        ? {
              backgroundColor: customizations.bookingWidgetColor,
              color: customizations.bookingWidgetColorContrast,
              '& .MuiIconButton-root': {
                  color: customizations.secondaryColorContrast,
              },
              '& .MuiSvgIcon-root': {
                  color: customizations.secondaryColorContrast,
              },
          }
        : {};
    const primaryColorsClass = props.invertedColors
        ? { color: customizations.bookingWidgetPrimaryColor }
        : {};
    const priceReductionGiftCard = props.appliedGiftcard ? props.appliedGiftcard.priceReduction : 0;
    const priceReductionPromoCode = props.appliedPromoCode
        ? calculateActivityPromoCodePriceReduction(cartItems, props.appliedPromoCode)
        : 0;
    const totalPriceWithPromoCodeReduction = Math.max(
        0,
        priceSummary.totalPrice - priceReductionPromoCode,
    );
    const totalPriceWithPriceReduction = Math.max(
        0,
        priceSummary.totalPrice - priceReductionGiftCard - priceReductionPromoCode,
    );

    const isPromoCodeEnabled =
        !!configurations.enablePromoCodes &&
        props.appliedPromoCode !== undefined &&
        props.onPromoCodeApplied !== undefined &&
        props.onError !== undefined;

    const displayPromoCodeValue =
        !!configurations.enablePromoCodes &&
        !!props.appliedPromoCode &&
        props.onPromoCodeApplied === undefined &&
        props.onError === undefined;

    const isGiftcardEnabled =
        !!configurations.enableGiftcards &&
        props.appliedGiftcard !== undefined &&
        props.onGiftcardApplied !== undefined &&
        props.onError !== undefined;

    const displayGiftcardValue =
        !!configurations.enableGiftcards &&
        !!props.appliedGiftcard &&
        props.onGiftcardApplied === undefined &&
        props.onError === undefined;

    const hasAccommodations = useMemo(
        () => cartItems.filter((x) => isICartAccommodationItemType(x.item)).length > 0,
        [cartItems],
    );
    const hasPackage = useMemo(
        () => cartItems.filter((x) => isICartPackageItemType(x.item)).length > 0,
        [cartItems],
    );

    const [shareDialogVisible, setShareDialogVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState('');

    return (
        <Grid
            component={Paper}
            mt={theme.spacing(2)}
            p={theme.spacing(3, 3, 2, 3)}
            maxWidth="400px"
            width="100%"
            sx={{
                backgroundColor: theme.palette.grey[50],
                color: theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    maxWidth: '440px',
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                },
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
                ...invertedColorsClass,
            }}
            variant="outlined"
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'auto auto',
                    gridTemplateColumns: '1fr 1fr',
                    gap: theme.spacing(1),
                    pb: theme.spacing(2),
                }}
            >
                <Typography
                    color="textSecondary"
                    variant="h4"
                    fontWeight={500}
                    fontSize={28}
                    sx={{ ...invertedColorsClass, ...primaryColorsClass, flexGrow: 1 }}
                >
                    {props.heading ?? t.summary}
                </Typography>
                {props.showShare && (
                    <ShareCartButton
                        hasInvertedColors={props.invertedColors}
                        setShareUrl={setShareUrl}
                        shareDialogVisible={shareDialogVisible}
                        setShareDialogVisible={setShareDialogVisible}
                        sx={{
                            mr: props.invertedColors ? theme.spacing(4) : 0,
                            justifySelf: 'end',
                        }}
                    />
                )}
                {props.showShare && (
                    <SharePanel
                        url={shareUrl}
                        visible={shareDialogVisible}
                        invertedColorsClass={invertedColorsClass}
                        sx={{ gridRow: 2, gridColumn: '1 / 3' }}
                    />
                )}
            </Box>
            <SummaryBooking
                invertedColorsClass={invertedColorsClass}
                cartItems={cartItems}
                isCheckoutSummary={props.isCheckoutSummary}
                isConfirmationPage={props.isConfirmationPage}
            />
            {!hasAccommodations && (
                <SummaryVat
                    priceSummary={priceSummary}
                    invertedColorsClass={invertedColorsClass}
                    hasPackage={hasPackage}
                />
            )}
            {isGiftcardEnabled && (
                <SummaryGiftCardPayment
                    totalPrice={totalPriceWithPromoCodeReduction}
                    appliedGiftcard={props.appliedGiftcard!}
                    onGiftcardApplied={props.onGiftcardApplied!}
                    onError={props.onError!}
                />
            )}
            {isPromoCodeEnabled && (
                <SummaryPromoCode
                    onError={props.onError!}
                    totalPrice={priceSummary.totalPrice}
                    cartItems={cartItems}
                    onPromoCodeApplied={props.onPromoCodeApplied}
                    appliedPromoCode={props.appliedPromoCode}
                />
            )}
            {displayGiftcardValue && (
                <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        wrap="nowrap"
                        flex="3"
                        flexShrink="1"
                    >
                        <Typography color="secondary" align="right">
                            {capitalize(t.gift_card)}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        wrap="nowrap"
                        flex="1"
                        flexGrow="1"
                    >
                        <Typography color="secondary" align="right">
                            -
                            {getLocaleNumberFormatTwoDecimals(
                                locale,
                                props.appliedGiftcard!.priceReduction,
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {displayPromoCodeValue && (
                <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        wrap="nowrap"
                        flex="3"
                        flexShrink="1"
                    >
                        <Typography color="secondary" align="right">
                            {props.appliedPromoCode?.coupon_code}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        wrap="nowrap"
                        flex="1"
                        flexGrow="1"
                    >
                        <Typography color="secondary" align="right">
                            -{getLocaleNumberFormatTwoDecimals(locale, priceReductionPromoCode)}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <SummaryPayment
                invertedColorsClass={invertedColorsClass}
                hideGoPay={props.hideGoPay}
                goPayClicked={props.goPayClicked}
                isMakingReservation={props.isMakingReservation}
                goToCheckout={props.goToCheckout}
                totalPrice={totalPriceWithPriceReduction}
                onCloseModal={props.onCloseModal}
                isConfirmationPage={props.isConfirmationPage}
                enableGoPay={cartItems.length > 0}
                nextButtonText={props.nextButtonText}
            />
        </Grid>
    );
}
