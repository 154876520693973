enum LogLevel {
    NONE = 0,
    ERROR = 1,
    INFO = 2,
    DEBUG = 3,
}

let logLevel = LogLevel.NONE;
if (window.location.hostname === 'localhost') {
    logLevel = LogLevel.DEBUG;
}

const storedLogLevel = localStorage.getItem('BilberryLogLevel');
if (storedLogLevel) {
    logLevel = parseInt(storedLogLevel);
}

export function debugLog(msg: string, ...args: any[]) {
    if (logLevel >= LogLevel.DEBUG) {
        console.log(msg, ...args);
    }
}

export function infoLog(msg: string, ...args: any[]) {
    if (logLevel >= LogLevel.INFO) {
        console.log(msg, ...args);
    }
}

export function errorLog(msg: string, ...args: any[]) {
    if (logLevel >= LogLevel.ERROR) {
        console.error(msg, ...args);
    }
}
