import { h, render } from 'preact';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import createWebComponentTagName from 'src/utils/domain/createWebComponentTagName';
import ProductCardList from 'src/widgets/activities/product/product-list/product-card-list/ProductCardList';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryProduct extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogIds = this.getAttribute('product-catalog-ids')!
            .toString()
            .split(',')
            .map((num) => Number(num));
        const productCatalogUrls = this.getAttribute('product-catalog-urls')!.toString().split(',');

        const textCustomizationAttributeName = 'button-text-customization-keys';
        const hasTextCustomizationKeysAttribute = this.hasAttribute(textCustomizationAttributeName);

        const productCatalogButtonCustomizationKeys = hasTextCustomizationKeysAttribute
            ? (this.getAttribute(textCustomizationAttributeName)!
                  .toString()
                  .split(',') as (keyof UserTextCustomizations)[])
            : undefined;
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');

        overrideLocaleFromAttribute(this);

        const numXs = this.hasAttribute('num-xs')
            ? parseInt(this.getAttribute('num-xs')!)
            : undefined;
        const numSm = this.hasAttribute('num-sm')
            ? parseInt(this.getAttribute('num-sm')!)
            : undefined;
        const numMd = this.hasAttribute('num-md')
            ? parseInt(this.getAttribute('num-md')!)
            : undefined;
        const numLg = this.hasAttribute('num-lg')
            ? parseInt(this.getAttribute('num-lg')!)
            : undefined;

        const numXsOrDefault = !numXs || numXs <= 0 ? 1 : numXs;
        const numSmOrDefault = !numSm || numSm <= 0 ? 2 : numSm;
        const numMdOrDefault = !numMd || numMd <= 0 ? 2 : numMd;
        const numLgOrDefault = !numLg || numLg <= 0 ? 2 : numLg;

        const numElements = {
            numXs: numXsOrDefault,
            numSm: numSmOrDefault,
            numMd: numMdOrDefault,
            numLg: numLgOrDefault,
        };

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductCardList
                    productCatalogIds={productCatalogIds}
                    productCatalogUrls={productCatalogUrls}
                    productCatalogButtonCustomizationKeys={productCatalogButtonCustomizationKeys}
                    scroll={scroll}
                    numElements={numElements}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        createWebComponentTagName('bilberry-product-list', 'v1'),
        BilberryProduct,
    );
}
