import sumBy from 'lodash-es/sumBy';
import { PackagePriceQuantity } from 'src/state/cart/ICart';
import { BilberryPackageAvailabilityVat } from 'src/types/bilberry-api-types';

function getTotalPackageAmount(amountType: 'price' | 'vat_amount') {
    return function (
        quantities: PackagePriceQuantity[],
        prices: { [id: number]: BilberryPackageAvailabilityVat[] },
    ) {
        return sumBy(quantities, (q) => sumBy(prices[q.id], (p) => p[amountType]) * q.quantity);
    };
}

export function getPackagePriceSummary(
    quantities: PackagePriceQuantity[],
    prices: { [id: number]: BilberryPackageAvailabilityVat[] },
) {
    return quantities.map((q) => {
        const price = prices[q.id];
        return {
            id: q.id,
            quantity: q.quantity,
            price: sumBy(price, (p) => p.price),
            vat_amount: sumBy(price, (p) => p.vat_amount),
        };
    });
}

export const getTotalPackageVatAmount = getTotalPackageAmount('vat_amount');
export const getTotalPackagePrice = getTotalPackageAmount('price');
