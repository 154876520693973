import AddRounded from '@mui/icons-material/AddRounded';
import RemoveRounded from '@mui/icons-material/RemoveRounded';
import { Box, Grid, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { darken, Theme } from '@mui/material/styles';
import { TargetedEvent } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';

interface IProps {
    value: number;
    maxValue: number;
    minValue?: number;
    name: string;
    subText?: string;
    onChange(value: number): void;
    maxReached?: boolean;
    colors?: 'primary' | 'secondary' | 'standard';
    disabled?: boolean;
}

function getColors(theme: Theme, colors?: 'primary' | 'secondary' | 'standard', state?: 'hover') {
    switch (colors) {
        case 'secondary':
            return {
                color: theme.palette.secondary.contrastText,
                backgroundColor:
                    state === 'hover'
                        ? darken(theme.palette.secondary.main, 0.2)
                        : theme.palette.secondary.main,
            };
        case 'standard':
            return {
                color: theme.palette.primary.main,
                backgroundColor:
                    state === 'hover'
                        ? darken(theme.palette.common.white, 0.2)
                        : theme.palette.common.white,
            };
        case 'primary':
        default:
            return {
                color: theme.palette.primary.contrastText,
                backgroundColor:
                    state === 'hover'
                        ? darken(theme.palette.primary.main, 0.2)
                        : theme.palette.primary.main,
            };
    }
}

export default function QuantityPicker(props: IProps): JSX.Element {
    const { value, maxValue, minValue = 0, onChange, name, subText, maxReached, disabled } = props;
    const theme = useTheme();
    const [rawValue, setRawValue] = useState(`${value} ${name}`);

    const onIncreaseQuantity = () => {
        if (maxValue) value < maxValue && onChange(value + 1);
        else onChange(value + 1);
    };
    const onDecreaseQuantity = () => {
        value > 0 && onChange(value - 1);
    };

    useEffect(() => {
        setRawValue(`${value} ${name}`);
    }, [value, name]);

    return (
        <Grid justifyContent="space-between" container direction="row" wrap="nowrap">
            <PlusMinusButton
                colors={props.colors}
                onChangeQuantity={onDecreaseQuantity}
                variant="minus"
                disabled={disabled || value === 0 || value === minValue}
            />
            <Stack sx={{ width: '100%', justifyContent: 'center', direction: 'column', m: 0 }}>
                <Box>
                    <TextField
                        disabled={disabled}
                        label={name}
                        sx={{
                            width: '100%',
                            m: 0,
                            fontSize: theme.typography.body1.fontSize,
                            '& .MuiInputBase-root': {
                                backgroundColor: 'transparent !important',
                                borderRadius: 0,
                                border: 'none',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    borderBottom: `2px dotted ${theme.palette.grey[500]}`,
                                },
                                '&.Mui-focused': {
                                    backgroundColor: '#fff !important',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                display: 'none',
                                '&.Mui-focused': {
                                    display: 'block',
                                },
                            },
                            '& .Mui-focused': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    borderRadius: `${theme.shape.borderRadius}px`,
                                },
                            },
                            borderRadius: 0,
                            '& input': {
                                border: 'none',
                                textAlign: 'center',
                                p: 1,
                                '&:hover, &:focus': {
                                    border: 'none',
                                },
                            },
                            boxShadow: 'none !important',
                        }}
                        type="phone"
                        value={rawValue}
                        onBlur={(
                            e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                        ) => {
                            const newValue = Number(e.currentTarget.value.split(' ')[0]);
                            if (!isNaN(newValue) && newValue > minValue && newValue < maxValue) {
                                setRawValue(`${newValue} ${name}`);
                                onChange(newValue);
                            } else {
                                setRawValue(`${value} ${name}`);
                            }
                        }}
                        onChange={(
                            e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                        ) => setRawValue(e.currentTarget.value)}
                        name={name}
                        margin="dense"
                        onFocus={(e: TargetedEvent<HTMLInputElement>) => {
                            setRawValue(String(value));
                            e.currentTarget.select();
                        }}
                    />
                </Box>
                {subText && (
                    <Typography fontSize={14} align="center">
                        {subText}
                    </Typography>
                )}
            </Stack>
            <PlusMinusButton
                colors={props.colors}
                onChangeQuantity={onIncreaseQuantity}
                variant="plus"
                disabled={disabled || maxReached || value === maxValue}
            />
        </Grid>
    );
}

function PlusMinusButton(props: {
    colors: any;
    onChangeQuantity: any;
    disabled: boolean;
    variant: 'minus' | 'plus';
}) {
    const { colors, onChangeQuantity, disabled, variant } = props;
    const theme = useTheme();
    const iconStyles = {
        width: '1.5rem',
        height: '1.5rem',
        fontSize: '1.5rem',
        borderRadius: `${theme.shape.borderRadius}px`,
        padding: theme.spacing(1),
        ...getColors(theme, colors),
        '&:hover': {
            '@media (hover: hover)': {
                ...getColors(theme, colors, 'hover'),
            },
        },
    };
    return (
        <IconButton
            color={'primary'}
            aria-label={`decrease-${name}`}
            disabled={disabled}
            onClick={onChangeQuantity}
            sx={{
                padding: theme.spacing(0.5),
                fontSize: 0,
                '&:disabled *': {
                    filter: 'opacity(0.5)',
                },
            }}
        >
            {variant === 'minus' ? (
                <RemoveRounded sx={iconStyles} />
            ) : (
                <AddRounded sx={iconStyles} />
            )}
        </IconButton>
    );
}
