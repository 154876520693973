import { createAuth0Client, Auth0Client, User } from '@auth0/auth0-spa-js';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';

import { atom } from 'ximple';

export type Authentication = {
    isAuthenticated: boolean;
    token: string | null;
    user?: User;
};

export const auth0Atom = atom<Auth0Client | null>({ initialValue: null });

export const isAuthenticatedAtom = atom<Authentication>({
    initialValue: { isAuthenticated: false, token: null },
});

export const previousPathAtom = atom<string | undefined>({
    initialValue: undefined,
    persistKey: 'no.bilberry-timeslots.previous-path',
});

function redirectToPreviousPathIfPresent() {
    const previousPath = previousPathAtom.subject.value;

    if (previousPath) {
        previousPathAtom.update(undefined);
        window.location.href = previousPath;
    }
}

export const configureAuth0 = async () => {
    const { auth0Domain, auth0ClientId, auth0Audience } = configurationAtom.subject.value;
    const auth0Client = await createAuth0Client({
        domain: auth0Domain ?? '',
        clientId: auth0ClientId ?? '',
        authorizationParams: {
            audience: auth0Audience,
        },
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
    });

    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
        await auth0Client.handleRedirectCallback();
        redirectToPreviousPathIfPresent();
        window.history.replaceState({}, document.title, '/');
    }

    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
        try {
            const token = await auth0Client.getTokenSilently();
            const user = await auth0Client.getUser();
            isAuthenticatedAtom.update({ isAuthenticated: isAuthenticated, token, user });
        } catch (e) {
            isAuthenticatedAtom.update({
                isAuthenticated: false,
                token: null,
                user: undefined,
            });
        }
        redirectToPreviousPathIfPresent();
    } else {
        redirectToPreviousPathIfPresent();
        isAuthenticatedAtom.update({
            isAuthenticated: isAuthenticated,
            token: null,
            user: undefined,
        });
    }

    auth0Atom.update(auth0Client);
};

// Login / logout events
isAuthenticatedAtom.subject.subscribe((isAuthenticated) => {
    if (isAuthenticated.isAuthenticated) {
        dispatchWidgetEvent({
            eventType: 'login',
        });
    }
});
