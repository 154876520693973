import { errorLog } from 'src/utils/common/Logger';

export function assertHasAttribute(
    elem: Element,
    attribute: string,
    legacyAttribute: string | null = null,
) {
    if (legacyAttribute && elem.hasAttribute(legacyAttribute)) return;
    if (!elem.hasAttribute(attribute))
        throw new Error(`Element <${elem.localName}> requires the attribute "${attribute}"`);
}

export function getStringAttribute(elem: Element, attributeName: string, optional: boolean = true) {
    if (!optional) assertHasAttribute(elem, attributeName);
    return elem.getAttribute(attributeName);
}

export function getIdAttribute(elem: Element, optional: boolean = true, attribute = 'id') {
    if (!optional) assertHasAttribute(elem, attribute);
    return parseInt((elem.getAttribute(attribute) ?? '0').toString());
}

export function getYesAttribute(elem: Element, attribute: string) {
    return elem.getAttribute(attribute)?.toLowerCase() === 'yes';
}

export function getCsvAttribute(
    elem: Element,
    attr: string,
    optional: boolean = true,
    valueValidator: (s: string) => boolean = () => true,
    legacyAttribute: string | null = null,
) {
    if (!optional) assertHasAttribute(elem, attr, legacyAttribute);
    const attrValue = elem.hasAttribute(attr)
        ? elem.getAttribute(attr)
        : elem.getAttribute(legacyAttribute ?? attr);
    if (!attrValue) return [];
    const values = attrValue.split(',');
    const invalidValues = values.filter((x) => !valueValidator(x));
    if (invalidValues.length > 0) {
        const issues = invalidValues.map((x) => `"${x}"`).join(',');
        errorLog(
            `Invalid values [${issues}] found in element <${elem.localName} ${attr}="${attrValue}">.`,
        );
    }
    return values;
}
