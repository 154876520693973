import { zIndex } from 'src/utils/common/theme/Theme';

export function instantiateShadowRoot(elem: HTMLElement) {
    const shadowRoot = elem.attachShadow({ mode: 'open' });
    const mountPoint = document.createElement('div');
    mountPoint.className = 'bilberry-widget-root';
    shadowRoot.append(mountPoint);

    const popoverMountPointRoot = document.createElement('div');
    popoverMountPointRoot.className = 'bilberry-widget-popover-root';
    const popoverMountPoint = shadowRoot
        .appendChild(popoverMountPointRoot)
        .appendChild(document.createElement('div'));
    popoverMountPoint.style.position = 'fixed';
    popoverMountPoint.style.top = '0';
    popoverMountPoint.style.width = '0px';
    popoverMountPoint.style.height = '0px';
    popoverMountPoint.style.zIndex = (zIndex.alwaysVisible + 1).toString();

    return { mountPoint, popoverMountPoint, shadowRoot };
}
