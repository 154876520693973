import { Paper, Typography, useTheme } from '@mui/material';
import { Fragment } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { AccommodationCheckoutInfo, ProductQuestion } from 'src/types/checkout-info';
import BilberryQuestionInput from './BilberryQuestionInput';

type Props = {
    accommodationsCheckoutInfo: AccommodationCheckoutInfo[];
    showErrors: boolean;
    onChange(accommodationsCheckoutInfo: AccommodationCheckoutInfo[]): void;
};

export function CheckoutInfoForAccommodations(props: Props): JSX.Element {
    const { accommodationsCheckoutInfo } = props;
    const { t } = useLocale();

    const onChange = (i: number, info: AccommodationCheckoutInfo) => {
        props.onChange([
            ...accommodationsCheckoutInfo.slice(0, i),
            info,
            ...accommodationsCheckoutInfo.slice(i + 1),
        ]);
    };

    return (
        <Fragment>
            <Header
                title={t.notes_regarding_your_stay}
                numberOfTravelers={accommodationsCheckoutInfo.length}
            />
            {accommodationsCheckoutInfo.length > 0 &&
                accommodationsCheckoutInfo.map((info, i) => (
                    <AccommodationQuestionnaire
                        key={i}
                        accommodationsCheckoutInfo={info}
                        showErrors={props.showErrors}
                        onChange={(info) => onChange(i, info)}
                    />
                ))}
        </Fragment>
    );
}

type AccommodationQuestionnaireProps = {
    onChange(accommodationsCheckoutInfo: AccommodationCheckoutInfo): void;
    showErrors: boolean;
    accommodationsCheckoutInfo: AccommodationCheckoutInfo;
};

export function AccommodationQuestionnaire(props: AccommodationQuestionnaireProps): JSX.Element {
    const { onChange, accommodationsCheckoutInfo } = props;
    const { t } = useLocale();
    const theme = useTheme();

    function accommodationQuestionOnChange(question: ProductQuestion) {
        const updated: AccommodationCheckoutInfo = {
            ...accommodationsCheckoutInfo,
            accommodationQuestions: {
                ...accommodationsCheckoutInfo.accommodationQuestions,
                notes: question,
            },
        };

        onChange(updated);
    }

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                backgroundColor: 'white',
                width: '100%',
            }}
            variant="outlined"
        >
            {Object.values(accommodationsCheckoutInfo.accommodationQuestions).map((question) => (
                <Fragment>
                    <Typography color="textSecondary" variant="h6" mt={0}>
                        {t.notes_for_accommodation_name_with_guests.parsed(
                            accommodationsCheckoutInfo.cartItem.accommodation.name,
                            accommodationsCheckoutInfo.cartItem.numberOfGuests,
                        )}
                    </Typography>
                    <BilberryQuestionInput
                        key={question.key}
                        onChange={accommodationQuestionOnChange}
                        showErrors={props.showErrors}
                        question={question}
                    />
                </Fragment>
            ))}
        </Paper>
    );
}

type HeaderProps = {
    title: string;
    numberOfTravelers: number;
};

function Header(props: HeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                backgroundColor: theme.palette.grey[50],
                marginTop: theme.spacing(3),
                marginBottom: 0,
            }}
            variant="outlined"
        >
            <Typography color="textSecondary" variant="h6">
                {props.title}
            </Typography>
        </Paper>
    );
}
