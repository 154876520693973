import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import toInteger from 'lodash-es/toInteger';
import { Fragment, h, render } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useAccommodation } from 'src/utils/domain/api/bilberry-hotels-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import AccommodationProductOverview from './AccommodationProductOverview';

const firstDay = BilberryWidgetsGlobal.openingDate
    ? dayjs(BilberryWidgetsGlobal.openingDate)
    : dayjs();
const initialDateRange: DateRange<Dayjs> = [firstDay, firstDay.add(1, 'days')];

export function AccommodationProductOverviewWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    id: number;
}): JSX.Element {
    const config = useConfigurations();
    const { locale } = useLocale();
    const { accommodation, isLoading } = useAccommodation(
        props.id.toString(),
        initialDateRange,
        1,
        locale,
        config,
    );

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            {isLoading || !accommodation ? (
                <Fragment />
            ) : (
                <AccommodationProductOverview
                    accommodation={accommodation}
                    dateRange={initialDateRange}
                />
            )}
        </WidgetWrapper>
    );
}

class BilberryAccommodationProductOverview extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        render(
            <AccommodationProductOverviewWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                id={toInteger(this.id)}
            />,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        'bilberry-accommodation-product-overview',
        BilberryAccommodationProductOverview,
    );
}
