import dayjs, { Dayjs } from 'dayjs';
import { getDayjsLocale } from 'src/i18n/locale';
import { Translations } from 'src/i18n/translations/types';
import { configurationAtom } from '../domain/widgetsConfiguration';

export function getSimpleDateString(selectedDate: Date, locale: string) {
    return formatDate(dayjs(selectedDate), locale, 'YYYY-MM-DD');
}

export function formatDate(date: Dayjs, locale: string, fmt: string) {
    const dayJsLocale = getDayjsLocale(locale);
    return date.tz(configurationAtom.subject.value.timezone).locale(dayJsLocale).format(fmt);
}

export function formatTime(date: Dayjs) {
    return date.tz(configurationAtom.subject.value.timezone).format('HH:mm');
}

/**
 * Formats a provided number of minutes into a duration using days, hours and minutes.
 *
 * @param t - Translations
 * @param minutes - Number of minutes
 */
export function formatMinutesToDaysHoursMinutes(t: Translations, minutes: number) {
    const hours = Math.floor(minutes / 60);
    const minutesRest = minutes % 60;

    const days = Math.floor(hours / 24);
    const hoursRest = hours % 24;

    const dayString = days > 0 ? `${days}${t.dayShort}` : ``;
    const hourString = hoursRest > 0 ? `${hoursRest}${t.hourShort}` : ``;
    const minuteString = minutesRest > 0 ? `${minutesRest}${t.minuteShort}` : ``;

    const parts = [dayString, hourString, minuteString].filter((x) => x.length > 0);
    return parts.join(' ');
}
