import { useEffect } from 'preact/hooks';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';

/**
 * Effect dispatching a BilberryWidgetEvent based on the React lifecycle.
 * The effect runs on mount only.
 *
 * @param createEvent Function creating an event object, based on the input parameters of the function.
 * @param breakCondition Optional condition function for which to short-circuit the effect if calling the function returns true. NOTE! useWidgetEventEffect assumes you have handled potentially undefined values in breakCondition.
 */
export function useWidgetEventEffect(
    createEvent: () => Parameters<typeof dispatchWidgetEvent>[0],
    breakCondition?: () => boolean,
): void;

/**
 * Effect dispatching a BilberryWidgetEvent based on the React lifecycle.
 * The effect runs when the reference of provided inputs change, and on mount.
 *
 * @param createEvent Function creating an event object, based on the input parameters of the function.
 * @param breakCondition Optional condition function for which to short-circuit the effect if calling the function returns true. NOTE! useWidgetEventEffect assumes you have handled potentially undefined values in breakCondition. If no breakCondition function is passed in, useWidgetEventEffect checks all inputs for truthy-ness and short-circuits if something is falsy.
 * @param input1 The dependent value of the effect. When this reference changes, the effect will re-run. This input can be used in the createEvent and breakCondition functions.
 */
export function useWidgetEventEffect<A>(
    createEvent: (...args: [NonNullable<A>]) => Parameters<typeof dispatchWidgetEvent>[0],
    input1: A,
    breakCondition?: (...args: [A]) => boolean,
): void;

/**
 * Effect dispatching a BilberryWidgetEvent based on the React lifecycle.
 * The effect runs when the reference of provided inputs change, and on mount.
 *
 * @param createEvent Function creating an event object, based on the input parameters of the function.
 * @param breakCondition Optional condition function for which to short-circuit the effect if calling the function returns true. NOTE! useWidgetEventEffect assumes you have handled potentially undefined values in breakCondition. If no breakCondition function is passed in, useWidgetEventEffect checks all inputs for truthy-ness and short-circuits if something is falsy.
 * @param input1 The first dependent value of the effect. When this reference changes, the effect will re-run. This input can be used in the createEvent and breakCondition functions.
 * @param input2 The first dependent value of the effect. When this reference changes, the effect will re-run. This input can be used in the createEvent and breakCondition functions.
 */
export function useWidgetEventEffect<A, B>(
    createEvent: (
        ...args: [NonNullable<A>, NonNullable<B>]
    ) => Parameters<typeof dispatchWidgetEvent>[0],
    input1: A,
    input2: B,
    breakCondition?: (...args: [A, B]) => boolean,
): void;

/**
 * Effect dispatching a BilberryWidgetEvent based on the React lifecycle.
 * The effect runs when the reference of provided inputs change, and on mount.
 *
 * @param createEvent Function creating an event object, based on the input parameters of the function.
 * @param breakCondition Optional condition function for which to short-circuit the effect if calling the function returns true. NOTE! useWidgetEventEffect assumes you have handled potentially undefined values in breakCondition. If no breakCondition function is passed in, useWidgetEventEffect checks all inputs for truthy-ness and short-circuits if something is falsy.
 * @param input1 The dependent value of the effect. When this reference changes, the effect will re-run. This input can be used in the createEvent and breakCondition functions.
 * @param input2 The first dependent value of the effect. When this reference changes, the effect will re-run. This input can be used in the createEvent and breakCondition functions.
 * @param input3 The second dependent value of the effect. When this reference changes, the effect will re-run. This input can be used in the createEvent and breakCondition functions.
 */
export function useWidgetEventEffect<A, B, C>(
    createEvent: (
        ...args: [NonNullable<A>, NonNullable<B>, NonNullable<C>]
    ) => Parameters<typeof dispatchWidgetEvent>[0],
    input1: A,
    input2?: B,
    input3?: C,
    breakCondition?: (...args: [A, B, C]) => boolean,
): void;

export function useWidgetEventEffect<A, B, C>(
    createEvent: (
        ...args: [NonNullable<A>, NonNullable<B>, NonNullable<C>]
    ) => Parameters<typeof dispatchWidgetEvent>[0],
    input1?: A,
    input2?: B | ((...args: [A, B, C]) => boolean),
    input3?: C | ((...args: [A, B, C]) => boolean),
    breakCondition?: (...args: [A, B, C]) => boolean,
): void {
    const [, ...rest] = Array.from(arguments);
    let inputs: any[];
    let breakConditionFn: ((...args: [A, B, C]) => boolean) | undefined;

    if (typeof rest[rest.length - 1] !== 'function') {
        inputs = rest;
    } else {
        breakConditionFn = rest.pop();
        inputs = rest;
    }

    useEffect(() => {
        if (!breakConditionFn && inputs.some((x) => !x)) return;
        else if (!!breakConditionFn && breakConditionFn(inputs[0], inputs[1], inputs[2])) return;
        dispatchWidgetEvent(createEvent(inputs[0]!, inputs[1]!, inputs[2]!));
    }, inputs);
}
