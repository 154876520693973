import { h, render } from 'preact';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import RequestTour from 'src/widgets/activities/request-tour/RequestTour';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryRequestTour extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        const titleTextCustomizationKey = this.getAttribute(
            'title-text-customization-key',
        ) as keyof UserTextCustomizations;
        const submitButtonTextCustomizationKey = this.getAttribute(
            'submit-button-text-customization-key',
        ) as keyof UserTextCustomizations;
        const thankYouTextCustomizationKey = this.getAttribute(
            'thank-you-text-customization-key',
        ) as keyof UserTextCustomizations;
        const expandArrowInside = getYesAttribute(this, 'expand-arrow-inside');
        const hideRequestDate = getYesAttribute(this, 'hide-request-date');
        const positionOffscreen = getYesAttribute(this, 'position-offscreen');

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <RequestTour
                    titleTextCustomizationKey={titleTextCustomizationKey ?? undefined}
                    submitButtonTextCustomizationKey={submitButtonTextCustomizationKey ?? undefined}
                    thankYouTextCustomizationKey={thankYouTextCustomizationKey ?? undefined}
                    expandArrowInside={expandArrowInside}
                    hideRequestDate={hideRequestDate}
                    positionOffscreen={positionOffscreen}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-request-tour', BilberryRequestTour);
}
