import { Box, Button, Collapse, Skeleton, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import KeyInformationGrid from 'src/components/domain/key-information-grid/KeyInformationGrid';
import { useLocale } from 'src/i18n/locale';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getProductCatalogDisplayTitle } from 'src/utils/domain/display-helper';

export default function ProductOverview(props: {
    product: BilberryProductCatalog | null;
}): JSX.Element {
    const { t } = useLocale();
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const { product } = props;
    const customizations = useCustomizations();
    const theme = useTheme();

    const webDisclaimer = product?.web_disclaimer;

    return (
        <Box color={{ color: theme.palette.common.black }}>
            <Box
                sx={{
                    [theme.breakpoints.up('md')]: {
                        paddingRight: theme.spacing(2),
                    },
                    '& > *': {
                        paddingBottom: theme.spacing(2),
                        [theme.breakpoints.up('sm')]: {
                            paddingBottom: theme.spacing(4),
                        },
                    },
                }}
            >
                {product ? (
                    <Typography variant="h2">{getProductCatalogDisplayTitle(product)}</Typography>
                ) : (
                    <Skeleton variant="text" />
                )}
                {product ? (
                    parseHtmlToMui(product?.web_short_description)
                ) : (
                    <Fragment>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Fragment>
                )}
                <KeyInformationGrid product={product} />
            </Box>
            {webDisclaimer && (
                <Box>
                    <Button
                        variant="text"
                        onClick={() => setShowCancellationPolicy(!showCancellationPolicy)}
                        sx={{
                            marginLeft: theme.spacing(-1),
                            marginTop: theme.spacing(-1),
                            color: customizations.linkColor,
                        }}
                    >
                        {capitalize(
                            showCancellationPolicy
                                ? t.hide_cancellation_policy
                                : t.show_cancellation_policy,
                        )}
                    </Button>
                    <Collapse in={showCancellationPolicy}>
                        <Typography>{parseHtmlToMui(webDisclaimer)}</Typography>
                    </Collapse>
                </Box>
            )}
        </Box>
    );
}
