import Person from '@mui/icons-material/Person';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import {
    ActivityCheckoutInfo,
    ContactPerson,
    MultiDayCheckoutInfo,
    PackageCheckoutInfo,
} from 'src/types/checkout-info';
import { isICartPackageItemType, isICartProductItemType } from 'src/utils/domain/cart/cartUtils';
import { getProductDisplayTitle } from 'src/utils/domain/display-helper';
import { findNumberOfTravelersInCartProductItem } from 'src/utils/domain/travelers-helper';
import ActivityQuestionnaire from './ActivityQuestionnaire';
import ExtraQuestionnaire from './ExtraQuestionnaire';
import TravelerQuestionnaire from './TravelerQuestionnaire';

type Props = {
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo;
    showErrors: boolean;
    onChange(checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo): void;
    contactPerson: ContactPerson | null;
};

export default function CheckoutInfoQuestionares(props: Props): JSX.Element {
    const { onChange, checkoutInfo } = props;
    const numberOfTravelers = findNumberOfTravelersInCartProductItem(checkoutInfo.cartItem);

    const travelersQuestionsEntries = Object.entries(checkoutInfo.travelersQuestions);
    const activityQuestionEntries = Object.entries(checkoutInfo.activityQuestions);
    const extrasQuestionEntries = Object.entries(checkoutInfo.extrasQuestions).filter(
        ([_, questions]) => Object.keys(questions).length > 0,
    );

    return (
        <Fragment>
            {(travelersQuestionsEntries.length > 0 ||
                activityQuestionEntries.length > 0 ||
                extrasQuestionEntries.length > 0) && (
                <Header
                    title={getProductDisplayTitle(
                        isICartProductItemType(checkoutInfo.cartItem)
                            ? checkoutInfo.cartItem.product
                            : isICartPackageItemType(checkoutInfo.cartItem)
                            ? checkoutInfo.cartItem.pkg
                            : checkoutInfo.cartItem.products[0],
                    )}
                    numberOfTravelers={numberOfTravelers}
                />
            )}

            {travelersQuestionsEntries.length > 0 &&
                travelersQuestionsEntries.map(([key]) => (
                    <TravelerQuestionnaire
                        key={key}
                        onChange={onChange}
                        travelerKey={key}
                        showErrors={props.showErrors}
                        checkoutInfo={props.checkoutInfo}
                        contactPerson={props.contactPerson}
                    />
                ))}

            {extrasQuestionEntries.map(([key]) => (
                <ExtraQuestionnaire
                    key={key}
                    onChange={onChange}
                    extraId={key}
                    showErrors={props.showErrors}
                    checkoutInfo={props.checkoutInfo}
                />
            ))}

            {activityQuestionEntries.length > 0 && (
                <ActivityQuestionnaire
                    checkoutInfo={props.checkoutInfo}
                    showErrors={props.showErrors}
                    onChange={onChange}
                />
            )}
        </Fragment>
    );
}

type HeaderProps = {
    title: string;
    numberOfTravelers: number;
};

function Header(props: HeaderProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                backgroundColor: theme.palette.grey[50],
                marginTop: theme.spacing(3),
                marginBottom: 0,
            }}
            variant="outlined"
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={9}>
                    <Typography color="textSecondary" variant="h6">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end" alignItems="center">
                    <Typography color="textSecondary" variant="h6" mr={1}>
                        {props.numberOfTravelers}x
                    </Typography>
                    <Person sx={{ fontSize: '1.6rem' }} color="secondary" />
                </Grid>
            </Grid>
        </Paper>
    );
}
