import dayjs from 'dayjs';
import { localeAtom } from 'src/i18n/locale';
import {
    BilberryTimeslotsProduct,
    BilberryTimeslotsProject,
    BilberryTimeslot,
} from 'src/types/bilberry-timeslots-api-types';
import { BASE_SERVICE_URL, USE_SERVICE_PROXY } from 'src/__autogen/env';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { fetcher, getUrlWithParams } from './api-client-common';
import { BilberryGiftcardStatus, BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { showError } from 'src/utils/common/error-handling';
import { BilberryWidgetsGlobalType } from '../widgetsConfiguration';

const { t } = localeAtom.subject.value;

function getApiBaseUrl(config: BilberryWidgetsGlobalType) {
    const baseUrlFromGlobal = config.bilberryBaseApiUrl;
    if (baseUrlFromGlobal) return baseUrlFromGlobal;
    // Needed for local development through proxy
    return USE_SERVICE_PROXY ? '' : BASE_SERVICE_URL;
}

function getApiAccessToken(config: BilberryWidgetsGlobalType) {
    return config.bilberryAccessToken;
}

function getApiDefaultHeaders(config: BilberryWidgetsGlobalType) {
    const accessToken = getApiAccessToken(config);
    return new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    });
}

function getDefaultRequest(
    subdirectory: string,
    locale: string,
    config: BilberryWidgetsGlobalType,
    queryParams: Record<string, any> = {},
) {
    const endpoint = `${getApiBaseUrl(config)}${subdirectory}`;
    const fullUrl = getUrlWithParams(endpoint, locale, queryParams, config);

    return {
        url: fullUrl,
        headers: getApiDefaultHeaders(config),
    };
}
export function useProjects(
    productId: number,
    from: string,
    to: string,
    config: BilberryWidgetsGlobalType,
) {
    const subdirectory = `/api/v2/membership/products/${productId}/projects`;
    const { locale } = localeAtom.subject.value;
    const { url, headers } = getDefaultRequest(subdirectory, locale, config, {
        from: from,
        to: to,
    });

    const { data, error } = useSWR(url, async () => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    return {
        projects: data?.data as BilberryTimeslotsProject[],
        isError: error,
        isLoading: !error && !data,
    };
}

export function useProduct(id: number, config: BilberryWidgetsGlobalType) {
    const subdirectory = `/api/v2/membership/products/${id}`;
    const { locale } = localeAtom.subject.value;
    const { url, headers } = getDefaultRequest(subdirectory, locale, config, {});

    const { data, error } = useSWR(url, async () => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        onError: () => showError(t.couldntGetProductPleaseTryAgain),
    });
    return {
        product: data?.data as BilberryTimeslot,
        isError: error,
        isLoading: !error && !data,
    };
}

export function useProducts(ids: number[], config: BilberryWidgetsGlobalType) {
    const subdirectory = `/api/v2/membership/products`;
    const { locale } = localeAtom.subject.value;
    const { url, headers } =
        ids.length > 0
            ? getDefaultRequest(subdirectory, locale, config, { ids })
            : getDefaultRequest(subdirectory, locale, config, {});

    const { data, error } = useSWR(url, async () => fetcher(url, headers), {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    return {
        products: data?.data as BilberryTimeslotsProduct[],
        isError: error,
        isLoading: !error && !data,
    };
}

export function useTimeslots(
    product: number,
    from: string,
    to: string,
    config: BilberryWidgetsGlobalType,
) {
    const subdirectory = `/api/v2/membership/projects/${product}/timeslots`;
    const { locale } = localeAtom.subject.value;
    const { url, headers } = getDefaultRequest(subdirectory, locale, config, {});

    const { data, error } = useSWR<{ data: BilberryTimeslot[] }>(
        url,
        async () => fetcher(url, headers),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    const now = dayjs();
    const timeslots = data?.data.map(
        ({
            id,
            capacity,
            start,
            end,
            duration,
            timeslot_min_booking_quantity,
            closed,
            cutoff_time,
        }) => ({
            id,
            capacity,
            start: dayjs(start),
            end: dayjs(end),
            duration,
            minBookingQuantity: timeslot_min_booking_quantity,
            closed: closed === 1,
            hasCutoffPassed: dayjs(start).subtract(cutoff_time, 'minutes').isBefore(now),
        }),
    );

    return {
        timeslots,
        isError: error,
        isLoading: !error && !data,
    };
}

export function useGiftcardStatus(
    giftcardReference: string | undefined,
    config: BilberryWidgetsGlobalType,
) {
    const { locale } = localeAtom.subject.value;
    const subdirectory = `/api/giftcards/${giftcardReference}`;
    const { url, headers } = getDefaultRequest(subdirectory, locale, config, {});

    const { data, error, mutate, isLoading } = useSWRImmutable(
        () => (giftcardReference ? url : null),
        () => fetcher(url, headers),
        {
            shouldRetryOnError: false,
        },
    );

    return {
        data: data ? (data.data as BilberryGiftcardStatus) : undefined,
        mutate,
        error,
        isLoading,
    };
}

export function updateGiftcardStatus(
    giftcardReference: string,
    mutate: any,
    config: BilberryWidgetsGlobalType,
) {
    const { locale } = localeAtom.subject.value;
    const subdirectory = `/api/giftcards/${giftcardReference}`;
    const { url } = getDefaultRequest(subdirectory, locale, config, {});

    mutate(url);
}

export function usePromocodeStatus(
    promoCode: string | undefined,
    config: BilberryWidgetsGlobalType,
) {
    const { locale } = localeAtom.subject.value;
    const subdirectory = `/api/v2/promo-code/${promoCode}`;
    const { url, headers } = getDefaultRequest(subdirectory, locale, config, {});

    const { data, error, mutate } = useSWRImmutable(
        () => (promoCode ? url : null),
        () => fetcher(url, headers),
        {
            shouldRetryOnError: false,
        },
    );

    return {
        data: data ? (data.data as BilberryPromoCodeStatus) : undefined,
        mutate,
        error,
    };
}

export async function getPromoCode(promoCode: string, config: BilberryWidgetsGlobalType) {
    const { locale } = localeAtom.subject.value;
    const subdirectory = `/api/v2/promo-code/${promoCode}`;
    const { url, headers } = getDefaultRequest(subdirectory, locale, config, {});

    const res = await fetcher(url, headers);
    return res.data as BilberryPromoCodeStatus;
}
