import { atom, useAtom } from 'ximple';

type StripePayment = {
    paymentStatus: string | undefined;
    stripeKey?: string;
    stripeClientSecret?: string;
};

export const stripePaymentAtom = atom<StripePayment>({
    initialValue: {
        paymentStatus: undefined,
        stripeKey: undefined,
        stripeClientSecret: undefined,
    },
});

export function useStripePayment() {
    return useAtom(stripePaymentAtom)[0];
}
