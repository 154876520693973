import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { useLocale } from 'src/i18n/locale';
import { ProductQuestion } from 'src/types/checkout-info';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { validateBilberryQuestion } from 'src/utils/domain/checkout-info-helper';

type Props = {
    showErrors: boolean;
    onChange(question: ProductQuestion): void;
    question: ProductQuestion;
    disabled?: boolean;
};

export default function BilberryQuestionInput(props: Props): JSX.Element {
    const { showErrors, onChange, question, disabled } = props;
    const isText = question.type === 'text';
    const isTextArea = question.type === 'textarea';
    const isCheckbox = question.type === 'checkbox';
    const theme = useTheme();
    const { t } = useLocale();

    const showValidationError = !validateBilberryQuestion(question) && showErrors;

    function updateValue(latestValue: string | boolean) {
        const question: ProductQuestion = {
            ...props.question,
            answer: latestValue,
        };

        onChange(question);
    }

    return (
        <Fragment>
            {(isText || isTextArea) && (
                <FormControl>
                    <InputLabel
                        htmlFor={`bilberry-additional-info-${question.key}`}
                        sx={{
                            ...TextFieldStyleUtils.positionInputLabelAboveField,
                            color: theme.palette.secondary.main,
                        }}
                        required={question.required}
                        error={showValidationError}
                    >
                        {question.title}
                    </InputLabel>
                    <TextField
                        id={`bilberry-additional-info-${question.key}`}
                        required={question.required}
                        disabled={disabled}
                        error={showValidationError}
                        value={question.answer}
                        onChange={(
                            e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                        ) => updateValue(e.currentTarget.value)}
                        type="text"
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        size="small"
                        multiline={isTextArea}
                        minRows={isTextArea ? 3 : undefined}
                        name={question.key}
                    />
                </FormControl>
            )}

            {isCheckbox && (
                <Box>
                    <FormControlLabel
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                color:
                                    showErrors && !question.answer && question.required
                                        ? theme.palette.error.main
                                        : undefined,
                            },
                        }}
                        control={
                            <Checkbox
                                checked={question.answer as boolean}
                                onChange={(_, checked) => updateValue(checked)}
                                name={question.key}
                            />
                        }
                        labelPlacement="end"
                        label={question.title}
                        color="secondary"
                    />
                    {showErrors && !question.answer && question.required && (
                        <Typography fontSize={12} color="error" mt={-1} ml="14px">
                            {t.pleaseConfirm.parsed(question.title)}
                        </Typography>
                    )}
                </Box>
            )}
        </Fragment>
    );
}
