import { Fragment, h } from 'preact';
import {
    isGiftcardPaymentInfo,
    isTimeslotsPaymentInfo,
} from 'src/utils/domain/payment-types-helper';
import NetsPaymentWrapper from './NetsPaymentWrapper';
import { GiftcardPaymentInfo, PaymentInfo, TimeslotsPaymentInfo } from './payment-types';
import StripePaymentWrapper from './StripePaymentWrapper';

type PaymentProps = {
    paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo;
    onPaymentCancelled: () => void;
    onPaymentCompleted: (
        paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
};

export default function Payment(props: PaymentProps) {
    const paymentGateway = getPaymentGateway(props.paymentInfo);

    if (paymentGateway === 'nets') return <NetsPaymentWrapper {...props} />;
    if (paymentGateway === 'stripe') return <StripePaymentWrapper {...props} />;
    return <Fragment></Fragment>;
}

function getPaymentGateway(paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo) {
    if (isTimeslotsPaymentInfo(paymentInfo)) return 'nets';
    else if (isGiftcardPaymentInfo(paymentInfo)) return paymentInfo.giftcard.payment_gateway;
    else return paymentInfo.booking.payment_gateway;
}
