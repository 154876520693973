import { Box, capitalize, Grid, Typography } from '@mui/material';
import { Fragment, h } from 'preact';
import Payment from 'src/components/domain/payment/Payment';
import {
    CompletedPaymentInfo,
    GiftcardPaymentInfo,
    PaymentInfo,
    TimeslotsPaymentInfo,
} from 'src/components/domain/payment/payment-types';
import Summary from 'src/components/domain/summary/Summary';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useLocale } from 'src/i18n/locale';
import { cartAtom } from 'src/state/cart/cartAtom';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { AppliedGiftCard } from 'src/types/giftcards';
import { useAtom } from 'ximple';
import Receipt from '../../receipt/Receipt';

type PaymentViewProps = {
    paymentInfo: PaymentInfo | null;
    largeScreen: boolean;
    onPaymentCancelled: () => void;
    onPaymentCompleted: (
        paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
    appliedGiftcard: AppliedGiftCard | null;
    appliedPromoCode: BilberryPromoCodeStatus | null;
    completedPaymentInfo: CompletedPaymentInfo | null;
};

export function PaymentView({
    paymentInfo,
    largeScreen,
    onPaymentCancelled,
    onPaymentCompleted,
    appliedGiftcard,
    appliedPromoCode,
    completedPaymentInfo,
}: PaymentViewProps) {
    const { t } = useLocale();
    const [cartItems] = useAtom(cartAtom);

    useWidgetEventEffect(
        () => ({
            eventType: 'checkoutStep',
            checkoutStep: 'Payment',
            cartItems: Object.values(cartItems),
            giftCard: appliedGiftcard
                ? {
                      giftcardReference: appliedGiftcard.giftcardStatus.id,
                      priceReduction: appliedGiftcard.priceReduction,
                  }
                : null,
            promoCode: appliedPromoCode,
        }),
        () => !!completedPaymentInfo,
    );

    return (
        <Fragment>
            {paymentInfo && (
                <Fragment>
                    <Grid
                        container
                        justifyContent={largeScreen ? 'space-between' : 'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                    >
                        <Box sx={{ width: largeScreen ? '50%' : '100%' }}>
                            <Payment
                                onPaymentCancelled={onPaymentCancelled}
                                onPaymentCompleted={onPaymentCompleted}
                                paymentInfo={paymentInfo}
                            />
                        </Box>
                        <Summary
                            hideGoPay={true}
                            isCheckoutSummary={false}
                            appliedGiftcard={appliedGiftcard}
                            appliedPromoCode={appliedPromoCode}
                        />
                    </Grid>
                </Fragment>
            )}
            {completedPaymentInfo && (
                <Fragment>
                    <Grid container justifyContent={largeScreen ? 'flex-start' : 'center'}>
                        <Typography variant="h3" color="secondary">
                            {capitalize(t.thank_you_for_booking_with_us)}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent={largeScreen ? 'space-between' : 'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                    >
                        <Receipt bookingRef={completedPaymentInfo.referenceId} />

                        {completedPaymentInfo.purchasedCartItems && (
                            <Summary
                                isCheckoutSummary={false}
                                isConfirmationPage={true}
                                cartItems={completedPaymentInfo.purchasedCartItems}
                                hideGoPay={true}
                                appliedGiftcard={appliedGiftcard}
                                appliedPromoCode={appliedPromoCode}
                            />
                        )}
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    );
}
