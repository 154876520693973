import { h, render } from 'preact';
import { getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BasketIconButton, {
    BasketIconButtonVariant,
} from 'src/widgets/activities/basket-icon/BasketIconButton';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryBasketIcon extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        const size = this.getAttribute('size')?.toLowerCase();
        const xsSize = this.getAttribute('xs-size')?.toLowerCase();
        const smallOnMobileOrDefault = getYesAttribute(this, 'small-on-mobile');
        const variantText = this.getAttribute('variant')?.toLowerCase();
        let sizeString: 's' | 'm' | 'l' = 's';
        let xsSizeOrDefault: 's' | 'm' | 'l' = 's';
        const variant =
            variantText === 'dense'
                ? BasketIconButtonVariant.DENSE
                : BasketIconButtonVariant.DEFAULT;

        if (size === 's' || size === 'm' || size === 'l') {
            sizeString = size;
        }

        if (xsSize === 's' || xsSize === 'm' || xsSize === 'l') {
            xsSizeOrDefault = xsSize;
        }

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <BasketIconButton
                    size={sizeString}
                    xsSize={xsSizeOrDefault}
                    smallOnMobile={smallOnMobileOrDefault}
                    variant={variant}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-basket-icon', BilberryBasketIcon);
}
