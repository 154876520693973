import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { h, RefObject } from 'preact';
import Modal from 'src/components/common/modal/Modal';
import { useLocale } from 'src/i18n/locale';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type ProductCardReadMoreDialogProps = {
    display: boolean;
    setDisplay: (val: boolean) => void;
    productId: number;
    focusRef: RefObject<HTMLAnchorElement>;
};

export function ProductCardReadMoreDialog(props: ProductCardReadMoreDialogProps) {
    const { locale } = useLocale();
    const theme = useTheme();
    const config = useConfigurations();
    const Content = () => {
        const { productCatalog } = useProductCatalog(props.productId, locale, config);
        return (
            <Paper elevation={0}>
                <img
                    src={productCatalog?.media.image.url}
                    style={{
                        height: '22vh',
                        width: '100%',
                        objectFit: 'cover',
                        borderRadius: theme.shape.borderRadius,
                    }}
                />
                <Box sx={{ px: theme.spacing(6), py: theme.spacing(4) }}>
                    <Typography variant="h4" component="h3" sx={{ mb: 2 }}>
                        {productCatalog?.web_title ?? ''}
                    </Typography>
                    {parseHtmlToMui(productCatalog?.web_full_description ?? '')}
                </Box>
            </Paper>
        );
    };

    return (
        <Modal
            refocusElementOnClose={props.focusRef}
            open={props.display}
            onClose={() => props.setDisplay(false)}
            large
            id={props.productId.toString() ?? 'None'}
        >
            {props.display ? <Content /> : <CircularProgress />}
        </Modal>
    );
}
