import { atom, useAtom } from 'ximple';
import { findBrefValue } from 'src/state/localstorage';
import { cartAtom } from 'src/state/cart/cartAtom';

declare const window: any;
export type BilberryWidgetsGlobalType = typeof BilberryWidgetsGlobal;

const _defaultConfig: BilberryWidgetsGlobalType = {
    bilberryAccessToken: '',
    bilberryBaseApiUrl: '',
    privacyUrl: '',
    termsUrl: '',
    language: '',
    useGtag: false, // override google tag manger and use GA directly.
    enableExtras: false,
    defaultVersion: 'v2',
    logo: '',
    siteKey: '',
    companyKey: '',
    referralCode: findBrefValue(),
    skipBasketOnBook: false,
    enableGiftcards: false,
    enablePromoCodes: true,
    quickCheckout: false,
    preloadLanguage: '',
    preloadMapping: {},
    cache: {
        productCatalogs: {},
    },
    preloadInProgress: {
        productCatalogs: false,
    },
    loginRedirectUrl: window.location.origin,
    timezone: 'Europe/Oslo',
};

// TODO: This is used so HUT don't need to update their configuration when support for multiple auth0 tenants is released.
// HUT should as soon as possible move the auth0 configuration into their own window.BilberryWidgetsGlobal configuration,
// and this code should be removed.
const tempAuth0Setup = window.location.origin.includes('hoytundertaket.no')
    ? {
          auth0Domain: 'bilberry-park.eu.auth0.com',
          auth0ClientId: 'b5rrBZZbGD96wcxEZpiSn52m0Hk2E5MZ',
          auth0Audience: 'https://membership-api.bilberry.no/',
      }
    : {};

const _widgetsConfig = {
    ..._defaultConfig,
    ...tempAuth0Setup,
    ...window.BilberryWidgetsGlobal,
} as BilberryWidgetsGlobalType;

export const configurationAtom = atom({
    initialValue: _widgetsConfig,
});

export function useConfigurations() {
    return useAtom(configurationAtom)[0];
}

window.BilberryWidgetsGlobalOverride = function (
    configurations: Partial<BilberryWidgetsGlobalType>,
) {
    cartAtom.update({ type: 'CLEAR' });
    configurationAtom.update({
        ...(configurationAtom.subject.value ?? {}),
        ...configurations,
    });
};
