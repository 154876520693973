import { h, render } from 'preact';
import { getIdAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import PackageItinerary from './PackageItinerary';

class PackageItineraryWidget extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        const packageId = getIdAttribute(this, false, 'package-id');

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <PackageItinerary id={packageId} />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-package-itinerary', PackageItineraryWidget);
}
