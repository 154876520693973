import produce from 'immer';
import { atom, Subject } from 'ximple';
import { Action } from './reducers';

export type BookingCheckoutTabState = {
    bookingCheckout: {
        tabIndex: number;
    };
};

export enum BookingCheckoutTabIndex {
    Contact = 0,
    Intent = 1,
    Payment = 2,
}

export type BookingCheckoutTabStateAction =
    | 'INITIALIZE'
    | 'GOTO_BOOKING_CONTACT_INFO'
    | 'GOTO_BOOKING_INTENT'
    | 'GOTO_BOOKING_PAYMENT'
    | 'GOTO_BOOKING_TAB_BY_INDEX'
    | 'RESET_BOOKING_TAB_STATE';

const initialBookingCheckoutTabStateAtom: BookingCheckoutTabState = {
    bookingCheckout: { tabIndex: 0 },
};

const uiStateReducer = produce(
    (
        draft: BookingCheckoutTabState,
        action: Action<BookingCheckoutTabStateAction>,
    ): BookingCheckoutTabState => {
        switch (action.type) {
            case 'INITIALIZE':
                return {
                    bookingCheckout: {
                        tabIndex: draft.bookingCheckout.tabIndex,
                    },
                };
            case 'GOTO_BOOKING_CONTACT_INFO':
                draft.bookingCheckout.tabIndex = BookingCheckoutTabIndex.Contact;
                break;
            case 'GOTO_BOOKING_INTENT':
                draft.bookingCheckout.tabIndex = BookingCheckoutTabIndex.Intent;
                break;
            case 'GOTO_BOOKING_PAYMENT':
                draft.bookingCheckout.tabIndex = BookingCheckoutTabIndex.Payment;
                break;
            case 'GOTO_BOOKING_TAB_BY_INDEX':
                draft.bookingCheckout.tabIndex = action.value;
                break;
            case 'RESET_BOOKING_TAB_STATE':
                return initialBookingCheckoutTabStateAtom as BookingCheckoutTabState;
        }
        return draft;
    },
);

export const bookingCheckoutTabStateActionLog$ = new Subject<{
    state: BookingCheckoutTabState;
    newState: BookingCheckoutTabState;
    action: Action<BookingCheckoutTabStateAction>;
}>();

function updateUiAtom(
    state: BookingCheckoutTabState,
    action: Action<BookingCheckoutTabStateAction>,
) {
    const result = uiStateReducer(state, action);
    bookingCheckoutTabStateActionLog$.next({ state, newState: result, action });
    return result;
}

export const bookingCheckoutTabStateAtom = atom<
    BookingCheckoutTabState,
    Action<BookingCheckoutTabStateAction>
>({
    initialValue: initialBookingCheckoutTabStateAtom,
    persistKey: 'no.bilberry-timeslots.booking-checkout-tab',
    update: updateUiAtom,
});
