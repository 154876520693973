import { norway, countryOptionsEn } from 'src/components/common/country-select/countries';
import { Participants } from 'src/state/booking';
import { Contact } from 'src/state/contactInfo';
import { User } from 'src/state/user';
import {
    MembershipConsumer,
    MembershipTicket,
    MembershipUser,
} from 'src/types/membership-api-types';

export function participantsToIntent(
    participants: Participants[],
    valueCardIdsToIgnore?: number[],
    ticketsToIgnore?: number[],
    ticketTypesToIgnore?: number[],
): MembershipTicket[] {
    const allParticipants = participants.flatMap((x) => {
        return Array(x.numberOfParticipants).fill({
            defaultTicketOptionId: x.defaultTicketOptionId,
            ignoredValueCardIds: valueCardIdsToIgnore || [],
            ignoredValueCardProductIds: ticketsToIgnore || [],
            ignoredValueCardProductTypeIds: ticketTypesToIgnore || [],
        });
    });
    return allParticipants as MembershipTicket[];
}

export function contactToConsumer({ content }: Contact, user: User): MembershipConsumer {
    return {
        addressLine1: content.address ?? user.address.address,
        addressLine2: '',
        firstName: content.firstName ?? user.info.firstName,
        lastName: content.surname ?? user.info.surname,
        phonePrefix: content.phonePrefix ?? user.info.phonePrefix,
        phoneNumber: content.phone ?? user.info.phone,
        email: content.email ?? user.info.email,
        postalCode: content.zipcode ?? user.address.zipcode,
        city: content.location ?? user.address.location,
        country:
            countryOptionsEn[content.country ?? norway] ?? countryOptionsEn[user.address.country],
        receiveNewsletter: content.receiveNewsletter ?? false,
    };
}

export function contactToUser({ content }: Contact, user?: MembershipUser): MembershipUser {
    return {
        addressLine1: content.address ?? user?.addressLine1,
        addressLine2: user?.addressLine2 ?? '',
        firstName: content.firstName ?? user?.firstName,
        lastName: content.surname ?? user?.lastName,
        phonePrefix: content.phonePrefix ?? user?.phonePrefix ?? '47',
        phoneNumber: content.phone ?? user?.phoneNumber,
        email: content.email ?? user?.email,
        postalCode: content.zipcode ?? user?.postalCode,
        city: content.location ?? user?.city,
        country: content.country ?? user?.country ?? norway,
        receiveNewsletter: content.receiveNewsletter ?? false,
    };
}
