import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { ICartPackageItem } from 'src/state/cart/ICart';
import { getPackagePriceSummary } from 'src/utils/domain/booking/packagePriceHelper';
import SummaryExtras from './SummaryExtras';

interface IProps {
    cartPackageItem: ICartPackageItem;
    invertedColorsClass?: Record<string, any>;
}

export default function SummaryPackageInformation(props: IProps): JSX.Element {
    const theme = useTheme();
    const { locale } = useLocale();
    const { pkg, vats, quantities } = props.cartPackageItem;

    const packagePriceSummary = getPackagePriceSummary(quantities, vats);
    return (
        <Box margin={theme.spacing(0.5, 0, 2, 0)}>
            {quantities
                .filter((q) => q.quantity > 0)
                .map((q) => (
                    <Grid
                        key={q.id}
                        container
                        direction="row"
                        justifyContent="space-between"
                        wrap="nowrap"
                    >
                        <Typography
                            align="left"
                            color="textSecondary"
                            sx={props.invertedColorsClass}
                        >
                            {q.quantity}x{' '}
                            {pkg.ticket_options.find((t) => q.id === t.ticket_option_id)?.name}
                        </Typography>
                        <Typography
                            align="right"
                            color="textSecondary"
                            sx={props.invertedColorsClass}
                        >
                            {getLocaleNumberFormatTwoDecimals(
                                locale,
                                packagePriceSummary.find((p) => p.id === q.id)?.price ?? 0,
                            )}
                        </Typography>
                    </Grid>
                ))}
            <SummaryExtras cartItem={props.cartPackageItem} {...props} />
        </Box>
    );
}
