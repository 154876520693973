import dayjs from 'dayjs';
import produce from 'immer';
import { postIntent, postReservation } from 'src/utils/domain/api/membership-api';
import { localeAtom } from 'src/i18n/locale';
import { contactToConsumer, participantsToIntent } from 'src/traversals/membership-transformers';
import { BilberryTimeslotsProject } from 'src/types/bilberry-timeslots-api-types';
import { MembershipPaymentPlan, MembershipReserveResponse } from 'src/types/membership-api-types';
import { atom, Subject } from 'ximple';
import { companyAtom } from './company';
import { contactAtom } from './contactInfo';
import { Action } from './reducers';
import { userAtom } from './user';
import { AppliedGiftCard } from 'src/types/giftcards';
import { AppliedPromoCode } from 'src/types/promoCode';
import { getPromoCodeDiscount } from 'src/utils/domain/discounts/promocodes';
import { formatDate } from 'src/utils/common/DateHelpers';

export type Participants = {
    defaultTicketOptionId: number;
    numberOfParticipants: number;
    ticketCategory: string;
    name: string;
    occupancy: number;
};

export type Booking = {
    productId: number;
    productName: string;
    appliedPromoCode: AppliedPromoCode | null;
    appliedGiftcard: AppliedGiftCard | null;
    giftcardReference?: string;
    promocodeReference?: string;
    participants: Participants[];
    extras: { type: string; price: string }[];
    project: BilberryTimeslotsProject | null;
    dateTime: {
        date: string;
        time: {
            start: string;
            end: string;
        };
    };
    timeslots: number[];
    paymentPlan: MembershipPaymentPlan[];
    isCreditsAndCurrencyNeeded: boolean;
    reservation?: MembershipReserveResponse;
};

export type BookingAction =
    | 'INITIALIZE'
    | 'UPDATE_PRODUCT_ID'
    | 'UPDATE_PARTICIPANTS'
    | 'UPDATE_APPLIED_GIFTCARD'
    | 'UPDATE_APPLIED_PROMOCODE'
    | 'UPDATE_GIFTCARD_REFERENCE'
    | 'UPDATE_PROMOCODE_REFERENCE'
    | 'PAY_BY_MEMBERSHIP_CARD'
    | 'PAY_BY_CREDITS'
    | 'UPDATE_PROJECT'
    | 'UPDATE_TIMESLOTS'
    | 'UPDATE_DATE'
    | 'UPDATE_TIME'
    | 'UPDATE_PAYMENT_PLAN'
    | 'UPDATE_RESERVATION'
    | 'UPDATE_PRODUCT_NAME'
    | 'UPDATE_IS_CREDITS_AND_CURRENCY_NEEDED'
    | 'RESET_BOOKING'
    | 'CANCEL';

export const bookingReducer = produce((draft: Booking, action: Action<BookingAction>) => {
    switch (action.type) {
        case 'RESET_BOOKING':
        case 'CANCEL':
            return {
                ...initialBookingAtom,
                productId: draft.productId,
                productName: draft.productName,
            } as Booking;
        case 'INITIALIZE':
            return action.value as Booking;
        case 'UPDATE_PRODUCT_ID':
            draft.productId = action.value;
            break;
        case 'UPDATE_PARTICIPANTS':
            const { id, value, ticketCategory, name, occupancy } = action.value;
            const found = draft.participants.find((x) => x.defaultTicketOptionId === id);
            if (found) found.numberOfParticipants = value;
            else {
                draft.participants = draft.participants.concat({
                    defaultTicketOptionId: id,
                    numberOfParticipants: value,
                    ticketCategory,
                    name,
                    occupancy,
                });
            }
            break;
        case 'UPDATE_PROJECT':
            draft.project = action.value;
            break;
        case 'UPDATE_DATE':
            draft.dateTime.date = dayjs(action.value).format('ll');
            break;
        case 'UPDATE_TIME':
            draft.dateTime.time = action.value;
            break;
        case 'UPDATE_APPLIED_GIFTCARD':
            draft.appliedGiftcard = action.value;
            break;
        case 'UPDATE_APPLIED_PROMOCODE':
            draft.appliedPromoCode = action.value;
            break;
        case 'UPDATE_GIFTCARD_REFERENCE':
            draft.giftcardReference = action.value !== '' ? action.value : undefined;
            break;
        case 'UPDATE_PROMOCODE_REFERENCE':
            draft.promocodeReference = action.value !== '' ? action.value : undefined;
            break;
        case 'UPDATE_PAYMENT_PLAN':
            draft.paymentPlan = action.value;
            break;
        case 'UPDATE_TIMESLOTS':
            draft.timeslots = action.value;
            break;
        case 'UPDATE_RESERVATION':
            draft.reservation = action.value;
            break;
        case 'UPDATE_PRODUCT_NAME':
            draft.productName = action.value;
            break;
        case 'UPDATE_IS_CREDITS_AND_CURRENCY_NEEDED':
            draft.isCreditsAndCurrencyNeeded =
                action.value === 0 ? draft.isCreditsAndCurrencyNeeded : action.value;
            break;

        default:
            break;
    }
});

export const initialBookingAtom: Booking = {
    productId: 0,
    productName: '',
    appliedGiftcard: null,
    appliedPromoCode: null,
    giftcardReference: undefined,
    promocodeReference: undefined,
    timeslots: [],
    paymentPlan: [],
    participants: [],
    project: null,
    extras: [],
    isCreditsAndCurrencyNeeded: false,
    dateTime: {
        date: '',
        time: { start: '', end: '' },
    },
    reservation: undefined,
};

function updateBooking(state: Booking, action: Action<BookingAction>) {
    const newState = bookingReducer(state, action);
    bookingActionLog$.next({ state, newState, action });
    return newState;
}

export const bookingActionLog$ = new Subject<{
    state: Booking;
    newState: Booking;
    action: Action<BookingAction>;
}>();

export const bookingAtom = atom<Booking, Action<BookingAction>>({
    initialValue: initialBookingAtom,
    persistKey: 'no.bilberry-timeslots.booking',
    update: updateBooking,
});

export function getSelectedDateTimeText(dateTime: {
    date: string;
    time: { start: string; end: string };
}) {
    const timeString = `${dateTime.time.start} - ${dateTime.time.end}`;
    return dateTime.date
        ? formatDate(dayjs(dateTime.date), localeAtom.subject.value.locale, 'll') +
              (dateTime.time.start ? `, ${timeString}` : '')
        : undefined;
}

export async function createIntent(
    valueCardIdsToIgnore?: number[],
    ticketsToIgnore?: number[],
    ticketTypesToIgnore?: number[],
    errorMessage?: string,
    booking?: Booking,
    promoCodeReference?: string,
): Promise<MembershipPaymentPlan[]> {
    const currentBooking = booking ? booking : bookingAtom.subject.value;
    const tickets = participantsToIntent(
        currentBooking.participants,
        valueCardIdsToIgnore,
        ticketsToIgnore,
        ticketTypesToIgnore,
    );
    const { currentSite } = companyAtom.subject.value;

    const reservation = {
        productId: currentBooking.productId,
        timeslotIds: currentBooking.timeslots,
        tickets,
        promoCodeReference: promoCodeReference,
    };

    if (!currentSite) {
        throw new Error('company.currentSite has not been loaded yet');
    }

    const error = errorMessage ?? localeAtom.subject.value.t.couldntGetPaymentPlan;
    const intent = await postIntent(currentSite.key, {}, reservation, window.location.href, error);
    return (
        intent?.paymentPlan.tickets.sort(
            (a, b) => a.ticket.defaultTicketOptionId - b.ticket.defaultTicketOptionId,
        ) ?? []
    );
}

export async function createReservation(
    valueCardIdsToIgnore: number[],
    valueCardProductIdsToIgnore: number[],
    valueCardProductTypeIdsToIgnore: number[],
) {
    const booking = bookingAtom.subject.value;
    const contact = contactAtom.subject.value;
    const user = userAtom.subject.value;
    const { currentSite } = companyAtom.subject.value;

    if (!currentSite) {
        throw new Error('company.currentSite has not been loaded yet');
    }

    const tickets = participantsToIntent(
        booking.participants,
        valueCardIdsToIgnore,
        valueCardProductIdsToIgnore,
        valueCardProductTypeIdsToIgnore,
    );
    const consumer = contactToConsumer(contact, user);

    const reservation = {
        productId: booking.productId,
        timeslotIds: booking.timeslots,
        tickets,
        giftcardReference: booking.giftcardReference,
        promoCodeReference: booking.promocodeReference,
    };

    const intentResponse = await postReservation(
        currentSite.key,
        consumer,
        reservation,
        window.location.href,
    );
    return intentResponse;
}

export function totalParticipantCount(booking: Booking) {
    const { participants } = booking;
    return participants.reduce(
        (pVal, cVal) => pVal + cVal.numberOfParticipants * cVal.occupancy,
        0,
    );
}

export function isBookingValid() {
    const { dateTime } = bookingAtom.subject.value;
    return (
        totalParticipantCount(bookingAtom.subject.value) > 0 &&
        dateTime.date &&
        dateTime.time.start &&
        dateTime.time.end
    );
}

export function isBookingStateValid(bookingState: Booking) {
    const { dateTime } = bookingState;
    return (
        totalParticipantCount(bookingState) > 0 &&
        dateTime.date &&
        dateTime.time.start &&
        dateTime.time.end
    );
}

// =========== Dispatchers =========== //

export async function dispatchUpdatePaymentPlan(
    valueCardIdsToIgnore?: number[],
    ticketsToIgnore?: number[],
    ticketTypesToIgnore?: number[],
    promoCodeReference?: string,
) {
    try {
        let usePromoCode = false;
        if (promoCodeReference) {
            const promoCode = await getPromoCodeDiscount(
                bookingAtom.subject.value.productId,
                promoCodeReference,
            );
            if (promoCode.promoCode !== null) {
                bookingAtom.update({
                    type: 'UPDATE_APPLIED_PROMOCODE',
                    value: {
                        promoCode: promoCode.promoCode,
                        discount: promoCode.promocodeDiscount,
                    } as AppliedPromoCode,
                });
                usePromoCode = true;
            }
        }
        const paymentPlan = await createIntent(
            valueCardIdsToIgnore,
            ticketsToIgnore,
            ticketTypesToIgnore,
            undefined,
            bookingAtom.subject.value,
            usePromoCode ? promoCodeReference : undefined,
        );
        bookingAtom.update({ type: 'UPDATE_PAYMENT_PLAN', value: paymentPlan });
    } catch (e) {
        bookingAtom.update({ type: 'UPDATE_PAYMENT_PLAN', value: [] });
        throw e;
    }
}
