import { h } from 'preact';
import ProductCard from 'src/components/domain/product-card-2/ActivityProductCard';
import { useLocale } from 'src/i18n/locale';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { CustomizationsContext, useCustomizations } from 'src/utils/common/theme/customizations';
import { ThemeType } from 'src/utils/common/theme/ThemeType';
import {
    useProductCatalogsByIds,
    useProductCollectionById,
} from 'src/utils/domain/api/bilberry-api-client';
import { ProductCatalogAttributes } from 'src/utils/domain/attributes/product-catalog-attribute-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';

interface IProps {
    productCatalogAttributes: ProductCatalogAttributes[];
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    productCollectionId?: number;
}

export default function ProductCardList2(props: IProps): JSX.Element {
    const {
        productCatalogAttributes,
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
        productCollectionId = null,
    } = props;
    const { locale } = useLocale();
    const config = useConfigurations();
    const customizations = useCustomizations();
    const productCatalogIds = productCatalogAttributes.map((x) => x.id);
    const { productCatalogs } = useProductCatalogsByIds(productCatalogIds, locale, config);

    const { productCollection } = useProductCollectionById(productCollectionId, locale, config);

    const catalogsFromCollection = productCollection?.products;
    const catalogsToUse = catalogsFromCollection ? catalogsFromCollection : productCatalogs;

    const productCardList = catalogsToUse.map((catalog, i) => {
        const url =
            productCollectionId !== null
                ? catalog.web_url
                : productCatalogAttributes[i].url ?? catalog.web_url ?? '#';
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
        };

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations} key={catalog.id}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={productCatalogs.length}
                >
                    <ProductCard
                        productCatalog={catalog}
                        url={url}
                        buttonCustomizationKey={
                            productCollectionId === null
                                ? productCatalogAttributes[i].textCustomizationKey
                                : undefined
                        }
                    />
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {productCardList.length > 0 && productCardList}
        </ProductCardListContainer>
    );
}
