import {
    Button,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { TargetedEvent } from 'preact/compat';
import { DateRangePicker } from 'src/components/common/date-range-picker/DateRangePicker';
import MultipleNumberInput from 'src/components/common/MultipleNumberInput/MultipleNumberInput';
import useLeadsState from 'src/hooks/domain/useLeadsState';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { useLocale } from 'src/i18n/locale';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

export default function LeadsForm(props: {
    hideRequestDate?: boolean;
    commentPlaceholderTextCustomizationKey?: keyof UserTextCustomizations;
}): JSX.Element {
    const { hideRequestDate } = props;
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const configurations = useConfigurations();

    const {
        quantities,
        onClickGotIt,
        onClickBook,
        showConfirm,
        showSubmitError,
        emailValid,
        nameValid,
        email,
        setEmail,
        setName,
        name,
        descriptionValid,
        description,
        setDescription,
        onChangeQuantities,
        setPhone,
        phone,
        setDateRange,
        dateRange,
        defaultQuantities,
    } = useLeadsState(hideRequestDate);

    const inputStyles = {
        ...TextFieldStyleUtils.positionInputLabelAboveField,
        color: customizations.inputFieldLabelColor,
    };

    return (
        <Grid container direction="column" position="relative">
            <Fade in={showConfirm}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    position="absolute"
                    top={0}
                    bottom={0}
                    right={0}
                    left={0}
                    width="100%"
                    height="100%"
                    zIndex="1"
                    sx={{
                        '& > *': {
                            marginBottom: theme.spacing(2),
                            '&:last-child': {
                                marginBottom: 0,
                            },
                        },
                    }}
                >
                    <Typography variant="h4" align="center">
                        {showSubmitError
                            ? t.an_error_occurred_when_submitting_your_request
                            : t.thank_you_for_your_request}
                    </Typography>
                    <Grid container justifyContent="center">
                        <Button
                            variant={customizations.primaryButtonStyle}
                            color="primary"
                            sx={{
                                fontWeight: customizations.boldFontWeight,
                                marginTop: theme.spacing(2),
                            }}
                            onClick={onClickGotIt}
                        >
                            {t.got_it}
                        </Button>
                    </Grid>
                </Grid>
            </Fade>
            <Grid
                item
                container
                sx={[
                    {
                        paddingBottom: theme.spacing(0.5),
                        '& > *': {
                            padding: theme.spacing(0, 1),
                        },
                    },
                    showConfirm && { visibility: 'hidden' },
                ]}
            >
                <Grid item xs={12} sm={hideRequestDate ? 12 : 6}>
                    <FormControl fullWidth={true}>
                        <MultipleNumberInput
                            values={quantities}
                            defaultValues={defaultQuantities}
                            onChange={onChangeQuantities}
                            enablePopoverBorder={true}
                            labelColor={customizations.inputFieldLabelColor}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                {!hideRequestDate && (
                    <Grid item xs={12} sm={6}>
                        <DateRangePicker
                            dateRange={dateRange}
                            onSelectDateRange={setDateRange}
                            label={capitalize(t.when_are_you_going)}
                            showError={false}
                            labelColor={customizations.inputFieldLabelColor}
                            variant="outlined"
                        />
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                container
                sx={[
                    {
                        paddingBottom: theme.spacing(0.5),
                        '& > *': {
                            padding: theme.spacing(0, 1),
                        },
                    },
                    showConfirm && { visibility: 'hidden' },
                ]}
            >
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth error={!descriptionValid}>
                        <InputLabel id="comment-input-label" required={true} sx={inputStyles}>
                            {t.comment}
                        </InputLabel>
                        <TextField
                            multiline
                            minRows={3}
                            required={true}
                            value={description}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => setDescription(e.currentTarget.value)}
                            helperText={!descriptionValid ? t.please_provide_a_description : ''}
                            error={!descriptionValid}
                            variant="outlined"
                            type="text"
                            color="secondary"
                            margin="dense"
                            size="small"
                            name="comment"
                            placeholder={getCustomOrDefaultText(
                                configurations.textCustomizations,
                                props.commentPlaceholderTextCustomizationKey,
                                locale,
                                t.any_special_requests_etc,
                            )}
                            inputProps={{
                                'aria-labelledby': 'comment-input-label',
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: theme.palette.common.white,
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                item
                container
                sx={[
                    {
                        paddingBottom: theme.spacing(0.5),
                        '& > *': {
                            padding: theme.spacing(0, 1),
                        },
                    },
                    showConfirm && { visibility: 'hidden' },
                ]}
            >
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth error={!nameValid}>
                        <InputLabel id="name-input-label" required={true} sx={inputStyles}>
                            {t.name}
                        </InputLabel>
                        <TextField
                            required={true}
                            value={name}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => setName(e.currentTarget.value)}
                            error={!nameValid}
                            helperText={!nameValid ? t.please_provide_your_name : ''}
                            variant="outlined"
                            type="text"
                            color="secondary"
                            margin="dense"
                            size="small"
                            name="name"
                            inputProps={{
                                'aria-labelledby': 'name-input-label',
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: theme.palette.common.white,
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth error={!emailValid}>
                        <InputLabel id="email-input-label" required={true} sx={inputStyles}>
                            {t.email}
                        </InputLabel>
                        <TextField
                            required={true}
                            error={!emailValid}
                            value={email}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => setEmail(e.currentTarget.value)}
                            helperText={!emailValid ? t.please_provide_your_email : ''}
                            variant="outlined"
                            type="text"
                            color="secondary"
                            margin="dense"
                            size="small"
                            name="email"
                            inputProps={{
                                'aria-labelledby': 'email-input-label',
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: theme.palette.common.white,
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="mobile-input-label" sx={inputStyles}>
                            {t.phone_number}
                        </InputLabel>
                        <TextField
                            value={phone}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => setPhone(e.currentTarget.value)}
                            variant="outlined"
                            type="tel"
                            color="secondary"
                            margin="dense"
                            size="small"
                            name="mobile"
                            inputProps={{
                                'aria-labelledby': 'mobile-input-label',
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: theme.palette.common.white,
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                container
                sx={[
                    {
                        paddingBottom: theme.spacing(0.5),
                        '& > *': {
                            padding: theme.spacing(0, 1),
                        },
                    },
                    showConfirm && { visibility: 'hidden' },
                ]}
            >
                <Grid item xs={12} container justifyContent="center">
                    <Button
                        color="primary"
                        variant={customizations.primaryButtonStyle}
                        sx={{
                            fontWeight: customizations.boldFontWeight,
                            marginTop: theme.spacing(2),
                        }}
                        onClick={onClickBook}
                    >
                        {t.submit_request}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
