import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { h } from 'preact';
import {
    ProductCardContainer,
    ProductCardHeader,
} from 'src/components/domain/product-card-2/ProductCard2';
import { ProductCardInfoContainer } from 'src/components/domain/product-card-2/ProductCardInfo';
import { useLocale } from 'src/i18n/locale';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { formatDate } from 'src/utils/common/DateHelpers';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { CustomizationsContext, useCustomizations } from 'src/utils/common/theme/customizations';
import { ThemeType } from 'src/utils/common/theme/ThemeType';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';
import { ActivityProductCardInfoContent } from '../product-card-2/ActivityProductCard';
import { ProductCardBookingForm } from './TourProductCardForm';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    tours: BilberryProduct[][];
    hideReadMore?: boolean;
};

export default function TourProductCardList(props: Props) {
    const {
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
        tours,
        hideReadMore,
    } = props;
    const customizations = useCustomizations();
    const { locale } = useLocale();

    const productCardList = tours.map((products, i) => {
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
            bookingWidgetColorContrast: textColors[cur] ?? customizations.productCardTextColor,
        };

        const [firstProduct] = products;

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations} key={firstProduct.id}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={tours.length ?? 0}
                >
                    <ProductCardContainer>
                        <ProductCardHeader imageUrl={firstProduct.media.image.url} />
                        <ProductCardInfoContainer>
                            <ActivityProductCardInfoContent
                                key={firstProduct.id}
                                product={{
                                    ...firstProduct,
                                    name: firstProduct.title,
                                    description: firstProduct.description,
                                    default_prices: firstProduct.prices,
                                    duration:
                                        dayjs(firstProduct.end).diff(dayjs(firstProduct.start)) /
                                        60000,
                                }}
                                url={firstProduct.web_url}
                                customPriceLabel={
                                    <Grid
                                        component="span"
                                        fontWeight={customizations.boldFontWeight}
                                        fontSize="1rem"
                                    >
                                        {formatDate(dayjs(firstProduct.start), locale, 'Do MMM')}
                                    </Grid>
                                }
                                hidePriceIcon
                                readMoreProductId={firstProduct.product_catalog_id}
                                readMoreLinkVariant={!hideReadMore ? 'none' : 'popup'}
                            />
                            <ProductCardBookingForm products={products} />
                        </ProductCardInfoContainer>
                    </ProductCardContainer>
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer scroll={scroll} numElements={numElements}>
            {productCardList.length > 0 && productCardList}
        </ProductCardListContainer>
    );
}
