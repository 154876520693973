import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { h } from 'preact';
import TourAvailability from 'src/components/domain/upcoming-tours/tour-availability/TourAvailability';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatNoDecimals } from 'src/i18n/Localization';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { upcomingSelectedTourAtom } from 'src/state/upcomingSelectedTour.atom';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { formatDate } from 'src/utils/common/DateHelpers';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAtom } from 'ximple';

export default function UpcomingTour(props: { tour: BilberryProduct; fewLeft: number }) {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const linkUrl = props.tour.web_url;

    return (
        <Box
            display="flex"
            flex="stretch"
            flexDirection="row"
            alignItems="center"
            width="100%"
            border="1px solid lightgrey"
            borderRadius={`${theme.shape.borderRadius}px`}
            mb={theme.spacing(1)}
            sx={{
                backgroundColor: '#f5f5f5',
                justify: 'space-between',
                [theme.breakpoints.down('sm')]: {
                    minHeight: '80px',
                    padding: '0',
                },
            }}
        >
            <Button
                component={Link}
                href="#"
                onClick={() => {
                    upcomingSelectedTourAtom.update(props.tour);
                    window.location.href = linkUrl;
                }}
                display="flex"
                flex="stretch"
                flexDirection="row"
                alignItems="center"
                width="100%"
                height="100%"
                sx={{ justify: 'space-between' }}
            >
                <Box
                    height={theme.spacing(9)}
                    sx={{
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                        [theme.breakpoints.down('sm')]: {
                            height: theme.spacing(10),
                            width: theme.spacing(12),
                        },
                        [theme.breakpoints.down(340)]: {
                            width: theme.spacing(10),
                        },
                    }}
                >
                    <Grid
                        component={'img'}
                        height={theme.spacing(9)}
                        sx={{
                            borderTopLeftRadius: theme.shape.borderRadius,
                            borderBottomLeftRadius: theme.shape.borderRadius,
                            [theme.breakpoints.down('sm')]: {
                                height: theme.spacing(10),
                                width: theme.spacing(12),
                            },
                            [theme.breakpoints.down(340)]: {
                                width: theme.spacing(10),
                            },
                        }}
                        src={props.tour.media.image.url}
                    />
                </Box>
                <Box
                    width="100%"
                    margin="auto"
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                >
                    <Box
                        padding={theme.spacing(1)}
                        pl={theme.spacing(2)}
                        flexWrap="wrap"
                        sx={{
                            justify: 'space-between',
                            [theme.breakpoints.down('sm')]: {
                                alignSelf: 'flex-start',
                            },
                        }}
                    >
                        <Typography
                            fontSize="20px"
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '15px',
                                    fontWeight: customizations.boldFontWeight,
                                },
                                [theme.breakpoints.down(340)]: {
                                    fontSize: '12px',
                                },
                            }}
                        >
                            {props.tour.web_title}
                        </Typography>
                        <Typography
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            {t.from} {currency.currency}{' '}
                            <Grid
                                component={'span'}
                                fontWeight={customizations.boldFontWeight}
                                fontSize="16px"
                            >
                                {' '}
                                {props.tour.from_price
                                    ? getLocaleNumberFormatNoDecimals(locale, props.tour.from_price)
                                    : '-'}
                            </Grid>
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        p={theme.spacing(1)}
                        sx={{ float: 'right' }}
                    >
                        <TourAvailability
                            fewLeft={props.fewLeft}
                            capacity={props.tour?.capacity ?? 0}
                        />
                        <Box
                            display="flex"
                            flexDirection="row"
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                },
                            }}
                        >
                            <Typography
                                m="2px"
                                whiteSpace="nowrap"
                                fontSize="16px"
                                sx={{
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                {formatDate(dayjs(props.tour.start), locale, 'D. MMM YYYY')}
                            </Typography>
                            <Typography
                                m="2px"
                                whiteSpace="nowrap"
                                fontSize="16px"
                                sx={{
                                    [theme.breakpoints.down('sm')]: {
                                        display: 'none',
                                    },
                                }}
                            >
                                -
                            </Typography>
                            <Typography
                                fontWeight={customizations.boldFontWeight}
                                m="2px"
                                fontSize="16px"
                                sx={{
                                    [theme.breakpoints.down('sm')]: {
                                        margin: 0,
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                {formatDate(dayjs(props.tour.start), locale, 'HH:mm')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Button>
        </Box>
    );
}
