import { KeyboardArrowDownRounded, Person } from '@mui/icons-material';
import {
    Box,
    Button,
    capitalize,
    ClickAwayListener,
    Collapse,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    Paper,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useRef, useState } from 'preact/hooks';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from 'src/i18n/locale';
import { Translations } from 'src/i18n/translations/types';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { zIndex } from 'src/utils/common/theme/Theme';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { GuestInfo, updateRoomCount } from './guestInfo';
import SelectGuests from './SelectGuests';

const ClickAwayListenerAny = ClickAwayListener as any;

interface Props {
    maxRooms: number;
    hotelRoomsInfo: GuestInfo[];
    onHotelRoomsInfoChanged: (param: GuestInfo[]) => void;
    showChildrenData: boolean;
}

export default function MultipleRoomSearchContent(props: Props): JSX.Element {
    const { maxRooms, hotelRoomsInfo, onHotelRoomsInfoChanged } = props;

    const customizations = useCustomizations();
    const theme = useTheme();
    const { t } = useLocale();
    const config = useConfigurations();
    const [expanded, setExpanded] = useState(false);
    const isMdPlusScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const roomCount = hotelRoomsInfo.length;

    const handleSelectRooms = (newRoomCount: number) => {
        const updatedHotelRoomsInfo = updateRoomCount(hotelRoomsInfo, newRoomCount);
        onHotelRoomsInfoChanged(updatedHotelRoomsInfo);
        setExpanded(!expanded);
    };

    return (
        <Grid container alignItems="center" direction="column">
            <InputLabel
                sx={
                    isMdPlusScreen
                        ? {
                              ...TextFieldStyleUtils.positionInputLabelAboveField,
                              color: customizations.bookingSearchFormColorContrast,
                              whiteSpace: 'nowrap',
                          }
                        : { margin: theme.spacing(0, 1) }
                }
            >
                {capitalize(t.number_of_rooms)}
            </InputLabel>
            <FormControl>
                <TextField
                    fullWidth
                    sx={{
                        cursor: 'pointer',
                        marginBottom: theme.spacing(3),
                        '& .MuiFilledInput-input': {
                            padding: theme.spacing(1.5),
                            cursor: 'pointer',
                        },
                        '& .MuiInputBase-root': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => setExpanded(!expanded)}
                    onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && setExpanded(!expanded)}
                    variant="outlined"
                    margin="dense"
                    value={`${roomCount} ${t.rooms.num(roomCount)}`}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {<KeyboardArrowDownRounded />}
                            </InputAdornment>
                        ),
                    }}
                />
                <Collapse
                    in={expanded}
                    sx={{
                        position: 'absolute',
                        top: `calc(100% - ${theme.spacing(3)}px) `,
                        width: '100%',
                        zIndex: zIndex.alwaysVisible,
                    }}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            padding: theme.spacing(1, 0),
                            '& > *': {
                                maxWidth: 250,
                                width: '100%',
                                margin: '0 auto',
                            },
                        }}
                    >
                        <List
                            sx={{
                                fontFamily: theme.typography.body1.fontFamily,
                                fontSize: theme.typography.body1.fontSize,
                                fontWeight: theme.typography.body1.fontWeight,
                                color: theme.typography.body1.color,
                                padding: 0,
                                '& > li': {
                                    cursor: 'pointer',
                                    padding: theme.spacing(1, 2),
                                    '&:hover': {
                                        backgroundColor: theme.palette.grey[50],
                                    },
                                },
                            }}
                        >
                            {[...Array(maxRooms)].map((_, index) => {
                                return (
                                    <ListItem
                                        key={`number-of-rooms-${index}`}
                                        tabIndex={0}
                                        onClick={() => handleSelectRooms(index + 1)}
                                        onKeyDown={(e: KeyboardEvent) =>
                                            e.key === 'Enter' && handleSelectRooms(index + 1)
                                        }
                                    >
                                        {`${index + 1} ${t.rooms.num(index + 1)}`}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Paper>
                </Collapse>
            </FormControl>
            <Grid
                container
                direction="row"
                wrap={isMdPlusScreen ? 'nowrap' : 'wrap'}
                sx={
                    isMdPlusScreen
                        ? {
                              '& > *': {
                                  minWidth: '280px',
                              },
                              '& > :not(:first-child)': {
                                  borderLeft: '1px solid',
                              },
                          }
                        : {
                              '& > :not(:first-child)': {
                                  borderTop: 'none',
                              },
                          }
                }
            >
                {[...Array(hotelRoomsInfo.length)].map((_, i) => (
                    <SelectGuests
                        showChildrenData={props.showChildrenData}
                        key={i + 1}
                        name={capitalize(t.rooms.singular) + ' ' + (i + 1)}
                        maxValue={config.personsMax}
                        hotelRoomIndex={i}
                        hotelRoomsInfo={hotelRoomsInfo}
                        onHotelRoomsInfoChanged={onHotelRoomsInfoChanged}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

export function MultipleRoomSearch(props: Props) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const { t } = useLocale();
    const [visible, setVisible] = useState(false);
    const popoverRef = useRef<HTMLDivElement | null>(null);
    const refocusElement = useRef<HTMLInputElement | null>(null);
    const trap = useFocusTrap(popoverRef, visible, refocusElement);

    let guestInfoString = `1 ${capitalize(t.adult.num(1))}, 1 ${capitalize(t.rooms.num(1))}`;
    if (props.hotelRoomsInfo?.length > 0) {
        guestInfoString =
            getTotalAdultsAndChildren(t, props.hotelRoomsInfo) +
            ', ' +
            props.hotelRoomsInfo.length +
            ' ' +
            capitalize(t.rooms.num(props.hotelRoomsInfo.length));
    }

    function close() {
        if (trap) trap.deactivate();
        setVisible(false);
    }

    return (
        <ClickAwayListenerAny onClickAway={close}>
            <Box position="relative">
                <FormControl fullWidth={true}>
                    <InputLabel
                        sx={{
                            ...TextFieldStyleUtils.positionInputLabelAboveField,
                            color: customizations.accommodationSearchFormColorContrast,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {t.guest_and_rooms}
                    </InputLabel>
                    <TextField
                        id="bilberry-date-range-picker-input"
                        inputRef={refocusElement}
                        required={true}
                        fullWidth
                        sx={{
                            cursor: 'pointer',
                            '& .MuiFilledInput-input': {
                                padding: theme.spacing(1.5),
                                cursor: 'pointer',
                            },
                            '& .MuiInputBase-root': {
                                cursor: 'pointer',
                            },
                        }}
                        tabIndex={0}
                        inputProps={{
                            readOnly: true,
                        }}
                        onClick={() => setVisible(true)}
                        onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && setVisible(true)}
                        variant="filled"
                        margin="dense"
                        value={guestInfoString ? guestInfoString : 'Select guests'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Person
                                        sx={{
                                            color: customizations.accommodationSearchFormInputTextColor,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <Collapse
                    in={visible}
                    unmountOnExit
                    sx={{
                        position: 'absolute',
                        minWidth: '100%',
                        zIndex: zIndex.alwaysVisible,
                        [theme.breakpoints.up('sm')]: {
                            left: '50%',
                            transform: 'translateX(-50%)',
                        },
                    }}
                >
                    <Grid
                        component={Paper}
                        elevation={2}
                        padding={theme.spacing(3, 0, 3, 0)}
                        minWidth={250}
                        maxWidth={350}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                maxWidth: '100vw',
                            },
                        }}
                        ref={popoverRef}
                    >
                        <MultipleRoomSearchContent {...props}></MultipleRoomSearchContent>
                        <Grid container justifyContent="center">
                            <Button
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    borderRadius: theme.shape.borderRadius,
                                    padding: theme.spacing(1, 2, 1, 2),
                                    marginTop: theme.spacing(3),
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary.main,
                                    },
                                }}
                                onClick={close}
                            >
                                {t.done.toUpperCase()}
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
            </Box>
        </ClickAwayListenerAny>
    );
}

function getTotalAdultsAndChildren(t: Translations, guestInfo: GuestInfo[]) {
    const adults = guestInfo.map((room) => room.adults).reduce((pVal, cVal) => pVal + cVal);
    const children = guestInfo
        .map((room) => room.children.length)
        .reduce((pVal, cVal) => pVal + cVal);

    let infoString = adults > 0 ? adults + ' ' + capitalize(t.adult.num(adults)) : '';
    infoString += adults > 0 && children > 0 ? ' + ' : '';
    infoString += children > 0 ? children + ' ' + capitalize(t.children.num(children)) : '';
    return infoString;
}
