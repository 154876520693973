import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment } from 'preact';

import { StateUpdater, useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import Modal from 'src/components/common/modal/Modal';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useLocale } from 'src/i18n/locale';
import { createAddProductToCartEvent, removeFromCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { BookingPriceAndQuantity, ICartProductItem } from 'src/state/cart/ICart';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAvailabilityData } from 'src/utils/domain/api/availabilityDataHook';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { getProductQuantities } from 'src/utils/domain/booking/bookingHelpers';
import { getCartProductTypeAndId } from 'src/utils/domain/cart/cartUtils';
import { getProductDisplayTitle } from 'src/utils/domain/display-helper';
import { getInitialQuantityData, updateQuantityData } from 'src/utils/domain/price-helper';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import BookingBase from '../../booking-base/BookingBase';
import { useAtom } from 'ximple/atoms';

interface IProps {
    cartItem: ICartProductItem;
    invertedColorsClass?: Object;
}

export default function EditActivity(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const [config] = useAtom(configurationAtom);
    const { cartItem } = props;

    const customizations = useCustomizations();
    const [selectedProducts, setSelectedProducts] = useState<BilberryProduct[] | undefined>(
        undefined,
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedTimeSlot, setSelectedTimeslot] = useState<
        TimeSlotType<BilberryProduct> | undefined
    >(undefined);
    const [quantities, setQuantities] = useState<BookingPriceAndQuantity[]>([]);

    const { productCatalog } = useProductCatalog(
        cartItem.product.product_catalog_id,
        locale,
        config,
    );

    const productCatalogPrices = useMemo(
        () => productCatalog?.default_prices ?? [],
        [productCatalog],
    );

    const pricesFromSelectedProduct = useMemo(() => {
        return selectedProducts && selectedProducts.length > 0
            ? selectedProducts[0].prices
            : undefined;
    }, [selectedProducts]);

    const { availabilitySearchPeriod, availabilityData, setAvailabilitySearchPeriod } =
        useAvailabilityData(cartItem.product.product_catalog_id, quantities);

    const defaultQuantities = getInitialQuantityData(
        productCatalogPrices,
        productCatalog?.min_entrants,
    );

    const cancel = () => {
        setAnchorEl(null);
        setSelectedTimeslot(undefined);
        setQuantities([]);
    };

    useEffect(() => {
        if (!cartItem || !anchorEl) return;

        const productStartDate = dayjs(cartItem.product.start);
        setSelectedTimeslot({
            product: cartItem.product,
            label: productStartDate.format('HH:mm'),
        });

        setSelectedProducts([cartItem.product]);

        setAvailabilitySearchPeriod({
            startDay: productStartDate.subtract(2, 'month').startOf('month'),
            endDay: productStartDate.add(3, 'month').startOf('month'),
        });

        setQuantities(cartItem.quantities);
    }, [cartItem, anchorEl]);

    useWidgetEventEffect(
        (cartItem) => ({
            eventType: 'viewItem',
            productType: 'Activity',
            product: cartItem.product,
        }),
        cartItem,
        anchorEl,
    );

    useEffect(() => {
        setQuantities(
            updateQuantityData(pricesFromSelectedProduct, productCatalogPrices, quantities),
        );
    }, [pricesFromSelectedProduct, productCatalogPrices]);

    const numPrices = Object.values(availabilityData).flat()[0]?.prices.length;

    return (
        <Fragment>
            <Button
                sx={[
                    {
                        padding: 0,
                        color:
                            props.invertedColorsClass == undefined ||
                            Object.keys(props.invertedColorsClass!).length === 0
                                ? { color: customizations.linkColor }
                                : { color: customizations.bookingWidgetPrimaryColor },
                        textDecoration: `${customizations.linkStyle} !important`,
                    },
                    props.invertedColorsClass != undefined ?? {
                        backgroundColor: customizations.bookingWidgetColor,
                        color: customizations.bookingWidgetPrimaryColor,
                    },
                ]}
                variant="text"
                onClick={(e: MouseEvent) => {
                    if (e.currentTarget instanceof HTMLElement) setAnchorEl(e.currentTarget);
                }}
            >
                {capitalize(t.edit_activity)}
            </Button>
            <Modal
                open={Boolean(anchorEl)}
                id="edit-activity-modal"
                refocusElementOnClose={{ current: anchorEl }}
                onClose={cancel}
            >
                <BookingBase
                    disableFixedPosition={true}
                    productTitle={getProductDisplayTitle(cartItem.product)}
                    setSelectedProducts={(e) => {
                        console.log('CLEARING', e);
                        setSelectedProducts(e);
                    }}
                    numPrices={numPrices}
                    travelerQuantities={quantities}
                    priceQuantities={quantities}
                    defaultQuantities={defaultQuantities}
                    setQuantities={setQuantities}
                    selectedTimeSlot={selectedTimeSlot}
                    onSelectTimeSlot={setSelectedTimeslot}
                    availabilityData={availabilityData}
                    leftButtonLabel={capitalize(t.cancel)}
                    rightButtonLabel={capitalize(t.update)}
                    onClickLeftButton={cancel}
                    onClickRightButton={save(
                        cartItem,
                        quantities,
                        setQuantities,
                        setAnchorEl,
                        setSelectedTimeslot,
                        selectedProducts,
                    )}
                    availabilitySearchPeriod={availabilitySearchPeriod}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    hasChosenDate={!!selectedProducts}
                    setHasChosenDate={() => null}
                    attemptedBooking={false}
                    productCapacity={selectedProducts?.[0]?.capacity || cartItem.product.capacity}
                    title={t.edit_activity}
                    visible={true}
                    onToggleVisible={() => null}
                    addMarginRightToHeader={true}
                    expandArrowInside={false}
                    positionOffscreen={false}
                />
            </Modal>
        </Fragment>
    );
}

function save(
    cartItem: ICartProductItem,
    quantities: BookingPriceAndQuantity[],
    setQuantities: StateUpdater<BookingPriceAndQuantity[]>,
    setAnchorEl: StateUpdater<HTMLElement | null>,
    setSelectedTimeslot: StateUpdater<TimeSlotType<BilberryProduct> | undefined>,
    selectedProducts?: BilberryProduct[],
) {
    return useCallback(() => {
        if (selectedProducts) {
            // remove existing product and exchange with the new one
            cartAtom.update(removeFromCartEvent(getCartProductTypeAndId(cartItem))); // Is this needed? @GoFjord
            const productQuantities = getProductQuantities(selectedProducts[0], quantities);
            cartAtom.update(
                createAddProductToCartEvent(
                    selectedProducts[0],
                    productQuantities,
                    cartItem.extras,
                ),
            );
        } else {
            // just update the existing product
            cartAtom.update(
                createAddProductToCartEvent(cartItem.product, quantities, cartItem.extras),
            );
        }
        setAnchorEl(null);
        setSelectedTimeslot(undefined);
        setQuantities([]);
    }, [cartItem, selectedProducts, quantities, setQuantities, setAnchorEl, setSelectedTimeslot]);
}
