import CancelRounded from '@mui/icons-material/CancelRounded';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import {
    Box,
    Button,
    capitalize,
    Collapse,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import { localeAtom, useLocale } from 'src/i18n/locale';
import { formatCurrency, getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { AppliedGiftCard } from 'src/types/giftcards';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getGiftcardStatus } from 'src/utils/domain/api/bilberry-api-client';
import { getGiftcardSpent } from 'src/utils/domain/price-helper';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';

interface Props {
    totalPrice: number;
    onGiftcardApplied: (appliedGiftcard: AppliedGiftCard | null) => void;
    appliedGiftcard: AppliedGiftCard | null;
    onError: (message: string) => void;
}

export default function SummaryGiftCardPayment(props: Props): JSX.Element {
    const customizations = useCustomizations();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const { t, locale } = useLocale();
    const [inputValue, setInputValue] = useState('');
    const [showGiftCardValidationError, setShowGiftCardValidationError] = useState(false);
    const [currency] = useAtom(currencyAtom);

    const giftcardSpent = props.appliedGiftcard
        ? getGiftcardSpent(props.appliedGiftcard.giftcardStatus, props.totalPrice)
        : 0;
    const giftCardRemaining = props.appliedGiftcard
        ? props.appliedGiftcard.giftcardStatus.balance - giftcardSpent
        : 0;

    // Re-applies giftcard when price changes
    useEffect(() => {
        // Unless there was no giftcard applied at the time
        if (props.appliedGiftcard === null) return;

        const doTryApplyGiftcard = async (giftcardId: string) => {
            await tryApplyGiftcard(
                giftcardId,
                props.totalPrice,
                props.onGiftcardApplied,
                setShowGiftCardValidationError,
                props.onError,
            );
        };

        doTryApplyGiftcard(props.appliedGiftcard.giftcardStatus.id);
    }, [props.totalPrice]);

    const handleButtonClick = async () => {
        if (inputValue.length <= 0) return;

        await tryApplyGiftcard(
            inputValue,
            props.totalPrice,
            props.onGiftcardApplied,
            setShowGiftCardValidationError,
            props.onError,
        );
    };

    const endAdornment =
        inputValue !== '' ? (
            <Box
                sx={{
                    '& .MuiIconButton-root': {
                        padding: 0,
                    },
                }}
            >
                <IconButton
                    sx={{
                        padding: 0,
                        '& .MuiSvgIcon-root': {
                            color: customizations.linkColor,
                        },
                    }}
                    onClick={() => {
                        setInputValue('');
                        setShowGiftCardValidationError(false);
                        props.onGiftcardApplied(null);
                    }}
                >
                    <CancelRounded fontSize="small" />
                </IconButton>
            </Box>
        ) : undefined;
    return (
        <Box borderBottom={`1px solid ${theme.palette.grey[400]}`}>
            <Grid
                container
                onClick={() => {
                    setExpanded(!expanded);
                }}
                alignItems="center"
            >
                <Button color="primary" sx={{ padding: 0, color: customizations.linkColor }}>
                    {capitalize(t.enter_giftcard_code)}
                </Button>
                <IconButton
                    sx={[
                        {
                            transition: theme.transitions.create('transform', {
                                duration: theme.transitions.duration.standard,
                            }),
                            '& .MuiSvgIcon-root': {
                                color: customizations.linkColor,
                            },
                            color: customizations.linkColor,
                        },
                        expanded && { transform: 'rotate(180deg)' },
                    ]}
                >
                    <KeyboardArrowDownRounded />
                </IconButton>
            </Grid>
            <Collapse
                in={expanded}
                sx={{ paddingBottom: theme.spacing(2) }}
                timeout="auto"
                unmountOnExit
            >
                <Fragment>
                    <Grid container wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <TextField
                                error={showGiftCardValidationError}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    marginRight: theme.spacing(1),
                                    width: '264px',
                                    [`@media screen and (max-width: ${768}px)`]: {
                                        width: '232px',
                                    },
                                }}
                                onChange={(
                                    e: TargetedEvent<HTMLTextAreaElement | HTMLInputElement, Event>,
                                ) => {
                                    setInputValue(e.currentTarget.value);
                                    setShowGiftCardValidationError(false);
                                }}
                                onKeyDown={(ev: KeyboardEvent) => {
                                    if (ev.code.toLowerCase() === 'enter') {
                                        handleButtonClick();
                                    }
                                }}
                                variant="outlined"
                                value={inputValue}
                                color="secondary"
                                placeholder="Enter code"
                                disabled={props.appliedGiftcard !== null}
                                InputProps={{
                                    endAdornment: endAdornment,
                                }}
                                size="small"
                            />
                            {showGiftCardValidationError ? (
                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                    {capitalize(t.giftcard_code_error)}
                                </FormHelperText>
                            ) : (
                                props.appliedGiftcard !== null && (
                                    <FormHelperText sx={{ color: theme.palette.grey[600] }}>
                                        {capitalize(t.remaining_value)}:{' '}
                                        {formatCurrency(locale, currency, giftCardRemaining)}
                                    </FormHelperText>
                                )
                            )}
                        </Grid>
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: theme.spacing(1),
                                borderColor: theme.palette.primary.main,
                                height: theme.spacing(5),
                            }}
                            onClick={handleButtonClick}
                        >
                            {t.apply.toUpperCase()}
                        </Button>
                    </Grid>
                    {props.appliedGiftcard !== null && !showGiftCardValidationError && (
                        <Grid container direction="row" justifyContent="flex-end">
                            <Typography color="secondary" mr={theme.spacing(2)}>
                                {capitalize(t.gift_card)}
                            </Typography>
                            <Typography color="secondary">
                                -{getLocaleNumberFormatTwoDecimals(locale, giftcardSpent)}
                            </Typography>
                        </Grid>
                    )}
                </Fragment>
            </Collapse>
        </Box>
    );
}

async function tryApplyGiftcard(
    giftcardId: string,
    totalPrice: number,
    onGiftcardApplied: (appliedGiftcard: AppliedGiftCard | null) => void,
    setShowGiftCardValidationError: StateUpdater<boolean>,
    onError: (message: string) => void,
) {
    const { t, locale } = localeAtom.subject.value;
    const configuration = configurationAtom.subject.value;
    try {
        const giftcardStatus = await getGiftcardStatus(giftcardId, locale, configuration);
        const appliedGiftCard: AppliedGiftCard = {
            giftcardStatus,
            priceReduction: getGiftcardSpent(giftcardStatus, totalPrice),
        };
        onGiftcardApplied(appliedGiftCard);
    } catch (error) {
        setShowGiftCardValidationError(true);
        const errorMessage = capitalize(t.giftcard_code_not_found) + ' ' + giftcardId;
        onError(errorMessage);
        onGiftcardApplied(null);
    }
}
