import CloseRounded from '@mui/icons-material/CloseRounded';
import { Box, Dialog, IconButton, useTheme } from '@mui/material';
import { createRef, h, RefObject } from 'preact';
import { useContext } from 'preact/hooks';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { displayModalAtom } from 'src/state/ui/displayModal.atom';
import { MountPointContext } from 'src/utils/common/mountPoint';
import { ensurePixelValue } from 'src/utils/common/styleUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { zIndex } from 'src/utils/common/theme/Theme';
import { useAtom } from 'ximple';

export default function ModalCollection(): JSX.Element {
    const [modals] = useAtom(displayModalAtom);

    return (
        <div>
            {modals.map((c) => (
                <Modal key={c.id} {...c} />
            ))}
        </div>
    );
}

function Modal(props: {
    id: string;
    content: JSX.Element;
    onClose: () => void;
    refocusElementOnClose?: string | RefObject<HTMLElement>;
    shadowRoot?: ShadowRoot;
    large?: boolean;
}) {
    const mountPoint = useContext(MountPointContext);
    const { content, onClose, refocusElementOnClose, shadowRoot } = props;
    const popoverRef = createRef<HTMLDivElement>();
    const trap = useFocusTrap(popoverRef, true, refocusElementOnClose, shadowRoot);

    const customizations = useCustomizations();
    const theme = useTheme();

    const close = () => {
        if (trap) trap.deactivate(50);
        onClose();
    };

    return (
        <Dialog
            open={true}
            container={mountPoint.popover}
            onBackdropClick={close}
            onClose={close}
            tabIndex={0}
            disableEnforceFocus={true}
            ref={popoverRef}
        >
            <Box
                position="fixed"
                zIndex={zIndex.alwaysVisible}
                border="1px solid"
                left="50%"
                top="50%"
                borderRadius={`calc(${ensurePixelValue(theme.shape.borderRadius)} * 2)`}
                overflow="hidden"
                borderColor={customizations.bookingWidgetPrimaryColor}
                maxHeight="95vh"
                maxWidth={props.large ? '780px' : '450px'}
                width="100%"
                sx={{
                    transform: `translateX(-50%) translateY(-50%)`,
                    overflowY: 'auto',
                    [theme.breakpoints.down('md')]: {
                        width: `calc(100% - ${theme.spacing(2)}) !important`,
                    },
                    '& > *:not(#modal-close-button)': {
                        width: '100% !important',
                        height: '100% !important',
                        maxWidth: '100% !important',
                        margin: '0px !important',
                    },
                }}
            >
                {content}
                <IconButton
                    id="modal-close-button"
                    aria-label="Close modal"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        top: theme.spacing(1.5),
                        right: theme.spacing(1.5),
                        width: 'auto !important',
                        height: 'auto !important',
                        maxWidth: 'unset !important',
                        '& .MuiSvgIcon-root': {
                            color: customizations.bookingWidgetColorContrast,
                        },
                    }}
                >
                    <CloseRounded />
                </IconButton>
            </Box>
        </Dialog>
    );
}
