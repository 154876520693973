import { Alert, Box, useTheme } from '@mui/material';
import { h, render } from 'preact';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryStripeAlertWidget extends BilberryCustomElement {
    static get observedAttributes() {
        return ['errormessage'];
    }

    constructor() {
        super();
    }

    connectedCallback() {
        this.renderComponent();
    }

    attributeChangedCallback() {
        this.renderComponent();
    }

    renderComponent() {
        const errorMessage = this.getAttribute('errormessage') ?? '';

        // finally we need to wrap our application in a StylesProvider
        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <StripeAlert errorMessage={errorMessage}></StripeAlert>
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

function StripeAlert({ errorMessage }: { errorMessage: string }) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                height: '100px',
                my: theme.spacing(2),
            }}
        >
            <Alert severity="error">{errorMessage}</Alert>
        </Box>
    );
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-stripe-alert', BilberryStripeAlertWidget);
}
