import curry from 'lodash-es/curry';
import zip from 'lodash-es/zip';
import { DiscountSource, PriceReduction } from 'src/types/discounts';
import { MembershipPaymentPlan } from 'src/types/membership-api-types';

/** Type guard for checking that an object is a discount source */
export function isDiscountSource(obj: any): obj is DiscountSource {
    return obj && obj.type && (obj.type === 'absolute' || obj.type === 'percentage');
}

/** Get the total currency to pay for an item after reducing the price with any applicable discount sources. The discounts are pre-filtered, so only discounts that are defined are actually applied
 * @param discounts An array of discounts, which each specify a discard amount and a type of discount (whether to apply the discount as a percentage or as an absolute value)
 * @param price The starting price which to use
 */
export const applyDiscounts = (discounts: DiscountSource[], price: number) =>
    disallowNegativePrices(
        discounts
            .filter(({ amount }) => amount && amount > 0)
            .reduce((acc, discount) => acc - getReducedPrice(price, discount), price),
    );

/** Get the total currency to pay for an item after reducing the price with any applicable discount sources. The discounts are pre-filtered, so only discounts that are defined are actually applied.
 * This is a curried version of `applyDiscounts`, which can be used within function flows.
 * @param discounts An array of discounts, which each specify a discard amount and a type of discount (whether to apply the discount as a percentage or as an absolute value)
 * @returns a new function that takes a price for which to apply discounts as its only argument
 */
export const withDiscounts = curry(applyDiscounts);

/** Get the total discount on price given a list of discount sources
 * @param discounts An array of discount sources which to apply
 * @param price The starting price which to discount
 */
export const getTotalDiscounts = (discounts: (DiscountSource | undefined)[], price: number) =>
    discounts
        .filter(isDiscountSource)
        .filter(({ amount }) => amount && amount > 0)
        .reduce((acc, discount) => acc + getReducedPrice(price, discount), 0);

/** Get the individual discounts on price given a list of discount sources
 * @param discounts An array of discount sources which to apply
 * @param price The starting price which to discount
 * @returns An array of price reductions from each discount source
 */
export const getPriceReductions = (
    discounts: (DiscountSource | undefined)[],
    price: number,
): PriceReduction[] => {
    const validDiscounts = discounts
        .filter(isDiscountSource)
        .filter(({ amount }) => amount && amount > 0);

    const reducedPrices = validDiscounts.reduce((acc: number[], discount, i) => {
        const previousPrice = i - 1 >= 0 ? acc[i - 1] : price;
        const priceReduction = price - getReducedPrice(previousPrice, discount);
        return [...acc, priceReduction];
    }, []);

    const priceReductions = zip(validDiscounts, reducedPrices).map(([discount, reduction]) => ({
        name: discount?.name,
        amount: price - (reduction ?? 0),
    }));

    return priceReductions;
};

/** Gets the priceReductions from the payment plan grouped by promo name
 * @param paymentPlan the payment plan to find price reductions for
 * @returns list with name and amount of each promo code
 */
export const getPriceReductionsFromPaymentPlan = (paymentPlan: MembershipPaymentPlan[]) => {
    var priceReductions = [] as PriceReduction[];

    const promos = paymentPlan
        .filter((x) => x.promoData != null)
        .forEach((plan) => {
            var existingIndex = priceReductions.findIndex((x) => x.name == plan.promoData?.name);

            if (existingIndex > -1) {
                priceReductions[existingIndex] = {
                    name: priceReductions[existingIndex].name,
                    amount:
                        priceReductions[existingIndex].amount + (plan.promoData?.amount ?? 0) / 100,
                };
            } else {
                priceReductions = [
                    ...priceReductions,
                    {
                        name: plan.promoData?.name ?? '',
                        amount: (plan.promoData?.amount ?? 0) / 100,
                    },
                ];
            }
        });

    return priceReductions;
};

/** Utility function to make sure a price cannot become negative
 * @param price The price that isn't allowed to become negative
 */
const disallowNegativePrices = (price: number) => Math.max(0, price);

/** Get either the absolute or percentage-based price reduction from a discount
 * @param price The price which to reduce
 * @param discountSource the discount source that applies the reduction
 */
const getReducedPrice = (price: number, { amount, type }: DiscountSource) =>
    type === 'absolute'
        ? getAbsoluteReducedPrice(amount ?? 0, price)
        : getPercentageReducedPrice(amount ?? 0, price);

/** Calculate how much of an absolute price reduction to apply based on a discount balance. If possible, the amount will pay
 * for an entire price. If not, it will only pay for as much money is left of the amount.
 */
const getAbsoluteReducedPrice = (amount: number, totalPrice: number): number =>
    Math.min(totalPrice, amount);

/** Calculate how much of a percentage price reduction to apply based on a discount percentage. If possible, a giftcard will pay
 * for an entire amount. If not, it will only pay for as much money is left on the giftcard.
 */
const getPercentageReducedPrice = (amount: number, totalPrice: number): number => {
    const relativeAmount = (totalPrice / 100) * amount;
    return Math.min(totalPrice, relativeAmount);
};
