import { Theme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { createRef, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { countryOptionsEn } from 'src/components/common/country-select/countries';
import CheckoutTabs from 'src/components/domain/checkout/CheckoutTabs';
import {
    CompletedPaymentInfo,
    GiftcardPaymentInfo,
    PaymentInfo,
    Purchase,
    TimeslotsPaymentInfo,
} from 'src/components/domain/payment/payment-types';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import {
    initializeHiddenBodyStyle,
    useHiddenBody as useHiddenBody,
} from 'src/hooks/common/ui/useHiddenBody';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useLocale } from 'src/i18n/locale';
import { Translations } from 'src/i18n/translations/types';
import { clearCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { ICartItem } from 'src/state/cart/ICart';
import { storeCheckoutInfoState } from 'src/state/checkout-info/checkout-info.localstorage';
import { storePurchase } from 'src/state/payment/purchase.localstorage';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { BilberryAccommodationReservationRequest } from 'src/types/bilberry-hotels-api-types';
import { CheckoutInfoData } from 'src/types/checkout-info';
import { AppliedGiftCard } from 'src/types/giftcards';
import { formatDate } from 'src/utils/common/DateHelpers';
import { showError } from 'src/utils/common/error-handling';
import { capitalize } from 'src/utils/common/TextUtils';
import { createReservation, useExtrasByIds } from 'src/utils/domain/api/bilberry-api-client';
import { BilberryApiError } from 'src/utils/domain/api/BilberryApiError';
import {
    getBookingClosedItems,
    getProductIdsFromCartItems,
    isICartAccommodationItemType,
    isICartPackageItemType,
} from 'src/utils/domain/cart/cartUtils';
import {
    createInitialCheckoutInfoData,
    createInitialCompletedPaymentInfo,
    validateCheckoutInfoData,
} from 'src/utils/domain/checkout-info-helper';
import {
    getCartItemDisplayStartDate,
    getCartItemDisplayTitle,
} from 'src/utils/domain/display-helper';
import { tryFindPaymentRedirectContext } from 'src/utils/domain/payment-redirect-helper';
import {
    guardIsValidPaymentGateway,
    mapUserInputToBilberryReservation,
} from 'src/utils/domain/reservation-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import { getTabIndex } from './views/getTabIndex';
import { ContactView } from './views/ContactView';
import { ExtrasView } from './views/ExtrasView';
import PackageView from './views/PackageView';
import { PaymentView } from './views/PaymentView';

const hiddenBodyStyle = initializeHiddenBodyStyle('activities');

export function Cart(): JSX.Element {
    const { t, locale } = useLocale();
    const sentinelRef = createRef<HTMLDivElement>();
    const configurations = useConfigurations();

    const [appliedGiftcard, setAppliedGiftcard] = useState<AppliedGiftCard | null>(null);
    const [appliedPromoCode, setAppliedPromoCode] = useState<BilberryPromoCodeStatus | null>(null);
    const [checkoutInfoData, setCheckoutInfoData] = useState<CheckoutInfoData | null>(null);

    const config = useConfigurations();
    const [cartItems] = useAtom(cartAtom);
    useEffect(() => {
        setCheckoutInfoData(createInitialCheckoutInfoData(cartItems));
    }, [cartItems, config]);

    const isPackageInCart = Object.values(cartItems).some(({ item }) =>
        isICartPackageItemType(item),
    );

    const idxOpts = {
        hasExtras: config.enableExtras,
        hasPackage: isPackageInCart,
        display: true,
    };

    const paymentRedirectContext = tryFindPaymentRedirectContext();
    const initialActiveTab =
        paymentRedirectContext !== null
            ? 'payment'
            : isPackageInCart
            ? 'package'
            : config.enableExtras
            ? 'extras'
            : 'contactinfo';
    const initialCompletedPaymentInfo = createInitialCompletedPaymentInfo(paymentRedirectContext);

    const [activeTab, setActiveTab] = useState<Parameters<typeof getTabIndex>[0]>(initialActiveTab);

    useEffect(() => {
        if (checkoutInfoData) {
            storeCheckoutInfoState(checkoutInfoData);
        }
    }, [checkoutInfoData]);

    useEffect(() => {
        sentinelRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAddressFields = Object.values(cartItems).some((item) =>
        isICartAccommodationItemType(item.item),
    );

    const isCheckoutInfoDataValid =
        checkoutInfoData !== null
            ? validateCheckoutInfoData(checkoutInfoData, showAddressFields)
            : false;

    const [hidePaymentTabHeader, setHidePaymentTabHeader] = useState<boolean>(false);
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [isMakingReservation, setIsMakingReservation] = useState<boolean>(false);
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
    const [completedPaymentInfo, setCompletedPaymentInfo] = useState<CompletedPaymentInfo | null>(
        initialCompletedPaymentInfo,
    );

    const onConfirmationPage = completedPaymentInfo !== null;
    const { handleGoPayClicked, onPaymentCancelled, onPaymentCompleted, clickedNext } = usePayment(
        t,
        locale,
        checkoutInfoData,
        setActiveTab,
        setPaymentInfo,
        setCompletedPaymentInfo,
        setIsMakingReservation,
        Object.values(cartItems),
        showAddressFields,
        configurations.enableGiftcards ? appliedGiftcard : null,
        configurations.enablePromoCodes ? appliedPromoCode : null,
        idxOpts,
    );

    const { extras, isLoading: isExtrasLoading } = useExtrasByIds(
        getProductIdsFromCartItems(cartItems),
        locale,
        config,
    );

    const hasExtras = isExtrasLoading || (extras && extras.length > 0);

    useHiddenBody(hiddenBodyStyle);

    // Dispatch book event if redirected from Vipps)
    useWidgetEventEffect(
        (cartItems, giftCard, promoCode) => ({
            eventType: 'book',
            cartItems: Object.values(cartItems),
            giftCard: giftCard
                ? {
                      priceReduction: giftCard.priceReduction,
                      giftcardReference: giftCard.giftcardStatus.id,
                  }
                : undefined,
            promoCode: promoCode,
        }),
        cartItems,
        appliedGiftcard,
        appliedPromoCode,
        () => !paymentRedirectContext,
    );

    const defaultTabset = {
        ordered: false,
        tabs: [
            ...(config.enableExtras
                ? [
                      {
                          name: `${getTabIndex('extras', idxOpts)} ${t.extras}`,
                          title: capitalize(t.extras),
                          description: capitalize(t.please_select),
                          backButtonText: t.back_to_webshop,
                          identifier: 'extras',
                          content: (
                              <ExtrasView
                                  activeTab={activeTab}
                                  cartItems={cartItems}
                                  isMakingReservation={isMakingReservation}
                                  largeScreen={largeScreen}
                                  onNextClicked={() => setActiveTab('contactinfo')}
                              />
                          ),
                          disabled: onConfirmationPage || !hasExtras,
                          sx: {
                              ...(!hasExtras && { textDecoration: 'line-through' }),
                          },
                      },
                  ]
                : []),
            {
                name: `${getTabIndex('contactinfo', idxOpts)} ${t.contact_details}`,
                identifier: 'contactinfo',
                title: capitalize(t.contact_details),
                description: capitalize(t.please_register),
                backButtonText: t.back_to_webshop,
                content: (
                    <ContactView
                        largeScreen={largeScreen}
                        isMakingReservation={isMakingReservation}
                        handleGoPayClicked={handleGoPayClicked}
                        checkoutInfoData={checkoutInfoData}
                        showAddressFields={showAddressFields}
                        setCheckoutInfoData={setCheckoutInfoData}
                        clickedNext={clickedNext}
                        appliedGiftcard={appliedGiftcard}
                        setAppliedGiftcard={setAppliedGiftcard}
                        appliedPromoCode={appliedPromoCode}
                        setAppliedPromoCode={setAppliedPromoCode}
                    />
                ),
                disabled: onConfirmationPage,
            },
            {
                name: `${getTabIndex('payment', idxOpts)} ${t.payment}`,
                identifier: 'payment',
                title: capitalize(t.payment),
                description: capitalize(t.please_select),
                backButtonText: t.back_to_webshop,
                hideTitle: completedPaymentInfo ? true : false,
                content: (
                    <PaymentView
                        paymentInfo={paymentInfo}
                        largeScreen={largeScreen}
                        onPaymentCancelled={onPaymentCancelled}
                        onPaymentCompleted={onPaymentCompleted(idxOpts)}
                        appliedGiftcard={appliedGiftcard}
                        appliedPromoCode={appliedPromoCode}
                        completedPaymentInfo={completedPaymentInfo}
                    />
                ),
                disabled: !isCheckoutInfoDataValid || onConfirmationPage,
            },
        ],
    };

    const packageTabset = {
        ordered: false,
        tabs: [
            {
                name: `${getTabIndex('package', idxOpts)} ${t.packages}`,
                identifier: 'package',
                title: capitalize(t.package),
                description: capitalize(t.pleaseChoose),
                backButtonText: t.back_to_webshop,
                content: (
                    <PackageView
                        largeScreen={largeScreen}
                        isMakingReservation={isMakingReservation}
                        onNextClicked={() => setActiveTab(hasExtras ? 'extras' : 'contactinfo')}
                        nextButtonText={hasExtras ? t.extras : t.contact_details}
                        appliedGiftcard={appliedGiftcard}
                        appliedPromoCode={appliedPromoCode}
                        setAppliedGiftcard={setAppliedGiftcard}
                        setAppliedPromoCode={setAppliedPromoCode}
                    />
                ),
                disabled: onConfirmationPage,
            },
            ...(config.enableExtras
                ? [
                      {
                          name: `${getTabIndex('extras', idxOpts)} ${t.extras}`,
                          identifier: 'extras',
                          title: capitalize(t.extras),
                          description: capitalize(t.please_select),
                          backButtonText: t.back_to_webshop,
                          content: (
                              <ExtrasView
                                  activeTab={activeTab}
                                  cartItems={cartItems}
                                  isMakingReservation={isMakingReservation}
                                  largeScreen={largeScreen}
                                  onNextClicked={() => setActiveTab('contactinfo')}
                              />
                          ),
                          disabled: onConfirmationPage || !hasExtras,
                          sx: {
                              ...(!hasExtras && { textDecoration: 'line-through' }),
                          },
                      },
                  ]
                : []),
            defaultTabset.tabs[
                getTabIndex('contactinfo', { ...idxOpts, hasPackage: false, display: false })
            ],
            defaultTabset.tabs[
                getTabIndex('payment', { ...idxOpts, hasPackage: false, display: false })
            ],
        ],
    };

    const tabset = isPackageInCart ? packageTabset : defaultTabset;
    const activeTabNumber = getTabIndex(activeTab, { ...idxOpts, display: false });

    return <CheckoutTabs tabset={tabset} currentTab={activeTabNumber} onChange={(e) => setActiveTab(tabset.tabs[e].identifier as typeof activeTab)} />;
}

function usePayment(
    t: Translations,
    locale: string,
    checkoutInfoData: CheckoutInfoData | null,
    setActiveTab: (arg: Parameters<typeof getTabIndex>[0]) => void,
    setPaymentInfo: (paymentInfo: PaymentInfo | null) => void,
    setCompletedPaymentInfo: (paymentInfo: CompletedPaymentInfo | null) => void,
    setIsMakingReservation: (isMakingReservation: boolean) => void,
    cartItems: ICartItem[],
    showAddressFields: boolean,
    appliedGiftcard: AppliedGiftCard | null,
    appliedPromoCode: BilberryPromoCodeStatus | null,
    tabIndexOpts: Record<string, any>,
) {
    const [cartData] = useAtom(cartAtom);
    const [clickedNext, setClickedNext] = useState(false);
    const config = useConfigurations();

    async function handleGoPayClicked() {
        setClickedNext(true);

        if (!checkoutInfoData) return;

        const isCheckoutInfoDataValid = validateCheckoutInfoData(
            checkoutInfoData,
            showAddressFields,
        );

        if (!isCheckoutInfoDataValid) {
            showError(capitalize(t.please_fill_in_all_required_information));
            return;
        }

        const bookingClosedItems = getBookingClosedItems(cartItems);
        if (bookingClosedItems.length > 0) {
            const productNames = bookingClosedItems.map((item) =>
                capitalize(
                    t.booking_is_closed.parsed(
                        `"${getCartItemDisplayTitle(item)} - ${formatDate(
                            dayjs(getCartItemDisplayStartDate(item)),
                            locale,
                            'lll',
                        )}"`,
                    ),
                ),
            );
            showError(productNames.join(', '));
            return;
        }

        setIsMakingReservation(true);

        const reservation = mapUserInputToBilberryReservation(
            checkoutInfoData,
            config,
            appliedGiftcard?.giftcardStatus.id,
            appliedPromoCode?.coupon_code,
        );

        reservation.accommodationReservation =
            mapCheckoutInfoDataToReserveAccommodationRequest(checkoutInfoData);

        try {
            // Extras need to go into reservation object.
            const booking = await createReservation(reservation, locale, config);

            guardIsValidPaymentGateway(booking);

            const paymentInfo: PaymentInfo = {
                reservation,
                booking,
            };

            const purchase: Purchase = {
                referenceId: booking.id.toString(),
                paymentId: booking.payment_id,
                amount: booking.total,
                cartItems: Object.values(cartData),
                giftcards: [],
                isAnalyticsNotified: false,
            };
            storePurchase(purchase);

            // Bilberry backoffice specific behavior
            if (config.backoffice_skipPayment) {
                onPaymentCompleted(tabIndexOpts)(paymentInfo, null);
                return;
            }

            if (booking.paid_at && !booking.payment_id && !booking.checkout_key) {
                // Most likely due to applied giftcard.
                // Go straight to receipt
                //
                onPaymentCompleted(tabIndexOpts)(paymentInfo, null);
            } else {
                // Proceed to the NETS payment
                setPaymentInfo(paymentInfo);
                setActiveTab('payment');
                dispatchWidgetEvent({
                    eventType: 'paymentInfoAdded',
                    cartItems: Object.values(cartData),
                    giftCard: appliedGiftcard
                        ? {
                              giftcardReference: appliedGiftcard.giftcardStatus.id,
                              priceReduction: appliedGiftcard.priceReduction,
                          }
                        : null,
                    promoCode: appliedPromoCode,
                });
            }
        } catch (error) {
            const errorOccuredMessage = capitalize(t.error_occurred_when_creating_reservation);
            let apiErrorMessages: string[] = [];

            if (error instanceof BilberryApiError) {
                apiErrorMessages = await error.errorMessages();
            }

            showError(`${errorOccuredMessage}. ${apiErrorMessages.join(',')}`);
        }
        setIsMakingReservation(false);
    }

    function onPaymentCancelled() {
        // TODO: what if the user go back, make some minor changes, and then want to proceed...
        // now we will make a new reservation.

        dispatchWidgetEvent({
            eventType: 'checkoutStep',
            cartItems: Object.values(cartData),
            giftCard: appliedGiftcard
                ? {
                      giftcardReference: appliedGiftcard.giftcardStatus.id,
                      priceReduction: appliedGiftcard.priceReduction,
                  }
                : null,
            promoCode: appliedPromoCode,
            checkoutStep: 'Cancel',
        });

        setPaymentInfo(null);
        setActiveTab('contactinfo');
    }

    function onPaymentCompleted(tabIndexOpts: Record<string, any>) {
        return function (
            paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
            paymentGatewayResponse: any,
        ) {
            const knownPaymentInfoType = paymentInfo as PaymentInfo;
            const purchasedCartItems = Object.values(cartData);
            const completedPaymentInfo: CompletedPaymentInfo = {
                paymentInfo: knownPaymentInfoType,
                paymentGatewayResponse,
                purchasedCartItems,
                paymentId: knownPaymentInfoType.booking.payment_id,
                referenceId: knownPaymentInfoType.booking.id.toString(),
            };

            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                cartItems: Object.values(cartData),
                promoCode: appliedPromoCode,
                giftCard: appliedGiftcard
                    ? {
                          giftcardReference: appliedGiftcard.giftcardStatus.id,
                          priceReduction: appliedGiftcard.priceReduction,
                      }
                    : null,
                checkoutStep: 'Success',
            });

            dispatchWidgetEvent({
                eventType: 'book',
                cartItems: Object.values(cartData),
                promoCode: appliedPromoCode,
                giftCard: appliedGiftcard
                    ? {
                          giftcardReference: appliedGiftcard.giftcardStatus.id,
                          priceReduction: appliedGiftcard.priceReduction,
                      }
                    : null,
                reference: knownPaymentInfoType.booking.id.toString(),
            });

            cartAtom.update(clearCartEvent());
            storeCheckoutInfoState(null);
            setPaymentInfo(null);
            setCompletedPaymentInfo(completedPaymentInfo);
            setActiveTab('payment');

            storePurchase(null);
        };
    }

    return {
        handleGoPayClicked,
        onPaymentCancelled,
        onPaymentCompleted,
        clickedNext,
    };
}

function mapCheckoutInfoDataToReserveAccommodationRequest(
    checkoutInfoData: CheckoutInfoData,
): BilberryAccommodationReservationRequest | undefined {
    const { accommodationCheckoutInfo, contactPerson } = checkoutInfoData;

    return accommodationCheckoutInfo.length > 0
        ? {
              from: accommodationCheckoutInfo[0].cartItem.start,
              to: accommodationCheckoutInfo[0].cartItem.end,
              guestDetails: {
                  ...contactPerson,
                  zipCode: contactPerson.postCode,
                  country: contactPerson.country ? countryOptionsEn[contactPerson.country] : '',
                  phoneNumberPrefix: contactPerson.phone.dialCode,
                  phoneNumber: contactPerson.phone.number,
              },
              accommodations: accommodationCheckoutInfo.map((item) => ({
                  accommodationId: item.cartItem.accommodation.id.toString(),
                  price: { id: item.cartItem.price.id },
                  guests: item.cartItem.numberOfGuests,
                  notes: [item.accommodationQuestions['notes'].answer as string],
              })),
          }
        : undefined;
}
