import { h, render } from 'preact';
import { getMediaQuerySizesFromAttributes } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { getCsvColorsAttributes } from 'src/utils/domain/attributes/color-attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import { getCsvProductCatalogAttributes } from 'src/utils/domain/attributes/product-catalog-attribute-helper';
import createWebComponentTagName from 'src/utils/domain/createWebComponentTagName';
import ProductCardList from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardList2';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryProduct extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogAttributes = getCsvProductCatalogAttributes(this);
        const productCollectionId = this.getAttribute('product-collection-id');

        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);

        overrideLocaleFromAttribute(this);

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductCardList
                    productCatalogAttributes={productCatalogAttributes}
                    scroll={scroll}
                    numElements={numElements}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                    productCollectionId={
                        productCollectionId ? parseInt(productCollectionId) : undefined
                    }
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        createWebComponentTagName('bilberry-product-list', 'v2'),
        BilberryProduct,
    );
}
