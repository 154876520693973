import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import ChildCareRounded from '@mui/icons-material/ChildCareRounded';
import LocalOfferRounded from '@mui/icons-material/LocalOfferRounded';
import TrendingUpRounded from '@mui/icons-material/TrendingUpRounded';
import { Box, SvgIcon, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { useLocale } from 'src/i18n/locale';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { formatMinutesToDaysHoursMinutes } from 'src/utils/common/DateHelpers';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { calculateProductCatalogMinValues } from 'src/utils/domain/activities/calculateProductCatalogValues';
import { getDifficultyText } from 'src/utils/domain/mapToTranslations';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type IProps = {
    productCatalog: Pick<
        BilberryProductCatalog,
        'default_prices' | 'from_price' | 'from_age' | 'difficulty' | 'duration'
    >;
    durationTextCustomizationKey?: keyof UserTextCustomizations;
    difficultyTextCustomizationKey?: keyof UserTextCustomizations;
    ageTextCustomizationKey?: keyof UserTextCustomizations;
    pricesFromTextCustomizationKey?: keyof UserTextCustomizations;
};

function OverviewIcon(props: { Icon: typeof SvgIcon; label: string; value: string }) {
    const { Icon, value, label } = props;
    const customizations = useCustomizations();
    const theme = useTheme();

    return (
        <Box
            display="inline-flex"
            flexDirection="row"
            boxSizing="border-box"
            p={theme.spacing(0, 2, 2, 0)}
            sx={{
                boxSizing: 'border-box',
                display: 'inline-flex',
                flexDirection: 'row',
                padding: theme.spacing(0, 2, 2, 0),
                '&:last-child': {
                    paddingRight: 0,
                },
                [theme.breakpoints.down('sm')]: {
                    flex: '1',
                    minWidth: '50%',
                    '&:last-child': {
                        paddingRight: theme.spacing(2),
                    },
                },
            }}
        >
            <Box flex="1" display="flex" alignItems="center">
                <Icon sx={{ fontSize: '2.5em' }} />
            </Box>
            <Box
                pl={theme.spacing(1)}
                display="inline-flex"
                flexDirection="column"
                flex="2"
                sx={{
                    '& p': {
                        whiteSpace: 'nowrap',
                    },
                }}
            >
                <Typography fontSize="0.85rem">{label}</Typography>
                <Typography variant="h5" component="p" fontWeight={customizations.boldFontWeight}>
                    {value}
                </Typography>
            </Box>
        </Box>
    );
}

export default function OverviewIconRow(props: IProps) {
    const { t, locale } = useLocale();
    const configurations = useConfigurations();
    const {
        productCatalog,
        durationTextCustomizationKey,
        difficultyTextCustomizationKey,
        ageTextCustomizationKey,
        pricesFromTextCustomizationKey,
    } = props;

    const { minAge } = calculateProductCatalogMinValues(productCatalog);

    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
        >
            {productCatalog.duration !== -1 && (
                <OverviewIcon
                    Icon={AccessAlarmsRoundedIcon}
                    label={getCustomOrDefaultText(
                        configurations.textCustomizations,
                        durationTextCustomizationKey,
                        locale,
                        t.duration,
                    )}
                    value={formatMinutesToDaysHoursMinutes(t, productCatalog.duration)}
                />
            )}
            <OverviewIcon
                Icon={TrendingUpRounded}
                label={getCustomOrDefaultText(
                    configurations.textCustomizations,
                    difficultyTextCustomizationKey,
                    locale,
                    t.difficulty,
                )}
                value={getDifficultyText(t, productCatalog)}
            />
            <OverviewIcon
                Icon={ChildCareRounded}
                label={getCustomOrDefaultText(
                    configurations.textCustomizations,
                    ageTextCustomizationKey,
                    locale,
                    t.age,
                )}
                value={minAge < Number.MAX_VALUE ? `${minAge}+` : '-'}
            />
            <OverviewIcon
                Icon={LocalOfferRounded}
                label={getCustomOrDefaultText(
                    configurations.textCustomizations,
                    pricesFromTextCustomizationKey,
                    locale,
                    t.prices_from,
                )}
                value={`${productCatalog.from_price}`}
            />
        </Box>
    );
}
