import { StateUpdater } from 'preact/hooks';
import { Translations } from 'src/i18n/translations/types';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { capitalize } from 'src/utils/common/TextUtils';

export function getProductQuantities(
    product: BilberryProduct,
    quantities: BookingPriceAndQuantity[],
) {
    return product.prices.reduce((acc: BookingPriceAndQuantity[], price) => {
        const matchingQuantity = quantities.find((q) => q.price_type === price.price_type);
        if (matchingQuantity) {
            acc.push({ ...price, quantity: matchingQuantity.quantity });
        }
        return acc;
    }, []);
}

export function getNumberOfTravelers(quantities: BookingPriceAndQuantity[]) {
    const travelers = quantities.reduce(
        (sum, current) => sum + current.quantity * current.occupancy,
        0,
    );
    return travelers > 0 ? travelers : 1;
}

export function mapPriceQuantitiesToMultipleNumberInputValueType(
    t: Translations,
    quantities: BookingPriceAndQuantity[],
    defaultQuantities: BookingPriceAndQuantity[],
) {
    const multipleNumberInputValues = quantities.map((q) => ({
        name: q.name,
        value: q.quantity,
        subText: getSubText(t, q),
        id: q.price_category_id,
        disabled: q.should_disable_price,
        disabledText: t.unavailableForSelectedTime,
    }));

    const defaultMultipleNumberInputValues = defaultQuantities.map((q) => ({
        name: q.name,
        value: q.quantity,
        subText: getSubText(t, q),
        id: q.price_category_id,
        disabled: q.should_disable_price,
        disabledText: t.unavailableForSelectedTime,
    }));

    return { multipleNumberInputValues, defaultMultipleNumberInputValues };
}

export function getSubText(t: Translations, ticketOptions: BookingPriceAndQuantity | undefined) {
    if (!ticketOptions) return undefined;
    return ticketOptions.age_from !== null &&
        ticketOptions.age_from !== undefined &&
        ticketOptions.age_to
        ? `(${capitalize(t.age)} ${ticketOptions.age_from}-${ticketOptions.age_to})`
        : undefined;
}

export function onChangeQuantity(
    id: number,
    quantity: number,
    setHasChangedQuantities: StateUpdater<boolean> | undefined,
    setQuantities: StateUpdater<BookingPriceAndQuantity[]>,
    productCapacity: number | undefined,
    setProductCapacityReached: StateUpdater<boolean>,
) {
    setQuantities((prevState) => {
        const index = prevState?.findIndex((x) => x.price_category_id === id);

        if (prevState[index].quantity !== quantity) {
            const newQuantities = [
                ...prevState.slice(0, index),
                { ...prevState[index], quantity: quantity },
                ...prevState.slice(index + 1),
            ];

            const numberOfTravelers = getNumberOfTravelers(newQuantities);

            if (productCapacity && numberOfTravelers > productCapacity) {
                setProductCapacityReached(true);
                if (quantity > prevState[index].quantity) {
                    return prevState;
                }
            } else {
                setProductCapacityReached(false);
            }

            return newQuantities;
        }
        return prevState;
    });
    setHasChangedQuantities?.(true);
}
