import { Box, Button, CircularProgress } from '@mui/material';
import { h, render } from 'preact';
import { localeAtom } from 'src/i18n/locale';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryStripePayButton extends BilberryCustomElement {
    private handleSubmit: ((e: any) => void) | undefined = undefined;

    static get observedAttributes() {
        return ['isloading'];
    }

    get isloading() {
        return !!this.getAttribute('isloading');
    }

    constructor() {
        super();
    }

    attributeChangedCallback() {
        this.renderComponent();
    }

    connectedCallback() {
        this.renderComponent();
    }

    renderComponent() {
        overrideLocaleFromAttribute(this);

        const { t } = localeAtom.subject.value;

        // finally we need to wrap our application in a StylesProvider
        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <Box sx={{ position: 'relative' }}>
                    <Button
                        onClick={this.handleSubmit}
                        color="primary"
                        variant="contained"
                        className="bilberry-stripe-payment"
                        disabled={this.isloading}
                        sx={{
                            mt: 2,
                        }}
                    >
                        {t.buyNow}
                    </Button>
                    {this.isloading && (
                        <CircularProgress sx={{ position: 'absolute', top: 10, left: 25 }} />
                    )}
                </Box>
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-stripe-pay-button', BilberryStripePayButton);
}
