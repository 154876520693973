import {
    Box,
    Collapse,
    debounce,
    FormControl,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    Paper,
    SxProps,
    TextField,
    useTheme,
} from '@mui/material';
import { createRef, RefObject } from 'preact';
import { StateUpdater, useCallback } from 'preact/hooks';
import {
    useControlledVisibilityState,
    VisibilityControllable,
} from 'src/hooks/common/ui/visibility-control';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { deselectTextInput } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { zIndex } from 'src/utils/common/theme/Theme';

export function Dropdown<T>(
    props: VisibilityControllable & {
        value: T;
        onChange: (value: T) => void;
        options: T[];
        getOptionLabel?: (option: T) => JSX.Element | JSX.Element[] | string | number;
        getTextFieldLabel?: (option: T) => string;
        label?: string;
        labelColor?: string;
        adornmentDown?: JSX.Element;
        adornmentUp?: JSX.Element;
        sx?: SxProps;
        error?: string;
        color?: 'primary' | 'secondary';
        helperTextAlignment?: 'start' | 'end';
        variant?: 'filled' | 'outlined' | 'standard' | 'booking-card';
        defaultValue?: string;
    },
) {
    const {
        value,
        onChange,
        label,
        getOptionLabel,
        adornmentDown,
        adornmentUp,
        options,
        getTextFieldLabel,
        error,
        color = 'secondary',
        helperTextAlignment = 'start',
        variant = 'outlined',
        defaultValue,
    } = props;
    const [visible, setVisible] = useControlledVisibilityState(props);
    const refocusElement = createRef<HTMLInputElement>();

    const customizations = useCustomizations();
    const theme = useTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeVisible = useCallback(
        debounce((visible: boolean) => setVisible(visible), 150),
        [],
    );

    const displayValue =
        defaultValue && (!value || (value as unknown as string) === '')
            ? defaultValue
            : getTextFieldLabel
            ? getTextFieldLabel(value)
            : getOptionLabel
            ? getOptionLabel(value)
            : value;

    return (
        <Box
            position={'relative'}
            sx={{
                '& > *': {
                    color:
                        variant === 'booking-card'
                            ? customizations.bookingWidgetPrimaryColor
                            : undefined,
                },
                '& > .MuiOutlinedInput-root': {
                    borderRadius: variant === 'booking-card' ? 0 : undefined,
                    backgroundColor:
                        variant === 'booking-card'
                            ? customizations.bookingWidgetInputColor
                            : undefined,
                },
                '& > .MuiOutlinedInput-root > .MuiOutlinedInput-input': {
                    color: customizations.bookingWidgetInputTextColor,
                },
                '& > .MuiOutlinedInput-root > .MuiModal-root': {
                    zIndex: zIndex.alwaysVisible,
                },
            }}
        >
            <FormControl fullWidth variant={variant === 'booking-card' ? 'outlined' : variant}>
                <InputLabel
                    id={`dropdown-${label}`}
                    sx={{
                        ...TextFieldStyleUtils.positionInputLabelAboveField,
                        color: props.labelColor,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {label}
                </InputLabel>
                <TextField
                    color={color}
                    inputRef={refocusElement}
                    sx={{
                        cursor: 'pointer',
                        '& .MuiFilledInput-input, & .MuiOutlinedInput-input': {
                            padding: theme.spacing(1.5),
                            cursor: 'pointer',
                        },
                        '& .MuiInputBase-root': {
                            cursor: 'pointer',
                        },
                        '& .MuiFormHelperText-root': {
                            color:
                                variant === 'booking-card'
                                    ? `${customizations.bookingWidgetColorContrast} !important`
                                    : undefined,
                            ml: helperTextAlignment === 'end' ? 'auto' : undefined,
                        },
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error}
                    onClick={() => onChangeVisible(!visible)}
                    onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && onChangeVisible(!visible)}
                    onFocus={deselectTextInput}
                    variant={variant === 'booking-card' ? 'filled' : variant}
                    margin="dense"
                    size="small"
                    value={displayValue}
                    InputProps={{
                        readOnly: true,
                        endAdornment:
                            adornmentDown && !visible ? (
                                <InputAdornment position="end">{adornmentDown}</InputAdornment>
                            ) : adornmentUp && visible ? (
                                <InputAdornment position="end">{adornmentUp}</InputAdornment>
                            ) : undefined,
                    }}
                    inputProps={{
                        'aria-labelledby': `dropdown-${label}`,
                    }}
                />
            </FormControl>
            <CollapseContent
                visible={visible}
                refocusElement={refocusElement}
                setVisible={setVisible}
                onChange={onChange}
                options={options}
                getTextFieldLabel={getTextFieldLabel}
                getOptionLabel={getOptionLabel}
            ></CollapseContent>
        </Box>
    );
}

function CollapseContent<T>(props: {
    visible: boolean;
    refocusElement: RefObject<HTMLInputElement>;
    setVisible: StateUpdater<boolean>;
    onChange: (value: T) => void;
    options: T[];
    getTextFieldLabel: ((option: T) => string) | undefined;
    getOptionLabel: ((option: T) => JSX.Element | JSX.Element[] | string | number) | undefined;
}) {
    const theme = useTheme();
    const {
        visible,
        refocusElement,
        setVisible,
        onChange,
        options,
        getTextFieldLabel,
        getOptionLabel,
    } = props;
    const popoverRef = createRef<HTMLDivElement>();
    const trap = useFocusTrap(popoverRef, visible, refocusElement);

    function change(value: T) {
        if (trap) trap.deactivate();
        setVisible(false);
        onChange(value);
    }
    return (
        <Collapse
            in={visible}
            sx={{ position: 'absolute', width: '100%', zIndex: zIndex.alwaysVisible }}
        >
            <Paper
                elevation={2}
                sx={{
                    padding: theme.spacing(1, 0),
                    '& > *': {
                        width: '100%',
                        margin: '0 auto',
                    },
                }}
                ref={popoverRef}
            >
                <List
                    sx={{
                        margin: 0,
                        fontFamily: theme.typography.body1.fontFamily,
                        fontSize: theme.typography.body1.fontSize,
                        fontWeight: theme.typography.body1.fontWeight,
                        color: theme.typography.body1.color,
                        padding: 0,
                        '& > li': {
                            cursor: 'pointer',
                            padding: theme.spacing(1, 2),
                            '&:hover': {
                                backgroundColor: theme.palette.grey[50],
                            },
                        },
                    }}
                >
                    {options.map((opt, i) => (
                        <ListItem
                            key={typeof opt === 'string' ? opt : getTextFieldLabel?.(opt) ?? i}
                            tabIndex={0}
                            onClick={() => change(opt)}
                            onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && change(opt)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {!getOptionLabel ? opt : getOptionLabel(opt)}
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Collapse>
    );
}
