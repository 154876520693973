import produce from 'immer';
import { atom, Subject } from 'ximple';
import { Action } from './reducers';

export type UIState = {
    bookingCheckout: {
        editModalVisible: boolean;
        tabIndex?: number;
    };
    booking: {
        timeslotSelectorVisible: boolean;
        calendarVisible: boolean;
        visible: boolean;
    };
    payment: {
        useCredits: boolean;
        isSuccessful: boolean;
    };
    currentUserFlow: 'product' | 'profile';
    valueCardPurchase: {
        calendarVisible: boolean;
        visible: boolean;
    };
};

export type UIStateAction =
    | 'INITIALIZE'
    | 'OPEN_TIMESLOT_SELECTOR'
    | 'SET_TIMESLOT_SELECTOR_VISIBILITY'
    | 'OPEN_BOOKING_CALENDAR'
    | 'SET_BOOKING_CALENDAR_VISIBILITY'
    | 'TOGGLE_BOOKING'
    | 'SET_BOOKING_VISIBILITY'
    | 'SET_CURRENT_USERFLOW'
    | 'OPEN_EDIT_MODAL'
    | 'CLOSE_EDIT_MODAL'
    | 'TOGGLE_VALUE_CARD'
    | 'SET_VALUE_CARD_VISIBILITY'
    | 'GOTO_BOOKING_TAB_BY_INDEX'
    | 'OPEN_VALUE_CARD_CALENDAR'
    | 'SET_VALUE_CARD_CALENDAR_VISIBILITY'
    | 'SET_PAYMENT_IS_SUCCSESSFUL'
    | 'UNSET_PAYMENT_IS_SUCCSESSFUL'
    | 'RESET_UI';

const uiStateReducer = produce((draft: UIState, action: Action<UIStateAction>) => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                ...initialUiStateAtom,
                bookingCheckout: {
                    tabIndex: draft.bookingCheckout.tabIndex,
                    editModalVisible: false,
                },
                booking: { timeslotSelectorVisible: false, calendarVisible: false, visible: false },
                payment: { useCredits: false, isSuccessful: false },
                currentUserFlow: 'product',
            } as UIState;
        case 'OPEN_TIMESLOT_SELECTOR':
            draft.booking.timeslotSelectorVisible = true;
            break;
        case 'SET_TIMESLOT_SELECTOR_VISIBILITY':
            draft.booking.timeslotSelectorVisible = action.value;
            break;
        case 'OPEN_BOOKING_CALENDAR':
            draft.booking.calendarVisible = true;
            break;
        case 'SET_BOOKING_CALENDAR_VISIBILITY':
            draft.booking.calendarVisible = action.value;
            break;
        case 'SET_BOOKING_VISIBILITY':
            draft.booking.visible = action.value;
            break;
        case 'TOGGLE_BOOKING':
            draft.booking.visible = !draft.booking.visible;
            break;
        case 'SET_CURRENT_USERFLOW':
            draft.currentUserFlow = action.value;
            break;
        case 'OPEN_EDIT_MODAL':
            draft.bookingCheckout.editModalVisible = true;
            break;
        case 'CLOSE_EDIT_MODAL':
            draft.bookingCheckout.editModalVisible = false;
            break;
        case 'SET_VALUE_CARD_VISIBILITY':
            draft.valueCardPurchase.visible = action.value;
            break;
        case 'TOGGLE_VALUE_CARD':
            draft.valueCardPurchase.visible = !draft.valueCardPurchase.visible;
            break;
        case 'OPEN_VALUE_CARD_CALENDAR':
            draft.valueCardPurchase.calendarVisible = true;
            break;
        case 'SET_VALUE_CARD_CALENDAR_VISIBILITY':
            draft.valueCardPurchase.calendarVisible = action.value;
            break;
        case 'SET_PAYMENT_IS_SUCCSESSFUL':
            draft.payment.isSuccessful = true;
            break;
        case 'UNSET_PAYMENT_IS_SUCCSESSFUL':
            draft.payment.isSuccessful = false;
            break;
        case 'RESET_UI':
            return initialUiStateAtom as UIState;
    }
});

const initialUiStateAtom: UIState = {
    bookingCheckout: { editModalVisible: false },
    booking: { timeslotSelectorVisible: false, calendarVisible: false, visible: false },
    payment: { useCredits: false, isSuccessful: false },
    currentUserFlow: 'product',
    valueCardPurchase: {
        calendarVisible: false,
        visible: false,
    },
};

function updateUiState(state: UIState, action: Action<UIStateAction>) {
    const newState = uiStateReducer(state, action);
    uiStateActionLog$.next({ state, newState, action });
    return newState;
}

export const uiStateActionLog$ = new Subject<{
    action: Action<UIStateAction>;
    state: UIState;
    newState: UIState;
}>();

export const uiStateAtom = atom<UIState, Action<UIStateAction>>({
    initialValue: initialUiStateAtom,
    update: updateUiState,
});
