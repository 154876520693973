import { Dayjs } from 'dayjs';
import { BilberryPackage } from 'src/types/bilberry-api-types';

export function getPackageDuration(date: Dayjs, pkg: BilberryPackage): [Dayjs, Dayjs] {
    const start = Math.min(
        ...pkg.ticket_options.flatMap((to) => to.products.map((p) => p.minutes_relative_start)),
    );
    const end = Math.max(
        ...pkg.ticket_options.flatMap((to) => to.products.map((p) => p.minutes_relative_end)),
    );

    return [date.add(start, 'minutes'), date.add(end, 'minutes')];
}
