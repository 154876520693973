import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { noop } from 'lodash-es';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { formatDate, getSimpleDateString } from '../common/DateHelpers';
import { AvailabilityProductType, TimeSlotType } from './TimeSlotType';

export function getAvailableTimeSlots(
    availabilityData: AvailabilityProductType<any>[] | AvailabilityProductType<any> | undefined,
    locale: string,
): TimeSlotType<any>[] {
    if (Array.isArray(availabilityData)) {
        return availabilityData.map((activity) => {
            return {
                label: formatDate(dayjs(new Date(activity['start'])), locale, 'HH:mm'),
                sublabel: formatDate(dayjs(new Date(activity['end'])), locale, 'HH:mm'),
                product: activity,
            };
        });
    } else if (availabilityData) {
        return [
            {
                label: formatDate(dayjs(new Date(availabilityData['start'])), locale, 'HH:mm'),
                sublabel: formatDate(dayjs(new Date(availabilityData['end'])), locale, 'HH:mm'),
                product: availabilityData,
            },
        ];
    }
    return [];
}

function isAccommodationProductType(availability: AvailabilityProductType<any>) {
    return typeof availability === 'object' && 'availableUnits' in availability;
}

export function findFirstAvailableProduct(availabilityData: {
    [date: string]: AvailabilityProductType<any>[] | AvailabilityProductType<any>;
}): AvailabilityProductType<any> | null {
    const availableDates = Object.keys(availabilityData);

    if (availableDates.length === 0) return null;

    availableDates.sort();

    if (isAccommodationProductType(Object.values(availabilityData)[0])) {
        const availableAccommodations = Object.values(availabilityData).filter(
            (accommodation) => accommodation.availableUnits > 0,
        );

        if (availableAccommodations.length > 0) return availableAccommodations[0];
    }

    const [firstDate] = availableDates;
    const productsOnFirstDate = availabilityData[firstDate];
    if (!Array.isArray(productsOnFirstDate)) return productsOnFirstDate;
    productsOnFirstDate.sort(compareStartDateOfProducts);
    return productsOnFirstDate[0];
}

export const findAvailabilityData = (
    date: Dayjs | null | undefined,
    availabilityData: {
        [id: string]:
            | AvailabilityProductType<BilberryProduct>[]
            | AvailabilityProductType<BilberryProduct>;
    },
    locale: string,
    quantities: BookingPriceAndQuantity[],
) => {
    if (!date) return [];

    const dateString = getSimpleDateString(date.toDate(), locale);
    let result = availabilityData[dateString] ?? [];
    if (!Array.isArray(result)) result = [result];

    const nonZeroQuantities = quantities.filter((x) => x.quantity > 0);

    // Every availability must have a price for the user-selected price categories,
    // unless no price categories have been selected
    result = result.filter((x) =>
        nonZeroQuantities.length === 0 ||
        x.prices.some((y) => {
            return nonZeroQuantities.some((z) => y.price_category_id === z.price_category_id);
        }),
    );

    return result;
};

export const tryFindFirstTimeslot = (
    date: Dayjs | null | undefined,
    availabilityData: { [x: string]: any },
    locale: string,
    quantities: BookingPriceAndQuantity[],
) => {
    const availabilityDataForDate = findAvailabilityData(
        date,
        availabilityData,
        locale,
        quantities,
    );
    const availableTimeSlots = getAvailableTimeSlots(availabilityDataForDate, locale);

    return availableTimeSlots.length > 0 ? availableTimeSlots[0] : undefined;
};

function compareStartDateOfProducts(
    a: AvailabilityProductType<any>,
    b: AvailabilityProductType<any>,
) {
    const startA = a.start;
    const startB = b.start;
    if (startA < startB) {
        return -1;
    }
    if (startA > startB) {
        return 1;
    }
    return 0;
}

export function getAllDatesInDateRange(dateRange: DateRange<Dayjs> | undefined) {
    const dates: Dayjs[] = [];
    if (!dateRange?.[0]) return dates;

    dates.push(dateRange[0]);

    if (dateRange[1]) {
        let currentDate = dateRange[0].add(1, 'day');

        while (currentDate <= dateRange[1]) {
            dates.push(currentDate);
            currentDate = currentDate.add(1, 'day');
        }
    }
    return dates;
}
