import {
    ICartAccommodationItem,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import {
    BilberryTimeslotInteractionType,
    BilberryWidgetEventsContentful,
    EventProduct,
} from './events.types';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { getPriceSummaryFromCartItems } from 'src/utils/domain/price-helper';
import {
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
} from 'src/utils/domain/cart/cartUtils';
import {
    BilberryCreateGiftcardRequest,
    BilberryPackage,
    BilberryProduct,
    BilberryProductCatalog,
    BilberryPromoCodeStatus,
} from 'src/types/bilberry-api-types';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { Booking } from 'src/state/booking';
import { getTotalNetPrice, getTotalPrice } from 'src/utils/domain/bookings/price';
import { ValueCardPurchase } from 'src/state/valueCard';
import { companyAtom } from 'src/state/company';
import { MembershipValueCardProduct } from 'src/types/membership-api-types';
import { sumBy } from 'lodash-es';
import { getTotalPackagePrice } from 'src/utils/domain/booking/packagePriceHelper';

type EventCartItem =
    | ICartProductItem
    | ICartMultiDayProductItem
    | ICartAccommodationItem
    | ICartPackageItem;

export function mapCartItemToWidgetEvent(
    cartItem: { item: EventCartItem },
    promoCode?: BilberryPromoCodeStatus | null,
) {
    if (isICartProductItemType(cartItem.item) || isICartMultiDayProductItemType(cartItem.item))
        return mapProductCartItemToWidgetEvent(cartItem.item, promoCode);
    else if (isICartPackageItemType(cartItem.item))
        return mapPackageCartItemToWidgetEvent(cartItem.item);
    else return mapAccommodationCartItemToWidgetEvent(cartItem.item, promoCode);
}

function mapProductCartItemToWidgetEvent(
    cartItem: ICartProductItem | ICartMultiDayProductItem,
    promoCode?: BilberryPromoCodeStatus | null,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const priceSummary = getPriceSummaryFromCartItems([{ item: cartItem }]);
    const product = isICartProductItemType(cartItem) ? cartItem.product : cartItem.products[0];

    const promoCodeIsProductSpecific = promoCode && promoCode.products.length > 0;
    const promoCodeReductionForProduct =
        !!promoCode && (promoCode.products.includes(product.id) || promoCode.products?.length === 0)
            ? promoCode.coupon_type === 'percent'
                ? (promoCode.coupon_value / 100) * priceSummary.totalPrice
                : promoCode.coupon_value
            : 0;

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: priceSummary.totalPrice - promoCodeReductionForProduct,
        promoCode:
            !promoCodeIsProductSpecific && promoCode
                ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                : undefined,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: priceSummary.totalPrice - promoCodeReductionForProduct,
                prices: cartItem.quantities
                    .filter((q) => q.quantity > 0)
                    .map((q) => ({
                        totalIncVat: q.price * q.quantity - promoCodeReductionForProduct,
                        priceType: q.price_type,
                        totalExVat:
                            q.price * q.quantity -
                            q.vat_amount * q.quantity -
                            (q.vat_amount / q.price) * promoCodeReductionForProduct,
                        numberOfTravelers: q.quantity,
                    })),
                product: {
                    productTitle: product.title,
                    productId: product.id.toString(),
                    productType: isICartProductItemType(cartItem)
                        ? 'Activity'
                        : 'BilberryAccommodation',
                },
                promoCode:
                    promoCodeIsProductSpecific && promoCodeReductionForProduct > 0 && promoCode
                        ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                        : undefined,
            },
        ],
    };
}

function mapAccommodationCartItemToWidgetEvent(
    cartItem: ICartAccommodationItem,
    promoCode?: BilberryPromoCodeStatus | null,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const priceSummary = getPriceSummaryFromCartItems([{ item: cartItem }]);

    const promoCodeIsProductSpecific = promoCode && promoCode.products.length > 0;
    const promoCodeReductionForProduct =
        !!promoCode &&
        (promoCode.products.includes(cartItem.accommodation.id) || promoCode.products?.length === 0)
            ? promoCode.coupon_type === 'percent'
                ? (promoCode.coupon_value / 100) * priceSummary.totalPrice
                : promoCode.coupon_value
            : 0;

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: priceSummary.totalPrice - promoCodeReductionForProduct,
        promoCode:
            !promoCodeIsProductSpecific && promoCode
                ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                : undefined,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: priceSummary.totalPrice - promoCodeReductionForProduct,
                prices: [
                    {
                        totalIncVat: cartItem.price.value - promoCodeReductionForProduct,
                        priceType: cartItem.price.priceType.toString(),
                        totalExVat: -1,
                        numberOfTravelers: cartItem.numberOfGuests,
                    },
                ],
                product: {
                    productTitle: cartItem.accommodation.name,
                    productId: cartItem.accommodation.id.toString(),
                    productType: 'Accommodation',
                },
                promoCode:
                    promoCodeIsProductSpecific && promoCodeReductionForProduct > 0 && promoCode
                        ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                        : undefined,
            },
        ],
    };
}

function mapPackageCartItemToWidgetEvent(
    cartItem: ICartPackageItem,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const price = getTotalPackagePrice(cartItem.quantities, cartItem.pkg);

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: price,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: price,
                prices: [
                    {
                        totalIncVat: price,
                        priceType: '',
                        totalExVat: sumBy(Object.values(cartItem.vats), (vats) =>
                            sumBy(vats, (vat) => vat.net),
                        ),
                        numberOfTravelers: sumBy(cartItem.quantities, ({ quantity }) => quantity),
                    },
                ],
                product: {
                    productTitle: cartItem.pkg.title,
                    productId: cartItem.pkg.id.toString(),
                    productType: 'Package',
                },
            },
        ],
    };
}

export function mapTimeslotBookingToWidgetEvent(
    booking: Booking,
    promoCode?: BilberryPromoCodeStatus | null,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const totalPrice = getTotalPrice(booking.paymentPlan);
    const promoCodeIsProductSpecific = promoCode && promoCode.products.length > 0;
    const promoDiscount =
        booking.paymentPlan.reduce((acc, cur) => acc + (cur.promoData?.amount ?? 0), 0) / 100;

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: totalPrice,
        promoCode:
            !promoCodeIsProductSpecific && promoCode
                ? { code: promoCode.coupon_code, discount: promoDiscount }
                : undefined,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'Timeslot',
                    productId: booking.productId.toString(),
                    productTitle: booking.productName,
                },
                priceIncVat: totalPrice,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: totalPrice,
                        totalExVat: getTotalNetPrice(booking.paymentPlan),
                        numberOfTravelers: booking.participants.reduce(
                            (acc, x) => acc + x.numberOfParticipants,
                            0,
                        ),
                    },
                ],
                promoCode:
                    promoCodeIsProductSpecific && promoDiscount > 0 && promoCode
                        ? { code: promoCode.coupon_code, discount: promoDiscount }
                        : undefined,
            },
        ],
    };
}

export function mapMembershipBookingToWidgetEvent(
    purchase: ValueCardPurchase,
    promoCode?: BilberryPromoCodeStatus | null,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const currentId =
        purchase.category === 'membership' ? purchase.membership.id : purchase.credits.id;
    const currentValueCard = companyAtom.subject.value.company?.valueCardProducts?.find(
        ({ id }) => id === currentId,
    );

    const totalPrice = (currentValueCard?.price ?? 0) / 100;
    const totalNetPrice = (currentValueCard?.netPrice ?? 0) / 100;

    const promoCodeIsProductSpecific = promoCode && promoCode.products.length > 0;
    const promoCodeReductionForProduct =
        !!promoCode && (promoCode.products.includes(currentId) || promoCode.products?.length === 0)
            ? promoCode.coupon_type === 'percent'
                ? (promoCode.coupon_value / 100) * totalPrice
                : promoCode.coupon_value
            : 0;

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: totalPrice,
        promoCode:
            !promoCodeIsProductSpecific && promoCode
                ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                : undefined,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'ValueCard',
                    productId: currentId?.toString() ?? '',
                    productTitle: `${currentValueCard?.name}${
                        currentValueCard?.valueCardProductCategory
                            ? ' - ' + currentValueCard.valueCardProductCategory.name
                            : ''
                    }`,
                },

                priceIncVat: totalPrice,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: totalPrice,
                        totalExVat: totalNetPrice,
                        numberOfTravelers: 1,
                    },
                ],
                promoCode:
                    promoCodeIsProductSpecific && promoCodeReductionForProduct > 0 && promoCode
                        ? { code: promoCode.coupon_code, discount: promoCodeReductionForProduct }
                        : undefined,
            },
        ],
    };
}

export function mapBilberryProductToWidgetEvent(
    product: BilberryProductCatalog | BilberryProduct,
    productType: EventProduct['productType'],
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: product.from_price ?? 0,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: product.from_price ?? 0,
                prices: [],
                product: {
                    productTitle: 'name' in product ? product.name : product.title,
                    productId: product.id.toString(),
                    productType: productType,
                },
            },
        ],
    };
}

export function mapBilberryPackageToWidgetEvent(
    product: Partial<BilberryPackage>,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    const fromPrice = Math.min(...(product.ticket_options?.map((to) => to.price) ?? [0]));

    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: fromPrice,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: fromPrice ?? 0,
                prices: [],
                product: {
                    productTitle: product?.title ?? '',
                    productId: product.id?.toString() ?? '',
                    productType: 'Package',
                },
            },
        ],
    };
}

export function mapAccommodationToWidgetEvent(
    accommodation: BilberryAccommodation,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: accommodation.prices[0]?.value ?? 0,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                priceIncVat: accommodation.prices[0]?.value ?? 0,
                prices: accommodation.prices.map((p) => ({
                    priceType: p.priceType.toString(),
                    totalIncVat: p.value,
                    totalExVat: p.value,
                    numberOfTravelers: 1,
                })),
                product: {
                    productTitle: accommodation.name,
                    productId: accommodation.id.toString(),
                    productType: 'Accommodation',
                },
            },
        ],
    };
}

export function mapBilberryTimeslotToWidgetEvent(
    timeslot: BilberryTimeslotInteractionType,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: timeslot.from_price,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'Timeslot',
                    productId: timeslot.id.toString(),
                    productTitle: timeslot.title ?? timeslot.internal_title ?? '',
                },
                priceIncVat: timeslot.from_price,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: timeslot.from_price,
                        totalExVat: timeslot.from_price,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}

export function mapMembershipValueCardProductToWidgetEvent(
    card: MembershipValueCardProduct,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: card.price / 100,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'ValueCard',
                    productId: card.id.toString(),
                    productTitle: `${card.name}${
                        card?.valueCardProductCategory
                            ? ' - ' + card.valueCardProductCategory.name
                            : ''
                    }`,
                },
                priceIncVat: card.price / 100,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: card.price / 100,
                        totalExVat: card.netPrice / 100,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}

export function mapBilberryGiftcardToWidgetEvent(
    giftCard: BilberryCreateGiftcardRequest,
): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: giftCard.amount,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'Giftcard',
                    productId: 'Giftcard',
                    productTitle: 'Giftcard',
                },
                priceIncVat: giftCard.amount,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: giftCard.amount,
                        totalExVat: giftCard.amount,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}

export function mapBilberryGiftcardUsageToWidgetEvent(appliedGiftCard: {
    priceReduction: number;
    giftcardReference: string;
}): Omit<BilberryWidgetEventsContentful, 'type'> {
    return {
        currency: currencyAtom.subject.value.currency,
        priceIncVat: -appliedGiftCard.priceReduction,
        products: [
            {
                currency: currencyAtom.subject.value.currency,
                product: {
                    productType: 'Applied Giftcard',
                    productId: 'Applied Giftcard',
                    productTitle: 'Applied Giftcard',
                },
                priceIncVat: -appliedGiftCard.priceReduction,
                prices: [
                    {
                        priceType: 'price',
                        totalIncVat: -appliedGiftCard.priceReduction,
                        totalExVat: -appliedGiftCard.priceReduction,
                        numberOfTravelers: 1,
                    },
                ],
            },
        ],
    };
}
