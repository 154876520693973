import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductImage from './ProductImage';

export default function ProductImageWidgetRoot(props: {
    productCatalogId: number;
    format?: string;
}) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(props.productCatalogId, locale, config);

    return (
        <ProductImage
            url={productCatalog?.media.image.url}
            size="m"
            format={props.format as any}
            stretchToFit
        />
    );
}
