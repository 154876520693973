import { Skeleton } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { h, render } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { getMediaQuerySizesFromAttributes } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { useAccommodation } from 'src/utils/domain/api/bilberry-hotels-api-client';
import { getIdAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ProductGallery2, {
    ProductGalleryAlign,
} from '../product/product-gallery-2/gallery/ProductGallery2';

const initialDateRange: DateRange<Dayjs> = [dayjs(), dayjs().add(1, 'days')];

export function AccommodationGalleryWidget(props: {
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    id: number;
    numVisibleImages: any;
    align: any;
}): JSX.Element {
    const config = useConfigurations();
    const { locale } = useLocale();

    const { accommodation, isLoading } = useAccommodation(
        props.id.toString(),
        initialDateRange,
        1,
        locale,
        config,
    );

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            {isLoading || !accommodation?.attributes ? (
                <Skeleton width="100%" height="auto" sx={{ aspectRatio: '16/9' }} />
            ) : (
                <ProductGallery2
                    imageList={accommodation?.attributes?.images.galleryImages ?? null}
                    numVisibleImages={props.numVisibleImages}
                    align={props.align}
                />
            )}
        </WidgetWrapper>
    );
}

function getAlignType(align: string) {
    switch (align.toLowerCase()) {
        case 'left':
            return ProductGalleryAlign.LEFT;
        case 'center':
            return ProductGalleryAlign.CENTER;
        case 'right':
            return ProductGalleryAlign.RIGHT;
        default:
            return ProductGalleryAlign.SCALE;
    }
}
class BilberryAccommodationGallery extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const accommodationCatalogId = getIdAttribute(this, false);

        overrideLocaleFromAttribute(this);
        const numVisibleImages = getMediaQuerySizesFromAttributes(this, 1, 2, 3, 3);
        const alignText = this.getAttribute('align');
        const align = alignText ? getAlignType(alignText) : ProductGalleryAlign.SCALE;

        render(
            <AccommodationGalleryWidget
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                id={accommodationCatalogId}
                numVisibleImages={numVisibleImages}
                align={align}
            ></AccommodationGalleryWidget>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-accommodation-gallery', BilberryAccommodationGallery);
}
