import { BilberryPackage } from 'src/types/bilberry-api-types';

export function initialPackagePricesFromTicketOptions(pkg?: BilberryPackage) {
    return (
        pkg?.ticket_options.map(
            ({ ticket_option_id, price, quantity, price_category_id, name }) => ({
                id: ticket_option_id,
                price,
                retail_price: price,
                quantity,
                occupancy: 1,
                price_type: price_category_id.toString(),
                price_category_id: price_category_id,
                name,
                age_from: null,
                age_to: null,
                rates: [],
                commission: -1,
                vat_amount: -1,
            }),
        ) ?? []
    );
}
