import {
    alpha,
    Box,
    Button,
    Grid,
    InputAdornment,
    OutlinedInput,
    Typography,
    useTheme,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useLocale } from 'src/i18n/locale';
import {
    getLocaleNumberFormatNoDecimals,
    formatCurrencyNoDecimalsIfPossible,
} from 'src/i18n/Localization';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { capitalize } from 'src/utils/common/TextUtils';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';

interface ValueProps {
    giftcardValue: number;
    onChange: (value: number) => void;
    showError: boolean;
}

export default function CreateGiftcardValue(props: ValueProps): JSX.Element {
    const { giftcardValue, onChange, showError } = props;
    const { t, locale } = useLocale();
    const theme = useTheme();

    const [currency] = useAtom(currencyAtom);
    const configuration = useConfigurations();

    const priceIncrements =
        configuration.giftCardPriceIncrements ??
        (currency.currency === 'nok' ? [50, 100, 500, 1000] : [10, 25, 50, 100]);

    return (
        <Grid container direction="column" m={`${theme.spacing(2)} 0 ${theme.spacing(6)} 0`}>
            <Grid item>
                <Typography color="textSecondary" variant="body1">
                    <Box fontSize="14px">{capitalize(t.value)}</Box>
                </Typography>
            </Grid>
            <Grid item>
                <OutlinedInput
                    fullWidth
                    error={showError}
                    sx={{
                        backgroundColor: 'white',
                        '& input': {
                            textAlign: 'right',
                        },
                    }}
                    value={
                        giftcardValue > 0
                            ? getLocaleNumberFormatNoDecimals(locale, giftcardValue)
                            : ''
                    }
                    onChange={(e) => handleInputChange(e, onChange)}
                    startAdornment={
                        <InputAdornment position="start">
                            <Typography
                                sx={{
                                    color: 'black',
                                    opacity: 0.4,
                                    backgroundColor: 'white',
                                    '& input': {
                                        textAlign: 'right',
                                    },
                                }}
                            >
                                {currency.currency}
                            </Typography>
                        </InputAdornment>
                    }
                />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                    <Box mt={theme.spacing(1)}>
                        {priceIncrements.map((price) => {
                            return <AddPriceButton price={price} />;
                        })}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
    function AddPriceButton(props: { price: number }): JSX.Element {
        const { price } = props;
        const { locale } = useLocale();
        const [currency] = useAtom(currencyAtom);

        return (
            <Button
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '0',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    '&.Mui-disabled': {
                        border: `1px solid ${theme.palette.action.disabledBackground}`,
                    },
                    '&:hover': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        backgroundColor: alpha(
                            theme.palette.text.primary,
                            theme.palette.action.hoverOpacity,
                        ),
                    },

                    '&:first-child': {
                        borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
                    },

                    '&:last-child': {
                        borderRadius: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
                    },

                    '&:nth-child(n+2)': {
                        '&.MuiButton-outlined': {
                            borderLeft: '0',
                        },
                    },
                    [theme.breakpoints.down('md')]: {
                        '&.MuiButton-root': {
                            minWidth: theme.spacing(6),
                            padding: '5px 10px',
                        },
                    },
                }}
                variant="outlined"
                key={`pre-defined-price-increment-${price}`}
                onClick={() => onChange(giftcardValue + price)}
            >
                <Typography variant="body1">
                    + {formatCurrencyNoDecimalsIfPossible(locale, currency, price)}
                </Typography>
            </Button>
        );
    }
}
function handleInputChange(
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: (value: number) => void,
): void {
    const { value } = e.currentTarget;
    // Removes everything except numbers
    const newValue = value.replace(/[^\d]/g, '');

    if (newValue.length > 12) return;

    if (newValue.length > 0) {
        const numValue = parseInt(newValue);
        if (numValue >= 0) {
            return onChange(numValue);
        }
    }
    return onChange(0);
}
