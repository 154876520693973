import { Box, Button, Grid, Link, Skeleton, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { useLocale } from 'src/i18n/locale';
import { formatCurrencyNoDecimalsIfPossible } from 'src/i18n/Localization';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { formatMinutesToDaysHoursMinutes } from 'src/utils/common/DateHelpers';
import { parseHtmlToMui } from 'src/utils/common/html-parser/parseBilberryHtmlToMui';
import { capitalize } from 'src/utils/common/TextUtils';
import { getPrimaryColors } from 'src/utils/common/theme/getPrimaryColors';
import { getProductCatalogDisplayTitle } from 'src/utils/domain/display-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';
import { useAtom } from 'ximple/atoms';

interface IProps {
    productCatalog: BilberryProductCatalog;
    url: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    scroll: boolean;
    numberOfCards: number;
    numElements: {
        numXs: number;
        numSm: number;
        numMd: number;
        numLg: number;
    };
}

const difficulty = {
    EASY: 'easy',
    MEDIUM: 'medium',
    HARD: 'hard',
};

export default function ProductCard(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const configurations = useConfigurations();
    const { productCatalog, url, scroll, numElements } = props;
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    return (
        <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{
                padding: theme.spacing(1, 2, 3, 0),
                '& > *': {
                    marginBottom: theme.spacing(2),
                },
                [theme.breakpoints.down('sm')]: {
                    minWidth: '100%',
                },
                color: theme.palette.common.black,
                flexGrow: 0,
                flexBasis: (props) => getCardFlexBasisAndWidth(scroll, numElements.numLg),
                maxWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numLg),
                minWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numLg),
                [theme.breakpoints.only('md')]: {
                    flexBasis: (props) => getCardFlexBasisAndWidth(scroll, numElements.numMd),
                    maxWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numMd),
                    minWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numMd),
                },
                [theme.breakpoints.only('sm')]: {
                    flexBasis: (props) => getCardFlexBasisAndWidth(scroll, numElements.numSm),
                    maxWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numSm),
                    minWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numSm),
                },
                [theme.breakpoints.down('sm')]: {
                    flexBasis: (props) => getCardFlexBasisAndWidth(scroll, numElements.numXs),
                    maxWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numXs),
                    minWidth: (props) => getCardFlexBasisAndWidth(scroll, numElements.numXs),
                },
            }}
        >
            <Box position="relative" sx={{ textAlign: 'center' }}>
                {productCatalog.media.image.url ? (
                    <Link href={url}>
                        <ProductImage url={productCatalog.media.image.url} size="s" />
                    </Link>
                ) : (
                    <Skeleton variant="rectangular" width="100%" height="300px" />
                )}
                <Typography
                    position="absolute"
                    color="white"
                    bottom={theme.spacing(2)}
                    left={theme.spacing(2)}
                    p={theme.spacing(0, 0.5)}
                    sx={{
                        backgroundColor: 'rgb(0,0,0, 0.3)',
                    }}
                >
                    {formatMinutesToDaysHoursMinutes(t, productCatalog.duration)}
                </Typography>
                <Typography
                    position="absolute"
                    color="white"
                    bottom={theme.spacing(2)}
                    right={theme.spacing(2)}
                    p={theme.spacing(0, 0.5)}
                    sx={{
                        backgroundColor: 'rgb(0,0,0, 0.3)',
                    }}
                >
                    {capitalize(
                        productCatalog.difficulty === difficulty.EASY
                            ? t.easy
                            : productCatalog.difficulty === difficulty.MEDIUM
                            ? t.medium
                            : productCatalog.difficulty === difficulty.HARD
                            ? t.hard
                            : '',
                    )}
                </Typography>
            </Box>
            <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h5" mb={theme.spacing(1)} mr={theme.spacing(4)}>
                    {getProductCatalogDisplayTitle(productCatalog)}
                </Typography>
                <Box pl={theme.spacing(1)} height="100%">
                    {parseHtmlToMui(productCatalog.web_short_description)}
                </Box>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <Button variant="contained" color="primary" href={url}>
                        {capitalize(
                            getCustomOrDefaultText(
                                configurations.textCustomizations,
                                props.buttonCustomizationKey,
                                locale,
                                t.more_information,
                            ),
                        )}
                    </Button>
                    <Typography
                        variant="h6"
                        color="primary"
                        pl={theme.spacing(1)}
                        sx={{
                            ...getPrimaryColors(
                                theme.palette.primary.main,
                                theme.palette.primary.contrastText,
                                '#FFFFFF',
                                false,
                                false,
                            ),
                        }}
                    >
                        {capitalize(t.from)}{' '}
                        {formatCurrencyNoDecimalsIfPossible(
                            locale,
                            currency,
                            productCatalog.default_prices[0]?.price,
                        ) ?? '-'}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    );
}

function getCardFlexBasisAndWidth(scroll: boolean, numCardsPerRow: number): string {
    if (!scroll) {
        return `${100 / numCardsPerRow}%`;
    }
    return `${100}%`;
}
