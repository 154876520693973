import { createRef, h, render } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { getIdAttribute, getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import { history } from 'src/utils/domain/setup';
import Booking from 'src/widgets/activities/activity-booking/booking/Booking';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

export function ActivityBooking(props: {
    productCatalogId: number;
    mountPoint: HTMLDivElement;
    popoverMountPoint: HTMLDivElement;
    shadowRoot: ShadowRoot;
    expandArrowInside: boolean;
    positionOffscreen: boolean;
}): JSX.Element {
    const [showBookingWidget, setShowBookingWidget] = useState(
        window.location.hash !== '#/checkout',
    );
    const ref = createRef<HTMLDivElement>();
    const [popoverMountPoint, setPopoverMountPoint] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
        if (ref.current) setPopoverMountPoint(ref.current);
    }, []);

    history.listen((event) => {
        setShowBookingWidget(event.location.pathname !== '/checkout');
    });

    return (
        <WidgetWrapper
            mountPoint={props.mountPoint}
            popoverMountPoint={popoverMountPoint ?? props.popoverMountPoint}
            shadowRoot={props.shadowRoot}
        >
            <div ref={ref} style={{ position: 'relative', zIndex: 9999999999 }} />
            {showBookingWidget && (
                <Booking
                    productCatalogId={props.productCatalogId}
                    expandArrowInside={props.expandArrowInside}
                    positionOffscreen={props.positionOffscreen}
                />
            )}
        </WidgetWrapper>
    );
}
class BilberryBooking extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        overrideLocaleFromAttribute(this);

        const expandArrowInside = getYesAttribute(this, 'expand-arrow-inside');

        const positionOffscreen = getYesAttribute(this, 'position-offscreen');

        render(
            <ActivityBooking
                productCatalogId={productCatalogId}
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
                expandArrowInside={expandArrowInside}
                positionOffscreen={positionOffscreen}
            />,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking-widget', BilberryBooking);
}
