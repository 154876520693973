import { BilberryProductCatalog } from 'src/types/bilberry-api-types';

export function calculateProductCatalogMinValues(
    productCatalog?: Pick<BilberryProductCatalog, 'default_prices' | 'from_price' | 'from_age'>,
) {
    const calculated = productCatalog?.default_prices.reduce(
        (acc: { minPrice: number; minAge: number }, cur) => {
            if (cur.price < acc.minPrice) acc.minPrice = cur.price;
            if (cur.age_from && cur.age_from < acc.minAge) acc.minAge = cur.age_from;
            return acc;
        },
        { minPrice: Number.MAX_VALUE, minAge: Number.MAX_VALUE },
    ) ?? { minPrice: 0, minAge: 0 };

    return {
        minPrice: productCatalog?.from_price ?? calculated.minPrice ?? 0,
        minAge: productCatalog?.from_age ?? calculated.minAge ?? 0,
    };
}
