import dayjs from 'dayjs';
import { ICartItem } from 'src/state/cart/ICart';
import {
    BilberryExtra,
    BilberryPackage,
    BilberryProductCatalog,
} from 'src/types/bilberry-api-types';
import {
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
} from './cart/cartUtils';

export function getCartItemDisplayStartDate(item?: ICartItem) {
    const cartItem = item?.item;
    if (cartItem && isICartProductItemType(cartItem)) {
        return cartItem.product.start;
    }
    // TODO: Add correct date based on data from Accommodations (André)
    return dayjs(Date.now());
}

export function getCartItemDisplayTitle(item?: ICartItem) {
    const cartItem = item?.item;
    if (cartItem && isICartProductItemType(cartItem)) {
        return cartItem.product?.web_title || cartItem.product?.title || '';
    } else if (cartItem && isICartMultiDayProductItemType(cartItem)) {
        return cartItem.products[0]?.web_title ?? cartItem.products[0]?.title ?? '';
    } else if (cartItem && isICartPackageItemType(cartItem)) {
        return cartItem.pkg.title ?? '';
    }
    return cartItem?.accommodation.name || '';
}

export function getProductDisplayTitle(product?: { title?: string; web_title?: string }) {
    return product?.web_title || product?.title || '';
}

export function getProductCatalogDisplayTitle(productCatalog?: BilberryProductCatalog | null) {
    return productCatalog?.web_title || productCatalog?.name || '';
}

export function getExtraDisplayTitle(extra?: BilberryExtra) {
    return extra?.web_title || extra?.title || '';
}

export function getPackageDisplayTitle(tourPackage?: BilberryPackage | null) {
    return tourPackage?.title || '';
}
