import { RawTranslations } from 'src/i18n/translations/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'Toon Annuleringsbeleid',
    hide_cancellation_policy: 'Verberg Annuleringsbeleid',
    departure: 'Vertrek',
    price: 'Prijs',
    difficulty: 'Moeilijkheid',
    duration: 'Duur',
    starting_time: 'Starttijd',
    about: 'Over',
    book_now: 'Nu boeken',
    book: 'Boeken',
    buy_as_gift: 'Kopen als cadeau',
    from: 'Van',
    your_booking_reference_is: 'Uw boekingsreferentie is',
    booking_details_in_email: 'U ontvangt binnenkort een e-mail met verdere informatie',
    see_more_activities: 'Bekijk meer activiteiten',
    your_cart: 'Uw winkelwagen',
    when_are_you_going: 'Kies een datum en tijd',
    how_many_are_going: 'Hoeveel gaan er mee?',
    contact_details: 'Contactgegevens',
    traveler: 'Reiziger',
    extras: "Extra's",
    please_select: 'Selecteer alstublieft',
    no_items_in_cart: 'Er zijn geen items in uw winkelwagen',
    payment: 'Betaling',
    receipt: 'Ontvangstbewijs',
    do_you_have_any_comments_or_special_needs: 'Heeft u opmerkingen of speciale behoeften?',
    i_have_read_and_accepted_the: 'Ik heb de',
    privacy_policy: 'Privacybeleid',
    terms_and_conditions: 'Algemene voorwaarden',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    phone_number: 'Telefoonnummer',
    dial_code: 'Netnummer',
    email: 'E-mail',
    please_register: 'Gelieve te registreren',
    main_contact: 'Hoofdcontact',
    additional_information: 'Aanvullende informatie',
    remove_cart_item: 'Verwijder item uit winkelwagen',
    back_to_webshop: 'Verder winkelen',
    edit_activity: 'Activiteit bewerken',
    excl: 'excl.',
    vat: 'BTW',
    total: 'Totaal',
    next: 'Volgende',
    go_and_pay: 'Ga en betaal',
    print: 'Afdrukken',
    close: 'Sluiten',
    checkout: 'Afrekenen',
    summary: 'Samenvatting',
    cancel: 'Annuleren',
    save: 'Opslaan',
    select_time: 'Selecteer tijd',
    selected_time: 'Geselecteerde tijd',
    time: 'Tijd',
    go_back: 'Terug',
    thank_you_for_booking_with_us: 'Bedankt voor uw boeking bij ons!',
    please_fill_in_all_required_information: 'Vul alstublieft alle vereiste informatie in',
    error_occurred_when_creating_reservation:
        'Er is een fout opgetreden bij het maken van de reservering',
    error_occurred_when_creating_giftcard:
        'Er is een fout opgetreden bij het maken van de cadeaubon',
    couldnt_apply_promocode_is_the_code_correct:
        'Kon de promotiecode niet toepassen. Is de code correct?',
    choose_a_country: 'Kies een land',
    select_date: 'Selecteer datum',
    select_travelers: 'Selecteer reizigers',
    hourShort: 'u',
    dayShort: 'd',
    minuteShort: 'm',
    years: 'jaren',
    age: 'Leeftijd',
    more_information: 'Meer informatie',
    same_as_main_contact: 'Hetzelfde als hoofdcontact',
    easy: 'Makkelijk',
    medium: 'Gemiddeld',
    hard: 'Moeilijk',
    please_select_date: 'Selecteer alstublieft een datum',
    please_select_date_and_time: 'Selecteer alstublieft datum en tijd',
    name: 'Naam',
    comment: 'Opmerking',
    submit_request: 'Verzoek indienen',
    any_special_requests_etc: 'Eventuele speciale verzoeken, voedselallergieën...?',
    request_tour_comment: 'Beschrijf uw verzoek',
    thank_you_for_your_request:
        'Bedankt voor uw verzoek, we zullen zo snel mogelijk contact met u opnemen.',
    got_it: 'Begrepen!',
    continue_shopping: 'Verder winkelen',
    continue_travelers: 'Reizigers',
    please_provide_your_name: 'Vul alstublieft uw naam in',
    please_provide_your_email: 'Vul alstublieft een geldig e-mailadres in',
    please_provide_a_description: 'Vul alstublieft een beschrijving in',
    an_error_occurred_when_submitting_your_request:
        'Er is een fout opgetreden bij het indienen van uw verzoek. Probeer het later opnieuw.',
    private_tour: 'Privétour',
    read_more_and_book: 'Lees meer & boek',
    read_more: 'Lees meer',
    prices_from: 'Prijzen vanaf',
    about_the_trip: 'Over de reis',
    prices: 'Prijzen',
    whats_included: 'Wat is inbegrepen',
    any: 'Elk(e)',
    duration_up_to_1_hour: 'Tot 1 uur',
    duration_1_2_hours: '1 - 2 uur',
    duration_2_6_hours: '2 - 6 uur',
    duration_6_12_hours: '6 - 12 uur',
    duration_12_24_hours: '12 - 24 uur',
    duration_1_2_days: '1 - 2 dagen',
    duration_more_than_2_days: 'Meer dan 2 dagen',
    find_activities: 'Vind activiteiten',
    ok: 'OK',
    clear: 'Wissen',
    filterActivities: 'Filter activiteiten',
    hideFilters: 'Verberg filters',
    shopMore: 'Meer winkelen',
    noSearchResults:
        'Sorry, we hebben geen beschikbare activiteiten gevonden die aan uw zoekopdracht voldoen.',
    update: 'Bijwerken',
    select_date_to_view_timeslots: 'Kies een datum om beschikbare tijdsleuven te bekijken',
    your_reservation_timed_out: 'Uw reservering is verlopen. Probeer het opnieuw.',
    aria_calendar_input_field_label: 'Kalenderinvoer. Druk op Enter om te openen.',
    aria_book_now_button: 'Open een venster om deze activiteit te boeken',
    aria_next_image: 'Volgende afbeelding',
    aria_previous_image: 'Vorige afbeelding',
    aria_open_booking_widget: 'Open boekingsvenster',
    aria_close_booking_widget: 'Sluit boekingsvenster',
    aria_open_request_tour_widget: 'Open aanvraag tourvenster',
    aria_close_request_tour_widget: 'Sluit aanvraag tourvenster',
    aria_open_find_activity: 'Open activiteit zoeken',
    aria_close_find_activity: 'Sluit activiteit zoeken',
    request_tour: 'Tour aanvragen',
    giftcard_message: 'bericht',
    giftcard_message_label: 'persoonlijk bericht aan ontvanger (optioneel)',
    recipient_details: 'ontvanger gegevens',
    gift_card: 'cadeaubon',
    value: 'waarde',
    create: 'maken',
    create_your_gift: 'maak uw cadeaubon',
    your_giftcard_reference_is: 'uw referentie is',
    giftcard_voucher_and_receipt:
        'we hebben u een cadeaubon klaar voor afdrukken gestuurd, samen met een ontvangstbewijs naar:',
    giftcard_voucher: 'we hebben de cadeaubon naar u gestuurd:',
    giftcard_and_receipt: 'en een ontvangstbewijs naar u gestuurd:',
    thank_you_for_your_purchase: 'bedankt voor uw aankoop!',
    valid_until: 'geldig tot',
    print_receipt: 'ontvangstbewijs afdrukken',
    select_gift_type: 'selecteer cadeautype',
    please_select_gift_type: 'selecteer alstublieft cadeautype',
    select_gift_print_description: 'ontvang een afdrukbaar PDF-cadeaubon in uw inbox',
    select_gift_email_description: 'stuur een cadeaubon per e-mail rechtstreeks naar de ontvanger',
    enter_giftcard_code: 'Voer een cadeauboncode in',
    enter_promo_code: 'Voer een promotiecode in',
    apply: 'toepassen',
    giftcard_code_error: 'cadeauboncode is niet geldig',
    remaining_value: 'resterende waarde',
    giftcard_code_not_found: 'kon cadeaubon met code niet vinden:',
    purchased_giftcard: {
        self: 'afdrukbare cadeaubon naar u gestuurd',
        recipient: 'afdrukbare cadeaubon naar ontvanger gestuurd',
    },
    contact_information: {
        short: 'contactgegevens',
        long: 'contactinformatie',
    },
    minutes: {
        singular: 'minuut',
        plural: 'minuten',
        long: 'minuut',
    },
    hours: {
        singular: 'uur',
        plural: 'uren',
    },
    days: {
        singular: 'dag',
        plural: 'dagen',
    },
    children: {
        singular: 'kind',
        plural: 'kinderen',
    },
    adult: {
        singular: 'volwassene',
        plural: 'volwassenen',
    },
    card: {
        singular: 'kaart',
        plural: 'kaarten',
    },
    number_of_travelers_exceeded: {
        template: 'Maximale groepsgrootte voor deze dag is <COUNT:persoon:personen>',
    } as any,
    booking_is_closed: {
        template: 'Boeken voor <NAME> is nu gesloten',
    } as any,
    rooms: {
        singular: 'kamer',
        plural: 'kamers',
    },
    person: {
        singular: 'persoon',
        plural: 'personen',
    },
    select_room: 'Selecteer kamer',
    change_room: 'Wijzig kamer',
    less_than: 'minder dan',
    year: {
        singular: 'jaar',
        plural: 'jaren',
    },
    select_age: 'Selecteer leeftijd',
    done: 'Klaar',
    number_of_rooms: 'Aantal kamers',
    select_bed: 'Selecteer bed',
    room_info: 'Kamerinformatie',
    price_one_night_one_guest: {
        template: 'prijs voor <COUNT:nacht:nachten>, <COUNT:gast:gasten>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:nacht:nachten>, <COUNT:gast:gasten>',
    } as any,
    price_nights: {
        template: 'prijs voor <COUNT:nacht:nachten>',
    } as any,
    night: {
        singular: 'nacht',
        plural: 'nachten',
    },
    select_room_for_total_price: 'Selecteer kamers voor de totaalprijs',
    find_rooms: 'Vind kamers',
    check_in_check_out: 'Inchecken en Uitchecken',
    guest_and_rooms: 'Gasten en kamers',
    no_accommodations_found:
        'Het spijt ons, maar we konden geen kamers vinden die aan uw zoekcriteria voldoen. Heeft u overwogen uw zoekparameters aan te passen om beschikbare kamers te vinden?',
    facilities_included: 'Inbegrepen faciliteiten',
    price_per_night: 'Prijs per nacht',
    season: 'Seizoen',

    has_airconditioning: 'Airconditioning',
    has_balcony: 'Balkon',
    has_bicycles: 'Fietsen',
    has_boat: 'Boot',
    has_coffee_maker: 'Koffiezetapparaat',
    has_dishwasher: 'Vaatwasser',
    has_dryer: 'Droger',
    has_fireplace: 'Open haard',
    has_garage: 'Garage',
    has_garden_furniture: 'Tuinmeubelen',
    has_grill: 'Grill',
    has_kitchen: 'Keuken',
    has_minibar: 'Minibar',
    has_mooring_space: 'Aanlegplaats',
    has_pool: 'Zwembad',
    has_roomservice: 'Roomservice',
    has_sauna: 'Sauna',
    has_tv: 'TV',
    has_washing_machine: 'Wasmachine',
    has_wifi: 'WiFi',
    is_breakfast_included: 'Ontbijt inbegrepen',
    is_cancellation_included: 'Mogelijkheid tot annulering',
    is_pets_allowed: 'Huisdieren toegestaan',
    is_smoking_allowed: 'Roken toegestaan',
    is_towels_and_bedlines_included: 'Handdoeken en bedlinnen',
    is_wheelchair_accessible: 'Toegankelijk voor rolstoelgebruikers',
    wifi: 'WiFi',
    breakfast: 'Ontbijt',
    accessible: 'Toegankelijk',
    pets: 'Huisdieren',
    kitchen: 'Keuken',
    smoking: 'Roken',
    unknown: 'Onbekend',
    accommodation_cancellation_policy: '',
    accommodations: 'Accommodaties',
    activities: 'Activiteiten',
    address: 'adres',
    city: 'stad',
    post_code: 'postcode',
    to: 'naar',
    date_range_start: 'start',
    date_range_end: 'einde',
    flag_of: 'vlag van',
    monday: 'maandag',
    tuesday: 'dinsdag',
    wednesday: 'woensdag',
    thursday: 'donderdag',
    friday: 'vrijdag',
    saturday: 'zaterdag',
    sunday: 'zondag',
    today: 'vandaag',
    tomorrow: 'morgen',
    january: 'januari',
    february: 'februari',
    march: 'maart',
    april: 'april',
    may: 'mei',
    june: 'juni',
    july: 'juli',
    august: 'augustus',
    september: 'september',
    october: 'oktober',
    november: 'november',
    december: 'december',
    few_left: 'nog maar enkele beschikbaar',
    available: 'beschikbaar',
    sold_out: 'uitverkocht',
    upcoming_tours_title: 'Aankomende Tours',
    unavailable: 'niet beschikbaar',
    notes_regarding_your_stay: 'Notities met betrekking tot uw verblijf',
    notes_for_accommodation_name_with_guests: {
        template: 'Notities voor <NAME> met <COUNT:gast:gasten>',
    } as any,
    select_price_type: 'Selecteer prijstype',
    finding_available_rooms: 'Beschikbare kamers vinden',
    total_price: 'Totaalprijs',
    of: 'van',
    select_dates: 'Selecteer data',
    please_select_price_type: 'Selecteer alstublieft het prijstype',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: `Er is een minimum van <COUNT> deelnemers vereist om dit product te boeken`,
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Een boekingsbevestiging wordt naar uw e-mailadres gestuurd zodra we uw bestelling hebben verwerkt.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Een bevestiging wordt naar uw e-mailadres gestuurd zodra we uw bestelling hebben verwerkt.',
    add: 'Toevoegen',
    amount: 'Bedrag',
    areYouAMemberOrHaveValueCard: 'Bent u lid of heeft u een waardekaart?',
    areYouSureYouWantToCancelYourBooking: 'Weet u zeker dat u deze boeking wilt annuleren?',
    areYouSureYouWantToCancelYourMembership: 'Weet u zeker dat u uw lidmaatschap wilt annuleren?',
    automaticallyRenewed: 'Automatisch verlengd',
    at: 'Op',
    backToWebsite: 'Terug naar website',
    belayCertification: 'Klimvaardigheidscertificering',
    belayCertificationConsent: 'Ik heb een klimvaardigheidscertificering',
    buyMembership: 'Koop lidmaatschap',
    buyNow: 'Nu kopen',
    cancelled: 'Geannuleerd',
    canBeCancelledFrom: 'Kan geannuleerd worden vanaf',
    choose_payment_method: 'Kies betaalmethode',
    bookNow: 'Nu boeken',
    bookTimeslot: 'Tickets kopen',
    cancellation: 'Annulering',
    choose: 'Kiezen',
    chooseACountry: 'Kies een land',
    chooseCreditsType: 'Kies het type credits',
    chooseDateAndTime: 'Kies datum en tijd',
    chooseNumberOfCredits: 'Kies het aantal credits',
    chooseStartDate: 'Kies de startdatum',
    chooseType: 'Kies het type',
    ticketCode: 'Bestelreferentie',
    completed: 'Voltooid',
    consents: 'Toestemmingen',
    contact: 'Contact',
    contactByEmail: 'Contact per e-mail',
    contactBySms: 'Contact per SMS',
    couldntApplyGiftcardIsTheCodeCorrect: 'Kon de cadeaubon niet toepassen. Is de code correct?',
    couldntApplyPromocodeIsTheCodeCorrect:
        'Kon de promotiecode niet toepassen. Is de code correct?',
    couldntGetCompanyInformation: 'Kon bedrijfsinformatie niet verkrijgen',
    couldntGetPaymentPlan:
        'Kon het betalingsplan niet verkrijgen. Probeer de webpagina te vernieuwen.',
    couldntGetPaymentPlanUsingPreviousPlan:
        'Kon het betalingsplan niet verkrijgen, gebruikmakend van het vorige plan',
    couldntGetValueCards: 'Kon de waardekaarten niet verkrijgen',
    couldntGetProductPleaseTryAgain: 'Kon het product niet verkrijgen, probeer het opnieuw',
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        'Kon de boekingskassa niet openen, niet alle vereiste boekingsinformatie is opgegeven.',
    couldntOpenProfilePleaseSignInFirst: 'Kon het profiel niet openen, log alstublieft eerst in.',
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        'Kon de waardekaartkassa niet openen, niet alle vereiste waardekaartinformatie is opgegeven.',
    country: 'Land',
    createAccount: 'Account aanmaken',
    credit: {
        singular: 'Credit',
        plural: 'Credits',
    },
    credits: 'Credits',
    creditsLeft: 'Credits over',
    creditDiscount: 'Kortingspercentage',
    date: 'Datum',
    delete: 'Verwijderen',
    doYouHaveAnAccount: 'Heeft u een account?',
    doYouHaveValueCards: 'Heeft u een account of waardekaarten?',
    doYouHaveBrattkort: 'Heeft u een Brattkort of klimvaardigheidscertificering?',
    doYouHaveClimbingCertification: 'Heeft u een klimvaardigheidscertificering?',
    edit: 'Bewerken',
    editTimeslot: 'Tijdsleuf bewerken',
    failed: 'Mislukt',
    firstName: 'Voornaam',
    flagOf: 'Vlag van',
    giftcard: 'Cadeaubon',
    goToMyPage: 'Ga naar mijn pagina',
    ifYouWantToDeleteYourProfile: 'Als u uw profiel wilt verwijderen',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'U kunt tickets kopen in de winkel op deze website.',
    yourOrderReferenceIs: 'Uw bestelreferentie is',
    iHaveReadAndAccepted: 'Ik heb de',
    lastName: 'Achternaam',
    location: 'Locatie',
    loggedInAs: 'Ingelogd als',
    login: 'Inloggen',
    logout: 'Uitloggen',
    membershipCard: 'Lidmaatschap',
    membershipNumber: 'Lidmaatschapsnummer',
    membershipSlashCredits: 'Lidmaatschap / Credits',
    monthlyCharge: 'Maandelijkse kosten',
    myAddress: 'Mijn adres',
    myPage: 'Mijn pagina',
    myProfile: 'Mijn profiel',
    nextCreateAccount: 'Volgende: Account aanmaken',
    no: 'Nee',
    noneSelected: 'Niets geselecteerd',
    noSelectedDate: 'Geen datum geselecteerd',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'Niet alle velden zijn ingevuld, vul alstublieft alle velden in.',
    noUserInfoFound: 'Geen gebruikersinformatie gevonden.',
    numberOfPersons: {
        singular: 'persoon',
        plural: 'personen',
        template: '<COUNT:persoon:personen>',
    } as any,
    pay: 'Betalen',
    payWithCreditCard: 'Betalen met creditcard',
    phoneNumber: 'Telefoonnummer',
    pleaseChoose: 'Kies alstublieft',
    pleaseContact: 'Neem alstublieft contact op',
    pleaseFillOutUserInfoInProfile: 'Vul alstublieft uw gebruikersinformatie in uw profiel in.',
    pleaseLogInToBook: 'Log alstublieft in om deze te kopen',
    pleaseLogInToPurchase: 'Log alstublieft in en wij vullen uw contactgegevens voor u in',
    pleaseProvideAllValueCardInformation: 'Geef alstublieft alle waardekaartinformatie op',
    pleaseProvideProperBookingInformation: 'Geef alstublieft geldige informatie op',
    pleaseRegister: 'Registreer alstublieft',
    postalCodeAndLocation: 'Postcode / locatie',
    prefix: 'Voorvoegsel',
    privacyPolicy: 'Privacybeleid',
    problemLoadingData: 'Fout bij het laden van gegevens',
    processing: 'Verwerken',
    purchaseValueCard: 'Aankoop waardekaart',
    readMoreAbout: 'Lees meer over onze',
    readMoreAndBook: 'Lees meer en koop',
    readMoreAndBuy: 'Lees meer en koop',
    refillCredits: 'Vul credits aan',
    returnToWebsite: 'Terug naar website',
    scanTicket: 'Scan ticket',
    signUp: 'Aanmelden',
    subtract: 'Aftrekken',
    shoesAndHarnessAlwaysIncluded: 'Schoenen en harnas altijd inbegrepen.',
    shoesAndHarnessNotIncluded: 'Schoenen en harnas niet inbegrepen.',
    somethingWentWrongUpdatingUserInfo:
        'Er is iets misgegaan bij het bijwerken van gebruikersinformatie.',
    termsOfUse: {
        template: 'Gebruiksvoorwaarden voor <NAME>',
    } as any,
    upcomingBookings: 'Aankomende boekingen',
    updateAddress: 'Adres bijwerken',
    terminate: 'Beëindigen',
    thankYouForBookingWithUs: 'Bedankt voor het boeken bij ons.',
    thePrivacyPolicy: 'Privacybeleid',
    thankYouForShoppingWithUs: 'Bedankt voor het winkelen bij ons.',
    thisGiftcardIsEmpty: 'Deze cadeaubon is leeg.',
    tHours: 'uur',
    totalCredits: 'Totaal credits',
    totalNOK: 'Totaal NOK',
    updateBooking: 'Boeking bijwerken',
    updateProfile: 'Profiel bijwerken',
    useGiftcard: 'Gebruik cadeaubon',
    useGiftcardOrPromocode: 'Gebruik cadeaubon / promotiecode',
    validationNotANumber: 'Moet een nummer zijn',
    validationMaxNumber: {
        template: 'Geen nummers boven <NUM>',
    } as any,
    validationMinNumber: {
        template: 'Geen nummers onder <NUM>',
    } as any,
    validForThePeriod: 'Geldig voor de periode',
    validUntil: 'Geldig tot',
    valueCard: 'Waardekaart',
    visitors: 'Bezoekers',
    withoutVat: 'Zonder BTW',
    yes: 'Ja',
    youDontHaveAnyUpcomingBookings: 'U heeft geen aankomende boekingen.',
    youDontHaveAnyValueCards: 'U heeft geen waardekaarten',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        'U heeft geen geldig lidmaatschap, wilt u er een kopen?',
    youMustSpecifyAValueCard: 'U moet een geldige waardekaart opgeven.',
    yourReservationTimedOut: 'Uw reservering is verlopen.',
    zipcode: 'Postcode',
    payByMembershipCard: 'Betalen met lidmaatschapskaart',
    payWith: 'Betalen met',
    deleteMyProfile: 'Verwijder mijn profiel',
    areYouSureYouWantToDeleteYourProfile: 'Weet u zeker dat u uw profiel wilt verwijderen?',
    choosePaymentOtherMethods: 'Kies andere betaalmethoden',
    dontUseMembershipCardForThisBooking: 'Gebruik geen lidmaatschapskaart voor deze boeking',
    nameNotProvided: 'Naam is niet ingevoerd',
    phoneNumberNotProvided: 'Telefoonnummer is niet ingevoerd',
    emailNotProvided: 'E-mail is niet ingevoerd',
    addressNotProvided: 'Adres is niet ingevoerd',
    promocode: 'Promocode',
    show_usage_terms: 'Toon Gebruiksvoorwaarden',
    hide_usage_terms: 'Verberg Gebruiksvoorwaarden',
    about_the_product: 'over het product',
    successful: 'Succesvol',
    selectProduct: 'Selecteer Product',
    withCreditCard: 'Met creditcard',
    paymentMethod: 'Betalingsmethode',
    noAvailableTimeslots: 'Geen beschikbare tijdvakken',
    orderReference: 'Bestelreferentie',
    iWishToRecieveNewsletters: 'Ik wil nieuwsbrieven ontvangen',
    mySite: 'Mijn Site',
    pleaseConfirm: {
        template: 'Bevestig alstublieft "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Geef alstublieft het aantal deelnemers op',
    pleaseSelectTheDateAndTimeOfYourBooking:
        'Selecteer alstublieft de datum en tijd van uw boeking',
    pleaseSelectNumberOfCredits: 'Selecteer alstublieft het aantal credits',
    pleaseSelectTypeOfCreditcard: 'Selecteer alstublieft het type creditcard',
    pleaseSelectAStartDate: 'Selecteer alstublieft een startdatum',
    pleaseSelectAMembershipType: 'Selecteer alstublieft een lidmaatschapstype',
    contactCustomerServiceToDeleteYourProfile:
        'Neem contact op met de klantenservice om uw profiel te verwijderen',
    inclInMembership: 'Inclusief in lidmaatschap',
    maxAmount: 'Maximaal bedrag',
    checkIn: 'Inchecken',
    checkOut: 'Uitchecken',

    anErrorOccurredDuringPayment: 'Er is een fout opgetreden tijdens de betaling',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'Er is een fout opgetreden, probeer het opnieuw door de pagina te herladen. Foutmelding',
    share: 'Delen',
    copyLink: 'Kopieer link',
    howDoYouWantToShare: 'Hoe wil je delen?',

    shoppingCart: 'Winkelwagen',
    heresMyShoppingCart: 'Dit is mijn winkelwagen',
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        'Er is niet voldoende capaciteit voor de items in deze winkelwagen',

    full: 'Volgeboekt',
    no_available_capacity_for_this_tour: 'Geen beschikbare capaciteit voor deze tour',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        'Eén of meer activiteiten hebben niet voldoende capaciteit',

    pick_a_date: 'Kies een datum',
    package: 'Pakket',
    packages: 'Pakketten',
    customize_activities: 'Pas activiteiten aan',
    check_in_at: 'Inchecken om',
    activity_starts_at: 'Activiteit begint om',
    other_times_are_available: 'Andere tijden zijn beschikbaar',
    press_to_select_other_options: 'Druk om andere opties te selecteren',
    unavailableForSelectedTime: 'Niet beschikbaar op het geselecteerde tijdstip',
    order_status_awaiting_payment: 'Betaling wordt verwerkt',
    order_status_payment_failed: 'Betaling mislukt',
    awaiting_payment_info:
        'Betaling wordt verwerkt... meestal duurt dit niet langer dan enkele minuten.',
    cancel_membership: 'Lidmaatschap annuleren',
    update_subscription_payment_details: 'Betaalgegevens bijwerken',
    payment_failed_info:
        'Er lijkt een probleem te zijn met uw betaling. U kunt proberen de betalingsgegevens bij te werken.',
    retry_subscription_payment: 'Probeer nu opnieuw te betalen',
};

export default translations;
