import { Box, Button, Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import sumBy from 'lodash-es/sumBy';
import { h } from 'preact';
import { route } from 'preact-router';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatNoDecimals } from 'src/i18n/Localization';
import { createAddAccommodationToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { PricedAccommodation } from 'src/types/bilberry-hotels-api-types';
import { capitalize } from 'src/utils/common/TextUtils';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';
import { GuestInfo } from '../MultipleRoomSearch/guestInfo';

type IProps = {
    selectedAccommodations: PricedAccommodation[];
    dateRange: DateRange<Dayjs>;
    guestInfoList: GuestInfo[];
    clearRoomSelections: () => void;
};

export default function HotelTotalPrice(props: IProps): JSX.Element {
    const { selectedAccommodations, guestInfoList, dateRange, clearRoomSelections } = props;
    const { t, locale } = useLocale();
    const theme = useTheme();
    const isRoomSelected = selectedAccommodations.length > 0;
    const [currency] = useAtom(currencyAtom);

    const isMediumPlusScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="50%"
            border="1px solid rgb(206,206,206)"
            borderRadius={`${theme.shape.borderRadius}px`}
            padding={theme.spacing(3, 2)}
            gap={theme.spacing(1)}
            sx={{
                backgroundColor: theme.palette.grey[50],
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            }}
        >
            <Grid
                container
                item
                justifyContent="flex-start"
                alignItems="flex-end"
                width="fit-content"
                color="black"
            >
                <Typography variant="h3">
                    <Box fontWeight="bold" fontSize="32px">
                        {t.total_price}&ensp;
                    </Box>
                </Typography>
                {!isRoomSelected && isMediumPlusScreen && (
                    <Typography variant="body1" fontWeight="normal" fontSize="18px">
                        <Box>{capitalize(t.select_room_for_total_price)}</Box>
                    </Typography>
                )}
            </Grid>
            <Grid
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                }}
            >
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    }}
                >
                    {isRoomSelected && (
                        <Grid container item alignItems="baseline" pr={2} width="auto">
                            <Typography variant="h3">
                                <Box fontSize="34px">
                                    {getLocaleNumberFormatNoDecimals(
                                        locale,
                                        getTotalPrice(
                                            selectedAccommodations,
                                            sumBy(guestInfoList, 'children') +
                                                sumBy(guestInfoList, 'adults'),
                                            dateRange,
                                        ),
                                    )}
                                </Box>
                            </Typography>
                            <Typography variant="body1" fontSize="18px" fontWeight="normal">
                                <Box fontWeight="bold">&nbsp;{currency.currency}</Box>
                            </Typography>
                        </Grid>
                    )}
                    {!isRoomSelected && !isMediumPlusScreen && (
                        <Typography variant="body1" fontSize="18px" fontWeight="normal">
                            <Box>{capitalize(t.select_room_for_total_price)}</Box>
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={(theme) => ({
                            padding: theme.spacing(1.5, 4),
                        })}
                        disabled={!isRoomSelected}
                        onClick={() =>
                            handleBookNow(
                                selectedAccommodations,
                                clearRoomSelections,
                                guestInfoList,
                                dateRange,
                            )
                        }
                    >
                        {t.book_now.toUpperCase()}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

function getTotalPrice(
    selectedAccommodations: PricedAccommodation[],
    numberOfGuests: number,
    dateRange: DateRange<Dayjs>,
): number {
    const prices = selectedAccommodations.map((selectedAccommodation) => {
        const totalDays = dateRange[1]?.diff(dateRange[0], 'day') ?? 1;

        const price = selectedAccommodation.price;
        let totalPrice = price.value;

        // TODO: I believe the below should be correct, but Visbook returns PER_DAY when it is clear from the order_balance of
        // created reservations that the price.value should be used directly. Need to ask Visbook about this
        /*
        totalPrice =
            price.priceType === AccommodationPriceType.PER_PERSON.valueOf()
                ? price.value * numberOfGuests
                : totalPrice;
        totalPrice =
            price.priceType === AccommodationPriceType.PER_DAY.valueOf()
                ? price.value * totalDays
                : totalPrice;
                */
        return totalPrice;
    });

    const totalPrice = prices.reduce((previousValue, currentSum) => previousValue + currentSum, 0);

    return totalPrice;
}

function handleBookNow(
    selectedAccommodations: PricedAccommodation[],
    clearRoomSelections: () => void,
    guestInfoList: GuestInfo[],
    dateRange: DateRange<Dayjs>,
): void {
    const configurations = configurationAtom.subject.value;
    const shouldShowBasketOnBook = !configurations.skipBasketOnBook;

    clearRoomSelections();
    selectedAccommodations.forEach((selectedAccommodation, i) => {
        cartAtom.update(
            createAddAccommodationToCartEvent(
                selectedAccommodation.accommodation,
                selectedAccommodation.price,
                guestInfoList[i].adults + guestInfoList[i].children.length ?? 0,
                dateRange[0]!.toDate(),
                dateRange[1]!.toDate(),
            ),
        );
    });
    if (shouldShowBasketOnBook) {
        showBasketAtom.update({
            visible: true,
        });
    } else route('/checkout');
}
