import { Grid, Typography, useTheme } from '@mui/material';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import WarningIcon from '@mui/icons-material/WarningRounded';

export function WarningMessage(props: { label?: string }) {
    const theme = useTheme();
    const customizations = useCustomizations();

    if (!props.label) return null;

    return (
        <Grid textAlign="center" display="flex" justifyContent="center">
            <WarningIcon
                sx={{
                    color: customizations.bookingWidgetColorContrast,
                }}
            ></WarningIcon>
            <Typography
                color={{ color: customizations.bookingWidgetColorContrast }}
                ml={theme.spacing(1)}
            >
                {props.label}
            </Typography>
        </Grid>
    );
}
