import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useLocale } from 'src/i18n/locale';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { DateRangePicker } from '../../common/date-range-picker/DateRangePicker';

type IProps = {
    dateRange: DateRange<Dayjs>;
    onDateRangeChanged: (newRange: DateRange<Dayjs>) => void;
    minDate?: Dayjs;
};

export default function HotelCalendar(props: IProps): JSX.Element {
    const customizations = useCustomizations();
    const { t } = useLocale();
    return (
        <DateRangePicker
            dateRange={props.dateRange}
            onSelectDateRange={props.onDateRangeChanged}
            label={capitalize(t.check_in_check_out)}
            labelColor={customizations.accommodationSearchFormColorContrast}
            minDate={props.minDate}
            required
            dateRangeVariant="nights"
        />
    );
}
