import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { capitalize } from 'src/utils/common/TextUtils';

interface IProps {
    day: Dayjs;
    onDayChange: (param: Dayjs) => void;
    days: Dayjs[];
    useMonths: boolean;
}

export default function UpcomingDaySelector(props: IProps) {
    const { day, onDayChange, days, useMonths } = props;
    const { t } = useLocale();

    const handleChange = (event: any, newDay: Dayjs) => {
        if (newDay) {
            onDayChange(newDay);
        }
    };
    const theme = useTheme();

    const weekdays: string[] = [
        capitalize(t.monday),
        capitalize(t.tuesday),
        capitalize(t.wednesday),
        capitalize(t.thursday),
        capitalize(t.friday),
        capitalize(t.saturday),
        capitalize(t.sunday),
    ];
    const months: string[] = [
        capitalize(t.january),
        capitalize(t.february),
        capitalize(t.march),
        capitalize(t.april),
        capitalize(t.may),
        capitalize(t.june),
        capitalize(t.july),
        capitalize(t.august),
        capitalize(t.september),
        capitalize(t.october),
        capitalize(t.november),
        capitalize(t.december),
    ];

    return (
        <Box
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
            <ToggleButtonGroup
                sx={{ display: 'flex', maxWidth: '100%' }}
                value={day}
                exclusive
                onChange={handleChange}
            >
                {days.map((currDay) => (
                    <ToggleButton
                        sx={{
                            whiteSpace: 'nowrap',
                            margin: theme.spacing(1),
                            '&:first-child': {
                                marginLeft: 0,
                            },
                            '&:last-child': {
                                marginRight: 0,
                            },
                        }}
                        key={currDay.millisecond()}
                        value={currDay}
                    >
                        {useMonths ? months[currDay.month()] : weekdayToString(currDay, weekdays)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
}

function weekdayToString(day: Dayjs, weekdays: string[]): string {
    const { t } = useLocale();
    const now = dayjs();
    if (day.isSame(now, 'day')) return t.today;
    if (day.isSame(now.add(1, 'day'), 'day')) return t.tomorrow;
    return weekdays[day.weekday()];
}
