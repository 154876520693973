import dayjs from 'dayjs';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { localeAtom } from 'src/i18n/locale';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { formatDate } from 'src/utils/common/DateHelpers';

export function groupToursByProduct(tours: BilberryProduct[], productKey: keyof BilberryProduct) {
    const toursByProduct = groupBy(tours, productKey);
    const toursGrouped = mapValues(toursByProduct, (tours) => {
        const groupedByDate = Object.values(
            groupBy(tours, (tour) =>
                formatDate(dayjs(tour.start), localeAtom.subject.value.locale, 'YYYY-MM-DD'),
            ),
        );
        return groupedByDate.map((group) => group.sort((a, b) => dayjs(a.start).diff(b.start)));
    });

    const sorted = Object.values(toursGrouped)
        .flat()
        .sort((a, b) => dayjs(a[0].start).diff(dayjs(b[0].start)));

    return sorted;
}
