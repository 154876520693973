import { Box, Button, darken, Grid, Popover, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { createRef } from 'preact';
import { StateUpdater, useContext } from 'preact/hooks';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from 'src/i18n/locale';
import { MountPointContext } from 'src/utils/common/mountPoint';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { zIndex } from 'src/utils/common/theme/Theme';
import { AvailabilityProductType } from 'src/utils/domain/TimeSlotType';
import BilberryStaticCalendar from '../BilberryStaticCalendar';

interface IProps {
    minDate: dayjs.Dayjs;
    displayDate: dayjs.Dayjs | null;
    anchorEl: HTMLDivElement | HTMLInputElement | null;
    updateDisplayDate: (date: Dayjs | null) => void;
    setAnchorEl: StateUpdater<HTMLDivElement | HTMLInputElement | null>;
    id?: string;
    availabilityData: {
        [id: string]: AvailabilityProductType<any>[] | AvailabilityProductType<any>;
    };
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: dayjs.Dayjs | null;
        endDay: dayjs.Dayjs | null;
    }) => void;
}

export default function PackageCalendarPopover(props: IProps) {
    const {
        minDate,
        displayDate,
        anchorEl,
        updateDisplayDate,
        setAnchorEl,
        availabilityData,
        setAvailabilitySearchPeriod,
        id = 'bilberry-calendar-input',
    } = props;
    const customizations = useCustomizations();
    const mountPoint = useContext(MountPointContext);
    const popoverRef = createRef<HTMLDivElement>();
    const theme = useTheme();
    const { t } = useLocale();
    const threeYearsAhead = minDate.add(5, 'year');
    const trap = useFocusTrap(popoverRef, !!anchorEl, `#${id}`, mountPoint.shadowRoot);

    const onSelectDate = (date: Dayjs | null) => {
        updateDisplayDate(date);
    };

    const updateAvailabilitySearchPeriod = (date: Dayjs | null) => {
        if (!date) return;

        setAvailabilitySearchPeriod({
            startDay: date.subtract(1, 'month').startOf('month'),
            endDay: date.add(1, 'month').startOf('month').add(1, 'day'),
        });
    };

    const onClose = () => {
        if (trap) trap.deactivate(200);
        setAnchorEl(null);
    };

    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            sx={{
                zIndex: `${zIndex.alwaysVisible} !important` as any,

                '& > .MuiPaper-root': {
                    maxWidth: 325,
                },
            }}
            container={mountPoint.popover}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onBackdropClick={onClose}
            onClose={onClose}
            aria-modal={true}
            disableEnforceFocus={true}
            ref={popoverRef}
        >
            <BilberryStaticCalendar
                date={displayDate}
                onChange={onSelectDate}
                minDate={minDate}
                maxDate={threeYearsAhead}
                onMonthChange={updateAvailabilitySearchPeriod}
                onYearChange={updateAvailabilitySearchPeriod}
                loading={false}
                shouldDisableDate={(date) => isDateUnavailable(date, availabilityData)}
            />

            <Box
                padding={theme.spacing(1, 0)}
                sx={{
                    '& button:hover': {
                        backgroundColor: () =>
                            customizations.primaryButtonStyle === 'contained'
                                ? darken(theme.palette.primary.main, 0.2)
                                : 'rgba(0, 0, 0, 30%)',
                    },
                }}
            >
                <Grid container justifyContent="center">
                    <Button
                        variant={customizations.primaryButtonStyle}
                        color="primary"
                        disabled={!displayDate}
                        onClick={() => onOkClicked(onClose)}
                        role={capitalize(t.select_date)}
                    >
                        {t.ok.toUpperCase()}
                    </Button>
                </Grid>
            </Box>
        </Popover>
    );
}

function onOkClicked(onClose: () => void) {
    onClose();
}

export function isDateUnavailable(
    date: Dayjs | null,
    availabilityData: { [x: string]: any },
): boolean {
    if (!date) return false;
    const data = availabilityData[date.format('YYYY-MM-DD')];

    if (!data) return true;
    return !data.available;
}
