import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { h } from 'preact';
import { Button, Link as MuiLink } from '@mui/material';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useLocale } from 'src/i18n/locale';
import { history } from 'src/utils/domain/setup';

type BackButtonProps = {
    onClick?: () => void | false;
    label?: string;
};

export default function BackButton({ onClick, label }: BackButtonProps) {
    const { t } = useLocale();
    const customizations = useCustomizations();

    return (
        <MuiLink
            component={Button}
            onClick={() => {
                if(onClick && (onClick()) === false)
                    return;

                history.back();
            }}
            onKeyDown={(e: KeyboardEvent) => {
              if(e.key === 'Enter') {
                  if(onClick && (onClick()) === false)
                      return;

                  history.back()
              }
            }}
            underline="hover"
            sx={{
                color: customizations.linkColor,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1.75,
                width: 'fit-content',
                textTransform: 'none',
                padding: '0px',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: customizations.linkColor,
                }
            }}
            variant='body1'
        >
            <ChevronLeftRoundedIcon sx={{ color: customizations.linkColor, marginRight: 0.5 }} />
            {label ?? t.backToWebsite}
        </MuiLink>
    );
}
