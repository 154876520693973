import { TodayRounded } from '@mui/icons-material';
import {
    alpha,
    darken,
    FormControl,
    InputAdornment,
    InputLabel,
    TextField,
    useTheme,
} from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { createRef } from 'preact';
import { StateUpdater, useMemo } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { formatDate } from 'src/utils/common/DateHelpers';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize, deselectTextInput } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';

type IProps = {
    labelColor: string | undefined;
    label: string;
    showError: boolean;
    required: boolean;
    setAnchorEl: StateUpdater<HTMLInputElement | HTMLDivElement | null>;
    dateRange: DateRange<Dayjs>;
    variant?: 'filled' | 'outlined';
};

export default function DateRangePickerInput(props: IProps) {
    const {
        labelColor,
        label,
        showError,
        required,
        setAnchorEl,
        dateRange,
        variant = 'filled',
    } = props;
    const refocusElement = createRef<HTMLInputElement>();
    const customizations = useCustomizations();
    const theme = useTheme();
    const { t, locale } = useLocale();

    const selectedDateText = useMemo(
        () => getSelectedDateText(dateRange, locale),
        [dateRange, locale],
    );
    return (
        <FormControl fullWidth>
            <InputLabel
                id="date-range-picker-label"
                sx={{
                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                    color: labelColor,
                    whiteSpace: 'nowrap',
                }}
            >
                {label}
            </InputLabel>
            <TextField
                id="bilberry-date-range-picker-input"
                inputRef={refocusElement}
                error={showError}
                helperText={showError ? capitalize(t.please_fill_in_all_required_information) : ''}
                required={required}
                sx={{
                    '& .MuiFilledInput-root.Mui-focused': {
                        borderColor: alpha(customizations.secondaryColor, 0.5),
                        boxShadow: `${darken(
                            theme.palette.primary.contrastText,
                            0.25,
                        )} 0 0 0 0.2rem`,
                    },
                    '& .MuiFilledInput-input': {
                        cursor: 'pointer',
                    },
                    '& .MuiInputBase-root': {
                        cursor: 'pointer',
                        backgroundColor: theme.palette.common.white,
                    },
                }}
                onClick={(e: MouseEvent) => {
                    if (
                        e.currentTarget instanceof HTMLInputElement ||
                        e.currentTarget instanceof HTMLDivElement
                    )
                        setAnchorEl(e.currentTarget);
                }}
                onKeyDown={(e: KeyboardEvent) => {
                    if (
                        (e.currentTarget instanceof HTMLInputElement ||
                            e.currentTarget instanceof HTMLDivElement) &&
                        e.key === 'Enter'
                    )
                        setAnchorEl(e.currentTarget);
                }}
                onFocus={deselectTextInput}
                variant={variant}
                margin="dense"
                size="small"
                value={selectedDateText.length > 0 ? selectedDateText : capitalize(t.select_date)}
                InputProps={{
                    readOnly: true,

                    endAdornment: (
                        <InputAdornment position="end">
                            <TodayRounded
                                sx={[
                                    {
                                        color: customizations.accommodationSearchFormInputTextColor,
                                    },
                                    showError && {
                                        color: theme.palette.error.main,
                                        '& > *': {
                                            color: theme.palette.error.main,
                                        },
                                    },
                                ]}
                            />
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    'aria-labelledby': 'date-range-picker-label',
                }}
            />
        </FormControl>
    );
}

function getSelectedDateText(dateRange: DateRange<Dayjs>, locale: string) {
    const [start, end] = dateRange;
    if (!start) return '';

    if (!end || end.isSame(start, 'date')) return formatDate(start, locale, 'll');

    return `${formatDate(start, locale, 'll')} - ${formatDate(end, locale, 'll')}`;
}
