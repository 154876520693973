import { Box, Modal, ModalProps } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { MountPointContext } from 'src/utils/common/mountPoint';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAccommodation } from 'src/utils/domain/api/bilberry-hotels-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import AccommodationOverview from '../../accommodation-overview/AccommodationOverview';

type AccommodationOverviewModalProps = {
    accommodation: BilberryAccommodation;
    onCloseRoomInfoOverlay: () => void;
    dateRange: DateRange<Dayjs>;
    guests: number;
};

const ModalFixedType = Modal as (
    props: Omit<ModalProps, 'children'> & { children: JSX.Element },
) => JSX.Element;

export default function AccommodationOverviewModal(props: AccommodationOverviewModalProps) {
    const customizations = useCustomizations();
    const config = useConfigurations();
    const { locale } = useLocale();
    const mountPointContext = useContext(MountPointContext);

    const { accommodation } = useAccommodation(
        props.accommodation.id.toString(),
        props.dateRange,
        props.guests,
        locale,
        config,
    );

    return (
        <ModalFixedType
            open={true}
            container={mountPointContext.popover}
            onBackdropClick={props.onCloseRoomInfoOverlay}
            onClose={props.onCloseRoomInfoOverlay}
        >
            <Box
                sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    boxSizing: 'border-box',
                    padding: theme.spacing(2.5),
                    overflowY: 'auto',
                    background: theme.palette.common.white,
                    [theme.breakpoints.up('sm')]: {
                        width: '100%',
                        height: '80vh',
                        top: '10vh',
                        maxWidth: '960px',
                        margin: '0 auto',
                        padding: theme.spacing(4),
                        paddingTop: theme.spacing(4),
                        borderRadius: `${customizations.borderRadius}px`,
                    },
                })}
            >
                <AccommodationOverview
                    accommodation={accommodation ?? props.accommodation}
                    onClickClose={props.onCloseRoomInfoOverlay}
                    dateRange={props.dateRange}
                    guests={props.guests}
                />
            </Box>
        </ModalFixedType>
    );
}
