import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { SmallerTextInline } from 'src/components/common/smaller-text-inline/SmallerTextInline';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatNoDecimals } from 'src/i18n/Localization';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { CurrencyType } from 'src/types/booking';
import { Currency } from 'src/types/currency';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAtom } from 'ximple/atoms';

interface IProps {
    title: string;
    toggleButton?: JSX.Element;
    onClick?(): void;
    quantities?: Array<{ price: number; quantity: number }>;
    fromPrice?: number | null;
    currencyType?: CurrencyType;
    justifyTitle?: 'space-between' | 'center';
    addMarginRightToHeader?: boolean;
    expandArrowInside?: boolean;
}

export function BookingHeader(props: IProps): JSX.Element {
    const {
        quantities,
        title,
        toggleButton,
        onClick,
        justifyTitle = 'space-between',
        fromPrice,
        currencyType = 'money',
        addMarginRightToHeader,
        expandArrowInside = false,
    } = props;
    const customizations = useCustomizations();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    const totalPrice = useTotalPrice(quantities);

    return (
        <Fragment>
            <Grid
                container
                justifyContent={justifyTitle}
                pr={addMarginRightToHeader ? `${theme.spacing(8)} !important` : ''}
                padding={theme.spacing(expandArrowInside ? 4 : 3, 4, 0, 4)}
                mt={0}
                sx={{
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                <Typography variant="h4" color={customizations.bookingWidgetHeaderColor}>
                    {title}
                </Typography>
                {quantities && (
                    <Grid item>
                        <Grid container alignItems="flex-end">
                            <Typography
                                variant="h4"
                                color={customizations.bookingWidgetHeaderColor}
                            >
                                <CurrencyCost
                                    fromPrice={fromPrice}
                                    totalPrice={totalPrice}
                                    currencyType={currencyType}
                                    currency={currency}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {toggleButton}
        </Fragment>
    );
}

function useTotalPrice(
    quantities?: {
        price: number;
        quantity: number;
    }[],
) {
    return useMemo(
        () =>
            quantities
                ? quantities.reduce(
                      (acc: number, val) => acc + val.price * val.quantity,

                      0,
                  )
                : 0,
        [quantities],
    );
}

type CurrencyCostProps = {
    fromPrice: number | null | undefined;
    totalPrice: number;
    currencyType: CurrencyType;
    currency: Currency;
};

const CurrencyCost = ({ fromPrice, totalPrice, currencyType, currency }: CurrencyCostProps) => {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const price = getLocaleNumberFormatNoDecimals(locale, fromPrice ? fromPrice : totalPrice);
    if (currencyType === 'money') {
        return (
            <Fragment>
                <SmallerTextInline size="60%">
                    {fromPrice ? t.from : ''} {currency.currency}&nbsp;&nbsp;
                </SmallerTextInline>
                <Box component="span" fontWeight={customizations.boldFontWeight}>
                    {price}
                </Box>
            </Fragment>
        );
    } else if (currencyType === 'credits')
        return (
            <Fragment>
                <Box component="span" fontWeight={customizations.boldFontWeight}>
                    {price}
                </Box>
                <SmallerTextInline size="60%">
                    &nbsp;&nbsp;{t.credit.num(totalPrice)}
                </SmallerTextInline>
            </Fragment>
        );
    return (
        <Fragment>
            <SmallerTextInline size="60%">{t.inclInMembership}</SmallerTextInline>
        </Fragment>
    );
};
