import { Grid } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { h } from 'preact';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import AccommodationProduct from 'src/widgets/activities/accommodation-product/AccommodationProductOverview';
import AccommodationOverviewHero from './subcomponents/AccommodationOverviewHero';

type AccommodationOverviewProps = {
    accommodation: BilberryAccommodation;
    dateRange: DateRange<Dayjs>;
    guests?: number;
    onClickClose?: () => void;
};

export default function AccommodationOverview({
    accommodation,
    dateRange,
    guests,
    onClickClose,
}: AccommodationOverviewProps) {
    return (
        <Grid>
            <AccommodationOverviewHero accommodation={accommodation} onClickClose={onClickClose} />
            <AccommodationProduct
                accommodation={accommodation}
                dateRange={dateRange}
                guests={guests}
            />
        </Grid>
    );
}
