import {
    defaultDisplaySnackbarAtomState,
    displaySnackbarAtom,
} from 'src/state/ui/globalSnackbar.atom';

export type ErrorSeverity = 'error' | 'warning' | 'info' | 'success';

export function showError(message: string, severity?: ErrorSeverity) {
    displaySnackbarAtom.update({ visible: true, message, severity: severity ?? 'error' });
}

export function hideError() {
    displaySnackbarAtom.update(defaultDisplaySnackbarAtomState);
}
