import produce from 'immer';
import { getCompanyConfig } from 'src/utils/domain/api/membership-api';
import {
    MembershipCompany,
    MembershipSite,
    MembershipValueCardProduct,
} from 'src/types/membership-api-types';
import { atom } from 'ximple';
import { Action } from './reducers';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import dayjs from 'dayjs';

export type Company = MembershipCompany & { currentSite: MembershipSite };

export type CompanyAction = 'UPDATE_COMPANY';

export async function configureCompany() {
    const config = configurationAtom.subject.value;
    const data = await getCompanyConfig(config.companyKey ?? '', config.siteKey ?? '');
}

export const companyReducer = produce(
    (draft: Partial<Company>, action: Action<CompanyAction, Company>) => {
        const config = configurationAtom.subject.value;

        switch (action.type) {
            case 'UPDATE_COMPANY':
                const currentSite =
                    action.value.sites.find(
                        (site: MembershipSite) => site.key === config.siteKey,
                    ) ?? undefined;

                action.value.company.valueCardProducts = findValueCardProductsAvailableForPurchase(
                    action.value.company.valueCardProducts,
                    config.siteKey,
                );

                return { ...action.value, currentSite } as Company;
            default:
                break;
        }
    },
);

export const companyAtom = atom<Partial<Company>, Action<CompanyAction>>({
    initialValue: {
        company: undefined,
        sites: [],
        currentSite: undefined,
    },
    update: companyReducer,
});

export function isCompany(partial: Partial<Company>): partial is Company {
    return !!partial.company && !!partial.currentSite;
}

function checkIfSiteIsAllowed(valueCardProduct: MembershipValueCardProduct, siteKey: string) {
    return (
        valueCardProduct.configuration?.restrictedToSiteKeys.length === 0 ||
        valueCardProduct.configuration?.restrictedToSiteKeys.includes(siteKey)
    );
}

function checkIfSiteIsAllowedForPurchase(
    valueCardProduct: MembershipValueCardProduct,
    siteKey: string,
) {
    return (
        valueCardProduct.configuration?.purchaseRestrictedToSiteKeys.length === 0 ||
        valueCardProduct.configuration?.purchaseRestrictedToSiteKeys.includes(siteKey)
    );
}

function checkIfAvailableForPurchaseNow(availableFrom: string | null, availableTo: string | null) {
    const now = dayjs();

    if (availableFrom && now.isBefore(availableFrom)) return false;
    if (availableTo && now.isAfter(availableTo)) return false;

    return true;
}

function findValueCardProductsAvailableForPurchase(
    valueCardProducts?: MembershipValueCardProduct[],
    companyKey?: string,
) {
    if (!companyKey || !valueCardProducts) return [];

    return valueCardProducts.filter((v) => {
        const isSiteAllowed = checkIfSiteIsAllowed(v, companyKey);
        const isSiteAllowedForPurchase = checkIfSiteIsAllowedForPurchase(v, companyKey);
        const isAvailableForPurchaseNow = checkIfAvailableForPurchaseNow(
            v.availableFrom,
            v.availableTo,
        );

        return isSiteAllowed && isSiteAllowedForPurchase && isAvailableForPurchaseNow;
    });
}
