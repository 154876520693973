import { localeAtom } from 'src/i18n/locale';
import { updateCurrencyAtomFromCode } from 'src/state/currency/currency.atom';
import { BilberrySettings } from 'src/types/bilberry-api-types';
import { getBilberrySettings } from 'src/utils/domain/api/bilberry-api-client';
import { errorLog } from 'src/utils/common/Logger';
import { configurationAtom } from '../widgetsConfiguration';

/**
 * Runs initialization code dependent on BilberrySettings.
 */
export async function initFromSettings() {
    try {
        const settings = await getBilberrySettings(
            localeAtom.subject.value.locale,
            BilberryWidgetsGlobal,
        );

        if (settings) {
            initCurrencyFromBilberrySettings(settings);
            initTimezoneFromBilberrySettings(settings);
        }
    } catch (e: any) {
        errorLog(e);
    }
}

function initCurrencyFromBilberrySettings(settings: BilberrySettings) {
    const code = settings.currency ?? 'NOK';
    updateCurrencyAtomFromCode(code);
}

function initTimezoneFromBilberrySettings(settings: BilberrySettings) {
    const timezone = settings.timezone ?? 'Europe/Oslo';
    configurationAtom.update({
        ...configurationAtom.subject.value,
        timezone,
    });
}
