import { h, render } from 'preact';
import { UpcomingToursContainer } from 'src/components/domain/upcoming-tours/UpcomingTours';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { getCsvAttribute, getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class UpcomingToursElement extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);
        const productCatalogIds = getCsvAttribute(this, 'product-catalog-ids', false).map(Number);

        const numberOfDays = this.hasAttribute('number-of-days')
            ? parseInt(this.getAttribute('number-of-days')!)
            : 7;

        const fewLeft = this.hasAttribute('few-left')
            ? parseInt(this.getAttribute('few-left')!)
            : 5;

        const groupBy = this.getAttribute('group-by');
        const titleTextCustomizationKey = this.getAttribute(
            'title-text-customization-key',
        ) as keyof UserTextCustomizations;

        const disableTitle = getYesAttribute(this, 'disable-title');
        const disableGrouping = getYesAttribute(this, 'disable-grouping');

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <UpcomingToursContainer
                    productCatalogIds={productCatalogIds}
                    numberOfDaysToDisplay={numberOfDays}
                    fewLeft={fewLeft}
                    useMonths={groupBy === 'month' ? true : false}
                    titleTextCustomizationKey={titleTextCustomizationKey ?? undefined}
                    disableTitle={disableTitle}
                    disableTogglebuttons={disableGrouping}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-upcoming-tours', UpcomingToursElement);
}
