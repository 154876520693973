import {
    Box,
    capitalize,
    FormControl,
    Grid,
    InputLabel,
    Typography,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency } from 'src/i18n/Localization';
import {
    createAddMultiDayProductToCartEvent,
    createAddPackageToCartEvent,
    createAddProductToCartEvent,
} from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import {
    BilberryPriceQuantity,
    ICartExtra,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { formatDate } from 'src/utils/common/DateHelpers';
import { isICartPackageItemType, isICartProductItemType } from 'src/utils/domain/cart/cartUtils';
import { getExtraDisplayTitle } from 'src/utils/domain/display-helper';
import QuantityPicker from 'src/components/common/quantity-picker/QuantityPicker';
import { Stack } from '@mui/system';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { useAtom } from 'ximple/atoms';

interface ChangeQuantity {
    item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem;
    extra: ICartExtra;
    quantity: BilberryPriceQuantity;
    newQuantity: number;
}

function changeQuantity({ item, extra, quantity, newQuantity }: ChangeQuantity) {
    const updated = {
        ...item,
        extras: item.extras.map((e) => {
            if (
                e.extra.id === extra.extra.id &&
                e.extra.ticketOptionId === extra.extra.ticketOptionId
            ) {
                return {
                    ...e,
                    quantities: e.quantities.map((q) => {
                        if (q.id === quantity.id) {
                            return {
                                ...q,
                                quantity: newQuantity,
                            };
                        }
                        return q;
                    }),
                };
            }
            return e;
        }),
    };

    if (isICartProductItemType(updated))
        cartAtom.update(
            createAddProductToCartEvent(updated.product, updated.quantities, updated.extras),
        );
    else if (isICartPackageItemType(updated))
        cartAtom.update(
            createAddPackageToCartEvent(
                updated.pkg,
                updated.vats,
                updated.quantities,
                updated.selectedProducts,
                updated.start,
                updated.end,
                updated.extras,
            ),
        );
    else
        cartAtom.update(
            createAddMultiDayProductToCartEvent(
                updated.products,
                updated.quantities,
                updated.extras,
                updated.dateRangeVariant,
            ),
        );
}

function findMaxQuantityForExtra(extra: ICartExtra, id: number) {
    if (extra.quantities.length === 1) return extra.extra.capacity;
    return (
        extra.extra.capacity -
        extra.quantities.filter((q) => q.id !== id).reduce((acc, cur) => acc + cur.quantity, 0)
    );
}

type ExtraItemRowProps = {
    item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem;
    extrasGroup: ICartExtra[];
    extra: ICartExtra;
};

export default function ExtraItemRow({ item, extra }: ExtraItemRowProps): JSX.Element {
    const { locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const currentStart = extra.extra.show_time ? extra.extra.start : null;

    const maxReached = useMemo(
        () => extra.quantities.reduce((acc, cur) => acc + cur.quantity, 0) === extra.extra.capacity,
        [extra],
    );

    return (
        <div key={extra.extra.id}>
            <Typography mt={2} color="secondary" variant="h6">
                {getExtraDisplayTitle(extra.extra)}
            </Typography>
            {currentStart && (
                <Typography mb={1} color="secondary" variant="h6" sx={{ fontSize: '0.9rem' }}>
                    {formatDate(dayjs(currentStart), locale, 'lll')}
                </Typography>
            )}
            <Stack gap={1}>
                {extra.quantities.map((price) => (
                    <Grid key={price.id} flex={1}>
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{
                                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                                    color: theme.palette.secondary.main,
                                }}
                            >
                                {capitalize(
                                    `${price.name} ${formatCurrency(
                                        locale,
                                        currency,
                                        price.price,
                                    )}`,
                                )}
                            </InputLabel>
                            <Box
                                backgroundColor={theme.palette.grey[50]}
                                borderRadius={theme.shape.borderRadius / 2}
                                p={0.5}
                                ml={-1}
                            >
                                <QuantityPicker
                                    value={price.quantity}
                                    name={price.name}
                                    onChange={(value: number) => {
                                        changeQuantity({
                                            item,
                                            extra,
                                            quantity: price,
                                            newQuantity: value,
                                        });
                                    }}
                                    maxReached={maxReached}
                                    maxValue={findMaxQuantityForExtra(extra, price.id)}
                                />
                            </Box>
                        </FormControl>
                    </Grid>
                ))}
            </Stack>
        </div>
    );
}
