import { Box, Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { ICartMultiDayProductItem } from 'src/state/cart/ICart';
import SummaryExtras from './SummaryExtras';

interface IProps {
    cartMultiDayProductItem: ICartMultiDayProductItem;
    invertedColorsClass?: Record<string, unknown>;
}

export default function SummaryMultiDayProductInformation(props: IProps): JSX.Element {
    const { locale, t } = useLocale();
    const theme = useTheme();

    const productPrices = props.cartMultiDayProductItem.products.reduce((acc, cur) => {
        cur.prices.forEach((price) => {
            if (!acc[price.price_type]) acc[price.price_type] = 0;
            acc[price.price_type] += price.price;
        });
        return acc;
    }, {} as Record<string, number>);

    const lengthString =
        props.cartMultiDayProductItem.dateRangeVariant === 'nights'
            ? t.night.num(props.cartMultiDayProductItem.products.length)
            : props.cartMultiDayProductItem.dateRangeVariant === 'days'
                ? t.days.num(props.cartMultiDayProductItem.products.length)
                : '';

    return (
        <Box m={theme.spacing(0.5, 0, 2, 0)}>
            {props.cartMultiDayProductItem.quantities.map((quantity) => {
                return (
                    quantity.quantity > 0 && (
                        <Grid
                            key={`summary-activity-info-${quantity.id}`}
                            container
                            direction="row"
                            justifyContent="space-between"
                            wrap="nowrap"
                        >
                            <Typography
                                align="left"
                                color="textSecondary"
                                sx={props.invertedColorsClass}
                            >
                                {quantity.quantity}x {quantity.name} (
                                {props.cartMultiDayProductItem.products.length + ' ' + lengthString}
                                )
                            </Typography>
                            <Typography
                                align="right"
                                color="textSecondary"
                                sx={props.invertedColorsClass}
                            >
                                {getLocaleNumberFormatTwoDecimals(
                                    locale,
                                    quantity.quantity * productPrices[quantity.price_type],
                                )}
                            </Typography>
                        </Grid>
                    )
                );
            })}
            <SummaryExtras cartItem={props.cartMultiDayProductItem} {...props} />
        </Box>
    );
}
