import { Grid, Paper, useTheme } from '@mui/material';
import Title from 'src/components/common/timeslots-typography/Title';
import { useLocale } from 'src/i18n/locale';
import {
    ActivityCheckoutInfo,
    MultiDayCheckoutInfo,
    PackageCheckoutInfo,
    ProductQuestion,
} from 'src/types/checkout-info';
import BilberryQuestionInput from './BilberryQuestionInput';

interface IProps {
    onChange(
        activityCheckoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    ): void;
    extraId: string;
    showErrors: boolean;
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo;
}

export default function ExtraQuestionnaire(props: IProps): JSX.Element {
    const { t } = useLocale();
    const { onChange, extraId, checkoutInfo } = props;
    const theme = useTheme();
    const extrasQuestions = checkoutInfo.extrasQuestions[extraId];
    const extra = checkoutInfo.cartItem.extras.find((x) => x.extra.id === extraId);

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                backgroundColor: 'white',
                width: '100%',
                marginTop: theme.spacing(1),
            }}
            variant="outlined"
        >
            <Grid container justifyContent="space-between" alignItems="center">
                <Title text={t.extras} description={extra?.extra.web_title}></Title>
            </Grid>

            {Object.entries(extrasQuestions).map(([key, question]) => {
                return (
                    <BilberryQuestionInput
                        key={key}
                        onChange={(question) =>
                            extraQuestionOnChange(
                                question,
                                extrasQuestions,
                                checkoutInfo,
                                extraId,
                                onChange,
                            )
                        }
                        showErrors={props.showErrors}
                        question={question}
                    />
                );
            })}
        </Paper>
    );
}

function extraQuestionOnChange(
    question: ProductQuestion,
    extrasQuestions: { [key: string]: ProductQuestion },
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    extraId: string,
    onChange: (
        activityCheckoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    ) => void,
) {
    const updatedExtrasQuestions = {
        ...extrasQuestions,
        [question.id]: question,
    };

    const updated: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo = {
        ...checkoutInfo,
        extrasQuestions: {
            ...checkoutInfo.extrasQuestions,
            [extraId]: updatedExtrasQuestions,
        },
    };

    onChange(updated);
}
