import { Grid, SvgIcon, Typography } from '@mui/material';
import { h } from 'preact';

export default function ProductCardIcon(props: { label: string; Icon: typeof SvgIcon; sx: any }) {
    const { label, Icon, sx } = props;
    return (
        <Grid justifyContent="center" sx={sx}>
            <Icon />
            <Typography sx={{ wordBreak: 'nowrap' }}>{label}</Typography>
        </Grid>
    );
}
