import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { RefObject } from 'preact';
import { route } from 'preact-router';
import { StateUpdater, useCallback } from 'preact/hooks';
import {
    createAddMultiDayProductToCartEvent,
    createAddProductToCartEvent,
} from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { BilberryPriceQuantity } from 'src/state/cart/ICart';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { BilberryProduct, BilberryProductPrice } from 'src/types/bilberry-api-types';
import { getProductQuantities } from 'src/utils/domain/booking/bookingHelpers';
import { updateQuantityData } from 'src/utils/domain/price-helper';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';

export function useOnClickBook(
    quantities: BilberryPriceQuantity[],
    attemptedBooking: boolean,
    onBookingAttempt: StateUpdater<boolean>,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onQuantitiesChange: StateUpdater<BilberryPriceQuantity[]>,
    hasChosenDate: boolean,
    config: any,
    selectedProducts: BilberryProduct[] | undefined,
    setSelectedProducts: StateUpdater<BilberryProduct[] | undefined>,
    setSelectedTimeslot: StateUpdater<TimeSlotType<BilberryProduct> | undefined>,
    productCatalogPrices: any,
    pricesFromSelectedProduct: BilberryProductPrice[] | undefined,
    setDateRange: StateUpdater<DateRange<Dayjs>>,
    dateRangeVariant?: 'nights' | 'days',
) {
    return useCallback(() => {
        if (!attemptedBooking) onBookingAttempt(true);
        if (!selectedProducts) return;
        if (hasChosenDate) {
            const productQuantities = getProductQuantities(selectedProducts[0], quantities);

            if (
                selectedProducts.length === 1 ||
                selectedProducts.every(
                    (p) =>
                        (p as any).is_accommodation !== undefined &&
                        (p as any).is_accommodation === 0,
                )
            ) {
                cartAtom.update(
                    createAddProductToCartEvent(selectedProducts[0], productQuantities),
                );
            } else if (selectedProducts.length > 1) {
                const lastSliceIndex =
                    dateRangeVariant === 'nights'
                        ? selectedProducts.length - 1
                        : selectedProducts.length;
                const productsToAddToCart = selectedProducts.slice(0, lastSliceIndex); // remove last entry
                cartAtom.update(
                    createAddMultiDayProductToCartEvent(
                        productsToAddToCart,
                        productQuantities,
                        [],
                        dateRangeVariant ?? 'nights',
                    ),
                );
                setDateRange([null, null]);
            }
            if (shouldShowBasketOnBook)
                showBasketAtom.update({
                    visible: true,
                    refocusElementOnClose: boxRef,
                });

            toggleBookingAtom.update({ visible: false });
            setSelectedTimeslot(undefined);
            setSelectedProducts(undefined);
            onQuantitiesChange(
                updateQuantityData(pricesFromSelectedProduct, productCatalogPrices, quantities),
            );

            if (!shouldShowBasketOnBook) route('/checkout');
        }
    }, [
        attemptedBooking,
        selectedProducts,
        hasChosenDate,
        quantities,
        boxRef,
        productCatalogPrices,
        config,
    ]);
}
