import { atom } from 'ximple';

export type ToggleBookingState = {
    visible: boolean;
    interaction: 'keyboard' | 'mouse';
};

export const toggleBookingAtom = atom<ToggleBookingState, Partial<ToggleBookingState>>({
    initialValue: {
        visible: false,
        interaction: 'mouse',
    },
});

toggleBookingAtom.subject.subscribe(console.log);
