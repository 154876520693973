import { FormControl, InputLabel, TextField, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { createRef, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { formatDate } from 'src/utils/common/DateHelpers';
import { positionInputLabelAboveField } from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize, deselectTextInput } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { AvailabilityProductType } from 'src/utils/domain/TimeSlotType';
import { getInputCallbackProps, getInputLayoutProps } from './common/calendar-props';
import PackageCalendarPopover from './subcomponents/PackageCalendarPopover';
import { EndIcon } from './subcomponents/EndIcon';

interface IProps {
    hasChosenDate: boolean;
    attemptedBooking: boolean;
    setHasChosenDate(hasChosen: boolean): void;
    selectedDate: Dayjs | null;
    setSelectedDate(value: Dayjs | null): void;
    minDate?: Dayjs;
    id?: string;
    labelColor?: string;
    backgroundColor?: string;
    color?: string;
    variant?: 'filled' | 'outlined';
    availabilityData: {
        [id: string]: AvailabilityProductType<any>[] | AvailabilityProductType<any>;
    };
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: Dayjs | null;
        endDay: Dayjs | null;
    }) => void;
}

export default function PackageCalendar(props: IProps): JSX.Element {
    const {
        hasChosenDate,
        setHasChosenDate,
        selectedDate,
        setSelectedDate,
        attemptedBooking,
        minDate = dayjs(),
        id = '',
        labelColor,
        backgroundColor,
        color,
        variant = 'filled',
        availabilityData,
        setAvailabilitySearchPeriod,
    } = props;

    const { t, locale } = useLocale();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    const customizations = useCustomizations();
    const theme = useTheme();
    const refocusElement = createRef<HTMLInputElement>();

    const showErrorDate = attemptedBooking && !hasChosenDate;
    const selectedDateText = getSelectedDateText(selectedDate, locale);

    const inputId = `bilberry-calendar-input-${id}`;

    const endIcon = (
        <EndIcon
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            showErrorDate={showErrorDate}
        />
    );

    return (
        <Fragment>
            <FormControl fullWidth>
                <InputLabel
                    id={`calendar-label-${id}`}
                    htmlFor={inputId}
                    sx={{
                        color: labelColor ?? customizations.bookingWidgetColorContrast,
                        ...positionInputLabelAboveField,
                    }}
                >
                    {capitalize(t.pick_a_date)}
                </InputLabel>
                <TextField
                    id={inputId}
                    inputRef={refocusElement}
                    error={showErrorDate}
                    helperText={showErrorDate ? capitalize(t.please_select_date) : ''}
                    value={hasChosenDate ? selectedDateText : t.select_date}
                    {...getInputLayoutProps(
                        t,
                        theme,
                        customizations,
                        showErrorDate,
                        endIcon,
                        backgroundColor,
                        color,
                        labelColor,
                        variant,
                    )}
                    {...getInputCallbackProps(
                        hasChosenDate,
                        setHasChosenDate,
                        deselectTextInput,
                        setAnchorEl,
                    )}
                />
            </FormControl>
            <PackageCalendarPopover
                minDate={minDate}
                displayDate={selectedDate}
                anchorEl={anchorEl}
                updateDisplayDate={setSelectedDate}
                setAnchorEl={setAnchorEl}
                id={inputId}
                setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                availabilityData={availabilityData}
            />
        </Fragment>
    );
}

function getSelectedDateText(date: Dayjs | null, locale: string) {
    if (date) {
        const dayString = formatDate(date, locale, 'L');
        return `${dayString}`;
    }

    return '';
}
