import { Currency } from 'src/types/currency';
import { debugLog } from 'src/utils/common/Logger';
import { atom } from 'ximple/atoms';

export const currencyAtom = atom<Currency>({
    initialValue: {
        currency: 'NOK',
        currencyDisplay: 'code',
    },
    equalityCompareFn: (a, b) => a.currency === b?.currency,
});

const currencyDisplayMap: Record<string, Currency['currencyDisplay']> = {
    NOK: 'code',
    GBP: 'symbol',
    USD: 'symbol',
    EUR: 'symbol',
};

export function updateCurrencyAtomFromCode(code: string) {
    debugLog('Updating currency atom to code: ', code);
    currencyAtom.update({
        currency: code,
        currencyDisplay: currencyDisplayMap[code] ?? 'code',
    });
}

/**
 * Updates the currency atom as a side-effect of fetching from an api
 *
 * This is needed in addititon to loading the currency from the bilberry-settings,
 * because of the seo-preloading feature. If data has been preloaded, that data will
 * end up loading before the settings, potentially causing a flash of "NOK" until the
 * settings have loaded. To mitigate this we need to set the currency based on the
 * preloaded data as well.
 *
 * To ensure correct behavior while the data is loading,
 * we need to be able to short circuit for null values,
 * so we do not accidentally set the currency to the default value.
 * This would cause eternal loops if using other than the default currency.
 */
export function updateAtomFromCurrencyCodeEffect<T>(getCurrencyCode: (obj: T) => string | null) {
    return (res: T) => {
        const code = getCurrencyCode(res);
        debugLog('Current currencyAtom code: ', currencyAtom.subject.value.currency);
        debugLog(
            'Calling updateAtomFromCurrencyCodeEffect with res: ',
            res,
            ' yielding code: ',
            code,
        );
        if (!code || currencyAtom.subject.value.currency === code) return res; // if code is null or unchanged, do nothing
        updateCurrencyAtomFromCode(code);
        return res;
    };
}
