import {
    BilberryBooking,
    BilberryGiftcard,
    BilberryProduct,
    BilberryReservation,
    BilberryReservationPax,
    BilberryReservationTour,
} from 'src/types/bilberry-api-types';
import { CheckoutInfoData, ProductQuestion } from 'src/types/checkout-info';
import { BilberryPriceQuantity } from 'src/state/cart/ICart';
import { countryOptionsEn, Iso2Code } from 'src/components/common/country-select/countries';
import { BilberryWidgetsGlobalType } from './widgetsConfiguration';
import { groupBy } from 'lodash-es';

const quantitiesToPax = (quantities: BilberryPriceQuantity[]) =>
    Object.values(quantities).map((priceAndQuantity): BilberryReservationPax => {
        return {
            id: priceAndQuantity.id,
            quantity: priceAndQuantity.quantity,
        };
    });

const quantitiesToPaxForMultiDay = (
    quantities: BilberryPriceQuantity[],
    product: BilberryProduct,
) =>
    Object.values(quantities).map((priceAndQuantity): BilberryReservationPax => {
        return {
            id: product.prices.filter(
                (price) => price.price_type === priceAndQuantity.price_type,
            )[0].id,
            quantity: priceAndQuantity.quantity,
        };
    });

const reduceQuestionsToAnswers = (questions: { [key: string]: ProductQuestion }) => {
    return Object.entries(questions).reduce((final, [key, question]) => {
        return {
            ...final,
            [key]: question.answer,
        };
    }, {});
};

export function mapUserInputToBilberryReservation(
    checkoutInfoData: CheckoutInfoData,
    config: BilberryWidgetsGlobalType,
    giftcardId?: string,
    promoCode?: string,
) {
    const checkoutInfoForActivities = Object.values(checkoutInfoData.checkoutInfoByActivity);
    const checkoutInfoForMultiDayActivities = Object.values(
        checkoutInfoData.checkoutInfoByMultiDayActivity,
    );
    const checkoutInfoForPackages = Object.values(checkoutInfoData.checkoutInfoByPackage);

    const extras = [...checkoutInfoForActivities, ...checkoutInfoForMultiDayActivities]
        .map((activityCheckoutInfo) => activityCheckoutInfo.cartItem.extras)
        .flat(1)
        .map((extra) => {
            const pax = quantitiesToPax(extra.quantities).filter((pax) => pax.quantity > 0);

            const checkoutInfoForActivity = checkoutInfoForActivities.find((activityCheckoutInfo) =>
                activityCheckoutInfo.cartItem.extras.find(
                    (cartExtra) => cartExtra.extra.id === extra.extra.id,
                ),
            );

            const extrasQuestions = checkoutInfoForActivity?.extrasQuestions[extra.extra.id];

            const orderEntities = reduceQuestionsToAnswers(extrasQuestions ?? {});
            const anyOrderEntities = Object.keys(orderEntities).length > 0;

            return {
                id: extra.extra.id,
                pax: pax,
                order_entities: anyOrderEntities ? orderEntities : undefined,
            };
        })
        .filter((reservationItem) => reservationItem.pax.length > 0);

    const tours = checkoutInfoForActivities.map((activityCheckoutInfo): BilberryReservationTour => {
        const { cartItem } = activityCheckoutInfo;

        const guestList = Object.values(activityCheckoutInfo.travelersQuestions).map(
            reduceQuestionsToAnswers,
        );

        const pax = quantitiesToPax(cartItem.quantities);

        const orderEntities = reduceQuestionsToAnswers(activityCheckoutInfo.activityQuestions);

        return {
            id: cartItem.product.id,
            pax: pax,
            guest_list: guestList,
            order_entities: orderEntities,
        };
    });

    checkoutInfoForMultiDayActivities.forEach((checkoutInfo) => {
        const { cartItem } = checkoutInfo;

        const guestList = Object.values(checkoutInfo.travelersQuestions).map(
            reduceQuestionsToAnswers,
        );

        const orderEntities = reduceQuestionsToAnswers(checkoutInfo.activityQuestions);

        cartItem.products.forEach((product) => {
            tours.push({
                id: product.id,
                pax: quantitiesToPaxForMultiDay(cartItem.quantities, product),
                guest_list: guestList,
                order_entities: orderEntities,
            });
        });
    });

    const packages = checkoutInfoForPackages.flatMap((checkoutInfo) => {
        const { cartItem } = checkoutInfo;

        const guest_list = Object.values(checkoutInfo.travelersQuestions).map(
            reduceQuestionsToAnswers,
        );
        const order_entities = reduceQuestionsToAnswers(checkoutInfo.activityQuestions);
        const productsByTicketOptionId = Object.entries(
            groupBy(cartItem.selectedProducts, 'ticketOptionId'),
        );

        return productsByTicketOptionId
            .flatMap(([toId, ps]) => {
                const price_category_id =
                    cartItem.pkg.ticket_options.find(
                        ({ ticket_option_id }) => ticket_option_id.toString() === toId,
                    )?.price_category_id ?? -1;

                return {
                    package_id: cartItem.pkg.id,
                    ticket_option_id: Number(toId),
                    price_category_id,
                    quantity: cartItem.quantities.find((q) => q.id === Number(toId))?.quantity ?? 0,
                    products: ps.map((p) => ({
                        tour_id: p.product.id,
                        plan_post_id: p.product.product_catalog_id,
                        price_category_id: p.priceCategoryId,
                        package_product_id: p.packageProductId,
                        quantity: 1,
                        extras: cartItem.extras
                            .filter(
                                (e) =>
                                    e.extra.ticketOptionId === p.ticketOptionId &&
                                    e.extra.tours.includes(p.product.id) &&
                                    e.quantities.some((q) => q.quantity > 0),
                            )
                            .map((e) => {
                                const pax = quantitiesToPax(e.quantities).filter(
                                    (pax) => pax.quantity > 0,
                                );

                                const checkoutInfoForPackage = checkoutInfoForPackages.find(
                                    (packageCheckoutInfo) =>
                                        packageCheckoutInfo.cartItem.extras.find(
                                            (cartExtra) => cartExtra.extra.id === e.extra.id,
                                        ),
                                );

                                const extrasQuestions =
                                    checkoutInfoForPackage?.extrasQuestions[e.extra.id];

                                const orderEntities = reduceQuestionsToAnswers(
                                    extrasQuestions ?? {},
                                );
                                const anyOrderEntities = Object.keys(orderEntities).length > 0;

                                return {
                                    id: e.extra.id,
                                    pax: pax,
                                    order_entities: anyOrderEntities ? orderEntities : undefined,
                                };
                            }),
                        guest_list: guest_list,
                        order_entities: order_entities,
                    })),
                };
            })
            .filter((p) => p.quantity > 0);
    });

    const siteUrl = window.location.toString();
    const reservation: BilberryReservation = {
        name: `${checkoutInfoData.contactPerson.firstName} ${checkoutInfoData.contactPerson.lastName}`,
        phone: `+${checkoutInfoData.contactPerson.phone.dialCode}${checkoutInfoData.contactPerson.phone.number}`,
        email: checkoutInfoData.contactPerson.email,
        country: countryOptionsEn[checkoutInfoData.contactPerson.country as Iso2Code],
        address1: checkoutInfoData.contactPerson.address,
        zip: checkoutInfoData.contactPerson.postCode,
        city: checkoutInfoData.contactPerson.city,
        tours,
        packages,
        extras,
        external_data: [],
        url: siteUrl,
        termsUrl: config.termsUrl,
        giftcard: giftcardId,
        coupon_code: promoCode,
    };

    return reservation;
}

export function guardIsValidPaymentGateway(booking: BilberryBooking | BilberryGiftcard) {
    if (booking.payment_gateway !== 'nets' && booking.payment_gateway !== 'stripe') {
        throw new Error(
            `Expected NETS or Stripe payment gateway but was ${booking.payment_gateway}`,
        );
    }
}
