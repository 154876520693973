import {
    ActivityCheckoutInfo,
    CheckoutInfoData,
    ContactPerson,
    MultiDayCheckoutInfo,
    PackageCheckoutInfo,
    ProductQuestion,
} from 'src/types/checkout-info';
import {
    getCountryFromSettings,
    getDialcodes,
    getUserCountry,
    norway,
} from 'src/components/common/country-select/countries';
import {
    CompletedGiftcardPaymentInfo,
    CompletedPaymentInfo,
} from 'src/components/domain/payment/payment-types';
import {
    validateEmail,
    validatePhone,
    validateTextField,
} from 'src/utils/common/validation-helper';
import {
    ICartAccommodationItem,
    ICartItem,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import { loadCheckoutInfoState } from 'src/state/checkout-info/checkout-info.localstorage';
import { loadPurchase } from 'src/state/payment/purchase.localstorage';
import {
    getCartPackageTypeAndId,
    isICartAccommodationItemType,
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
} from './cart/cartUtils';
import { BilberryOrderEntity, BilberryProduct } from 'src/types/bilberry-api-types';
import { PaymentRedirectContext } from './payment-redirect-helper';
import { findNumberOfTravelersInCartProductItem } from './travelers-helper';
import { uniqBy } from 'lodash-es';
import { localeAtom } from 'src/i18n/locale';
import { configurationAtom } from './widgetsConfiguration';
import { currencyAtom } from 'src/state/currency/currency.atom';

const reduceOrderEntitiesToQuestions = (
    orderEntities: { productId: number | null; orderEntity: BilberryOrderEntity }[],
    cachedQuestions?: { [key: string]: ProductQuestion },
) => {
    return orderEntities.reduce((final, { productId, orderEntity }) => {
        const cachedAnswer = cachedQuestions && cachedQuestions[orderEntity.id]?.answer;

        const question: ProductQuestion = {
            id: orderEntity.id,
            key: orderEntity.key,
            productId,
            answer:
                cachedAnswer !== undefined
                    ? cachedAnswer
                    : orderEntity.type === 'checkbox'
                    ? false
                    : '',
            required: orderEntity.mandatory === 1,
            title: orderEntity.title,
            type: orderEntity.type,
        };

        return {
            ...final,
            [question.id]: question,
        };
    }, {});
};

export function createInitialCheckoutInfoData(cartItems: {
    [key: string]: ICartItem;
}): CheckoutInfoData | null {
    const cachedCheckoutInfoData = loadCheckoutInfoState();

    const initialContactPerson = createInitialContactPerson(cachedCheckoutInfoData);

    const initialCheckoutInfoData: CheckoutInfoData = {
        checkoutInfoByActivity: {},
        checkoutInfoByPackage: {},
        checkoutInfoByMultiDayActivity: {},
        contactPerson: initialContactPerson,
        accommodationCheckoutInfo: [],
    };

    const cartItemActivityEntries = Object.entries(cartItems).filter(
        (x): x is [string, { item: ICartProductItem }] => isICartProductItemType(x[1].item),
    );

    const cartItemPackageEntries = Object.entries(cartItems).filter(
        (x): x is [string, { item: ICartPackageItem }] => isICartPackageItemType(x[1].item),
    );

    const cartItemMultiDayEntries = Object.entries(cartItems).filter(
        (x): x is [string, { item: ICartMultiDayProductItem }] =>
            isICartMultiDayProductItemType(x[1].item),
    );

    const cartItemAccommodationsEntries = Object.entries(cartItems).filter(
        (x): x is [string, { item: ICartAccommodationItem }] =>
            isICartAccommodationItemType(x[1].item),
    );

    if (cartItemActivityEntries.length > 0) {
        initialCheckoutInfoData.checkoutInfoByActivity = cartItemActivityEntries.reduce(
            (final, [key, cartItem]) => {
                const activityCheckoutInfo = createInitialCheckoutInfo(
                    cartItem.item,
                    cachedCheckoutInfoData,
                );

                const value: { [productId: number]: ActivityCheckoutInfo } = {
                    ...final,
                    [key]: activityCheckoutInfo,
                };

                return value;
            },
            {},
        );
    }

    if (cartItemPackageEntries.length > 0) {
        initialCheckoutInfoData.checkoutInfoByPackage = cartItemPackageEntries.reduce(
            (final, [key, cartItem]) => {
                const packageCheckoutInfo = createInitialCheckoutInfo(
                    cartItem.item,
                    cachedCheckoutInfoData,
                );

                const value: { [productId: number]: ActivityCheckoutInfo } = {
                    ...final,
                    [key]: packageCheckoutInfo,
                };

                return value;
            },
            {},
        );
    }

    if (cartItemMultiDayEntries.length > 0) {
        initialCheckoutInfoData.checkoutInfoByMultiDayActivity = cartItemMultiDayEntries.reduce(
            (final, [key, cartItem]) => {
                const activityCheckoutInfo = createInitialCheckoutInfo(
                    cartItem.item,
                    cachedCheckoutInfoData,
                );

                const value: { [productId: number]: ActivityCheckoutInfo } = {
                    ...final,
                    [key]: activityCheckoutInfo,
                };

                return value;
            },
            {},
        );
    }

    initialCheckoutInfoData.accommodationCheckoutInfo = cartItemAccommodationsEntries.map(
        ([_, { item }], i) => ({
            cartItem: item,
            accommodationQuestions: {
                notes: {
                    id: i,
                    productId: null,
                    key: `notes-${i}`,
                    answer: '',
                    required: false,
                    title: '',
                    type: 'textarea',
                },
            },
        }),
    );

    return initialCheckoutInfoData;
}

export function createInitialCheckoutInfo(
    cartItem: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem,
    cachedCheckoutInfoData: CheckoutInfoData | null,
): MultiDayCheckoutInfo | ActivityCheckoutInfo | PackageCheckoutInfo {
    const productsId = isICartMultiDayProductItemType(cartItem)
        ? cartItem.products.reduce(
              (acc: string, product: BilberryProduct) => (acc += product.id + '-'),
              '',
          )
        : '';

    const products = isICartProductItemType(cartItem)
        ? [cartItem.product]
        : isICartPackageItemType(cartItem)
        ? cartItem.selectedProducts.map((p) => p.product)
        : [cartItem.products[0]];
    const packageProductsId = isICartPackageItemType(cartItem)
        ? getCartPackageTypeAndId(cartItem)
        : '';

    const cachedCheckoutInfo = isICartProductItemType(cartItem)
        ? cachedCheckoutInfoData?.checkoutInfoByActivity[cartItem.product.id]
        : isICartPackageItemType(cartItem)
        ? cachedCheckoutInfoData?.checkoutInfoByPackage[packageProductsId]
        : cachedCheckoutInfoData?.checkoutInfoByMultiDayActivity[productsId];
    const activityQuestions = reduceOrderEntitiesToQuestions(
        products.flatMap(({ id, order_entities }) =>
            order_entities.flatMap((oe) => ({
                productId: id,
                orderEntity: oe,
            })),
        ),
        cachedCheckoutInfo?.activityQuestions,
    );

    const extrasWithQuantityAndIsOrderEntityDependant = cartItem.extras.filter((cartExtra) => {
        const hasQuantity =
            cartExtra.quantities.find(
                (priceQuantity) => priceQuantity.quantity > 0,
                cachedCheckoutInfoData?.checkoutInfoByMultiDayActivity[productsId],
            ) ||
            cartExtra.quantities.find(
                (priceQuantity) => priceQuantity.quantity > 0,
                cachedCheckoutInfoData?.checkoutInfoByPackage[packageProductsId],
            );
        const isOrderEntityDependant = cartExtra.extra.order_entity_dependant === 1;
        return hasQuantity && isOrderEntityDependant;
    });

    const extrasQuestions = extrasWithQuantityAndIsOrderEntityDependant.reduce((final, extra) => {
        const extraQuestions = reduceOrderEntitiesToQuestions(
            extra.extra.order_entities.map((oe) => ({ productId: null, orderEntity: oe })),
            cachedCheckoutInfo?.extrasQuestions[extra.extra.id],
        );

        return {
            ...final,
            [extra.extra.id]: extraQuestions,
        };
    }, {});

    const numberOfTravelers = findNumberOfTravelersInCartProductItem(cartItem);

    const travelersQuestions: {
        [travelerKey: string]: { [key: string]: ProductQuestion };
    } = {};

    const guestListEntities = uniqBy(
        products.flatMap(({ id, guest_list_entities }) =>
            guest_list_entities.flatMap((gle) => ({ productId: id, guestListEntity: gle })),
        ),
        (entity) => entity.guestListEntity.id,
    );
    for (let i = 0; i < numberOfTravelers; i++) {
        const cachedTravelerQuestions = cachedCheckoutInfo?.travelersQuestions[i];
        travelersQuestions[i] = guestListEntities.reduce(
            (final, { productId, guestListEntity }) => {
                const cachedQuestion =
                    cachedTravelerQuestions !== undefined
                        ? cachedTravelerQuestions[guestListEntity.id]
                        : null;
                const cachedAnswer = cachedQuestion?.answer;
                const question: ProductQuestion = {
                    id: guestListEntity.id,
                    productId,
                    key: guestListEntity.key,
                    answer:
                        cachedAnswer !== undefined
                            ? cachedAnswer
                            : guestListEntity.type === 'checkbox'
                            ? false
                            : '',
                    required: guestListEntity.mandatory === 1,
                    title: guestListEntity.title,
                    type: guestListEntity.type,
                };

                return {
                    ...final,
                    [question.id]: question,
                };
            },
            {},
        );
    }

    const isGuestListDependant = products.some(
        ({ guest_list_dependant }) => guest_list_dependant === 1,
    );

    const isOrderEntityDependant = products.some(
        ({ order_entity_dependant }) => order_entity_dependant === 1,
    );

    if (isICartPackageItemType(cartItem)) {
        return {
            cartItem,
            copyFromContactPerson:
                cachedCheckoutInfo !== undefined ? cachedCheckoutInfo.copyFromContactPerson : false,
            activityQuestions: isOrderEntityDependant ? activityQuestions : {},
            extrasQuestions,
            travelersQuestions: isGuestListDependant ? travelersQuestions : {},
        };
    } else if (isICartMultiDayProductItemType(cartItem)) {
        return {
            cartItem,
            copyFromContactPerson:
                cachedCheckoutInfo !== undefined ? cachedCheckoutInfo.copyFromContactPerson : false,
            activityQuestions: isOrderEntityDependant ? activityQuestions : {},
            extrasQuestions,
            travelersQuestions: isGuestListDependant ? travelersQuestions : {},
        };
    }
    return {
        cartItem,
        copyFromContactPerson:
            cachedCheckoutInfo !== undefined ? cachedCheckoutInfo.copyFromContactPerson : false,
        activityQuestions: isOrderEntityDependant ? activityQuestions : {},
        extrasQuestions,
        travelersQuestions: isGuestListDependant ? travelersQuestions : {},
    };
}

export function validateBilberryQuestion(question: ProductQuestion) {
    let isValid = true;
    if (
        question.required &&
        typeof question.answer === 'string' &&
        !validateTextField(question.answer)
    ) {
        isValid = false;
    } else if (
        question.required &&
        typeof question.answer === 'boolean' &&
        question.answer === false
    ) {
        isValid = false;
    }
    return isValid;
}

export function validateCheckoutInfoData(
    checkoutInfoData: CheckoutInfoData,
    showAddressFields: boolean,
) {
    const anyInvalidQuestions = Object.values(checkoutInfoData.checkoutInfoByActivity).some(
        (activityCheckoutInfo) => {
            const allQuestions = [
                ...Object.values(activityCheckoutInfo.travelersQuestions),
                ...Object.values(activityCheckoutInfo.extrasQuestions),
                activityCheckoutInfo.activityQuestions,
            ];

            return allQuestions.some((travelerQuestions) => {
                return Object.values(travelerQuestions).some((travelerQuestion) => {
                    return !validateBilberryQuestion(travelerQuestion);
                });
            });
        },
    );

    const { isValid: isContactPersonValid } = validateContactPerson(
        checkoutInfoData.contactPerson,
        showAddressFields,
    );

    return isContactPersonValid && !anyInvalidQuestions;
}

export function validateContactPerson(contactPerson: ContactPerson, showAddressFields: boolean) {
    const isFirstNameValid = validateTextField(contactPerson.firstName);
    const isLastNameValid = validateTextField(contactPerson.lastName);
    const isPhoneValid = validatePhone(contactPerson.phone);
    const isEmailValid = validateEmail(contactPerson.email);
    const isAddressValid = !showAddressFields || validateTextField(contactPerson.address);
    const isPostCodeValid = !showAddressFields || validateTextField(contactPerson.postCode);
    const isCityValid = !showAddressFields || validateTextField(contactPerson.city);

    const isValid =
        isFirstNameValid &&
        isLastNameValid &&
        isPhoneValid &&
        isEmailValid &&
        isLastNameValid &&
        isAddressValid &&
        isPostCodeValid &&
        isCityValid &&
        contactPerson.hasAcceptedPrivacy &&
        contactPerson.hasAcceptedTerms;

    return {
        isFirstNameValid,
        isLastNameValid,
        isPhoneValid,
        isEmailValid,
        isAddressValid,
        isPostCodeValid,
        isCityValid,
        isValid,
    };
}

export function createInitialContactPerson(
    cachedCheckoutInfoData: CheckoutInfoData | null,
): ContactPerson {
    if (cachedCheckoutInfoData) {
        return cachedCheckoutInfoData.contactPerson;
    }

    return {
        firstName: '',
        lastName: '',
        country: getCountryFromSettings(),
        phone: { dialCode: getDialcodes()[getCountryFromSettings()], number: '' },
        email: '',
        address: '',
        postCode: '',
        city: '',
        hasAcceptedPrivacy: false,
        hasAcceptedTerms: false,
    };
}

export function findContactPersonValue(
    contactPerson: ContactPerson | null,
    key: string,
): string | null {
    if (!contactPerson) return null;

    switch (key.toLowerCase()) {
        case 'fornavn':
        case 'firstname':
        case 'first_name':
            return contactPerson.firstName;
        case 'etternavn':
        case 'lastname':
        case 'last_name':
            return contactPerson.lastName;
        default:
            return null;
    }
}

export function getUpdatedTravelerQuestions(
    previousQuestions: { [key: string]: ProductQuestion },
    contactPerson: ContactPerson | null,
    shouldCopyFromContactPerson: boolean,
) {
    const travelerQuestions = Object.values(previousQuestions);
    return travelerQuestions.reduce(
        (final, question) => {
            const contactPersonValue = findContactPersonValue(contactPerson, question.key);
            const updatedQuestion = {
                ...question,
                answer:
                    contactPersonValue !== null && shouldCopyFromContactPerson
                        ? contactPersonValue
                        : question.answer,
            };
            return {
                ...final,
                [question.id]: updatedQuestion,
            };
        },
        {} as { [key: string]: ProductQuestion },
    );
}

export function getUpdatedTravelersQuestions(
    previous: { [travelerKey: string]: { [key: string]: ProductQuestion } },
    contactPerson: ContactPerson | null,
    copyFromContactPerson: boolean,
) {
    return Object.entries(previous).reduce(
        (final, [key, travelerQuestions]) => {
            const travelerIndex = parseInt(key);
            const isFirstTraveler = travelerIndex === 0;
            const shouldCopyFromContactPerson = isFirstTraveler && copyFromContactPerson;

            return {
                ...final,
                [key]: getUpdatedTravelerQuestions(
                    travelerQuestions,
                    contactPerson,
                    shouldCopyFromContactPerson,
                ),
            };
        },
        {} as { [travelerKey: string]: { [key: string]: ProductQuestion } },
    );
}

export function getUpdatedCheckoutInfoByActivity(
    previousCheckoutInfoByActivity: { [productId: number]: ActivityCheckoutInfo },
    contactPerson: ContactPerson | null,
) {
    return Object.entries(previousCheckoutInfoByActivity).reduce(
        (final, [key, activityCheckoutInfo]) => {
            const updatedTravelersQuestions = getUpdatedTravelersQuestions(
                activityCheckoutInfo.travelersQuestions,
                contactPerson,
                activityCheckoutInfo.copyFromContactPerson,
            );
            const updatedActivityCheckoutInfo: ActivityCheckoutInfo = {
                ...activityCheckoutInfo,
                travelersQuestions: updatedTravelersQuestions,
            };

            return {
                ...final,
                [key]: updatedActivityCheckoutInfo,
            };
        },
        {} as { [productId: number]: ActivityCheckoutInfo },
    );
}

export function validateGiftcardInfo(value: number, contactPerson: ContactPerson) {
    const isValueValid = value > 0;
    const { isValid: isContactPersonValid } = validateContactPerson(contactPerson, false);
    return isValueValid && isContactPersonValid;
}

export function createInitialCompletedPaymentInfo(
    paymentRedirectContext: PaymentRedirectContext | null,
) {
    if (!paymentRedirectContext) return null;

    const purchase = loadPurchase();
    const initialCompletedPaymentInfo: CompletedGiftcardPaymentInfo & CompletedPaymentInfo = {
        paymentId: paymentRedirectContext.paymentId,
        referenceId:
            purchase && purchase.paymentId === paymentRedirectContext.paymentId
                ? purchase.referenceId
                : undefined,
    };
    return initialCompletedPaymentInfo;
}
