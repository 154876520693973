import { FormControl, Grid, InputAdornment, InputLabel, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { TargetedEvent } from 'preact/compat';
import { useLocale } from 'src/i18n/locale';
import { PhoneNumber } from 'src/types/checkout-info';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize } from 'src/utils/common/TextUtils';

interface props {
    phoneNumber: PhoneNumber;
    onChange: (phoneNumber: PhoneNumber) => void;
    error: boolean;
}
export default function PhoneInput(props: props): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();

    return (
        <FormControl>
            <InputLabel
                htmlFor="bilberry-phone"
                error={props.error}
                required={true}
                sx={{
                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                    color: theme.palette.secondary.main,
                }}
            >
                {capitalize(t.phone_number)}
            </InputLabel>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        error={props.error}
                        required={true}
                        value={props.phoneNumber.dialCode}
                        onChange={(
                            e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                        ) =>
                            props.onChange({
                                ...props.phoneNumber,
                                dialCode: e.currentTarget.value,
                            })
                        }
                        aria-label={capitalize(t.dial_code)}
                        variant="outlined"
                        type={'text'}
                        color="secondary"
                        margin="dense"
                        size="small"
                        name="phoneDialCode"
                        inputProps={{
                            sx: {
                                paddingLeft: '0px !important',
                            },
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="bilberry-phone"
                        sx={{
                            width: '100%',
                            paddingLeft: 0,
                        }}
                        error={props.error}
                        required={true}
                        value={props.phoneNumber.number}
                        onChange={(
                            e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                        ) =>
                            props.onChange({ ...props.phoneNumber, number: e.currentTarget.value })
                        }
                        variant="outlined"
                        type={'text'}
                        color="secondary"
                        margin="dense"
                        size="small"
                        name="phoneNumber"
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                    />
                </Grid>
            </Grid>
        </FormControl>
    );
}
