import { Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { searchFormDataAtom } from 'src/state/search-form-data/searchFormDataAtom';
import { ProductSearch } from 'src/types/parameter-types';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { useProductCatalogsFiltered } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import { SearchForm } from 'src/widgets/activities/booking-search/search-form/SearchForm';
import { SearchResult } from 'src/widgets/activities/booking-search/search-result/SearchResult';
import { useAtom } from 'ximple';

type IProps = {
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
};

export default function BookingSearch(props: IProps): JSX.Element {
    const { numElements } = props;
    const { locale, t } = useLocale();
    const [searchFormData, setSearchFormData] = useAtom(searchFormDataAtom);
    const theme = useTheme();
    const config = useConfigurations();
    const {
        productCatalogsFiltered = [],
        isLoading,
        isError,
    } = useProductCatalogsFiltered({
        filters: searchFormData,
        locale,
        config,
    });
    const { minDuration, maxDuration } = useMinMaxDurations();

    const onClick = (formData: ProductSearch) => {
        setSearchFormData(formData);
    };

    const noResults = !isLoading && !isError && productCatalogsFiltered.length === 0;

    return (
        <Grid container direction="column">
            <SearchForm
                onClick={onClick}
                minDuration={minDuration}
                maxDuration={maxDuration}
                defaultFormData={searchFormData}
            />
            {!noResults && (
                <SearchResult
                    productCatalogs={productCatalogsFiltered}
                    numElements={numElements}
                    backgroundColors={props.backgroundColors}
                    textColors={props.textColors}
                    primaryColors={props.primaryColors}
                    primaryTextColors={props.primaryTextColors}
                    accentColors={props.accentColors}
                    accentTextColors={props.accentTextColors}
                />
            )}
            {noResults && (
                <Typography align="center" variant="h4" p={theme.spacing(6, 2)}>
                    {t.noSearchResults}
                </Typography>
            )}
        </Grid>
    );
}

function useMinMaxDurations() {
    const [minMaxDurations, setMinMaxDurations] = useState({ minDuration: -1, maxDuration: -1 });
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalogsFiltered } = useProductCatalogsFiltered({
        locale,
        filters: null,
        config,
    });

    useEffect(() => {
        if (
            productCatalogsFiltered?.length > 0 &&
            minMaxDurations.minDuration === -1 &&
            minMaxDurations.maxDuration === -1
        ) {
            const minMax = productCatalogsFiltered.reduce(
                (acc, cur) => {
                    acc.minDuration =
                        cur.duration < acc.minDuration ? cur.duration : acc.minDuration;
                    acc.maxDuration =
                        cur.duration > acc.maxDuration ? cur.duration : acc.maxDuration;
                    return acc;
                },
                { minDuration: Number.MAX_VALUE, maxDuration: -1 },
            );
            setMinMaxDurations(minMax);
        }
    }, [productCatalogsFiltered, minMaxDurations]);

    return minMaxDurations;
}
