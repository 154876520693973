import { h, render } from 'preact';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import ModalCollection from 'src/widgets/activities/modal-widget/modal/ModalCollection';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class ModalElem extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ModalCollection />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-modal', ModalElem);
}
