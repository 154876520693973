import { getIdAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';

class ActivateBilberryBooking extends HTMLElement {
    connectedCallback() {
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        overrideLocaleFromAttribute(this);
        const expandArrowInside = this.getAttribute('expand-arrow-inside');
        const positionOffscreen = this.getAttribute('position-offscreen');

        setBookingWidgetActiveProduct(productCatalogId, expandArrowInside, positionOffscreen);
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-booking', ActivateBilberryBooking);
}

function setBookingWidgetActiveProduct(
    productCatalogId: number,
    expandArrowInside: string | null,
    positionOffscreen: string | null,
) {
    if (document.querySelector('bilberry-booking-widget') !== null) return;

    const booking = document.createElement('bilberry-booking-widget');
    if (productCatalogId) booking.setAttribute('product-catalog-id', productCatalogId.toString());
    if (expandArrowInside) booking.setAttribute('expand-arrow-inside', expandArrowInside);
    if (positionOffscreen) booking.setAttribute('position-offscreen', positionOffscreen);
    document.body.appendChild(booking);
}
