import { omit } from 'lodash-es';
import groupBy from 'lodash-es/groupBy';
import isObject from 'lodash-es/isObject';
import mapValues from 'lodash-es/mapValues';
import { localeAtom } from 'src/i18n/locale';
import {
    ICartExtra,
    ICartItem,
    ICartPackageItem,
    PackagePriceQuantity,
} from 'src/state/cart/ICart';
import { BilberryExtra } from 'src/types/bilberry-api-types';
import {
    getBilberryProductsByIds,
    getExtrasByIds,
    getPackage,
    getPackageAvailability,
} from '../../api/bilberry-api-client';
import { getCartPackageTypeAndId, isCartItemGuard } from '../../cart/cartUtils';
import { configurationAtom } from '../../widgetsConfiguration';
import { assignQuantityToExtraProduct, MinimalPackageCartItem, parseExtraParams } from './common';

export async function deserializePackages(params: string[]) {
    if (params.length === 0) return;

    const minimalCartItems = params.map(asMinimalPackageCartItem);

    const allPackages = minimalCartItems.map(async (item) => {
        const productIds = item.selectedProducts;

        const [pkg, pkgAvailability, productArray, extraProducts] = await Promise.all([
            getPackage(
                Number(item.pkg),
                localeAtom.subject.value.locale,
                configurationAtom.subject.value,
            ),
            getPackageAvailability(
                Number(item.pkg),
                localeAtom.subject.value.locale,
                configurationAtom.subject.value,
            ),
            getBilberryProductsByIds(
                productIds,
                localeAtom.subject.value.locale,
                configurationAtom.subject.value,
            ),
            getExtrasByIds(
                productIds,
                localeAtom.subject.value.locale,
                configurationAtom.subject.value,
            ),
        ]);

        const extrasGroups = groupBy(extraProducts, 'tours');

        const extras: Record<string, ICartExtra[]> = mapValues(
            extrasGroups,
            (group: BilberryExtra[]) =>
                group
                    .flatMap((extra) => assignQuantityToExtraProduct(item, extra))
                    .filter(isObject),
        ) as unknown as Record<string, ICartExtra[]>;

        const currentExtras = item.selectedProducts.flatMap((p) => extras[p] ?? []);
        const packageProducts = pkg.ticket_options.flatMap((option) =>
            option.products.map((p) => ({ ...omit(option, 'products'), ...p })),
        );

        const selectedProducts = packageProducts.map((p) => ({
            packageProductId: p.product_id,
            ticketOptionId: Number(p.ticket_option_id),
            priceCategoryId: Number(p.price_category_id),
            product: productArray.find((product) => product.id === p.product_id)!,
        }));

        const quantities: PackagePriceQuantity[] =
            item.quantities?.map((q) => ({ id: Number(q.id), quantity: Number(q.quantity) })) ?? [];

        const vats = pkgAvailability.reduce(
            (acc, { ticket_option_id, vats }) => ({
                ...acc,
                [ticket_option_id]: vats,
            }),
            {},
        );
        const productItem: ICartPackageItem = {
            pkg,
            vats,
            start: item.start,
            end: item.end,
            selectedProducts,
            quantities,
            extras: currentExtras,
        };

        return {
            item: productItem,
        };
    });

    const cartItems = Promise.all(allPackages).then((res) =>
        res.reduce(
            (acc, cartItem) => ({
                ...acc,
                [getCartPackageTypeAndId(cartItem.item)]: cartItem as ICartItem,
            }),
            {} as Record<string, ICartItem>,
        ),
    );

    if (isCartItemGuard(cartItems)) {
        return cartItems;
    }
}

function asMinimalPackageCartItem(cartItemString: string): MinimalPackageCartItem {
    const [packageIdParam, quantitiesParam, extrasParam, startDate, endDate] =
        cartItemString.split(';');
    const [packageId, ...productIds] = packageIdParam.split(':');
    const quantities = quantitiesParam.split(',').map((quantity) => quantity.split(':'));
    const extras = parseExtraParams(extrasParam);

    return {
        pkg: packageId,
        start: decodeURIComponent(startDate),
        end: decodeURIComponent(endDate),
        selectedProducts: productIds,
        quantities: quantities.map(([id, quantity]) => ({
            id,
            quantity,
        })),
        extras,
    };
}
