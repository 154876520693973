import {
    BookingPriceAndQuantity,
    CartEvents,
    IAddEvent,
    IAddExtraEvent,
    ICartAccommodationItem,
    ICartExtra,
    ICartItem,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartPackageItemProduct,
    ICartProductItem,
    IClearEvent,
    IRemoveEvent,
    PackagePriceQuantity,
} from './ICart';
import {
    BilberryExtra,
    BilberryPackage,
    BilberryPackageAvailabilityVat,
    BilberryProduct,
    BilberryProductPrice,
} from 'src/types/bilberry-api-types';
import { AccommodationPrice, BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import {
    getCartItemTypeAndId,
    getCartMultiDayProductTypeAndId,
    getCartPackageTypeAndId,
    getCartProductTypeAndId,
    isICartMultiDayProductItemType,
    isICartPackageItemType,
    isICartProductItemType,
} from 'src/utils/domain/cart/cartUtils';
import { generateID } from 'src/utils/domain/IdGeneretor';
import { produce } from 'immer';
import { uniq } from 'lodash-es';

// Higher order function - Filter extras matching cart item
const createFilterForExtrasMatchingCartItem =
    (item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem) =>
    (e: BilberryExtra) => {
        if (isICartProductItemType(item))
            return e.tours.some((tourId) => tourId === item.product.id);
        else if (isICartPackageItemType(item))
            return e.tours.some((tourId) =>
                item.selectedProducts.some((p) => tourId === p.product.id),
            );
        else
            return e.tours.some((tourId) => item.products.some((product) => tourId === product.id));
    };

// Higher order function - Filter extras already cart item
const createFilterForExtrasPresentInCartItems = (extras: ICartExtra[]) => (e: BilberryExtra) =>
    !extras.some((extra) => extra.extra.id === e.id);

// Higher order function - Filter old extras in cart matching cart item
const createFilterForOldCartExtrasMatchingCartItem =
    (item: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem) =>
    (extraInCart: ICartExtra) => {
        if (isICartProductItemType(item))
            return extraInCart.extra.tours.some((tourId) => tourId === item.product.id);
        if (isICartPackageItemType(item))
            return extraInCart.extra.tours.some((tourId) =>
                item.selectedProducts.some((p) => tourId === p.product.id),
            );
        else
            return extraInCart.extra.tours.some((tourId) =>
                item.products.some((product) => tourId === product.id),
            );
    };

const findInitialQuantity = (
    newExtra: BilberryExtra,
    newPrice: BilberryProductPrice,
    oldExtrasInCart: ICartExtra[],
): number => {
    const matchingOldExtra = oldExtrasInCart.find((oldExtra: ICartExtra) => {
        return (
            oldExtra.extra.category.id === newExtra.category.id &&
            oldExtra.extra.title === newExtra.title &&
            oldExtra.extra.product_catalog_id === newExtra.product_catalog_id
        );
    });

    const matchingOldQuantityInfo = matchingOldExtra
        ? matchingOldExtra.quantities.find((oldPrice) => {
              return oldPrice.name === newPrice.name;
          })
        : undefined;

    const desiredQuantity = matchingOldQuantityInfo ? matchingOldQuantityInfo.quantity : 0;

    // There was a case where capacity was negative coming from Bilberry.
    // We'll use 0 capacity in this case.
    const availableCapacity = Math.max(0, newExtra.capacity);

    return desiredQuantity > availableCapacity ? availableCapacity : desiredQuantity;
};

const createExtraWithQuantityData = (
    e: BilberryExtra,
    oldExtrasInCart: ICartExtra[],
): ICartExtra => {
    return {
        extra: e,
        quantities: e.prices.map((price) => ({
            ...price,
            quantity: findInitialQuantity(e, price, oldExtrasInCart),
        })),
    };
};

export function reduceCartState(
    state: { [key: string]: ICartItem },
    action: CartEvents,
): { [key: string]: ICartItem } {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'ADD':
                draft[getCartItemTypeAndId(action.data)] = action.data;
                break;
            case 'ADD_EXTRA': {
                const oldExtrasInCart = action.data.cartItem.extras ?? [];

                const filteredOldExtrasInCart = oldExtrasInCart.filter(
                    createFilterForOldCartExtrasMatchingCartItem(action.data.cartItem),
                );

                const filteredNewExtras = action.data.extras
                    .filter(createFilterForExtrasPresentInCartItems(action.data.cartItem.extras))
                    .filter(createFilterForExtrasMatchingCartItem(action.data.cartItem));

                const cartItem = isICartProductItemType(action.data.cartItem)
                    ? draft[getCartProductTypeAndId(action.data.cartItem)]
                    : isICartPackageItemType(action.data.cartItem)
                    ? draft[getCartPackageTypeAndId(action.data.cartItem)]
                    : draft[getCartMultiDayProductTypeAndId(action.data.cartItem)];

                if (
                    isICartProductItemType(cartItem.item) ||
                    isICartPackageItemType(cartItem.item) ||
                    isICartMultiDayProductItemType(cartItem.item)
                ) {
                    cartItem.item.extras = [
                        ...filteredOldExtrasInCart,
                        ...filteredNewExtras.map((e) =>
                            createExtraWithQuantityData(e, oldExtrasInCart),
                        ),
                    ];
                }
                break;
            }
            case 'REMOVE':
                Object.keys(state).forEach((key) => {
                    if (key === action.data.toString()) delete draft[key];
                });
                break;
            case 'CLEAR':
                return {};
            case 'INITIALIZE':
                return action.data;
            default:
                break;
        }
    });
}

export function createAddProductToCartEvent(
    product: BilberryProduct,
    quantities: BookingPriceAndQuantity[],
    extras: ICartExtra[] = [],
): IAddEvent<ICartItem> {
    return {
        type: 'ADD',
        data: {
            item: {
                product: product,
                quantities,
                extras,
            } as ICartProductItem,
        },
    };
}

export function createAddPackageToCartEvent(
    pkg: BilberryPackage,
    vats: { [ticket_option_id: string]: BilberryPackageAvailabilityVat[] },
    quantities: PackagePriceQuantity[],
    products: ICartPackageItemProduct[],
    start: string,
    end: string,
    extras: ICartExtra[] = [],
): IAddEvent<ICartItem> {
    return {
        type: 'ADD',
        data: {
            item: {
                pkg,
                quantities,
                vats,
                selectedProducts: products,
                start,
                end,
                extras,
            } as ICartPackageItem,
        },
    };
}

export function createAddMultiDayProductToCartEvent(
    products: BilberryProduct[],
    quantities: BookingPriceAndQuantity[],
    extras: ICartExtra[] = [],
    dateRangeVariant: 'nights' | 'days',
): IAddEvent<ICartItem> {
    return {
        type: 'ADD',
        data: {
            item: {
                products: products,
                quantities,
                extras,
                dateRangeVariant,
            } as ICartMultiDayProductItem,
        },
    };
}

export function createAddAccommodationToCartEvent(
    accommodation: BilberryAccommodation,
    price: AccommodationPrice,
    numberOfGuests: number,
    start: Date,
    end: Date,
): IAddEvent<ICartItem> {
    return {
        type: 'ADD',
        data: {
            item: {
                accommodation: accommodation,
                price: price,
                id: generateID().toString(),
                numberOfGuests: numberOfGuests,
                start,
                end,
            } as ICartAccommodationItem,
        },
    };
}

export function removeFromCartEvent(prefixedId: string): IRemoveEvent<string> {
    return {
        type: 'REMOVE',
        data: prefixedId,
    };
}

export function clearCartEvent(): IClearEvent {
    return {
        type: 'CLEAR',
    };
}

export function createAddExtraToCartEvent(
    cartItem: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem,
    extras: BilberryExtra[],
): IAddExtraEvent {
    if (isICartPackageItemType(cartItem)) {
        const ticketOptionIds = uniq(cartItem.selectedProducts.map((x) => x.ticketOptionId));
        return {
            type: 'ADD_EXTRA',
            data: {
                cartItem,
                extras: ticketOptionIds.flatMap((ticketOptionId) =>
                    extras.map((e) => ({
                        ...e,
                        ticketOptionId,
                    })),
                ),
            },
        };
    }
    return {
        type: 'ADD_EXTRA',
        data: {
            cartItem,
            extras,
        },
    };
}
