import { Box, Slide } from '@mui/material';
import { h } from 'preact';
import { zIndex } from 'src/utils/common/theme/Theme';

type OverlayProps = {
    children: any;
};

export default function Overlay({ children }: OverlayProps) {
    return (
        <Slide in={true} direction="left">
            <Box
                sx={{
                    position: 'fixed',
                    zIndex: zIndex.base,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: '#fff',
                    overflow: 'auto',
                }}
            >
                {children}
            </Box>
        </Slide>
    );
}
