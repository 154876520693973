import { Button, Grid, Link, List, Paper, Typography, useTheme } from '@mui/material';
import { Fragment, h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { capitalize } from 'src/utils/common/TextUtils';

export default function Receipt(props: { bookingRef?: string }): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();
    const linkUrl = '/';

    return (
        <List
            sx={{
                marginTop: theme.spacing(2),
                color: theme.palette.secondary.main,
                paddingTop: 0,
                minWidth: '300px',
                width: '100%',
                maxWidth: '440px',
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
        >
            <Paper
                sx={{
                    fontWeight: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '100%',
                    marginBottom: theme.spacing(2),
                    '& > *': {
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        fontWeight: 300,
                    },
                }}
                variant="outlined"
            >
                <Grid container alignItems="center">
                    {props.bookingRef && (
                        <Fragment>
                            <Typography>{capitalize(t.your_booking_reference_is)}&nbsp;</Typography>
                            <Typography variant="h5">{props.bookingRef}</Typography>
                        </Fragment>
                    )}

                    <Typography>{capitalize(t.booking_details_in_email)}.</Typography>
                </Grid>
            </Paper>
            <Link href={linkUrl} className="no-print">
                <Button variant="outlined" color="primary">
                    {t.see_more_activities}
                </Button>
            </Link>
        </List>
    );
}
