import { Box, CircularProgress, Grid } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { useBilberrySettings } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

export default function GiftTypeSelectionBody(): JSX.Element {
    const { locale } = useLocale();
    const configuration = useConfigurations();

    const { bilberrySettings, isError, isLoading } = useBilberrySettings(locale, configuration);

    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            maxWidth="100%"
            sx={{
                backgroundImage: 'linear-gradient(135deg, #B4B4B4 0%,  #DBDBDB 100%)',
            }}
        >
            {!isLoading && !isError ? (
                <Box
                    component="img"
                    maxWidth="100%"
                    src={bilberrySettings?.giftcard_dummy_image ?? ''}
                    alt="giftcard image"
                />
            ) : (
                <CircularProgress
                    color="secondary"
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
            )}
        </Grid>
    );
}
