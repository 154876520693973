import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import sumBy from 'lodash-es/sumBy';
import { h, RefObject } from 'preact';
import { route } from 'preact-router';
import { StateUpdater, useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import BookingBase from 'src/components/domain/booking-base/BookingBase';
import BookingToggleOpenButton from 'src/components/domain/booking-widget-card/BookingToggleOpenButton';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from 'src/i18n/locale';
import { createAddAccommodationToCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { AccommodationPrice, BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { mapAccommodationPriceToBookingPriceAndQuantity } from 'src/utils/domain/accommodations/transformations';
import { useAccommodationsAvailabilityData } from 'src/utils/domain/api/availabilityDataHook';
import { useAccommodation } from 'src/utils/domain/api/bilberry-hotels-api-client';
import { getNumberOfTravelers } from 'src/utils/domain/booking/bookingHelpers';
import { getInitialQuantityData } from 'src/utils/domain/price-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

interface IProps {
    accommodationId: string;
    expandArrowInside: boolean;
    positionOffscreen: boolean;
}

export default function Booking(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const { accommodationId, expandArrowInside, positionOffscreen } = props;
    const {
        onToggleVisible,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    } = useBookingState();
    const customizations = useCustomizations();
    const config = useConfigurations();
    const [selectedPrice, setSelectedPrice] = useState<AccommodationPrice | null>(null);
    const minGuests = selectedPrice?.minGuests ? selectedPrice.minGuests : 1;
    const initialAdults = Math.ceil(minGuests / 2);
    const initialChildren = Math.floor(minGuests / 2);

    const [quantities, setQuantities] = useState<BookingPriceAndQuantity[]>([
        {
            name: t.adult.plural,
            quantity: initialAdults,
            price: 0,
            occupancy: 1,
            price_type: 'adult',
            price_category_id: 0,
            age_to: null,
            age_from: null,
        },
        {
            name: t.children.plural,
            quantity: initialChildren,
            price: 0,
            occupancy: 1,
            price_type: 'children',
            price_category_id: 1,
            age_to: null,
            age_from: null,
        },
    ]);
    const { availabilitySearchPeriod, availabilityData, setAvailabilitySearchPeriod } =
        useAccommodationsAvailabilityData(accommodationId, getNumberOfTravelers(quantities));
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const [showPriceError, setShowPriceError] = useState(false);
    const numPrices = Object.values(availabilityData).flat()[0]?.prices.length;

    const { accommodation } = useAccommodation(
        accommodationId,
        dateRange,
        sumBy(quantities, 'quantity'),
        locale,
        config,
    );

    useWidgetEventEffect(
        (accommodation) => ({
            eventType: 'viewItem',
            product: accommodation,
            productType: 'Accommodation',
        }),
        accommodation,
        visible,
    );

    const accommodationPrices = useMemo(
        () =>
            accommodation
                ? accommodation.prices.map(mapAccommodationPriceToBookingPriceAndQuantity)
                : [],
        [accommodation],
    );

    const shouldUseDefaultPrice = useDefaultPrice(setSelectedPrice, accommodation);

    const priceQuantities = getPriceQuantities(accommodation, selectedPrice);

    const onSelectPrice = (price: AccommodationPrice | null) => {
        setShowPriceError(false);
        setSelectedPrice(price);

        if (!price) return;

        const newQuantities: BookingPriceAndQuantity[] = [
            {
                ...mapAccommodationPriceToBookingPriceAndQuantity(price),
                quantity: quantities[0].quantity,
                name: t.adult.plural,
            },
            {
                ...mapAccommodationPriceToBookingPriceAndQuantity(price),
                quantity: quantities[1].quantity,
                name: t.children.plural,
            },
        ];
        setQuantities(newQuantities);
    };

    function noExtendSetDateRange(newRange: DateRange<Dayjs>) {
        //Removes the ability to extend range in both directions in mui calendar
        if (!dateRange[0]?.isSame(newRange[0], 'day')) {
            newRange[1] = null;
        }
        setDateRange(newRange);
    }

    function onQuantityChange(
        value:
            | BookingPriceAndQuantity[]
            | ((prevValue: BookingPriceAndQuantity[]) => BookingPriceAndQuantity[]),
    ) {
        setSelectedPrice(null);
        setQuantities(value);
    }
    const onClickBook = useOnClickBook(
        quantities,
        attemptedBooking,
        setAttemptedBooking,
        selectedPrice,
        setShowPriceError,
        dateRange,
        minGuests,
        accommodation,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        accommodationPrices,
        config,
        t,
    );
    return (
        <BookingBase
            jsxSectionEnd={
                !shouldUseDefaultPrice ? (
                    <Dropdown
                        value={selectedPrice ?? null}
                        options={accommodation?.prices ?? []}
                        getOptionLabel={(opt) => opt?.name ?? t.select_price_type}
                        onChange={onSelectPrice}
                        labelColor={customizations.bookingWidgetColorContrast}
                        label={t.select_price_type}
                        error={showPriceError ? t.please_select_price_type : undefined}
                    />
                ) : undefined
            }
            minDate={config.openingDate ? dayjs(config.openingDate) : undefined}
            onSelectDateRange={noExtendSetDateRange}
            selectedDateRange={dateRange}
            bookingCardRef={boxRef}
            hideLeftButton={true}
            productTitle={accommodation?.name ?? ''}
            travelerQuantities={quantities}
            priceQuantities={priceQuantities}
            numPrices={numPrices}
            setQuantities={onQuantityChange}
            defaultQuantities={getInitialQuantityData(accommodationPrices, minGuests)}
            availabilityData={availabilityData}
            rightButtonLabel={capitalize(t.book_now)}
            onClickRightButton={onClickBook}
            availabilitySearchPeriod={availabilitySearchPeriod}
            setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            attemptedBooking={attemptedBooking}
            productCapacity={accommodation?.capacity}
            title={capitalize(t.book_now)}
            visible={visible}
            onToggleVisible={onToggleVisible}
            fromPrice={accommodation?.prices[0]?.value ?? 0}
            expandArrowInside={expandArrowInside}
            minEntrants={minGuests}
            dateRangeVariant="nights"
            toggleButton={
                <BookingToggleOpenButton expandArrowInside={expandArrowInside} visible={visible} />
            }
            positionOffscreen={positionOffscreen}
        />
    );
}
function useOnClickBook(
    quantities: BookingPriceAndQuantity[],
    attemptedBooking: boolean,
    onBookingAttempt: StateUpdater<boolean>,
    selectedPrice: AccommodationPrice | null,
    setShowPriceError: StateUpdater<boolean>,
    dateRange: DateRange<dayjs.Dayjs>,
    minGuests: number,
    accommodation: BilberryAccommodation,
    shouldShowBasketOnBook: boolean,
    boxRef: RefObject<HTMLDivElement>,
    onQuantitiesChange: StateUpdater<BookingPriceAndQuantity[]>,
    hasChosenDate: boolean,
    accommodationPrices: BookingPriceAndQuantity[],
    config: any,
    t: any,
) {
    return useCallback(() => {
        const guests = sumBy(quantities, 'quantity');

        if (!attemptedBooking) onBookingAttempt(true);
        if (!selectedPrice) setShowPriceError(true);
        if (!dateRange[0] || !dateRange[1] || guests < minGuests || !selectedPrice) {
            return;
        }

        const price =
            accommodation?.prices.find((x) => x.id === selectedPrice?.id) ??
            accommodation?.prices[0];

        // We must ensure we actually find a price, otherwise the client will get a broken application state (localstorage)
        if (!price) {
            setShowPriceError(true);
            return;
        }

        cartAtom.update(
            createAddAccommodationToCartEvent(
                accommodation,
                price,
                guests,
                dateRange[0].toDate(),
                dateRange[1].toDate(),
            ),
        );

        if (shouldShowBasketOnBook)
            showBasketAtom.update({
                visible: true,
                refocusElementOnClose: boxRef,
            });

        toggleBookingAtom.update({ visible: false });

        onQuantitiesChange(
            getInitialQuantityData(
                [
                    {
                        name: t.adult.plural,
                        quantity: 1,
                        price: 0,
                        occupancy: 1,
                        price_type: 'adult',
                        price_category_id: 1,
                        age_to: null,
                        age_from: null,
                    },
                    {
                        name: t.children.plural,
                        quantity: 0,
                        price: 0,
                        occupancy: 1,
                        price_type: 'children',
                        price_category_id: 2,
                        age_to: null,
                        age_from: null,
                    },
                ],
                minGuests,
            ),
        );

        if (!shouldShowBasketOnBook) route('/checkout');
    }, [attemptedBooking, hasChosenDate, quantities, boxRef, accommodationPrices, config]);
}

function getPriceQuantities(
    accommodation: BilberryAccommodation | undefined,
    selectedPrice: AccommodationPrice | null,
): BookingPriceAndQuantity[] {
    const price = accommodation?.prices.find((x) => x.id === selectedPrice?.id);
    return price
        ? [
              {
                  ...mapAccommodationPriceToBookingPriceAndQuantity(price),

                  quantity: 1,
              },
          ]
        : [];
}

function useDefaultPrice(
    setSelectedPrice: (val: AccommodationPrice) => void,
    accommodation?: BilberryAccommodation,
) {
    const shouldUseDefaultPrice = accommodation && accommodation.prices.length === 1;

    useEffect(() => {
        if (shouldUseDefaultPrice) {
            setSelectedPrice(accommodation!.prices[0]);
        }
    }, [accommodation]);

    return shouldUseDefaultPrice;
}
