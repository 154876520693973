import CancelRounded from '@mui/icons-material/CancelRounded';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import {
    Box,
    Button,
    Collapse,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { StateUpdater, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { ICartItem } from 'src/state/cart/ICart';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { usePromocodeStatus } from 'src/utils/domain/api/bilberry-api-client';
import { calculateActivityPromoCodePriceReduction } from 'src/utils/domain/cart/priceReductions';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

interface Props {
    cartItems: ICartItem[];
    totalPrice: number;
    onPromoCodeApplied?: (appliedPromoCode: BilberryPromoCodeStatus | null) => void;
    appliedPromoCode?: BilberryPromoCodeStatus | null;
    onError: (message: string) => void;
}

export default function SummaryPromoCode(props: Props): JSX.Element {
    const customizations = useCustomizations();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const { t, locale } = useLocale();
    const [inputValue, setInputValue] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const promoCodeStatus = getPromocodeStatusData(
        promoCode,
        setPromoCode,
        setShowError,
        setErrorMessage,
        props.onError,
        props.onPromoCodeApplied,
    );

    const priceReduction = calculateActivityPromoCodePriceReduction(
        props.cartItems,
        promoCodeStatus,
    );

    const endAdornment = inputValue !== '' && (
        <Box
            sx={{
                '& .MuiIconButton-root': {
                    padding: 0,
                },
            }}
        >
            <IconButton
                sx={{
                    padding: 0,
                    '& .MuiSvgIcon-root': {
                        color: customizations.linkColor,
                    },
                    color: customizations.linkColor,
                }}
                onClick={() => {
                    setInputValue('');
                    setPromoCode('');
                    setShowError(false);
                    props.onPromoCodeApplied?.call(null, null);
                }}
            >
                <CancelRounded fontSize="small" />
            </IconButton>
        </Box>
    );

    return (
        <Box borderBottom={`1px solid ${theme.palette.grey[400]}`}>
            <Grid
                container
                onClick={() => {
                    setExpanded(!expanded);
                }}
                alignItems="center"
            >
                <Button
                    color="primary"
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: customizations.linkColor,
                        },
                        color: customizations.linkColor,
                        padding: 0,
                    }}
                >
                    {t.enter_promo_code}
                </Button>
                <IconButton
                    sx={[
                        {
                            transition: theme.transitions.create('transform', {
                                duration: theme.transitions.duration.standard,
                            }),
                            '& .MuiSvgIcon-root': {
                                color: customizations.linkColor,
                            },
                            color: customizations.linkColor,
                        },
                        expanded && { transform: 'rotate(180deg)' },
                    ]}
                >
                    <KeyboardArrowDownRounded />
                </IconButton>
            </Grid>
            <Collapse
                in={expanded}
                sx={{ paddingBottom: theme.spacing(2) }}
                timeout="auto"
                unmountOnExit
            >
                <Fragment>
                    <Grid container wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <TextField
                                error={showError}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    marginRight: theme.spacing(1),
                                    width: '264px',
                                    [`@media screen and (max-width: ${768}px)`]: {
                                        width: '232px',
                                    },
                                }}
                                onChange={(
                                    e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                                ) => {
                                    setInputValue(e.currentTarget.value);
                                    showError && setShowError(false);
                                }}
                                onKeyDown={(ev: KeyboardEvent) => {
                                    if (ev.code.toLowerCase() === 'enter') {
                                        setPromoCode(inputValue);
                                    }
                                }}
                                variant="outlined"
                                value={inputValue}
                                color="secondary"
                                placeholder="Enter code"
                                disabled={props.appliedPromoCode !== null}
                                InputProps={{
                                    endAdornment: endAdornment,
                                }}
                                size="small"
                            />
                            {showError && (
                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                    {errorMessage}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Button
                            variant="outlined"
                            sx={{
                                borderRadius: theme.spacing(1),
                                borderColor: theme.palette.primary.main,
                                height: theme.spacing(5),
                            }}
                            onClick={() => setPromoCode(inputValue)}
                        >
                            {t.apply.toUpperCase()}
                        </Button>
                    </Grid>
                    {!!promoCodeStatus && !showError && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            pt={theme.spacing(2)}
                        >
                            <Typography color="secondary" mr={theme.spacing(2)}>
                                {promoCodeStatus?.coupon_code}
                            </Typography>
                            <Typography color="secondary">
                                -{getLocaleNumberFormatTwoDecimals(locale, priceReduction)}
                            </Typography>
                        </Grid>
                    )}
                </Fragment>
            </Collapse>
        </Box>
    );
}

function getPromocodeStatusData(
    promoCode: string,
    setPromoCode: StateUpdater<string>,
    setShowError: StateUpdater<boolean>,
    setErrorMessage: StateUpdater<string>,
    onError: (message: string) => void,
    onPromoCodeApplied?: (appliedPromoCode: BilberryPromoCodeStatus | null) => void,
) {
    const { t, locale } = useLocale();
    const configuration = useConfigurations();
    const { data: promoCodeStatus } = usePromocodeStatus(
        promoCode,
        locale,
        configuration,
        () => {
            setErrorMessage(t.couldnt_apply_promocode_is_the_code_correct);
            setShowError(true);
            setPromoCode('');
            onError(t.couldnt_apply_promocode_is_the_code_correct);
        },
        (promoCodeStatus) => {
            onPromoCodeApplied?.call(null, promoCodeStatus);
        },
    );
    return promoCodeStatus;
}
