import { Box, Button, Grid, useTheme } from '@mui/material';
import { BilberryPromoCodeStatus } from 'src/types/bilberry-api-types';
import { AppliedGiftCard } from 'src/types/giftcards';
import { useLocale } from 'src/i18n/locale';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import Summary from 'src/components/domain/summary/Summary';
import { showError } from 'src/utils/common/error-handling';
import { ButtonDisplay } from './ButtonDisplay';
import TourSelection from 'src/components/domain/package/tour-selection/TourSelection';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { cartAtom } from 'src/state/cart/cartAtom';
import { useAtom } from 'ximple';
import { isICartPackageItemType } from 'src/utils/domain/cart/cartUtils';

type PackageViewProps = {
    largeScreen: boolean;
    appliedGiftcard: AppliedGiftCard | null;
    setAppliedGiftcard: (val: AppliedGiftCard | null) => void;
    appliedPromoCode: BilberryPromoCodeStatus | null;
    setAppliedPromoCode: (val: BilberryPromoCodeStatus | null) => void;
    onNextClicked: () => void;
    nextButtonText: string;
    isMakingReservation: boolean;
};

export default function PackageView(props: PackageViewProps) {
    const {
        largeScreen,
        appliedGiftcard,
        appliedPromoCode,
        setAppliedPromoCode,
        setAppliedGiftcard,
        isMakingReservation,
        onNextClicked,
        nextButtonText,
    } = props;
    const { t } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();
    const [cartItems] = useAtom(cartAtom);

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'Package',
        cartItems: Object.values(cartItems),
    }));

    const packageItems = Object.values(cartItems)
        .map(({ item }) => item)
        .filter(isICartPackageItemType);

    return (
        <Grid
            container
            justifyContent={largeScreen ? 'space-between' : 'center'}
            alignItems={largeScreen ? 'flex-start' : 'center'}
            direction={largeScreen ? 'row' : 'column-reverse'}
        >
            {!largeScreen && (
                <Box
                    maxWidth="440px"
                    width="100%"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            marginBottom: theme.spacing(3),
                        },
                    }}
                >
                    <Button
                        sx={{ float: 'right', marginTop: theme.spacing(2) }}
                        color="primary"
                        variant={customizations.primaryButtonStyle}
                        disabled={isMakingReservation}
                        onClick={onNextClicked}
                    >
                        <ButtonDisplay
                            buttonText={nextButtonText}
                            isMakingReservation={isMakingReservation}
                        />
                    </Button>
                </Box>
            )}

            {packageItems.map((item) => (
                <TourSelection item={item} key={`package-tour-selection-item-${item.pkg.id}`} />
            ))}

            <Summary
                showShare
                hideGoPay={!largeScreen}
                goPayClicked={onNextClicked}
                nextButtonText={nextButtonText}
                isCheckoutSummary={true}
                isMakingReservation={isMakingReservation}
                onGiftcardApplied={setAppliedGiftcard}
                appliedGiftcard={appliedGiftcard}
                appliedPromoCode={appliedPromoCode}
                onPromoCodeApplied={setAppliedPromoCode}
                onError={(message: string) => showError(message)}
            />
        </Grid>
    );
}
