import { alpha, Box, Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { SmallerTextInline } from 'src/components/common/smaller-text-inline/SmallerTextInline';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency, getLocaleNumberFormatNoDecimals } from 'src/i18n/Localization';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useAtom } from 'ximple/atoms';

export interface IProps {
    header: string;
    quantities: BookingPriceAndQuantity[];
    numPrices: number;
}

export function CostSummary(props: IProps): JSX.Element {
    const { locale } = useLocale();
    const { quantities, header, numPrices } = props;
    const [currency] = useAtom(currencyAtom);
    const { bookingWidgetColorContrast } = useCustomizations();
    const theme = useTheme();
    const numPaddedRows = Math.max(0, numPrices - quantities.filter((q) => q.quantity > 0).length);

    return (
        <Box>
            <Typography
                variant="h6"
                color={bookingWidgetColorContrast}
                mt={theme.spacing(2)}
                width={'100%'}
                borderBottom="1px solid"
                borderColor={alpha(bookingWidgetColorContrast, 0.2)}
            >
                {header}
            </Typography>
            <Grid mb={theme.spacing(3)} minHeight={`calc(2rem + ${theme.spacing(1)})`}>
                {quantities.map(
                    (quantity) =>
                        quantity.quantity > 0 && (
                            <Grid
                                key={quantity.name + quantity.price_type}
                                container
                                justifyContent="space-between"
                                pt={theme.spacing(1)}
                            >
                                <Typography color={bookingWidgetColorContrast}>
                                    {quantity.quantity}x {quantity.name}{' '}
                                    <SmallerTextInline size="75%">
                                        ({formatCurrency(locale, currency, quantity.price)})
                                    </SmallerTextInline>
                                </Typography>
                                <Typography color={bookingWidgetColorContrast}>
                                    {getLocaleNumberFormatNoDecimals(
                                        locale,
                                        quantity.price * quantity.quantity,
                                    )}
                                </Typography>
                            </Grid>
                        ),
                )}
                {new Array(numPaddedRows).fill(false).map((_x, i) => (
                    <Grid container justifyContent="space-between" key={i} pt={theme.spacing(1)}>
                        <Typography>&nbsp;</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
