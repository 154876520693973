import { SvgIconComponent } from '@mui/icons-material';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { getIconSet } from 'src/utils/domain/accommodations/accommodationIconUtils';

type AccommodationOverviewFacilityIconsProps = {
    accommodation: BilberryAccommodation;
    numberOfIcons?: number;
};

export default function AccommodationOverviewFacilityIcons({
    accommodation,
    numberOfIcons,
}: AccommodationOverviewFacilityIconsProps) {
    const { t } = useLocale();

    const notPhoneScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const iconCount = notPhoneScreen ? 5 : 3;
    const icons = getIconSet(
        accommodation,
        numberOfIcons ? numberOfIcons : iconCount,
        t,
        (icon, label) => <AccommodationOverviewIcon Icon={icon} label={label} />,
    );

    return (
        <Grid container item>
            <Grid
                container
                direction="row"
                mx="auto"
                width="100%"
                justifyContent="center"
                wrap="nowrap"
                sx={(theme) => ({ [theme.breakpoints.down(340)]: { flexWrap: 'wrap' } })}
            >
                {icons}
            </Grid>
        </Grid>
    );
}

function AccommodationOverviewIcon(props: { Icon: SvgIconComponent; label: string }) {
    const { Icon, label } = props;
    const customizations = useCustomizations();

    return (
        <Grid
            container
            item
            pb={2}
            sx={{
                width: 'auto',
                boxSizing: 'border-box',
                ['&:last-child']: {
                    paddingRight: 0,
                },
            }}
        >
            <Grid container direction="column" flexGrow={1} alignItems="center">
                <Icon
                    sx={(theme) => ({
                        fontSize: '1.5em',
                        minWidth: '112px',
                        [theme.breakpoints.up('sm')]: {
                            fontSize: '2.5em',
                        },
                    })}
                />
                <Typography
                    variant="h5"
                    pt={1}
                    fontSize={customizations.fontSize * 0.85}
                    sx={{
                        '& *': {
                            whiteSpace: 'break-spaces',
                        },
                    }}
                >
                    <Box>{label}</Box>
                </Typography>
            </Grid>
        </Grid>
    );
}
