import { Grid, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import { capitalize } from 'src/utils/common/TextUtils';
import { configurationAtom } from 'src/utils/domain/widgetsConfiguration';
import { useAtom } from 'ximple';

export default function SummaryGiftcardVat(props: { giftcardPrice: number }): JSX.Element | null {
    const { t, locale } = useLocale();
    const theme = useTheme();
    const [configuration] = useAtom(configurationAtom);

    if (configuration.hideVat) return null;

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            borderBottom={`1px solid ${theme.palette.grey[400]}px`}
            borderTop={`1px solid ${theme.palette.grey[400]}px`}
            p={theme.spacing(1, 0)}
            mt={theme.spacing(6)}
        >
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex={3}
                flexShrink={1}
            >
                <Typography align="right" color="textSecondary">
                    {capitalize(t.excl)}&nbsp;{t.vat}
                </Typography>

                <Typography align="right" color="textSecondary">
                    {t.vat}&nbsp;{`0%`}
                </Typography>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex={1}
                flexShrink={1}
            >
                <Typography align="right" color="textSecondary">
                    {getLocaleNumberFormatTwoDecimals(locale, props.giftcardPrice)}
                </Typography>

                <Typography align="right" color="textSecondary">
                    {getLocaleNumberFormatTwoDecimals(locale, 0)}
                </Typography>
            </Grid>
        </Grid>
    );
}
