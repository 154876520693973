import { Grid, useTheme } from '@mui/material';
import { h } from 'preact';
import InfoBox from 'src/components/common/info-box/InfoBox';
import { useLocale } from 'src/i18n/locale';

export default function NoAccommodationFoundOverlay() {
    const theme = useTheme();
    const { t } = useLocale();
    return (
        <Grid margin={theme.spacing(4, 'auto')} width="100%" maxWidth="620px">
            <InfoBox text={t.no_accommodations_found} infoType="warning" />
        </Grid>
    );
}
