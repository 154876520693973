import { Purchase } from 'src/components/domain/payment/payment-types';
import { loadDataFromLocalStorage, LocalStorageKey } from '../localstorage';

export function storePurchase(purchase: Purchase | null) {
    localStorage.setItem(LocalStorageKey.BILBERRY_PURCHASE, JSON.stringify(purchase));
}

export function loadPurchase() {
    return loadDataFromLocalStorage(LocalStorageKey.BILBERRY_PURCHASE) as Purchase | null;
}
