import { Box, Grid, SxProps, useTheme } from '@mui/material';
import { h } from 'preact';

interface IProps {
    scroll: boolean;
    numElements: {
        numXs: number;
        numSm: number;
        numMd: number;
        numLg: number;
    };
    children: any;
    sx?: SxProps;
    productListPadding?: number;
}

export default function ProductCardListContainer(props: IProps): JSX.Element {
    const { scroll, children, sx, productListPadding } = props;
    const theme = useTheme();

    if (scroll) {
        return (
            <Grid
                container
                item
                width="100%"
                gap={2}
                sx={{ overflowX: 'auto' }}
                direction="row"
                wrap={props.scroll ? 'nowrap' : 'wrap'}
            >
                {children}
            </Grid>
        );
    } else {
        return (
            <Box
                display="flex"
                justifyContent="center"
                p={productListPadding ?? 0}
                sx={{
                    [theme.breakpoints.down('md')]: {
                        paddingX: productListPadding ?? theme.spacing(2),
                    },
                    ...sx,
                }}
            >
                <Box
                    display="grid"
                    gap={theme.spacing(2)}
                    gridTemplateColumns={`repeat(${props.numElements.numXs}, 1fr)`}
                    sx={{
                        placeItems: 'center',
                        [theme.breakpoints.up('sm')]: {
                            gridTemplateColumns: `repeat(${props.numElements.numSm}, 1fr)`,
                        },
                        [theme.breakpoints.up('md')]: {
                            gridTemplateColumns: `repeat(${props.numElements.numMd}, 1fr)`,
                        },
                        [theme.breakpoints.up('lg')]: {
                            gridTemplateColumns: `repeat(${props.numElements.numLg}, 1fr)`,
                        },
                    }}
                >
                    {children}
                </Box>
            </Box>
        );
    }
}
