import { Box, Typography, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { h } from 'preact';
import { useEffect } from 'preact/compat';
import { useState } from 'preact/hooks';
import UpcomingDaySelector from 'src/components/domain/upcoming-tours/DaySelector/UpcomingDaySelector';
import { getCustomOrDefaultText } from 'src/i18n/i18n';
import { useLocale } from 'src/i18n/locale';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { capitalize } from 'src/utils/common/TextUtils';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { useUpcomingTours } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import UpcomingToursList from './UpcomingToursList';

interface IContainerProps {
    useMonths: boolean;
    productCatalogIds: number[];
    numberOfDaysToDisplay: number;
    fewLeft: number;
    titleTextCustomizationKey?: keyof UserTextCustomizations;
    disableTitle: boolean;
    disableTogglebuttons: boolean;
}

export function UpcomingToursContainer({
    useMonths,
    productCatalogIds,
    numberOfDaysToDisplay,
    fewLeft,
    titleTextCustomizationKey,
    disableTitle,
    disableTogglebuttons,
}: IContainerProps) {
    const config = useConfigurations();
    const { t, locale } = useLocale();
    const startDate = dayjs();
    const endDate = startDate.add(numberOfDaysToDisplay, 'day');
    const { tours } = useUpcomingTours(productCatalogIds, startDate, endDate, locale, config);

    return (
        <UpcomingTours
            fewLeft={fewLeft}
            tours={tours}
            useMonths={useMonths}
            title={
                disableTitle
                    ? undefined
                    : capitalize(
                          getCustomOrDefaultText(
                              config.textCustomizations,
                              titleTextCustomizationKey,
                              locale,
                              t.upcoming_tours_title,
                          ),
                      )
            }
            disableTogglebuttons={disableTogglebuttons}
        ></UpcomingTours>
    );
}

interface IProps {
    useMonths: boolean;
    tours: BilberryProduct[];
    fewLeft: number;
    title?: string;
    disableTogglebuttons: boolean;
}

export function UpcomingTours({ tours, useMonths, fewLeft, title, disableTogglebuttons }: IProps) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const [shownTours, setShownTours] = useState<BilberryProduct[]>(tours);
    const [days, setDays] = useState<Dayjs[]>([]);
    const [day, setDay] = useState(dayjs());

    useEffect(() => {
        const days = tours
            .map((x) => dayjs(x.start))
            .sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
            .reduce<Dayjs[]>((unique: Dayjs[], day, index) => {
                if (index > 0) {
                    if (unique[unique.length - 1].isSame(day, useMonths ? 'month' : 'day'))
                        return unique;
                }
                unique.push(day);
                return unique;
            }, []);

        setDays(days);

        const day = days.length > 0 ? days[0] : dayjs();
        setDay(day);

        setShownTours(
            disableTogglebuttons
                ? tours
                : tours.filter((x) => dayjs(x.start).isSame(day, useMonths ? 'month' : 'day')),
        );
    }, [tours]);

    const onChangeDay = (day: Dayjs) => {
        setDay(day);
        setShownTours(
            disableTogglebuttons
                ? tours
                : tours.filter((x) => dayjs(x.start).isSame(day, useMonths ? 'month' : 'day')),
        );
    };

    return (
        <Box
            color="white"
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={theme.spacing(1)}
            sx={{
                backgroundColor: customizations.upcomingToursBackgroundColor,
            }}
        >
            {title && title.length > 0 && (
                <Typography
                    color={{ color: customizations.upcomingToursTitleTextColor }}
                    variant="h2"
                >
                    {title}
                </Typography>
            )}
            {!disableTogglebuttons && (
                <UpcomingDaySelector
                    day={day}
                    onDayChange={onChangeDay}
                    days={days}
                    useMonths={useMonths}
                ></UpcomingDaySelector>
            )}
            <UpcomingToursList fewLeft={fewLeft} upcomingTours={shownTours}></UpcomingToursList>
        </Box>
    );
}
