import { Button, darken, Stack, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import { BookingBaseForm } from 'src/components/domain/booking-base/BookingBase';
import { WarningMessage } from 'src/components/domain/booking-base/BookingCardWarningMessage';
import { ActivityProductCardInfoContent } from 'src/components/domain/product-card-2/ActivityProductCard';
import {
    ProductCardContainer,
    ProductCardHeader,
} from 'src/components/domain/product-card-2/ProductCard2';
import { ProductCardInfoContainer } from 'src/components/domain/product-card-2/ProductCardInfo';
import { useOnClickBook } from 'src/hooks/domain/activities/useOnClickBook';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from 'src/i18n/locale';
import { BilberryPriceQuantity, BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryProduct, BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { CustomizationsContext, useCustomizations } from 'src/utils/common/theme/customizations';
import { ThemeType } from 'src/utils/common/theme/ThemeType';
import {
    getBilberryProductMaxEntrants,
    getBilberryProductMinEntrants,
} from 'src/utils/domain/activities/bilberryProductEntrants';
import { useAvailabilityData } from 'src/utils/domain/api/availabilityDataHook';
import {
    useProductCatalogsByIds,
    useProductCollectionById,
} from 'src/utils/domain/api/bilberry-api-client';
import { getNumberOfTravelers } from 'src/utils/domain/booking/bookingHelpers';
import { calculateMultiDayPriceQuantities } from 'src/utils/domain/booking/multiDayPriceHelper';
import { getInitialQuantityData, updateQuantityData } from 'src/utils/domain/price-helper';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductCardListContainer from '../product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from '../product-list-2/product-card-list-2/ProductCardListItem';

type Props = {
    scroll: boolean;
    numElements: MediaQueryAttributeInput;
    backgroundColors: string[];
    textColors: string[];
    primaryColors: string[];
    primaryTextColors: string[];
    accentColors: string[];
    accentTextColors: string[];
    productCollectionId?: number;
    productCatalogIds?: number[];
    productCatalogUrls?: string[];
    hideReadMore?: boolean;
};

export function ProductListBookable(props: Props) {
    const { locale } = useLocale();
    const config = useConfigurations();

    const {
        scroll,
        numElements,
        accentColors,
        accentTextColors,
        backgroundColors,
        primaryColors,
        primaryTextColors,
        textColors,
        productCollectionId,
        productCatalogIds,
        productCatalogUrls = [],
    } = props;
    const customizations = useCustomizations();

    const { productCatalogs } = useProductCatalogsByIds(productCatalogIds ?? null, locale, config);

    const { productCollection } = useProductCollectionById(
        productCollectionId ?? null,
        locale,
        config,
    );

    const catalogsFromCollection = productCollection?.products;
    const catalogsToUse = (catalogsFromCollection ? catalogsFromCollection : productCatalogs) ?? [];

    const productCardList = catalogsToUse.map((catalog, i) => {
        const url = productCatalogUrls[i] ?? catalog.web_url ?? '#';
        const { length } = backgroundColors;
        const cur = i % length;
        const alternatingCustomizations: ThemeType = {
            ...customizations,
            productCardColor: backgroundColors[cur] ?? customizations.productCardColor,
            productCardTextColor: textColors[cur] ?? customizations.productCardTextColor,
            productCardPrimaryColor: primaryColors[cur] ?? customizations.productCardPrimaryColor,
            productCardPrimaryColorContrast:
                primaryTextColors[cur] ?? customizations.productCardPrimaryColorContrast,
            productCardAccentColor: accentColors[cur] ?? customizations.productCardAccentColor,
            productCardAccentColorContrast:
                accentTextColors[cur] ?? customizations.productCardAccentColorContrast,
            bookingWidgetColorContrast: textColors[cur] ?? customizations.productCardTextColor,
        };

        return (
            <CustomizationsContext.Provider value={alternatingCustomizations} key={catalog.id}>
                <ProductCardListItem
                    scroll={scroll}
                    numElements={numElements}
                    numProductCatalogs={catalogsToUse.length}
                >
                    <ProductCardContainer>
                        <ProductCardHeader
                            imageUrl={catalog.media.image.url}
                            url={url}
                            category={catalog.web_category}
                        />
                        <ProductCardInfoContainer>
                            <ActivityProductCardInfoContent
                                product={catalog}
                                url={url}
                                readMoreLinkVariant={props.hideReadMore ? 'none' : 'href'}
                                readMoreProductId={catalog.id}
                            />
                            <ProductCardBookingForm productCatalog={catalog} />
                        </ProductCardInfoContainer>
                    </ProductCardContainer>
                </ProductCardListItem>
            </CustomizationsContext.Provider>
        );
    });

    return (
        <ProductCardListContainer
            scroll={scroll}
            numElements={numElements}
            productListPadding={customizations.productListPadding}
        >
            {productCardList.length > 0 && productCardList}
        </ProductCardListContainer>
    );
}

function ProductCardBookingForm(props: { productCatalog: BilberryProductCatalog }) {
    const { productCatalog } = props;
    const {
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
    } = useBookingState();
    const { t } = useLocale();
    const config = useConfigurations();
    const customizations = useCustomizations();
    const theme = useTheme();
    const [selectedTimeSlot, setSelectedTimeslot] = useState<
        TimeSlotType<BilberryProduct> | undefined
    >(undefined);
    const [quantities, setQuantities] = useState<BilberryPriceQuantity[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<BilberryProduct[] | undefined>(
        undefined,
    );
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const { availabilitySearchPeriod, availabilityData, setAvailabilitySearchPeriod } =
        useAvailabilityData(productCatalog.id, quantities);
    const [productCapacityReached, setProductCapacityReached] = useState<boolean>(false);

    const productCatalogPrices = useMemo(
        () => (productCatalog ? productCatalog.default_prices : []),
        [productCatalog],
    );

    const pricesFromSelectedProduct = useMemo(
        () =>
            selectedProducts && selectedProducts.length > 0
                ? selectedProducts[0].prices
                : undefined,
        [selectedProducts],
    );

    useEffect(() => {
        setQuantities(
            updateQuantityData(pricesFromSelectedProduct, productCatalogPrices, quantities),
        );
    }, [pricesFromSelectedProduct, productCatalogPrices]);

    function noExtendSetDateRange(newRange: DateRange<Dayjs>) {
        //Removes the ability to extend range in both directions in mui calendar
        if (!dateRange[0]?.isSame(newRange[0], 'day')) {
            newRange[1] = null;
        }
        setDateRange(newRange);
    }

    const priceQuantities = useMemo(() => {
        if (selectedProducts && selectedProducts.length > 1 && quantities) {
            return calculateMultiDayPriceQuantities(
                quantities,
                selectedProducts.slice(0, selectedProducts.length - 1),
            );
        }

        return quantities;
    }, [quantities, selectedProducts]);

    const onClickBook = useOnClickBook(
        quantities,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        config,
        selectedProducts,
        setSelectedProducts,
        setSelectedTimeslot,
        productCatalogPrices,
        pricesFromSelectedProduct,
        setDateRange,
        productCatalog.is_accommodation ? 'nights' : productCatalog.is_rental ? 'days' : undefined,
    );
    const minEntrants = getBilberryProductMinEntrants(selectedProducts, productCatalog);

    let disableBookButton = false;
    let disabledReason = null as null | 'minEntrants' | 'capacity';
    const numberOfTravelers = getNumberOfTravelers(priceQuantities);
    if (minEntrants && numberOfTravelers < minEntrants) {
        disableBookButton = true;
        disabledReason = 'minEntrants';
    } else {
        const product = selectedTimeSlot?.product as BilberryProduct | undefined;
        if (
            product &&
            numberOfTravelers > Math.min(product.capacity, product.max_entrants ?? Number.MAX_VALUE)
        ) {
            disabledReason = 'capacity';
            disableBookButton = true;
        }
    }

    function getWarningLabel() {
        if (disabledReason === 'minEntrants') {
            return t.a_minimum_of_x_participants_is_required_to_book_this_product.parsed(
                minEntrants?.toString() ?? '',
            );
        } else if (disabledReason === 'capacity') {
            return t.no_available_capacity_for_this_tour;
        }
    }

    return (
        <Stack alignItems="center" gap={2} textAlign="left">
            <div>
                <BookingBaseForm
                    setSelectedProducts={setSelectedProducts}
                    travelerQuantities={priceQuantities}
                    defaultQuantities={getInitialQuantityData(
                        productCatalogPrices,
                        productCatalog?.min_entrants,
                    )}
                    setQuantities={
                        setQuantities as unknown as StateUpdater<BookingPriceAndQuantity[]>
                    }
                    selectedTimeSlot={selectedTimeSlot}
                    onSelectTimeSlot={setSelectedTimeslot}
                    availabilityData={availabilityData}
                    availabilitySearchPeriod={availabilitySearchPeriod}
                    setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
                    hasChosenDate={hasChosenDate}
                    setHasChosenDate={setHasChosenDate}
                    attemptedBooking={attemptedBooking}
                    productCapacity={getBilberryProductMaxEntrants(selectedProducts)}
                    minEntrants={getBilberryProductMinEntrants(selectedProducts, productCatalog)}
                    onSelectDateRange={noExtendSetDateRange}
                    dateRangeVariant={
                        productCatalog?.is_accommodation
                            ? 'nights'
                            : productCatalog?.is_rental
                            ? 'days'
                            : undefined
                    }
                    selectedDateRange={dateRange}
                    setProductCapacityReached={setProductCapacityReached}
                    productCapacityReached={productCapacityReached}
                    variant="outlined"
                    color={customizations.inputFieldTextColor}
                    labelColor={customizations.productCardTextColor}
                    backgroundColor={theme.palette.common.white}
                    id={productCatalog.id.toString()}
                />
            </div>
            <WarningMessage label={getWarningLabel()} />
            <Button
                onClick={onClickBook}
                disabled={disableBookButton}
                sx={(theme) => ({
                    px: 3,
                    borderRadius: theme.shape,
                    color: customizations.productCardPrimaryColorContrast,
                    backgroundColor: customizations.productCardPrimaryColor,
                    borderColor: customizations.productCardPrimaryColor,
                    '&:hover': {
                        color: darken(customizations.productCardPrimaryColorContrast, 0.1),
                        backgroundColor: darken(customizations.productCardPrimaryColor, 0.1),
                        borderColor: darken(customizations.productCardPrimaryColor, 0.1),
                    },
                })}
            >
                {t.book_now}
            </Button>
        </Stack>
    );
}
