export function getTabIndex(
    tabType: 'package' | 'extras' | 'contactinfo' | 'payment',
    opts: Partial<{ hasPackage: boolean; hasExtras: boolean; display: boolean; }>) {
    const format = (n: number) => {
        if (opts.display) {
            return n + 1;
        } else {
            return n;
        }
    };

    if (opts.hasPackage && opts.hasExtras) {
        switch (tabType) {
            case 'package':
                return format(0);
            case 'extras':
                return format(1);
            case 'contactinfo':
                return format(2);
            case 'payment':
                return format(3);
        }
    } else if (opts.hasPackage) {
        switch (tabType) {
            case 'package':
                return format(0);
            case 'contactinfo':
                return format(1);
            case 'payment':
                return format(2);
        }
    } else if (opts.hasExtras) {
        switch (tabType) {
            case 'extras':
                return format(0);
            case 'contactinfo':
                return format(1);
            case 'payment':
                return format(2);
        }
    } else {
        switch (tabType) {
            case 'contactinfo':
                return format(0);
            case 'payment':
                return format(1);
        }
    }

    return -1;
}

