import dayjs, { Dayjs } from 'dayjs';
import { BASE_MEMBERSHIP_API_URL, USE_SERVICE_PROXY } from 'src/__autogen/env';
import { localeAtom } from 'src/i18n/locale';
import { useAtom } from 'ximple';
import { isAuthenticatedAtom } from 'src/state/authentication';
import { Company, companyAtom } from 'src/state/company';
import { userAtom } from 'src/state/user';
import {
    MembershipBooking,
    MembershipConsumer,
    MembershipIntentResponse,
    MembershipOrder,
    MembershipReserveResponse,
    MembershipTicket,
    MembershipUser,
    MembershipUserValueCard,
    MembershipValueCardProduct,
    UpdateValueCardSubscriptionResponse,
} from 'src/types/membership-api-types';
import { TimeslotsCompanyConfig, TimeslotsValueCardUsage } from 'src/types/timeslots-api-types';
import { getBilberryLanguageFromLocale } from 'src/utils/domain/api/api-client-common';
import useSWR, { mutate } from 'swr';
import { getUserCountry } from 'src/components/common/country-select/countries';
import { useMemo } from 'preact/hooks';
import { showError } from 'src/utils/common/error-handling';

const API_URL = USE_SERVICE_PROXY ? '' : BASE_MEMBERSHIP_API_URL;
const API_VERSION = 'v1';

const apiUrl = () => API_URL + '/' + API_VERSION;

async function get<T = any>(url: string, errorMessage?: string): Promise<T> {
    const token = isAuthenticatedAtom.subject.value?.token;

    const response = await fetch(url, {
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-type': 'application/json',
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error(errorMessage);

    return response.json();
}

async function post(url: string, body?: any, errorMessage?: string) {
    const token = isAuthenticatedAtom.subject.value?.token;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        try {
            const body = await response.json();
            throw new Error(errorMessage + '. Server message: ' + body.title);
        } catch (e) {
            throw e;
        }
    }
    return response.json();
}

async function put(url: string, body?: any, errorMessage?: string) {
    const token = isAuthenticatedAtom.subject.value?.token;
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error(errorMessage);
    return response.json();
}

export function getUrlWithParams(
    url: string,
    locale: string,
    queryParams: Record<string, any> = {},
) {
    const langQueryParam = `lang=${getBilberryLanguageFromLocale(locale)}`.toLowerCase();

    let fullUrl = `${url}?${langQueryParam}`;

    const queryString = new URLSearchParams(queryParams).toString();
    if (queryString.length > 0) fullUrl += `&${queryString}`;
    return fullUrl;
}

export function useUser() {
    const { locale } = localeAtom.subject.value;
    const [{ isAuthenticated }] = useAtom(isAuthenticatedAtom);

    const url = apiUrl() + getUrlWithParams('/Users/me', locale, {});
    const { data, ...swrReturnData } = useSWR<MembershipUser>(isAuthenticated ? url : null, get, {
        errorRetryCount: 1,
    });

    return useMemo(
        () => ({
            data: data ? { ...data, country: getUserCountry(data?.country) } : data,
            ...swrReturnData,
        }),
        [data],
    );
}

export function updateUser(user: MembershipUser) {
    const { locale } = localeAtom.subject.value;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const url = apiUrl() + getUrlWithParams('/Users/me', locale, {});
    return mutate(url, putUser(user), true);
}

export async function getUser() {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    const response = await get(url);
    userAtom.update({
        type: 'UPDATE_INFO',
        value: {
            firstName: response.firstName,
            surname: response.lastName,
            phonePrefix: response.phonePrefix,
            phone: response.phoneNumber,
            email: response.email,
            receiveNewsletter: response.recievereceiveNewsletter,
        },
    });
    userAtom.update({
        type: 'UPDATE_FULL_ADDRESS',
        value: {
            address: response.addressLine1,
            zipcode: response.postalCode,
            location: response.city,
            country: getUserCountry(response.country ?? ''),
        },
    });
}

export async function putUser(
    consumer: Omit<MembershipUser, 'receiveNewsletter'>,
): Promise<MembershipUser> {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me`, locale, {});
    return put(url, consumer);
}

export async function getCompanyConfig(key: string, siteKey: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Config/company/${key}/site/${siteKey}`, locale, {});
    try {
        const response = await get<Company>(
            url,
            localeAtom.subject.value.t.couldntGetCompanyInformation,
        );
        companyAtom.update({ type: 'UPDATE_COMPANY', value: response });
    } catch (e: any) {
        showError(e.toString());
    }
}

export async function getValueCards(companyKey: string) {
    const queryParams = { companyKey: companyKey };
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Users/me/valueCards`, locale, queryParams);
    try {
        const response = await get<MembershipUserValueCard[]>(
            url,
            localeAtom.subject.value.t.couldntGetValueCards,
        );

        const withCancellations = response.map((valueCard) => ({
            ...valueCard,
            ...(valueCard.subscription
                ? {
                      cancellation: {
                          fromDate:
                              dayjs(valueCard.subscription.cancelSubscriptionAllowedFrom) ?? null,
                          nextChargeAt:
                              dayjs(valueCard.subscription.nextSubscriptionChargeAt) ?? null,
                          cancelledFrom:
                              dayjs(valueCard.subscription.subscriptionCancelledFrom) ?? null,
                      },
                  }
                : {}),
        }));

        userAtom.update({ type: 'UPDATE_VALUECARDS', value: withCancellations });
        return withCancellations as (MembershipUserValueCard & {
            cancellation?: {
                fromDate: Dayjs | null;
                nextChargeAt: Dayjs | null;
                cancelledFrom: Dayjs | null;
            };
        })[];
    } catch (e: any) {
        showError(e.toString());
        return [];
    }
}

export async function getAvailableValueCardProductsForUser(siteKey: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/availableValueCardProducts/site/${siteKey}`,
        locale,
    );

    try {
        const response = await get<MembershipValueCardProduct[]>(
            url,
            localeAtom.subject.value.t.couldntGetValueCardProducts,
        );

        userAtom.update({ type: 'UPDATE_VALUECARD_PRODUCTS', value: response });
        return response;
    } catch (e: any) {
        showError(e.toString());
        return [];
    }
}

export async function postIntent(
    siteKey: string,
    consumer: any,
    reservation: any,
    checkoutUrl: string,
    errorMessage: string,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/site/${siteKey}/intent`, locale, {});
    try {
        const intent = (await post(
            url,
            {
                consumer,
                reservation,
                checkoutUrl,
            },
            errorMessage,
        )) as MembershipIntentResponse;
        return intent;
    } catch (e: any) {
        showError(e.toString());
    }
}

export async function postReservation(
    siteKey: string,
    consumer: MembershipConsumer,
    reservation: {
        productId: number;
        timeslotIds: number[];
        tickets: MembershipTicket[];
    },
    checkoutUrl: string,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/site/${siteKey}/reserve`, locale, {});
    try {
        const reserve = await post(url, {
            consumer,
            reservation,
            checkoutUrl,
        });
        return reserve as MembershipReserveResponse;
    } catch (e: any) {
        showError(e.toString());
    }
}

export async function terminateReservation(orderReference: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Bookings/order/${orderReference}/terminate`,
        locale,
        {},
    );
    const termination = await post(url);
    return termination as MembershipReserveResponse;
}

export async function cancelReservation(orderReference: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Bookings/order/${orderReference}/cancel`, locale, {});
    const cancellation = await post(url);
    return cancellation as MembershipReserveResponse;
}

export async function cancelMembership(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/cancel`,
        locale,
        {},
    );
    const cancellation = await post(url);
    return cancellation as MembershipReserveResponse;
}

export function useValueCards(companyKey: string | null) {
    const { locale } = localeAtom.subject.value;
    const queryParams = companyKey ? { companyKey } : {};

    const url = companyKey
        ? getUrlWithParams(apiUrl() + '/Users/me/valueCards', locale, queryParams)
        : null;
    const { data, error, isLoading } = useSWR<MembershipUserValueCard[]>(url, get);

    return {
        valueCards: data ?? [],
        valueCardsError: error,
        valueCardsLoading: isLoading,
    };
}

export function useValueCardUsage(valueCardId: number) {
    const { locale } = localeAtom.subject.value;
    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/usage`,
        locale,
        {},
    );
    return useSWR<TimeslotsValueCardUsage>(url, get);
}

export function useBookings() {
    const { locale } = localeAtom.subject.value;
    const url = getUrlWithParams(apiUrl() + `/Users/me/bookings`, locale, {});
    return useSWR<MembershipBooking[]>(url, get);
}

export async function getBookings(companyKey: string) {
    const { locale } = localeAtom.subject.value;
    const queryParams = { companyKey: companyKey };
    const url = getUrlWithParams(apiUrl() + `/Users/me/bookings`, locale, queryParams);
    const response = await get(url);
    userAtom.update({ type: 'UPDATE_BOOKINGS', value: response });
}

export async function getOrder(orderId: number) {
    const { locale } = localeAtom.subject.value;
    const url = getUrlWithParams(apiUrl() + `/Orders/${orderId}`, locale, {});
    const response = await get(url);
    return response;
}

export async function getCreditsLeft(valueCardIds: number[]) {
    const { locale } = localeAtom.subject.value;
    const queryParams = { valueCardIds: valueCardIds.join(',') };
    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/credit-balance`,
        locale,
        queryParams,
    );
    const response = await get(url);
    userAtom.update({ type: 'UPDATE_CREDITS', value: response });
    return response;
}

export async function postValueCardReservation(
    consumer: MembershipUser,
    valueCardProductId: number,
    siteKey: string,
    checkoutUrl: string,
    validFrom?: string,
    campaignId?: number,
    giftcardReference?: string,
    promoCodeReference?: string,
) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(apiUrl() + `/Purchase/valueCard`, locale, {});
    const response = await post(url, {
        valueCardProductId,
        validFrom: validFrom === '' ? new Date().toISOString() : validFrom,
        siteKey,
        checkoutUrl,
        consumer,
        campaignId,
        giftcardReference,
        promoCodeReference,
    });
    return response as MembershipReserveResponse;
}

export async function updateValueCardSubscription(valueCardId: number, checkoutUrl: string) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/update`,
        locale,
        {},
    );
    const response = await post(url, {
        checkoutUrl,
    });
    return response as UpdateValueCardSubscriptionResponse;
}

export function useLatestValueCardSubscriptionOrder(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/latest-order`,
        locale,
        {},
    );
    const { data, error, isLoading, mutate } = useSWR<MembershipOrder>(url, get);

    return {
        order: data ?? undefined,
        error: error,
        isLoading: isLoading,
        mutate,
    };
}

export async function retrySubscriptionPayment(valueCardId: number) {
    const { locale } = localeAtom.subject.value;

    const url = getUrlWithParams(
        apiUrl() + `/Users/me/valueCards/${valueCardId}/subscription/retry-payment`,
        locale,
        {},
    );
    const response = await post(url);
    return response as MembershipOrder;
}
