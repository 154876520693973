import { Paper, Typography, useTheme } from '@mui/material';
import { useLocale } from 'src/i18n/locale';
import {
    ActivityCheckoutInfo,
    MultiDayCheckoutInfo,
    PackageCheckoutInfo,
    ProductQuestion,
} from 'src/types/checkout-info';
import { capitalize } from 'src/utils/common/TextUtils';
import BilberryQuestionInput from './BilberryQuestionInput';

type Props = {
    onChange(
        activityCheckoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo,
    ): void;
    showErrors: boolean;
    checkoutInfo: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo;
};

export default function ActivityQuestionnaire(props: Props): JSX.Element {
    const { onChange, checkoutInfo } = props;
    const { t } = useLocale();
    const theme = useTheme();

    function activityQuestionOnChange(question: ProductQuestion) {
        const updated: ActivityCheckoutInfo | MultiDayCheckoutInfo | PackageCheckoutInfo = {
            ...checkoutInfo,
            activityQuestions: {
                ...checkoutInfo.activityQuestions,
                [question.id]: question,
            },
        };

        onChange(updated);
    }

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginTop: theme.spacing(1),
                },
                backgroundColor: 'white',
                width: '100%',
            }}
            variant="outlined"
        >
            <Typography color="textSecondary" variant="h6" mt={0}>
                {capitalize(t.additional_information)}
            </Typography>

            {Object.values(checkoutInfo.activityQuestions).map((question) => (
                <BilberryQuestionInput
                    key={question.key}
                    onChange={activityQuestionOnChange}
                    showErrors={props.showErrors}
                    question={question}
                />
            ))}
        </Paper>
    );
}
