import dayjs from 'dayjs';
import { findUrlQueryParam } from 'src/utils/common/query-params-helper';
import { VERSION } from 'src/__autogen/env';
import { BilberryBrefData } from './bref-data-types';
import { BilberryWidgetsMeta } from './widgets-meta-types';

export enum LocalStorageKey {
    BILBERRY_CART = 'no.bilberry.widgets-cart',
    BILBERRY_WIDGETS_META = 'no.bilberry.widgets-meta',
    BILBERRY_CHECKOUT_INFO = 'no.bilberry.widgets-checkout-info',
    BILBERRY_SEARCH_FORM_DATA = 'no.bilberry.search-form-data',
    BILBERRY_PURCHASE = 'no.bilberry.purchase',
    BILBERRY_BREF = 'no.bilberry.bref',
}

const TTL = 24; // in hours

declare const window: any;

export function assertVersionAndTTLValid(): void {
    const stored = loadWidgetsMeta();

    if (!stored) return;

    const isVersionChanged = VERSION !== stored.version;
    const isTTLExpired = dayjs(stored.lastUpdated) < dayjs().subtract(TTL, 'hours');

    if (isVersionChanged || isTTLExpired) {
        localStorage.removeItem(LocalStorageKey.BILBERRY_CART);
        localStorage.removeItem(LocalStorageKey.BILBERRY_CHECKOUT_INFO);
        localStorage.removeItem(LocalStorageKey.BILBERRY_WIDGETS_META);
        localStorage.removeItem(LocalStorageKey.BILBERRY_SEARCH_FORM_DATA);
    }
}

export function loadWidgetsMeta() {
    return loadDataFromLocalStorage(
        LocalStorageKey.BILBERRY_WIDGETS_META,
    ) as BilberryWidgetsMeta | null;
}

export function loadDataFromLocalStorage(storageKey: string) {
    const serialized = localStorage.getItem(storageKey);
    if (serialized) {
        return JSON.parse(serialized) as any;
    } else {
        return null;
    }
}

export function updateWidgetsMeta() {
    const newWidgetsMeta: BilberryWidgetsMeta = {
        version: VERSION,
        lastUpdated: new Date(),
    };
    localStorage.setItem(LocalStorageKey.BILBERRY_WIDGETS_META, JSON.stringify(newWidgetsMeta));
}

function getBrefValueFromLocalStorage(): string {
    var brefData = loadDataFromLocalStorage(
        LocalStorageKey.BILBERRY_BREF,
    ) as BilberryBrefData | null;

    if (!brefData) return '';

    var now = new Date();

    if (now > brefData.expireAt) return '';

    return brefData.value;
}

function updateBrefData(newBrefData: BilberryBrefData) {
    localStorage.setItem(LocalStorageKey.BILBERRY_BREF, JSON.stringify(newBrefData));
}

export function findBrefValue(): string {
    var brefFromQueryParam = findUrlQueryParam('bref') ?? '';
    if (brefFromQueryParam) {
        const brefTTL = window.BilberryWidgetsGlobal?.brefTTL
            ? parseInt(window.BilberryWidgetsGlobal.brefTTL)
            : 14;

        updateBrefData({
            value: brefFromQueryParam,
            expireAt: dayjs().add(brefTTL, 'day').toDate(),
        });
    }

    return getBrefValueFromLocalStorage();
}
