import { Translations } from 'src/i18n/translations/types';
import {
    AccommodationPrice,
    BilberryAccommodation,
    FacilitiesAttributes,
    PricedAccommodation,
} from 'src/types/bilberry-hotels-api-types';

export function findImageAttribute(accommodation?: BilberryAccommodation) {
    return (
        accommodation?.attributes?.images?.largeImage ??
        accommodation?.attributes?.images?.roomImage ??
        accommodation?.attributes?.images?.smallImage
    );
}

export function getLowestAccommodationPriceValue(prices: AccommodationPrice[]) {
    return Math.min(...prices.map((price) => price.value));
}

export function getLowestAccommodationPrice(prices: AccommodationPrice[]): AccommodationPrice {
    const lowestValue = Math.min(...prices.map((price) => price.value));
    return prices.find((price) => price.value === lowestValue) ?? prices[0];
}

export function getAccommodationFullDescription(accommodation: BilberryAccommodation | null) {
    return accommodation?.attributes?.descriptions.longDescription;
}
export function findAccommodationFacilities(accommodation: BilberryAccommodation | null) {
    if (!accommodation || !accommodation?.attributes || !accommodation?.attributes?.facilities)
        return [];

    return Object.entries(accommodation?.attributes.facilities).reduce((acc, [key, value]) => {
        if (value === true) acc.push(key as keyof FacilitiesAttributes);
        return acc;
    }, [] as (keyof FacilitiesAttributes)[]);
}

export function facilityAsReadableText(facility: keyof FacilitiesAttributes, t: Translations) {
    switch (facility) {
        case 'hasAirconditioning':
            return t.has_airconditioning;
        case 'hasBalcony':
            return t.has_balcony;
        case 'hasBicycles':
            return t.has_bicycles;
        case 'hasBoat':
            return t.has_boat;
        case 'hasCoffeeMaker':
            return t.has_coffee_maker;
        case 'hasDishwasher':
            return t.has_dishwasher;
        case 'hasDryer':
            return t.has_dryer;
        case 'hasFireplace':
            return t.has_fireplace;
        case 'hasGarage':
            return t.has_garage;
        case 'hasGardenFurniture':
            return t.has_garden_furniture;
        case 'hasGrill':
            return t.has_grill;
        case 'hasKitchen':
            return t.has_kitchen;
        case 'hasMinibar':
            return t.has_minibar;
        case 'hasMooringSpace':
            return t.has_mooring_space;
        case 'hasPool':
            return t.has_pool;
        case 'hasRoomService':
            return t.has_roomservice;
        case 'hasSauna':
            return t.has_sauna;
        case 'hasTv':
            return t.has_tv;
        case 'hasWashingMachine':
            return t.has_washing_machine;
        case 'hasWifi':
            return t.has_wifi;
        case 'isBreakfastIncluded':
            return t.is_breakfast_included;
        case 'isCancellationIncluded':
            return t.is_cancellation_included;
        case 'isPetsAllowed':
            return t.is_pets_allowed;
        case 'isSmokingAllowed':
            return t.is_smoking_allowed;
        case 'isTowelsAndBedlinesIncluded':
            return t.is_towels_and_bedlines_included;
        case 'isWheelchairAccessible':
            return t.is_wheelchair_accessible;
    }
}

export function getAvailabilityMap(
    accommodations: BilberryAccommodation[],
    selectedAccommodations: PricedAccommodation[],
) {
    const availableUnits: [number, number][] =
        accommodations.map((accommodation) => [accommodation.id, accommodation.availableUnits]) ??
        [];

    return selectedAccommodations
        .map((sAcc) => sAcc.accommodation)
        .reduce((acc: Map<number, number>, accommodation: BilberryAccommodation) => {
            const id = accommodation.id;
            const currentCount = acc.get(id);
            currentCount && acc.set(id, currentCount - 1);
            return acc;
        }, new Map<number, number>(availableUnits));
}
