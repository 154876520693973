import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { useAvailableProducts } from 'src/utils/domain/api/bilberry-api-client';
import { AvailabilityProductType } from '../TimeSlotType';
import { useConfigurations } from '../widgetsConfiguration';
import { useAccommodationAvailabilities } from './bilberry-hotels-api-client';
import { getNumberOfTravelers } from 'src/utils/domain/booking/bookingHelpers';

export function useAvailabilityData(productCatalogId: number, quantities: BookingPriceAndQuantity[]) {
    const { locale } = useLocale();
    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: Dayjs | null;
        endDay: Dayjs | null;
    }>({
        startDay: null,
        endDay: null,
    });
    const config = useConfigurations();

    const [availabilityData, setAvailabilityData] = useState<{
        [id: string]: BilberryProduct[];
    }>({});

    const { availableProducts } = useAvailableProducts(
        productCatalogId,
        availabilitySearchPeriod.startDay,
        availabilitySearchPeriod.endDay,
        locale,
        config,
    );

    useEffect(() => {
        if (!availableProducts) return;

        const numberOfTravelers = getNumberOfTravelers(quantities);

        const priceTypes = quantities.reduce(
            (acc, cur) => [...acc, ...(cur.quantity > 0 ? [cur.price_category_id] : [])],
            [] as number[],
        );

        setAvailabilityData((prevAvailabilityData) => {
            const newAvailabilityData: { [id: string]: BilberryProduct[] } = {};
            Object.assign(newAvailabilityData, prevAvailabilityData, availableProducts);
            for (const key in newAvailabilityData) {
                newAvailabilityData[key] = newAvailabilityData[key].filter(
                    (x) => x.capacity >= numberOfTravelers,
                );
                if (
                    newAvailabilityData[key].length === 0 ||
                    !newAvailabilityData[key].some((x) =>
                        priceTypes.every((type) =>
                            x.prices.map((price) => price.price_category_id).includes(type),
                        ),
                    )
                ) {
                    delete newAvailabilityData[key];
                }
            }
            return newAvailabilityData;
        });
    }, [availableProducts, quantities, setAvailabilityData]);

    return {
        availabilityData,
        availabilitySearchPeriod,
        setAvailabilitySearchPeriod,
    };
}

export function useAccommodationsAvailabilityData(
    accommodationId: string,
    numberOfTravelers: number,
) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: Dayjs | null;
        endDay: Dayjs | null;
    }>(() => {
        const startDay = config.openingDate ? dayjs(config.openingDate) : dayjs();
        return {
            startDay,
            endDay: dayjs(startDay).add(60, 'days'),
        };
    });

    const [availabilityData, setAvailabilityData] = useState<
        Record<string, AvailabilityProductType<BilberryAccommodation>>
    >({});

    const { availabilities } = useAccommodationAvailabilities(
        accommodationId,
        [availabilitySearchPeriod.startDay, availabilitySearchPeriod.endDay],
        numberOfTravelers,
        locale,
        config,
    );

    useEffect(() => {
        if (availabilities) {
            const newAvailabilityData: {
                [id: string]: AvailabilityProductType<BilberryAccommodation>;
            } = {};
            Object.assign(newAvailabilityData, availabilityData, availabilities);
            for (const key in newAvailabilityData) {
                newAvailabilityData[key].start = key;
                if (
                    newAvailabilityData[key].capacity !== null &&
                    newAvailabilityData[key].capacity < numberOfTravelers
                ) {
                    delete newAvailabilityData[key];
                }
            }
            setAvailabilityData(newAvailabilityData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilities, numberOfTravelers]);

    const onChangeAvailabilitySearchPeriod = useCallback(
        (dates: { startDay: Dayjs; endDay: Dayjs }) => {
            setAvailabilitySearchPeriod({
                startDay: dates.startDay.isAfter(dayjs()) ? dates.startDay : dayjs(),
                endDay: dates.endDay.isAfter(dates.startDay)
                    ? dates.endDay
                    : dayjs().add(1, 'days'),
            });
        },
        [],
    );

    return {
        availabilityData,
        availabilitySearchPeriod,
        setAvailabilitySearchPeriod: onChangeAvailabilitySearchPeriod,
    };
}
