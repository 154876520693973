import { Box, BoxProps, Fade, Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Fragment } from 'preact';
import { useMemo, useRef, useState } from 'preact/hooks';
import { ArrowButton } from 'src/components/common/buttons/arrow/ArrowButton';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { BilberryGalleryImage } from 'src/types/bilberry-hotels-api-types';
import { MediaQueryAttributeInput } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import ProductImage from 'src/widgets/activities/product/product-image/ProductImage';
import { ProductGalleryOverlay } from './ProductGalleryOverlay';

export enum ProductGalleryAlign {
    SCALE,
    LEFT,
    CENTER,
    RIGHT,
}

function useNumImages(props: {
    numVisibleImages: MediaQueryAttributeInput;
    align: ProductGalleryAlign;
    numImages: number;
}) {
    const xs: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const sm: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const md: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const numImagesForBreakpoint = xs
        ? props.numVisibleImages.numXs
        : sm
        ? props.numVisibleImages.numSm
        : md
        ? props.numVisibleImages.numMd
        : props.numVisibleImages.numLg;

    return props.align === ProductGalleryAlign.SCALE
        ? Math.min(numImagesForBreakpoint, props.numImages)
        : numImagesForBreakpoint;
}

export default function ProductGallery2(props: {
    imageList: BilberryGalleryImage[] | null;
    numVisibleImages: MediaQueryAttributeInput;
    align: ProductGalleryAlign;
}): JSX.Element {
    const customizations = useCustomizations();
    const [firstVisibleImage, setFirstVisibleImage] = useState(0);
    const [touchStart, setTouchStart] = useState<TouchEvent | null>(null);
    const numImages = props.imageList ? props.imageList.length : 0;
    const [overlayOpen, setOverlayOpen] = useState<boolean>(false);
    const [clickedImageIndex, setClickedImageIndex] = useState(0);
    const popoverRef = useRef<HTMLDivElement>(null);
    const refocusElement = useRef<HTMLInputElement>(null);
    const trap = useFocusTrap(popoverRef, overlayOpen, refocusElement);
    const theme = useTheme();

    function change() {
        if (trap) trap.deactivate();
        setOverlayOpen(!overlayOpen);
    }

    const adjustedNumImages = useNumImages({
        numVisibleImages: props.numVisibleImages,
        align: props.align,
        numImages,
    });

    const align = adjustedNumImages < numImages ? ProductGalleryAlign.LEFT : props.align;

    const onMoveRight = () => {
        if (firstVisibleImage + 1 + adjustedNumImages <= numImages) {
            setFirstVisibleImage(firstVisibleImage + 1);
        }
    };

    const onMoveLeft = () => {
        if (firstVisibleImage - 1 >= 0) {
            setFirstVisibleImage(firstVisibleImage - 1);
        }
    };

    const leftButtonVisible = firstVisibleImage > 0;
    const rightButtonVisible = firstVisibleImage + adjustedNumImages < numImages;

    const transformContainer = useMemo(() => {
        const imageWidth = refocusElement.current?.getBoundingClientRect().width;
        return `translateX(calc(${-(
            imageWidth ?? 0
        )}px * ${firstVisibleImage} - ${firstVisibleImage} * ${theme.spacing(1)}))`;
    }, [firstVisibleImage, theme, refocusElement]);

    return (
        <Box
            position="relative"
            overflow="hidden"
            onTouchStart={setTouchStart}
            onTouchEnd={(end: TouchEvent) => {
                handleTouch(end, touchStart, onMoveLeft, onMoveRight);
            }}
        >
            <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="flex-start"
                gap={1}
                sx={{
                    transition: 'all 0.5s ease-in-out',
                    transform: transformContainer,
                }}
            >
                {(props.imageList ?? []).map((image, i) => (
                    <ProductImageBox
                        numVisibleImages={props.numVisibleImages}
                        adjustedNumImages={adjustedNumImages}
                        align={align}
                        key={image.url + i}
                        innerRef={refocusElement}
                        onClick={() => {
                            setOverlayOpen(!overlayOpen);
                            setClickedImageIndex(i);
                            change();
                        }}
                        onKeyDown={(e: KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                setOverlayOpen(!overlayOpen);
                                setClickedImageIndex(i);
                                change();
                            }
                        }}
                        tabIndex={0}
                    >
                        <ProductImage
                            url={image.url ?? null}
                            size="s"
                            disableShadows={true}
                            format="16_9"
                            stretchToFit
                        />
                        <Box
                            sx={(theme) => ({
                                boxShadow: `inset 0 0 5px 5px ${customizations.linkColor}`,
                                visibility: 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: `calc(100% - ${theme.spacing(1)})`,
                                height: '100%',
                            })}
                        />
                    </ProductImageBox>
                ))}
            </Grid>
            <Fade in={leftButtonVisible}>
                <ArrowButton direction="left" onClick={onMoveLeft} />
            </Fade>
            <Fade in={rightButtonVisible}>
                <ArrowButton direction="right" onClick={onMoveRight} />
            </Fade>
            {overlayOpen ? (
                <ProductGalleryOverlay
                    imageList={props.imageList || []}
                    clickedImageIndex={clickedImageIndex}
                    setOpen={setOverlayOpen}
                    open={overlayOpen}
                    popoverRef={popoverRef}
                ></ProductGalleryOverlay>
            ) : (
                <Fragment></Fragment>
            )}
        </Box>
    );
}

function handleTouch(
    end: TouchEvent,
    touchStart: TouchEvent | null,
    onMoveLeft: () => void,
    onMoveRight: () => void,
) {
    if (!touchStart || end.changedTouches.length === 0) return;

    const diffX =
        end.changedTouches.item(0)?.screenX ?? 0 - (touchStart.touches.item(0)?.screenX ?? 0);
    const diffY =
        end.changedTouches.item(0)?.screenY ?? 0 - (touchStart.touches.item(0)?.screenY ?? 0);

    if (Math.abs(diffX) < 25 || diffY > window.innerWidth / 2) return;

    if (diffX >= 0) onMoveLeft();
    else onMoveRight();
}

function ProductImageBox(
    props: BoxProps & {
        adjustedNumImages: number;
        numVisibleImages: MediaQueryAttributeInput;
        align: ProductGalleryAlign;
        innerRef: React.RefObject<HTMLDivElement>;
    },
) {
    const { innerRef, align, adjustedNumImages, numVisibleImages, ...boxProps } = props;
    const theme = useTheme();
    return (
        <Box
            {...boxProps}
            ref={innerRef}
            sx={[
                {
                    position: 'relative',
                    '&:focus > *:last-child': {
                        visibility: 'visible',
                    },
                },
                align === ProductGalleryAlign.SCALE
                    ? {
                          height: 'auto',
                          flexGrow: 0,
                          flexShrink: 0,
                          flexBasis: `calc(${100 / adjustedNumImages}%)`,
                      }
                    : {
                          height: 'auto',
                          flexGrow: 0,
                          flexShrink: 0,
                          '&:hover': {
                              cursor: 'pointer',
                          },
                          flexBasis: `${100 / numVisibleImages.numXs}%`,
                          [theme.breakpoints.up('sm')]: {
                              flexBasis: `calc(${100 / numVisibleImages.numSm}%)`,
                          },
                          [theme.breakpoints.up('md')]: {
                              flexBasis: `calc(${100 / numVisibleImages.numMd}%)`,
                          },
                          [theme.breakpoints.up('lg')]: {
                              flexBasis: `calc(${100 / numVisibleImages.numLg}%)`,
                          },
                      },
            ]}
        >
            {props.children}
        </Box>
    );
}
