import { cloneElement, Fragment, h, VNode } from 'preact';
import { useState } from 'preact/hooks';
import { VisibilityControllable } from 'src/hooks/common/ui/visibility-control';

type ControlledInputSectionProps = {
    children: (VNode<Partial<VisibilityControllable>> | undefined)[];
};

/**
 * Container component used to control visibility of a series of input components that
 * implement the `VisibilityControllable` type. The container makes sure that only a single
 * child input is in a `visible` state at a time.
 *
 * The use case for this component is in locations where multiple input components such as dropdowns
 * can be opened simultaneously and potentially overlap, and where the parent component can't control
 * visibility directly itself, such as for the Booking base component used in the booking and
 * accommodation booking widgets.
 *
 * @param children The components which to control. Only children that implement the VisibilityControllable
 * type are controlled.
 */
export default function ControlledInputVisibilitySection({
    children,
}: ControlledInputSectionProps) {
    const [currentIndex, setCurrentIndex] = useState(-1);

    return (
        <Fragment>
            {children.map((child, i) => {
                const visible = currentIndex === i;

                const setVisible = (isVisible: boolean) => setCurrentIndex(isVisible ? i : -1);

                if (child) {
                    return cloneElement(child, { key: i, visible, setVisible });
                }
                return <Fragment>{child}</Fragment>;
            })}
        </Fragment>
    );
}
