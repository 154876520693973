import { h, render } from 'preact';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BasketWidget from 'src/widgets/activities/basket/BasketWidget';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryBasket extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <BasketWidget />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-basket', BilberryBasket);
}
