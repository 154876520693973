import { Grid, useTheme } from '@mui/material';
import { h } from 'preact';
import ProductDetailsWidgetRoot from 'src/widgets/activities/product/product-details/ProductDetailsWidgetRoot';
import ProductGalleryWidgetRoot from 'src/widgets/activities/product/product-gallery/ProductGalleryWidgetRoot';
import ProductImageWidgetRoot from 'src/widgets/activities/product/product-image/ProductImageWidgetRoot';
import ProductOverviewWidgetRoot from 'src/widgets/activities/product/product-overview/ProductOverviewWidgetRoot';

export function ProductPage(props: { productCatalogId: number }) {
    const theme = useTheme();
    const rightPadding = {
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
        },
    };
    return (
        <Grid container>
            <Grid
                item
                sm={12}
                direction={'row-reverse'}
                display="flex"
                pb={theme.spacing(4)}
                sx={{
                    '& > *:first-child': {
                        [theme.breakpoints.down('md')]: {
                            paddingBottom: theme.spacing(4),
                        },
                    },
                    [theme.breakpoints.down('md')]: {
                        display: 'block',
                    },
                }}
            >
                <Grid item sm={12} md={6}>
                    <ProductImageWidgetRoot productCatalogId={props.productCatalogId} />
                </Grid>
                <Grid item sm={12} md={6} sx={rightPadding}>
                    <ProductOverviewWidgetRoot productCatalogId={props.productCatalogId} />
                </Grid>
            </Grid>
            <Grid
                item
                sm={12}
                display="flex"
                sx={{
                    [theme.breakpoints.down('md')]: {
                        display: 'block',
                    },
                }}
            >
                <Grid item sm={12} md={6} sx={rightPadding}>
                    <ProductDetailsWidgetRoot productCatalogId={props.productCatalogId} />
                </Grid>
                <Grid item sm={12} md={6}>
                    <ProductGalleryWidgetRoot productCatalogId={props.productCatalogId} />
                </Grid>
            </Grid>
        </Grid>
    );
}
