export type MediaQueryAttributeInput = {
    numXs: number;
    numSm: number;
    numMd: number;
    numLg: number;
};

export function getMediaQuerySizesFromAttributes(
    element: Element,
    defaultXs = 1,
    defaultSm = 2,
    defaultMd = 3,
    defaultLg = 4,
): MediaQueryAttributeInput {
    const numXs = element.hasAttribute('num-xs')
        ? parseInt(element.getAttribute('num-xs')!)
        : undefined;
    const numSm = element.hasAttribute('num-sm')
        ? parseInt(element.getAttribute('num-sm')!)
        : undefined;
    const numMd = element.hasAttribute('num-md')
        ? parseInt(element.getAttribute('num-md')!)
        : undefined;
    const numLg = element.hasAttribute('num-lg')
        ? parseInt(element.getAttribute('num-lg')!)
        : undefined;

    const numXsOrDefault = !numXs || numXs <= 0 ? defaultXs : numXs;
    const numSmOrDefault = !numSm || numSm <= 0 ? defaultSm : numSm;
    const numMdOrDefault = !numMd || numMd <= 0 ? defaultMd : numMd;
    const numLgOrDefault = !numLg || numLg <= 0 ? defaultLg : numLg;

    return {
        numXs: numXsOrDefault,
        numSm: numSmOrDefault,
        numMd: numMdOrDefault,
        numLg: numLgOrDefault,
    };
}
