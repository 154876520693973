import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import DateRangePickerInput from './subcomponents/DateRangePickerInput';
import DateRangePickerPopover from './subcomponents/DateRangePickerPopover';

export const initialDateRangeState: DateRange<Dayjs> = [null, null];

type IProps = {
    onSelectDateRange(dateRange: DateRange<Dayjs>): void;
    dateRange: DateRange<Dayjs>;
    label: string;
    showError?: boolean;
    required?: boolean;
    labelColor?: string;
    minDate?: Dayjs;
    variant?: 'filled' | 'outlined';
    dateRangeVariant?: 'days' | 'nights' | undefined;
};

export function DateRangePicker(props: IProps) {
    const {
        label,
        showError = false,
        required = false,
        labelColor,
        onSelectDateRange,
        dateRange,
        minDate,
        variant = 'filled',
        dateRangeVariant,
    } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    return (
        <Fragment>
            <DateRangePickerInput
                labelColor={labelColor}
                label={label}
                showError={showError}
                required={required}
                setAnchorEl={setAnchorEl}
                dateRange={dateRange}
                variant={variant}
            ></DateRangePickerInput>
            <DateRangePickerPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                dateRange={dateRange}
                minDate={minDate}
                onSelectDateRange={onSelectDateRange}
                dateRangeVariant={dateRangeVariant}
            ></DateRangePickerPopover>
        </Fragment>
    );
}
