import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import {
    ProductCardButton,
    ProductCardInfoContainer,
    ProductCardInfoContent,
} from 'src/components/domain/product-card-2/ProductCardInfo';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { useLocale } from 'src/i18n/locale';
import { formatCurrencyNoDecimalsIfPossible } from 'src/i18n/Localization';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { BilberryProduct, BilberryProductCatalog } from 'src/types/bilberry-api-types';
import { isBilberryProduct } from 'src/types/guards/bilberry-api-type-guards';
import { formatMinutesToDaysHoursMinutes } from 'src/utils/common/DateHelpers';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import { calculateProductCatalogMinValues } from 'src/utils/domain/activities/calculateProductCatalogValues';
import {
    getProductCatalogDisplayTitle,
    getProductDisplayTitle,
} from 'src/utils/domain/display-helper';
import { getDifficultyText } from 'src/utils/domain/mapToTranslations';
import { useAtom } from 'ximple/atoms';
import { ProductCard2, ProductCard2Props } from './ProductCard2';
import ProductCardIcon from './ProductCardIcon';
import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import ChildCareRounded from '@mui/icons-material/ChildCareRounded';
import LocalOfferRounded from '@mui/icons-material/LocalOfferRounded';
import TrendingUpRounded from '@mui/icons-material/TrendingUpRounded';

type ActivityProductCardProps = ProductCard2Props & {
    productCatalog?: BilberryProductCatalog;
};

export default function ActivityProductCard(props: ActivityProductCardProps): JSX.Element {
    return (
        <ProductCard2
            {...props}
            category={props.productCatalog?.web_category}
            image={{ url: props.productCatalog?.media.image.url, alt: '' }}
            body={
                <ActivityProductCardInfo
                    product={props.productCatalog}
                    url={props.url}
                    buttonCustomizationKey={props.buttonCustomizationKey}
                />
            }
        />
    );
}

type ActivityProductCardInfoContentProps = {
    product?: BilberryProductCatalog | BilberryProduct;
    url: string;
    buttonCustomizationKey?: keyof UserTextCustomizations;
    customPriceLabel?: string | JSX.Element;
    hidePriceIcon?: boolean;
    readMoreProductId?: number;
    readMoreLinkVariant?: 'href' | 'popup' | 'none';
};

export function ActivityProductCardInfoContent(props: ActivityProductCardInfoContentProps) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const product = getCommonAttributes(props.product);

    const { minAge } = calculateProductCatalogMinValues(product);

    const iconStyles = {
        '& > .MuiSvgIcon-root': {
            color: customizations.productCardIconColor,
        },
        '& > .MuiTypography-root': {
            padding: theme.spacing(0, 0.5),
            color: customizations.productCardTextColor,
        },
    };

    const icons = (
        <Fragment>
            <ProductCardIcon
                label={formatMinutesToDaysHoursMinutes(t, product?.duration ?? 0)}
                Icon={AccessAlarmsRoundedIcon}
                sx={iconStyles}
            />
            <ProductCardIcon
                label={getDifficultyText(t, product)}
                Icon={TrendingUpRounded}
                sx={iconStyles}
            />
            <ProductCardIcon
                label={minAge < Number.MAX_VALUE ? `${minAge}+` : '-'}
                Icon={ChildCareRounded}
                sx={iconStyles}
            />
            {!props.hidePriceIcon && (
                <ProductCardIcon
                    label={`${
                        product?.from_price
                            ? formatCurrencyNoDecimalsIfPossible(
                                  locale,
                                  currency,
                                  product.from_price,
                              )
                            : '-'
                    }`}
                    Icon={LocalOfferRounded}
                    sx={iconStyles}
                />
            )}
        </Fragment>
    );
    return (
        <ProductCardInfoContent
            {...props}
            title={product?.title}
            fromPrice={product?.from_price}
            description={product?.description}
            icons={icons}
        />
    );
}

export function ActivityProductCardInfo(props: ActivityProductCardInfoContentProps): JSX.Element {
    const { product, url } = props;

    return (
        <ProductCardInfoContainer>
            <ActivityProductCardInfoContent {...props} />
            <ProductCardButton
                onClick={() =>
                    product &&
                    dispatchWidgetEvent({
                        eventType: 'viewItem',
                        productType: 'Activity',
                        product: product,
                    })
                }
                url={url}
                buttonCustomizationKey={props.buttonCustomizationKey}
            />
        </ProductCardInfoContainer>
    );
}

function getCommonAttributes(product?: BilberryProductCatalog | BilberryProduct) {
    if (!product) return;
    else if (isBilberryProduct(product)) {
        return {
            title: getProductDisplayTitle(product),
            default_prices: product.prices,
            from_price: product.from_price,
            from_age: product.from_age,
            description: product.web_short_description ?? product.description,
            difficulty: product.difficulty,
            duration: dayjs(product.end).diff(dayjs(product.start), 'minutes'),
        };
    } else {
        return {
            title: getProductCatalogDisplayTitle(product),
            default_prices: product.default_prices,
            from_price: product.from_price,
            from_age: product.from_age,
            description: product.web_short_description,
            difficulty: product.difficulty,
            duration: product.duration,
        };
    }
}
