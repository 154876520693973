import { h } from 'preact';

const RadioUnchecked = (props: { color: string; hover: boolean }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            width="24px"
            viewBox="0 0 24 24"
            fill={props.color}
        >
            {props.hover ? (
                <circle cx="12" cy="12" r="8" stroke="white" strokeWidth="2" fill={props.color} />
            ) : (
                <circle cx="12" cy="12" r="8" stroke={props.color} strokeWidth="1" fill="white" />
            )}
        </svg>
    );
};

export default RadioUnchecked;
