import { Grid, Typography } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { BilberryAccommodation } from 'src/types/bilberry-hotels-api-types';
import { useCustomizations } from 'src/utils/common/theme/customizations';
import {
    facilityAsReadableText,
    findAccommodationFacilities,
} from 'src/utils/domain/accommodations/accommodationUtils';

type AccommodationOverviewFacilitiesListProps = {
    accommodation: BilberryAccommodation;
};

export default function AccommodationOverviewFacilitiesList({
    accommodation,
}: AccommodationOverviewFacilitiesListProps) {
    const { t } = useLocale();
    const customizations = useCustomizations();

    const accommodationFacilites = findAccommodationFacilities(accommodation).map((facility) =>
        facilityAsReadableText(facility, t),
    );

    return (
        <Grid container mx="auto">
            <Grid container direction="column">
                <Typography variant="h2" fontSize={22} fontWeight={400} mb={3}>
                    {t.facilities_included}
                </Typography>
                <Grid
                    container
                    wrap="wrap"
                    gap={2}
                    sx={(theme) => ({
                        border: `2px ${customizations.bookingWidgetColor} solid`,
                        padding: theme.spacing(3),
                    })}
                >
                    {accommodationFacilites.map((facility) => (
                        <Typography flexGrow={1} flexBasis="40%" fontWeight={600}>
                            - {facility}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
