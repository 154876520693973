import { Box, Collapse, Link, Paper, Stack, Typography, useTheme } from '@mui/material';
import { BilberryProduct } from 'src/types/bilberry-api-types';
import { TimeSlotType } from 'src/utils/domain/TimeSlotType';
import TimeSlots from '../../../time-slot/TimeSlots';
import { useState } from 'react';
import { useLocale } from 'src/i18n/locale';
import dayjs from 'dayjs';
import { useCustomizations } from 'src/utils/common/theme/customizations';

type TourSelectionPanelProps = {
    title: string;
    imageSrc: string;
    description: string;
    availabilities: (BilberryProduct & { ticketOptionId: number })[];
    isAccommodation?: boolean;
    value?: BilberryProduct;
    setValue: (val: BilberryProduct & { ticketOptionId: number }) => void;
    id?: string;
};

export default function TourSelectionPanel({
    title,
    imageSrc,
    description,
    isAccommodation,
    availabilities,
    value,
    setValue,
    id,
}: TourSelectionPanelProps) {
    const theme = useTheme();
    const { t } = useLocale();
    const customizations = useCustomizations();

    const [isShowingTimeslots, setIsShowingTimeslots] = useState(false);

    const selectedTimeSlot: TimeSlotType<BilberryProduct> | undefined = value
        ? {
              label: '',
              sublabel: '',
              product: value,
          }
        : undefined;

    return (
        <Paper
            id={id}
            sx={{
                display: 'flex',
                border: `1px solid ${theme.palette.grey[300]}`,
                minHeight: '150px',
                p: 0,
            }}
            elevation={0}
        >
            <Box sx={{ flexBasis: '33%', mr: 2 }}>
                <img
                    src={imageSrc}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    }}
                ></img>
            </Box>
            <Stack justify="space-between">
                <Stack sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
                    <Typography variant="body2">{description}</Typography>
                </Stack>
                <Box sx={{ mb: 1 }}>
                    <Box>
                        <Typography variant="body2">
                            {`${isAccommodation ? t.check_in_at : t.activity_starts_at} `}
                            {availabilities.length > 0
                                ? dayjs(value?.start ?? availabilities[0].start).format('HH:mm')
                                : ''}
                        </Typography>
                        {availabilities.length > 1 && (
                            <Collapse
                                timeout={80}
                                easing="linear(0,1)"
                                in={!isShowingTimeslots}
                                exit={isShowingTimeslots}
                            >
                                <Box>
                                    <Typography variant="body2">
                                        {t.other_times_are_available}.
                                    </Typography>
                                    <Link
                                        component="button"
                                        onClick={() => setIsShowingTimeslots(true)}
                                    >
                                        <Typography
                                            sx={{
                                                color: customizations.linkColor,
                                            }}
                                        >
                                            {t.press_to_select_other_options}
                                        </Typography>
                                    </Link>
                                </Box>
                            </Collapse>
                        )}
                    </Box>
                    <Collapse
                        timeout={80}
                        easing="linear(0,1)"
                        in={isShowingTimeslots}
                        exit={!isShowingTimeslots}
                    >
                        <Typography>{t.select_time}</Typography>
                        <TimeSlots
                            hideHeader
                            fullWidth
                            availabilityData={availabilities}
                            selectedTimeSlot={selectedTimeSlot}
                            isTimeSlotsEqual={(
                                ts1: TimeSlotType<BilberryProduct & { ticketOptionId: number }>,
                                ts2: TimeSlotType<BilberryProduct & { ticketOptionId: number }>,
                            ) =>
                                ts1.product.id === ts2.product.id &&
                                ts1.product.ticketOptionId === ts2.product.ticketOptionId
                            }
                            onSelectTimeSlot={(ts) => {
                                setValue(ts?.product);
                                setIsShowingTimeslots(false);
                            }}
                        />
                    </Collapse>
                </Box>
            </Stack>
        </Paper>
    );
}
