import dayjs from 'dayjs';
import isEmpty from 'lodash-es/isEmpty';
import {
    ICartAccommodationItem,
    ICartItem,
    ICartProductItem,
    ICartUiItem,
    BilberryPriceQuantity,
    PriceUiQuantity,
    ICartMultiDayProductItem,
    ICartPackageItem,
} from 'src/state/cart/ICart';
import { PackagePrice } from 'src/types/bilberry-api-types';
import { AccommodationPrice } from 'src/types/bilberry-hotels-api-types';
import { formatDate } from 'src/utils/common/DateHelpers';
import { getCartItemDisplayStartDate } from '../display-helper';

export function convertCartDataToUiFriendlyCartType(item: ICartItem): ICartUiItem {
    const cartItem = item.item;
    if (isICartProductItemType(cartItem)) {
        return {
            id: getCartProductTypeAndId(cartItem),
            title: cartItem.product.web_title || cartItem.product.title || '',
            start: cartItem.product.start,
            prices: cartItem.quantities.map((quantity) => {
                return {
                    id: quantity.id,
                    name: quantity.name,
                    value: quantity.price,
                    quantity: quantity.quantity,
                };
            }),
        } as ICartUiItem;
    } else if (isICartAccommodationItemType(cartItem)) {
        return {
            id: getCartAccommodationTypeAndId(cartItem),
            title: cartItem.accommodation.name || '',
            start: getCartItemDisplayStartDate(item),
            prices: [
                {
                    id: cartItem.price.id,
                    name: cartItem.price.name,
                    value: cartItem.price.value,
                    quantity: 1,
                } as PriceUiQuantity,
            ],
        } as ICartUiItem;
    } else if (isICartPackageItemType(cartItem)) {
        return {
            id: getCartPackageTypeAndId(cartItem),
            title: cartItem.pkg.title ?? '',
            start: getCartItemDisplayStartDate(item),
            prices: cartItem.pkg.ticket_options.map((to) => ({
                id: to.ticket_option_id,
                name: to.name,
                value: to.price,
                quantity: 1,
            })),
        } as ICartUiItem;
    } else {
        return {
            id: getCartMultiDayProductTypeAndId(cartItem),
            title: cartItem.products[0]?.web_title || cartItem.products[0]?.title || '',
            start: cartItem.products[0]?.start,
            prices: cartItem.quantities.map((quantity) => {
                return {
                    id: quantity.id,
                    name: quantity.name,
                    value: quantity.price,
                    quantity: quantity.quantity,
                };
            }),
        } as ICartUiItem;
    }
}

export function isICartProductItemType(
    cartItem:
        | ICartProductItem
        | ICartAccommodationItem
        | ICartMultiDayProductItem
        | ICartPackageItem,
): cartItem is ICartProductItem {
    return 'product' in cartItem;
}

export function isICartPackageItemType(
    cartItem:
        | ICartProductItem
        | ICartAccommodationItem
        | ICartMultiDayProductItem
        | ICartPackageItem,
): cartItem is ICartPackageItem {
    return 'pkg' in cartItem;
}

export function isICartMultiDayProductItemType(
    cartItem:
        | ICartProductItem
        | ICartAccommodationItem
        | ICartMultiDayProductItem
        | ICartPackageItem,
): cartItem is ICartMultiDayProductItem {
    return 'products' in cartItem;
}

export function isICartAccommodationItemType(
    cartItem:
        | ICartProductItem
        | ICartAccommodationItem
        | ICartMultiDayProductItem
        | ICartPackageItem,
): cartItem is ICartAccommodationItem {
    return 'accommodation' in cartItem;
}

export function isPriceQuantityType(
    price: AccommodationPrice | BilberryPriceQuantity | PackagePrice,
): price is BilberryPriceQuantity {
    return 'quantity' in price;
}

export function isAccommodationPriceType(
    price: AccommodationPrice | BilberryPriceQuantity | PackagePrice,
): price is AccommodationPrice {
    return 'maxDays' in price;
}

export function getCartItemTypeAndId(item: ICartItem) {
    const cartItem = item.item;
    if (isICartProductItemType(cartItem)) {
        return 'product-' + cartItem.product.id;
    } else if (isICartAccommodationItemType(cartItem)) {
        return 'accommodation-' + cartItem.id;
    } else if (isICartPackageItemType(cartItem)) {
        return 'package-' + cartItem.pkg.id;
    } else {
        return getCartMultiDayProductTypeAndId(cartItem);
    }
}

export function getCartProductTypeAndId(item: ICartProductItem) {
    return 'product-' + item.product.id;
}

export function getCartMultiDayProductTypeAndId(item: ICartMultiDayProductItem) {
    return (
        'multi-day-product' +
        item.products.reduce<string>((x, y) => {
            return x + '-' + y.id;
        }, '')
    );
}

export function getCartPackageTypeAndId(item: ICartPackageItem) {
    return 'package-' + item.pkg.id;
}

export function getCartAccommodationTypeAndId(item: ICartAccommodationItem) {
    return 'accommodation-' + item.id;
}

export function getICartProductItems(cartItems: ICartItem[]): ICartProductItem[] {
    return cartItems.map((cartItem) => cartItem.item).filter(isICartProductItemType);
}

export function getICartMultiDayProductItems(cartItems: ICartItem[]): ICartMultiDayProductItem[] {
    return cartItems.map((cartItem) => cartItem.item).filter(isICartMultiDayProductItemType);
}

export function getICartPackageItems(cartItems: ICartItem[]): ICartPackageItem[] {
    return cartItems.map((cartItem) => cartItem.item).filter(isICartPackageItemType);
}

export function getCartItemSummaryDateInformation(item: ICartItem, locale: string) {
    const cartItem = item.item;
    if (isICartAccommodationItemType(cartItem)) {
        return (
            formatDate(dayjs(cartItem.start), locale, 'll') +
            ' - ' +
            formatDate(dayjs(cartItem.end), locale, 'll')
        );
    } else if (isICartMultiDayProductItemType(cartItem)) {
        return (
            formatDate(dayjs(cartItem.products[0].start), locale, 'll') +
            ' - ' +
            formatDate(dayjs(cartItem.products[cartItem.products.length - 1].end), locale, 'll')
        );
    } else if (isICartPackageItemType(cartItem)) {
        return (
            formatDate(dayjs(cartItem.start), locale, 'll') +
            ' - ' +
            formatDate(dayjs(cartItem.end), locale, 'll')
        );
    } else {
        return formatDate(dayjs(cartItem.product.start), locale, 'lll');
    }
}

export function isBookingClosed(item: ICartItem): boolean {
    const now = dayjs(new Date(Date.now()));
    const cartItem = item.item;

    if (isICartProductItemType(cartItem)) {
        const cutoffTime = cartItem.product.cutoff_time;
        const startTime = dayjs(new Date(cartItem.product.start));
        const productClosingTime = startTime.subtract(cutoffTime, 'minutes');
        const productDiff = productClosingTime.diff(now, 'minutes');
        return productDiff <= 0;
    } else if (isICartMultiDayProductItemType(cartItem)) {
        const cutoffTime = cartItem.products[0].cutoff_time;
        const startTime = dayjs(new Date(cartItem.products[0].start));
        const productClosingTime = startTime.subtract(cutoffTime, 'minutes');
        const productDiff = productClosingTime.diff(now, 'minutes');
        return productDiff <= 0;
    } else {
        const accommodationClosingTimeDate = new Date(
            new Date(cartItem.start).setHours(23, 59, 59),
        );
        const accommodationDiff = dayjs(accommodationClosingTimeDate).diff(now, 'minutes');
        return accommodationDiff <= 0;
    }
}

export function getBookingClosedItems(cartItems: ICartItem[]) {
    return cartItems.filter(isBookingClosed);
}

export function getProductIdsFromCartItems(cartItems: { [key: number]: ICartItem }): number[] {
    return Object.values(cartItems)
        .filter(
            ({ item }) =>
                isICartMultiDayProductItemType(item) ||
                isICartProductItemType(item) ||
                isICartPackageItemType(item),
        )
        .flatMap(({ item }) => {
            if (isICartProductItemType(item)) {
                return item.product.id;
            } else if (isICartPackageItemType(item)) {
                return item.selectedProducts.map(({ product }) => product.id);
            } else if (isICartMultiDayProductItemType(item)) {
                return item.products.map(({ id }) => id);
            }
        }) as number[];
}

export function isCartItemGuard(content: any): content is { [key: string]: ICartItem } {
    return !isEmpty(content) || Object.values(content).every((cartItem: any) => 'item' in cartItem);
}

export function asCartProductItems(cartMultiDayProductItems: ICartMultiDayProductItem[]) {
    return cartMultiDayProductItems.flatMap((item) =>
        item.products.reduce(
            (acc, product) => [
                ...acc,
                { product, extras: item.extras, quantities: item.quantities },
            ],
            [] as ICartProductItem[],
        ),
    );
}
