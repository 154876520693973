import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Link,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { getDialcodes, Iso2Code } from 'src/components/common/country-select/countries';
import CountrySelect from 'src/components/common/country-select/CountrySelect';
import PhoneInput from 'src/components/common/phone-input/PhoneInput';
import { PreactCheckbox } from 'src/components/common/preact-checkbox/PreactCheckbox';
import { useLocale } from 'src/i18n/locale';
import { ContactPerson, PhoneNumber } from 'src/types/checkout-info';
import TextFieldStyleUtils from 'src/utils/common/jss/TextFieldStyleUtils';
import { capitalize } from 'src/utils/common/TextUtils';
import { validateContactPerson } from 'src/utils/domain/checkout-info-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

type Props = {
    contactPerson: ContactPerson;
    showErrors: boolean;
    onChange(contactPerson: ContactPerson): void;
    showAddressFields: boolean;
};

export default function ContactPersonForm(props: Props): JSX.Element {
    const { t } = useLocale();
    const { contactPerson, onChange } = props;
    const theme = useTheme();

    const {
        isFirstNameValid,
        isLastNameValid,
        isPhoneValid,
        isEmailValid,
        isAddressValid,
        isPostCodeValid,
        isCityValid,
    } = validateContactPerson(contactPerson, props.showAddressFields);

    const showHasAcceptedPrivacyValidationError =
        !contactPerson.hasAcceptedPrivacy && props.showErrors;
    const showHasAcceptedTermsValidationError = !contactPerson.hasAcceptedTerms && props.showErrors;
    const showFirstNameValidationError = !isFirstNameValid && props.showErrors;
    const showLastNameValidationError = !isLastNameValid && props.showErrors;
    const showPhoneNumberValidationError = !isPhoneValid && props.showErrors;
    const showEmailValidationError = !isEmailValid && props.showErrors;
    const showAddressValidationError = !isAddressValid && props.showErrors;
    const showPostCodeValidationError = !isPostCodeValid && props.showErrors;
    const showCityValidationError = !isCityValid && props.showErrors;

    const configurations = useConfigurations();
    const inputLabelStyles = {
        ...TextFieldStyleUtils.positionInputLabelAboveField,
        color: theme.palette.secondary.main,
    };
    const errorStyles = {
        color: '#f44336',
        '& > *': {
            color: '#f44336',
        },
    };

    return (
        <Fragment>
            <FormControl>
                <InputLabel
                    htmlFor="bilberry-first-name"
                    error={showFirstNameValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.first_name)}
                </InputLabel>
                <TextField
                    id="bilberry-first-name"
                    required={true}
                    error={showFirstNameValidationError}
                    value={contactPerson.firstName}
                    onChange={(e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>) =>
                        onChange({ ...contactPerson, firstName: e.currentTarget.value })
                    }
                    variant="outlined"
                    type="text"
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="firstName"
                />
            </FormControl>
            <FormControl>
                <InputLabel
                    htmlFor="bilberry-last-name"
                    error={showLastNameValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.last_name)}
                </InputLabel>
                <TextField
                    id="bilberry-last-name"
                    required={true}
                    error={showLastNameValidationError}
                    value={contactPerson.lastName}
                    onChange={(e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>) =>
                        onChange({ ...contactPerson, lastName: e.currentTarget.value })
                    }
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="lastName"
                />
            </FormControl>

            <CountrySelect
                country={contactPerson.country}
                onChange={(country: Iso2Code) =>
                    onChange({
                        ...contactPerson,
                        country,
                        phone: { ...contactPerson.phone, dialCode: getDialcodes()[country] },
                    })
                }
                secondaryColor={true}
            />
            {props.showAddressFields && (
                <Fragment>
                    <FormControl>
                        <InputLabel
                            error={showAddressValidationError}
                            required={true}
                            sx={inputLabelStyles}
                        >
                            {capitalize(t.address)}
                        </InputLabel>
                        <TextField
                            error={showAddressValidationError}
                            required={true}
                            value={contactPerson.address}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => onChange({ ...contactPerson, address: e.currentTarget.value })}
                            variant="outlined"
                            type={'text'}
                            color="secondary"
                            margin="dense"
                            name="address"
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            error={showPostCodeValidationError}
                            required={true}
                            sx={inputLabelStyles}
                        >
                            {capitalize(t.post_code)}
                        </InputLabel>
                        <TextField
                            error={showPostCodeValidationError}
                            required={true}
                            value={contactPerson.postCode}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => onChange({ ...contactPerson, postCode: e.currentTarget.value })}
                            variant="outlined"
                            type={'text'}
                            color="secondary"
                            margin="dense"
                            name="postCode"
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel
                            error={showCityValidationError}
                            required={true}
                            sx={inputLabelStyles}
                        >
                            {capitalize(t.city)}
                        </InputLabel>
                        <TextField
                            error={showCityValidationError}
                            required={true}
                            value={contactPerson.city}
                            onChange={(
                                e: TargetedEvent<HTMLInputElement | HTMLTextAreaElement, Event>,
                            ) => onChange({ ...contactPerson, city: e.currentTarget.value })}
                            variant="outlined"
                            type={'text'}
                            color="secondary"
                            margin="dense"
                            name="city"
                        />
                    </FormControl>
                </Fragment>
            )}

            <PhoneInput
                phoneNumber={contactPerson.phone}
                error={showPhoneNumberValidationError}
                onChange={(phone: PhoneNumber) => onChange({ ...contactPerson, phone })}
            ></PhoneInput>

            <FormControl>
                <InputLabel
                    htmlFor="bilberry-email"
                    error={showEmailValidationError}
                    required={true}
                    sx={inputLabelStyles}
                >
                    {capitalize(t.email)}
                </InputLabel>
                <TextField
                    id="bilberry-email"
                    error={showEmailValidationError}
                    required={true}
                    value={contactPerson.email}
                    onChange={(e) => {
                        if (e.target instanceof HTMLInputElement)
                            onChange({ ...contactPerson, email: e.target.value });
                    }}
                    variant="outlined"
                    type={'text'}
                    color="secondary"
                    margin="dense"
                    size="small"
                    name="email"
                />
            </FormControl>
            <FormControl required={true} error={showHasAcceptedPrivacyValidationError}>
                <FormControlLabel
                    sx={[showHasAcceptedPrivacyValidationError && errorStyles]}
                    control={
                        <PreactCheckbox
                            required={true}
                            sx={[showHasAcceptedPrivacyValidationError && errorStyles]}
                            color="secondary"
                            checked={contactPerson.hasAcceptedPrivacy}
                            onChange={(checked) =>
                                onChange({
                                    ...contactPerson,
                                    hasAcceptedPrivacy: checked,
                                })
                            }
                        />
                    }
                    labelPlacement="end"
                    label={
                        (
                            <Typography color="textSecondary" variant="body1">
                                {capitalize(t.i_have_read_and_accepted_the)}{' '}
                                <Link
                                    color="primary"
                                    href={configurations.privacyUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {capitalize(t.privacy_policy)}
                                </Link>
                            </Typography>
                        ) as JSX.Element
                    }
                />
                {showHasAcceptedPrivacyValidationError && (
                    <FormHelperText>You must accept the Privacy Policy to continue</FormHelperText>
                )}
            </FormControl>

            <FormControl required={true} error={showHasAcceptedTermsValidationError}>
                <FormControlLabel
                    sx={[showHasAcceptedTermsValidationError && errorStyles, { mt: 0 }]}
                    control={
                        <PreactCheckbox
                            sx={[showHasAcceptedTermsValidationError && errorStyles]}
                            checked={contactPerson.hasAcceptedTerms}
                            color="secondary"
                            onChange={(checked) =>
                                onChange({
                                    ...contactPerson,
                                    hasAcceptedTerms: checked,
                                })
                            }
                        />
                    }
                    labelPlacement="end"
                    label={
                        (
                            <Typography color="textSecondary" variant="body1">
                                {capitalize(t.i_have_read_and_accepted_the)}{' '}
                                <Link
                                    color="primary"
                                    href={configurations.termsUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {capitalize(t.terms_and_conditions)}
                                </Link>
                            </Typography>
                        ) as JSX.Element
                    }
                    color="textSecondary"
                />
                {showHasAcceptedTermsValidationError && (
                    <FormHelperText>
                        You must accept the Terms and Conditions to continue
                    </FormHelperText>
                )}
            </FormControl>
        </Fragment>
    );
}
