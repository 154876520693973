import { Fragment, h, render } from 'preact';
import Router from 'preact-router';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import { history } from 'src/utils/domain/setup';
import { Cart } from 'src/widgets/activities/checkout/cart/Cart';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { GiftcardCart } from '../giftcard-checkout/cart/GiftcardCart';

class BilberryCheckout extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <Router history={history as any}>
                    <Fragment path="/checkout">
                        <Cart />
                    </Fragment>
                    <Fragment path="/gift-card-checkout">
                        <GiftcardCart />
                    </Fragment>
                </Router>
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-checkout', BilberryCheckout);
}
