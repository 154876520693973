import { BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { AccommodationPrice } from 'src/types/bilberry-hotels-api-types';

export function mapAccommodationPriceToBookingPriceAndQuantity(
    price: AccommodationPrice,
): BookingPriceAndQuantity {
    return {
        name: price.name,
        price: price.value,
        price_type: price.id.toString(),
        price_category_id: price.id,
        quantity: 0,
        occupancy: 1,
        age_to: null,
        age_from: null,
    };
}
