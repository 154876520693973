import dayjs, { Dayjs } from 'dayjs';
import { groupBy, mapValues } from 'lodash-es';
import omit from 'lodash-es/omit';
import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import BookingBase from 'src/components/domain/booking-base/BookingBase';
import BookingToggleOpenButton from 'src/components/domain/booking-widget-card/BookingToggleOpenButton';
import { useOnClickBookPackage } from 'src/hooks/domain/activities/useOnClickBookPackage';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import useBookingState from 'src/hooks/domain/useBookingState';
import { useLocale } from 'src/i18n/locale';
import { BilberryPriceQuantity, BookingPriceAndQuantity } from 'src/state/cart/ICart';
import { BilberryPackageAvailability } from 'src/types/bilberry-api-types';
import { capitalize } from 'src/utils/common/TextUtils';
import {
    getAvailableProductsFromMultipleProductCatalogs,
    usePackage,
    usePackageAvailability,
    usePackageAvailabilityMulti,
} from 'src/utils/domain/api/bilberry-api-client';
import { getPackageDisplayTitle } from 'src/utils/domain/display-helper';
import { getPackageDuration } from 'src/utils/domain/packages/duration';
import { initialPackagePricesFromTicketOptions } from 'src/utils/domain/packages/prices';
import {
    getPackageProductIds,
    hasPackageProductsCapacity,
    packageProductsFromTicketOptions,
} from 'src/utils/domain/packages/products';
import { updateQuantityData } from 'src/utils/domain/price-helper';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

interface IProps {
    packageId: number;
    expandArrowInside: boolean;
    positionOffscreen?: boolean;
}
export default function PackageBooking(props: IProps): JSX.Element {
    const { packageId, expandArrowInside, positionOffscreen = false } = props;
    const {
        onToggleVisible,
        hasChosenDate,
        setHasChosenDate,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        visible,
        boxRef,
    } = useBookingState();
    const { t, locale } = useLocale();
    const config = useConfigurations();
    const [quantities, setQuantities] = useState<BilberryPriceQuantity[]>([]);
    const { pkg } = usePackage(packageId, locale, config, {
        onSuccess: (data) => {
            const prices = initialPackagePricesFromTicketOptions(data);
            setQuantities((prev) => updateQuantityData(undefined, prices, prev));
        },
    });

    const [availabilitySearchPeriod, setAvailabilitySearchPeriod] = useState<{
        startDay: Dayjs | null;
        endDay: Dayjs | null;
    }>({
        startDay: dayjs(),
        endDay: dayjs().add(1, 'month'),
    });
    const { pkgAvailability } = usePackageAvailability(packageId, locale, config);
    const { pkgAvailability: pkgAvailabilityMulti } = usePackageAvailabilityMulti(
        packageId,
        locale,
        config,
        availabilitySearchPeriod.startDay ?? dayjs(),
        availabilitySearchPeriod.endDay ?? dayjs().add(1, 'month'),
    );
    const availabilityData = getPkgAvailabilityDataFromPkgAvailabilityMulti(
        pkgAvailabilityMulti,
        quantities,
    );

    const [capacityError, setCapacityError] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

    useWidgetEventEffect(
        (pkg) => ({
            eventType: 'viewItem',
            product: pkg,
            productType: 'Package',
        }),
        pkg,
        visible,
    );

    const packagePrices = useMemo(() => initialPackagePricesFromTicketOptions(pkg), [pkg]);
    const packageFromPrice = Math.min(...packagePrices.map((p) => p.price));

    useEffect(() => {
        const run = async () => {
            if (selectedDate && pkg) {
                const packageProducts = packageProductsFromTicketOptions(pkg);
                const productIds = getPackageProductIds(packageProducts);
                const [start, end] = getPackageDuration(selectedDate, pkg);

                const bilberryProducts = await getAvailableProductsFromMultipleProductCatalogs(
                    productIds,
                    start,
                    end,
                    locale,
                    config,
                );

                const hasCapacity = hasPackageProductsCapacity(
                    packageProducts,
                    bilberryProducts,
                    quantities,
                );

                if (!hasCapacity) setCapacityError(true);
                else setCapacityError(false);
            }
        };

        run();
    }, [selectedDate, quantities, pkg, setCapacityError, locale, config]);

    const onClickBook = useOnClickBookPackage(
        pkg,
        pkgAvailability ?? [],
        quantities,
        packagePrices,
        attemptedBooking,
        setAttemptedBooking,
        shouldShowBasketOnBook,
        boxRef,
        setQuantities,
        hasChosenDate,
        selectedDate,
        setSelectedDate,
    );

    return (
        <BookingBase
            bookingCardRef={boxRef}
            isPackageCalendar
            hideLeftButton
            productTitle={getPackageDisplayTitle(pkg)}
            travelerQuantities={quantities}
            priceQuantities={quantities}
            defaultQuantities={getInitialQuantityData(packagePrices)}
            setQuantities={setQuantities as unknown as StateUpdater<BookingPriceAndQuantity[]>}
            availabilityData={availabilityData}
            rightButtonLabel={capitalize(t.book_now)}
            onClickRightButton={onClickBook}
            hasChosenDate={hasChosenDate}
            setHasChosenDate={setHasChosenDate}
            attemptedBooking={attemptedBooking}
            availabilitySearchPeriod={availabilitySearchPeriod}
            setAvailabilitySearchPeriod={setAvailabilitySearchPeriod}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            title={capitalize(t.book_now)}
            fromPrice={packageFromPrice}
            expandArrowInside={expandArrowInside}
            visible={visible}
            onToggleVisible={onToggleVisible}
            minEntrants={1}
            toggleButton={
                <BookingToggleOpenButton expandArrowInside={expandArrowInside} visible={visible} />
            }
            warningLabel={
                capacityError
                    ? t.one_or_more_activities_or_accommodations_dont_have_enough_capacity
                    : undefined
            }
            positionOffscreen={positionOffscreen}
        />
    );
}

function getInitialQuantityData<T>(ticketOptions: T[]): Array<T & { quantity: number }> {
    return ticketOptions.map((to) => ({
        ...to,
        quantity: 1,
    }));
}
function getPkgAvailabilityDataFromPkgAvailabilityMulti(
    pkgAvailabilityMulti: BilberryPackageAvailability,
    quantities: BilberryPriceQuantity[],
) {
    const flattened = pkgAvailabilityMulti
        .map((pam) => pam.products.map((ps) => ({ ...omit(pam, 'products'), ...ps })))
        .flat();
    const withStartDate = flattened.map((p) => ({
        ...p,
        startDate: p.tours.reduce<string>(
            (acc, t) => (dayjs(t.start).isBefore(dayjs(acc)) ? t.start : acc),
            p.tours[0].start,
        ),
    }));

    const availability = withStartDate.map(({ startDate, available, ticket_option_id }) => ({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        ticketOptionId: ticket_option_id,
        available: Boolean(available),
    }));
    const groupedByDate = groupBy(availability, 'startDate');

    const filteredByQuantity = mapValues(groupedByDate, (val) =>
        val.filter((v) => {
            const isWithinQuantities = quantities.map(({ id }) => id).includes(v.ticketOptionId);
            const isUnavailableForQuantity = isWithinQuantities && !v.available;
            return isUnavailableForQuantity;
        }),
    );
    const available = mapValues(filteredByQuantity, (val) => ({
        available: val.length === 0,
    }));

    return available;
}
