import { KingBed } from '@mui/icons-material';
import { Grid, LinearProgress, Typography, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';

export default function HotelLoadingOverlay(): JSX.Element {
    const { t } = useLocale();
    const theme = useTheme();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            zIndex={100}
            sx={{ backgroundColor: 'transparent' }}
        >
            <KingBed color="primary" />
            <Typography variant="h3" color="textPrimary" mt={theme.spacing(2)} textAlign="center">
                {t.finding_available_rooms}
            </Typography>
            <LinearProgress color="primary" sx={{ marginTop: theme.spacing(4), width: '100%' }} />
        </Grid>
    );
}
