import { Grid, useTheme } from '@mui/material';
import { h } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { UserTextCustomizations } from 'src/i18n/translations/types';
import { useProductCatalogsByIds } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';
import ProductCard from 'src/widgets/activities/product/product-list/product-card/ProductCard';

interface IProps {
    productCatalogIds: number[];
    productCatalogUrls: string[];
    productCatalogButtonCustomizationKeys?: (keyof UserTextCustomizations)[];
    scroll: boolean;
    numElements: {
        numXs: number;
        numSm: number;
        numMd: number;
        numLg: number;
    };
}

export default function ActivityProductCardList(props: IProps): JSX.Element {
    const { productCatalogIds, productCatalogUrls, scroll, numElements } = props;
    const { locale } = useLocale();
    const config = useConfigurations();
    const numProductCatalogs = productCatalogIds.length;
    const theme = useTheme();

    return <ProductCardList {...props} />;
}

type ProductCardListProps = {
    products: {};
};
export function ProductCardList(props: IProps): JSX.Element {
    const { productCatalogIds, productCatalogUrls, scroll, numElements } = props;
    const { locale } = useLocale();
    const config = useConfigurations();
    const numProductCatalogs = productCatalogIds.length;
    const theme = useTheme();

    const { productCatalogs } = useProductCatalogsByIds(productCatalogIds, locale, config);

    const productCardList = productCatalogs.map((catalog) => {
        return (
            <ProductCard
                key={catalog.id}
                productCatalog={catalog}
                url={productCatalogUrls[productCatalogIds.indexOf(catalog.id ?? 0)]}
                buttonCustomizationKey={
                    props.productCatalogButtonCustomizationKeys
                        ? props.productCatalogButtonCustomizationKeys[
                              productCatalogIds.indexOf(catalog.id ?? 0)
                          ]
                        : undefined
                }
                scroll={scroll}
                numberOfCards={productCatalogs.length}
                numElements={numElements}
            />
        );
    });

    return (
        <Grid
            container
            direction="row"
            alignContent="space-around"
            m="auto"
            width="100%"
            overflow="auto"
        >
            <Grid
                container
                item
                direction="row"
                wrap={scroll ? 'nowrap' : 'wrap'}
                sx={{
                    flexGrow: 0,
                    flexBasis: (props) =>
                        getCardListFlexBasisAndWidth(scroll, numElements.numLg, numProductCatalogs),
                    maxWidth: (props) =>
                        getCardListFlexBasisAndWidth(scroll, numElements.numLg, numProductCatalogs),
                    [theme.breakpoints.down('lg')]: {
                        flexBasis: (props) =>
                            getCardListFlexBasisAndWidth(
                                scroll,
                                numElements.numMd,
                                numProductCatalogs,
                            ),
                        maxWidth: (props) =>
                            getCardListFlexBasisAndWidth(
                                scroll,
                                numElements.numMd,
                                numProductCatalogs,
                            ),
                    },
                    [theme.breakpoints.down('md')]: {
                        flexBasis: (props) =>
                            getCardListFlexBasisAndWidth(
                                scroll,
                                numElements.numSm,
                                numProductCatalogs,
                            ),
                        maxWidth: (props) =>
                            getCardListFlexBasisAndWidth(
                                scroll,
                                numElements.numSm,
                                numProductCatalogs,
                            ),
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexBasis: (props) =>
                            getCardListFlexBasisAndWidth(
                                scroll,
                                numElements.numXs,
                                numProductCatalogs,
                            ),
                        maxWidth: (props) =>
                            getCardListFlexBasisAndWidth(
                                scroll,
                                numElements.numXs,
                                numProductCatalogs,
                            ),
                    },
                }}
            >
                {productCatalogs?.length > 0 && productCardList}
            </Grid>
        </Grid>
    );
}

function getCardListFlexBasisAndWidth(
    scroll: boolean,
    numCardsPerRow: number,
    numProductCatalogs: number,
): string {
    if (scroll && numCardsPerRow >= numProductCatalogs) {
        return `${100 / numCardsPerRow}%`;
    }
    if (scroll) {
        const oneThirdCardSpace = 100 / numCardsPerRow / 3;
        const restCardSpace = 100 - oneThirdCardSpace;
        return `${restCardSpace / numCardsPerRow}%`;
    }
    return '100%';
}
