import { h, render } from 'preact';
import { getMediaQuerySizesFromAttributes } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { getYesAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { getCsvColorsAttributes } from 'src/utils/domain/attributes/color-attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import TourListBookable from './TourListBookable';

class BilberryTourListBookable extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        overrideLocaleFromAttribute(this);

        const ids = this.getAttribute('tour-ids')?.split(',') ?? [];
        const hideReadMore = getYesAttribute(this, 'hide-read-more');

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 2, 3);

        const scrollAttribute = this.getAttribute('scroll');
        const scroll = !(scrollAttribute && scrollAttribute!.toString().toLowerCase() === 'no');
        const colors = getCsvColorsAttributes(this);

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <TourListBookable
                    ids={ids}
                    hideReadMore={hideReadMore}
                    numElements={numElements}
                    scroll={scroll}
                    backgroundColors={colors.backgroundColors}
                    textColors={colors.textColors}
                    primaryColors={colors.primaryColors}
                    primaryTextColors={colors.primaryTextColors}
                    accentColors={colors.accentColors}
                    accentTextColors={colors.accentTextColors}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-tour-list-bookable', BilberryTourListBookable);
}
