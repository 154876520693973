import { h, render } from 'preact';
import { getMediaQuerySizesFromAttributes } from 'src/utils/common/mediaQueryAttributeInputHelper';
import { getIdAttribute } from 'src/utils/domain/attributes/attribute-helper';
import { overrideLocaleFromAttribute } from 'src/utils/domain/attributes/language-attribute-helper';
import createWebComponentTagName from 'src/utils/domain/createWebComponentTagName';
import { ProductGalleryAlign } from 'src/widgets/activities/product/product-gallery-2/gallery/ProductGallery2';
import ProductGallery2WidgetRoot from 'src/widgets/activities/product/product-gallery-2/ProductGallery2WidgetRoot';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

function getAlignType(align: string) {
    switch (align.toLowerCase()) {
        case 'left':
            return ProductGalleryAlign.LEFT;
        case 'center':
            return ProductGalleryAlign.CENTER;
        case 'right':
            return ProductGalleryAlign.RIGHT;
        default:
            return ProductGalleryAlign.SCALE;
    }
}

class BilberryProductGallery2 extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const productCatalogId = getIdAttribute(this, false, 'product-catalog-id');
        overrideLocaleFromAttribute(this);
        const numVisibleImages = getMediaQuerySizesFromAttributes(this, 1, 2, 3, 3);
        const alignText = this.getAttribute('align');
        const align = alignText ? getAlignType(alignText) : ProductGalleryAlign.SCALE;

        render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ProductGallery2WidgetRoot
                    productCatalogId={productCatalogId}
                    numVisibleImages={numVisibleImages}
                    align={align}
                />
            </WidgetWrapper>,
            this._mountPoint,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        createWebComponentTagName('bilberry-product-gallery', 'v2'),
        BilberryProductGallery2,
    );
}
