import { h } from 'preact';
import ProductCardWide from 'src/components/domain/product-card-2/ProductCardWide';
import { useLocale } from 'src/i18n/locale';
import { useProductCatalog } from 'src/utils/domain/api/bilberry-api-client';
import { useConfigurations } from 'src/utils/domain/widgetsConfiguration';

export default function ProductFeatured(props: {
    productCatalogId: number;
    productCatalogUrl?: string;
}) {
    const { locale } = useLocale();
    const config = useConfigurations();
    const { productCatalog } = useProductCatalog(props.productCatalogId, locale, config);

    if (!productCatalog) return null;

    return (
        <ProductCardWide
            productCatalog={productCatalog}
            productCatalogUrl={props.productCatalogUrl}
        />
    );
}
