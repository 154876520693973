import { Grid, Typography } from '@mui/material';
import { Fragment } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency, getLocaleNumberFormatTwoDecimals } from 'src/i18n/Localization';
import {
    ICartExtra,
    ICartMultiDayProductItem,
    ICartPackageItem,
    ICartProductItem,
} from 'src/state/cart/ICart';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { getExtraDisplayTitle } from 'src/utils/domain/display-helper';
import { useAtom } from 'ximple';

type SummaryExtrasProps = {
    cartItem: ICartProductItem | ICartMultiDayProductItem | ICartPackageItem;
    invertedColorsClass?: Record<string, unknown>;
};

export default function SummaryExtras({ cartItem, invertedColorsClass }: SummaryExtrasProps) {
    const { locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const extrasWithQuantity = cartItem.extras
        .filter(extraWithQuantity)
        .map(removeEmptyPriceQuantities);

    return (
        <Fragment>
            {extrasWithQuantity.map((extra) =>
                extra.quantities.map((quantity) => (
                    <Grid
                        key={`summary-activity-info-${quantity.id}`}
                        container
                        direction="row"
                        justifyContent="space-between"
                        wrap="nowrap"
                    >
                        <Typography align="left" color="textSecondary" sx={invertedColorsClass}>
                            {quantity.quantity}x {getExtraDisplayTitle(extra.extra)}{' '}
                            {quantity.name !== 'Unit' ? `(${quantity.name})` : null} (
                            {formatCurrency(locale, currency, quantity.price)})
                        </Typography>
                        <Typography align="right" color="textSecondary" sx={invertedColorsClass}>
                            {getLocaleNumberFormatTwoDecimals(
                                locale,
                                quantity.quantity * quantity.price,
                            )}
                        </Typography>
                    </Grid>
                )),
            )}
        </Fragment>
    );
}

function extraWithQuantity({ quantities }: ICartExtra) {
    return quantities.some((q) => q.quantity > 0);
}

function removeEmptyPriceQuantities(extra: ICartExtra) {
    return {
        ...extra,
        quantities: extra.quantities.filter((q) => q.quantity > 0),
    };
}
