import {
    GiftcardPaymentInfo,
    PaymentInfo,
    TimeslotsPaymentInfo,
} from 'src/components/domain/payment/payment-types';

export function isGiftcardPaymentInfo(
    paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
): paymentInfo is GiftcardPaymentInfo {
    return (paymentInfo as GiftcardPaymentInfo).giftcard !== undefined;
}

export function isTimeslotsPaymentInfo(
    paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
): paymentInfo is TimeslotsPaymentInfo {
    return (paymentInfo as TimeslotsPaymentInfo).checkout_key !== undefined;
}
