import flow from 'lodash-es/flow';
import { DiscountSource } from 'src/types/discounts';
import { MembershipPaymentPlan } from 'src/types/membership-api-types';
import { isDiscountSource, withDiscounts } from '../discounts/price';

/** Get the total currency cost in NOK of a booking
 * @param paymentPlan The payment plan for the booking
 */
export function getTotalPrice(paymentPlan: MembershipPaymentPlan[]): number {
    const totalPrice = paymentPlan
        .filter(({ currencyCost }) => currencyCost)
        .reduce((total, { currencyCost }) => total + (currencyCost?.price ?? 0), 0);
    return totalPrice / 100;
}

/** Get the total currency cost in NOK of a booking, before VAT has been applied
 * @param paymentPlan The payment plan for the booking
 */
export function getTotalNetPrice(paymentPlan: MembershipPaymentPlan[]) {
    const totalNetPrice = paymentPlan
        .filter(({ currencyCost }) => currencyCost)
        .reduce((acc, { currencyCost }) => acc + (currencyCost?.netPrice ?? 0), 0);
    return totalNetPrice / 100;
}

/** Get the total cost of the applied VAT of a booking, in NOK
 * @param paymentPlan The payment plan for the booking
 */
export function getTotalVat(paymentPlan: MembershipPaymentPlan[]) {
    const totalVat = paymentPlan
        .filter(({ currencyCost }) => currencyCost)
        .reduce((acc, { currencyCost }) => acc + (currencyCost?.vatAmount ?? 0), 0);
    return totalVat / 100;
}

/**
 * Get the total price of a booking, in NOK
 * @param paymentPlan The payment plan from an intent for which to potentially reduce the price
 * @param discounts The various discount source objects, which specify a balance and a reduction type (percentage/absolute)
 */
export const getTotalPriceWithReductions = (
    paymentPlan: MembershipPaymentPlan[],
    discounts: (DiscountSource | undefined)[],
) =>
    flow<[MembershipPaymentPlan[]], number, number>(
        getTotalPrice,
        withDiscounts(discounts.filter(isDiscountSource)),
    )(paymentPlan);
