import { h } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import {
    GiftcardPaymentInfo,
    PaymentInfo,
    TimeslotsPaymentInfo,
} from 'src/components/domain/payment/payment-types';
import { useLocale } from 'src/i18n/locale';
import { MountPointContext } from 'src/utils/common/mountPoint';
import {
    isGiftcardPaymentInfo,
    isTimeslotsPaymentInfo,
} from 'src/utils/domain/payment-types-helper';

declare const window: any;

interface IProps {
    context: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo;
    onPaymentCompleted: (
        paymentInfo: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) => void;
}

function getCheckoutLanguage(preferredLocale: string) {
    switch (preferredLocale) {
        case 'dk':
        case 'da-DK':
            return 'da-DK';

        case 'se':
        case 'sv-SE':
            return 'sv-SE';

        case 'no':
        case 'nb':
        case 'nn':
        case 'nn-NO':
        case 'nb-NO':
            return 'nb-NO';

        case 'de':
        case 'de-DE':
            return 'de-DE';

        case 'pl':
        case 'pl-PL':
            return 'pl-PL';

        case 'fr':
        case 'fr-FR':
            return 'fr-FR';

        case 'es':
        case 'es-ES':
            return 'es-ES';

        case 'nl':
        case 'nl-NL':
            return 'nl-NL';

        case 'fi':
        case 'fi-FI':
            return 'fi-FI';

        default:
            return 'en-GB';
    }
}

export default function NetsPayment(props: IProps): JSX.Element {
    const { locale } = useLocale();
    const mountPoint = useContext(MountPointContext);
    setupDocumentToSearchShadowRoot(mountPoint.shadowRoot);
    useEffect(() => {
        const isGiftcard = isGiftcardPaymentInfo(props.context);
        const isTimeslots = isTimeslotsPaymentInfo(props.context);
        const paymentType = isGiftcard ? 'giftcard' : isTimeslots ? 'timeslots' : 'booking';
        const { checkoutKey, paymentId } = getPaymentInfoByType(props.context, paymentType);

        const checkoutOptions = {
            checkoutKey,
            paymentId,
            containerId: 'dibs-complete-checkout',
            language: getCheckoutLanguage(locale),
        };
        const checkout = new window.Dibs.Checkout(checkoutOptions);

        checkout.on('payment-completed', function (paymentGatewayResponse: any) {
            props.onPaymentCompleted(props.context, paymentGatewayResponse);
        });
    }, []);

    return <div id="dibs-complete-checkout" />;
}

function getPaymentInfoByType(
    context: TimeslotsPaymentInfo | PaymentInfo | GiftcardPaymentInfo,
    type: 'giftcard' | 'timeslots' | 'booking',
) {
    switch (type) {
        case 'giftcard': {
            return {
                checkoutKey: (context as GiftcardPaymentInfo).giftcard.checkout_key,
                paymentId: (context as GiftcardPaymentInfo).giftcard.payment_id,
            };
        }
        case 'timeslots': {
            return {
                checkoutKey: (context as TimeslotsPaymentInfo).checkout_key,
                paymentId: (context as TimeslotsPaymentInfo).payment_id,
            };
        }
        default: {
            return {
                checkoutKey: (context as PaymentInfo).booking.checkout_key,
                paymentId: (context as PaymentInfo).booking.payment_id,
            };
        }
    }
}

// All this only happens once per reload of the page.
// We need to make sure the shadow root is searched for the id. (Nets needs to find the div to inject iframe into)
declare const document: any;
document._oldGetElementById = document.getElementById;
let calledOnce = false;
function setupDocumentToSearchShadowRoot(shadowRoot: ShadowRoot) {
    if (calledOnce) {
        return;
    }
    document.getElementById = (id: string) => {
        let res = document._oldGetElementById(id);
        if (!res) {
            res = shadowRoot.querySelector('#' + id);
        }
        return res;
    };
    calledOnce = true;
}
