import { Dayjs } from 'dayjs';

export enum DifficultyEnum {
    Any,
    Easy = 'easy',
    Medium = 'medium',
    Hard = 'hard',
}

export enum DurationEnum {
    Any,
    OneHour,
    TwoHours,
    SixHours,
    TwelveHours,
    OneDay,
    TwoDays,
    Longer,
}

export type ProductSearch = {
    adults: number | null;
    children: number | null;
    start: Dayjs | null;
    end: Dayjs | null;
    difficulty: DifficultyEnum;
    minDuration: number | null;
    maxDuration: number | null;
};
