import { h } from 'preact';
import { searchFormDataAtom } from 'src/state/search-form-data/searchFormDataAtom';
import { ProductSearch } from 'src/types/parameter-types';
import { SearchForm } from 'src/widgets/activities/booking-search/search-form/SearchForm';
import { useAtom } from 'ximple';

export default function BookingSearchFormWidgetRoot(props: { resultUrl: string }): JSX.Element {
    const [searchFormData, setSearchFormData] = useAtom(searchFormDataAtom);
    const onClick = (formData: ProductSearch) => {
        setSearchFormData(formData);
        window.location.href = props.resultUrl;
    };

    return (
        <SearchForm
            onClick={onClick}
            minDuration={0}
            maxDuration={Number.MAX_VALUE}
            defaultFormData={searchFormData}
        />
    );
}
