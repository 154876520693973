import { Box } from '@mui/material';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
    Appearance,
    PaymentIntent,
    Stripe,
    StripeElementsOptions,
    StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useEffect, useState } from 'preact/hooks';
import { useLocale } from 'src/i18n/locale';
import { stripePaymentAtom, useStripePayment } from 'src/state/payment/stripePayment.atom';
declare module 'preact/src/jsx' {
    namespace JSXInternal {
        interface IntrinsicElements {
            'bilberry-stripe-pay-button': any;
            'bilberry-stripe-alert': any;
        }
    }
}
import { h } from 'preact';

export default function StripePayment() {
    const { stripeKey, stripeClientSecret } = useStripePayment();
    const { locale } = useLocale();

    const stripeLocale = locale.slice(0, 2) as 'en' | 'nb';

    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | undefined>(
        undefined,
    );

    useEffect(() => {
        if (!stripeKey || stripePromise) return;
        const stripe = loadStripe(stripeKey, { locale: stripeLocale });
        setStripePromise(stripe);
    }, [stripeKey]);

    const appearance: Appearance = {
        theme: 'stripe',
    };

    const elementsOptions: StripeElementsOptions = {
        clientSecret: stripeClientSecret ?? '',
        appearance,
    };

    return (
        <>
            {stripeClientSecret && stripePromise && (
                <Elements options={elementsOptions} stripe={stripePromise}>
                    <StripeForm />
                </Elements>
            )}
        </>
    );
}

function StripeForm() {
    const stripe = useStripe();
    const elements = useElements();

    const { t } = useLocale();

    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(undefined);

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe || !paymentIntent) return;
        stripePaymentAtom.update({
            ...stripePaymentAtom.subject.value,
            paymentStatus: paymentIntent.status,
        });
    }, [stripe, paymentIntent]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
        });

        if (error && error.type === 'card_error') {
            setShowError(true);
            setIsLoading(false);
            setErrorMessage(error.message ?? t.anErrorOccurredDuringPayment);
            return;
        }

        setPaymentIntent(paymentIntent);
        setIsLoading(false);
    };

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: 'tabs',
    };

    return (
        <form className="bilberry-stripe-payment" id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
                className="bilberry-stripe-payment"
                id="payment-element"
                options={paymentElementOptions}
            />
            {showError && (
                <bilberry-stripe-alert errormessage={errorMessage}></bilberry-stripe-alert>
            )}
            <Box
                className="bilberry-stripe-payment"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {stripe && elements && (
                    <bilberry-stripe-pay-button
                        handleSubmit={handleSubmit}
                        isloading={isLoading}
                    ></bilberry-stripe-pay-button>
                )}
            </Box>
        </form>
    );
}
