import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Fragment } from 'preact';
import { useLocale } from 'src/i18n/locale';
import { formatCurrency } from 'src/i18n/Localization';
import { currencyAtom } from 'src/state/currency/currency.atom';
import { getSimpleDateString } from 'src/utils/common/DateHelpers';
import { capitalize } from 'src/utils/common/TextUtils';
import { useAtom } from 'ximple/atoms';
import CreateGiftcardValue from './CreateGiftcardValue';

interface IProps {
    largeScreen: boolean;
    giftcardValue: number;
    onGiftcardValueChanged: (giftcardValue: number) => void;
    showError?: boolean;
    expiryDate?: string;
}

export default function CreateGiftcard(props: IProps): JSX.Element {
    const {
        largeScreen,
        giftcardValue,
        onGiftcardValueChanged,
        showError = false,
        expiryDate,
    } = props;
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();

    const onValueChange = (value: number): void => {
        onGiftcardValueChanged(value);
    };

    return (
        <Box
            component={Paper}
            variant="outlined"
            mt={theme.spacing(2)}
            mr={largeScreen ? 0 : theme.spacing(2)}
            padding={theme.spacing(3, 3, 2, 3)}
            minHeight="400px"
            minWidth={largeScreen ? '400px' : '300px'}
            width="100%"
            color={theme.palette.primary.main}
            border={`1px solid ${theme.palette.grey[300]}`}
            sx={{
                backgroundColor: theme.palette.grey[50],
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    maxWidth: '440px',
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                },
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
        >
            <Typography color="textSecondary" variant="h4">
                <Box fontSize="20px" fontWeight="600">
                    {capitalize(t.gift_card)}
                </Box>
            </Typography>
            <CreateGiftcardValue
                giftcardValue={giftcardValue}
                onChange={onValueChange}
                showError={showError}
            />
            <Typography align="right" color="textSecondary" variant="body1" mt={theme.spacing(16)}>
                <Box fontSize="14px">
                    {expiryDate && (
                        <Fragment>
                            {capitalize(t.valid_until)}:{' '}
                            {getSimpleDateString(new Date(expiryDate), locale)}
                        </Fragment>
                    )}
                </Box>
            </Typography>
            <Grid
                container
                direction="row"
                alignItems="baseline"
                justifyContent="flex-end"
                borderTop={`1px solid ${theme.palette.grey[400]}`}
            >
                <Grid item>
                    <Typography color="textSecondary" variant="h6" pr={'12px'}>
                        <Box fontSize="18px">{t.total.toUpperCase()}</Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="textSecondary" variant="h4" mt={theme.spacing(2)}>
                        <Box fontSize="28px" fontWeight="600">
                            {formatCurrency(locale, currency, giftcardValue)}
                        </Box>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
